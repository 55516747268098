import { ShotSettings } from "./../../../models/shotSettings";
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Observable, of, Subject, Subscription } from "rxjs";
import { debounceTime, filter, switchMap } from "rxjs/operators";
import { JobDetail } from "../../../models/jobDetail";
import { Ranges } from "../../../models/ranges";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { ProjectService } from "../../../shared/services/project.service";
import {
  SliceSettings,
  SliceSettingsService,
} from "../../services/slice-settings.service";
import { StoredSettingsService } from "../../services/stored-settings.service";

@Component({
  selector: "app-slice-settings",
  templateUrl: "./slice-settings.component.html",
  styleUrls: ["./slice-settings.component.less"],
})
export class SliceSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobDetail: JobDetail;
  @Input() settingsKey: string;
  @Input() ranges: Ranges = {
    x: [0, 0],
    y: [0, 0],
    z: [0, 0],
  };
  // iteration: number = 0;
  steps: number[] = [10, 10, 10, 10];
  timeSliceModels = ["ForwardWavefield", "BackwardWavefield"];
  shotModels = ["ForwardWavefield", "BackwardWavefield", "grad", "den", "dens"];
  availTimeSlices: number[] = [];
  shotId: number = null;
  availShots: number[] = [];
  timeSlice: number = null;
  sliceSettings: SliceSettings;
  sliderSettings$ = new Subject();
  timeSliceSubscription: Subscription;
  sliderSubscription: Subscription;
  private settingsSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private sliceSettingsService: SliceSettingsService,
    private storedSettingsService: StoredSettingsService
  ) {}

  ngOnInit() {
    this.loadPage(this.route.snapshot.queryParamMap);

    this.sliderSubscription = this.sliderSettings$
      .pipe(debounceTime(100))
      .subscribe((params) => {
        this.storeSettings();
        if (params === "depth") {
          this.storedSettingsService.settingsUpdated([SettingTypes.Depth]);
        }
      });
    this.settingsSubscription = this.storedSettingsService.settingsUpdated$
      .pipe(
        filter((settingTypes) =>
          settingTypes.some((r) => [SettingTypes.SliceSettings].indexOf(r) >= 0)
        )
      )
      .subscribe(() => {
        this.restoreSettings();
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    const change = changes.jobDetail;
    if (change.currentValue && !change.isFirstChange()) {
      this.loadPage(this.route.snapshot.queryParamMap);
    }
  }
  loadPage(queryParams: ParamMap) {
    this.restoreSettings();
  }
  onSliderChanged(sliderName: string) {
    this.sliderSettings$.next(sliderName);
  }
  getTimeSliceInterval() {
    if (!this.availTimeSlices || this.availTimeSlices.length <= 1) {
      return 0;
    }

    return this.availTimeSlices[1] - this.availTimeSlices[0];
  }

  private getAvailableTimeSlices(modelType) {
    if (!this.shotModels.includes(modelType)) return;
    this.timeSliceSubscription = this.projectService
      .getShotsAvailableForModel(
        this.jobDetail.id,
        this.sliceSettings.iteration,
        modelType
      )
      .pipe(
        switchMap((shots) => {
          if (!shots || shots.length <= 0) return of(null);
          shots = shots.sort((a, b) => a - b);
          this.availShots = shots;
          if (!this.shotId) this.shotId = shots[0];
          if (!this.timeSliceModels.includes(modelType)) return of(null);
          return this.projectService.getTimeSliceAvailableForModel(
            this.jobDetail.id,
            this.sliceSettings.iteration,
            this.shotId,
            modelType
          );
        })
      )
      .subscribe((timeSlices) => {
        if (!timeSlices) return;
        this.availTimeSlices = timeSlices;
        if (
          this.availTimeSlices &&
          this.availTimeSlices.length > 0 &&
          !this.timeSlice
        )
          this.timeSlice = this.availTimeSlices[0];
      });
  }
  storeSettings() {
    this.storedSettingsService.setSettings<SliceSettings>(
      this.settingsKey,
      {
        ...this.sliceSettings,
        id: this.jobDetail.id,
        projectId: this.jobDetail.projectId,
      },
      SettingTypes.SliceSettings
    );

    const shotSettings = this.storedSettingsService.getSettings<ShotSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Shot,
      this.jobDetail.projectId
    );

    this.storedSettingsService.setSettings<ShotSettings>(
      this.settingsKey,
      {
        ...shotSettings,
        id: this.jobDetail.id,
        projectId: this.jobDetail.projectId,
        sliceNum: this.sliceSettings.depth,
      },
      SettingTypes.Shot
    );

    this.storedSettingsService.settingsUpdated([
      SettingTypes.SliceSettings,
      SettingTypes.Shot,
    ]);
  }
  restoreSettings() {
    var storedSliceSettings =
      this.storedSettingsService.getSettings<SliceSettings>(
        this.settingsKey,
        this.jobDetail.id,
        SettingTypes.SliceSettings,
        this.jobDetail.projectId
      );
    this.sliceSettings = this.sliceSettingsService.setDefaults(
      storedSliceSettings,
      this.jobDetail
    );
  }
  ngOnDestroy(): void {
    if (this.sliderSubscription) {
      this.sliderSubscription.unsubscribe();
    }
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }
}
