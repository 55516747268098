import {
  Component,
  OnInit,
  AfterViewChecked,
  OnChanges,
  SimpleChanges,
  Input,
  ElementRef,
  ViewChild,
  HostListener,
  OnDestroy,
  EventEmitter,
  Output,
} from "@angular/core";
import { SliceType } from "../../../models/sliceType";
import { Ranges } from "../../../models/ranges";
import { PlotDetail } from "../../../models/plotDetail";
import { ImageLoaderService } from "../../../shared/services/image-loader.service";
import { D3Chart } from "./d3.script.heatmap";
import { forkJoin, Observable, Subscription } from "rxjs";
import { Coordinate } from "../../../models/coordinate";
import { CostItem } from "../../../models/costItem";
import { ShotOverlay } from "../../../models/shotOverlay";
import { ShotOverlayDto } from "../../../models/shotOverlayDto";
import { ShotOverlayType } from "../../../models/shotOverlayType";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { resizeBase64Img } from "../shot-image/d3-image/d3-image.component";

import * as html2canvas from "html2canvas";
import { take } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";

export interface ColorBarTextInfo {
  sliceType: SliceType;
  min: number;
  max: number;
}

export const vpLowerBound = 1000;
export const vpUpperBound = 8000;

@Component({
  selector: "app-chart-slice",
  templateUrl: "./chart-slice.component.html",
  styleUrls: ["./chart-slice.component.less"],
})
export class ChartSliceComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("chart", { static: true }) chart: ElementRef;
  @Input() sliceType: SliceType;
  @Input() id: string;
  @Input() ranges: Ranges;
  @Input() iteration: number;
  @Input() timeSlice: number;
  @Input() shot: number;
  @Input() sliceNumber: number;
  @Input() updateChart: boolean;
  @Input() refreshChart: boolean;
  @Input() height: number = 0;
  @Input() is2d: boolean = false;
  @Input() xTitle: string;
  @Input() yTitle: string;
  @Input() xLine: number;
  @Input() yLine: number;
  @Input() showLegend: boolean = true;
  @Input() palette: string;
  @Input() xSteps: number;
  @Input() ySteps: number;
  @Input() set sonicLogId(id: string) {
    this._sonicLogId = id;
    this.loadChart();
  }
  @Input() dx: number;
  @Input() sonicNX: any;
  @Input() sonicWidth: number;
  @Input() hoverLabelX: string;
  @Input() hoverLabelY: string;
  @Input() velocityRange: number[];
  @Input() showShotOverlay: boolean = false;
  @Input() showHorizons: string[] = [];
  @Input() totalIterations: number;
  @Input() modelType: string;
  @Input() shotOverlayType: ShotOverlayType;
  @Input() x2IsInline: boolean = false;
  @Input() isVpReference: boolean;
  @Input() vpIteration: number;
  @Input() hideControls = false;
  @Input() horizonColors: string[];
  @Input() pngNameDetails;
  @Input() indexInDocument; // for selecting the correct element in ref in order to draw to canvas and download, currently not used
  // @Input() shotColourRange;
  @Input() shotRadius: number;
  @Input() showDottedLines: boolean = true;
  @Input() startDepth: number;
  @Input() endDepth: number;
  @Input() startInline: number;
  @Input() endInline: number;
  @Input() startCrossline: number;
  @Input() endCrossline: number;

  isLoading: boolean = true;
  hasError: boolean = false;
  d3HeatmapChart: D3Chart = null;
  errorMessage: string = null;

  plotDetail: PlotDetail;
  _imageBase64: string;
  private _isUnderRelayout: boolean = false;
  pngWidth: number = 1600;
  pngHeight: number = 900;
  private _legendImageBase64: string;
  private _updateChart: boolean;
  private _timeout: any = null;
  private _request: Subscription = null;
  private _imageRequest: Subscription = null;
  private _shotRequest: Subscription = null;
  private _sliceRequest: Subscription = null;
  private _logRequest: Subscription = null;
  private _waterBottomRequest: Subscription = null;
  private _resizeTimeout: any = null;
  private _sllceArray: Array<Array<number>> = null;
  private _logArray: Array<Coordinate> = null;
  private _modelArray: Array<Coordinate> = null;
  private _waterBottomArrays: Array<Array<Coordinate>> = null;
  private _shotOverlayData: any;
  private _shotOverlayError: any;
  private _shotOverlayMin: number;
  private _shotOverlayMax: number;
  private _shotModels: string[] = [
    "ForwardWavefield",
    "BackwardWavefield",
    "grad",
    "den",
    "dens",
  ];
  private _timeSliceModels: string[] = [
    "ForwardWavefield",
    "BackwardWavefield",
  ];
  private _currentSonic = null;
  private _deltaModels = ["Total_Vp_Update", "Gradient"];
  private _sonicLogId: string = null;

  @Output() clickEmitter = new EventEmitter<number>(); // emits slice/depth number clicked
  clickSubscription = null;
  @Output() colorbarTextEmitter = new EventEmitter<ColorBarTextInfo>(); // emits color bar text for vp x-axis
  @Output() loadFinishedEmitter = new EventEmitter<any>(); // emits when get slice finishes, and tell model page to scroll

  //for normalizing shot functional value
  scaleRatio: number;

  constructor(
    private projectService: CachedProjectService,
    private imageService: ImageLoaderService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribeHandlers();
  }

  unsubscribeHandlers() {
    if (this._request) {
      this._request.unsubscribe();
      this._request = null;
    }
    if (this._imageRequest) {
      this._imageRequest.unsubscribe();
      this._imageRequest = null;
    }
    if (this._sliceRequest) {
      this._sliceRequest.unsubscribe();
      this._sliceRequest = null;
    }
    if (this._logRequest) {
      this._logRequest.unsubscribe();
      this._logRequest = null;
    }
    if (this._shotRequest) {
      this._shotRequest.unsubscribe();
      this._shotRequest = null;
    }
    if (this._waterBottomRequest) {
      this._waterBottomRequest.unsubscribe();
      this._waterBottomRequest = null;
    }
    if (this.clickSubscription) {
      this.clickSubscription.unsubscribe();
      this.clickSubscription = null;
    }
  }

  onResize(event) {
    if (
      !this.chart ||
      !this.chart.nativeElement ||
      !this.chart.nativeElement.firstChild
    )
      return;

    if (this._resizeTimeout) {
      clearTimeout(this._resizeTimeout);
    }

    this._resizeTimeout = setTimeout(() => {
      var layout = {
        width: this.chart.nativeElement.offsetWidth,
        height:
          this.height > 0 ? this.height : this.chart.nativeElement.offsetHeight,
      };

      if (this.d3HeatmapChart) {
        this.d3HeatmapChart.attrs.resized = true;
        this.updatePlot();
      }

      this._resizeTimeout = null;
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    let delay = 0;
    if (Object.keys(changes).includes("modelType")) {
      this.modelType = changes.modelType.currentValue;
    }
    if (Object.keys(changes).includes("palette")) {
      this.palette = changes.palette.currentValue;
    }

    for (let propName in changes) {
      if (
        propName == "updateChart" &&
        changes[propName].currentValue === true
      ) {
        this._updateChart = true;
        this.loadChart();
        return;
      } else if (
        propName == "shot" ||
        propName == "timeSlice" ||
        propName == "iteration" ||
        propName == "sliceNumber" ||
        propName == "id" ||
        propName == "palette" ||
        propName == "showShotOverlay" ||
        propName == "showHorizons" ||
        propName == "modelType" ||
        propName == "shotOverlayType" ||
        propName == "x2IsInline" ||
        propName == "dx" ||
        propName == "vpUpdateCheck"
      ) {
        this.loadChart();
        if (propName == "modelType" || propName == "palette") {
          this.updatePlot();
        }
        return;
      } else if (
        propName == "xLine" ||
        propName == "yLine" ||
        propName == "showLegend" ||
        propName == "height" ||
        propName == "shotRadius" ||
        propName == "shotOverlayType"
      ) {
        this.updatePlot();
        return;
      } else if (propName == "refreshChart") {
        this.onResize(null);
        return;
      }
    }
  }

  resetClicked() {
    this.d3HeatmapChart.reset();
  }

  zoomInClicked() {
    this.d3HeatmapChart.zoomIn();
  }

  zoomOutClicked() {
    this.d3HeatmapChart.zoomOut();
  }

  public loadChart() {
    if (!this.id || this.iteration == null || (!this.sliceNumber && !this.is2d))
      return;

    if (this._shotModels.includes(this.modelType) && !this.shot) {
      this.errorMessage = "Please specify shot number.";
      this.isLoading = false;
      this.hasError = true;
      this._imageBase64 = null;
      return;
    }

    if (this._timeSliceModels.includes(this.modelType) && !this.timeSlice) {
      this.errorMessage = "Please specify time slice.";
      this.isLoading = false;
      this.hasError = true;
      this._imageBase64 = null;
      return;
    }

    this.hasError = false;
    this.isLoading = true;

    if (this._timeout) {
      clearTimeout(this._timeout);
    }

    this._timeout = setTimeout(() => {
      this.unsubscribeHandlers();

      // var hasVelocity = (this.velocityRange && this.velocityRange.length == 2 && this.velocityRange[0] && this.velocityRange[1]);

      let baseSlice = [];

      // if (hasVelocity) {
      //   if (this.velocityRange[0] != null)
      //     this.velocityRange[0] = isNaN(this.velocityRange[0]) || Number.isNaN(this.velocityRange[0]) ? null : this.velocityRange[0]
      //   if (this.velocityRange[1] != null)
      //     this.velocityRange[1] = isNaN(this.velocityRange[1]) || Number.isNaN(this.velocityRange[1]) ? null : this.velocityRange[1]
      // }

      this._request =
        // this.projectService.getSlice(this.id, 'Vp', 0, this.sliceType, this.sliceNumber, this.is2d, this.palette, this.velocityRange[0], this.velocityRange[1], this.shot, 1, this.isVpReference ? this.vpIteration : 0, this.timeSlice)
        //   .switchMap( res => {
        //     return this.projectService.getSliceArray(res.sliceUrl, this.ySteps, this.xSteps, res.trueMin, res.trueMax)
        //   })
        //   .switchMap(v => {
        //     if (this.sliceType == SliceType.depth && this.sonicNX) {
        //       let loc = this.sonicNX.find(l => l[2]);
        //       this._currentSonic = v[loc[1]][loc[0]];
        //     }else
        //       v.forEach((data) => baseSlice.push(data[this.sonicNX]));

        //     return
        this.projectService
          .getSlice(
            this.id,
            this.modelType,
            this.iteration,
            this.sliceType,
            this.sliceNumber,
            this.is2d,
            this.palette,
            this.velocityRange[0],
            this.velocityRange[1],
            this.shot,
            1,
            this.isVpReference ? this.vpIteration : 0,
            this.timeSlice
          )
          .pipe(take(1))
          .subscribe(
            (p) => {
              if (!p) {
                this.errorMessage = "Model file does not exist";
                this.hasError = true;
                this.clearImage();
                this._updateChart = false;
                this.updateChart = false;
                this.isLoading = false;
                return;
              }
              var images: Array<Observable<any>> = null;

              if (!!this._sonicLogId && p) {
                images = [
                  this.imageService.getImageBase64(p.url),
                  this.imageService.getImageBase64(p.colorBarUrl),
                  this.projectService.getSliceArray(
                    p.sliceUrl,
                    this.ySteps,
                    this.xSteps,
                    p.trueMin,
                    p.trueMax
                  ),
                  this.projectService.getSonicLog(this._sonicLogId),
                ];
              } else
                images = [
                  this.imageService.getImageBase64(p.url),
                  this.imageService.getImageBase64(p.colorBarUrl),
                ];

              this._imageRequest = forkJoin(...images)
                .pipe(take(1))
                .subscribe(
                  (i) => {
                    this._imageBase64 = i[0];
                    this._legendImageBase64 = i[1];
                    this._sllceArray = null;
                    this._logArray = null;
                    this._modelArray = null;
                    this._shotOverlayData = null;
                    this._shotOverlayError = null;
                    this._waterBottomArrays = null;

                    if (!!this._sonicLogId) {
                      this.getSonicLogs(i[2], i[3], baseSlice);
                    }

                    if (!!this.showShotOverlay) {
                      forkJoin([
                        this.projectService.getCosts(
                          this.id,
                          this.totalIterations
                        ),
                        this.projectService.getShotOverlay(
                          this.id,
                          this.iteration === 0 ? 1 : this.iteration
                        ),
                        this.projectService.getShotOverlay(this.id, 1),
                      ])
                        .pipe(take(1))
                        .subscribe(
                          (i) => {
                            var costs: CostItem[] = i[0].costItems;
                            var shotOverlays: ShotOverlayDto[] = i[1];
                            var firstIter: ShotOverlayDto[] = i[2];
                            // console.log(costs, shotOverlays, firstIter);
                            if (firstIter) {
                              var firstShots = firstIter.map((s) => s["fnlc"]);
                              this.scaleRatio = Math.round(
                                Math.max(...firstShots)
                              );
                            }
                            if (costs && shotOverlays) {
                              var shots = shotOverlays.map((s) => {
                                var costItem = costs.find(
                                  (c) => c.shotNo == s.shot_id
                                );
                                return {
                                  shotFit: this.getShotOverlayProperty(s),
                                  shotFitOriginal:
                                    this.getShotOverlayProperty(s),
                                  y: costItem
                                    ? this.x2IsInline
                                      ? costItem.sx
                                      : costItem.sy
                                    : null,
                                  x: costItem
                                    ? this.x2IsInline
                                      ? costItem.sy
                                      : costItem.sx
                                    : null,
                                  shot_id: s.shot_id,
                                };
                              });
                              this._shotOverlayMin = Math.round(
                                Math.min(...shots.map((s) => s.shotFit))
                              );
                              this._shotOverlayMax = Math.round(
                                Math.max(...shots.map((s) => s.shotFit))
                              );
                              this._shotOverlayData = shots;
                              if (this.shotOverlayType.id === "fnlc") {
                                for (let i in this._shotOverlayData) {
                                  this._shotOverlayData[i].shotFit = Number(
                                    (
                                      (100 * this._shotOverlayData[i].shotFit) /
                                      this.scaleRatio
                                    ).toFixed(2)
                                  );
                                }
                              }
                            } else {
                              this._shotOverlayData = null;
                              this._shotOverlayError = "No shot data";
                            }

                            this.updatePlot();
                          },
                          () => {
                            this._shotOverlayData = null;
                            this._shotOverlayError = "No shot data";
                            this.updatePlot();
                          }
                        );
                    }
                    if (!!this.showHorizons && this.showHorizons.length > 0) {
                      var horizonRequests = this.showHorizons.map((h) => {
                        let param = new URLSearchParams();
                        param.set("name", h);
                        let encoded_h = param.toString();
                        // console.log(encoded_h)
                        return this.projectService.getHorizon(
                          this.id,
                          this.sliceNumber,
                          this.sliceType,
                          encoded_h
                        );
                      });
                      this._waterBottomRequest = forkJoin(horizonRequests)
                        .pipe(take(1))
                        .subscribe(
                          (responses) => {
                            this._waterBottomArrays = [];
                            for (var d of responses) {
                              var horizonArray = [];
                              d.forEach((data, idx) => {
                                horizonArray.push({
                                  x: idx * this.dx,
                                  y: data * this.dx,
                                });
                              });
                              this._waterBottomArrays.push(horizonArray);
                            }
                            this.updatePlot();
                          },
                          () => {
                            // console.log("HORIZON REQUEST FAILED")
                            this.showHorizons = [];
                            this._waterBottomArrays = null;
                            this.updatePlot();
                          }
                        );
                    }

                    if (!!p) {
                      this.plotDetail = p;
                      console.log(
                        `ModelType: ${this.modelType} Slice: ${this.sliceType} MIN AND MAX VALUES FROM THE REQUEST IS`,
                        [this.plotDetail.min, this.plotDetail.max]
                      );

                      this.colorbarTextEmitter.emit({
                        sliceType: this.sliceType,
                        min: this.plotDetail.min,
                        max: this.plotDetail.max,
                      });
                      this.updatePlot();
                      this._updateChart = false;
                      this.updateChart = false;
                      this.isLoading = false;
                    }

                    this._sliceRequest = this.projectService
                      .getSliceArray(
                        p.sliceUrl,
                        this.ySteps,
                        this.xSteps,
                        p.trueMin,
                        p.trueMax
                      )
                      .pipe(take(1))
                      .subscribe((d) => {
                        this._sllceArray = d;
                        this.updatePlot();
                        this.loadFinishedEmitter.emit({
                          dim: this.sliceType,
                          num: this.sliceNumber,
                        });
                      });
                  },
                  (err) => {
                    this.errorMessage =
                      "Sorry there was an error loading the slice";

                    this.hasError = true;
                    this.clearImage();
                    this._updateChart = false;
                    this.updateChart = false;
                    this.isLoading = false;
                  }
                );
            },
            (err: HttpErrorResponse) => {
              if (!err.statusText)
                this.errorMessage = "Sorry the request timed out";
              else if (
                this._deltaModels.includes(this.modelType) &&
                this.iteration == 0 &&
                this._sonicLogId
              ) {
                this.projectService
                  .getSonicLog(this._sonicLogId)
                  .pipe(take(1))
                  .subscribe((log) => {
                    if (!log) return;
                    this.getSonicLogs([], log, baseSlice);
                    this.plotDetail = {
                      url: null,
                      colorBarUrl: null,
                      min: 0,
                      max: 1,
                      sliceUrl: null,
                      trueMin: null,
                      trueMax: null,
                    };
                    this.updatePlot();
                  });
                this.errorMessage = null;
              } else if (err.error && err.error.detail)
                this.errorMessage = err.error.detail;
              else if (err && err.message) this.errorMessage = err.message;
              else
                this.errorMessage =
                  "Sorry there was an error loading the slice";

              this.hasError = true;
              this.clearImage();
              this.updateChart = false;
              this._updateChart = false;
              this.isLoading = false;
            }
          );

      this._timeout = null;
    }, 250);
  }

  private getSonicLogs(modelSlice, logArray, baseSlice) {
    this._sllceArray = modelSlice;
    this._logArray = logArray;

    if (this.sliceType != SliceType.depth) {
      var realMax = Math.ceil(Math.max(...this.ranges.y));
      this._logArray = this._logArray.filter((data) => data.x <= realMax);
      var xValues = this._logArray.map((data) => data.x);
      var min = Math.min(...xValues);
      var max = Math.max(...xValues);

      var minDepth = Math.floor(min / this.dx);
      var maxDepth = Math.ceil(max / this.dx);

      var modelArray = [];
      if (this._sllceArray.length) {
        this._sllceArray.forEach((data, idx) => {
          modelArray.push({ x: idx * this.dx, y: data[this.sonicNX] });
        });
        modelArray.splice(0, minDepth - 7);
        if (modelArray.length > maxDepth - minDepth + 12)
          modelArray.splice(maxDepth - minDepth + 12);
      } else {
        modelArray = this._logArray.map((l) => {
          return { x: l.x, y: 0 };
        });
      }

      this._modelArray = modelArray;

      if (this._deltaModels.includes(this.modelType)) {
        let sonicDiff = [];
        for (let i = 0; i < baseSlice.length - 1; ++i) {
          let s = baseSlice[i];
          let e = baseSlice[i + 1];
          let step = (e - s) / this.dx;
          this._logArray
            .filter((l) => Math.floor(l.x / this.dx) == i)
            .forEach((p) =>
              sonicDiff.push({ x: p.x, y: p.y - ((p.x % this.dx) * step + s) })
            );
        }
        this._logArray = sonicDiff;
      }
    }
  }

  private getShotOverlayProperty(item: ShotOverlayDto) {
    return item[this.shotOverlayType.id];
  }

  private getShotOverlayTitle() {
    return this.shotOverlayType.name;
  }

  private clearImage() {
    if (
      !this.chart ||
      !this.chart.nativeElement ||
      !this.chart.nativeElement.firstChild
    )
      return;

    this._imageBase64 = null;
    this._shotOverlayData = null;
    this.updatePlot();
  }

  private updatePlot() {
    // if (this.clickSubscription != null) {
    //   this.clickSubscription.unsubscribe()
    // }
    if (!this.plotDetail) return;

    var range = [this.ranges.y[0], this.ranges.y[1]];
    var reversed = true;

    if (this.ranges.y[0] > this.ranges.y[1]) {
      range = [this.ranges.y[1], this.ranges.y[0]];
      reversed = false;
    }

    var layout = {
      width: this.chart.nativeElement.offsetWidth,
      height:
        this.height > 0 ? this.height : this.chart.nativeElement.offsetHeight,
      yaxis: {
        autorange: reversed ? "reversed" : null,
        range: [this.ranges.y[0], this.ranges.y[1]],
        tickformat: ":04,2f",
        title: this.yTitle,
        titlefont: {
          size: 10,
        },
      },
      xaxis: {
        range: [this.ranges.x[0], this.ranges.x[1]],
        tickformat: ":04,2f",
        title: this.xTitle,
        titlefont: {
          size: 10,
        },
      },
      margin: {
        l: 60,
        r: 50,
        b: 50,
        t: 10,
        pad: 4,
      },
      images: [
        {
          source: this._imageBase64,
          xref: "x",
          yref: "y",
          x: this.ranges.x[0],
          y: this.ranges.y[0],
          sizex: this.ranges.x[1] - this.ranges.x[0],
          sizey: this.ranges.y[1] - this.ranges.y[0],
          xanchor: "left",
          yanchor: "bottom",
          sizing: "stretch",
          layer: "above",
        },
      ],
    };

    if (!this.d3HeatmapChart) {
      this.d3HeatmapChart = new D3Chart();
    }

    var attrs = this.d3HeatmapChart.attrs;
    attrs.container = this.chart.nativeElement;
    attrs.svgHeight = layout.height;
    attrs.svgWidth = layout.width;
    attrs.data = this._imageBase64;
    attrs.legendImage = this._legendImageBase64;
    attrs.legendVisibility = true;
    attrs.startDepth = this.startDepth;
    attrs.endDepth = this.endDepth;
    if (!this.x2IsInline && this.sliceType == SliceType.depth) {
      attrs.startInline = this.startCrossline;
      attrs.endInline = this.endCrossline;
      attrs.startCrossline = this.startInline;
      attrs.endCrossline = this.endInline;
    } else {
      attrs.startInline = this.startInline;
      attrs.endInline = this.endInline;
      attrs.startCrossline = this.startCrossline;
      attrs.endCrossline = this.endCrossline;
    }

    attrs.sliceType = this.sliceType;
    attrs.yScale = <any>{ maxDomain: range[1] };
    attrs.xScale = <any>{ maxDomain: layout.xaxis.range[1] };
    attrs.yLegend = <any>{
      minDomain: this.plotDetail.min,
      maxDomain: this.plotDetail.max,
    };
    attrs.xTitle = this.xTitle;
    attrs.yTitle = this.yTitle;
    attrs.dotLineX = this.xLine;
    attrs.dotLineY = this.yLine;
    attrs.dotLineVisibility = this.showDottedLines;
    attrs.legendVisibility = this.showLegend;
    attrs.reverseY = reversed;
    attrs.dataMatrix = this._sllceArray;
    attrs.solidLineData = null;
    attrs.lightLineData = null;
    attrs.shotOverlayPointRadius =
      this.shotRadius != null ? this.shotRadius : 2;
    // below changes the lines of plot to black or white for improved visibility
    if (["red_white_blue"].includes(this.palette)) {
      attrs.dotted_line_color = "black";
    } else {
      attrs.dotted_line_color = "white";
    }

    if (this.sliceType != SliceType.depth && this.sonicNX != null) {
      attrs.lightLineData = this._logArray;
      attrs.solidLineData = this._modelArray;
    } else {
      if (
        this.sonicNX &&
        this.sonicNX.length &&
        this._sonicLogId &&
        this._logArray
      ) {
        attrs.solidDotData = this.sonicNX.map((loc) => {
          let sonicVal = NaN;
          let modelVal = this._sllceArray.length
            ? this._sllceArray[loc[1]][loc[0]]
            : 0;
          if (loc[2]) {
            let closestRecord = this._logArray.reduce(
              (a, l) => {
                let dist = Math.abs(l.x - this.sliceNumber * this.dx);
                if (dist < a[0]) return [dist, l.y];
                return a;
              },
              [Infinity, NaN]
            );
            sonicVal = this._deltaModels.includes(this.modelType)
              ? closestRecord[1] - this._currentSonic
              : closestRecord[1];
          }
          return {
            x: loc[0] * this.dx,
            y: loc[1] * this.dx,
            selected: loc[2],
            sonic: sonicVal,
            model: modelVal,
          };
        });
      } else {
        attrs.solidDotData = null;
      }
    }

    attrs.hoverLabelX = this.hoverLabelX;
    attrs.hoverLabelY = this.hoverLabelY;
    attrs.shotsOverlayLegendsVisibility = this.showShotOverlay;
    attrs.shotOverlayData = this._shotOverlayData;
    attrs.shotsOverlayErrorMessage = this._shotOverlayError;
    attrs.shotOverlayColorRange = ["black", "white"];
    attrs.shotLegend = {
      minDomain:
        this.shotOverlayType.min == null
          ? this._shotOverlayMin
          : this.shotOverlayType.min,
      maxDomain:
        this.shotOverlayType.max == null
          ? this._shotOverlayMax
          : this.shotOverlayType.max,
      text: this.getShotOverlayTitle(),
    };
    attrs.normalLineData = this._waterBottomArrays;
    attrs.gridSpacing = this.dx;

    if (this.modelType === "Vp") attrs.legendTitle.text = "velocity";
    else if (this.modelType === "Gradient") attrs.legendTitle.text = "gradient";
    else if (this.modelType === "InvVp Update")
      attrs.legendTitle.text = "slowness update";
    else {
      if (this.modelType == null) {
        console.error("modelType is null");
        this.hasError = true;
        this.clearImage();
        this._updateChart = false;
        this.updateChart = false;
        this.isLoading = false;
        return;
      }
      attrs.legendTitle.text = this.modelType
        .split("_")
        .join(" ")
        .toLowerCase();
    }

    if (this.modelType === "Vp") attrs.legendTitle.unit = "(m/s)";
    else if (this.modelType === "Gradient") attrs.legendTitle.unit = "(s/m)";
    else attrs.legendTitle.unit = "";

    if (this._logArray && this._modelArray) {
      var xValues = this._modelArray.map((data) => data.x);
      var min = Math.min(...xValues);
      var max = Math.max(...xValues);

      attrs.lineOverlayY = Math.round(min);
      attrs.lineOverlayX = this.sonicNX * this.dx;
      attrs.lineOverlayHeight = Math.round(max - min);
      attrs.lineOverlayWidth = this.sonicWidth;

      if (this._deltaModels.includes(this.modelType))
        attrs.zeroLineOffset = Math.abs(
          Math.min(...this._logArray.map((l) => l.y))
        );
      else attrs.zeroLineOffset = null;
    }

    if (this._deltaModels.includes(this.modelType))
      attrs.normalLineColor = "black";
    else attrs.normalLineColor = "white";

    attrs.normalLineColors = this.horizonColors;

    setTimeout(async () => {
      await this.d3HeatmapChart.run();
    }, 0);
    this.subscribeToClick();
  }

  downloadPng() {
    // const elements = document.getElementsByClassName('chart-container')
    // console.log(elements)
    // // console.log(this.indexInDocument)
    // const element = elements[this.indexInDocument]
    // html2canvas(element).then(canvas => {
    //   let url = canvas.toDataURL()
    //   if (this.pngHeight != null && this.pngWidth != null) {
    //     resizeBase64Img(url, this.pngWidth, this.pngHeight).then((resizedImage:string) => {
    //       const link = document.createElement("a");
    //       link.href = resizedImage;
    //       link.download = this.getpngNameStr()
    //       link.click();
    //     })
    //   } else {
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = this.getpngNameStr();
    //     link.click();
    //   }

    // })
    resizeBase64Img(this._imageBase64, this.pngWidth, this.pngHeight).then(
      (resizedImage: string) => {
        const link = document.createElement("a");
        link.href = resizedImage;
        link.download = this.getpngNameStr();
        link.click();
      }
    );
  }

  getpngNameStr() {
    let pDs = this.pngNameDetails;
    let parts = [
      pDs.pName.substring(0, 6),
      pDs.jName.substring(0, 6),
      "CP" + pDs.cpnum.toString(),
      this.sliceType != null ? this.sliceTypeToString(this.sliceType) : null,
      this.sliceNumber != null ? this.sliceNumber.toString() : null,
      this.modelType != null ? this.modelType : null,
    ];
    let str = "";
    for (let i = 0; i < parts.length; i++) {
      if (parts[i] == null) continue;
      if (i != 0) {
        str = str + "-";
      }
      str = str + stripDash(parts[i]);
    }
    return str + ".png";
  }

  sliceTypeToString(sliceType: SliceType) {
    const mapping = ["", "inline", "crossline", "depth"];
    return mapping[sliceType];
  }

  subscribeToClick() {
    if (this.clickSubscription == null) {
      this.clickSubscription = this.d3HeatmapChart.clickedPosition.subscribe(
        (val) => {
          this.clickEmitter.emit(val);
        }
      );
    }
  }
}

export const stripDash = function (str: string) {
  if (str.startsWith("-")) {
    return stripDash(str.substring(1));
  } else if (str.endsWith("-")) {
    return stripDash(str.substring(0, str.length - 1));
  }
  return str;
};
