<div class="container-fluid">
  <div>
    <button nz-button nzType="primary" (click)="createModalVisible = true">
      Create Job Group
    </button>
  </div>

  <div>
    <nz-table
      #filterTable
      [nzData]="this.jobGroups"
      nzTableLayout="fixed"
      [nzLoading]="isLoading"
      nzSize="small"
      nzShowPagination
      nzShowSizeChanger
    >
      <thead>
        <tr>
          <th nzWidth="35px"></th>
          <!-- the first th is for the nzExpand -->
          <ng-container *ngFor="let column of this.listOfColumns">
            <th
              [nzSortOrder]="column.sortOrder"
              [nzSortFn]="column.sortFn"
              [nzSortDirections]="column.sortDirections"
              [nzFilterMultiple]="column.filterMultiple"
              [nzWidth]="column.width"
            >
              {{ column.name }}
            </th>
          </ng-container>
          <th nzWidth="150px">Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of filterTable.data">
          <tr>
            <td [(nzExpand)]="data.expand"></td>
            <td>
              {{ data.name }}
            </td>
            <td>
              {{ data.comment }}
            </td>
            <td>
              {{ data.created_by_name }}
            </td>
            <td>
              <div style="display: flex; gap: 10px">
                <a
                  nz-popconfirm
                  nzPopconfirmTitle="Are you sure you want to Delete this group?"
                  (nzOnConfirm)="deleteGroup(data)"
                  nz-tooltip
                  nzTooltipTitle="Delete Group"
                >
                  <span
                    nz-icon
                    nzType="delete"
                    nzTheme="outline"
                    style="font-size: larger"
                  ></span>
                </a>
                <a (click)="editGroup(data)">
                  <span
                    nz-icon
                    nzType="edit"
                    nzTheme="outline"
                    style="font-size: larger"
                    nz-tooltip
                    nzTooltipTitle="Edit group"
                  ></span>
                </a>
                <a (click)="openGroup(data)">
                  <span
                    nz-icon
                    nzType="export"
                    nzTheme="outline"
                    style="font-size: larger"
                    nz-tooltip
                    nzTooltipTitle="Open group in Model View"
                  ></span>
                </a>
                <a (click)="openGroupInParameterView(data)">
                  <i
                    style="font-size: 20px"
                    class="zmdi zmdi-filter-list"
                    nz-tooltip
                    nzTooltipTitle="Open group in Parameter view"
                  >
                  </i>
                </a>
                <a (click)="openGroupInTracefitsView(data)">
                  <span
                    nz-icon
                    nzType="bar-chart"
                    nzTheme="outline"
                    style="font-size: larger"
                    nz-tooltip
                    nzTooltipTitle="Open group in Tracefits plotting view"
                  ></span>
                </a>
              </div>
            </td>
          </tr>
          <tr [nzExpand]="data.expand">
            <app-job-groups-inner-table
              [jobs]="data.jobs"
              (removeJob)="removeJobFromGroup($event)"
            ></app-job-groups-inner-table>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-modal
  [(nzVisible)]="createModalVisible"
  [nzTitle]="edittingGroupId ? 'Edit Group' : 'Create Group'"
  (nzOnCancel)="createModalVisible = false; resetFields()"
>
  <ng-container *nzModalContent>
    <nz-spin [nzSpinning]="this.isLoading">
      <div class="form-container">
        <p style="margin-bottom: 20px">Job groups for quick access</p>
        <nz-form-item style="padding: 0">
          <nz-form-label nzRequired style="left: 10px"
            >Group Name</nz-form-label
          >
          <nz-form-control nzErrorTip="Group Name is a required field">
            <input nz-input type="text" [formControl]="this.nameControl" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="padding: 0">
          <nz-form-label style="left: 10px">Comment</nz-form-label>
          <nz-form-control>
            <textarea
              nz-input
              type="text"
              [formControl]="this.commentControl"
            ></textarea>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="padding: 0">
          <nz-form-label nzRequired style="left: 10px"
            >Select Jobs</nz-form-label
          >
          <nz-form-control nzErrorTip="You must select 1 or more jobs">
            <nz-select
              nzShowSearch
              nzMode="multiple"
              style="width: 100%"
              [formControl]="this.jobsControl"
              [nzLoading]="this.gettingParentJobs"
            >
              <nz-option
                *ngFor="let job of this.parentJobs"
                [nzLabel]="job.job_name"
                [nzValue]="job.id"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </nz-spin>
  </ng-container>
  <div *nzModalFooter>
    <button
      nz-button
      nzType="default"
      (click)="createModalVisible = false; resetFields()"
      [disabled]="this.isLoading"
    >
      Cancel
    </button>
    <button
      nz-button
      nzType="primary"
      (click)="createGroup()"
      [disabled]="this.isLoading"
    >
      Save
    </button>
  </div>
</nz-modal>
