import { Component, Input, OnInit } from "@angular/core";
import { JobDetail } from "../../../models/jobDetail";
import { ShotSettings } from "../../../models/shotSettings";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { StoredSettingsService } from "../../services/stored-settings.service";

@Component({
  selector: "app-shot-select-form",
  templateUrl: "./shot-select-form.component.html",
  styleUrls: ["./shot-select-form.component.less"],
})
export class ShotSelectFormComponent implements OnInit {
  @Input() jobDetail: JobDetail;
  @Input() settingsKey: string;
  @Input() selectedShot: number;
  @Input() startTrace: number;
  @Input() endTrace: number;
  constructor(private storedSettingsService: StoredSettingsService) {}

  ngOnInit() {}
  onKeySubmission(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.onReplotClicked();
    }
  }
  onReplotClicked() {
    this.storeSettings();
  }

  storeSettings() {
    let settings = this.storedSettingsService.getSettings<ShotSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Shot
    );

    const updatedSettings = {
      ...settings,
      startTraceManual: this.startTrace,
      endTraceManual: this.endTrace,
    };

    this.storedSettingsService.setSettings<ShotSettings>(
      this.settingsKey,
      updatedSettings,
      SettingTypes.Shot
    );
    this.storedSettingsService.settingsUpdated([SettingTypes.Shot]);
  }
}
