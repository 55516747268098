import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CachedProjectService } from "../../shared/services/cached-project.service";
import { Title } from "@angular/platform-browser";
import { FormBuilder, NonNullableFormBuilder } from "@angular/forms";
import { ProjectService } from "../../shared/services/project.service";
import { entries } from "lodash";
import { take, takeUntil } from "rxjs/operators";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ReplaySubject } from "rxjs";
import { NzOptionComponent } from "ng-zorro-antd/select";
import { AuthService } from "../../shared/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { ProjectNameComponent } from "../project/dialogs/project-name/project-name.component";
import { Project } from "../../models/project";
import { ProjectDialog } from "../../models/projectDialog";

@Component({
  selector: "app-project-view",
  templateUrl: "./project-view.component.html",
  styleUrls: ["./project-view.component.less"],
})
export class ProjectViewComponent implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1); // used to unsubscribe to all subscriptions on destroy

  project: Project;
  project_id: string = "";
  project_name: string = "";
  project_description: string = "";

  add_job_modal: boolean = false;
  job_entries: Array<any> = [];
  isLoading: boolean = false;
  parentJobs: Array<any> = [];
  parent_max_iteration: number = 0;

  constructor(
    private route: ActivatedRoute,
    private cachedProjectService: CachedProjectService,
    private titleService: Title,
    private fb: FormBuilder,
    private projectService: ProjectService,
    public authService: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private notificationService: NzNotificationService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.cachedProjectService
          .getProjectById(params.id)
          .toPromise()
          .then((data) => {
            this.project = data;
            this.project_id = data["id"];
            this.getParentJobs();
            this.project_name = data["name"];
            this.project_description = data["description"];
            this.cachedProjectService.setCurrentProject(data);
            this.titleService.setTitle(this.project_name);
          });
      }
    });

    this.job_entries.push({
      jobpath: this.fb.control(""),
      parent: this.fb.control(null),
      parent_cp: this.fb.control(null),
      x2_is_inline: this.fb.control(false),
    });

    this.job_entries[0].parent.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (val) => {
          this.parent_max_iteration = val.iterations_completed;
        },
        error: (err) => {
          console.log(err);
          this.notificationService.error("Error", err);
        },
      });
  }

  searchFn(searchString: string, option: NzOptionComponent) {
    return String(option.nzLabel)
      .toLocaleLowerCase()
      .replace(/_|-/g, " ")
      .includes(searchString.toLocaleLowerCase());
  }

  getParentJobs() {
    this.cachedProjectService
      ._getProjectJobs(this.project_id, {
        fields: ["id", "job_name", "iterations_completed", "project__name"],
      })
      .toPromise()
      .then((res) => {
        this.parentJobs = res.data.sort((a, b) =>
          String(a.job_name).localeCompare(b.job_name)
        );
      })
      .catch((err) => console.log(err));
  }

  addJobsToProject() {
    let entries = this.job_entries.map((j) => ({
      path: j.jobpath.value,
      parent_job_id: j.parent.value?.id,
      parent_cp_num: j.parent_cp.value || null,
      x2_is_inline: j.x2_is_inline.value,
    }));
    this.isLoading = true;
    this.projectService
      .createJobs(this.project_id, entries)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.notificationService.success(
            "Success",
            `Job was successfully added to the project`
          );
          this.job_entries = [
            {
              jobpath: this.fb.control(""),
              parent: this.fb.control(null),
              parent_cp: this.fb.control(null),
              x2_is_inline: this.fb.control(false),
            },
          ];
          this.getParentJobs();
          this.isLoading = false;
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error || "An unexpected error occured"
          );
          this.isLoading = false;
        },
      });
  }

  addEntry() {
    this.job_entries.push({
      jobpath: this.fb.control(""),
      parent: this.fb.control(null),
      parent_cp: this.fb.control(null),
      x2_is_inline: this.fb.control(false),
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  openProjectDialog() {
    let dialogRef = this.dialog.open(ProjectNameComponent, {
      width: "400px",
      data: {
        id: this.project.id,
        name: this.project_name,
        description: this.project_description,
        field: this.project.field,
      },
    });

    dialogRef.afterClosed().subscribe((result: ProjectDialog) => {
      if (!result) return;
      this.projectService.updateProjectDetails(result).subscribe(
        (data) => {
          this.project.name = result.name;
          this.project.description = result.description;
          this.project.field = result.field || null;
          this.project_name = result.name;
          this.project_description = result.description;

          this.cachedProjectService.clearProjectsCache();
          this.cachedProjectService.clearJobDetailCache(
            this.project.jobs.map((j) => j.id)
          );
          this.snackBar.open("Project details updated", null, {
            duration: 2000,
          });
        },
        (error) => {
          this.snackBar.open(
            "Sorry, the project details could not be updated",
            null,
            { duration: 2000 }
          );
        }
      );
    });
  }
}
