<div class="container-fluid">
  <div *ngIf="!currentJob" class="loading">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="loading-container">
              <mat-progress-spinner
                mode="indeterminate"
                diameter="25"
              ></mat-progress-spinner>
              <div class="text">Loading...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="margin: 0px" *ngIf="!!currentJob" [@fadeInOut]>
    <div class="col-md-12" style="padding: 0px">
      <div class="card" id="slice-settings" style="margin-bottom: 0px">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 total-costs">
              <div class="cost-details row" *ngIf="!!currentJob">
                <div class="col-md-3">
                  <div class="des-box blue">
                    <div class="des-icon">
                      <img src="/assets/icons/costs/icon02.png" alt="" />
                    </div>
                    <div class="des-detail">
                      <h2>Iterations</h2>
                      <p>{{ iteration }} / {{ currentJob.iterations }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="slice-settings" class="col-md-12">
              <h4 class="card-title">Slice Settings</h4>
              <div class="row">
                <div class="col-md-6" style="padding: 0">
                  <div class="sliders form-horizontal">
                    <div *ngIf="!currentJob.modelGrid.is2d">
                      <div
                        class="form-group"
                        style="display: flex; align-items: center"
                      >
                        <div class="col-xs-12 pink-slider">
                          <app-slider
                            label="Inline"
                            [antd]="true"
                            [min]="1"
                            [max]="this.currentJob.modelGrid.nx1"
                            [step]="steps[0]"
                            [(value)]="inlineNumber"
                            (valueChange)="onSliderChanged()"
                          >
                          </app-slider>
                        </div>
                      </div>
                      <div
                        class="form-group"
                        style="display: flex; align-items: center"
                      >
                        <div class="col-xs-12 pink-slider">
                          <app-slider
                            label="Crossline"
                            [antd]="true"
                            [min]="1"
                            [max]="this.currentJob.modelGrid.nx2"
                            [step]="steps[1]"
                            [(value)]="crosslineNumber"
                            (valueChange)="onSliderChanged()"
                          >
                          </app-slider>
                        </div>
                      </div>
                      <div
                        class="form-group"
                        style="display: flex; align-items: center"
                      >
                        <div class="col-xs-12 pink-slider">
                          <app-slider
                            label="Depth Slice"
                            [antd]="true"
                            [min]="1"
                            [max]="this.currentJob.modelGrid.nx3"
                            [step]="steps[2]"
                            [(value)]="depthSlice"
                            (valueChange)="onSliderChanged()"
                          >
                          </app-slider>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-group iteration-slider"
                      style="display: flex; align-items: center"
                    >
                      <div class="col-xs-12">
                        <app-slider
                          label="Iteration"
                          [antd]="true"
                          [min]="0"
                          [max]="currentJob.iterations"
                          [completed]="currentJob.iterationsComplete"
                          [(value)]="iteration"
                          [step]="steps[3]"
                          (valueChange)="onSliderChanged(); clearModelInfo()"
                        >
                        </app-slider>
                      </div>
                    </div>
                    <div
                      class="form-group iteration-slider"
                      *ngIf="
                        timeSliceModels.includes(currentModelTypeId) &&
                        availTimeSlices.length > 0
                      "
                    >
                      <label class="col-sm-2">Time Slice</label>
                      <app-slider
                        class="col-sm-10"
                        [min]="availTimeSlices[0]"
                        [max]="availTimeSlices[availTimeSlices.length - 1]"
                        [(value)]="timeSlice"
                        [step]="getTimeSliceInterval()"
                        (valueChange)="onSliderChanged()"
                      ></app-slider>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 functional-sequence-table">
                  <nz-table
                    #functionalsTable
                    nzSize="middle"
                    id="functionals-table"
                    style="height: 340px"
                    [nzData]="dataSource"
                    [nzScroll]="{ y: '300px' }"
                    [nzFrontPagination]="false"
                    [nzShowPagination]="false"
                  >
                    <thead>
                      <tr>
                        <th>Block</th>
                        <th>Functional</th>
                        <th>Frequency</th>
                        <th>Iterations</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let data of functionalsTable.data"
                        [ngStyle]="{
                          opacity: data.block_started ? 1 : 0.5
                        }"
                      >
                        <td
                          [ngClass]="data.functional + ' block'"
                          [ngStyle]="{
                            'border-left-width': 15,
                            'border-left-color':
                              FUNCTIONAL_COLORS[data.functional],
                            opacity: data.block_started ? 1 : 0.5
                          }"
                        >
                          {{ data["block"] }}
                        </td>
                        <td>{{ data["functional"] }}</td>
                        <td>{{ data["frequency"].toFixed(2) }}</td>
                        <td>
                          <div>
                            <span>{{ data["iters"]["Iters"][0] }}</span>
                            <span>&nbsp;-&nbsp;</span>
                            <span>{{ data["iters"]["Iters"][1] }}</span>
                            <span
                              *ngIf="
                                data['functional'] == 'RWI' &&
                                data['iters']['RWI VS Iters'] != null &&
                                data['iters']['RWI BG Iters'] != null
                              "
                            >
                              &nbsp;(vs : {{ data["iters"]["RWI VS Iters"] }},
                              bg : {{ data["iters"]["RWI BG Iters"] }})
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="slices col-xs-12">
      <div class="loader" *ngIf="isLoading">
        <mat-progress-spinner
          mode="indeterminate"
          diameter="25"
        ></mat-progress-spinner>
      </div>
      <div id="slices">
        <div
          [ngClass]="{ 'fade-out': isLoading }"
          class="chart-slice-container"
          style="display: flex; gap: 10px; flex-wrap: wrap"
        >
          <ng-container *ngFor="let slice of slices; let i = index">
            <div
              [id]="'slice_id_' + i"
              class="chart-slice card"
              [style.width]="
                currentJob.modelGrid.is2d && !isMobile
                  ? '75%'
                  : getWidthForSliceType(slice.sliceType)
              "
              [style.order]="i + 1"
              *ngIf="!currentJob.modelGrid.is2d || i == 0"
              [style.height]="
                i == 2 ? depthHeight + 80 + 'px' : height + 80 + 'px'
              "
              [style.padding]="'10px'"
              [style.position]="'relative'"
            >
              <h4
                class="card-title"
                style="
                  margin-bottom: 0;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                {{ getTitleForSliceType(slice.sliceType) }}&emsp;&emsp;{{
                  getWellName(selectedSonicId)
                }}
                <div
                  [ngStyle]="{
                    visibility:
                      currentModelTypeId === 'Total_Vp_Update' && i == 0
                        ? 'visible'
                        : 'hidden'
                  }"
                  class="vpUpdateCheck"
                  style="display: inline-flex; align-items: center"
                >
                  <mat-checkbox
                    class=""
                    [(ngModel)]="vpUpdateCheck"
                    (ngModelChange)="onVpUpdateChange($event)"
                    >Diff
                  </mat-checkbox>
                  <nz-input-group
                    class="legacy-input-styling"
                    nz-size="small"
                    style="width: 100px"
                  >
                    <label>Iter</label>
                    <input
                      nz-input
                      type="number"
                      placeholder="iter"
                      [(ngModel)]="vpDiffIteration"
                      (keyup)="onKeySubmission($event)"
                    />
                  </nz-input-group>
                </div>
              </h4>

              <div
                class="actions"
                *ngIf="i != 1"
                style="margin: 0"
                style="
                  position: absolute;
                  right: 30px;
                  z-index: 2;
                  top: 55px;
                  margin: 0;
                "
              >
                <a
                  class="actions__item"
                  [matMenuTriggerFor]="menu"
                  style="padding: 5px"
                  ><i class="zmdi zmdi-more-vert"></i
                ></a>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    *ngIf="i != 1 && modelTypes && modelTypes.length > 0"
                    [matMenuTriggerFor]="modelTypeMenu"
                  >
                    Model Property
                  </button>
                  <div
                    class="slider-container"
                    style="text-overflow: clip; height: fit-content"
                    *ngIf="i == 0"
                    [matTooltip]="height"
                  >
                    Height
                    <mat-slider
                      [min]="250"
                      [max]="800"
                      [step]="25"
                      (click)="$event.stopPropagation()"
                      #ngSlider
                    >
                      <input
                        matSliderThumb
                        [(ngModel)]="height"
                        (change)="inputHeight = height; setVpHeight()"
                        #ngSliderThumb="matSliderThumb"
                      />
                    </mat-slider>
                  </div>
                  <div
                    class="slider-container"
                    style="text-overflow: clip"
                    *ngIf="i == 2"
                    [matTooltip]="depthHeight"
                  >
                    Height
                    <mat-slider
                      [min]="200"
                      [max]="maxDepth * 1.3"
                      [step]="25"
                      (click)="$event.stopPropagation()"
                      #ngSlider
                    >
                      <input
                        matSliderThumb
                        [(ngModel)]="depthHeight"
                        (change)="inputDepthHeight = depthHeight; setVpHeight()"
                        #ngSliderThumb="matSliderThumb"
                      />
                    </mat-slider>
                  </div>
                  <button
                    mat-menu-item
                    (click)="showMinMaxDialog(slice.sliceType)"
                  >
                    Min/Max
                  </button>
                  <div
                    mat-menu-item
                    *ngIf="
                      i == 2 && shotOverlayTypes && shotOverlayTypes.length > 0
                    "
                    [matMenuTriggerFor]="shotOverlayMenu"
                  >
                    <mat-slide-toggle
                      [(ngModel)]="showShotOverlay"
                      (click)="$event.stopPropagation()"
                    >
                    </mat-slide-toggle>
                    <span>Shot Overlay&nbsp;</span>
                  </div>
                  <div
                    class="slider-container"
                    *ngIf="
                      i == 2 &&
                      shotOverlayTypes &&
                      shotOverlayTypes.length > 0 &&
                      showShotOverlay
                    "
                  >
                    <span>Shot Radius</span>
                    <mat-slider
                      [min]="1"
                      [max]="10"
                      [step]="1"
                      (click)="$event.stopPropagation()"
                    >
                      <input [(ngModel)]="shotRadius" matSliderThumb />
                    </mat-slider>
                  </div>
                  <mat-form-field
                    *ngIf="
                      i == 2 &&
                      shotOverlayTypes &&
                      shotOverlayTypes.length > 0 &&
                      showShotOverlay
                    "
                    style="padding-left: 5%"
                  >
                    <mat-label>Shot Colour Min</mat-label>
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="currentShotOverlayType.min"
                      (click)="$event.stopPropagation()"
                      (keyup)="onKeySubmissionShotOverlayType($event)"
                    />
                  </mat-form-field>
                  <mat-form-field
                    *ngIf="
                      i == 2 &&
                      shotOverlayTypes &&
                      shotOverlayTypes.length > 0 &&
                      showShotOverlay
                    "
                    style="padding-left: 5%"
                  >
                    <mat-label>Shot Colour Max</mat-label>
                    <input
                      matInput
                      type="number"
                      [(ngModel)]="currentShotOverlayType.max"
                      (click)="$event.stopPropagation()"
                      (keyup)="onKeySubmissionShotOverlayType($event)"
                    />
                  </mat-form-field>

                  <div
                    mat-menu-item
                    [matMenuTriggerFor]="logMenu"
                    *ngIf="currentJob.containsLog && i == 0"
                  >
                    <mat-slide-toggle
                      [(ngModel)]="showSonicLog"
                      (change)="onSonicLogClicked()"
                    ></mat-slide-toggle>
                    <span>Sonic Logs&nbsp;</span>
                  </div>

                  <!-- index signifies slice type. 0 == inline, 1 == crossline, 2 == depth -->
                  <div
                    *ngIf="!showSonicLog && currentJob.containsLog && i == 2"
                    mat-menu-item
                  >
                    <mat-slide-toggle
                      [(ngModel)]="showWellLog"
                      (ngModelChange)="onShowWellLog()"
                    ></mat-slide-toggle>
                    <span>Well Logs</span>
                  </div>

                  <div
                    mat-menu-item
                    [matMenuTriggerFor]="horizonMenu"
                    *ngIf="i == 0 && horizonTypes.length > 0"
                  >
                    <mat-slide-toggle
                      [(ngModel)]="showHorizon"
                      (click)="onSonicLogClicked()"
                    ></mat-slide-toggle>
                    <span>Horizon&nbsp;</span>
                  </div>

                  <button
                    mat-menu-item
                    *ngIf="!!palettes && palettes.length > 0 && i == 0"
                    [matMenuTriggerFor]="paletteMenu"
                  >
                    Colour Map
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="timeSliceModels.includes(currentModelTypeId)"
                    [matMenuTriggerFor]="timeSliceMenu"
                  >
                    Time Slices
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="shotModels.includes(currentModelTypeId)"
                    [matMenuTriggerFor]="shotMenu"
                  >
                    Shots
                  </button>
                  <div mat-menu-item *ngIf="currentJob.modelGrid.is2d">
                    <mat-slide-toggle [(ngModel)]="enableClick"
                      >Enable Click</mat-slide-toggle
                    >
                  </div>

                  <div mat-menu-item *ngIf="!this.currentJob.modelGrid.is2d">
                    <mat-slide-toggle
                      (change)="toggleDepthSlice($event)"
                      [checked]="showDepthSliceGraph"
                    >
                      Show Depth slice
                    </mat-slide-toggle>
                  </div>

                  <div
                    mat-menu-item
                    [matMenuTriggerFor]="screenshotConfigMenu"
                    (click)="downloadSlicesAsPng()"
                  >
                    Screenshot
                  </div>
                  <div mat-menu-item>
                    <mat-slide-toggle
                      [(ngModel)]="showDottedLines"
                      (click)="$event.stopPropagation(); onSonicLogClicked()"
                    ></mat-slide-toggle>
                    <span>Dotted Lines&nbsp;</span>
                  </div>
                  <mat-label
                    htmlFor="xlStart"
                    *ngIf="i == 0"
                    style="padding-left: 16px; font-size: 15px"
                    >Start:</mat-label
                  >
                  <div class="menu-xl-start-fields">
                    <nz-input-group
                      [nzSize]="'default'"
                      id="xlStart"
                      *ngIf="
                        currentJob.modelGrid.is2d ||
                        (currentJob.x2_is_inline && i == 0)
                      "
                      style="flex-grow: 1"
                      (click)="$event.stopPropagation()"
                      [style.width.px]="70"
                    >
                      <label>XL</label>
                      <input
                        nz-input
                        type="number"
                        [(ngModel)]="ILXLStartCrossline"
                        (keyup)="submitSliceRanges($event)"
                      />
                    </nz-input-group>
                    <nz-input-group
                      [nzSize]="'default'"
                      *ngIf="
                        currentJob.modelGrid.is2d ||
                        (currentJob.x2_is_inline && i == 0)
                      "
                      style="flex-grow: 1"
                      (click)="$event.stopPropagation()"
                      [style.width.px]="70"
                    >
                      <label>IL</label>
                      <input
                        nz-input
                        type="number"
                        [(ngModel)]="ILXLStartInline"
                        (keyup)="submitSliceRanges($event)"
                      />
                    </nz-input-group>
                    <nz-input-group
                      [nzSize]="'default'"
                      id="xlStart"
                      *ngIf="
                        !currentJob.modelGrid.is2d &&
                        !currentJob.x2_is_inline &&
                        i == 0
                      "
                      style="flex-grow: 1"
                      (click)="$event.stopPropagation()"
                      [style.width.px]="70"
                    >
                      <label>IL</label>
                      <input
                        nz-input
                        type="number"
                        [(ngModel)]="ILXLStartInline"
                        (keyup)="submitSliceRanges($event)"
                      />
                    </nz-input-group>
                    <nz-input-group
                      [nzSize]="'default'"
                      *ngIf="
                        !currentJob.modelGrid.is2d &&
                        !currentJob.x2_is_inline &&
                        i == 0
                      "
                      style="flex-grow: 1"
                      (click)="$event.stopPropagation()"
                      [style.width.px]="70"
                    >
                      <label>XL</label>
                      <input
                        nz-input
                        type="number"
                        [(ngModel)]="ILXLStartCrossline"
                        (keyup)="submitSliceRanges($event)"
                      />
                    </nz-input-group>
                    <nz-input-group
                      [nzSize]="'default'"
                      *ngIf="i == 0"
                      style="flex-grow: 1"
                      (click)="$event.stopPropagation()"
                      [style.width.px]="70"
                    >
                      <label>Depth</label>
                      <input
                        nz-input
                        type="number"
                        [(ngModel)]="ILXLStartDepth"
                        (keyup)="submitSliceRanges($event)"
                      />
                    </nz-input-group>
                  </div>
                  <mat-label
                    htmlFor="xlEnd"
                    *ngIf="i == 0"
                    style="padding-left: 16px; font-size: 15px"
                    >End:</mat-label
                  >
                  <div class="menu-xl-end-fields">
                    <nz-input-group
                      [nzSize]="'default'"
                      id="xlEnd"
                      *ngIf="
                        currentJob.modelGrid.is2d ||
                        (currentJob.x2_is_inline && i == 0)
                      "
                      style="flex-grow: 1"
                      (click)="$event.stopPropagation()"
                      [style.width.px]="70"
                    >
                      <label>XL</label>
                      <input
                        nz-input
                        type="number"
                        [(ngModel)]="ILXLEndCrossline"
                        (keyup)="submitSliceRanges($event)"
                      />
                    </nz-input-group>
                    <nz-input-group
                      [nzSize]="'default'"
                      *ngIf="
                        currentJob.modelGrid.is2d ||
                        (currentJob.x2_is_inline && i == 0)
                      "
                      style="flex-grow: 1"
                      (click)="$event.stopPropagation()"
                      [style.width.px]="70"
                    >
                      <label>IL</label>
                      <input
                        nz-input
                        type="number"
                        [(ngModel)]="ILXLEndInline"
                        (keyup)="submitSliceRanges($event)"
                      />
                    </nz-input-group>
                    <nz-input-group
                      [nzSize]="'default'"
                      id="xlEnd"
                      *ngIf="
                        !currentJob.modelGrid.is2d &&
                        !currentJob.x2_is_inline &&
                        i == 0
                      "
                      style="flex-grow: 1"
                      (click)="$event.stopPropagation()"
                      [style.width.px]="70"
                    >
                      <label>IL</label>
                      <input
                        nz-input
                        type="number"
                        [(ngModel)]="ILXLEndInline"
                        (keyup)="submitSliceRanges($event)"
                      />
                    </nz-input-group>
                    <nz-input-group
                      [nzSize]="'default'"
                      *ngIf="
                        !currentJob.modelGrid.is2d &&
                        !currentJob.x2_is_inline &&
                        i == 0
                      "
                      style="flex-grow: 1"
                      (click)="$event.stopPropagation()"
                      [style.width.px]="70"
                    >
                      <label>XL</label>
                      <input
                        nz-input
                        type="number"
                        [(ngModel)]="ILXLEndCrossline"
                        (keyup)="submitSliceRanges($event)"
                      />
                    </nz-input-group>
                    <nz-input-group
                      [nzSize]="'default'"
                      *ngIf="i == 0"
                      style="flex-grow: 1"
                      (click)="$event.stopPropagation()"
                      [style.width.px]="70"
                    >
                      <label>Depth</label>
                      <input
                        nz-input
                        type="number"
                        [(ngModel)]="ILXLEndDepth"
                        (keyup)="submitSliceRanges($event)"
                      />
                    </nz-input-group>
                  </div>
                  <div style="display: flex; justify-content: flex-end">
                    <button
                      mat-stroked-button
                      (click)="resetRanges()"
                      style="margin: 5px 16px"
                    >
                      Reset Ranges
                    </button>
                  </div>
                </mat-menu>

                <mat-menu #screenshotConfigMenu="matMenu">
                  <div mat-menu-item>
                    <mat-slide-toggle
                      [(ngModel)]="screenshotWithVertProfile"
                      (click)="$event.stopPropagation()"
                    >
                      With Vertical Profile
                    </mat-slide-toggle>
                  </div>
                  <div mat-menu-item *ngIf="!this.currentJob.modelGrid.is2d">
                    <mat-slide-toggle
                      (change)="toggleDepthSlice($event)"
                      [checked]="showDepthSliceGraph"
                      (click)="$event.stopPropagation()"
                    >
                      With depth Slice
                    </mat-slide-toggle>
                  </div>
                </mat-menu>

                <mat-menu #modelTypeMenu="matMenu">
                  <button
                    *ngFor="let modelType of modelTypes"
                    mat-menu-item
                    (click)="setModelType(modelType, $event)"
                  >
                    <span>{{ modelType.name }}</span>
                    <i
                      *ngIf="modelType.id === currentModelTypeId"
                      class="zmdi zmdi-check"
                      style="margin-left: 2px"
                    ></i>
                  </button>
                </mat-menu>
                <mat-menu #paletteMenu="matMenu">
                  <button
                    *ngFor="let palette of palettes"
                    mat-menu-item
                    (click)="setPalette(palette)"
                  >
                    <span>{{ palette }}</span>
                    <i
                      *ngIf="palette == currentJob.last_used_palette"
                      class="zmdi zmdi-check"
                      style="margin-left: 2px"
                    ></i>
                  </button>
                </mat-menu>
                <mat-menu #logMenu="matMenu">
                  <button
                    *ngFor="let log of currentJob.wellLogs"
                    mat-menu-item
                    (click)="setLog(log.id)"
                  >
                    <span>IL - {{ log.inline }}, XL - {{ log.crossline }}</span>
                  </button>
                </mat-menu>
                <mat-menu #horizonMenu="matMenu">
                  <div *ngFor="let type of horizonTypes">
                    <div mat-menu-item>
                      <span (click)="setHorizon(type)">{{ type }}</span>
                      <i
                        *ngIf="isHorizonSelected(type)"
                        class="zmdi zmdi-check"
                        style="margin-left: 2px"
                      ></i>
                    </div>
                  </div>
                </mat-menu>
                <mat-menu #shotOverlayMenu="matMenu">
                  <button
                    *ngFor="let shotOverlayType of shotOverlayTypes"
                    mat-menu-item
                    (click)="setShotOverlayType(shotOverlayType)"
                  >
                    <span>{{ shotOverlayType.name }}</span>
                    <i
                      *ngIf="shotOverlayType.id === currentShotOverlayType.id"
                      class="zmdi zmdi-check"
                      style="margin-left: 2px"
                    ></i>
                  </button>
                </mat-menu>
                <mat-menu #timeSliceMenu="matMenu">
                  <button
                    *ngFor="let time of availTimeSlices"
                    mat-menu-item
                    (click)="setTimeSlice(time)"
                  >
                    <span>{{ time }}</span>
                    <i
                      *ngIf="timeSlice == time"
                      class="zmdi zmdi-check"
                      style="margin-left: 2px"
                    ></i>
                  </button>
                </mat-menu>
                <mat-menu #shotMenu="matMenu">
                  <button
                    *ngFor="let shot of availShots"
                    mat-menu-item
                    (click)="setShot(shot)"
                  >
                    <span>{{ shot }}</span>
                    <i
                      *ngIf="shotId == shot"
                      class="zmdi zmdi-check"
                      style="margin-left: 2px"
                    ></i>
                  </button>
                </mat-menu>
              </div>
              <app-chart-slice
                [shot]="shotId"
                [id]="currentJob.id"
                [ranges]="getRangesForSliceType(slice.sliceType)"
                [xSteps]="getXStepForSliceType(slice.sliceType)"
                [ySteps]="getYStepForSliceType(slice.sliceType)"
                [sliceType]="getAdjustedSliceType(slice.sliceType)"
                [iteration]="iteration"
                [sliceNumber]="getNumberForSliceType(slice.sliceType)"
                [xTitle]="getXTitleForSliceType(slice.sliceType)"
                [yTitle]="getYTitleForSliceType(slice.sliceType)"
                [updateChart]="updateChart"
                [refreshChart]="refreshChart"
                [height]="i == 2 ? depthHeight : height"
                [is2d]="currentJob.modelGrid.is2d"
                [xLine]="getXForSliceType(slice.sliceType)"
                [yLine]="getYForSliceType(slice.sliceType)"
                [showLegend]="i != 1 || inlineWidth == 100"
                [palette]="currentJob.last_used_palette"
                [sonicLogId]="showShowLogId()"
                [depthSliceWellLog]="depthSliceWellLogs"
                [dx]="this.currentJob.modelGrid.dx"
                [sonicNX]="getSonicNXForSliceType(slice.sliceType)"
                [sonicWidth]="getSonicWidthForSliceType(slice.sliceType)"
                [hoverLabelX]="getHoverLabelX(slice.sliceType)"
                [hoverLabelY]="getHoverLabelY(slice.sliceType)"
                [velocityRange]="getCurrentVeloctiyRange(slice.sliceType)"
                [modelType]="currentModelTypeId"
                [shotOverlayType]="currentShotOverlayType"
                [showShotOverlay]="getShotOverlay(slice.sliceType)"
                [shotRadius]="shotRadius"
                [showHorizons]="getHorizons(slice.sliceType)"
                [horizonColors]="horizonColors"
                [totalIterations]="currentJob.iterations"
                [x2IsInline]="currentJob.x2_is_inline"
                [isVpReference]="vpUpdateCheck"
                [vpIteration]="vpDiffIteration"
                [timeSlice]="timeSlice"
                [pngNameDetails]="{
                  pName: currentJob.projectName,
                  jName: currentJob.name,
                  cpnum: iteration
                }"
                [indexInDocument]="
                  vpPositionSwapped ? (i == 1 ? 2 : i == 2 ? 1 : 0) : i
                "
                [showDottedLines]="showDottedLines"
                [startDepth]="ILXLStartDepth"
                [endDepth]="ILXLEndDepth"
                [startInline]="ILXLStartInline"
                [endInline]="ILXLEndInline"
                [startCrossline]="ILXLStartCrossline"
                [endCrossline]="ILXLEndCrossline"
                (clickEmitter)="onClickEmit($event)"
                (colorbarTextEmitter)="onColorbarTextEmit($event)"
                (loadFinishedEmitter)="onLoadFinishedEmit($event)"
              >
              </app-chart-slice>
              <!-- </div> -->
            </div>
          </ng-container>

          <div
            id="velocity-profile"
            [ngClass]="
              currentJob.modelGrid.is2d
                ? 'velocity-profile'
                : 'velocity-profile'
            "
            *ngIf="!isLoading"
            [style.width]="getWidthForSliceType(null)"
            style="margin-left: 0 !important; order: 4"
          >
            <div
              class="card"
              [style.paddingBottom]="
                currentJob.modelGrid.is2d ? '7.15px' : '12.15px'
              "
            >
              <h4
                class="card-title"
                style="
                  white-space: nowrap;
                  margin-bottom: 0;
                  height: 52px;
                  line-height: 52px;
                  position: relative;
                  z-index: 5;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                Vertical Profile
                <mat-slide-toggle
                  class="swap-pos"
                  *ngIf="!currentJob.modelGrid.is2d"
                  [(ngModel)]="vpPositionSwapped"
                  (ngModelChange)="swapPosition()"
                >
                  <span>View next to Inline</span>
                </mat-slide-toggle>
              </h4>
              <app-vertical-profile
                [height]="
                  currentJob.modelGrid.is2d
                    ? vpHeight + 4
                    : this.vpPositionSwapped
                    ? vpHeight
                    : depthHeight
                "
                [depth]="depth"
                [depthSlice]="depthSlice"
                [velocities]="velocities"
                [hideAxes]="false"
                [axisMin]="verticalProfileAxis[0]"
                [axisMax]="verticalProfileAxis[1]"
                [modelTypeId]="currentModelTypeId"
                [isLoading]="verticalProfileIsLoading"
                [hasError]="verticalProfileHasError"
                [gridSpacing]="currentJob.modelGrid.dx"
                [ILXLStartDepth]="ILXLStartDepth"
                [ILXLEndDepth]="ILXLEndDepth"
                (axisMinMaxEmitter)="onVerticalProfileAxisMinMaxUpdate($event)"
                (syncAxisEmitter)="onSyncAxisUpdate($event)"
              ></app-vertical-profile>
            </div>
          </div>
          <div
            id="comment"
            *ngIf="!isLoading"
            style="flex: 1 1 300px; max-width: 350px; order: 4"
          >
            <div class="card" style="padding-bottom: 2.15px !important">
              <h4
                class="card-title"
                style="white-space: nowrap; margin-bottom: 0"
              >
                Comment
              </h4>
              <div style="padding-top: 20px">
                <textarea
                  [(ngModel)]="comments"
                  matTooltip="Enter your comment"
                  rows="3"
                  style="width: 100%; resize: vertical"
                ></textarea>
              </div>
              <button
                class="custom-button"
                (click)="updateComment()"
                [disabled]="isUpdated"
              >
                {{ isUpdated ? "Updated" : "Save" }}
              </button>
            </div>
          </div>

          <div
            class="card"
            id="_model_information_container"
            style="height: fit-content; width: 400px; order: 5"
          >
            <nz-spin [nzSpinning]="this.modelInfoLoading">
              <div
                style="
                  margin-bottom: 10px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <h4 class="card-title" style="margin: 0">Model information</h4>
                <div>
                  <button
                    nz-button
                    (click)="getModelInformation()"
                    *ngIf="!this.modelInfoFetched"
                  >
                    Get Model Information
                  </button>
                  <button
                    *ngIf="this.modelInfoFetched"
                    nz-button
                    nzType="default"
                    (click)="clearModelInfo()"
                  >
                    <span nz-icon nzType="close" nzTheme="outline"></span>
                  </button>
                </div>
              </div>
              <div id="_model_information" class="pretty-parsed-data"></div>
            </nz-spin>
          </div>

          <div
            style="
              display: inline-block;
              vertical-align: top;
              height: fit-content;
              order: 4;
            "
            [style.height]="vpHeight"
            class="card"
            *ngIf="showHorizon"
          >
            <h4 class="card-title" style="margin-bottom: 15px">
              Selected Horizons:
            </h4>
            <div *ngFor="let type of showHorizonTypes; let i = index">
              <div style="display: inline-block">{{ type }}</div>
              <Button
                style="
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  border-radius: 8px;
                "
                [(colorPicker)]="horizonColors[i]"
                (colorPickerChange)="onColorChanged($event)"
                [style.background-color]="horizonColors[i]"
              >
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div id="screenshot-container-div"></div>
    </div>

    <div
      class="sliders-bar"
      [ngClass]="{ open: sliderBarOpen, visible: sliderBarVisible }"
    >
      <div class="col-md-12">
        <div>
          <h4 class="card-title" style="margin: 0">Slice Settings</h4>
          <div class="actions" (click)="sliderBarOpen = !sliderBarOpen">
            <i class="fa fa-arrow-up"></i>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-6"
            style="padding: 0"
            *ngIf="!currentJob.modelGrid.is2d"
          >
            <div *ngIf="!currentJob.modelGrid.is2d">
              <div class="form-group">
                <div class="col-xs-12">
                  <app-slider
                    label="Inline"
                    [antd]="true"
                    [min]="1"
                    [max]="this.currentJob.modelGrid.nx1"
                    [step]="steps[0]"
                    [(value)]="inlineNumber"
                    (valueChange)="onSliderChanged()"
                  >
                  </app-slider>
                </div>
              </div>
              <div class="form-group">
                <div class="col-xs-12">
                  <app-slider
                    label="Crossline"
                    [antd]="true"
                    [min]="1"
                    [max]="this.currentJob.modelGrid.nx2"
                    [step]="steps[1]"
                    [(value)]="crosslineNumber"
                    (valueChange)="onSliderChanged()"
                  >
                  </app-slider>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" style="padding: 0; white-space: nowrap">
            <div>
              <div *ngIf="!currentJob.modelGrid.is2d" class="form-group">
                <div class="col-xs-12">
                  <app-slider
                    label="Depth Slice"
                    [antd]="true"
                    [min]="1"
                    [max]="this.currentJob.modelGrid.nx3"
                    [step]="steps[2]"
                    [(value)]="depthSlice"
                    (valueChange)="onSliderChanged()"
                  >
                  </app-slider>
                </div>
              </div>
              <div class="form-group">
                <div class="col-xs-12">
                  <app-slider
                    label="Iteration"
                    [antd]="true"
                    [min]="0"
                    [max]="currentJob.iterations"
                    [completed]="currentJob.iterationsComplete"
                    [(value)]="iteration"
                    [step]="steps[3]"
                    (valueChange)="onSliderChanged()"
                  >
                  </app-slider>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <mat-checkbox
              style="margin-top: 10px; margin-right: 38px; float: right"
              [(ngModel)]="synchroniseSettings"
              (ngModelChange)="onSynchroniseChange($event)"
              >Synchronise Settings</mat-checkbox
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
