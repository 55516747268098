import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzOptionComponent } from "ng-zorro-antd/select";
import { Job } from "../../../../models/job";
import { CachedProjectService } from "../../../../shared/services/cached-project.service";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ProjectService } from "../../../../shared/services/project.service";

@Component({
  selector: "app-jobs-form",
  templateUrl: "./jobs-form.component.html",
  styleUrls: ["./jobs-form.component.less"],
})
export class JobsFormComponent {
  private destroy$ = new Subject<void>();
  jobsForm: FormGroup;
  parentJobs: Array<Job> = [];
  project_id: string;
  parent_max_iteration: any;
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private cachedProjectService: CachedProjectService,
    private notificationService: NzNotificationService,
    private projectService: ProjectService
  ) {
    this.jobsForm = this.fb.group({
      job_basepath: this.fb.control<string>("", [Validators.required]),
      x2_is_inline: this.fb.control<boolean>(false),
      parent_job: this.fb.control<object>(null),
      parent_cp: this.fb.control<number>(null),
    });

    this.jobsForm.controls["parent_cp"]?.disable();

    this.jobsForm.controls["parent_job"]?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (val) => {
          console.log(val);
          if (val) {
            this.jobsForm.controls["parent_cp"]?.enable();
            this.parent_max_iteration = val.iterations_completed;
            this.jobsForm.controls["parent_cp"].setValue(0);
          } else {
            this.jobsForm.controls["parent_cp"]?.disable();
            this.jobsForm.controls["parent_cp"].setValue(null);
          }
        },
        error: (err) => {
          console.log(err);
          this.notificationService.error("Error", err);
        },
      });

    this.cachedProjectService.currentProject
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (project) => {
          if (project) {
            this.project_id = project.id;
            this.getParentJobs();
          }
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            "An unexpected error occured"
          );
        },
      });
  }

  getParentJobs() {
    this.cachedProjectService
      ._getProjectJobs(this.project_id, {
        fields: ["id", "job_name", "iterations_completed", "project__name"],
      })
      .toPromise()
      .then((res) => {
        this.parentJobs = res.data.sort((a, b) =>
          String(a.job_name).localeCompare(b.job_name)
        );
      })
      .catch((err) => console.log(err));
  }

  searchFn(searchString: string, option: NzOptionComponent) {
    return String(option.nzLabel)
      .toLocaleLowerCase()
      .replace(/_|-/g, " ")
      .includes(searchString.toLocaleLowerCase());
  }

  submitForm() {
    let entries = [
      {
        path: this.jobsForm.value["job_basepath"],
        parent_job_id: this.jobsForm.value["parent_job"]["id"],
        parent_cp_num: this.jobsForm.value["parent_cp"] || null,
        x2_is_inline: this.jobsForm.value["x2_is_inline"],
      },
    ];

    this.isLoading = true;
    this.projectService
      .createJobs(this.project_id, entries)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.notificationService.success(
            "Success",
            `Job was successfully added to the project`
          );
          this.jobsForm.reset();
          this.getParentJobs();
          this.isLoading = false;
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error || "An unexpected error occured"
          );
          this.isLoading = false;
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
