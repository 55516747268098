export const parameters = {
  Job: ["job_name", "xwi_executable", "comments", "date"],
  "Default Locators": [
    "epicmod_locator",
    "checkpoint_locator",
    "geometry_locator",
    "modelprops_locator",
    "qc_dump_locator",
    "sourcesig_locator",
    "traces_locator",
  ],
  General: [
    "io_api",
    "tti_units",
    "ttiang_units",
    "units",
    "equation",
    "anisotropy",
    "domain",
    "kernel",
    "probdims",
    "problem",
    "iters",
    "numiterblocks",
    "final_iteration",
  ],
  Discretisation: ["nx1", "nx2", "nx3", "dx", "fd_time_step", "totaltime"],
  Attenuation: ["alpha"],
  "Source and Receiver": [
    "flipshotcycles",
    "max_dead_srcs",
    "maxps",
    "maxrc",
    "ncomp",
    "nptrecs",
    "nptsrcs",
    "numcsperiter",
    "numcstoskip",
  ],
  "EPICMod Type": [
    "epicmod_adjoints",
    "epicmod_comparetraces",
    "epicmod_localgradprec",
    "epicmod_localmodprops",
    "epicmod_propagate",
    "epicmod_residuals",
    "epicmod_update",
    "epicmod_modelprops",
    "epicmod_density",
  ],
  "RWI Trace Manipulations": [
    "rwi_shot_autoscaling",
    "rwi_end_time",
    "rwi_start_time",
    "rwi_agc",
    "rwi_timetweak_ratefact",
    "rwi_timetweak_maxfact",
    "rwi2_far_ratio",
    "rwi2_near_ratio",
  ],
  "RWI Other": [
    "iterations:_vs/bg",
    "rwi_cut_factor",
    "rwi_restart_vs",
    "rwi_shift_factor",
    "rwi_add_incident",
    "rwi_vs_iters",
    "rwi_bg_iters",
    "rwi_scale",
    "rwi_type",
  ],
  "RWI Gradient & Preconditioner": [
    "rwi_spatial",
    "rwi_grad_contrib",
    "rwi_gradmaskang",
  ],
  "RWI Filters": ["vs_low_cut", "rwi_low_cut", "vs_high_cut", "rwi_high_cut"],
  "Iteration Smooth": [
    "smoothx1",
    "smoothx2",
    "smoothx3",
    "smoothcut",
    "smoothramp",
    "struct_smooth",
    "rcvrline_smoothdist",
  ],
  "RWI Smooth": [
    "rwi_smooth_x1",
    "rwi_smooth_x2",
    "rwi_smooth_x3",
    "rwi_smooth_cut",
    "rwi_smooth_ramp",
    "rwi_struct_smooth",
    "rwi_rcvrline_smoothdist",
    "rwi_median_x1",
    "rwi_median_x2",
    "rwi_median_x3",
    "rwi_presmooth_clip_lo",
    "rwi_presmooth_clip_hi",
    "rwi_presmooth_clip_smthfact",
    "rwi_smthsprdclip_low",
    "rwi_smthsprdclip_high",
    "rwi_smthsprdclip_smthfact",
  ],
  "Block Smooth": [
    "finalsmoothx1",
    "finalsmoothx2",
    "finalsmoothx3",
    "finalsmoothcut",
    "finalsmoothramp",
    "finalmodelsmoothcutvel",
  ],
  "Model Smooth": [
    "modelsmoothx1",
    "modelsmoothx2",
    "modelsmoothx3",
    "modelsmoothcut",
    "modelsmoothramp",
    "modelsmoothcutvel",
    "finalsmoothvelcut",
  ],
  "RWI Mute": ["rwi_cut_taper", "rwi_offset_cut", "rwi_offset_grow"],
  Mute: [
    "outer_mute_start_time",
    "outer_mute_growth",
    "outer_mute_taper_width",
    "inner_mute_start_time",
    "inner_mute_growth",
    "inner_mute_taper_width",
    "offset_plane_normal",
  ],
  Density: [
    "density_ramps",
    "min_gardner_vel",
    "max_gardner_vel",
    "salt_velocity",
    "salt_density",
    "water_mincells",
    "water_maxcells",
    "water_velocity",
    "water_density",
    "gardner_factor",
    "gardner_power",
    "fixed_density",
  ],
  "Step Factor": [
    "scale_i",
    "scale_r",
    "steplen",
    "line_search_method",
    "line_search_max_steps",
    "badresidweight",
  ],
  "Trace Manipulations": [
    "starttime",
    "endtime",
    "minoffset",
    "maxoffset",
    "amplitude",
    "normalise",
    "timetweak_ratefact",
    "timetweak_maxfact",
    "shot_autoscaling",
    "source_scaling",
    "corr_match_weight",
    "final_iter_srcdiff",
    "final_iter_srcsmth",
    "source_differ",
    "source_smooth",
    "gausswid",
    "minimum_traces",
  ],
  "Gradient & Preconditioner": [
    "functional",
    "slowness",
    "spatial",
    "conj_grad",
    "accel_grad",
    "reduce_oscillation",
    "gradmaskang",
    "long_contrib_x1",
    "long_contrib_x2",
    "long_contrib_x3",
    "short_contrib_x1",
    "short_contrib_x2",
    "short_contrib_x3",
    "percent",
  ],
  AWI: [
    "awi_scale",
    "awi_top",
    "awi_normfilt",
    "awi_atype",
    "awi_rtype",
    "awi_steptype",
    "awi_protectspike",
    "awi_amin",
    "awi_decimate",
    "awi_diffenv",
    "awi_filtsmooth",
    "awi_flavour",
    "awi_length",
    "awi_max_freq",
    "awi_maxsubtraces",
    "awi_noise",
    "awi_taper",
    "awi_full_on",
    "awi_min_freq",
    "awi_mix",
    "awi_mix_dist",
    "awi_overcook",
    "awi_post_filt",
    "awi_power",
    "awi_pre_filt",
    "awi_rmin",
    "awi_smooth_dat",
    "awi_smooth_src",
    "awi_start",
    "awi_tile_traces",
    "awi_trace_deci",
  ],
  PyAWI: [
    "pyawi",
    "tile_width",
    "tiling",
    "tile_extension",
    "math_mode",
    "protect_spike",
    "sailline_edge_taper",
    "filtsmooth_offset",
    "offset_scale",
    "decimate",
    "max_freq",
    "trace_window",
    "direction",
    "use_fft",
    "length",
    "width",
    "noise",
    "filtsmooth",
    "scale",
    "normfilt",
    "top",
    "d_scale",
    "filter_dim",
    "filtsmooth_time",
    "frequencies",
    "mode",
    "mu",
    "parallel",
    "sailline_length",
    "salline_length",
    "tile_time",
    "total_iterations",
  ],
  "Minimum Velocities": [
    "velcutoff",
    "velcon_min",
    "auto_minvel",
    "velstart_min",
    "vs_start_min",
    "delta_start_min",
    "eps_start_min",
  ],
  "Maximum Velocities": [
    "velcon_max",
    "auto_maxvel",
    "velstart_max",
    "vs_start_max",
    "delta_start_max",
    "eps_start_max",
  ],
  Window: [
    "window",
    "w1",
    "w2",
    "w3",
    "w4",
    "window1",
    "window2",
    "window3",
    "window4",
  ],
  "Global Domain": [
    "extra_left",
    "extra_right",
    "extra_front",
    "extra_back",
    "extra_top",
    "extra_bottom",
  ],
  "Local Domain": ["borderx1", "borderx2", "borderx3"],
  Absorbing: ["bleft", "bright", "bfront", "bback", "btop", "bbottom"],
  Misc: [
    "sourceamp",
    "sourcephase",
    "fm",
    "aws_path",
    "frequency",
    "gausswidth",
    // "iterations",
    "ndone",
    "update_props",
    "used_rwi",
    "min_traces",
    "minvpwithvs",
    "modelsmoothvelcut",
    "reduce_oscillations",
    "rwi_rcvrline_smthdist",
    "rwi_timeslice_maxboost",
    "timeshift_adjoint",
    "vptovsratio",
    "vs_override_max_con",
  ],
  "Starting model": ["parent_job_cp", "parent_job_name"],
};
