<div>
  <div *ngIf="showChart()">
    <h4 class="card-title">Selected shot: {{ selectedShot }}</h4>

    <div class="row">
      <div class="tabs col-md-9">
        <app-shot-tabs
          [shotType]="shotType"
          [typeOfSelectedRcvr]="typeOfSelectedRcvr"
          (tabChanged)="onTabChanged($event)"
        ></app-shot-tabs>
      </div>

      <div class="col-md-3 chart-controls">
        <div class="spacer"></div>
        <div class="chart-buttons">
          <app-shot-container-menu
            [jobDetail]="jobDetail"
            [settingsKey]="settingsKey"
            [selectedShot]="selectedShot"
            [availableShotIds]="availableShotIds"
            [iterationsOfSameShot]="iterationsOfSameShot"
            [switchIteration]="switchIteration"
            (menuChanged)="onShotContainerMenuChanged($event)"
            (iterationChanged)="onIterationChanged($event)"
          ></app-shot-container-menu>
        </div>
        <div>
          <app-shot-chart-menu
            [jobDetail]="jobDetail"
            [settingsKey]="settingsKey"
            [shotTypes]="shotTypes"
            (menuChanged)="onMenuChanged($event)"
          ></app-shot-chart-menu>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="image-div" tabindex="0">
          <div *ngIf="loadingImage" class="loading">
            <mat-progress-spinner
              mode="indeterminate"
              class="spinner"
              diameter="30"
            ></mat-progress-spinner>
            <span class="loading-text">loading image</span>
          </div>
          <div class="row">
            <div class="col-md-1 image-switch-wrapper">
              <div
                *ngIf="hasLeftPage"
                [ngStyle]="{
                  height: mainGraphHeight * 0.9 + 'px',
                  float: 'right'
                }"
                class="image-switch"
                (click)="onSliceChange(false)"
              >
                <div class="image-switch-middle">
                  <i
                    style="margin-right: 10%; float: right"
                    class="zmdi zmdi-hc-2x zmdi-caret-left"
                  ></i>
                </div>
              </div>
            </div>
            <div class="col-md-10">
              <app-d3-image
                class="image"
                [ngClass]="{
                  'left-shadow': hasLeftPage,
                  'right-shadow': hasRightPage,
                  'shadow-both': hasLeftPage && hasRightPage
                }"
                [height]="mainGraphHeight"
                [hideAxes]="shotType && shotType.hideAxes"
                [image]="{
                  backgroundImage: imageUrl,
                  totalTime: totalTime,
                  range: { startTrace: startTrace, endTrace: endTrace },
                  interleaveInfo: {
                    isInter: isInterleaveType,
                    extent: interleaveExtent
                  }
                }"
              >
              </app-d3-image>
            </div>
            <div class="col-md-1 image-switch-wrapper">
              <div
                *ngIf="hasRightPage"
                [ngStyle]="{ height: mainGraphHeight * 0.9 + 'px' }"
                class="image-switch"
                (click)="onSliceChange(true)"
              >
                <div class="image-switch-middle">
                  <i
                    style="margin-left: 10%"
                    class="zmdi zmdi-hc-2x zmdi-caret-right"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="error-message" *ngIf="!imageUrl && !loadingImage && !rcvrData">
            <h3 *ngIf="!shotImageError">
              No trace file for this iteration
            </h3>
          <h3 *ngIf="shotImageError">
            {{shotImageError}}
          </h3>
        </div> -->
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-shot-chart-legend
            [shotType]="shotType"
            [clipValue]="clipValue"
            [selectedPanel]="selectedPanel"
            (interleaveValueChanged)="interleaveChanged($event)"
          ></app-shot-chart-legend>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4" *ngIf="(!selectedShot || !isValidSelectedShot) && hasShotData()">Select a shot from the shot map</div>
  <div class="mt-4" *ngIf="!hasShotData()">Select an iteration with shot data</div>
</div>
