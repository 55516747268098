<ng-container *ngIf="visible">
  <div class="settings-bar" [style.bottom]="bottom">
    <div>
      <div class="row settings-bar-row">
        <div class="col-xs-2 text-right"><label>Iteration</label></div>
        <div class="col-xs-10 col-md-6">
          <app-slider
            [min]="1"
            [max]="this.jobDetail.iterationsComplete"
            [step]="step"
            [(value)]="sliceSettings.iteration"
            (valueChange)="onSliderChanged()"
          ></app-slider>
        </div>
        <div class="col-xs-5 text-right">
          <!-- <button
            mat-raised-button
            [matMenuTriggerFor]="menu"
            xPosition="before"
            Position="above"
            class="job-menu"
          >
            {{ jobDetail.name }}
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              *ngFor="let job of jobs"
              (click)="onJobClicked(job)"
            >
              {{ job.name }}
            </button>
          </mat-menu> -->
        </div>
      </div>
    </div>
  </div>
</ng-container>
