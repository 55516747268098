import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ShotType } from "../../../models/shotType";
export interface Panel {
  name: string;
  index: number;
}
@Component({
  selector: "app-shot-tabs",
  templateUrl: "./shot-tabs.component.html",
  styleUrls: ["./shot-tabs.component.less"],
})
export class ShotTabsComponent implements OnInit {
  @Input() shotType: ShotType;
  @Input() typeOfSelectedRcvr: ShotType;
  @Output() tabChanged = new EventEmitter<Panel>();
  allPanelNames: string[] = ["Prediction", "Field", "Difference"];
  panelNames: string[];
  selectedPanel: number = 0;

  constructor() {}

  ngOnInit() {
    this.setPanelNames();
  }
  onTabChanged(index: number) {
    this.tabChanged.emit({
      name: this.panelNames[index],
      index: index,
    });
  }

  private setPanelNames() {
    let numPanel =
      this.shotType.name == "RcvrList"
        ? this.typeOfSelectedRcvr.numPanel
        : this.shotType.numPanel;
    this.panelNames = this.allPanelNames.slice(0, numPanel);
    if (this.shotType.numPanel > 1 && this.shotType.name != "RcvrList") {
      this.panelNames.push("Interleaved");
    }
  }

  getTabName(name) {
    if (
      name != "Difference" ||
      this.shotType.name != "RcvrList" ||
      this.typeOfSelectedRcvr.name != "RcvrList"
    )
      return name;
    else return "Phase Residual";
  }

  onKeySwitchTab(event: KeyboardEvent) {
    if (event.key == "ArrowRight") {
      this.selectedPanel = Math.min(
        this.selectedPanel + 1,
        this.panelNames.length - 1
      );
    } else if (event.key == "ArrowLeft") {
      this.selectedPanel = Math.max(0, this.selectedPanel - 1);
    }
    this.tabChanged.emit({
      name: this.panelNames[this.selectedPanel],
      index: this.selectedPanel,
    });
  }
}
