import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ECharts, EChartsOption } from "echarts";
import { NzMessageService } from "ng-zorro-antd/message";
import { ProjectService } from "../../../shared/services/project.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { Project } from "../../../models/project";
import * as _ from "lodash";
import { parameter_view_fields } from "../parameter-overview-2/fields";
import { FormBuilder, FormControl } from "@angular/forms";

interface HeatmapConfig {
  x: FormControl;
  y: FormControl;
  colorRange: number[];
  treeviewState?: any[]; // this one is to store the state of the tree. Might be redundant tho.
}

interface GeoShotType {
  shot_id: string;
  x: string;
  y: string;
  z: string;
  trace_fit: number;
  fnlc: number;
  dc_raw: number;
}

@Component({
  selector: "app-trace-fits-plotting",
  templateUrl: "./trace-fits-plotting.component.html",
  styleUrls: ["./trace-fits-plotting.component.less"],
})
export class TraceFitsPlottingComponent implements OnInit {
  @ViewChild("graph", { static: true }) graph: ElementRef;
  chartInstance: ECharts;

  ylim: any[] = [0, 100];
  xlim: any[] = [0, 0];
  xMin: number = 0;
  xMax: number = 0;
  yMin: number = 0;
  yMax: number = 100;
  spatial_iteration: number = 1;

  project_id: string = "";
  project_name: string = "";
  loadingChart: boolean = false;
  error: string = null;
  searchValue: string = "";
  options: EChartsOption;
  data: {
    results: any[];
    tracedata: {
      [key: string]: {
        job_info: Object;
        iterations_trace: object;
        iterations_dc_raw: object;
        iterations_fnlc: object;
        frequency: object;
        shot_with_geo: {
          [iteration: string]: { [shot_id: string]: GeoShotType };
        };
      };
    };
  };

  paramViewFields: object = parameter_view_fields;
  heatmapParams: string[] = ["awi_scale", "awi_top"];
  heatX: string = "awi_scale";
  heatY: string = "awi_top";
  heatmapConfigs: HeatmapConfig[] = [];
  heatmapAxisSelectionModal: boolean = false;
  activeConfig: number = 1;
  treeviewInitValue: any[] = [];
  heatmapOptions: EChartsOption[];

  tracetype: "fnlc" | "trace_fit" | "dc_raw" = "trace_fit";
  linePlotXConfig: "spatial" | "iteration" = "spatial";
  iteration_type: "global" | "per-job" = "per-job";
  spatial_plotType: "scatter" | "line" = "scatter";
  totalIterations: any;
  spatial_totalIterations: any;

  jobsSelected: any[] = [];
  jobsSelectedIds: string[] = [];
  allJobs: any[] = [];
  is2d: boolean;
  tolerance: number = 100;
  swapAxis: boolean = false;
  areFrequenciesComparable: boolean = true;
  imageName: string = "plot";

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _projectService: ProjectService,
    private _cachedProjectService: CachedProjectService,
    private _message: NzMessageService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this._cachedProjectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project_id = project.id;
        this.project_name = project.name;
        this._cachedProjectService
          ._getProjectJobs(this.project_id, {
            fields: ["id", "job_name", "iterations"],
          })
          .toPromise()
          .then((res) => {
            this.allJobs = res["data"];

            // if queryParams have jobs to actually plot
            if (this._route.snapshot.queryParams.jobs) {
              let jobIds = this._route.snapshot.queryParams.jobs;
              if (!_.isArray(jobIds)) {
                jobIds = [jobIds];
              }

              jobIds.forEach((id) => {
                let selectedJob = this.allJobs.find((j) => j.id == id);
                this.jobsSelectedIds.push(selectedJob.id);
                this.jobsSelected.push({ ...selectedJob, currentIteration: 1 });
              });

              this.getPlottingDataForParamJobs();
            }
          });
      }
    });
  }

  getPlottingDataForParamJobs() {
    this.loadingChart = true;
    let jobIds = this._route.snapshot.queryParams.jobs;
    if (!_.isArray(jobIds)) {
      jobIds = [jobIds];
    }
    this._cachedProjectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project_id = project.id;
        this.project_name = project.name;
        this._projectService
          .getTraceFitPlottingData(this.project_id, {
            job_ids: jobIds,
            parameters: [
              "awi_scale",
              "awi_normfilt",
              "job__iterations",
              "job__comments",
            ],
          })
          .toPromise()
          .then((res) => {
            this.data = res;
            this.checkFrequencyDifference();
            this.plotLineChart();
          })
          .catch((err) => this._message.error(err.message))
          .finally(() => (this.loadingChart = false));
      }
    });
  }

  /**
   * update via updating the URL. Might seem unintuitive but actually better cause allows sharing and not really that tedious
   */
  plotSelectedJobs() {
    if (this.jobsSelectedIds?.length > 0) {
      this._router
        .navigate(["."], {
          relativeTo: this._route,
          queryParams: { jobs: this.jobsSelectedIds },
          replaceUrl: true,
        })
        .then((_) => this.getPlottingDataForParamJobs());
    }
  }

  updateJobsSelected(data) {
    this.jobsSelected = this.allJobs
      .filter((j) => data.includes(j.id))
      .map((j) => ({ ...j, currentIteration: 1 }));
  }

  chartInit(event) {
    this.chartInstance = event;
  }

  onYlimChanged(e) {
    this.chartInstance.setOption({
      yAxis: {
        min: e[0],
        max: e[1],
        type: "value",
      },
    });
  }
  onXlimChanged(e) {
    this.chartInstance.setOption({
      xAxis: {
        min: e[0] - 1,
        max: e[1] - 1,
        type: "category",
        boundaryGap: false,
      },
    });
  }

  plotLineChart() {
    this.imageName = "TFP-" + this.project_name.substring(0, 10);
    if (this.linePlotXConfig == "iteration") {
      this.imageName += "-" + this.tracetype;
      this.imageName += "-iteration-plot-";
      this.imageName += this.jobsSelected
        .map((j) => j.job_name.substring(0, 7))
        .join("-");
      this.makeIterationLineChart();
    } else if (this.linePlotXConfig == "spatial") {
      this.imageName += "-" + this.tracetype;
      this.imageName += "-spatial-plot";
      this.imageName += "-" + this.spatial_plotType + "-";
      this.imageName += this.jobsSelected
        .map((j) => j.job_name.substring(0, 7))
        .join("-");
      this.makeSpatialLineChart();
    }
  }

  makeSpatialLineChart() {
    this.chartInstance?.clear();

    // avg tracefit over a position across iterations
    let shotSet = new Set<number>();
    let averagingDict = {};
    let minX: number = null;
    let minY: number = null;
    let maxY: number = null;

    // the results contains job info. So the dx and nx1 can be fetched from here
    this.xMax = _.max(
      this.data.results.map((j) => {
        if (j.job__x2_is_inline) {
          if (this.swapAxis) {
            return Number(j.nx1) * Number(j.dx);
          } else {
            return Number(j.nx2) * Number(j.dx);
          }
        } else {
          if (this.swapAxis) {
            return Number(j.nx2) * Number(j.dx);
          } else {
            return Number(j.nx1) * Number(j.dx);
          }
        }
      })
    );
    this.xlim = [0, this.xMax];

    if (this.data.results.length > 0) {
      this.is2d = this.data.results[0].job__model_grid__is_2d;
    }

    for (let job of Object.keys(this.data.tracedata)) {
      const job_info = this.data.tracedata[job].job_info;

      if (!(job in averagingDict)) {
        averagingDict[job] = {};
      }
      const shots_with_geo_by_iteration =
        this.data.tracedata[job].shot_with_geo;

      let totalIterations = job_info["job__iterations"];

      if (
        !this.spatial_totalIterations ||
        this.spatial_totalIterations < totalIterations
      ) {
        this.spatial_totalIterations = totalIterations;
      }

      var iteration: number;
      if (this.iteration_type == "global") {
        iteration = this.spatial_iteration;
      } else {
        iteration = this.jobsSelected.find((j) => j.id == job).currentIteration;
      }

      let iteration_shots = shots_with_geo_by_iteration[iteration];

      if (iteration_shots) {
        for (let shot of Object.values(iteration_shots)) {
          // console.log("these", shot);
          shotSet.add(Number(shot.shot_id)); //it will only add the unique ones since its a set
          if (`${shot.shot_id}` in averagingDict[job]) {
            let value = null;
            if (this.tracetype == "trace_fit") value = shot.trace_fit;
            else if (this.tracetype == "dc_raw") value = shot.dc_raw;
            else value = shot.fnlc;

            averagingDict[job][`${shot.shot_id}`]["fits"].push(value);
            if (!maxY || maxY < Number(value)) maxY = Number(value.toFixed(3));
            if (!minY || minY > Number(value)) minY = Number(value.toFixed(3));
          } else {
            let value = null;

            averagingDict[job][`${shot.shot_id}`] = {};
            averagingDict[job][`${shot.shot_id}`]["fits"] = [shot.trace_fit];

            if (this.tracetype == "trace_fit") value = shot.trace_fit;
            else if (this.tracetype == "dc_raw") value = shot.dc_raw;
            else value = shot.fnlc;

            if (job_info["job__x2_is_inline"]) {
              // if x2_is_inline, x and z are swapped
              // now we wanna do one more check, if the user swappedAxis or not.
              // doing it like this (nested ifs) may not be the best, but it is simple solution. No point overcomplicating when it can be done in just 1 if statement
              if (this.swapAxis) {
                averagingDict[job][`${shot.shot_id}`]["x"] = shot.x;
              } else {
                averagingDict[job][`${shot.shot_id}`]["x"] = shot.z;
              }
            } else {
              if (this.swapAxis) {
                averagingDict[job][`${shot.shot_id}`]["x"] = shot.z;
              } else {
                averagingDict[job][`${shot.shot_id}`]["x"] = shot.x;
              }
            }

            averagingDict[job][`${shot.shot_id}`]["fits"] = [value];

            if ((!maxY || maxY < Number(value)) && value)
              maxY = Number(value.toFixed(3));
            if ((!minY || minY > Number(value)) && value)
              minY = Number(value.toFixed(3));

            if (!minX || Number(minX) > Number(shot.x)) {
              minX = Number(shot.x);
            }
          }
        }
      }
    }

    this.yMax = maxY < 100 ? 100 : maxY;
    this.yMin = minY > 0 ? 0 : minY;

    // this.ylim[0] =
    //   this.ylim[0] < Math.floor(this.yMin)
    //     ? Math.floor(this.yMin)
    //     : this.ylim[0];
    // this.ylim[1] =
    //   this.ylim[1] > Math.ceil(this.yMax) ? Math.ceil(this.yMax) : this.ylim[1];

    // // clone helps update state
    this.ylim = [this.yMin, this.yMax];

    for (let jobInfo of Object.values(averagingDict)) {
      for (let shotInfo of Object.values(jobInfo)) {
        shotInfo["fit"] = _.mean(shotInfo["fits"]);
      }
    }

    // the averagingDict has the jobs as keys, and their shots with the tracefits and their x positions
    const xaxisData = Array.from(
      { length: Math.floor(this.xMax) },
      (_, i) => i
    );

    const seriesData = [];
    for (let job of Object.keys(averagingDict)) {
      const jobDict = averagingDict[job];
      const linedata = [];

      // the x2_is_inline, will switch the x and z values of the data. So here, if the job has x2_is_inlin true, use z.
      Object.values(jobDict).forEach((shot: object) => {
        linedata[Math.floor(Number(shot["x"]))] =
          Number(shot["fit"])?.toFixed(2) || null;
      });

      const newLindedata = this.resolve3DSlices(linedata);

      seriesData.push({
        connectNulls: true,
        data: newLindedata,
        name: this.data.tracedata[job].job_info["job__job_name"],
        type: this.spatial_plotType,
        symbolSize: 5,

        animation: false,
        tooltip: { show: true },
      });
    }

    // its just to make a reference to this.data so i can access it. Otherwise I can't use the this from inside the options obj cause different context. So just make this new variable independent of context which i can use to access it
    var dataRef = this.data;

    this.options = {
      title: {
        show: this.iteration_type == "global",
        text: `Iteration: ${this.spatial_iteration}`,
      },
      legend: {
        type: "scroll",
        padding: 20,
        data: this.makeLegendData(),
        formatter(name) {
          // format the full long name to just show the fullname
          return name.substring(0, 10);
        },
        tooltip: {
          show: true,
          formatter: function (params) {
            let j = dataRef.results.find((d) => d.job__job_name == params.name);
            return `<span><b>${
              params.name.length > 50
                ? params.name.replace(/(.{50})/g, "$1<br>")
                : params.name
            }</b></span><br><span style='padding-left: 5px;'>${
              j.job__comments || ""
            }</span>`;
          },
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {
            name: this.imageName,
          },
          restore: {},
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          if (params.name) {
            return `<span><b>${
              params.seriesName.length > 50
                ? params.seriesName.replace(/(.{50})/g, "$1<br>")
                : params.seriesName
            }</b></span><br><div style='display: flex; justify-content: space-between; gap: 20px;'><span>${
              params.marker
            }${params.name}</span><span>${params.value}</span></div>`;
          } else {
            return;
          }
        },
        axisPointer: {
          type: "cross",
          animation: false,
          label: {
            backgroundColor: "#ccc",
            borderColor: "#aaa",
            borderWidth: 1,
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            color: "#222",
          },
        },
      },
      animation: false,
      xAxis: {
        type: "category",
        name: this.swapAxis ? "Crossline" : "Inline",
        nameLocation: "middle",
        nameGap: 20,
        min: 0,
        max: this.xMax,
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 15,
        },
        boundaryGap: false,
        data: xaxisData,
      },
      yAxis: {
        type: "value",
        name:
          this.tracetype == "dc_raw"
            ? "Trace fit RAW"
            : this.tracetype == "fnlc"
            ? "Functional"
            : "Trace fit",
        nameLocation: "middle",
        min: this.ylim[0],
        max: this.ylim[1],
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 15,
        },
        axisLine: {
          show: true,
        },
        axisPointer: {
          show: true,
        },
        minorTick: {
          show: true,
        },
        minorSplitLine: {
          show: true,
        },
      },
      series: seriesData,
    };

    this.chartInstance?.setOption(_.cloneDeep(this.options));
    this._changeDetectorRef.detectChanges();
  }

  swapSpatialAxis() {
    this.swapAxis = !this.swapAxis;
    this.plotLineChart();
  }

  resolve3DSlices(linedata: any) {
    // the case of tolerance less than 1 will default tolerance to 1
    if (this.tolerance < 1) this.tolerance = 1;

    // make buckets using the tolerance, and the total points on the line
    let limit = linedata.length;
    let buckets = Math.ceil(limit / this.tolerance);
    let breakpoints = Array.from(Array(buckets).keys()).map(
      (i) => i * this.tolerance
    );
    let indexes = breakpoints.map((i) => i + Math.floor(this.tolerance / 2));

    const newLinedata = [];

    // using the breakpoints, start getting all the tracefit values in that area and averaging
    for (let [index, bucket] of breakpoints.entries()) {
      let startIndex = bucket;
      let endIndex = startIndex + this.tolerance;
      let bucketData = linedata
        .slice(startIndex, endIndex)
        .map((v) => Number(v))
        .filter((val) => val !== null && val !== undefined)
        .map((v) => Number(v)); // endIndex not included, so no need to -1. Also filter out nulls
      let bucketValue = _.mean(bucketData); // get the avg of the bucket area. Also filter out nulls
      newLinedata[indexes[index]] = bucketValue;
    }
    return newLinedata;
  }

  makeIterationLineChart() {
    this.checkFrequencyDifference();
    // very complex and dense way of basically just making an array from first to last iteration
    const preXaxisData = Array.from(
      new Set(
        Object.values(this.data.tracedata)
          .map((o) => {
            if (this.tracetype == "trace_fit")
              return Object.keys(o.iterations_trace).map((iter) =>
                Number(iter)
              );
            else if (this.tracetype == "dc_raw")
              return Object.keys(o.iterations_dc_raw).map((iter) =>
                Number(iter)
              );
            else if (this.tracetype == "fnlc")
              return Object.keys(o.iterations_fnlc).map((iter) => Number(iter));
          })
          .flat()
      )
    ).sort(function (a, b) {
      return Number(a) - Number(b);
    });

    this.xMin = 0;
    this.xMax = _.max(preXaxisData);
    this.xlim = [this.xMin, this.xMax];

    const xaxisData = Array.from({ length: this.xMax }, (_, i) => i + 1); //=> [1, 2, 3, 4, 5, 6, 7, 8, 9...]

    // complex looking chainging but is actually very simple
    const seriesData: any[] = Object.entries(this.data.tracedata).map(
      ([job, tracefits]) => {
        // const lineData = new Array(xaxisData.length).fill(null);
        const lineData = [];
        let maxY: number = null;
        let minY: number = null;
        if (this.tracetype == "trace_fit")
          Object.entries(tracefits.iterations_trace).forEach((trf) => {
            // assign the tracefit to the index according to iter. e.g. iteration 31 will be assigned to index 30 (iteration starting from 1)
            lineData[Number(trf[0]) - 1] = Number(trf[1]).toFixed(3);
            if (!maxY || maxY < Number(trf[1]))
              maxY = Number(trf[1].toFixed(3));
            if (!minY || minY > Number(trf[1]))
              minY = Number(trf[1].toFixed(3));
          });
        else if (this.tracetype == "dc_raw")
          Object.entries(tracefits.iterations_dc_raw).forEach((trf) => {
            // assign the tracefit to the index according to iter. e.g. iteration 31 will be assigned to index 30 (iteration starting from 1)
            lineData[Number(trf[0]) - 1] = Number(trf[1]).toFixed(3);
            if (!maxY || maxY < Number(trf[1]))
              maxY = Number(trf[1].toFixed(3));
            if (!minY || minY > Number(trf[1]))
              minY = Number(trf[1].toFixed(3));
          });
        else if (this.tracetype == "fnlc")
          Object.entries(tracefits.iterations_fnlc).forEach((trf) => {
            // assign the tracefit to the index according to iter. e.g. iteration 31 will be assigned to index 30 (iteration starting from 1)
            lineData[Number(trf[0]) - 1] = Number(trf[1]).toFixed(3);
            if (!maxY || maxY < Number(trf[1]))
              maxY = Number(trf[1].toFixed(3));
            if (!minY || minY > Number(trf[1]))
              minY = Number(trf[1].toFixed(3));
          });

        this.yMax = maxY < 100 ? 100 : maxY;
        this.yMin = minY > 0 ? 0 : minY;
        this.ylim = [Math.floor(this.yMin), Math.ceil(this.yMax)];

        return {
          name: this.data.tracedata[job].job_info["job__job_name"],
          type: "line",
          connectNulls: true,
          data: lineData,
        };
      }
    );

    // its just to make a reference to this.data so i can access it. Otherwise I can't use the this from inside the options obj cause different context. So just make this new variable independent of context which i can use to access it
    const dataRef = this.data;

    this.options = {
      legend: {
        type: "scroll",
        data: this.makeLegendData(),
        padding: 20,
        formatter(name) {
          return name.substring(0, 10);
        },
        tooltip: {
          show: true,
          formatter: function (params) {
            let j = dataRef.results.find((d) => d.job__job_name == params.name);
            return `<span><b>${
              params.name.length > 50
                ? params.name.replace(/(.{50})/g, "$1<br>")
                : params.name
            }</b></span><br><span style='padding-left: 5px;'>${
              j.job__comments || ""
            }</span>`;
          },
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
          restore: {},
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          animation: false,
          label: {
            backgroundColor: "#ccc",
            borderColor: "#aaa",
            borderWidth: 1,
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            color: "#222",
          },
        },
        formatter: function (params) {
          if (params.length == 0) return "";

          let tooltipHtml = `<b>Iteration ${params[0].axisValue} </b><br />`;
          let sortedParams = params
            .map((p) => ({
              marker: p.marker,
              seriesName: p.seriesName,
              value: p.value,
            }))
            .sort((a, b) => b.value - a.value);
          sortedParams.forEach((param) => {
            var name =
              param.seriesName?.length > 50
                ? param.seriesName.replace(/(.{50})/g, "$1<br>")
                : param.seriesName;

            tooltipHtml +=
              `<div style="display: flex; gap: 10px; align-items: center; margin: 10px 0">
                ` +
              `<div>` +
              param.marker +
              `</div><div>` +
              "<b>" +
              name +
              " </b> </div>" +
              `<div>` +
              param.value +
              `</div>` +
              `</div>`;
          });
          console.log(tooltipHtml);
          return tooltipHtml;
        },
      },
      xAxis: {
        type: "category",
        name: "Iterations",
        nameLocation: "middle",
        nameGap: 20,
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 15,
        },
        min: this.xlim[0],
        max: this.xlim[1],
        boundaryGap: false,
        data: xaxisData,
      },
      yAxis: {
        min: this.ylim[0],
        max: this.ylim[1],
        type: "value",
        name:
          this.tracetype == "dc_raw"
            ? "Trace fit RAW"
            : this.tracetype == "fnlc"
            ? "Functional"
            : "Trace fit",
        nameLocation: "middle",
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 15,
        },
        axisLine: {
          show: true,
        },
        axisPointer: {
          show: true,
        },
        minorTick: {
          show: true,
        },
        minorSplitLine: {
          show: true,
        },
      },
      dataZoom: [
        {
          show: true,
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      series: seriesData,
    };
  }

  areFrequencyArraysSimilar(arrays) {
    for (let i = 0; i < arrays.length; i++) {
      for (let j = i + 1; j < arrays.length; j++) {
        const [shorter, longer] =
          arrays[i].length <= arrays[j].length
            ? [arrays[i], arrays[j]]
            : [arrays[j], arrays[i]];

        // Check if `shorter` is the starting sub-array of `longer`
        if (
          !longer
            .slice(0, shorter.length)
            .every((value, index) => value === shorter[index])
        ) {
          return false; // Arrays are not similar
        }
      }
    }
    return true; // All arrays are similar
  }

  checkFrequencyDifference() {
    let frequencies = Object.entries(this.data.tracedata).map(
      ([job, job_data]) => {
        return job_data.job_info["frequency"]?.flatMap((num) =>
          Array(job_data.job_info["iters"])?.fill(num)
        );
      }
    );
    this.areFrequenciesComparable = this.areFrequencyArraysSimilar(frequencies);
  }

  makeLegendData(): any {
    const legend = Object.values(this.data.tracedata)
      .map((k) => ({
        name: k.job_info["job__job_name"],
        // name: k.job_info["job__job_name"].split("-")[0],
        textStyle: {
          ellipsis: true,
          overflow: "truncated",
        },
      }))
      .sort((a, b) => a.name?.localeCompare(b.name));
    return legend;
  }

  // ------------------------------------------------------ HEATMAP ------------------------------------

  plotHeatmap() {
    if (this._route.snapshot.queryParams.jobs) {
      const jobIds = this._route.snapshot.queryParams.jobs;
      this.loadingChart = true;
      this._projectService
        .getTraceFitPlottingData(this.project_id, {
          job_ids: jobIds,
          parameters: [
            this.heatX.replace(/ /g, "_"),
            this.heatY.replace(/ /g, "_"),
          ],
          mode: "parameters",
        })
        .toPromise()
        .then((res) => {
          // this.data = res;
          this.data.results = res.results;
          console.log(res, this.data);
          this.makeHeatMap();
        })
        .catch((err) => this._message.error(err.message))
        .finally(() => (this.loadingChart = false));
    }
  }

  makeHeatMap() {
    const xData = Array.from(
      new Set(
        this.data.results.map((job) =>
          String(job[this.heatX.replace(/ /g, "_")])
        )
      )
    );
    const yData = Array.from(
      new Set(
        this.data.results.map((job) =>
          String(job[this.heatY.replace(/ /g, "_")])
        )
      )
    );

    // heatmapData = [yIndex, xIndex, mapValue]
    let heatmapData: Array<any> = [];
    this.data.results.forEach((job) => {
      let name = job.job__job_name;
      // the pop basically gets the last value
      let lastTraceValue =
        this.data.tracedata[name].iterations_trace[
          Object.keys(this.data.tracedata[name].iterations_trace).pop()
        ];
      let xpos = xData.indexOf(String(job[this.heatX.replace(/ /g, "_")]));
      let ypos = yData.indexOf(String(job[this.heatY.replace(/ /g, "_")]));

      // check if the parameter combo is a repeat
      let d = heatmapData.find((datum) => {
        let [xposPrev, yposPrev, tracePrev] = datum;
        if (xposPrev == xpos && yposPrev == ypos) {
          return datum;
        }
      });

      // if it is a repeat, append to array of traceValues for taking mean later
      if (d) {
        d[2].push(lastTraceValue);
      } else {
        // if not repeat, add the traceValue as an array so that if it is a repeat later, we can append
        heatmapData.push([xpos, ypos, [lastTraceValue]]);
      }
    });

    console.log(
      xData,
      yData,
      heatmapData.map((d) => [d[0], d[1], _.mean(d[2])])
    );

    // take the mean of the arrays of traceValues. if single, no effect, if multiple, gets avg
    heatmapData = heatmapData.map((d) => [d[0], d[1], _.mean(d[2]).toFixed(2)]);

    this.options = {
      tooltip: {
        show: false,
      },
      grid: {
        height: "50%",
        top: "10%",
      },
      xAxis: {
        type: "category",
        data: xData,
        name: this.heatX,
        splitArea: {
          show: true,
        },
      },
      yAxis: {
        type: "category",
        data: yData,
        name: this.heatY,
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        min: 90,
        max: 100,
        calculable: true,
        orient: "horizontal",
        left: "center",
        bottom: "15%",
      },
      series: [
        {
          name: "Tracefit",
          type: "heatmap",
          data: heatmapData,
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          name: "Tracefit2",
          type: "heatmap",
          data: heatmapData,
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  }

  makeHeatMapOption(xKey: string, yKey: string): EChartsOption {
    console.log("preemptive", this.data.results, xKey.replace(/ /g, "_"), yKey);
    const xData = Array.from(
      new Set(
        this.data.results.map((job) => String(job[xKey.replace(/ /g, "_")]))
      )
    );
    const yData = Array.from(
      new Set(
        this.data.results.map((job) => String(job[yKey.replace(/ /g, "_")]))
      )
    );

    // heatmapData = [yIndex, xIndex, mapValue]
    let heatmapData: Array<any> = [];
    this.data.results.forEach((job) => {
      let name = job.job__job_name;
      // the pop basically gets the last value
      let lastTraceValue =
        this.data.tracedata[name].iterations_trace[
          Object.keys(this.data.tracedata[name].iterations_trace).pop()
        ];
      let xpos = xData.indexOf(String(job[xKey.replace(/ /g, "_")]));
      let ypos = yData.indexOf(String(job[yKey.replace(/ /g, "_")]));

      // check if the parameter combo is a repeat
      let d = heatmapData.find((datum) => {
        let [xposPrev, yposPrev, tracePrev] = datum;
        if (xposPrev == xpos && yposPrev == ypos) {
          return datum;
        }
      });

      // if it is a repeat, append to array of traceValues for taking mean later
      if (d) {
        d[2].push(lastTraceValue);
      } else {
        // if not repeat, add the traceValue as an array so that if it is a repeat later, we can append
        heatmapData.push([xpos, ypos, [lastTraceValue]]);
      }
    });

    console.log(
      xData,
      yData,
      heatmapData.map((d) => [d[0], d[1], _.mean(d[2])])
    );

    // take the mean of the arrays of traceValues. if single, no effect, if multiple, gets avg
    heatmapData = heatmapData.map((d) => [d[0], d[1], _.mean(d[2]).toFixed(2)]);

    return {
      tooltip: {
        show: false,
      },
      grid: {
        height: "50%",
        top: "10%",
      },
      xAxis: {
        type: "category",
        data: xData,
        name: xKey,
        splitArea: {
          show: true,
        },
      },
      yAxis: {
        type: "category",
        data: yData,
        name: yKey,
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        min: 90,
        max: 100,
        calculable: true,
        orient: "horizontal",
        left: "center",
        bottom: "15%",
      },
      series: [
        {
          name: "Tracefit",
          type: "heatmap",
          data: heatmapData,
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        {
          name: "Tracefit2",
          type: "heatmap",
          data: heatmapData,
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
  }

  plotHeatmaps() {
    this.options = null;

    let fields = Array.from(
      new Set(
        this.heatmapConfigs
          .map((config) => {
            let [x, y] = [
              config.x.value.replace(/ /g, "_"),
              config.y.value.replace(/ /g, "_"),
            ];
            return [x, y];
          })
          .flat()
      )
    );
    console.log("fields", fields);
    // get the parameters data for the selected params for those jobs
    if (this._route.snapshot.queryParams.jobs) {
      const jobIds = this._route.snapshot.queryParams.jobs;
      this.loadingChart = true;
      this._projectService
        .getTraceFitPlottingData(this.project_id, {
          job_ids: jobIds,
          parameters: fields,
          mode: "parameters",
        })
        .toPromise()
        .then((res) => {
          this.data.results = res.results;
          console.log(res, this.data);
          this.heatmapOptions = this.heatmapConfigs.map((config) => {
            return this.makeHeatMapOption(config.x.value, config.y.value);
          });
        })
        .catch((err) => this._message.error(err.message))
        .finally(() => (this.loadingChart = false));
    }
  }

  onParametersChangedForConfig(params: { name?: string }[]) {
    this.heatmapConfigs[this.activeConfig].treeviewState = params;
    this.heatmapConfigs[this.activeConfig].x.patchValue(
      params[0]?.name || null
    );
    this.heatmapConfigs[this.activeConfig].y.patchValue(
      params[1]?.name || null
    );
  }

  swapHeatmapAxis(config: { x: string; y: string }) {
    [config.x, config.y] = [config.y, config.x];
  }
  appendHeatmapConfig() {
    this.heatmapConfigs.push({
      x: this._fb.control(null),
      y: this._fb.control(null),
      colorRange: [0, 100],
    });
  }
}
