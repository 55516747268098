import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-compare-graph',
  templateUrl: './compare-graph.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./compare-graph.component.css']
})
export class CompareGraphComponent implements OnInit {
  @Input() rawData: any;
  @Input() iteration: number;
  @Input() wideGraph: boolean = false;
  @Input() scale: number[] = null;
  @Input() xAxisName: string;
  @Input() yAxisName: string;
  @Output('point') point: EventEmitter<number[]> = new EventEmitter<number[]>();
  @ViewChild('graph', { static: true }) graph: ElementRef;
  @ViewChild('graphContainer', { static: true }) container: ElementRef;

  private width: number;
  private height: number;
  private margin = {top: 0.1, right: 0.1, bottom: 0.1, left: 0.15};
  private svg;
  private xScale;
  private yScale;
  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.width = this.container.nativeElement.offsetWidth*0.8;
    if (this.wideGraph){
      this.height = this.width*0.375;
      this.margin.left = 0.075;
    }
    else {
      this.height = this.width*0.75;
    }
    if (!this.rawData || this.iteration >= this.rawData.length) return;

    let stepHistData = this.rawData[this.iteration]

    let xValues = this.rawData.filter(d => d !== null).map(d => d[0]).reduce((a, c) => a.concat(c), []);
    let yValues = this.rawData.filter(d => d !== null).map(d => d[1]).reduce((a, c) => a.concat(c), []);

    let xRange = Math.max(...xValues)-Math.min(...xValues);
    let minX = Math.min(...xValues.concat(this.rawData.map(d => d[2]*1.2)))-0.05*xRange;
    let maxX = Math.max(...xValues.concat(this.rawData.map(d => d[2]*1.2)))+0.05*xRange;

    let minY = Math.min(...yValues)
    let maxY = Math.max(...yValues)
    if (minY == maxY) {
      minY *= 0.9;
      maxY *= 1.1;
    }

    if (this.scale[0] != null) {
      minY = this.scale[0];
    }
    if (this.scale[1] != null && this.scale[1] > minY) {
      maxY = this.scale[1];
    }

    let yRange = maxY - minY;
    minY = minY-0.05*yRange;
    maxY =  maxY+0.05*yRange;
    this.setUPCanvas(minX, maxX, minY, maxY);

    if (!stepHistData) return

    let xData = stepHistData[0];
    let yData = stepHistData[1];
    let chosenValue = stepHistData[2];

    this.draw(xData, yData, minY, maxY, chosenValue);
  }

  private setUPCanvas(minX, maxX, minY, maxY) {
    let xScale = d3.scaleLinear()
                   .domain([minX, maxX])
                   .range([0, this.width]);
    let yScale = d3.scaleLinear()
                   .domain([minY, maxY])
                   .range([this.height, 0]);
    let svg = d3.select(this.graph.nativeElement)
                .attr("width", this.width*(1+this.margin.left+this.margin.right))
                .attr("height", this.height*(1+this.margin.top+this.margin.bottom))
                .append("g")
                .attr("transform", 'translate('+this.width*this.margin.left+', '+this.height*this.margin.top+')');
    svg.append("g").attr("transform", 'translate(0,'+this.height+')').call(d3.axisBottom(xScale));
    svg.append("g").call(d3.axisLeft(yScale));

    svg.append("text").attr("transform", 'translate('+ (this.width/2) +','+(this.height+40)+')')
       .text(() => this.xAxisName).style("font-weight", "bold")
    svg.append("text").attr("transform", 'translate(-90,'+ this.height/2 +')')
       .text(() => this.yAxisName).style("font-weight", "bold")
    this.svg = svg;
    this.xScale = xScale;
    this.yScale = yScale
  }

  private draw(xData: number[], yData: number[], minY: number, maxY: number, chosenValue?: number) {
    let svg = this.svg;
    let xScale = this.xScale;
    let yScale = this.yScale;
    let data: number[][] = xData.map((e, i) => [e, yData[i]]).filter(d => d[1] != undefined && d[1] !== null);
    data.sort((a, b) => a[0]-b[0])
    let line = d3.line().x(function(d: number[]) {return xScale(d[0]);})
                        .y(function(d: number[]) {return yScale(d[1]);});
    // Add path
    svg.append("g").selectAll("path").data([data]).enter().append("path").attr("class", "fline").attr("d",line).attr("clip-path", "url(#rect_clip)");
    // Add nodes
    svg.append("g").selectAll("circle").data(data).enter().append("circle")
       .attr("cx", function(d: number[]) {return xScale(d[0])})
       .attr("cy", function(d: number[]) {;return yScale(d[1])}).attr("r", 5)
       .on("mouseover", function() {d3.select(this).attr("r", 8).style("opacity", 0.7)})
       .on("mouseout", function() {d3.select(this).attr("r", 5).style("opacity", 1)})
       .on("click",(d: number[]) => {this.point.emit(d)})
       .attr("clip-path", "url(#rect_clip");
    svg.append("clipPath").attr("id", "rect_clip").append("rect").attr("x", 0).attr("y", 0).attr("height", this.height).attr("width", this.width);

    svg.append("g").append("path").attr("d", line([[chosenValue, minY], [chosenValue, maxY]])).attr("class", "chosen-line")

  }
}
