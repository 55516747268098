<h3 mat-dialog-title>EPICMod Comment Setting</h3>

<form (ngSubmit)="submit()" [formGroup]="epicmod_comments_setting_form">
  <mat-dialog-content>
    <h5>{{data.epicmod_name}}</h5>
    <mat-form-field>
      <input type="text" matInput placeholder="Comment" formControlName="comments">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="button-container">
    <button mat-raised-button color="primary" type="submit">Update</button>
    <button mat-raised-button mat-dialog-close color="basic" type="reset">Cancel</button>
  </mat-dialog-actions>
</form>
