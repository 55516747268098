import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { ShowAllFiltersDialogComponent } from "../../../project/dialogs/show-all-filters-dialog/show-all-filters-dialog.component";
import { always_hide_from_changing_list } from "../../parameter-overview-2/fields";

interface LinkDefinition {
  key: string;
  template: (row: object) => string;
}

export interface ButtonConfig {
  text: string;
  action: (event: MouseEvent, info: any) => void;
}

@Component({
  selector: "app-custom-table",
  templateUrl: "./custom-table.component.html",
  styleUrls: ["./custom-table.component.less"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTableComponent implements OnInit, OnChanges {
  sortingKey: string = ""; // key of the column being sorted
  sortingMode: string = "desc"; // sorting mode of the sorting column
  filteredData: Array<Object> = []; // filtered data being shown. this.data contains the whole data, this contains the filtered subset
  keysObject: Object = {};
  displayKeysArray: Array<Object> = []; // the array of the keys being displayed. In the form of Array of objects. {key, label, hide}
  pageCount: number = 0;
  currentPage: number = 0;
  pageSize: number = 50;

  totalRows: number = 0; // the total cound of rows in the data. Used for showing the cound of rows on the top. Length of this.data
  showingRows: number = 0; // the count of the rows showing. Used for showing the count of rows on the top. Length of this.filteredData

  @Input() buttonConfig: Array<ButtonConfig> = []; // this is a config for buttons to be added to header. This is mainly for ones that want to do somethin with the state/data of the customTable from the parent component. Show example in ParameterOverview2

  @Input() isParameterView: Boolean = false; // for when the datable is of parameterView. It has some special filteration functions hence made

  @Input() data: Array<any>; // Array of objects containing the whole data
  @Input() columns: Object = {}; // Columns in the form of a tree. Categories: [children...]
  @Input() columnsFlattened: Array<string> = []; // Columns in the form of an array. this just has the keys basically. No info on the categories
  @Input() dateKeys: string[] = []; // the keys that will be treated as dates, i.e. converted to date objects. Must have values that can be converted
  @Input() rowIdKey: string = null; // the key that will be used from the row data to proved ID to the <tr> containing that datum

  @Input() collapsibleDetail: Boolean = false; // boolean to specify if there will be collapsible thingy
  // function that will output the content of the collapsible object. Expects HTML as string for return
  @Input() collapsibleBodyFunction: (row: object) => string = (row: object) =>
    null;

  @Input() loading: boolean; // boolean for loading. Used for the loading indicator
  @Input() editRow: boolean = false; // boolean specifying if row editting will be possible
  @Input() deleteRow: boolean = false; // boolean specifying if row deletion will be possible
  @Input() highlightColor: string = null; // must be hex code. Can be 8 digit hex for opacity
  @Input() highlightControlKey: string = null; // key that will be used/checked for highliting rows. Must be a boolean field i think
  @Input() removalColumns: string[] = []; // Columns which will neve be showed. Though I suggest simply removing those columns from the fields declaration being passed to columns
  // function for mapping the headers. Transformation basically. By default it capitalizes, and replaces '_' with ' ', but you can provide your own.
  @Input() headerMappingFunction: (key: string) => string = (key: string) =>
    key
      .split("_")
      .map((word) => word[0].toLocaleUpperCase() + word.substring(1))
      .join(" ");

  @Input() linkDefinitions: Array<LinkDefinition> = []; // object containing the keys and format of the href of the links. Look at the usage and interface, you'll understand
  allLinkKeys: Array<string> = []; // helper storing all the linkKeys

  @Input() truncationFields: Array<string> = []; // fields to be truncated. Specify the keys of those columns

  @Input() stickyFieldKeys: Array<string> = ["job_name"];
  @Input() defaultShowingColumns: Array<string> = [];

  @Output() editRowHandler = new EventEmitter<object>(); // handler for when edit clicked
  @Output() deleteRowHandler = new EventEmitter<object>(); // handler for when delete clicked

  headerFilterationObject: Object = {}; // this stores all the applied filters from the headers filter box. It is used for multi column filtering
  allFilterationOptions: Object = {}; // an object with format < key : Set >. The set is basically all the unique values in that column. Used in the header fileration menu. Made on data change to reduce resource utilization

  defaultSelectedColumns: Array<any> = []; // an array of the default selected columns for the table and filter box. Mainly used for propogating state to the filter box

  // -----------------------------------
  // specific vars for parameter view special filtering (matching jobs)
  // -----------------------------------
  matchingJobOptions: Object = {}; // same as this.allFilterationOptions. Probably redundant
  showChangingColumnsControl: Boolean = false;
  preChangingColumnsOnlyMemory: Array<Object> = []; // this is only for saving the state of the pre toggle thing. So basically if you toggle it, the state will be saved, when you untoggled this will be loaded. That's all this is used for.

  constructor(public dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.transformKeys(changes.columns.currentValue);
      this.columnsFlattened = [].concat(
        ...Object.values(changes.columns.currentValue)
      );
      if (this.isParameterView) {
        this.showChangingColumns();
      } else {
        this.hideNullColumns();
      }
    }
    if (changes.data) {
      this.totalRows = this.data.length;
      this.showingRows = this.data.length;
      this.filteredData = this.data;

      Object.values(this.keysObject).forEach((keys: Array<any>) => {
        keys.forEach((key) => {
          const valsArray = [];
          this.data.forEach((datum) => {
            valsArray.push(datum[key["key"]]);
          });

          if (valsArray.length > 0) {
            this.matchingJobOptions[key["key"]] = new Set(valsArray);
          }
        });
      });

      this.allFilterationOptions = { ...this.matchingJobOptions };

      if (this.isParameterView) {
        this.showChangingColumns();
      } else {
        this.hideNullColumns();
      }

      this.pageCount = Math.ceil(this.data.length / this.pageSize);
      this.currentPage = 0;
    }
  }

  ngOnInit() {
    this.allLinkKeys = this.linkDefinitions.map((definition) => definition.key);
  }

  transformKeys(keys: Object) {
    const finalKeyObj = {};
    const displayKeys = [];
    for (const [key, value] of Object.entries(keys)) {
      finalKeyObj[key] = [];
      for (const leaf of value) {
        finalKeyObj[key].push({
          label: this.headerMappingFunction(leaf),
          key: leaf,
          hide: false,
        });
        displayKeys.push({
          label: this.headerMappingFunction(leaf),
          key: leaf,
          hide: false,
        });
      }
    }
    this.keysObject = finalKeyObj;

    // this last filter just ALWAYS removes the columns we specified to remove
    this.displayKeysArray = displayKeys.filter(
      (key) => !this.removalColumns.includes(key.key)
    );
  }

  getEntryValue(value: any, key: string, row: object = {}) {
    if (key == "parent_job_name" && (value == "" || !value)) {
      return "No Parent Job";
    }

    if (this.allLinkKeys.length > 0 && this.allLinkKeys.includes(key)) {
      if (value && value != "") {
        let linkTemplate = this.linkDefinitions.find((defs) => defs.key == key);

        if (this.truncationFields.includes(key)) {
          return `<a 
                    class='overflow-ellipsis-truncation'
                    title='${value}' 
                    href=${linkTemplate.template(row)}
                    target='_blank'
                  >
                   ${value}
                  </a>`;
        } else {
          return `<a  href=${linkTemplate.template(row)}>${value}</a>`;
        }
      } else return "";
    }

    if (this.truncationFields.includes(key)) {
      return `<span class='overflow-ellipsis-truncation' title='${value}'>${value}</span>`;
    }

    if (this.dateKeys.includes(key)) {
      if (value) {
        const dateTime = value;
        return new Intl.DateTimeFormat(undefined, {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        }).format(dateTime);
      } else {
        return "";
      }
    } else {
      return value;
    }
  }

  applyColumnHiding(selectedHeaders: Array<string>) {
    this.showChangingColumnsControl = false;
    this.preChangingColumnsOnlyMemory = [];

    const finalKeyObj = {};
    const displayKeys = [];
    for (const [key, value] of Object.entries(this.keysObject)) {
      finalKeyObj[key] = [];
      for (const leaf of value) {
        let hide = selectedHeaders.includes(leaf.key);
        finalKeyObj[key].push({ ...leaf, hide: hide });
        if (hide) {
          displayKeys.push(leaf);
        }
      }
    }
    this.keysObject = finalKeyObj;
    // this last filter just ALWAYS removes the columns we specified to remove
    this.displayKeysArray = displayKeys.filter(
      (key) => !this.removalColumns.includes(key.key)
    );
  }

  activateRowCollapse(el: HTMLElement, row: object) {
    const collapsibleBody = el.nextElementSibling as HTMLElement;
    const collapsibleTrigger = el.querySelector(
      ".collapsible-row-trigger > div"
    ) as HTMLElement;

    if (
      collapsibleBody.style.display == "none" ||
      collapsibleBody.style.display == ""
    ) {
      collapsibleBody.style.display = "table-row";
      collapsibleBody.children[0].innerHTML = this.collapsibleBodyFunction(row);
      collapsibleTrigger.style.rotate = "360deg";
      collapsibleTrigger.innerHTML = `<i class="zmdi zmdi-minus-circle-outline"></i>`;
    } else {
      collapsibleBody.style.display = "none";
      collapsibleTrigger.style.rotate = "0deg";
      collapsibleTrigger.innerHTML = `<i class="zmdi zmdi-plus-circle-o"></i>`;
    }
  }

  getFilterValuesForColumn(column: string, searchString: HTMLInputElement) {
    if (!searchString.value || searchString.value == "") {
      return this.allFilterationOptions[column];
    } else {
      return new Set(
        this.data
          .map((data) => data[column])
          .filter((datum) => {
            if (datum)
              return datum
                .toString()
                .toLowerCase()
                .includes(searchString.value.toLowerCase());
            else return false;
          })
      );
    }
  }

  deselectAllSearch(key: string) {
    this.headerFilterationObject[key] = Array.from(
      this.allFilterationOptions[key]
    );
  }

  selectAllSearch(key: string) {
    if (Object.keys(this.headerFilterationObject).includes(key)) {
      delete this.headerFilterationObject[key];
    }
  }

  searchFilterSelected(
    event: MatCheckboxChange,
    key: string,
    searchValue: string
  ) {
    if (!event.checked) {
      // multiColumn
      if (Object.keys(this.headerFilterationObject).includes(key)) {
        this.headerFilterationObject[key].push(searchValue);
      } else {
        this.headerFilterationObject[key] = [searchValue];
      }
    } else {
      // multi column
      this.headerFilterationObject[key] = this.headerFilterationObject[
        key
      ].filter((val: string) => val != searchValue);
      if (this.headerFilterationObject[key] == 0) {
        delete this.headerFilterationObject[key];
      }
    }
  }

  removeAllFilters() {
    this.headerFilterationObject = {};
    this.runSearch();
  }

  runSearch() {
    // if searching for nothing, return the original data
    if (Object.keys(this.headerFilterationObject).length == 0) {
      this.filteredData = this.data;
      this.pageCount = Math.ceil(this.data.length / this.pageSize);
      this.currentPage = 0;
      this.showingRows = this.data.length;
      return;
    }

    const newSearchedData = this.data.filter((datum: object) => {
      for (let [field, values] of Object.entries(
        this.headerFilterationObject
      )) {
        if (values.includes(datum[field])) {
          return false;
        }
      }
      return true;
    });

    this.filteredData = newSearchedData;
    this.pageCount = Math.ceil(newSearchedData.length / this.pageSize);
    this.currentPage = 0;
    this.showingRows = newSearchedData.length;
    this.showChangingColumnsControl = false;
    this.sorter("", true);
  }

  sorter(key: string, retain: boolean = false) {
    if (!retain) {
      if (this.sortingKey == key) {
        if (this.sortingMode == "asc") {
          this.sortingMode = "desc";
        } else {
          this.sortingMode = "asc";
        }
      } else {
        this.sortingMode = "asc";
      }
      this.sortingKey = key;
    }

    this.filteredData.sort((a, b) => {
      if (!a[key] || a[key] === null) {
        return 1;
      }
      if (!b[key] || b[key] === null) {
        return -1;
      }

      if (!isNaN(a[key]) && !isNaN(b[key])) {
        if (this.sortingMode == "desc") return a[key] - b[key];
        else return b[key] - a[key];
      }

      if (a[key].toLowerCase() === b[key].toLowerCase()) {
        return 0;
      }

      if (this.sortingMode == "asc") {
        return a[key].toLowerCase() < b[key].toLowerCase() ? -1 : 1;
      }
      return a[key].toLowerCase() < b[key].toLowerCase() ? 1 : -1;
    });
  }

  // ------------------------------------------
  // helper functions
  // ------------------------------------------

  getObjectKeys(obj: object) {
    return Object.keys(obj);
  }

  getObjectValues(obj: object) {
    return Object.values(obj);
  }

  toggleUglyFilterShow(event: MouseEvent) {
    event.stopPropagation();
    const els = document.getElementsByClassName("mat-menu-panel");
    for (let index = 0; index < els.length; index++) {
      const element = els[index] as HTMLElement;
      if (element.style.maxWidth != "2000px") {
        element.style.maxWidth = "2000px";
      } else {
        element.style.maxWidth = "280px";
      }
    }
  }

  showAppliedFilterDialog() {
    this.dialog.open(ShowAllFiltersDialogComponent, {
      data: {
        data: this.data,
        filters: this.headerFilterationObject,
      },
    });
  }

  // --------------------------------------------
  // Parameter View specific filtering functions
  // --------------------------------------------

  applyDiff(args: object) {
    let selectedJob = args["selectedJob"];
    let exclusionList = args["exclusionList"];
    let diffLimit = args["diffArg"];

    const diffData = this.data.filter((datum: object) =>
      deepEqualityChecker(
        selectedJob,
        datum,
        this.columnsFlattened,
        diffLimit,
        exclusionList
      )
    );

    this.filteredData = diffData;
    this.pageCount = Math.ceil(diffData.length / this.pageSize);
    this.currentPage = 0;

    this.showingRows = diffData.length;
  }

  hideNullColumns() {
    let nonNullColumns = [];
    let currentColumns: Array<string>;

    if (this.defaultShowingColumns.length > 0) {
      currentColumns = this.defaultShowingColumns;
    } else {
      currentColumns = this.displayKeysArray.map(
        (keyObj: Object) => keyObj["key"]
      );
    }

    currentColumns.forEach((column: string) => {
      const columnSet = new Set(
        this.filteredData.map((datum: Object) => datum[column])
      );

      if (columnSet.size == 1 && (columnSet.has(null) || columnSet.has(""))) {
        return;
      }
      nonNullColumns.push(column);
    });

    this.displayKeysArray = [
      ...this.displayKeysArray.filter((keyObj: object) =>
        nonNullColumns.includes(keyObj["key"])
      ),
    ];
    this.defaultSelectedColumns = [...this.displayKeysArray];
  }

  showChangingColumns() {
    this.showChangingColumnsControl = !this.showChangingColumnsControl;
    // construct Set from the this.filteredData for every column
    // show only the columns where the values are different

    if (this.showChangingColumnsControl) {
      let changingColumns = [];

      let currentColumns = this.displayKeysArray.map(
        (keyObj: Object) => keyObj["key"]
      );

      currentColumns.forEach((column: string) => {
        const columnSet = new Set(
          this.filteredData.map((datum: Object) => datum[column])
        );

        if (columnSet.size > 1) {
          changingColumns.push(column);
        }
      });

      // remove the pointless ones from showing the changing columns
      changingColumns = changingColumns.filter(
        (col) => !always_hide_from_changing_list.includes(col)
      );

      this.preChangingColumnsOnlyMemory = [...this.displayKeysArray];
      this.displayKeysArray = [
        ...this.displayKeysArray.filter((keyObj: object) =>
          changingColumns.includes(keyObj["key"])
        ),
      ];
      this.defaultSelectedColumns = [...this.displayKeysArray];
    } else {
      this.displayKeysArray = [...this.preChangingColumnsOnlyMemory];
      this.defaultSelectedColumns = [...this.displayKeysArray];
      this.preChangingColumnsOnlyMemory = [];
    }
  }
}

function deepEqualityChecker(
  obj1: Object,
  obj2: Object,
  keyList: Array<string>,
  diffLimit: number = 0,
  exclusionList: Array<string> = [],
  log: Boolean = false
) {
  let countDiff = 0;
  // for (let [key, value] of Object.entries(obj1)) {
  for (let key of keyList) {
    // if key in exclusionlist --> next key
    if (exclusionList.includes(key)) continue;
    if (key.toLowerCase().includes("id")) continue;

    if (obj2[key] != obj1[key]) {
      if (log)
        console.log("difference: ", key, "-->", obj1[key], " == ", obj2[key]);

      countDiff += 1;
      // for early termination. If the difference count over limit, return false i.e. don't return the object
      if (countDiff > diffLimit) {
        if (log) console.log("---------------------------");
        return false;
      }
    }
  }
  if (log) console.log("---------------------------");
  return true;
}
