<!-- <app-banner heading="Projects" description="Select your project or create a new one to add your data" *ngIf="!authService.isDemoUser() && isStaff" button-text="Parameter View" (button-event)="gotoMonitoring()"></app-banner>
<app-banner heading="Projects" description="Select your project or create a new one to add your data" *ngIf="!authService.isDemoUser() && !isStaff"></app-banner>
<app-banner heading="Projects" description="Select your project or create a new one to add your data" *ngIf="authService.isDemoUser()"></app-banner>

<section class="content apc standard-components  c5-bg" id="pos_1404">
  
  <div class="container" data-os-animation="fadeIn" data-os-animation-delay="0">
    <div class="row">
      <div class="spc accordion">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <button nz-button nzBlock nzType="dashed" (click)="projectModalVisible = true">
            <span nz-icon nzType="plus" nzTheme="outline"></span> Create New Project
          </button>
          <div role="tab" *ngFor="let project of projects; let i = index">
            <p class="heading">
              <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapse-1293-1" aria-expanded="false" (click)="clickProject(project)">
                <div style="display: flex; justify-content: space-between;">
                  <p class="heading">{{project.name}}</p>
                  <span *ngIf="isActiveProject(project)" style="margin-right: 50px;" (click)="navigateToProjectTable(project)">Project Page</span>
                </div>
                <div class="text"><p><em>{{project.description}}</em></p></div>
                <i *ngIf="isActiveProject(project)" class="zmdi zdmi-minus"></i><i *ngIf="!isActiveProject(project)" class="zdmi zdmi-plus"></i>
                <app-list [@slideIn] [hidden]="!isActiveProject(project)" [list]="jobItems[project.id]"></app-list>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section
  class="content apc standard-components c5-bg content-container-div"
  id="pos_1404"
  style="background-color: #2196f3"
>
  <div style="width: 800px; margin: auto">
    <p class="heading os-animation fadeInUp animated">Projects</p>
    <div
      class="text c5-text os-animation fadeInUp animated"
      data-os-animation="fadeInUp"
      data-os-animation-delay="0.2s"
      style="animation-delay: 0.2s"
    >
      <p>Select your project or create a new one to add your data</p>
    </div>

    <div style="margin: 10px 0">
      <div style="display: flex; justify-content: space-between">
        <div>
          <button
            nzGhost
            nz-button
            [disabled]="!this.selectedProject"
            (click)="goToProjectPage()"
          >
            Project Page
          </button>
        </div>

        <div style="display: flex; gap: 20px">
          <button nz-button nzGhost (click)="gotoMonitoring()">
            Parameter View
          </button>
          <button
            *ngIf="this.isStaff"
            nz-button
            nzGhost
            (click)="goToCostAnalysis()"
          >
            Cost Analysis
          </button>
        </div>
      </div>
    </div>

    <div style="display: flex; gap: 0px 10px; margin: 10px 0; flex-wrap: wrap;">
      <nz-input-group style="width: 395px">
        <label style="color: white; font-size: 20px; margin: 0">
          Field
        </label>
        <nz-select
          style="width: 395px; color: white"
          nzShowSearch
          nzAllowClear
          [nzFilterOption]="searchFn"
          [(ngModel)]="selectedField"
          (ngModelChange)="onFieldSelected()"
        >
          <nz-option nzLabel="No Field" nzValue=""></nz-option>
          <nz-option
            *ngFor="let field of fields"
            [nzValue]="field"
            [nzLabel]="field.name"
          >
            {{ field.name }}
          </nz-option>
        </nz-select>
      </nz-input-group>

      <nz-input-group style="width: 395px">
        <label style="color: white; font-size: 20px; margin: 0">
          Project
        </label>
        <nz-select
          style="width: 395px; color: white"
          nzShowSearch
          [nzFilterOption]="searchFn"
          [(ngModel)]="selectedProject"
          (ngModelChange)="getProjectJobs()"
        >
          <nz-option
            *ngFor="let project of filteredProjects"
            [nzValue]="project"
            [nzLabel]="project.name"
          >
            {{ project.name }}
          </nz-option>
        </nz-select>
      </nz-input-group>

      <nz-input-group style="width: 800px">
        <label style="color: white; font-size: 20px; margin: 0"> Job </label>
        <nz-select
          style="width: 800px; color: white"
          nzShowSearch
          [nzDisabled]="!selectedProject"
          [nzFilterOption]="searchFn"
          [(ngModel)]="selectedJob"
          (ngModelChange)="onJobSelected($event)"
        >
          <nz-option
            *ngFor="let job of projectJobs"
            [nzValue]="job"
            [nzLabel]="job.name"
          >
            {{ job.name }}
          </nz-option>
        </nz-select>
      </nz-input-group>
    </div>

    <div style="color: white; white-space: pre-wrap">
      {{ projectDescriptipon }}
    </div>

    <button
      nz-button
      nzGhost
      nzBlock
      nzType="dashed"
      (click)="projectModalVisible = true"
      style="margin: 10px 0"
      *ngIf="!authService.isDemoUser()"
    >
      <span nz-icon nzType="plus" nzTheme="outline"></span> Create New Project
    </button>
  </div>
</section>

<app-fields-listing [demoUser]="authService.isDemoUser()"></app-fields-listing>

<nz-modal
  [nzVisible]="projectModalVisible"
  nzTitle="Create New Project"
  (nzOnCancel)="projectModalVisible = false"
  (nzOnOk)="createProject()"
>
  <ng-container *nzModalContent>
    <form
      nz-form
      [formGroup]="projectFormGroup"
      (ngSubmit)="createProject()"
      class="form-container"
    >
      <nz-form-item>
        <nz-form-label for="name" nzRequired>Project Name</nz-form-label>
        <nz-form-control nzErrorTip="Please input a Project Name">
          <input nz-input type="text" formControlName="name" />
        </nz-form-control>
      </nz-form-item>
      
      <nz-form-item>
        <nz-form-label for="Field" nzRequired>Project Field</nz-form-label>
        <nz-form-control nzErrorTip="Please select a Field">
          <!-- <input nz-input type="text" formControlName="name" /> -->
          <nz-select [nzLoading]="isLoading" [nzDisabled]="isLoading" formControlName="field">
            <nz-option *ngFor="let field of fields" [nzValue]="field.id" [nzLabel]="field.name"></nz-option>
          </nz-select>  
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label for="description">Project Description</nz-form-label>
        <nz-form-control>
          <textarea
            nz-input
            type="text"
            formControlName="description"
          ></textarea>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control
          nzExtra="Projects with Archive Enabled will automatically have their jobs archived after 7 days, unless specfically selected to be kept indefinitely."
        >
          <label nz-checkbox formControlName="archiveEnabled"
            >Archive Enabled</label
          >
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
</nz-modal>
