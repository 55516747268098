import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ProjectDialog } from '../../../../models/projectDialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-project-name',
  templateUrl: './project-name.component.html',
  styleUrls: ['./project-name.component.less']
})
export class ProjectNameComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) form;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ProjectDialog, public dialogRef: MatDialogRef<ProjectNameComponent>) { }

  ngOnInit() {
  }

  submitForm() {
    if (!this.form.valid)
      return;

    this.dialogRef.close(this.data);
  }
}
