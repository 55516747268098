<!-- Opt in for antd migration -->
<ng-container *ngIf="antd; else material">
  <div class="_antd-custom-slider-container" style="width: 100%; margin-bottom: 10px; padding-right: 10px">
    <nz-row>
      <nz-col
        nzFlex="100px"
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          top: 5px;
          padding-right: 3px;
        "
        >{{ label }}</nz-col
      >
      <nz-col nzFlex="1 1 auto" style="position: relative; max-width: 300px; top: 7px;">
        <nz-slider
          [nzMin]="min"
          [nzMax]="max"
          [(ngModel)]="value"
          (nzOnAfterChange)="changeValue($event)"
          class="_custom-slider"
        ></nz-slider>
        <div style="position: absolute; bottom: -4px; font-size: 10px;">{{ min }}</div>
        <div style="position: absolute; bottom: -4px; font-size: 10px; right: 0">{{ max }}</div>
      </nz-col>

      <nz-col nzFlex="0 0 250px" style="display: flex">
        <div class="_slider-number-input">
          <nz-input-number
            nzSize="small"
            [nzMin]="min"
            [nzMax]="max"
            [ngStyle]="{ marginLeft: '15px', width: '70px' }"
            [(ngModel)]="value"
            (ngModelChange)="changeValue($event)"
          ></nz-input-number>
        </div>
        <nz-divider nzType="vertical" style="height: 100%" />
        <div class="_slider-number-input">
          Step:
          <nz-input-number
            [nzMin]="min"
            nzSize="small"
            [nzMax]="max"
            [(ngModel)]="inputStep"
            (ngModelChange)="stepChanged($event)"
            [ngStyle]="{ marginLeft: '0px', width: '50px' }"
          ></nz-input-number>
        </div>

        <button class="step-change" color="primary" (click)="minusClicked()">
          <i class="zmdi zmdi-minus"></i>
        </button>
        <button class="step-change" color="primary" (click)="plusClicked()">
          <i class="zmdi zmdi-plus"></i>
        </button>
      </nz-col>
    </nz-row>
  </div>
</ng-container>

<ng-template #material>
  <div class="slider-group">
    <div class="slider" #slider>
      <mat-slider
        [min]="min"
        [max]="max"
        step="1"
        [(ngModel)]="value"
        (ngModelChange)="changeValue($event)"
        ngDefaultControl
        style="flex-grow: 1"
        [discrete]="true"
        #ngSlider
      >
        <!-- thumbLabel="true" -->
        <!-- <input matSliderThumb (input)="liveChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" /> -->
        <input matSliderThumb [(ngModel)]="value" #slider />
      </mat-slider>
      <div class="slider-label min">{{ min }}</div>
      <div class="slider-label max">{{ max }}</div>
    </div>
    <mat-form-field class="slider-input hidden-sm hidden-xs">
      <input
        matInput
        [(ngModel)]="inputValue"
        ngDefaultControl
        (ngModelChange)="changeValue($event)"
        type="number"
        input-number="true"
      />
    </mat-form-field>
    <div class="step hidden-sm hidden-xs">
      <div class="border">&nbsp;</div>
      <span>Step:</span>
      <mat-form-field style="width: 100px" class="step-input">
        <input
          matInput
          [(ngModel)]="inputStep"
          ngDefaultControl
          (ngModelChange)="stepChanged($event)"
          type="number"
          input-number="true"
        />
      </mat-form-field>
      <button color="primary" (click)="minusClicked()">
        <i class="zmdi zmdi-minus"></i>
      </button>
      <button color="primary" (click)="plusClicked()">
        <i class="zmdi zmdi-plus"></i>
      </button>
    </div>
  </div>
</ng-template>
