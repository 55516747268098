
import * as d3 from 'd3';
import { Subject } from 'rxjs';
import { SliceType } from '../../../models/sliceType';
import { Slice } from '../../../models/slice';



/*

This code is based on following convention:

https://github.com/bumbeishvili/d3-coding-conventions/blob/84b538fa99e43647d0d4717247d7b650cb9049eb/README.md


*/

export class D3Chart {
  public zoomIn: () => void;
  public zoomOut: () => void;
  public reset: () => void;

  public attrs = {
    id: "ID" + Math.floor(Math.random() * 1000000),  // Id for event handlings
    dotLineX: null,
    dotLineY: null,
    dotLineVisibility: true,
    svgWidth: 400,
    svgHeight: 400,
    marginTop: 10,
    reverseY: false,
    marginBottom: 105,
    marginRight: 25,
    marginLeft: 75,
    container: 'body',
    defaultTextFill: '#2C3E50',
    defaultFont: 'Helvetica',
    gradientHTML: '',
    backRect: '#fff',
    zoomedAtLeastOnce: false,
    hoverLineWidth: 2,
    gridSpacing: 1,
    xTicks: 6,
    yTicks: 7,
    xTitle: '',
    yTitle: '',
    yScale: {
      maxRange: null,
      maxDomain: null
    },
    xScale: {
      maxRange: null,
      maxDomain: null
    },
    yLegend: {
      maxRange: null,
      maxDomain: null,
      minDomain: null
    },
    yAxisContainer: { x: 65, y: 53 },
    xAxisContainer: { x: 70, y: 62 },
    ticks: { fontSize: 12, fontFamily: 'Open Sans', fill: 'rgb(68, 68, 68)' },
    imageContainer: { x: 65, y: 48 },
    image: { width: 1106, height: 390 },
    velocityLegend: {
      x: 1220, y: 60, width: 20, height: 370,
      style: 'fill: url("#g236e71-cb794399");stroke: rgb(68, 68, 68); stroke-opacity: 1; stroke-width: 1;'
    },
    legendAxis: { x: 1287, y: 50 },
    legendTitle: {
      translate: null,
      velocityTranslate: null,
      inlineTranslate: null,
      style: 'font-family: "Open Sans", verdana, arial, sans-serif; font-size: 10px; fill: #444w; opacity: 1; font-weight: normal; white-space: pre;',
      depthX: -40, depthY: 190, rotate: 'rotate(-90)', inlineX: 600, inlineY: 480, velocityX: -20, velocityY: 1380,
      text: "velocity",
      unit: "(m/s)"
    },
    data: null,
    lastTransform: {
      k: 1,
      x: 0,
      y: 0,
      rescaleX: null,
      rescaleY: null
    },
    legendImage: null,
    legendVisibility: false,
    defaultZoomLevel: 10,
    zoomActions: [],
    xAxisGroup: null,
    yAxisGroup: null,
    lastScaleX: null,
    lastScaleY: null,
    resized: false,
    legendRects: 200,
    dataMatrix: null,
    solidLineData: [],
    lightLineData: [],
    lineOverlayX: null,
    lineOverlayY: null,
    lineOverlayHeight: null,
    lineOverlayWidth: null,
    solidDotData: null,
    solidLineWidth: 2,
    lightLineWidth: 0.2,
    hoverLabelX: null,
    hoverLabelY: null,
    imgHoverG: null,
    pointHoverG: null,
    scales: null,
    axes: null,
    zeroAxisTick: null,
    xAxisLastTick: null,
    solidLine: null,
    lightLine: null,
    solidDot: null,
    zeroLineOffset: null,
    shotOverlayData: null,
    shotOverlayColorRange: null,
    shotOverlayPointRadius: 2,
    shotsOverlayLegendsVisibility: false,
    shotOverlayCircles: null,
    shotsOverlayErrorMessage: null,
    shotLegend: null,
    shotLegendProps: {
      x: 1220,
      y: 70,
      width: 320,
      height: 5,
      style: 'fill: url("#g236e71-cb794399");stroke: rgb(68, 68, 68); stroke-opacity: 1; stroke-width: 1;'
    },
    shotLegendTitle: {
      translate: null,
      velocityTranslate: null,
      inlineTranslate: null,
      style:
        'font-family: "Open Sans", verdana, arial, sans-serif; font-size: 10px; fill: #444w; opacity: 1; font-weight: normal; white-space: pre;',
      depthX: -40,
      depthY: 190,
      rotate: 'rotate(-90)',
      inlineX: 600,
      inlineY: 480,
      velocityX: -20,
      velocityY: 1380
    },
    shotLegendAxis: {
      x: 0,
      y: null
    },
    normalLine: null,
    normalLineColor: 'white',
    normalLineColors:['white','orange','purple','cyan','black'],
    normalLineData: null,
    normalLineWidth: 2,
    dotted_line_color:'white',
    // following properties are used for depth range
    sliceType: null,
    startDepth: null, // meters, start from 0
    endDepth: null, // meters, start from 0
    startInline: null, // meters, start from 0
    endInline: null, // meters, start from 0
    startCrossline: null, // meters, start from 0
    endCrossline: null, // meters, start from 0
    dataMatrixSliced: null,
    yIndexOffset: null,
    xIndexOffset: null,
  };

  public clickedPosition = new Subject<any>();

  //Main chart object
  public async run() {
    var attrs = this.attrs;
    //Calculated properties
    //@ts-ignore TS2339
    var calc = {
      id: null,
      chartLeftMargin: null,
      chartTopMargin: null,
      chartWidth: null,
      chartHeight: null,
      xRangeWeWantAdd: null,
      xDomainRangeProportion: null,
      xDomainWeShouldAdd: null,
      yRangeWeWantAdd: null,
      yDomainRangeProportion: null,
      yDomainWeShouldAdd: null,
      canvasDimensions: null,
      maxSolidX: null,
      minSolidX: null,
      minLightX: null,
      maxLightX: null,
      maxSolidY: null,
      maxLightY: null,
      minSolidY: null,
      minLightY: null,
      overlayRectheight: null,
      eachShotLegendWidth: null
    };

    calc.id = "ID" + Math.floor(Math.random() * 1000000);  // id for event handlings
    calc.chartLeftMargin = attrs.marginLeft;
    calc.chartTopMargin = attrs.marginTop;
    calc.chartWidth = attrs.svgWidth - attrs.marginRight - calc.chartLeftMargin;
    calc.chartHeight = attrs.svgHeight - attrs.marginBottom - calc.chartTopMargin;

    // Override x and y attributes

    attrs.image.width = calc.chartWidth;
    attrs.image.height = calc.chartHeight;

    attrs.velocityLegend.x = 5;
    attrs.velocityLegend.y = attrs.marginTop + calc.chartHeight + 60;
    attrs.velocityLegend.height = (attrs.svgWidth / 2 - 30) > 300 ? 300 : (attrs.svgWidth / 2 - 30);


    attrs.legendTitle.translate = "translate(14," + (calc.chartHeight / 2 + attrs.marginTop) + ") "
    attrs.legendTitle.velocityTranslate = "translate(" + (attrs.velocityLegend.height / 2) + "," + (calc.chartHeight + attrs.marginTop + 55 + 15) + ") "
    attrs.legendTitle.inlineTranslate = "translate(" + (calc.chartWidth / 2 + attrs.marginLeft) + "," + (calc.chartHeight + attrs.marginTop + 65) + ") "

    attrs.legendAxis.x = attrs.velocityLegend.x;
    attrs.legendAxis.y = attrs.velocityLegend.y;

    attrs.xAxisContainer.y = calc.chartHeight + attrs.marginTop;
    attrs.yAxisContainer.y = attrs.marginTop;

    attrs.yScale.maxRange = calc.chartHeight;
    attrs.xScale.maxRange = calc.chartWidth;
    attrs.yLegend.maxRange = calc.chartHeight;

    // --------------  Shots Legend  ----------

    attrs.shotLegendProps.width = attrs.svgWidth / 3 - 30 > 300 ? 300 : attrs.svgWidth / 3 - 60;
    attrs.shotLegendProps.height = 18;

    attrs.shotLegendProps.x = attrs.svgWidth - attrs.shotLegendProps.width - attrs.marginRight - 55;
    attrs.shotLegendProps.y = attrs.marginTop + calc.chartHeight + 76;

    calc.eachShotLegendWidth = attrs.shotLegendProps.width / attrs.legendRects;

    attrs.shotLegendTitle.translate = 'translate(14,' + (calc.chartHeight / 2 + attrs.marginTop) + ') ';
    attrs.shotLegendTitle.velocityTranslate =
      'translate(' +
      (attrs.shotLegendProps.x + attrs.shotLegendProps.width / 2) +
      ',' +
      (calc.chartHeight + attrs.marginTop + 70) +
      ') ';
    attrs.shotLegendTitle.inlineTranslate =
      'translate(' +
      (calc.chartWidth / 2 + attrs.marginLeft) +
      ',' +
      (calc.chartHeight + attrs.marginTop + 65) +
      ') ';

    attrs.shotLegendAxis.x = attrs.shotLegendProps.x;
    attrs.shotLegendAxis.y = attrs.shotLegendProps.y;

    // --------------

    // console.log("reach data matrix section")
    // if (attrs.dataMatrix) {
    //   console.log("data matrix length:", attrs.dataMatrix.length)
    //   if (attrs.dataMatrix.length > 0) {
    //     console.log("data matrix[0]:", attrs.dataMatrix[0])
    //     // console.log("data matrix[0]:", attrs.dataMatrix[0].length)
    //   }
    // }
    // console.log("slice type:", attrs.sliceType)
    // console.log("inline start/end", attrs.startInline, attrs.endInline);
    // console.log("crossline start/end", attrs.startCrossline, attrs.endCrossline)
    // if (attrs.dataMatrix && attrs.sliceType == SliceType.depth){
    //   console.log("input start -", attrs.startInline, attrs.startCrossline, attrs.startDepth)
    //   console.log("input end -", attrs.endInline, attrs.endCrossline, attrs.endDepth)
    //   console.log("data matrix shape:",attrs.dataMatrix.length,"x",attrs.dataMatrix[0].length, ": ", typeof attrs.dataMatrix[0][0])
    //   console.log("reversed:", attrs.reverseY)
    // }
    if (attrs.dataMatrix && attrs.dataMatrix.length > 0) {
      let startIndex_y = 0;
      let endIndex_y = attrs.dataMatrix.length - 1;
      let startIndex_x = 0;
      let endIndex_x = attrs.dataMatrix[0].length - 1;
      // if (attrs.startDepth != null && attrs.endDepth != null) {
      //   startIndex_y = Math.round (attrs.startDepth / attrs.gridSpacing);
      //   endIndex_y = Math.round (attrs.endDepth / attrs.gridSpacing);
      // }
      
      if (attrs.sliceType == SliceType.depth) {
        // console.log("matrix dims = ", endIndex_y, "x",endIndex_x)
        startIndex_x = Math.round (attrs.startCrossline / attrs.gridSpacing);
        endIndex_x = Math.round (attrs.endCrossline / attrs.gridSpacing);
        
        startIndex_y = Math.round (attrs.startInline / attrs.gridSpacing);
        endIndex_y = Math.round (attrs.endInline / attrs.gridSpacing);
        
        // console.log("depth slice start y/x-", startIndex_y, startIndex_x)
        // console.log("depth slice end  y/x-", endIndex_y, endIndex_x)
        
      } else if (attrs.sliceType == SliceType.inline) {
        if (attrs.startCrossline != null && attrs.endCrossline != null) {
          startIndex_x = Math.round (attrs.startCrossline / attrs.gridSpacing);
          endIndex_x = Math.round (attrs.endCrossline / attrs.gridSpacing);

          startIndex_y = Math.round (attrs.startDepth / attrs.gridSpacing);
          endIndex_y = Math.round (attrs.endDepth / attrs.gridSpacing);
        }
      } else if (attrs.sliceType == SliceType.crossLine) {
        if (attrs.startInline != null && attrs.endInline != null) {
          startIndex_x = Math.round (attrs.startInline / attrs.gridSpacing);
          endIndex_x = Math.round (attrs.endInline / attrs.gridSpacing);
          
          startIndex_y = Math.round (attrs.startDepth / attrs.gridSpacing);
          endIndex_y = Math.round (attrs.endDepth / attrs.gridSpacing);
        }
      }
      // console.log("index_x set to:",attrs.sliceType, startIndex_x, endIndex_x)
      attrs.xIndexOffset = startIndex_x;
      attrs.yIndexOffset = startIndex_y;
      attrs.dataMatrixSliced = attrs.dataMatrix.slice(startIndex_y, endIndex_y + 1).map(i=>i.slice(startIndex_x,endIndex_x+1));
      if (attrs.sliceType == SliceType.depth) {
        attrs.dataMatrixSliced = attrs.dataMatrix.slice(startIndex_y, endIndex_y + 1).map(i=>i.slice(startIndex_x,endIndex_x+1));
      }
      calc.canvasDimensions = [attrs.dataMatrixSliced[0].length, attrs.dataMatrixSliced.length];
      // console.log("canvas dimensions:",calc.canvasDimensions)
      
    }

    

    if (attrs.solidLineData && attrs.lightLineData) {
      calc.maxSolidX = d3.max(attrs.solidLineData, d => +d.x);
      calc.minSolidX = d3.min(attrs.solidLineData, d => +d.x);
      calc.minLightX = d3.min(attrs.lightLineData, d => +d.x);
      calc.maxLightX = d3.max(attrs.lightLineData, d => +d.x);

      calc.maxSolidY = d3.max(attrs.solidLineData, d => +d.y);
      calc.maxLightY = d3.max(attrs.lightLineData, d => +d.y);
      calc.minSolidY = d3.min(attrs.solidLineData, d => +d.y);
      calc.minLightY = d3.min(attrs.lightLineData, d => +d.y);
    }


    if (calc.chartWidth <= 450) {
      attrs.xTicks = 4
    }

    if (calc.chartWidth > 450) {
      attrs.xTicks = 6
    }

    if (calc.chartWidth > 550) {
      attrs.xTicks = 8
    }

    if (calc.chartWidth > 650) {
      attrs.xTicks = 10
    }

    if (calc.chartWidth > 750) {
      attrs.xTicks = 10
    }

    if (calc.chartWidth > 900) {
      attrs.xTicks = 12
    }

    if (calc.chartWidth > 1100) {
      attrs.xTicks = 15
    }

    if (calc.chartWidth > 1400) {
      attrs.xTicks = 19
    }

    if (calc.chartWidth > 1700) {
      attrs.xTicks = 24
    }

    if (calc.chartWidth > 2000) {
      attrs.xTicks = 30
    }

    if (calc.chartHeight <= 250) {
      attrs.yTicks = 3
    }
    if (calc.chartHeight > 250) {
      attrs.yTicks = 3
    }
    if (calc.chartHeight > 350) {
      attrs.yTicks = 6
    }
    if (calc.chartHeight > 450) {
      attrs.yTicks = 8
    }

    if (calc.chartHeight > 550) {
      attrs.yTicks = 10
    }

    if (calc.chartHeight > 650) {
      attrs.yTicks = 14
    }

    if (calc.chartHeight > 750) {
      attrs.yTicks = 18
    }

    if (calc.chartHeight > 900) {
      attrs.yTicks = 20
    }

    if (calc.chartHeight > 1100) {
      attrs.yTicks = 24
    }

    if (calc.chartHeight > 1400) {
      attrs.yTicks = 28
    }

    if (calc.chartHeight > 1700) {
      attrs.yTicks = 32
    }

    if (calc.chartHeight > 2000) {
      attrs.yTicks = 40
    }

    // --------------- Colors  -------------
    var shotColor = d3.interpolateRgbBasis(attrs.shotOverlayColorRange);

    //#################### SCALES ####################
    var scales = {
      y: null,
      x: null,
      legendY: null,
      lineSolidX: null,
      lineLightX: null,
      lineSolidY: null,
      lineLightY: null,
      shotOverlayColors: null,
      shotOverlayColorScaleHelper: null,
      shotOverlayColor: null
    };

    attrs.scales = scales;

    let xmin = 0;
    let xmax =attrs.xScale.maxDomain;
    let ymin = 0;
    let ymax = attrs.yScale.maxDomain;

    if (attrs.sliceType == SliceType.depth) {
      xmin = attrs.startCrossline != null ? attrs.startCrossline : 0;
      xmax = attrs.endCrossline != null ? attrs.endCrossline: attrs.xScale.maxDomain;
      
      // console.log("inline start/end, maxDom", attrs.startInline, attrs.endInline, attrs.yScale.maxDomain);
      // console.log("crossline start/end", attrs.startCrossline, attrs.endCrossline)

      ymin = attrs.yScale.maxDomain - (attrs.startInline != null ? attrs.startInline : 0);
      ymax = attrs.yScale.maxDomain - (attrs.endInline != null ? attrs.endInline : attrs.yScale.maxDomain);
      // ymin = attrs.endInline != null ? attrs.endInline : attrs.yScale.maxDomain;
      // ymax = attrs.startInline != null ? attrs.startInline : 0;
      // console.log("ymin/ymax", ymin, ymax);

      // ymin = attrs.yScale.maxDomain - ymax;
    } else if (attrs.sliceType == SliceType.inline) {
      xmin = attrs.startCrossline != null ? attrs.startCrossline : 0;
      xmax = attrs.endCrossline != null ? attrs.endCrossline: attrs.xScale.maxDomain;

      ymin = attrs.startDepth != null ? attrs.startDepth : 0;
      ymax = attrs.endDepth != null ? attrs.endDepth: attrs.yScale.maxDomain;
    } else if (attrs.sliceType == SliceType.crossLine) {
      xmin = attrs.startInline != null ? attrs.startInline : 0;
      xmax = attrs.endInline != null ? attrs.endInline: attrs.xScale.maxDomain;
      
      ymin = attrs.startDepth != null ? attrs.startDepth : 0;
      ymax = attrs.endDepth != null ? attrs.endDepth: attrs.yScale.maxDomain;
    }
    
    calc.yRangeWeWantAdd = 0;
    calc.yDomainRangeProportion = attrs.sliceType == SliceType.depth ? (ymin-ymax) / attrs.yScale.maxRange : (ymax-ymin) / attrs.yScale.maxRange;
    calc.yDomainWeShouldAdd = calc.yRangeWeWantAdd * calc.yDomainRangeProportion;

    //scale for calculating each shape height
    scales.y = d3.scaleLinear()
      .range([0, attrs.yScale.maxRange + calc.yRangeWeWantAdd]);
      
    if (attrs.reverseY) {
      scales.y.domain([attrs.yScale.maxDomain-ymax + calc.yDomainWeShouldAdd, attrs.yScale.maxDomain-ymin])
      // console.log("set domain to:", attrs.yScale.maxDomain-ymin,attrs.yScale.maxDomain- ymax + calc.yDomainWeShouldAdd)
    } else {
      // scales.y.domain([0, attrs.yScale.maxDomain])
      scales.y.domain([ymin, ymax+ calc.yDomainWeShouldAdd])
    }

    function cropImage(startDepth, endDepth, startX, endX) {
      return new Promise((resolve, reject) => {
        let img = document.createElement('img');
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          let startWidth = img.naturalWidth / attrs.xScale.maxDomain * startX;
          
          let endWidth = img.naturalWidth / attrs.xScale.maxDomain * endX;
          const cropWidth = endWidth - startWidth;
          

          // console.log("startW,endW,cropW:",startWidth,endWidth,cropWidth)

          let startHeight = img.naturalHeight / attrs.yScale.maxDomain * startDepth;
          let endHeight = img.naturalHeight / attrs.yScale.maxDomain * endDepth;
          const cropHeight = endHeight - startHeight;
          // console.log("startH,endH,cropH:",startHeight,endHeight,cropHeight)
          canvas.width = attrs.image.width;
          canvas.height = attrs.image.height;
          
          context.drawImage(img, startWidth, startHeight, cropWidth, cropHeight, 0, 0, attrs.image.width, attrs.image.height);
          resolve(canvas.toDataURL('image/png'));
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = attrs.data;
      });
    }
    let cropped_image = await cropImage(ymin, ymax, xmin, xmax);
    calc.xRangeWeWantAdd = attrs.marginRight + attrs.marginLeft;
    if (attrs.xScale.maxRange == undefined) {
      attrs.xScale.maxRange = calc.chartWidth;
    }
    calc.xDomainRangeProportion = (xmax-xmin) / attrs.xScale.maxRange;
    calc.xDomainWeShouldAdd = calc.xRangeWeWantAdd * calc.xDomainRangeProportion;

    //x axis scale
    scales.x = d3.scaleLinear()
      .range([0, attrs.xScale.maxRange + calc.xRangeWeWantAdd]);

    
    scales.x.domain([xmin, xmax+ calc.xDomainWeShouldAdd])

    //legend vertical scale
    scales.legendY = d3.scaleLinear()
      .domain([attrs.yLegend.minDomain, attrs.yLegend.maxDomain])
      .range([10, attrs.yLegend.maxRange]);

    //Line stuff
    calc.overlayRectheight = Math.abs(scales.y(attrs.lineOverlayY + attrs.lineOverlayHeight) - scales.y(attrs.lineOverlayY))

    if (attrs.solidLineData && attrs.lightLineData) {
      let rangeY = calc.maxLightY - calc.minLightY;
      scales.lineSolidY = d3.scaleLinear().range([attrs.lineOverlayWidth, 0]).domain([calc.minLightY - 0.2 * rangeY, calc.maxLightY + 0.2 * rangeY]);
      scales.lineLightY = d3.scaleLinear().range([attrs.lineOverlayWidth, 0]).domain([calc.minLightY - 0.2 * rangeY, calc.maxLightY + 0.2 * rangeY]);
      scales.lineSolidX = d3.scaleLinear().range([0, calc.overlayRectheight]).domain([Math.min(calc.minSolidX, calc.minLightX), Math.max(calc.maxSolidX, calc.maxLightX)]);
      scales.lineLightX = d3.scaleLinear().range([0, calc.overlayRectheight]).domain([Math.min(calc.minSolidX, calc.minLightX), Math.max(calc.maxSolidX, calc.maxLightX)]);

      //scales.lineSolidY = d3.scaleLinear().range([attrs.lineOverlayWidth, 0]).domain([0, calc.maxSolidY]);
      //scales.lineLightY = d3.scaleLinear().range([attrs.lineOverlayWidth, 0]).domain([0, calc.maxLightY]);
      //scales.lineSolidX = d3.scaleLinear().range([0, calc.overlayRectheight]).domain([calc.minSolidX, calc.maxSolidX]);
      //scales.lineLightX = d3.scaleLinear().range([0, calc.overlayRectheight]).domain([calc.minLightX, calc.maxLightX]);
    }
    
    
    // Shot overlay color scale
    scales.shotOverlayColorScaleHelper = d3.scaleLinear().domain([attrs.shotLegend.minDomain, attrs.shotLegend.maxDomain]).range([0, 1]);
    scales.shotOverlayColor = function (number) {
      return shotColor(scales.shotOverlayColorScaleHelper(number));
    }

    let strokeWidthScale = (attrs.lastTransform || { k: 1 }).k

    //---------  Layouts -----------------
    const layouts = {
      lineSolid: null,
      lineLight: null,
      normalLine: null
    }

    if (attrs.solidLineData && attrs.lightLineData) {
      layouts.lineSolid = d3.line()
        .x(d => scales.lineSolidX(+d.x))
        .y(d => scales.lineSolidY(+d.y))

      layouts.lineLight = d3.line()
        .x(d => scales.lineLightX(+d.x))
        .y(d => scales.lineLightY(+d.y))
    }

    if (attrs.normalLineData) {
      layouts.normalLine = [];
      for (var i = 0; i < attrs.normalLineData.length; i++) {
        layouts.normalLine.push(d3.line().defined((d) => +d.y >= 0).x(d => scales.x(+d.x)).y(d => scales.y(+d.y)));
      }
    }


    //#################### AXES ####################
    var axes = {
      y: null,
      x: null,
      legendY: null
    };

    attrs.axes = axes;

    //y axis for depth
    axes.y = d3
      .axisLeft(scales.y)
      .ticks(attrs.yTicks);

    //x axis for brands
    axes.x = d3
      .axisBottom(scales.x)
      .ticks(attrs.xTicks);



    //legend y axis
    axes.legendY = d3
      .axisBottom(scales.legendY)
      .tickSize(0)
      .ticks(2)
      .tickSizeOuter([0]);

    //#################  BEHAVIORS  ###############
    var behaviors = {
      zoom: null
    };

    behaviors.zoom = d3.zoom()
      .filter(function (event) {
        return event.metaKey || event.ctrlKey;
      })
      .on("zoom", zoomed)
      .scaleExtent([0.99999, attrs.defaultZoomLevel + 0.000000001]);

    //Drawing containers
    var container = d3.select(attrs.container);

    //Add svg
    var svg = container.patternify({ tag: 'svg', selector: 'svg-chart-container' })
      .attr('width', attrs.svgWidth)
      .attr('height', attrs.svgHeight)
      .attr('font-family', attrs.defaultFont)

      .style('background-color', attrs.backRect);




    //Add container g element
    var chart = svg.patternify({ tag: 'g', selector: 'chart' }).attr('id', attrs.id)
      .attr('transform', 'translate(' + (calc.chartLeftMargin) + ',' + calc.chartTopMargin + ')');
    
    const zoomIdentity = d3.zoomIdentity
      .translate(calc.chartLeftMargin, calc.chartTopMargin)
      .scale(1);


    if (attrs.zoomedAtLeastOnce) {
      updateChartBasedOnTransform();
      // zoomIdentity
      //   .translate(attrs.lastTransform.x, attrs.lastTransform.y)
      //   .scale(attrs.lastTransform.k);
    } else {
      // zoomIdentity
      //   .translate(calc.chartLeftMargin, calc.chartTopMargin)
      //   .scale(1);
    }


    const wrapperRects = svg.patternify({ tag: 'g', selector: 'wrapper-rects' });

    // Adding background rects
    wrapperRects.patternify({ tag: 'rect', selector: 'left-wrapper' })
      .attr('width', attrs.marginLeft)
      .attr('height', attrs.svgHeight)
      .attr('fill', attrs.backRect)


    wrapperRects.patternify({ tag: 'rect', selector: 'top-wrapper' })
      .attr('width', attrs.svgWidth)
      .attr('height', attrs.marginTop)
      .attr('fill', attrs.backRect)

    wrapperRects.patternify({ tag: 'rect', selector: 'bottom-wrapper' })
      .attr('width', attrs.svgWidth)
      .attr('height', attrs.marginBottom)
      .attr('y', calc.chartHeight + attrs.marginTop)
      .attr('fill', attrs.backRect)

    wrapperRects.patternify({ tag: 'rect', selector: 'right-wrapper' })
      .attr('width', attrs.marginRight)
      .attr('height', attrs.svgHeight)
      .attr('x', calc.chartWidth + attrs.marginLeft)
      .attr('fill', attrs.backRect)


    //container for y axis
    var yAxisContainer = svg.patternify({ tag: 'g', selector: 'y-axis-container' })
      .attr('transform', 'translate(' + attrs.yAxisContainer.x + ',' + (0) + ')');


    attrs.yAxisGroup = yAxisContainer;

    // Adding background rects
    svg.patternify({ tag: 'rect', selector: 'y-axis-rect-wrapper' })
      .attr('width', attrs.svgWidth)
      .attr('height', attrs.marginTop * 0.75)
      .attr('fill', attrs.backRect)
      .attr('opacity', 0);

    //display y axis
    yAxisContainer.call(axes.y);

    attrs.zeroAxisTick = svg.patternify({ tag: 'g', selector: 'zero-tick' })
      .attr('transform', `translate(${attrs.marginLeft - 17},${attrs.marginTop + calc.chartHeight - 5})`)

    attrs.zeroAxisTick.patternify({ tag: 'rect', selector: 'zero-tick-rect' })
      .attr('width', 7)
      .attr('height', 1)
      .attr('fill', attrs.defaultTextFill)

    attrs.zeroAxisTick.patternify({ tag: 'text', selector: 'zero-tick-text' })
      .text('0')
      .attr('x', -10)
      .attr('y', 5)
      .attr('font-size', 12)
      .attr('fill', attrs.defaultTextFill)

    if (attrs.lastTransform.k != 1 || !attrs.reverseY) {
      attrs.zeroAxisTick.attr('opacity', 0)
    } else {
      attrs.zeroAxisTick.attr('opacity', 0)
    }


    //container for x axis
    var xAxisContainer = svg
      .patternify({ tag: 'g', selector: 'x-axis-container' })
      .attr('transform', 'translate(' + 0 + ',' + (calc.chartHeight + attrs.marginTop + 15) + ')');

    attrs.xAxisGroup = xAxisContainer;
    
    svg.patternify({ tag: 'rect', selector: 'x-axis-rect-wrapper' })
      .attr('width', attrs.marginLeft * 0.9)
      .attr('height', attrs.marginBottom * 0.9)
      .attr('y', attrs.marginTop + calc.chartHeight + attrs.marginBottom * 0.1)
      .attr('fill', attrs.backRect)

    //display x axis
    xAxisContainer
      .call(axes.x);

    //set tick text styles
    var ticks = svg.selectAll('.tick').selectAll('text')
      .attr('font-size', attrs.ticks.fontSize)
      .attr('font-family', attrs.ticks.fontFamily)
      .attr('fill', attrs.ticks.fill);

    //Add image container g element
    var imageContainer = chart.patternify({ tag: 'g', selector: 'image-layer' })


    const _this = this;
    //add main image
    var image = imageContainer.patternify({ tag: 'image', selector: 'depth-image' })
      .attr('width', attrs.image.width)
      .attr('height', attrs.image.height)
      .attr('preserveAspectRatio', 'none')
      .attr('xlink:href', cropped_image)
      .attr('rx', 15)
      .on('mousemove', function (event, d) {
        attrs.imgHoverG.attr('opacity', 1)
        const mouse = d3.pointer(event);
        updatePosStats(mouse);
      })
      .on('mouseleave', function (event, d) {
        attrs.imgHoverG.attr('opacity', 0)
      }).on('click', function (event, d) {
        const mouse = d3.pointer(event);
        updateClicked(mouse, _this);
      })

    const horizontalLine = chart.patternify({ tag: 'line', selector: 'horizontal-dotted-line' })
      .attr('x1', 0)
      .attr('y1', scales.y(attrs.dotLineY))
      .attr('x2', calc.chartWidth)
      .attr('y2', scales.y(attrs.dotLineY))
      .attr('width', calc.chartWidth)
      .attr('height', 0.1)
      .attr('stroke', attrs.dotted_line_color)
      .attr('stroke-width', attrs.hoverLineWidth / strokeWidthScale)
      .attr('stroke-dasharray', '3,6')
      .attr('pointer-events', 'none')
      .attr('opacity', attrs.dotLineY == null || !attrs.dotLineVisibility ? 0 : 1)

    const verticalLine = chart.patternify({ tag: 'line', selector: 'vertical-dotted-line' })
      .attr('x1', scales.x(attrs.dotLineX))
      .attr('y1', 0)
      .attr('x2', scales.x(attrs.dotLineX))
      .attr('y2', calc.chartHeight)
      .attr('width', 0.1)
      .attr('height', attrs.svgHeight)
      .attr('stroke-width', attrs.hoverLineWidth / strokeWidthScale)
      .attr('stroke-dasharray', '3,6')
      .attr('stroke', attrs.dotted_line_color)
      .attr('pointer-events', 'none')
      .attr('opacity', attrs.dotLineX == null || !attrs.dotLineVisibility ? 0 : 1)


    // Mini Line Chart Overlay
    const miniLineOverlayG = chart.patternify({ tag: 'g', selector: 'overlay-line-g' })
      .attr('transform', `translate(${scales.x(attrs.lineOverlayX) || 0},0)`)
      .attr('pointer-events', 'none')

    const logClip = miniLineOverlayG.patternify({ tag: "clipPath", selector: "log-clip" })
      .attr("id", "log-clip")
      .append("rect")
      .attr('width', attrs.lineOverlayWidth)
      .attr('height', calc.overlayRectheight || 0)
      .attr('y', scales.y(attrs.lineOverlayY))

    //rect
    const overlayPanel = miniLineOverlayG.patternify({ tag: 'rect', selector: 'line-overlay-rect' })
      .attr('height', calc.overlayRectheight || 0)
      .attr('y', scales.y(attrs.lineOverlayY))
      .attr('fill', 'white')
      .attr('opacity', 0.7)

    const verticalBlackLine = miniLineOverlayG.patternify({ tag: 'line', selector: 'vertical-black-line' })
      .attr('y1', 0)
      .attr('y2', calc.chartHeight)
      .attr('width', 0.1)
      .attr('height', attrs.svgHeight)
      .attr('stroke-width', attrs.hoverLineWidth / strokeWidthScale)
      .attr('stroke', 'black')
      .attr('pointer-events', 'none')
      .attr('opacity', attrs.lineOverlayX == null ? 0 : 1)
    if (attrs.zeroLineOffset != null && scales.lineLightY) {
      let shift = attrs.lineOverlayWidth - scales.lineLightY(0)
      let fullWdith = Math.abs(Math.max(...attrs.lightLineData.map(d => scales.lineLightY(d.y))) - shift) * 1.1;
      miniLineOverlayG.attr('transform', `translate(${scales.x(attrs.lineOverlayX) - shift},0)`)
      overlayPanel
        .attr('width', 2 * fullWdith)
        .attr('transform', `translate(${-fullWdith + shift},0)`)
      logClip.attr('transform', `translate(${-fullWdith + shift},0)`)
      verticalBlackLine.attr('transform', `translate(${shift},0)`)
    } else {
      miniLineOverlayG.attr('transform', `translate(${scales.x(attrs.lineOverlayX) || 0},0)`)
      overlayPanel
        .attr('width', attrs.lineOverlayWidth)
        .attr('transform', `translate(0,0)`)
      logClip.attr('transform', `translate(0,0)`)
      verticalBlackLine.attr('transform', `translate(0,0)`)
    }

    // ------------  Shot Overlay  ------------
    if (attrs.shotsOverlayLegendsVisibility && attrs.shotOverlayData) {
      const shotOverlayPointsWrapper = chart.patternify({ tag: 'g', selector: 'shot-overlay-point-g' });
      attrs.shotOverlayCircles = shotOverlayPointsWrapper
        .patternify({
          tag: 'circle',
          data: attrs.shotOverlayData,
          selector: 'shot-overlay-circles'
        })
        .attr('r', attrs.shotOverlayPointRadius)
        .attr('cx', (d) => {
          return scales.x(d.x);
        })
        .attr('cy', (d) => {
          return scales.y(d.y);
        })
        .attr('stroke-width', 0)
        .attr('stroke', (d) => {
          return scales.shotOverlayColor(d.shotFit);
        })
        .attr('fill', (d) => {
          return scales.shotOverlayColor(d.shotFit);
        });
      svg.select('.vertical-dotted-line').remove();
      svg.select('.horizontal-dotted-line').remove();

      imageContainer.patternify({ tag: 'rect', selector: 'depth-image-overlay' })
        .attr('width', attrs.image.width)
        .attr('height', attrs.image.height)
        .attr('preserveAspectRatio', 'none')
        .attr('fill', 'white')
        .attr('opacity', 0.3);
      
      // attrs.shotOverlayCircles.on('mouseover', function (d) {
      //   let cx = d3.select(this).attr('cx');
      //   let cy = d3.select(this).attr('cy');
      //   // var shotHover = chart.append('text').attr('class','shotHover').attr('x',cx + 10).attr('y', cy + 10).text(d.shot_id);
      // })

      // attrs.shotOverlayCircles.on('mouseout', function (d) {
      //   svg.select('.shotHover').remove();
      // })

    } else {
      svg.select('.depth-image-overlay').remove();
      svg.select('.shot-overlay-point-g').remove();
    }


    function updatePosStats(mouse) {
      try {
        
        var x = attrs.gridSpacing * Math.round((mouse[0] / attrs.image.width) * calc.canvasDimensions[0]);
        var y = attrs.gridSpacing * Math.round((mouse[1] / attrs.image.height) * calc.canvasDimensions[1]);
        
        // console.log(calc.canvasDimensions[0])
          
        if (((attrs.sliceType == SliceType.inline || attrs.sliceType == SliceType.depth) && (attrs.startCrossline != null && attrs.endCrossline != null && (attrs.startCrossline != 0 || attrs.endCrossline != attrs.xScale.maxDomain))) ||
            ((attrs.sliceType == SliceType.crossLine) && (attrs.startInline != null && attrs.endInline != null && (attrs.startInline != 0 || attrs.endInline != attrs.xScale.maxDomain)))) {
          x = attrs.xIndexOffset * attrs.gridSpacing + Math.round((mouse[0] / attrs.image.width) * calc.canvasDimensions[0]) * attrs.gridSpacing;
        }
        x = (x + attrs.gridSpacing / 2);
        
        if (attrs.reverseY) {
          // console.log("setting reverse y:", attrs.sliceType)
          y = attrs.yIndexOffset * attrs.gridSpacing + attrs.gridSpacing * Math.round(((attrs.image.height - mouse[1]) / attrs.image.height) * calc.canvasDimensions[1]);
        } else { // if (attrs.startDepth != null && attrs.endDepth != null && (attrs.startDepth != 0 || attrs.endDepth != attrs.yScale.maxDomain))
          y = attrs.yIndexOffset * attrs.gridSpacing + Math.round((mouse[1] / attrs.image.height) * calc.canvasDimensions[1]) * attrs.gridSpacing;          
        }
        y = y + attrs.gridSpacing / 2;
        hoverX.text(`${attrs.hoverLabelX} - ${Number(x).toFixed(2)}`);
        hoverY.text(`${attrs.hoverLabelY} - ${Number(y).toFixed(2)}`);
      } catch (e) {
        hoverX.text(``);
        hoverY.text(``);
      }

      if (!calc.canvasDimensions)
        return;
      try {
        const originalIndexX = Math.round((mouse[0] / attrs.image.width) * calc.canvasDimensions[0]);
        const originalIndexY = Math.round((mouse[1] / attrs.image.height) * calc.canvasDimensions[1]);
        const velocity = attrs.dataMatrixSliced[originalIndexY][originalIndexX];
        hoverVelocity.text(`${attrs.legendTitle.text} - ${Number(velocity).toFixed(2)}`);
      } catch (e) {
        hoverVelocity.text(``);
      }
    }

    function updateClicked(mouse, _this) {
      try {
        const originalIndexX = attrs.xIndexOffset + Math.round((mouse[0] / attrs.image.width) * calc.canvasDimensions[0]);
        const originalIndexY = attrs.yIndexOffset + Math.round((mouse[1] / attrs.image.height) * calc.canvasDimensions[1]);
        _this.clickedPosition.next({
          crossline:originalIndexX,
          depth:originalIndexY
        })
      } catch (e) {
      }
    }
    // -------------  Shot Overlay Hove ------------

    if (attrs.shotsOverlayLegendsVisibility && attrs.shotOverlayCircles) {
      attrs.shotOverlayCircles
        .on('mouseenter', function (event, d) {
          d3.select(this).raise().attr('stroke-width', 10 / attrs.lastTransform.k);
          hoverShotFitOriginal.text('value - ' + d.shotFitOriginal).attr('opacity', 1);
          hoverShotFit.text('new - ' + d.shotFit).attr('opacity', 1);
          attrs.imgHoverG.attr('opacity', 1);
          hoverShotFitId.text('shot_id - ' + d.shot_id).attr('opacity', 1);
          const mouse = d3.pointer(event);
          updatePosStats(mouse);
        })
        .on('mouseleave', function (d) {
          d3.select(this).attr('stroke-width', 0);
          hoverShotFitOriginal.attr('opacity', 0);
          hoverShotFit.attr('opacity', 0);
          hoverShotFitId.attr('opacity', 0);
          attrs.imgHoverG.attr('opacity', 0);
        });
    }

    //  Normal Line
    if (attrs.normalLineData) {
      chart.selectAll('.normal-line').remove();
      attrs.normalLine = [];

      for (var i = 0; i < attrs.normalLineData.length; i++) {
        attrs.normalLine.push(chart.patternify({ tag: 'path', selector: 'normal-line normal-line-' + i, data: [attrs.normalLineData[i]] })
          .attr('d', d => layouts.normalLine[i](d))
          .attr('stroke', attrs.normalLineColors[i])
          .attr('stroke-width', attrs.normalLineWidth / strokeWidthScale)
          .attr('fill', 'none')
          .attr('pointer-events', 'none'));
      }
    } else {
      chart.selectAll('.normal-line').remove();
    }

    // solid line
    attrs.solidLine = d3.select(null);
    if (attrs.solidLineData && attrs.lightLineData) {
      let solidLineGroup = miniLineOverlayG.patternify({ tag: 'g', selector: 'line-group' })
        .attr("clip-path", "url(#log-clip)")
      attrs.solidLine = solidLineGroup.patternify({ tag: 'path', selector: 'solid-line' })
        .attr('d', layouts.lineSolid(attrs.solidLineData))
        .attr('stroke', 'red')
        .attr('fill', 'none')
        .attr('stroke-width', attrs.solidLineWidth / strokeWidthScale)
        .attr('transform', `translate(${attrs.lineOverlayWidth},${scales.y(attrs.lineOverlayY)}) rotate(90)`)
    }

    attrs.lightLine = d3.select(null);
    if (attrs.solidLineData && attrs.lightLineData) {
      const solidLineDataPoints = Array.isArray(attrs.solidLineData) ? attrs.solidLineData.length : 1;
      const lightLineDataPoints = Array.isArray(attrs.lightLineData) ? attrs.lightLineData.length : 1;
     
      const dataPointScaleFactor = Math.sqrt(solidLineDataPoints / lightLineDataPoints);

      attrs.lightLine = miniLineOverlayG.patternify({ tag: 'path', selector: 'light-line' })
        .attr('d', layouts.lineLight(attrs.lightLineData))
        .attr('stroke', 'black')
        .attr('fill', 'none')
        .attr('opacity', 0.5)
        // .attr('stroke-width', attrs.lightLineWidth / strokeWidthScale)
        .attr('stroke-width', 6*(attrs.lightLineWidth * dataPointScaleFactor) / attrs.lastTransform.k)
        .attr('transform', `translate(${attrs.lineOverlayWidth},${scales.y(attrs.lineOverlayY)}) rotate(90)`)
    }

    attrs.solidDot = d3.select(null);
    if (attrs.solidDotData) {
      attrs.lightLine = chart
        .patternify({ tag: 'circle', selector: 'well-locations', data: attrs.solidDotData })
        .attr('r', 5)
        .attr('cx', (d) => {
          return scales.x(d.x);
        })
        .attr('cy', (d) => {
          return scales.y(d.y);
        })
        .attr('stroke-width', 0)
        .attr('fill', (d) => {

          return d.selected ? 'green' : 'black';
        }).on('mouseenter', function (event, d) {
          d3.select(this).raise().attr('stroke-width', 10 / attrs.lastTransform.k);
          hoverShotFitOriginal.text('sonic: ' + d.sonic.toFixed(2)).attr('opacity', 1);
          hoverShotFit.text('model: ' + d.model.toFixed(2)).attr('opacity', 1);
          const mouse = d3.pointer(event);
          console.log('here', mouse)
          updatePosStats(mouse);
        })
        .on('mouseleave', function (event, d) {
          d3.select(this).attr('stroke-width', 0);
          hoverShotFit.attr('opacity', 0);
          hoverShotFitOriginal.attr('opacity', 0);
        });
    } else {
      chart.selectAll('.well-locations').remove();
    }

    if (!attrs.solidLineData || !attrs.lightLineData) {
      chart.select('.overlay-line-g').remove();
      chart.select('.line-overlay-rect').remove();
      chart.select('.vertical-black-line').remove();
      chart.select('.solid-line').remove();
    }
    

    //container for legend descriptions
    var descriptionsContainer = svg.patternify({ tag: 'g', selector: 'legend-descriptions-container' });

    if (attrs.legendVisibility) {
      //legend gradient container
      var velocityGradientContainer = svg.patternify({ tag: 'g', selector: 'velocity-gradient-container' })
        .attr('transform', 'translate(' + attrs.velocityLegend.x + ',' + (attrs.velocityLegend.y + attrs.velocityLegend.width + 15) + ') rotate(-90)');

      velocityGradientContainer.patternify({ tag: 'image', selector: 'legend-image' })
        .attr('height', attrs.velocityLegend.height)
        .attr('width', attrs.velocityLegend.width)
        .attr('preserveAspectRatio', 'none')
        .attr('xlink:href', attrs.legendImage)
        .attr('rx', 15)
      // //vertical velocity gradient
      // velocityGradientContainer
      //   .patternify({ tag: 'rect', selector: 'velocity-gradient-rect' })
      //   .attr('width', attrs.velocityLegend.width)
      //   .attr('height', attrs.velocityLegend.height)
      //   .attr("style", attrs.velocityLegend.style);

      //container for legend y axis
      var legendAxisContainer = svg.patternify({ tag: 'g', selector: 'legend-y-axis-container' })
        .attr('transform', 'translate(' + attrs.legendAxis.x + ',' + (attrs.legendAxis.y + 15) + ')');

      legendAxisContainer.patternify({ tag: 'text', selector: "min-legend-text" })
        .text(formatLegendValue(attrs.yLegend.minDomain))
        .attr('text-anchor', 'start')
        .attr('y', -4)
        .attr('font-size', '12')
        .attr('fill', attrs.defaultTextFill)

      legendAxisContainer.patternify({ tag: 'text', selector: "max-legend-text" })
        .text(formatLegendValue(attrs.yLegend.maxDomain))
        .attr('x', attrs.velocityLegend.height)
        .attr('text-anchor', 'end')
        .attr('y', -4)
        .attr('font-size', '12')
        .attr('fill', attrs.defaultTextFill)

      //display legend y axis values
      //legendAxisContainer.call(axes.legendY);

      //velocity axis title
      var velocityText = descriptionsContainer.patternify({ tag: 'text', selector: 'velocity-legend-text' })
        .attr('transform', attrs.legendTitle.velocityTranslate)
        .attr('style', attrs.legendTitle.style)
        .text(attrs.legendTitle.text + ' ' + attrs.legendTitle.unit)
        .attr('text-anchor', 'middle')

    } else {
      svg.select('.velocity-gradient-container').remove();
      svg.select('.velocity-legend-text').remove();
      svg.select('.legend-y-axis-container').remove();
    }

    if (attrs.shotsOverlayLegendsVisibility) {

      if (!attrs.shotsOverlayErrorMessage) {
        var shotOverlayGradientContainer = svg
          .patternify({
            tag: 'g',
            selector: 'shot-overlay-gradient-container'
          })
          .attr('transform', 'translate(' + attrs.shotLegendProps.x + ',' + attrs.shotLegendProps.y + ')');
        shotOverlayGradientContainer
          .patternify({ tag: 'rect', selector: 'shot-gradient-rects', data: d3.range(attrs.legendRects) })
          .attr('height', attrs.shotLegendProps.height)
          .attr('width', calc.eachShotLegendWidth)
          .attr('x', (d, i) => i * calc.eachShotLegendWidth)
          .attr('fill', (d, i, arr) => shotColor(i / arr.length))
          .attr('stroke', (d, i, arr) => shotColor(i / arr.length));

        //shotOverlayGradientContainer
        //  .patternify({ tag: 'rect', selector: 'shot-gradient-rect' })
        //  .attr('height', attrs.shotLegendProps.height)
        //  .attr('width', attrs.shotLegendProps.width)
        //  .attr('fill', 'none')
        //  .attr('stroke', 'black')
        //  .attr('stroke-width', 0.5);

        //container for legend y axis
        var shotLegendAxisContainer = svg
          .patternify({ tag: 'g', selector: 'shot-legend-y-axis-container' })
          .attr('transform', 'translate(' + attrs.shotLegendAxis.x + ',' + attrs.shotLegendAxis.y + ')');

        shotLegendAxisContainer
          .patternify({ tag: 'text', selector: 'shot-min-legend-text' })
          .text(attrs.shotLegend.minDomain)
          .attr('text-anchor', 'start')
          .attr('y', -4)
          .attr('font-size', '12')
          .attr('fill', attrs.defaultTextFill);

        shotLegendAxisContainer
          .patternify({ tag: 'text', selector: 'shot-max-legend-text' })
          .text(attrs.shotLegend.maxDomain)
          .attr('x', attrs.shotLegendProps.width)
          .attr('text-anchor', 'end')
          .attr('y', -4)
          .attr('font-size', '12')
          .attr('fill', attrs.defaultTextFill);

        //display legend y axis values

        //velocity axis title
        var velocityText = descriptionsContainer
          .patternify({ tag: 'text', selector: 'shot-legend-text' })
          .attr('transform', attrs.shotLegendTitle.velocityTranslate)
          .attr('style', attrs.shotLegendTitle.style)
          .text(attrs.shotLegend.text)
          .attr('text-anchor', 'middle');
      } else {
        var velocityText = descriptionsContainer
          .patternify({ tag: 'text', selector: 'shot-legend-text' })
          .attr('transform', attrs.shotLegendTitle.velocityTranslate)
          .attr('style', attrs.shotLegendTitle.style)
          .text(attrs.shotsOverlayErrorMessage)
          .attr('text-anchor', 'middle');
      }
    } else {
      svg.select('.shot-overlay-gradient-container').remove();
      svg.select('.shot-legend-text').remove();
      svg.select('.shot-max-legend-text').remove();
      svg.select('.shot-min-legend-text').remove();
    }


    //y axis title
    var depthText = descriptionsContainer.patternify({ tag: 'text', selector: 'depth-legend-text' })
      // .attr('x', attrs.legendTitle.depthX)
      // .attr('y', attrs.legendTitle.depthY)
      .attr('transform', attrs.legendTitle.translate + attrs.legendTitle.rotate)
      .attr('style', attrs.legendTitle.style)
      .text(attrs.yTitle)
      .attr('text-anchor', 'middle')

    //x axis title
    var inlineText = descriptionsContainer.patternify({ tag: 'text', selector: 'inline-legend-text' })
      .attr('style', attrs.legendTitle.style)
      .attr('transform', attrs.legendTitle.inlineTranslate)
      .text(attrs.xTitle)
      .attr('text-anchor', 'middle')


    // zoom actions
    var zoomActionsWrapper = svg.patternify({ tag: 'g', selector: 'zoom-actions-wrapper' })
      .attr('cursor', 'pointer')
      .attr('transform', 'translate(' + (10) + ',' + (attrs.svgHeight - 27) + ')')


    const eachButtonWrapper = zoomActionsWrapper.patternify({ tag: 'g', selector: 'each-action-wrapper', data: attrs.zoomActions })
      .attr('transform', (d, i) => 'translate(' + (100 * i) + ',5)')
      .on('click', onZoomActionClick);

    eachButtonWrapper.patternify({ tag: 'rect', selector: 'zoom-button-rect', data: d => [d] })
      .attr('stroke', attrs.defaultTextFill)
      .attr('stroke-width', 1)
      .attr('fill', attrs.backRect)
      .attr('width', 80)
      .attr('height', 20)

    eachButtonWrapper.patternify({ tag: 'text', selector: 'zoom-button-text', data: d => [d] })
      .attr('fill', attrs.defaultTextFill)
      .text(d => d.name)
      .attr('y', 15)
      //.attr('x', 40)
      .attr('text-anchor', 'middle')

    eachButtonWrapper.each(function (d) {
      var width = d3.select(this).select('text').node().getBoundingClientRect().width;
      d3.select(this).select('rect').attr('width', width + 10)
      d3.select(this).select('text').attr('x', width / 2 + 5)
    })
    var prevX = 0;
    eachButtonWrapper.each(function (d) {

      var width = d3.select(this).node().getBoundingClientRect().width;
      d3.select(this)
        .attr('transform', (d, i) => 'translate(' + (prevX) + ',5)')
      prevX += (width + 5);
    })

    //Image  hover text
    attrs.imgHoverG = svg.patternify({ tag: 'g', selector: 'image-hover-group' })
      .attr('transform', `translate(${calc.chartWidth + attrs.marginLeft - 55},${calc.chartHeight + attrs.marginTop + 50})`)
      .attr('fill', attrs.defaultTextFill)
      .attr('font-size', 11)
      .attr('opacity', 0)

    const hoverX = attrs.imgHoverG.patternify({ tag: 'text', selector: 'hover-x' })
      .text('x - 200')
      .attr('text-anchor', 'end')
      .attr('x', 50)
      
    const hoverY = attrs.imgHoverG.patternify({ tag: 'text', selector: 'hover-y' })
      .text('y - 1500')
      .attr('text-anchor', 'end')
      .attr('y', 13)
      .attr('x', 50)

    const hoverVelocity = attrs.imgHoverG.patternify({ tag: 'text', selector: 'hover-velocity' })
      .text('velocity - 1500')
      .attr('text-anchor', 'end')
      .attr('y', 26)
      .attr('x', 50)

    attrs.pointHoverG = svg
      .patternify({ tag: 'g', selector: 'point-hover-group' })
      .attr(
        'transform',
        `translate(${calc.chartWidth + attrs.marginLeft + 25},${calc.chartHeight + attrs.marginTop + 76})`
      )
      .attr('font-size', 11)
      .attr('fill', attrs.defaultTextFill)
      .attr('opacity', 1);

    const hoverShotFitOriginal = attrs.pointHoverG
      .patternify({ tag: 'text', selector: 'hover-shotfit-original' })
      .text('shotfit - 1500')
      .attr('text-anchor', 'end')
      .attr('y', 0)
      .attr('opacity', 0);

    const hoverShotFit = attrs.pointHoverG
      .patternify({ tag: 'text', selector: 'hover-shotfit' })
      .text('shotfit- 1500')
      .attr('text-anchor', 'end')
      .attr('y', 13)
      .attr('opacity', 0);

    var hoverShotFitMean = attrs.pointHoverG
      .patternify({ tag: 'text', selector: 'hover-shotfit-mean' })
      .text('')
      .attr('text-anchor', 'end')
      .attr('y', 26)
      .attr('opacity', 1);
    
    const hoverShotFitId = attrs.pointHoverG
      .patternify({ tag: 'text', selector: 'hover-shotfit-id' })
      .text('')
      .attr('text-anchor', 'end')
      .attr('y', -39)
      .attr('opacity', 0);

    //hide legend axis line
    hideLegendAxis();

    //remove commas from tick numbers
    formatTickNumbers();
    updateShotfitMean();
    if (attrs.resized) {
      attrs.resized = false;
      svg.call(behaviors.zoom);
      // svg.call(behaviors.zoom).call(behaviors.zoom.transform, zoomIdentity)
      //     .on("dblclick.zoom", null)
    }

    if (attrs.zoomedAtLeastOnce) {

    } else {
      svg.call(behaviors.zoom).call(behaviors.zoom.transform, zoomIdentity)
        .on("dblclick.zoom", null);
    }

    function updateAxisFontSize() {
      attrs.yAxisGroup.selectAll('text').attr('font-size', 12)
      attrs.xAxisGroup.selectAll('text').attr('font-size', 12)
    }

    //########################  EVENT HANDLER FUNCTIONS #################
    function onZoomActionClick(d) {

      switch (d.value) {
        case 'reset':
          svg.call(behaviors.zoom)
            .call(behaviors.zoom.transform, zoomIdentity)
            .on("dblclick.zoom", null);
          break;
        case 'zoomin':
          behaviors.zoom.scaleBy(svg, 1.3);
          break;
        case 'zoomout':
          behaviors.zoom.scaleBy(svg, 1 / 1.3);
          break;
      }
    }

    function zoomed(event) {
      // if (d3.event.sourceEvent) {
      //   if (d3.event.sourceEvent.target.className.baseVal != 'depth-image') {
      //     return
      //   }
      // }
      attrs.zoomedAtLeastOnce = true;
      attrs.lastTransform = event.transform;
      updateChartBasedOnTransform();
    }

    function formatLegendValue(val) {
      if (Math.abs(val) < 1)
        return val.toExponential(2);
      else if (Math.abs(val) < 10)
        return val.toExponential(1);
      else
        return Math.round(val);
    }

    function updateChartBasedOnTransform() {

      if (attrs.lastTransform.k < 1) {
        attrs.lastTransform.k = 1;
      }
      if (attrs.lastTransform.k > attrs.defaultZoomLevel) {
        attrs.lastTransform.k = attrs.defaultZoomLevel;
      }

      if (attrs.lastTransform.x >= (attrs.marginLeft)) {
        attrs.lastTransform.x = attrs.marginLeft;
      }
      if (attrs.lastTransform.y > (attrs.marginTop + 2)) {
        attrs.lastTransform.y = attrs.marginTop + 2;
      }


      if ((attrs.lastTransform.x + calc.chartWidth * attrs.lastTransform.k) < (attrs.marginLeft + calc.chartWidth)) {
        attrs.lastTransform.x = attrs.marginLeft + calc.chartWidth - calc.chartWidth * attrs.lastTransform.k;
      }

      if ((attrs.lastTransform.y + calc.chartHeight * attrs.lastTransform.k) < (attrs.marginTop + calc.chartHeight)) {
        attrs.lastTransform.y = attrs.marginTop + calc.chartHeight - calc.chartHeight * attrs.lastTransform.k;
      }

      const newScaleX = attrs.lastTransform.rescaleX(attrs.scales.x);
      const newScaleY = attrs.lastTransform.rescaleY(attrs.scales.y);
      attrs.lastScaleX = newScaleX;
      attrs.lastScaleY = newScaleY;
      attrs.axes.x.scale(newScaleX);
      attrs.axes.y.scale(newScaleY);


      attrs.yAxisGroup.call(attrs.axes.y);
      attrs.xAxisGroup.call(attrs.axes.x);
      updateAxisFontSize();
      formatTickNumbers();
      chart.attr('transform', attrs.lastTransform)
      chart.selectAll('line')
        .attr('stroke-width', attrs.hoverLineWidth / attrs.lastTransform.k);

      if (attrs.shotsOverlayLegendsVisibility && attrs.shotOverlayCircles) {
        attrs.shotOverlayCircles.attr('r', attrs.shotOverlayPointRadius / attrs.lastTransform.k);
      }
      // .attr('stroke-dasharray', `${3 / attrs.lastTransform.k},${6 / attrs.lastTransform.k}`)

      if (attrs.solidLine && attrs.lightLine) {
        const solidLineDataPoints = Array.isArray(attrs.solidLineData) ? attrs.solidLineData.length : 1;
        const lightLineDataPoints = Array.isArray(attrs.lightLineData) ? attrs.lightLineData.length : 1;
            
        const dataPointScaleFactor = Math.sqrt(solidLineDataPoints / lightLineDataPoints);
        attrs.solidLine.attr('stroke-width', attrs.solidLineWidth / attrs.lastTransform.k);
        // attrs.lightLine.attr('stroke-width', attrs.lightLineWidth / attrs.lastTransform.k); old method doesn't work with lines with different data points 
        attrs.lightLine.attr('stroke-width', 6*(attrs.lightLineWidth * dataPointScaleFactor) / attrs.lastTransform.k);

      }

      if (!!attrs.zeroAxisTick) {
        if (attrs.lastTransform.k != 1 || !attrs.reverseY) {
          attrs.zeroAxisTick.attr('opacity', 0)
        } else {
          attrs.zeroAxisTick.attr('opacity', 1)
        }
      }

      // Update shotfit meant
      updateShotfitMean();

      // --- Update normal line ----
      if (attrs.normalLine && attrs.normalLine.length > 0) {
        for (var i = 0; i < attrs.normalLine.length; i++) {
          attrs.normalLine[i]
            .attr('stroke-width', attrs.normalLineWidth / attrs.lastTransform.k)
        }

      }
      
    }

    //######################################### FUNCTIONS ####################################


    function updateShotfitMean() {
      if (!attrs.shotsOverlayLegendsVisibility || !attrs.shotOverlayCircles || !attrs.shotOverlayData || !hoverShotFitMean) {
        if (hoverShotFitMean)
          hoverShotFitMean.text('');
        return;
      }

      const left = attrs.lastScaleX.invert(attrs.marginLeft);
      const right = attrs.lastScaleX.invert(attrs.marginLeft + calc.chartWidth);
      const top = attrs.lastScaleY.invert(attrs.marginTop);
      const bottom = attrs.lastScaleY.invert(attrs.marginTop + calc.chartHeight);

      const visibleShots = attrs.shotOverlayData.filter((d) => {
        return d.x >= left && d.x < right && d.y >= bottom && d.y <= top;
      });

      const mean = d3.mean(visibleShots, (d) => d.shotFit);
      hoverShotFitMean.text('mean - ' + (mean ? mean.toFixed(2) : ''));
      
    }

    function hideLegendAxis() {
      svg.selectAll('.legend-y-axis-container, .y-axis-container, .x-axis-container')
        .selectAll('.domain')
        .style('display', 'none');
    }

    function formatTickNumbers() {
      svg.selectAll('.tick').select('text')
        .attr('style', 'font-family: "Open Sans", verdana, arial, sans-serif; font-size: 12px; fill: rgb(68, 68, 68); fill-opacity: 1; white-space: pre;')
        .text(function (d) {
          return (d / 1000).toString();
        })
    }


    // ############################ Responsivity  ##############################

    //d3.select(window).on('resize.' + attrs.id, function () {
    //  attrs.resized = true;
    //  setDimensions();
    //  main.run();
    //});

    //function setDimensions() {
    //  var containerRect = container.node().getBoundingClientRect();
    //  if (containerRect.width > 0)
    //    attrs.svgWidth = containerRect.width;
    //}

    function triggerZoomIn() {
      onZoomActionClick({ value: 'zoomin' });
    }

    function triggerZoomOut() {
      onZoomActionClick({ value: 'zoomout' });
    }

    function triggerReset() {
      onZoomActionClick({ value: 'reset' });
    }

    this.zoomIn = triggerZoomIn.bind(this);
    this.zoomOut = triggerZoomOut.bind(this);
    this.reset = triggerReset.bind(this);
    return chart;
  }
}

//----------- PROTOTYEPE FUNCTIONS  ----------------------
d3.selection.prototype.patternify = function (params) {
  var container = this;
  var selector = params.selector;
  var elementTag = params.tag;
  var data = params.data || [selector];

  // Pattern in action
  var selection = container.selectAll('.' + selector.replace(' ', '.')).data(data, (d, i) => {
    if (typeof d === "object") {
      if (d.id) {
        return d.id;
      }
    }
    return i;
  })
  selection.exit().remove();
  selection = selection.enter().append(elementTag).merge(selection)
  selection.attr('class', selector);
  return selection;
}

d3.selection.prototype.removePattern = function (params) {
  var container = this;
  var selector = params.selector;
  var elementTag = params.tag;
  var data = params.data || [selector];

  // Pattern in action
  var selection = container.selectAll('.' + selector).data(data, (d, i) => {
    if (typeof d === "object") {
      if (d.id) {
        return d.id;
      }
    }
    return i;
  })
  selection.exit().remove();
}
