import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";
import { AuthService } from "./auth.service";
import { HttpHeaders } from "@angular/common/http";
import { map, retry } from "rxjs/operators";

@Injectable()
export class ScoresService {
  constructor(private http: HttpService, private authService: AuthService) {}

  getScores(): Observable<any> {
    let apiUrl = `/assets/data/scores.json`;

    return this.http.get(apiUrl, this.getAuthorizationHeader()).pipe(
      retry(2),
      map((response: Response) => {
        if (!response) return null;

        return response;
      })
    );
  }

  private getAuthorizationHeader(): { headers: HttpHeaders } {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );
    return { headers };
  }
}
