import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-proj-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class ProjSideBarComponent implements OnInit, OnDestroy {
  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
