import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition, state } from '@angular/animations';

import { ListItem } from '../shared/list/listitem.model';
import { BreadCrumb } from '../shared/breadcrumb/breadcrumb.model';
import { Project } from "../models/project";
import { Job } from "../models/job";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectNameComponent } from './project/dialogs/project-name/project-name.component';
import { ProjectDialog } from '../models/projectDialog';
import { AuthService } from '../shared/services/auth.service';
import { Subject, Subscription } from 'rxjs';
import { CachedUserService } from '../shared/services/cached-user.service';
import { Router } from '@angular/router';
import { ProjectService } from '../shared/services/project.service';
import { CachedProjectService } from '../shared/services/cached-project.service';
import { take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzOptionComponent } from 'ng-zorro-antd/select';
import { Field } from '../models/field';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.less'],
  animations: [
    trigger('slideIn', [
    state('*', style({ 'overflow-y': 'hidden' })),
    state('void', style({ 'overflow-y': 'hidden' })),
    transition('* => void', [
      style({ height: '*', opacity: 1 }),
      animate(250, style({ height: 0, opacity: 0 }))
    ]),
    transition('void => *', [
      style({ height: '0', opacity: 0 }),
      animate(250, style({ height: '*', opacity: 1 }))
    ])
  ])]
})
export class ProjectsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  
  projects: Project[];
  jobItems: {};
  isStaff = false;
  subscription: Subscription = null;
  projectModalVisible: boolean = false;
  projectFormGroup: FormGroup
  projects2: {id: string, name: string, description: string, field?: string}[];
  selectedProject: {id: string, name: string, description: string} = null;
  projectJobs: Job[] = [];
  searchString: string = ""
  selectedJob: any;
  projectDescriptipon: string;
  filteredProjects: { id: string; name: string; description: string, field?: string }[];
  fields: Field[] = []
  isLoading: boolean = false
  selectedField: Field | null = null;
  
  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, private notificationService: NzNotificationService, private fb: FormBuilder, private projectService: ProjectService, private cachedProjectService: CachedProjectService, public authService: AuthService, private userService: CachedUserService, private router: Router) { 
    this.getFields()
    this.projectFormGroup = this.fb.group({
      name: new FormControl<String>(null, [Validators.required]),
      description: new FormControl<String>(''),
      archiveEnabled:  new FormControl<Boolean>(false),
      isParameterScan: new FormControl<Boolean>(false),
      field: new FormControl('', [Validators.required])
    })
  }

  getListFromJobs(jobs: Job[]): ListItem[] {
    return jobs.map(j => {
      if (j.parent_job_id != null) {
        // console.log(j.parent_job_id)
      }
      return new ListItem(j.id, j.name, null,'/projects/' + j.id);
    });
  }

  activeProject: string;

  clickProject(project: Project) {
    this.activeProject === project.id ? this.activeProject = null : this.activeProject = project.id;
  }

  isActiveProject(project: Project): boolean {
    return this.activeProject === project.id;
  }

  breadcrumb = [new BreadCrumb('Home', '/'), new BreadCrumb('Projects', '/projects')];

  ngOnInit() {
    this.cachedProjectService.setCurrentProject(null);

    this.loadProjects2();

    this.subscription = this.userService.userDetail.pipe(takeUntil(this.destroy$)).subscribe(d => {
      this.isStaff = d && d.isStaff;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  this.destroy$.complete();
  }

  loadProjects() {
    this.projectService.getProjects().pipe(takeUntil(this.destroy$))
      .subscribe(projects => {
        this.projects = projects;
        this.jobItems = {};
        for (let project of projects) {
          this.jobItems[project.id] = this.getListFromJobs(project.jobs);
        }
      });
  }

  loadProjects2(){
    this.projectService.getAllProjects().pipe(take(1)).subscribe({
      next:(data)=>{
        this.projects2 = data
        this.filteredProjects = data
      },
      error: (err) => {
        console.log('err', err)
      }
    })
  }

  onJobSelected(job: Job){
    this.router.navigate(["/projects/" + job.id]);
  }

  getProjectJobs(){
    this.projectDescriptipon = this.selectedProject.description
    this.projectService.getProjectById(this.selectedProject.id).pipe(take(1)).subscribe({
      next: res => {
        this.projectJobs = res.jobs
      },
      error: (err) => {
        console.log('err', err)
      }
    })
  }

  onFieldSelected() {
    this.selectedProject = null
    this.projectDescriptipon = null
    if (!this.selectedField){
      this.filteredProjects = this.projects2
      return 
    }
    this.filteredProjects = this.projects2.filter(p => p.field == this.selectedField.id)
  }
  
  onProjectSelected() {
    this.getProjectJobs()
  }

  goToProjectPage() {
    if (!this.selectedProject)
      return;
    this.router.navigate(["project", this.selectedProject.id, "parameter-view"]);
  }
  
  searchFn(searchString: string, option: NzOptionComponent){
    return String(option.nzLabel).toLocaleLowerCase().replace(/_|-/g, ' ').includes(searchString.toLocaleLowerCase())
  }

  goToCostAnalysis() {
    this.router.navigate(["cost-analysis"]);
  }

  gotoMonitoring() {
    this.router.navigate(['/monitoring'])
  }

  openProjectDialog() {
    let dialogRef = this.dialog.open(ProjectNameComponent, {
      width: '400px',
      data: {
        id:null,
        name: null,
        description: null, 
        field: null
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((result: ProjectDialog) => {
      if (!result)
        return;
      this.projectService.createProject(result).pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.snackBar.open("Project created successfully", null, { duration: 2000 });

        this.loadProjects();
      }, (error) => {
        this.snackBar.open("Sorry, there was an error creating the project", null, { duration: 2000 });
        });
    });
  }

  navigateToProjectTable(project: Project) {
    this.router.navigate(['/project', project.id]);
  }

  getFields() {
    this.isLoading = true;
    this.projectService
      .getAllFields()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.fields = res.fields;
          this.isLoading = false;
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error || "There was an unexepected error"
          );
          this.isLoading = false;
        },
      });
  }

  createProject() {
    if (this.projectFormGroup.valid){
      this.projectService.createProject({...this.projectFormGroup.value}).pipe(take(1)).subscribe({
        next: (res) => {
          this.loadProjects()
          this.projectModalVisible  = false
          this.projectFormGroup.reset()
          this.notificationService.success('Success', 'project created')
        },
        error: (err) => {
          console.log('error', err)
          this.notificationService.error('Error', 'project could not be created due to an error')
        },
      })
    }
  }
}
