<section id="parameter-filter-box">
  <div
    style="
      width: 100%;
      padding-left: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    "
  >
    <button
      (click)="emitSelected()"
      mat-raised-button
      [disabled]="!this.columnsChanged"
      [style.background]="this.columnsChanged ? '#2196f3' : '#ccc'"
      style="color: white; flex: 1 1 auto"
    >
      Apply
    </button>

    <button style="flex: 1 1 auto" (click)="selectAll()" mat-raised-button>
      Select All
    </button>
    <button style="flex: 1 1 auto" (click)="deselectAll()" mat-raised-button>
      Deselect All
    </button>

    <ng-content></ng-content>
    <!-- Parameter View Specific special filters -->
    <ng-container *ngIf="this.isParameterView">
      <button
        style="flex: 1 1 auto"
        mat-raised-button
        (click)="this.openMatchingJobsOptions()"
      >
        Find Similar Jobs
      </button>

      <mat-slide-toggle
        style="flex: 1 1 auto"
        (change)="this.showChangingColumnsHandler()"
        [checked]="!this.showChangingColumnsControl"
      >
        Show Unchanging Columns
      </mat-slide-toggle>
    </ng-container>

    <nz-form-item class="_search-field" style="width: 100%">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input
          nz-input
          type="Search"
          [(ngModel)]="this.searchValue"
          (keyup)="this.searchOptions($event)"
          placeholder=""
        />
        <nz-form-label [nzNoColon]="true">Search</nz-form-label>
      </nz-input-group>
    </nz-form-item>
    <ng-template #suffixIconSearch>
      <span nz-icon nzType="search"></span>
    </ng-template>
  </div>

  <div id="params-listing" #paramsListing>
    <div [formGroup]="this.optionsControl">
      <ng-container *ngIf="this.isArray(this.options); else objectList">
        <ng-container *ngFor="let opt of this.options">
          <div class="child">
            <mat-checkbox [formControlName]="opt">{{ opt }}</mat-checkbox>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #objectList>
        <ng-container
          *ngFor="let key of this.getObjectKeys(this.options).sort()"
        >
          <div
            class="parent child"
            [id]="key"
            #parent
            [style.display]="
              this.filteredOptions[key].length > 0 ? 'block' : 'none'
            "
          >
            <div class="label-container">
              <mat-checkbox
                style="pointer-events: auto"
                (change)="categorySelected($event, key)"
                [checked]="this.categoriesControl.value[key]"
                [indeterminate]="
                  this.categoriesControl.value[key] == 'indeterminate'
                "
              >
                &nbsp;&nbsp;
              </mat-checkbox>
              <span
                class="parent-category-collapser"
                (click)="activateCollapse(parent)"
                >{{ this.labelsFormatter(key) }}</span
              >
              <i class="collapsible-chevron zmdi zmdi-chevron-left"></i>
            </div>
            <fieldset [formGroupName]="key">
              <ng-container *ngFor="let child of this.filteredOptions[key]">
                <div class="child">
                  <mat-checkbox
                    (change)="childSelected($event, key)"
                    [formControlName]="child"
                    >{{ this.labelsFormatter(child) }}</mat-checkbox
                  >
                </div>
              </ng-container>
            </fieldset>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</section>
