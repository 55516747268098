<div>
  <form
    nz-form
    [formGroup]="horizonsForm"
    (ngSubmit)="submitForm()"
    class="form-container"
  >
    <div nz-row [nzGutter]="24">
      <div nz-col [nzXs]="24" [nzSm]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="name">Name</nz-form-label>
          <nz-form-control nzErrorTip="The input required!">
            <input
              placeholder="Horizon name"
              nz-input
              formControlName="name"
              id="name"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="24" [nzSm]="12">
        <nz-form-item>
          <nz-form-label nzFor="description">Description</nz-form-label>
          <nz-form-control>
            <input
              placeholder="Horizon description"
              nz-input
              formControlName="description"
              id="description"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <nz-form-item>
      <nz-form-label nzRequired nzFor="horizon_path"
        >Horizon Path</nz-form-label
      >
      <nz-form-control
        [nzSm]="24"
        [nzXs]="24"
        nzErrorTip="The input is not valid Horizon path!"
      >
        <input
          placeholder="Horizon file path"
          nz-input
          formControlName="horizon_path"
          id="horizon_path"
        />
      </nz-form-control>
    </nz-form-item>

    <div
      style="
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        margin-bottom: 15px;
      "
    >
      <button type="reset" (click)="resetForm()" nz-button>Reset</button>
      <button nzType="primary" type="submit" nz-button>Submit</button>
    </div>
  </form>
</div>

<div>
  <nz-table id="listing-table" #horizonsTable [nzData]="horizons">
    <thead>
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Horizon Path</th>
        <th [nzWidth]="150">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of horizonsTable.data">
        <td>{{ data.name }}</td>
        <td>{{ data.description }}</td>
        <td>{{ data.horizon_path }}</td>
        <td [width]="150">
          <a (click)="editHorizon(data)">Edit</a>
          <nz-divider nzType="vertical"></nz-divider>
          <a (click)="deleteHorizon(data)">Delete</a>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
