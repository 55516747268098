import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Subscription, Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { CachedProjectService } from "../../shared/services/cached-project.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.less"],
})
export class BannerComponent implements OnInit, OnDestroy {
  @Input("button-text") buttonText: string;
  @Input() heading: string;
  @Input() description: string;
  @Output("button-event") buttonLink = new EventEmitter();
  @Input() small: boolean;

  projectControl = new UntypedFormControl();
  jobControl = new UntypedFormControl();
  projectFilteredOptions: Observable<any[]>;
  jobFilteredOptions: Observable<any[]>;

  projects: any[];
  jobs: any[];

  private projectSubscription: Subscription = null;

  constructor(
    private projectService: CachedProjectService,
    private router: Router
  ) {}

  ngOnInit() {
    this.projectControl.disable();
    this.jobControl.disable();

    this.projectSubscription = this.projectService
      .getProjects()
      .subscribe((p) => {
        this.projects = p;
        this.projectControl.enable();
      });
  }

  ngOnDestroy() {
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
      this.projectSubscription = null;
    }
  }

  projectSelected2(selectedProject: string) {
    let project = this.projects.find((p) => p.name == selectedProject);
    if (!project) return;
    this.jobs = project.jobs;
    this.jobControl.enable();
  }

  jobSelected2(selectedJob: string) {
    let job = this.jobs.find((j) => j.name == selectedJob);
    this.router.navigate(["/projects/" + job.id]);
  }

  goToProjectPage() {
    if (this.projectControl.value == "" || this.projectControl.value == null)
      return;
    let activeProject = this.projects.find(
      (p) => p.name == this.projectControl.value
    );
    this.router.navigate(["project", activeProject.id, "parameter-view"]);
  }

  buttonPressed() {
    this.buttonLink.emit();
  }
}
