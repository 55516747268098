import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";
import { ProjectService } from "../../../shared/services/project.service";

interface ProjectItemData {
  id: string;
  name: string;
  archive_enabled: Boolean;
  potential_project_savings: number;
  project_cost: number;
  job_costs: Array<any>;
}

interface JobItemData {
  job_id: string;
  job_name: string;
  soft_archive_status: any;
  keep_job: any;
  storage_cost: number;
  potential_savings?: number;
}

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<ProjectItemData> | null;
  sortDirections: NzTableSortOrder[];
  listOfFilter?: NzTableFilterList;
  filterMultiple?: boolean;
  filterFn?: NzTableFilterFn<ProjectItemData> | null;
  width?: string;
}

interface InnerColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<JobItemData> | null;
  sortDirections: NzTableSortOrder[];
  listOfFilter?: NzTableFilterList;
  filterMultiple?: boolean;
  filterFn?: NzTableFilterFn<JobItemData> | null;
  width?: string;
}

@Component({
  selector: "app-inner-table",
  templateUrl: "./inner-table.component.html",
  styleUrls: ["./inner-table.component.css"],
})
export class InnerTableComponent implements OnChanges {
  listOfInnerColumns: InnerColumnItem[] = [
    {
      name: "Job Name",
      sortOrder: null,
      sortFn: (a: JobItemData, b: JobItemData) =>
        a.job_name.localeCompare(b.job_name),
      sortDirections: ["ascend", "descend", null],
    },
    {
      name: "Storage Status",
      sortOrder: null,
      sortFn: (a: JobItemData, b: JobItemData) =>
        a.soft_archive_status.localeCompare(b.soft_archive_status),
      sortDirections: ["ascend", "descend", null],
      width: "200px",
    },
    // {
    //   name: "Keep",
    //   sortOrder: null,
    //   sortFn: (a: JobItemData, b: JobItemData) =>
    //     a.keep_job.localeCompare(b.keep_job),
    //   sortDirections: ["ascend", "descend", null],
    //   width: "150px",
    // },
    {
      name: "Storage Cost",
      sortOrder: "descend",
      sortFn: (a: JobItemData, b: JobItemData) => {
        if (a.storage_cost < b.storage_cost) return -1;
        if (a.storage_cost > b.storage_cost) return 1;
        return 0;
      },
      sortDirections: ["ascend", "descend", null],
      width: "200px",
    },
    {
      name: "Potential Savings",
      sortOrder: null,
      sortFn: (a: JobItemData, b: JobItemData) => {
        if (Number(a.potential_savings) < Number(b.potential_savings))
          return -1;
        if (Number(a.potential_savings) > Number(b.potential_savings)) return 1;
        return 0;
      },
      sortDirections: ["ascend", "descend", null],
      width: "200px",
    },
  ];

  isLoading = false;
  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();

  jobCostsData: any[] = [];

  @Input("project") project: ProjectItemData;
  @Output() refreshData = new EventEmitter();

  constructor(
    private projectSevice: ProjectService,
    private message: NzMessageService
  ) {
    this.jobCostsData = this.project?.job_costs || [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project) {
      this.jobCostsData = this.project?.job_costs || [];
    }
  }

  keepAllSelected() {
    const arryOfCheckedIds = Array.from(this.setOfCheckedId);
    this.projectSevice
      .setProjectArchiving({
        jobIds: arryOfCheckedIds,
        keep: true,
      })
      .subscribe(
        (res) => {
          this.message.success("Keeping selected jobs successfully!");
          this.isLoading = false;
          this.refreshData.emit();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }
  unkeepAllSelected() {
    const arryOfCheckedIds = Array.from(this.setOfCheckedId);
    this.projectSevice
      .setProjectArchiving({
        jobIds: arryOfCheckedIds,
        keep: false,
      })
      .subscribe(
        (res) => {
          this.message.success("Unkeeping selected jobs successfully!");
          this.isLoading = false;
          this.refreshData.emit();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }

  unkeepJob(job) {
    this.projectSevice
      .setProjectArchiving({
        jobIds: [job.job_id],
        keep: false,
      })
      .subscribe(
        (res) => {
          this.message.success(
            `Unkeeping ${
              job.job_name.length > 30
                ? job.job_name.substring(1, 30)
                : job.job_name
            } successfully!`
          );
          this.isLoading = false;
          this.refreshData.emit();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }
  keepJob(job) {
    this.projectSevice
      .setProjectArchiving({
        jobIds: [job.job_id],
        keep: true,
      })
      .subscribe(
        (res) => {
          this.message.success(`Keeping ${job.job_name} successfully!`);
          this.isLoading = false;
          this.refreshData.emit();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }
  // functions for ng-zorro table selection
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  onCurrentPageDataChange(): void {
    // this.costsData = costsData;
    this.refreshCheckedStatus();
  }
  refreshCheckedStatus(): void {
    const listOfEnabledData = this.jobCostsData.filter(
      ({ disabled }) => !disabled
    );
    this.checked = listOfEnabledData.every(({ job_id }) =>
      this.setOfCheckedId.has(job_id)
    );
    this.indeterminate =
      listOfEnabledData.some(({ job_id }) => this.setOfCheckedId.has(job_id)) &&
      !this.checked;
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  onAllChecked(checked: boolean): void {
    this.jobCostsData
      .filter(({ disabled }) => !disabled)
      .forEach(({ job_id }) => this.updateCheckedSet(job_id, checked));
    this.refreshCheckedStatus();
  }
}
