import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { style, animate, transition, trigger } from "@angular/animations";
import { Router } from "@angular/router";
import { JobDetail } from "../../../models/jobDetail";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { Subscription, Observable, of } from "rxjs";
import { CachedUserService } from "../../../shared/services/cached-user.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { JobDialog } from "../../../models/jobDialog";
import { JobDeleteComponent } from "../dialogs/job-delete/job-delete.component";
import { diff_match_patch } from "diff-match-patch";
import { UntypedFormControl } from "@angular/forms";
import { startWith, map, switchMap, finalize } from "rxjs/operators";
import { range } from "lodash";

import { MatTableDataSource } from "@angular/material/table";
import * as Diff from "diff";
import { Buffer } from "buffer";
import { inflate } from "pako";

export interface DiffTableElement {
  "Field Name": string;
  this: string;
  other: string;
}

export interface Difference {
  originalLine: string;
  modifiedLine: string;
  added: boolean;
  removed: boolean;
}

// declare const Buffer;
@Component({
  selector: "app-runfile",
  templateUrl: "./runfile.component.html",
  styleUrls: ["./runfile.component.less"],
  animations: [
    // trigger('fadeInOut', [
    //   transition(':enter', [   // :enter is alias to 'void => *'
    //     style({ opacity: 0 }),
    //     animate(500, style({ opacity: 1 }))
    //   ]),
    //   transition(':leave', [   // :leave is alias to '* => void'
    //     animate(500, style({ opacity: 0 }))
    //   ])
    // ])
  ],
})
export class RunfileComponent implements OnInit, OnDestroy {
  currentJob: JobDetail;
  displayContent: string;
  displayDiff: boolean = false;
  displayedColumns = ["Field Name", "this", "other"];
  diffTable: DiffTableElement[] = [];
  dataSource = new MatTableDataSource(this.diffTable);
  runfile: string;
  isLoading: boolean = true;
  otherJob: any;
  currentOtherJob: any;
  jobControl: UntypedFormControl;
  filteredJobs: any;
  currentIteration: number = 1;
  iterations: number[];
  private _jobSubscription: Subscription;
  private _userSubscription: Subscription;
  private _otherRunfileSubscription: Subscription;
  private _changeIterSubscription: Subscription;
  private otherJobs: { name: string; id: string }[];
  differences: Difference[];
  diffoutput: string;
  rawDiff = true;
  runfiles: Map<string, string> = new Map<string, string>();
  timestamps: Map<string, Date> = new Map<string, Date>();
  mapSize: number = 5;
  scrollPos: number = 0;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private projectService: CachedProjectService,
    private userService: CachedUserService,
    private router: Router
  ) {
    this.jobControl = new UntypedFormControl();
  }

  ngOnInit() {
    this._jobSubscription = this.projectService.currentJob
      .pipe(
        switchMap((d) => {
          this.isLoading = true;
          this.currentJob = d;
          if (!d) return of(null);
          this.iterations = range(1, d.iterationsComplete + 1, 1);
          if (this._otherRunfileSubscription != null) {
            this._otherRunfileSubscription.unsubscribe();
            this._otherRunfileSubscription = null;
          }
          if (this._changeIterSubscription != null) {
            this._changeIterSubscription.unsubscribe();
            this._changeIterSubscription = null;
          }
          return this.projectService.getProjects();
        }),
        switchMap((projects) => {
          if (!projects) return of(null);
          let currentProject = projects.find(
            (p) => p.id === this.currentJob.projectId
          );
          if (currentProject) {
            this.otherJobs = currentProject.jobs
              .filter((j) => j.id != this.currentJob.id)
              .map((j) => {
                return { name: j.name, id: j.id };
              });
            this.filteredJobs = this.jobControl.valueChanges.pipe(
              startWith(""),
              map((key: string) =>
                key ? this._filterJobs(key) : this.otherJobs.slice()
              )
            );
          }

          if (this.runfiles.has(this.currentJob.id)) {
            this.runfile = this.runfiles.get(this.currentJob.id);
            this.showRunfile();
            return of("found in map");
          }

          return this.projectService.getRunfile(this.currentJob.id);
        })
      )
      .subscribe((runfile) => {
        // this.runfile = '';
        setTimeout(() => (this.isLoading = false), 0);
        if (runfile == "found in map") return;
        if (!runfile || !runfile.runfile) {
          // this.runfile = 'No runfile found';
          this.displayContent = "No runfile found";
          this.displayDiff = false;
          this.otherJob = null;
          this.currentIteration = null;
          // this.showRunfile();
          return;
        }
        this.currentIteration = 1;
        let compressed_runfile = runfile.runfile;
        let runfileBuffer = new Buffer(compressed_runfile, "base64");
        // zlib.inflate(runfileBuffer, (err, data) => {
        let inflated = inflate(runfileBuffer, { to: "string" });

        if (!inflated.err && inflated) {
          this.runfile = inflated;
          // this.displayContent = this.runfile
          // this.showRunfile()
          if (this.runfiles.size == this.mapSize) {
            let earliest = this.findStringWithEarliestDate(this.timestamps);
            this.runfiles.delete(earliest);
            this.timestamps.delete(earliest);
          }
          this.runfiles.set(this.currentJob.id, this.runfile);
          this.timestamps.set(this.currentJob.id, new Date());
          this.showRunfile();
        }
        // (err, data) => {
        //   if (!err) {
        //     this.runfile = data.toString();
        //     // this.displayContent = this.runfile
        //     // this.showRunfile()
        //     if (this.runfiles.size == this.mapSize) {
        //       let earliest = this.findStringWithEarliestDate(this.timestamps);
        //       this.runfiles.delete(earliest);
        //       this.timestamps.delete(earliest);
        //     }
        //     this.runfiles.set(this.currentJob.id, this.runfile);
        //     this.timestamps.set(this.currentJob.id, new Date());
        //     this.showRunfile();
        //   }
        // }
        // this.showRunfile()
      });

    this._userSubscription = this.userService.userDetail.subscribe((d) => {
      if (
        d &&
        !(
          d.isStaff ||
          d.email === "testuser01@s-cube.com" ||
          (d.runfileAccess != null && d.runfileAccess)
        )
      )
        this.router.navigateByUrl("/");
    });
  }

  ngOnDestroy() {
    if (this._jobSubscription) {
      this._jobSubscription.unsubscribe();
    }
    if (this._userSubscription) {
      this._userSubscription.unsubscribe();
    }
    if (this._otherRunfileSubscription != null) {
      this._otherRunfileSubscription.unsubscribe();
      this._otherRunfileSubscription = null;
    }
    if (this._changeIterSubscription != null) {
      this._changeIterSubscription.unsubscribe();
      this._changeIterSubscription = null;
    }
  }

  formatDifferences(text1: string, text2: string) {
    const diff = Diff.diffLines(text1, text2, { newlineIsToken: true });
    const differences: Difference[] = [];

    let index = 0;
    while (index < diff.length) {
      let part = diff[index];
      if (part.removed) {
        let nextPart = diff[index + 1];
        if (nextPart && nextPart.added) {
          differences.push({
            originalLine: part.value,
            modifiedLine: nextPart.value,
            added: true,
            removed: true,
          });
          index += 2;
          continue;
        } else {
          let originals = part.value.split("\n");
          let modified = originals.map((str) => "").join("\n");
          differences.push({
            originalLine: part.value,
            modifiedLine: modified,
            added: false,
            removed: true,
          });
          index++;
          continue;
        }
      } else if (part.added) {
        let modifieds = part.value.split("\n");
        let original = modifieds.map((str) => "").join("\n");
        differences.push({
          originalLine: original,
          modifiedLine: part.value,
          added: true,
          removed: false,
        });
        index++;
        continue;
      } else {
        differences.push({
          originalLine: part.value,
          modifiedLine: part.value,
          added: false,
          removed: false,
        });
        index++;
        continue;
      }
    }
    return differences;
  }

  getDiff() {
    this.diffTable = [];
    let dmp = new diff_match_patch();
    this.currentOtherJob = this.otherJob;
    let otherId = (
      this.otherJobs.find((j) => j.name === this.otherJob) || { id: null }
    ).id;
    if (!otherId) return;

    if (this._otherRunfileSubscription != null) {
      this._otherRunfileSubscription.unsubscribe();
      this._otherRunfileSubscription = null;
    }
    this._otherRunfileSubscription = this.projectService
      .getRunfile(otherId, this.currentIteration)
      .subscribe((otherRunfile) => {
        if (!otherRunfile || !otherRunfile.runfile) {
          this.displayContent = "Runfile Not Found";
          return;
        }
        let compressed_runfile = otherRunfile.runfile;
        let runfileBuffer = new Buffer(compressed_runfile, "base64");
        // zlib.inflate(runfileBuffer, (err, data) => {
        let inflated = inflate(runfileBuffer, { to: "string" });

        if (inflated && !inflated.err) {
          let otherContent = inflated;
          // if (this.rawDiff) {
          this.differences = this.formatDifferences(this.runfile, otherContent);
          // }
          let a = dmp.diff_linesToChars_(this.runfile, otherContent);
          let diffs = dmp.diff_main(a.chars1, a.chars2, false);
          dmp.diff_charsToLines_(diffs, a.lineArray);
          let diffParams = [];
          diffs.forEach((d) => {
            let lines = d[1].split("\n");
            diffParams = diffParams.concat(
              lines
                .filter(
                  (line) =>
                    line &&
                    !line.trimStart().startsWith("!") &&
                    !line.includes("Locator")
                )
                .map((line) => [d[0], line])
            );
          });

          let deletedParams = [];
          let addedParams = [];
          diffParams.forEach((diff) => {
            let params = diff[1].split(":", 2).map((s) => s.trim());
            if (diff[0] == -1) deletedParams.push(params);
            else if (diff[0] == 1) addedParams.push(params);
          });
          let allParams = Array.from(
            new Set(deletedParams.concat(addedParams).map((p) => p[0]))
          );
          let diffText = "";
          let oldVersionText = this.runfile.split("\n", 1)[0];
          let newVersionText = otherContent.split("\n", 1)[0];
          if (
            oldVersionText.startsWith("! Produced by WRITEPARMS") &&
            newVersionText.startsWith("! Produced by WRITEPARMS")
          ) {
            let oldVersion = oldVersionText.replace(
              "! Produced by WRITEPARMS",
              ""
            );
            let newVersion = newVersionText.replace(
              "! Produced by WRITEPARMS",
              ""
            );
            // console.log(oldVersion)
            // console.log(newVersion)
            if (oldVersion !== newVersion) {
              diffText = `Code Version        :       ${oldVersion}    ->    ${newVersion}\n`;
              this.diffTable.push({
                "Field Name": "Code Version",
                this: oldVersion,
                other: newVersion,
              });
            }
          }
          allParams.forEach((p) => {
            let originalValue = (deletedParams.find((d) => d[0] === p) || [
              p,
              "",
            ])[1];
            let newValue = (addedParams.find((d) => d[0] === p) || [p, ""])[1];
            diffText = diffText.concat(
              `${p}        :      ${originalValue}    ->    ${newValue}\n`
            );
            this.diffTable.push({
              "Field Name": p,
              this: originalValue,
              other: newValue,
            });
          });
          this.displayContent = diffText;
          this.diffTable = Array.from(this.diffTable);
          this.displayDiff = true;
        }

        // (err, data) => {
        //   if (!err) {
        //     let otherContent = data.toString();
        //     // if (this.rawDiff) {
        //     this.differences = this.formatDifferences(
        //       this.runfile,
        //       otherContent
        //     );
        //     // }
        //     let a = dmp.diff_linesToChars_(this.runfile, otherContent);
        //     let diffs = dmp.diff_main(a.chars1, a.chars2, false);
        //     dmp.diff_charsToLines_(diffs, a.lineArray);
        //     let diffParams = [];
        //     diffs.forEach((d) => {
        //       let lines = d[1].split("\n");
        //       diffParams = diffParams.concat(
        //         lines
        //           .filter(
        //             (line) =>
        //               line &&
        //               !line.trimStart().startsWith("!") &&
        //               !line.includes("Locator")
        //           )
        //           .map((line) => [d[0], line])
        //       );
        //     });

        //     let deletedParams = [];
        //     let addedParams = [];
        //     diffParams.forEach((diff) => {
        //       let params = diff[1].split(":", 2).map((s) => s.trim());
        //       if (diff[0] == -1) deletedParams.push(params);
        //       else if (diff[0] == 1) addedParams.push(params);
        //     });
        //     let allParams = Array.from(
        //       new Set(deletedParams.concat(addedParams).map((p) => p[0]))
        //     );
        //     let diffText = "";
        //     let oldVersionText = this.runfile.split("\n", 1)[0];
        //     let newVersionText = otherContent.split("\n", 1)[0];
        //     if (
        //       oldVersionText.startsWith("! Produced by WRITEPARMS") &&
        //       newVersionText.startsWith("! Produced by WRITEPARMS")
        //     ) {
        //       let oldVersion = oldVersionText.replace(
        //         "! Produced by WRITEPARMS",
        //         ""
        //       );
        //       let newVersion = newVersionText.replace(
        //         "! Produced by WRITEPARMS",
        //         ""
        //       );
        //       // console.log(oldVersion)
        //       // console.log(newVersion)
        //       if (oldVersion !== newVersion) {
        //         diffText = `Code Version        :       ${oldVersion}    ->    ${newVersion}\n`;
        //         this.diffTable.push({
        //           "Field Name": "Code Version",
        //           this: oldVersion,
        //           other: newVersion,
        //         });
        //       }
        //     }
        //     allParams.forEach((p) => {
        //       let originalValue = (deletedParams.find((d) => d[0] === p) || [
        //         p,
        //         "",
        //       ])[1];
        //       let newValue = (addedParams.find((d) => d[0] === p) || [
        //         p,
        //         "",
        //       ])[1];
        //       diffText = diffText.concat(
        //         `${p}        :      ${originalValue}    ->    ${newValue}\n`
        //       );
        //       this.diffTable.push({
        //         "Field Name": p,
        //         this: originalValue,
        //         other: newValue,
        //       });
        //     });
        //     this.displayContent = diffText;
        //     this.diffTable = Array.from(this.diffTable);
        //     this.displayDiff = true;
        //   }
        // }
      });
  }

  showRunfile() {
    this.displayContent = this.runfile;
    // this.currentIteration = null;
    this.otherJob = null;
    this.displayDiff = false;
  }

  changeIteration(iter) {
    this.currentIteration = iter;

    if (this._changeIterSubscription != null) {
      this._changeIterSubscription.unsubscribe();
      this._changeIterSubscription = null;
    }
    this._changeIterSubscription = this.projectService
      .getRunfile(this.currentJob.id, this.currentIteration)
      .pipe(finalize(() => this.getDiff()))
      .subscribe((runfile) => {
        this.runfile = "";
        if (!runfile || !runfile.runfile) return;
        let compressed_runfile = runfile.runfile;
        let runfileBuffer = new Buffer(compressed_runfile, "base64");
        this.runfile = inflate(runfileBuffer, { to: "string" });
        this.showRunfile();
      });
  }

  deleteJob(job: JobDetail) {
    let dialogRef = this.dialog.open(JobDeleteComponent, {
      data: {
        id: job.id,
        name: job.name,
        projectId: null,
      },
    });

    dialogRef.afterClosed().subscribe((result: JobDialog) => {
      if (!result) return;
      this.projectService.deleteJob(result.id).subscribe(
        (data) => {
          this.router.navigate(["/projects"]);
          return;
        },
        (error) => {
          this.snackBar.open("Sorry, the job could not be deleted", null, {
            duration: 2000,
          });
        }
      );
    });
  }

  private _filterJobs(name) {
    return this.otherJobs.filter(
      (j) => j.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  findStringWithEarliestDate(dateMap: Map<string, Date>): string | undefined {
    const entriesArray = Array.from(dateMap.entries());

    const earliestEntry = entriesArray.reduce((earliest, current) => {
      const [, earliestDate] = earliest;
      const [, currentDate] = current;
      return currentDate < earliestDate ? current : earliest;
    });

    return earliestEntry ? earliestEntry[0] : undefined;
  }
}
