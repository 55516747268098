import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy, AfterContentInit } from '@angular/core';
import { JobDetail } from '../../../models/jobDetail';
import { CachedProjectService } from '../../../shared/services/cached-project.service';
import { ActivatedRoute } from '@angular/router';
import { CostItem } from '../../../models/costItem';

import { Subscription } from 'rxjs';

declare var CanvasJS: any;

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.less']
})
export class CostsComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('chart', { static: true }) chart: ElementRef;
  currentJob: JobDetail;
  isLoading: boolean = true;
  noDataMessage: boolean = false;
  costs: CostItem[] = [];
  filteredCosts: CostItem[] = [];
  totalTime: number;
  totalCost: number;
  page: number = 1;
  canvasChart: any = null;
  jobSubscription: Subscription;
  isSimple = false;
  height = 320;
  radius = 1;
  shotId;

  constructor(private projectService: CachedProjectService, private route: ActivatedRoute) {

  }


  ngAfterContentInit () {
    if (!!this.canvasChart) {
      this.canvasChart.destroy();
      this.canvasChart = null;
    }
    this.isLoading = true;
    this.noDataMessage = false;

    if (this.jobSubscription) {
      this.jobSubscription.unsubscribe();
    }

    this.jobSubscription = this.projectService.currentJob
      .subscribe(d => {
        if (!d)
          return;
        this.isLoading = true;
        this.currentJob = d;

        this.projectService.getCosts(d.id, d.iterations, this.currentJob.x2_is_inline).subscribe(d => {
          if (!d) {
            this.isLoading = false;
            this.noDataMessage = true;
            return;
          }
          this.noDataMessage = false;
          // this.isSimple = (d.type === "simple_shot_geom");
          this.isSimple = true; //set to false no matter what for now
          this.costs = d.costItems;
          this.filteredCosts = Array.from(this.costs);
          var iterations = this.costs.map(c => c.iterations).reduce((a, b) => a + b, 0)/this.costs.length;
          this.totalCost = this.costs.map(c => c.cost).reduce((a, b) => a + b, 0) / iterations;
          this.totalTime = Math.round(this.costs.map(c => c.nodeHours).reduce((a, b) => a + b, 0) / iterations);

          this.updatePlot();

          this.isLoading = false;
        });
      });
  }

  ngOnDestroy(): void {
    if (this.jobSubscription) {
      this.jobSubscription.unsubscribe();
      this.jobSubscription = null;
    }
  }

  ngOnInit(): void {

  }


  getScale() {
    let height: number;
    let gridSpace = this.currentJob.modelGrid.dx;
    if (this.currentJob.modelGrid.is2d) {
      height = -this.currentJob.modelGrid.nx3 * gridSpace;
    } else {
      height = this.currentJob.modelGrid.nx1 * gridSpace;
    }

    return {x: this.currentJob.modelGrid.nx2 * gridSpace, y: height}
  }

  onKeySubmission(event) {
    if (event.key == "Enter") {
      this.updatePlot()
    }
  }

  updatePlot() {
    document.getElementById("chartContainer").style.height=this.height + 'px';
    if (!this.chart || !this.chart.nativeElement || !this.currentJob || !this.chart.nativeElement.offsetParent)
      return;
    var minVal = 0;
    var maxVal = Math.max(...this.costs.map(c => c.sy));

    var scales = this.getScale();

    if (maxVal == 0) {
      minVal = -1;
      maxVal = 1;
    } else {
      maxVal = scales.y;
    }

    this.canvasChart = new CanvasJS.Chart("chartContainer", {
      animationEnabled: true,
      axisX: {
        minimum: 0,
        maximum: scales.x,
        title: "sx",
        labelFontSize:12,
        titleFontSize:12,
      },
      axisY: {
        minimum: minVal,
        maximum: maxVal,
        title: "sy",
        labelFontSize:12,
        titleFontSize:12,
      },
      toolTip: {
        content: "sx: {x}, sy: {y}, shot id:{id}",
      },
      data: [{
        indexLabelFontColor: "darkSlateGray",
        type: "scatter",
        // markerHoverSize: 1,
        dataPoints: this.costs.map(c => {
          return {
            id: c.shotNo,
            x: c.sx,
            y: c.sy,
            markerColor: "#4485ed",
            markerSize: this.radius,
          }
        }),
      }],
    });
    this.canvasChart.render();
  }

  filterShot(){
    if (this.shotId != null){
      this.filteredCosts = this.filteredCosts.filter((item)=> item.shotNo == this.shotId);
    } else {
      this.filteredCosts = Array.from(this.costs);
    }
  }

}
