<div class="container-fluid">
  <div *ngIf="isLoading" class="loading">
    <mat-progress-spinner mode="indeterminate" diameter="75" class="spinner"></mat-progress-spinner>
  </div>
  <div class="col-md-8" >
    <mat-tab-group class="inner_tab" [selectedIndex]="selectedParam" (selectedIndexChange)="onSelectedParamChange($event)" *ngIf="plotParams.length>0">
      <mat-tab *ngFor="let param of plotParams" [label]="param.name"></mat-tab>
    </mat-tab-group>
  </div>
  <div class="row">
    <div [ngClass]="{'chart_wide': wideGraph, 'col-md-11': wideGraph,
      'chart': !wideGraph, 'col-md-6': !wideGraph}">
      <template #chart></template>
      <h3 *ngIf="plotParams.length==0 && !isLoading" class="error_message">{{errorMessage}}</h3>
    </div>
    <div [ngClass]="{'info_card_wide': wideGraph, 'col-md-6': wideGraph,
      'info_card': !wideGraph, 'col-md-4': !wideGraph}" *ngIf="plotParams.length > 0">
      <mat-card>
        <mat-card-content>
          <mat-grid-list [cols]="wideGraph?6:2" rowHeight="12:1" class="parameters">
            <ng-container *ngFor="let param of displayingData">
              <mat-grid-tile><span style="font-weight: bold;"> {{param.name}}: </span></mat-grid-tile>
              <mat-grid-tile><span> {{param.data | number: '1.0-3'}}</span></mat-grid-tile>
            </ng-container>
          </mat-grid-list>
          <mat-divider *ngIf="displayingData"></mat-divider>
          <div class="sliders form-horizontal iter_setting">
            <div class="form-group iteration-slider">
              <app-slider class="col-sm-12" label="Iteration" [antd]="true" [min]="0" [max]="largestIter" [(value)]="selectedIteration" [step]="1" (valueChange)="loadChart(selectedParam)"></app-slider>
            </div>
          </div>
          <!-- <div class="row scale_input"> -->
          <div style="display: flex; gap: 10px">
            <!-- <mat-form-field class="col-md-5">
              <input matInput type="number" placeholder="Lower Bound" [(ngModel)]="lowLimitY" (keyup)="updateGraphScale($event)">
            </mat-form-field>
            <mat-form-field class="col-md-5">
              <input matInput type="number" placeholder="Upper Bound" [(ngModel)]="upLimitY" (keyup)="updateGraphScale($event)">
            </mat-form-field> -->
            <nz-input-group class="nz-input-group-floating-label" [style.flexGrow]="1">
              <label>Lower Bound</label>
              <input type="number" nz-input placeholder="Lower Bound" [(ngModel)]="lowLimitY" (keyup)="updateGraphScale($event)"/>
            </nz-input-group>
            <nz-input-group class="nz-input-group-floating-label" [style.flexGrow]="1">
              <label>Upper Bound</label>
              <input type="number" nz-input placeholder="Upper Bound" [(ngModel)]="upLimitY" (keyup)="updateGraphScale($event)"/>
            </nz-input-group>

            <i style="
              position: absolute;
              top: 5px;
              right: 10px;
              width: fit-content;
              margin: 0px;
            " [ngClass]="{'zmdi':true, 'zmdi-fullscreen':!wideGraph, 'zmdi-fullscreen-exit':wideGraph, 'zmdi-hc-2x':true, 'full_screen':true}"
            (click)="wideGraph=!wideGraph; loadChart()" ></i>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>