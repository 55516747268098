import { Injectable } from '@angular/core';
import { defaultValues } from '../default_value/default_value'

@Injectable()
export class DefaultMappingService {

  constructor() { }

  getDefaultValue(parameterName: string): any {
    return defaultValues[parameterName] || "default";
  }
}
