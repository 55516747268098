import { StoredSettingsService } from "./../../projects/services/stored-settings.service";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable ,  forkJoin } from "rxjs";
import { filter, map } from "rxjs/operators";
import { JobDetail } from "../../models/jobDetail";
import { Project } from "../../models/project";
import { ProjectService } from "../../shared/services/project.service";
import { DemoPageHistory } from "./demo-page-history.service";

@Injectable()
export class DemoPageResolver implements Resolve<[JobDetail, Project, any]> {
  constructor(
    private projectService: ProjectService,
    private demoPageHistory: DemoPageHistory,
    private storedSettingsService: StoredSettingsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const jobId = route.parent.paramMap.get("id");

    this.demoPageHistory.setHistory(jobId);
    if (
      this.demoPageHistory.previousId &&
      this.demoPageHistory.previousId !== this.demoPageHistory.currentId
    ) {
      console.log(
        "sync source:" +
          this.demoPageHistory.previousId +
          " target:" +
          this.demoPageHistory.currentId
      );

      this.storedSettingsService.synchroniseJob(
        "demo",
        this.demoPageHistory.previousId
      );
    }

    const jobDetail$ = this.projectService.getJobDetail(jobId);
    const project$ = this.projectService
      .getProjects()
      .pipe(
        map((projects) =>
          projects.find((p) => !!p.jobs.find((j) => j.id === jobId))
        )
      );
    const jobMeta$ = this.projectService.getJobMeta(jobId);

    return forkJoin([jobDetail$, project$, jobMeta$]).pipe(
      map((results) => {
        return [results[0], results[1], results[2]];
      })
    );
  }
}
