<h3 mat-dialog-title>Job Delete</h3>

<form class="form-container" (ngSubmit)="submitForm()">
  <mat-dialog-content>
    <span>
     Are you sure you want to delete the job '{{data.name}}'? <br/>
      Note this action cannot be reversed
    </span>
  </mat-dialog-content>
  <mat-dialog-actions class="button-container">
    <button mat-raised-button color="warn" type="submit">Delete</button>
    <button mat-raised-button mat-dialog-close color="basic" type="reset">Cancel</button>
  </mat-dialog-actions>
</form>
