<div>
    <div class="container-fluid">
        <ng-container *ngIf="selectedJobs">
            <mat-form-field appearance="fill" style="display: inline-block;">
                <mat-label>Select Jobs</mat-label>
                <mat-select [disabled]="is_jobs_loading" multiple [(ngModel)]="selectedJobs">
                    <div class="select-jobs-dropdown-search-input">
                        <i class="zmdi zmdi-search"></i>
                        <input (keyup)="onSearchJobs($event)" placeholder="Search..." />
                    </div>
                    <mat-option (click)="toggleAllSelection()" value="all">All</mat-option>
                    <ng-container *ngFor="let jname of getJobNames()">
                        <!-- (onSelectionChange)="change_selection($event)" -->
                        <mat-option [style.display]="this.showJobs.includes(jname) ? 'flex' : 'none'" [value]="jname">{{
                            jname }}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <button style="display: inline-block;" (click)="job_filter()">Apply</button>
        <div>
            <div style="display: inline-block;">
                <div *ngIf="is_getting_parameter_table" style="display: inline-block;">
                    <mat-spinner mode="indeterminate" color="primary" diameter="20"></mat-spinner>
                </div>
                <button mat-button (click)="_get_project_table(project_id, false)"
                    style="display: inline-block;">Refresh Table</button>
            </div>
            <div style="display: inline-block;">
                <div *ngIf="is_getting_parameter_table" style="display: inline-block;">
                    <mat-spinner mode="indeterminate" color="primary" diameter="20"></mat-spinner>
                </div>
                <button mat-button (click)="rebuild_parameter_table(project_id)" style="display: inline-block;">Rebuild
                    Table</button>
            </div>
            <!-- <div style="display: inline-block;">
                <div *ngIf="is_exporting_as_csv" style="display: inline-block;">
                    <mat-spinner mode="indeterminate" color="primary" diameter="20"></mat-spinner>
                </div>
                <button mat-button (click)="_export_table_as_csv(project_id)" style="display: inline-block;">Export as CSV</button>
            </div> -->
        </div>
        <div style="display: flex;">
            <div class="table">
                <table>
                    <thead>
                        <tr dragula="draggable_column" [(dragulaModel)]="displayedColumns">
                            <ng-container *ngFor="let f of displayedColumns">
                                <ng-container *ngIf="f!='job_id' && f!='id' && f!='project_id' && f !='parent_job_id'">
                                    <th>
                                        <div style="display: flex; align-items: center; justify-content: space-between;">
                                            <span class="sort-heading" (click)="sort_table(f)">{{columnDefinitions[f]['label']}}</span>
                                            
                                            <div class="header-filter-menu-container">
                                                <span>
                                                    <ng-container *ngIf="sort_by == f">
                                                        <span class="sort-arrow" *ngIf="sort_order == 'asc'"><i class="zmdi zmdi-long-arrow-down"></i></span>
                                                        <span class="sort-arrow" *ngIf="sort_order == 'desc'"><i class="zmdi zmdi-long-arrow-up"></i></span>
                                                    </ng-container>
                                                </span>
                                                    
                                                <button class="header-filter-menu-button" [matMenuTriggerFor]="menu">
                                                    <i [class.active-filter]="this.columnFilters.has(f)" class="inactive-filter zmdi zmdi-filter-list zmdi-hc-lg"></i>
                                                </button>
                                            </div>
                                        </div>
                                            
                                        <mat-menu #menu="matMenu" class="filter-menu" (closed)="applyTableFilter()">
                                            <button mat-menu-item (click)="sort_table(f, 'asc')">Sort A to Z <i class="zmdi zmdi-long-arrow-down"></i></button>
                                            <button mat-menu-item (click)="sort_table(f, 'desc')">Sort Z to A <i class="zmdi zmdi-long-arrow-up"></i></button>

                                            <mat-divider></mat-divider>
                                            <span style="padding: 16px;">Filter</span>
                                            <section class="filter-options">
                                                <span mat-menu-item *ngFor="let item of getFilterValuesForColumn(f)" (click)="handleFilterInputClick($event)">
                                                <mat-checkbox [checked]="(columnFilters.get(f) || []).includes(item)" (change)="addToTableFilter($event.checked, item, f)">{{ item }}</mat-checkbox>
                                                </span>
                                            </section>
                                            <div class="filter-button-container">
                                            <button class="filter-confirm-button" mat-raised-button (click)="clearFilters(f)">Clear</button>
                                            <button class="filter-confirm-button" mat-raised-button color="primary" (click)="applyTableFilter()">OK</button>
                                            </div>
                                        </mat-menu>
                                    </th>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let entry of filteredData" [style.border-left]="'5px solid'">
                            <ng-container *ngFor="let f of displayedColumns">
                                <ng-container *ngIf="f!='job_id' && f!='id' && f!='project_id' && f !='parent_job_id'">
                                    <td
                                        *ngIf="f!='job_name' && f!='parent_job_name' && f!='traces_locator';else linkedName">
                                        {{ entry[f] }}
                                    </td>
                                    <ng-template #linkedName>
                                        <td *ngIf="f=='job_name'" class="job-name" (click)="nav_to_job(entry['job_id'])"
                                            [title]="entry[f]">
                                            {{ entry[f].slice(0,10) }}
                                        </td>
                                        <td *ngIf="f=='parent_job_name'" class="job-name"
                                            (click)="nav_to_job(entry['parent_job_id'])">
                                            {{ entry[f] }}
                                        </td>
                                        <td *ngIf="f=='traces_locator'" class="job-name"
                                            (click)="nav_to_trace(entry['traces_locator'])">
                                            {{ entry[f] }}
                                        </td>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="filter_panel">
                <form [formGroup]="form" style="height: 80vh; display: flex; flex-direction: column;">
                    <div style="display: inline-block;">
                        <p
                            style="width: 320px; display: flex; justify-content: space-between; gap: 10px; align-items: center; flex-wrap: wrap; margin: 5px 0px">
                            <button mat-raised-button (click)="apply_filters()"
                                style="flex-grow: 1; display: inline-block;">Apply</button>
                            <button mat-raised-button (click)="toggle_extra_cols()"
                                style="flex-grow: 1; display: inline-block;">Toggle Unchanged Columns</button>
                            <button mat-raised-button (click)="deselect_all()"
                                style="flex-grow: 1; display: inline-block;">Deselect
                                All</button>
                            <button mat-raised-button (click)="show_matching_jobs()"
                                style="flex-grow: 1; display: inline-block;">Show matching jobs
                            </button>
                        </p>
                    </div>

                    <select name="_filtermode" formControlName='_filtermode' class="filtermodeSelection">
                        <option *ngFor="let opt of options; let last = last" [id]="'_mode_'+opt" [value]="opt">{{opt}}
                        </option>
                    </select>
                    <!-- <ng-container *ngFor="let opt of options">
                    <div class="custom-control custom-radio">
                        <input [id]="'_mode_'+opt" type="radio" class="custom-control-input" [value]="opt" name="_filtermode" formControlName="_filtermode">
                    <label class="custom-control-label" [for]="'_mode_'+opt">{{(opt)}}</label>
                    </div>
                </ng-container> -->
                    <!-- <div class="custom-control custom-radio">
                    <input id="_mode_awi" type="radio" checked class="custom-control-input" value="awi" name="_filtermode" formControlName="_filtermode">
                <label class="custom-control-label" for="_mode_awi">AWI</label>
                </div>
                <div class="custom-control custom-radio">
                    <input id="_mode_fwi" type="radio" class="custom-control-input" value="fwi" name="_filtermode" formControlName="_filtermode">
                <label class="custom-control-label" for="_mode_fwi">FWI</label>
                </div>
                <div class="custom-control custom-radio">
                    <input id="_mode_custom" type="radio" class="custom-control-input" value="custom" name="_filtermode" formControlName="_filtermode">
                <label class="custom-control-label" for="_mode_custom">Custom</label>
                </div> -->
                    <!-- <div dragula="draggable_checkbox" [(dragulaModel)]="displayedColumns">
                    <ng-container *ngFor="let f of displayedColumns">
                        <ng-container *ngIf="f!='job_id'">
                            <mat-checkbox  [formControlName]="columnDefinitions[f]['def']">{{columnDefinitions[f]['label']}}</mat-checkbox>
                        </ng-container>
                    </ng-container>
                </div> -->

                    <!-- <pre>
                    {{ displayedColumns | json }}
                </pre> -->
                    <div class="columnDefinitionListingSearchDiv">
                        <input class="columnDefinitionListingSearchInput" (keyup)="onSearchSub($event)" type="text"
                            placeholder="Type Param Name...">
                    </div>
                    <!-- Steps of the below HTML -->
                    <!-- the first is, checks if the user is searching or not. If they aren't, categorized list. If they are, the full list -->
                    <!-- Go over every key in the ParamsTree object. Basically all the category names, RWI, Smoothing, Density -->
                    <!-- All the children of the category, in a different div. This makes it easier to hide a show when clicked. the display: none/block properties are set in the onCollapsibleClickHandler function -->
                    <!-- The Misc category has 1 less level to it, hence checking. If its the Misc category, go the next container which directly lists the params with checkboxes -->
                    <!-- Go over the sub-categories of the category and make the collapsibles -->
                    <!-- All the children of the category, in a different div. This makes it easier to hide a show when clicked. the display: none/block properties are set in the onCollapsibleClickHandler function -->
                    <!-- going over evety item and showing it in a checkbox -->
                    <!-- Some names in the PramsTree don't map into the columnDefinitions object, hence ensuring it exists to not cause any -->
                    <div class="columnDefinitionListing">
                        <ng-container *ngIf="!(this.query);else searching">
                            <ng-container *ngFor="let category of this.getKeys(this.paramsTree)">
                                <div style="user-select: none; cursor: pointer; ">
                                    <p style="margin: 0;" class="collapsible-parameter-category"
                                        (click)="onCollapsibleClickHandler(this.paramsTree[category], category, $event)">
                                        {{category}}
                                    </p>
                                    <div
                                        style="display: none; border-left: 1px solid rgba(0, 0, 0, 0.12); margin: 0px 0px 0px 10px;">
                                        <ng-container *ngIf="category != 'Misc'; else MiscList">
                                            <ng-container
                                                *ngFor="let sub_category of this.getKeys(this.paramsTree[category]['children'])">
                                                <p class="collapsible-parameter-category"
                                                    style="margin: 0px; cursor: pointer;"
                                                    (click)="onCollapsibleClickHandler(this.paramsTree[category]['children'][sub_category], category, $event)">
                                                    {{sub_category}}
                                                </p>
                                                <div
                                                    style="display: none; border-left: 1px solid rgba(0, 0, 0, 0.12); margin-left: 5px;">
                                                    <ng-container
                                                        *ngFor="let item of this.paramsTree[category]['children'][sub_category]['children']">
                                                        <ng-container class="parameter-selectable"
                                                            style="margin: 0px 0px 0px 10px;"
                                                            *ngIf="columnDefinitions[getSnakeCase(item)]">
                                                            <mat-checkbox style="margin-left: 5px !important;"
                                                                [formControlName]="columnDefinitions[getSnakeCase(item)]['def']">{{columnDefinitions[getSnakeCase(item)]['label']}}
                                                            </mat-checkbox>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <ng-template #MiscList>
                                        <ng-container style="margin: 5px 0px 0px 10px;"
                                            *ngFor="let item of this.paramsTree['Misc']['children']">
                                            <ng-container *ngIf="columnDefinitions[getSnakeCase(item)]">
                                                <mat-checkbox style="margin-left: 5px !important;"
                                                    [formControlName]="columnDefinitions[getSnakeCase(item)]['def']">{{columnDefinitions[getSnakeCase(item)]['label']}}
                                                </mat-checkbox>
                                            </ng-container>
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </ng-container>

                        <ng-template #searching>
                            <ng-container
                                *ngIf="displayedColumnsFilteredKeys.length == displayedColumns.length; else filteredBox">
                                <ng-container *ngFor="let f of displayedColumns">
                                    <ng-container *ngIf="f!='job_id'">
                                        <mat-checkbox
                                            [formControlName]="columnDefinitions[f]['def']">{{columnDefinitions[f]['label']}}</mat-checkbox>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-template #filteredBox>
                                <ng-container *ngFor="let f of displayedColumnsFilteredKeys">
                                    <ng-container *ngIf="f!='job_id'">
                                        <mat-checkbox
                                            [formControlName]="columnDefinitions[f]['def']">{{columnDefinitions[f]['label']}}</mat-checkbox>
                                    </ng-container>
                                </ng-container>
                            </ng-template>
                            <ng-container *ngFor="let col_key of columnDefinitionsFilteredKeys">
                                <ng-container *ngIf="!isDisplayed(col_key) && col_key!='job_id'">
                                    <ng-container *ngIf="columnDefinitions[col_key]!=undefined  ">
                                        <mat-checkbox class="paramsListingCheckbox"
                                            [formControlName]="columnDefinitions[col_key]['def']">{{columnDefinitions[col_key]['label']}}</mat-checkbox>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-template>

                    </div>
                </form>
            </div>
        </div>

        <div class="footer-jobs">
            <a mat-raised-button [routerLink]="['/projects/', selectedJobIds[0]]" [queryParams]="{jobs: selectedJobIds}"
                color="primary" target="_blank">Open Selected Jobs</a>
        </div>
        <!-- <div class="footer-jobs">
            <a mat-raised-button [routerLink]="['/projects/', selectedJobIds[0]]" color="primary" target="_blank">Open Selected Jobs</a>
        </div> -->
    </div>
</div>
