<div>
  <app-proj-sidebar></app-proj-sidebar>
  <div class="container-fluid" style="padding-left: 85px;">
    <h6 class="project-title">
      {{project_name}}
    </h6>
    <small>
      {{project_description}}
    </small>
  </div>
  <router-outlet></router-outlet>
  
</div>