import { Component } from "@angular/core";
import { CachedProjectService } from "../../../../shared/services/cached-project.service";
import { ProjectService } from "../../../../shared/services/project.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { take, takeUntil } from "rxjs/operators";
import { Project } from "../../../../models/project";
import { Subject } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

interface NewHorizon {
  name: string;
  description: string;
  horizon_path: string;
}

interface Horizon extends NewHorizon {
  id: string;
}

@Component({
  selector: "app-horizons-listing",
  templateUrl: "./horizons-listing.component.html",
  styleUrls: ["./horizons-listing.component.less"],
})
export class HorizonsListingComponent {
  private destroy$ = new Subject<void>();
  project_id: string;
  horizons: Horizon[] = [];
  horizonsForm: FormGroup;
  mode: "editting" | "creating" = "creating";
  activeHorizon: Horizon;

  constructor(
    private cachedProjectService: CachedProjectService,
    private projectService: ProjectService,
    private notificationService: NzNotificationService,
    private fb: FormBuilder
  ) {
    this.horizonsForm = this.fb.group({
      name: this.fb.control<string>("", [Validators.required]),
      horizon_path: this.fb.control<string>("", [Validators.required]),
      description: this.fb.control<string>(""),
    });

    this.cachedProjectService.currentProject
      .pipe(takeUntil(this.destroy$))
      .subscribe((project: Project) => {
        if (project) {
          this.project_id = project.id;
          this.getHorizons();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getHorizons() {
    this.projectService
      .getProjectHorizons(this.project_id)
      .pipe(take(1))
      .subscribe({
        next: (res: Horizon[]) => {
          console.log("got horizons", res);
          this.horizons = res;
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error || "An unexpected error ocurred!"
          );
        },
      });
  }

  editHorizon(data: Horizon) {
    this.horizonsForm.setValue({
      name: data.name,
      description: data.description,
      horizon_path: data.horizon_path,
    });
    this.activeHorizon = data;
    this.mode = "editting";
  }

  deleteHorizon(data: Horizon) {
    this.projectService
      .deleteProjectHorizons(this.project_id, data.id)
      .pipe(take(1))
      .subscribe({
        next: (data: Horizon[]) => {
          this.notificationService.success(
            "Success",
            "Successfully deleted Horizon!"
          );
          this.horizons = data;
        },
        error: (err) => {
          console.log("error", err);
          this.notificationService.error(
            "Error",
            err.error?.error || "An unexpected error occured!"
          );
        },
      });
  }

  submitForm() {
    for (var i in this.horizonsForm.controls) {
      this.horizonsForm.controls[i].updateValueAndValidity();
    }

    if (this.horizonsForm.valid) {
      let data = this.horizonsForm.value;
      if (this.mode == "editting") {
        data["id"] = this.activeHorizon.id;
      }

      this.projectService
        .updateProjectHorizons(this.project_id, data)
        .pipe(take(1))
        .subscribe({
          next: (data: Horizon[]) => {
            this.notificationService.success(
              "Success",
              this.mode == "creating"
                ? "Successfully added Horizon!"
                : "Successfully editted Horizon!"
            );
            this.horizons = data;
            this.cachedProjectService.clearProjectCachedById(this.project_id);
          },
          error: (err) => {
            console.log("error", err);
            this.notificationService.error(
              "Error",
              err.error?.error || "An unexpected error occured!"
            );
          },
        });
      this.resetForm();
    }
  }

  resetForm() {
    this.horizonsForm.reset();
    this.mode = "creating";
  }
}
