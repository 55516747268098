import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  trigger,
  style,
  animate,
  transition,
  state,
} from "@angular/animations";
import { CachedProjectService } from "../../shared/services/cached-project.service";
import { Project } from "../../models/project";
import { Job } from "../../models/job";
import { SelectionListItem } from "../../shared/selectionList/selectionlistitem.model";
import { ActivatedRoute, Router } from "@angular/router";
import { range } from "lodash";
import { forkJoin, Observable, Subscription, of } from "rxjs";
import { DatePipe } from "@angular/common";
import { UntypedFormControl } from "@angular/forms";
import { startWith, map, switchMap } from "rxjs/operators";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.less"],
  animations: [
    trigger("slideIn", [
      state("*", style({ "overflow-y": "hidden" })),
      state("void", style({ "overflow-y": "hidden" })),
      transition("* => void", [
        style({ height: "*", opacity: 1 }),
        animate(250, style({ height: 0, opacity: 0 })),
      ]),
      transition("void => *", [
        style({ height: "0", opacity: 0 }),
        animate(250, style({ height: "*", opacity: 1 })),
      ]),
    ]),
  ],
})
export class SearchComponent implements OnInit, OnDestroy {
  array = Array;
  rules = new Map();
  afterSearch = false;
  inputRule = "";
  errorInput = false;
  resultGroups: any[] = [];
  matchedJobs: any[] = [];
  matchedValues = null;
  numResultPage = 0;
  currentPageNum = 1;
  pageNumOnDisplay = [];
  selectedJobs = [];
  isLoading = false;
  activeProject: string = null;
  isSuggestion = true;
  sortCriteria = "_score";
  keywords = [];
  keywordsCtrl = null;
  filteredKeywords: Observable<any[]>;
  errorMessage = "No Result Found";

  constructor(
    private projectService: CachedProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.keywordsCtrl = new UntypedFormControl();
  }

  ngOnInit() {
    let snapShotParams = this.route.snapshot.queryParams;
    if (snapShotParams.rules) {
      let rules = snapShotParams.rules.split(";");
      rules.forEach((r) => {
        let kv = r.split(":");
        this.rules.set(kv[0], kv[1].split(","));
      });
    }
    if (this.rules.size > 0) {
      this.search();
    } else {
      this.getSearchSuggestions();
    }
    this.projectService.getSearchKeywords().subscribe((keywords) => {
      this.keywords = keywords;
      this.filteredKeywords = this.keywordsCtrl.valueChanges.pipe(
        startWith(""),
        map((key: string) =>
          key ? this._filterKeywords(key) : this.keywords.slice()
        )
      );
    });
  }

  ngOnDestroy() {}

  getSearchSuggestions() {
    let numSearchGroups = 0;
    this.projectService
      .getRecentSearchGroups()
      .pipe(
        switchMap((groups: any[]) => {
          numSearchGroups = groups.length;
          groups.sort(
            (a, b) => +(a.created < b.created) - +(a.created > b.created)
          );
          let jobGroups = [];
          groups.forEach((g) => {
            let rules = JSON.parse(g.rule);
            this.resultGroups.push({
              id: g.id,
              name: g.name,
              description: g.comments,
              jobs: [],
              isProject: false,
              rules: rules,
            });
            jobGroups.push(g.jobs);
          });
          return forkJoin(
            ...jobGroups.map((g) => this.projectService.getGroupData(g))
          );
        })
      )
      .subscribe((res: any) => {
        if (!res || res.length != numSearchGroups) return;
        let searchGroups = Object.keys(this.resultGroups);
        res.forEach((r, i) => {
          this.resultGroups[searchGroups[i]].jobs = r.job_list.map((j) => {
            return {
              id: j.job_id,
              name: j.job_name,
              description: j.comments,
              createdDate: new Date(j.modified_time),
            };
          });
        });
        this.numResultPage = 1;
        this.pageNumOnDisplay = range(1, Math.min(10, this.numResultPage) + 1);
      });
  }

  deleteRule(rule) {
    this.rules.delete(rule);
  }

  addRule() {
    if (!this.inputRule) {
      return;
    }
    let keyvalue: string[] = this.inputRule.split(":");
    if (keyvalue.length > 2) {
      this.errorInput = true;
      return;
    } else if (keyvalue.length < 2) {
      let freeValues = this.rules.get("free_vals") || [];
      let values = keyvalue[0].split(",");
      this.rules.set("free_vals", freeValues.concat(values));
    } else {
      let values = keyvalue[1].split(",");
      values = values.map((v) => v.trim());
      this.rules.set(keyvalue[0].trim(), values);
    }
    this.errorInput = false;
    this.inputRule = "";
  }

  search() {
    this.addRule();
    if (this.rules.size <= 0) {
      return;
    }
    this._initialize();
    let rules = this._formatSearchRules();
    let resultJobs = [];
    let jobs = [];
    this.projectService
      .getMatchedJobs(rules, this.currentPageNum, this.sortCriteria)
      .pipe(
        switchMap((searchResults) => {
          if (!searchResults) return of(null);
          resultJobs = searchResults.results;
          this.numResultPage = searchResults.num_pages;
          return this.projectService.getGroupData(resultJobs);
        }),
        switchMap((res: any) => {
          if (!res || !res.job_list) return of(null);
          jobs = res.job_list;
          jobs.forEach((j) => {
            this.matchedJobs.push({
              name: j.job_name,
              id: j.job_id,
              description: j.comments,
              createdDate: new Date(j.modified_time),
              project: j.project_name,
              prefix: j.job_prefix,
              status: j.status,
              iteration: j.iterations,
              path: j.job_basepath,
              tag: JSON.parse(j.tag || "{}"),
              projectDescription: j.project_comment,
            });
          });
          return this.projectService.getSearchResults(rules);
        }),
        switchMap((groupedResults) => {
          if (!groupedResults) return of(null);

          groupedResults.forEach((g) => {
            let groupedJobs = g.grouped_jobs.map((j) => {
              resultJobs.push(j.job_id);
              return {
                name: j.job_name,
                id: j.job_id,
                description: j.comments,
                createdDate: new Date(j.modified_time),
              };
            });
            this.resultGroups.push({
              id: g.id,
              name: g.name,
              description: g.comment,
              jobs: groupedJobs,
              isProject: false,
            });
          });
          if (!this.pageNumOnDisplay || this.pageNumOnDisplay.length == 0)
            this.pageNumOnDisplay = range(
              1,
              Math.min(10, this.numResultPage) + 1
            );
          return this.projectService.getGroupParams(
            resultJobs,
            Array.from(this.rules.keys())
          );
        })
      )
      .subscribe(
        (jobParams) => {
          if (!jobParams) {
            this.isLoading = false;
            this.afterSearch = true;
            this.errorMessage = "No Result Found";
            return;
          }
          this.matchedValues = {};
          jobParams.forEach((j) => {
            let job_id = j.job_id;
            let paramKeys = Array.from(Object.keys(j));
            paramKeys.splice(
              paramKeys.findIndex((k) => k == "job_id"),
              1
            );
            let params = paramKeys.map((k) => {
              return { param: k, value: j[k] };
            });
            this.matchedValues[job_id] = params;
          });
          this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: { rules: this._rulesTOString() },
            replaceUrl: true,
          });
          this.isLoading = false;
          this.isSuggestion = false;
          this.afterSearch = true;
          this.errorMessage = "No Result Found";
        },
        (error) => {
          this.isLoading = false;
          this.afterSearch = true;
          if (error && error.detail) error = error.detail;
          this.errorMessage = error;
        }
      );
  }

  jumpPage(pageNumber) {
    this.currentPageNum = pageNumber;
    this.search();
    let startPage =
      Math.min(
        Math.max(0, pageNumber - 4),
        Math.max(this.numResultPage - 10, 0)
      ) + 1;
    this.pageNumOnDisplay = range(
      startPage,
      Math.min(startPage + 10, this.numResultPage + 1)
    );
    window.scrollTo(0, 0);
  }

  onGroupJobSelected(selection) {
    this.selectedJobs = this.selectedJobs.concat(selection.selection);
  }

  onJobSelected(event, job) {
    event.stopPropagation();
    this.selectedJobs.push(job);
  }

  onJobClicked(job) {
    this.activeProject === job.id
      ? (this.activeProject = null)
      : (this.activeProject = job.id);
  }

  openJob(event, job) {
    event.stopPropagation();
    let url = this.router.createUrlTree(["/projects/" + job.id]).toString();
    window.open(url, "_blank");
  }

  openSearchGroup(project) {
    let rules = [];
    if (project.rules) {
      rules = Array.from(Object.entries(project.rules));
    } else {
      rules = Array.from(this.rules.entries());
    }
    let jobs = project.jobs.map((j) => j.id);
    let url = this.router.createUrlTree(["/projects/", jobs[0]], {
      queryParams: {
        jobs: jobs,
        searchId: project.id,
        rules: rules.map((v, k) => `${k}:${v.join(",")}`).join(";"),
        comment: project.description,
        name: project.name,
      },
    });
    window.open(url.toString(), "_blank");
  }

  openSelectedJobs() {
    if (!this.rules) return;
    let ruleString = this._rulesTOString();
    let url = this.router.createUrlTree(["/projects/", this.selectedJobs[0]], {
      queryParams: {
        jobs: this.selectedJobs,
        searchId: "",
        rules: ruleString,
        comment: "",
        name: "New Search Group",
      },
    });
    window.open(url.toString(), "_blank");
  }

  clickProject(project: Project) {
    this.activeProject === project.id
      ? (this.activeProject = null)
      : (this.activeProject = project.id);
  }

  deletSearchGroup(event, project) {
    event.stopPropagation();
    this.projectService.deleteSearchGroup(project.id).subscribe((res) => {
      let idx = this.resultGroups.findIndex((p) => p.id === res);
      if (idx < 0) return;
      this.resultGroups.splice(idx, 1);
    });
  }

  isActiveProject(id: string): boolean {
    return this.activeProject === id;
  }

  getListFromJobs(jobs: Job[]): SelectionListItem[] {
    let list = jobs.map((j) => {
      let descriptions = [];
      if (j.description) descriptions.push(j.description);
      if (this.matchedValues) {
        descriptions.push(
          this.matchedValues[j.id]
            .map((p) => `${p.param} : ${p.value}`)
            .join(", ")
        );
      }
      let title =
        j.name + " -- " + this.datePipe.transform(j.createdDate, "dd/MM/yyyy");
      let item = new SelectionListItem(
        j.id,
        title,
        descriptions,
        "/projects/" + j.id,
        "_blank",
        this.onJobSelected
      );
      return item;
    });
    return list;
  }

  displayRunfileValue(job_id) {
    if (!this.matchedValues || !this.matchedValues[job_id]) return;
    return this.matchedValues[job_id]
      .map((p) => `${p.param} : ${p.value}`)
      .join(", ");
  }

  displayRules(rules) {
    let ruleString = [];
    for (let k in rules) {
      ruleString.push(`${k}: ${rules[k].join(",")}`);
    }
    return ruleString.join("; ");
  }

  displayRule(rule) {
    let values = this.rules.get(rule);
    if (rule == "free_vals") rule = "free keywords";
    return rule + " : " + values.join(", ");
  }

  importRules(event, rules) {
    event.stopPropagation();
    Object.keys(rules).forEach((k) => {
      this.rules.set(k, rules[k]);
    });
  }

  private _rulesTOString() {
    let rules = [];
    this.rules.forEach((v, k) => rules.push(`${k}:${v.join(",")}`));
    return rules.join(";");
  }

  private _filterKeywords(name: string) {
    return this.keywords.filter(
      (key) => key.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  private _formatSearchRules() {
    let rules = {};
    let partialRules = [
      "project",
      "job_name",
      "job_prefix",
      "comments",
      "free_vals",
      "project_comments",
      "job_basepath",
    ];
    this.rules.forEach((v, k) => {
      if (partialRules.includes(k)) {
        v = v.map((w) => `*${w}*`);
      }
      rules[k] = v;
    });
    return rules;
  }

  private _initialize() {
    this.afterSearch = false;
    this.pageNumOnDisplay = [];
    this.isLoading = true;
    this.selectedJobs = [];
    this.resultGroups = [];
    this.matchedJobs = [];
  }
}
