import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
export interface ShotSettings {
  startTrace: number;
  endTrace: number;
  startTraceManual: number;
  endTraceManual: number;
  panel: number;
}
@Injectable()
export class ShotSettingsService {
  shotSettings: ShotSettings;
  private _shotSettings: BehaviorSubject<ShotSettings> =
    new BehaviorSubject<ShotSettings>({
      startTrace: undefined,
      endTrace: undefined,
      startTraceManual: undefined,
      endTraceManual: undefined,
      panel: 0
    });
  public readonly shotSettings$: Observable<ShotSettings> =
    this._shotSettings.asObservable();

  public setShotSettings(shotSettings: ShotSettings) {
    this.shotSettings = shotSettings;
    this._shotSettings.next(this.shotSettings);
  }
}
