import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SearchGroupDialog } from '../../../../models/search-group-dialog.ts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-project-name',
  templateUrl: './search-group.component.html',
  styleUrls: ['./search-group.component.less']
})
export class SearchGroupComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) form;

  constructor(@Inject(MAT_DIALOG_DATA) public data: SearchGroupDialog, public dialogRef: MatDialogRef<SearchGroupComponent>) { }

  ngOnInit() {
  }

  submitForm() {
    if (!this.form.valid)
      return;

    this.dialogRef.close(this.data);
  }
}

