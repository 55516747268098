<div>
  <div class="container-fluid">
    <div style="display: flex; justify-content: space-between">
      <div>
        <div style="display: inline-block">
          <div *ngIf="is_getting_project_table" style="display: inline-block">
            <mat-spinner
              mode="indeterminate"
              color="primary"
              diameter="20"
            ></mat-spinner>
          </div>
          <button
            mat-button
            (click)="_get_project_table(project_id, false)"
            style="display: inline-block"
          >
            Refresh Table
          </button>
        </div>
        <div style="display: inline-block">
          <div *ngIf="is_getting_project_table" style="display: inline-block">
            <mat-spinner
              mode="indeterminate"
              color="primary"
              diameter="20"
            ></mat-spinner>
          </div>
          <button
            mat-button
            (click)="rebuild_project_table(project_id)"
            style="display: inline-block"
          >
            Rebuild Table
          </button>
        </div>
        <div style="display: inline-block">
          <div *ngIf="is_exporting_as_csv" style="display: inline-block">
            <mat-spinner
              mode="indeterminate"
              color="primary"
              diameter="20"
            ></mat-spinner>
          </div>
          <button
            mat-button
            (click)="_export_table_as_csv(project_id)"
            style="display: inline-block"
          >
            Export as CSV
          </button>
        </div>
      </div>
      <div style="display: flex; gap: 20px; align-items: center">
        <b>Project Potential Savings:</b
        >{{ this.project_total_savings_possible.toFixed(2) }}$ per Month
        <nz-divider nzType="vertical" />
        <b>Project Storage Cost:</b
        >{{ this.project_total_storage_cost.toFixed(2) }}$ per Month
        <span class="custom-tooltip">
          <div><i class="zmdi zmdi-info-outline"></i></div>
          <span>
            There are various storage types. The following is a desciption of
            all of them:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;<b>keep:</b> All the files of this job have been
            retained in standard storage. Keep in mind that this is the most
            costly form of storage.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;<b>glacier instant retrieval:</b> "These jobs have
            had most of their files deleted, but the important ones have been
            retained. You can still see all the relevant information in this
            project while saving on storage costs. Our suggestion is to keep all
            inactive jobs in Glacial instant retrieval.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;<b>full storage:</b> The jobs marked 'full
            storage' will be sent to Glacier Instant Retrieval. You will still
            be able to access all relevant information in these jobs and models,
            while saving alot on storage costs.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;<b>&lt;7 days (full storage):</b> These jobs have
            been created in the past 7 days. If you decide to not 'keep' these
            jobs, they will automatically be set to full storage, and sent to
            Glacier Instant Retrieval after 7 days.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;<b>glacier deep archive:</b> These jobs have been
            put in deep archive. Please consult the admin to unarchive these
            jobs.
          </span>
        </span>
      </div>
    </div>
    <div class="table">
      <table>
        <thead>
          <tr>
            <th (click)="sort_table('job_name')" style="width: 20%">
              Job Name
              <span *ngIf="sort_by == 'job_name'">
                <span *ngIf="sort_order == 'asc'"
                  ><a><i class="zmdi zmdi-caret-up"></i></a
                ></span>
                <span *ngIf="sort_order == 'desc'"
                  ><a><i class="zmdi zmdi-caret-down"></i></a
                ></span>
              </span>
            </th>
            <th (click)="sort_table('job_type')" style="width: 15%">
              Job Type
              <span *ngIf="sort_by == 'job_type'">
                <span *ngIf="sort_order == 'asc'"
                  ><a><i class="zmdi zmdi-caret-up"></i></a
                ></span>
                <span *ngIf="sort_order == 'desc'"
                  ><a><i class="zmdi zmdi-caret-down"></i></a
                ></span>
              </span>
            </th>
            <th (click)="sort_table('storage_cost')" style="width: 20%">
              Storage Cost ($ per Month)
              <span *ngIf="sort_by == 'storage_cost'">
                <span *ngIf="sort_order == 'asc'"
                  ><a><i class="zmdi zmdi-caret-up"></i></a
                ></span>
                <span *ngIf="sort_order == 'desc'"
                  ><a><i class="zmdi zmdi-caret-down"></i></a
                ></span>
              </span>
            </th>
            <th (click)="sort_table('possible_savings')" style="width: 25%">
              <div>
                Potential Savings ($ per Month)
                <span *ngIf="sort_by == 'possible_savings'">
                  <span *ngIf="sort_order == 'asc'"
                    ><a><i class="zmdi zmdi-caret-up"></i></a
                  ></span>
                  <span *ngIf="sort_order == 'desc'"
                    ><a><i class="zmdi zmdi-caret-down"></i></a
                  ></span>
                </span>
              </div>
            </th>
            <th (click)="sort_table('job_status')" style="width: 20%">
              Job Status
              <span *ngIf="sort_by == 'job_status'">
                <span *ngIf="sort_order == 'asc'"
                  ><a><i class="zmdi zmdi-caret-up"></i></a
                ></span>
                <span *ngIf="sort_order == 'desc'"
                  ><a><i class="zmdi zmdi-caret-down"></i></a
                ></span>
              </span>
            </th>
            <th (click)="sort_table('iters')" style="width: 15%">
              Iters
              <span *ngIf="sort_by == 'iters'">
                <span *ngIf="sort_order == 'asc'"
                  ><a><i class="zmdi zmdi-caret-up"></i></a
                ></span>
                <span *ngIf="sort_order == 'desc'"
                  ><a><i class="zmdi zmdi-caret-down"></i></a
                ></span>
              </span>
            </th>
            <!-- <th (click)="sort_table('keep')" style="width: 20%"> -->
            <th style="width: 10%">
              <!-- Keep -->
              <!-- <span *ngIf="sort_by == 'keep'">
                <span *ngIf="sort_order == 'asc'"
                  ><a><i class="zmdi zmdi-caret-up"></i></a
                ></span>
                <span *ngIf="sort_order == 'desc'"
                  ><a><i class="zmdi zmdi-caret-down"></i></a
                ></span> -->
              <!-- </span> -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let entry of table_data"
            [style.border-left]="'5px solid'"
            [style.border-left-color]="get_color_for_type(entry.job_type)"
          >
            <td
              class="job-name"
              (click)="nav_to_job(entry.job_id)"
              style="width: 20%"
            >
              {{ entry.job_name }}
            </td>
            <td style="width: 20%">{{ entry.job_type }}</td>
            <td style="width: 5%">{{ four_dp(entry.storage_cost) }}</td>
            <td style="width: 5%">{{ four_dp(entry.possible_savings) }}</td>
            <td style="width: 20%">
              <div [matTooltip]="getTooltipText(entry.job_status)">
                {{ entry.job_status }}
              </div>
            </td>
            <td style="width: 15%">{{ entry.iters }}</td>
            <td style="width: 10%">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  vertical-align: middle;
                  align-items: center;
                "
              >
                <!-- <div>
                  {{ entry.keep ? "Yes" : "No" }}
                </div> -->
                <button
                  mat-raised-button
                  (click)="keep_job(entry.job_id)"
                  style="display: inline-block"
                >
                  Update
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
