import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DragulaModule } from "ng2-dragula";
import { MaterialModule } from "../shared/material.module";
import { ParameterFilterBoxComponent } from "../shared/parameter-filter-box/parameter-filter-box.component";
import { SharedModule } from "../shared/shared.module";
import { ZorroModule } from "../shared/zorro.module";
import { SliceChartContainerComponent } from "./model/model-chart-container/model-chart-container.component";
import { SliceChartMenuComponent } from "./model/model-chart-menu/model-chart-menu.component";
import { SliceSettingsChartComponent } from "./model/slice-settings-chart/slice-settings-chart.component";
import { SliceSettingsComponent } from "./model/slice-settings/slice-settings.component";
import { CustomTableComponent } from "./project-view/data-table/custom-table/custom-table.component";
import { DataTableComponent } from "./project-view/data-table/data-table.component";
import { FlowchartComponent } from "./project-view/flowchart/flowchart.component";
import { ImagePanelComponent } from "./project-view/image-panel/image-panel.component";
import { ModelTableComponent } from "./project-view/model-table/model-table.component";
import { ParameterOverview2Component } from "./project-view/parameter-overview-2/parameter-overview-2.component";
import { ParameterOverviewComponent } from "./project-view/parameter-overview/parameter-overview.component";
import { ProjectTableComponent } from "./project-view/project-table/project-table.component";
import { ChartSliceComponent } from "./project/chart-slice/chart-slice.component";
import { ProjectSettingsBarComponent } from "./project/project-settings-bar/project-settings-bar.component";
import { D3ImageComponent } from "./project/shot-image/d3-image/d3-image.component";
import { ShotsGraphComponent } from "./project/shot-image/shots-graph/shots-graph.component";
import { TablesComponent } from "./project/tables/tables.component";
import { ShotBlockSettingsComponent } from "./shot/shot-block-settings/shot-block-settings.component";
import { ShotChartContainerComponent } from "./shot/shot-chart-container/shot-chart-container.component";
import { ShotChartLegendComponent } from "./shot/shot-chart-legend/shot-chart-legend.component";
import { ShotChartMenuComponent } from "./shot/shot-chart-menu/shot-chart-menu.component";
import { ShotChartComponent } from "./shot/shot-chart/shot-chart.component";
import { ShotContainerMenuComponent } from "./shot/shot-container-menu/shot-container-menu.component";
import { ShotContainerComponent } from "./shot/shot-container/shot-container.component";
import { ShotSelectChartMenuComponent } from "./shot/shot-select-chart-menu/shot-select-chart-menu.component";
import { ShotSelectContainerComponent } from "./shot/shot-select-container/shot-select-container.component";
import { ShotSelectFormComponent } from "./shot/shot-select-form/shot-select-form.component";
import { ShotTabsComponent } from "./shot/shot-tabs/shot-tabs.component";
@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    ZorroModule,
    DragulaModule,
    RouterModule,
  ],
  declarations: [
    SliceSettingsComponent,
    SliceSettingsChartComponent,
    SliceChartContainerComponent,
    ChartSliceComponent,
    ShotsGraphComponent,
    ShotSelectContainerComponent,
    ShotChartContainerComponent,
    ShotChartComponent,
    ShotContainerComponent,
    D3ImageComponent,
    SliceChartMenuComponent,
    ShotTabsComponent,
    ShotSelectChartMenuComponent,
    ShotBlockSettingsComponent,
    ProjectSettingsBarComponent,
    ShotChartMenuComponent,
    ShotContainerMenuComponent,
    ShotChartLegendComponent,
    ShotSelectFormComponent,
    TablesComponent,
    ProjectTableComponent,
    ParameterOverviewComponent,
    FlowchartComponent,
    DataTableComponent,
    ImagePanelComponent,
    CustomTableComponent,
    ModelTableComponent,
    ParameterFilterBoxComponent,
    ParameterOverview2Component,
  ],
  exports: [
    SliceSettingsComponent,
    SliceChartContainerComponent,
    ChartSliceComponent,
    ShotsGraphComponent,
    ShotSelectContainerComponent,
    ShotChartContainerComponent,
    ShotChartComponent,
    ShotContainerComponent,
    D3ImageComponent,
    ShotBlockSettingsComponent,
    ProjectSettingsBarComponent,
    ShotChartMenuComponent,
    ShotContainerMenuComponent,
    ShotChartLegendComponent,
    ShotSelectFormComponent,
    TablesComponent,
    ProjectTableComponent,
  ],
})
export class ProjectModule {}
