import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  currentYear = new Date();
  // appVersion: string;
  constructor() { }

  ngOnInit() {
    // this.appVersion = require("../../../../package.json").version;
  }

}
