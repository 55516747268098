<div class="_container">
  <ng-container *ngIf="this.isStaff; else page403">
    <h4>Storage Cost Analysis</h4>

    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div>
        <b>Total Storage Cost:</b>
        {{ this.totalProjectsCost.toFixed(4) }} $ per month

        <nz-divider nzType="vertical" />

        <b>Total Potential Savings:</b>
        {{ this.totalProjectsSavings.toFixed(4) }} $ per month
      </div>
      <div style="display: flex; gap: 10px">
        <button
          nz-button
          nzType="primary"
          [disabled]="setOfCheckedId.size === 0"
          [nzLoading]="isLoading"
          nz-popconfirm
          nzPopconfirmTitle="Are you sure you want to enable archiving for all selected projects?"
          (nzOnConfirm)="enableArchivingAllSelected()"
        >
          <!-- (click)="enableArchivingAllSelected()" -->
          Enable Archiving for Selected
        </button>
        <button
          nz-button
          nzType="default"
          [disabled]="setOfCheckedId.size === 0"
          [nzLoading]="isLoading"
          (click)="disableArchivingAllSelected()"
        >
          Disable Archiving for Selected
        </button>
      </div>
    </div>
    <div>
      <nz-table
        #filterTable
        [nzData]="this.costsData"
        nzTableLayout="fixed"
        [nzLoading]="isLoading"
        nzSize="small"
        nzShowPagination
        nzShowSizeChanger
        (nzCurrentPageDataChange)="onCurrentPageDataChange()"
      >
        <thead>
          <tr>
            <th nzWidth="35px"></th>
            <th
              nzWidth="35px"
              [nzChecked]="checked"
              [nzIndeterminate]="indeterminate"
              nzLabel="Select all"
              (nzCheckedChange)="onAllChecked($event)"
            ></th>
            <ng-container *ngFor="let column of this.listOfColumns">
              <!-- the first th is for the nzExpand -->
              <th
                [nzSortOrder]="column.sortOrder"
                [nzSortFn]="column.sortFn"
                [nzSortDirections]="column.sortDirections"
                [nzFilterMultiple]="column.filterMultiple"
                [nzWidth]="column.width"
              >
                {{ column.name }}
              </th>
            </ng-container>
            <th nzWidth="150px">Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of filterTable.data">
            <tr>
              <td [(nzExpand)]="data.expand"></td>
              <td
                [nzChecked]="setOfCheckedId.has(data.id)"
                [nzLabel]="data.name"
                (nzCheckedChange)="onItemChecked(data.id, $event)"
              ></td>
              <td>
                <a
                  [href]="'project/' + data.id + '/parameter-view'"
                  target="_blank"
                >
                  {{ data.name }}
                </a>
              </td>
              <td>
                <nz-badge
                  *ngIf="data.archive_enabled"
                  nzStatus="success"
                  nzText="True"
                >
                </nz-badge>
                <nz-badge
                  *ngIf="!data.archive_enabled"
                  nzStatus="processing"
                  nzText="False"
                >
                </nz-badge>
              </td>
              <td>
                <div style="display: flex; justify-content: space-between">
                  <span> {{ data.project_cost }} </span>
                  <span> $ per month </span>
                </div>
              </td>
              <td>
                <a
                  *ngIf="!data.archive_enabled"
                  nz-popconfirm
                  nzPopconfirmTitle="Are you sure you want to enable archiving for this project?"
                  (nzOnConfirm)="enableArchiving(data)"
                >
                  Enable archiving
                </a>
                <a
                  *ngIf="data.archive_enabled"
                  nz-popconfirm
                  nzPopconfirmTitle="Are you sure you want to disable archiving for this project?"
                  (nzOnConfirm)="disableArchiving(data)"
                >
                  Disable archiving
                </a>
              </td>
            </tr>
            <tr [nzExpand]="data.expand">
              <app-inner-table
                [project]="data"
                (refreshData)="this.getData()"
              />
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </ng-container>
  <ng-template #page403>
    <nz-result
      nzStatus="403"
      nzTitle="403"
      nzSubTitle="Sorry, you are not authorized to access this page."
    >
    </nz-result>
  </ng-template>
</div>
