import { Component } from "@angular/core";

import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";
import { Project } from "../../../models/project";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { ProjectService } from "../../../shared/services/project.service";
import { NzMessageService } from "ng-zorro-antd/message";

interface StorageItemData {
  job_name: string;
  job_id: string;
  functional: string;
  comments?: string;
  storage_cost: number;
  potential_savings: number;
  job_status: string;
  iters: number;
  iterations: number;
  keep?: boolean;
  // the disabled is for the nzTable func
  disabled?: boolean;
}

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<StorageItemData> | null;
  sortDirections: NzTableSortOrder[];
  listOfFilter?: NzTableFilterList;
  filterFn?: NzTableFilterFn<StorageItemData> | null;
  filterMultiple?: boolean;
  width?: string | number;
  left?: boolean;
}

interface ProjectCostInfo {
  cost?: number;
  potential_savings?: number;
}

@Component({
  selector: "app-storage-table",
  templateUrl: "./storage-table.component.html",
  styleUrls: ["./storage-table.component.less"],
})
export class StorageTableComponent {
  project_id: string;
  project_name: string;
  projectCostInfo: ProjectCostInfo;
  isLoading: boolean = false;
  is_exporting_as_csv: boolean = false;
  storageData: StorageItemData[] = [];
  listOfColumns: ColumnItem[] = [
    {
      name: "Job Name",
      sortOrder: null,
      sortFn: (a: StorageItemData, b: StorageItemData) =>
        a.job_name.localeCompare(b.job_name),
      sortDirections: ["ascend", "descend", null],
      width: "180px",
      left: true,
    },
    {
      name: "Functional",
      sortOrder: null,
      sortFn: (a: StorageItemData, b: StorageItemData) =>
        a.functional.localeCompare(b.functional),
      sortDirections: ["descend", "ascend", null],
      listOfFilter: [
        { text: "AWI", value: "A" },
        { text: "RWI", value: "R" },
        { text: "FWI", value: "F" },
      ],
      filterFn: (filterOpt: string, item: StorageItemData) => {
        if (!item.functional) return false;
        let control = item.functional
          .replace("]", "")
          .replace("[", "")
          .replace(/ /g, "")
          .split(",");
        return control.some((func) => filterOpt.includes(func));
      },
      filterMultiple: true,
    },
    {
      name: "Comments",
      sortOrder: null,
      sortDirections: ["ascend", "descend", null],
      sortFn: (a: StorageItemData, b: StorageItemData) =>
        a.comments.localeCompare(b.comments),
    },
    {
      name: "Storage Cost",
      sortOrder: "descend",
      sortDirections: ["ascend", "descend", null],
      sortFn: (a: StorageItemData, b: StorageItemData) =>
        a.storage_cost - b.storage_cost,
      width: "170px",
    },
    {
      name: "Potential Savings",
      sortOrder: null,
      sortDirections: ["ascend", "descend", null],
      sortFn: (a: StorageItemData, b: StorageItemData) =>
        a.potential_savings - b.potential_savings,
      width: "170px",
    },
    {
      name: "Status",
      sortOrder: null,
      sortDirections: ["ascend", "descend", null],
      sortFn: (a: StorageItemData, b: StorageItemData) =>
        a.job_status.localeCompare(b.job_status),
      width: "100px",
      listOfFilter: [
        { text: "keep", value: "keep" },
        { text: "full storage", value: "full storage" },
        { text: "< 7 days (full storage)", value: "< 7 days (full storage)" },
        {
          text: "glacier instant retrieval",
          value: "glacier instant retrieval",
        },
        { text: "deep archive", value: "deep archive" },
      ],
      filterFn: (filterOpt: string[], item: StorageItemData) => {
        return filterOpt.some((s) => item.job_status == s);
      },
      filterMultiple: true,
    },
    {
      name: "Iterations",
      sortOrder: null,
      sortDirections: ["ascend", "descend", null],
      sortFn: (a: StorageItemData, b: StorageItemData) =>
        a.iterations - b.iterations,
      width: "120px",
    },
    {
      name: "Iters / Block",
      sortOrder: null,
      sortDirections: ["ascend", "descend", null],
      sortFn: (a: StorageItemData, b: StorageItemData) => a.iters - b.iters,
      width: "130px",
    },
  ];

  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<any>();
  storageClassesModalVisible: boolean = false;
  unkeepConfirmationModalVisible: boolean = false;
  keepConfirmationModalVisible: boolean = false;
  editCommentModal: boolean = false;

  comments: string = "";
  activeJob: StorageItemData;

  constructor(
    private projectService: ProjectService,
    private cachedProjectService: CachedProjectService,
    private message: NzMessageService
  ) {
    this.isLoading = true;
    this.cachedProjectService.currentProject.subscribe(
      async (project: Project) => {
        if (project != null) {
          this.project_id = project.id;
          this.project_name = project.name;
          this.getProjectTable();
        }
      }
    );
  }

  getProjectTable() {
    this.isLoading = true;
    this.projectService.getProjectTable(this.project_id).subscribe(
      (res) => {
        this.storageData = res.results.map((job: StorageItemData) => ({
          ...job,
          functional: job.functional
            .replace(/RWI/g, "R")
            .replace(/FWI/g, "F")
            .replace(/AWI/g, "A"),
        }));
        this.projectCostInfo = res.project_info;
        this.isLoading = false;
      },
      (err) => {
        console.log("error", err);
        this.isLoading = false;
      }
    );
  }

  keepSelected() {
    const arryOfCheckedIds = Array.from(this.setOfCheckedId);
    this.projectService
      .setProjectArchiving({
        jobIds: arryOfCheckedIds,
        keep: true,
      })
      .subscribe(
        (res) => {
          this.message.success("Keeping selected jobs successfully!");
          this.isLoading = false;
          this.getProjectTable();
          this.setOfCheckedId = new Set<any>();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }
  unkeepSelected() {
    const arryOfCheckedIds = Array.from(this.setOfCheckedId);
    this.projectService
      .setProjectArchiving({
        jobIds: arryOfCheckedIds,
        keep: false,
      })
      .subscribe(
        (res) => {
          this.message.success("Unkeeping selected jobs successfully!");
          this.isLoading = false;
          this.getProjectTable();
          this.setOfCheckedId = new Set<any>();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }

  keep_job(job: StorageItemData) {
    this.projectService
      .setProjectArchiving({
        jobIds: [job.job_id],
        keep: true,
      })
      .subscribe(
        (res) => {
          this.message.success(`Keeping Job ${job.job_name} successfully!`);
          this.isLoading = false;
          this.getProjectTable();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }
  unkeep_job(job: StorageItemData) {
    this.projectService
      .setProjectArchiving({
        jobIds: [job.job_id],
        keep: false,
      })
      .subscribe(
        (res) => {
          this.message.success(`Unkeeping Job ${job.job_name} successfully!`);
          this.isLoading = false;
          this.getProjectTable();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }

  _export_table_as_csv() {
    this.is_exporting_as_csv = true;
    this.cachedProjectService
      ._getProjectTableCSV(this.project_id)
      .toPromise()
      .then((data) => {
        this.is_exporting_as_csv = false;
        let results = data["results"];
        let fields = [
          "job_id",
          "job_name",
          "job_type",
          "storage_cost",
          "iters",
          "job_status",
          "keep",
          "bucket",
          "key",
          "sequence",
        ];
        let csvstr = fields.join(",") + "\n";
        for (let row of results) {
          let entry = fields.map((x) => row[x]);
          csvstr += entry.join(",") + "\n";
        }
        let blob = new Blob([csvstr], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "project_table.csv";
        a.click();
      });
  }

  handleConfirmUnkeep() {
    this.unkeep_job(this.activeJob);
    this.unkeepConfirmationModalVisible = false;
  }
  handleConfirmKeep() {
    this.keep_job(this.activeJob);
    this.keepConfirmationModalVisible = false;
  }

  getUnkeepConfirmationContent() {
    const job: StorageItemData = this.activeJob;
    const cpsToKeep = [];

    if (job) {
      var rwiText = "";
      if (job.iterations > 0 && job.iters > 0 && job.iterations > job.iters) {
        let count = Math.floor(job.iterations / job.iters);
        for (let i = 1; i <= count; i++) {
          cpsToKeep.push(`CP${job.iters * i}`);
        }
        if (!cpsToKeep.includes(`CP${job.iterations}`)) {
          cpsToKeep.push(`CP${job.iterations}`);
        }
      }

      var isRwi = job.functional
        .replace("]", "")
        .replace("[", "")
        .replace(/ /g, "")
        .split(",")
        .some((func) => func == "R");
      if (isRwi) {
        rwiText += `<li>For each RWI original block, first and last background CP and first and last Virtual Source CP are kept(all model files)</li>`;
        rwiText += `<li>For each RWI reloaded block, last two CP's are kept(all model files)</li>`;
      }

      return (
        `
          <p>Are you sure you want to unkeep job ${job.job_name}?</p>
          <p>The job will still be accessible, but will be sent to Glacier Instant Retrival (01:00 AM tomorrow) to save storage costs.
          This would also mean that all files while be deleted except for the following:
            <ul>
              <li>Velocity(Vp/Vs) and Density from <b>CP0</b> and <b>CP1</b> will be retained.</li>
              ` +
        (cpsToKeep.length > 0
          ? `<li>Velocity(Vp/Vs) and Density from <b>${cpsToKeep.join(
              ", "
            )}</b> will be retained</li>`
          : "") +
        `
              <li>
                For each CP retained, we retain only Lowpass-B4 and Compare files for half of the csref files.
              </li>
              ${isRwi ? rwiText : ""}
            </ul> 
          </p>
          <p>The job will still be available for viewing, but would simply have only the necessary files and models.</p>
        `
      );
    } else {
      return ``;
    }
  }

  saveComment() {
    const details = {
      id: this.activeJob.job_id,
      name: this.activeJob.job_name,
      comments: this.comments,
    };
    this.isLoading = true;

    this.editCommentModal = false;
    this.comments = "";
    this.activeJob = null;

    this.projectService.updateJobDetails(details).subscribe(
      (data) => {
        this.cachedProjectService.clearProjectsCache();
        this.cachedProjectService.clearJobDetailCache([this.project_id]);
        this.message.success("Job Comment updated");
        this.getProjectTable();
      },
      (error) => {
        this.message.error("Sorry, the job comment could not be updated");
        this.isLoading = false;
      }
    );
  }

  // functions for ng-zorro table selection and clearing filters
  resetFilters(): void {
    this.listOfColumns.forEach((item) => {
      if (item.name === "Functional") {
        item.listOfFilter = [
          { text: "AWI", value: "A" },
          { text: "RWI", value: "R" },
          { text: "FWI", value: "F" },
        ];
      } else if (item.name === "Status") {
        item.listOfFilter = [
          { text: "keep", value: "keep" },
          { text: "full storage", value: "full storage" },
          { text: "< 7 days (full storage)", value: "< 7 days (full storage)" },
          {
            text: "glacier instant retrieval",
            value: "glacier instant retrieval",
          },
          { text: "deep archive", value: "deep archive" },
        ];
      }
    });
  }
  updateCheckedSet(id: any, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  onCurrentPageDataChange(): void {
    this.refreshCheckedStatus();
  }
  refreshCheckedStatus(): void {
    const listOfEnabledData = this.storageData.filter(
      ({ disabled }) => !disabled
    );
    this.checked = listOfEnabledData.every(({ job_id }) =>
      this.setOfCheckedId.has(job_id)
    );
    this.indeterminate =
      listOfEnabledData.some(({ job_id }) => this.setOfCheckedId.has(job_id)) &&
      !this.checked;
  }
  onItemChecked(id: any, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  onAllChecked(checked: boolean): void {
    this.storageData
      .filter(({ disabled }) => !disabled)
      .forEach(({ job_id }) => this.updateCheckedSet(job_id, checked));
    this.refreshCheckedStatus();
  }
}
