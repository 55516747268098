<div>
  <button mat-stroked-button color="primary" [matMenuTriggerFor]="shotMenu">
    <span>Shot: {{ selectedShot }}</span>
    <i class="zmdi zmdi-caret-down" style="display: inline-block"></i>
  </button>
  <mat-menu #shotMenu="matMenu">
    <button
      mat-menu-item
      *ngFor="let shotId of availableShotIds"
      (click)="onShotSelected(shotId)"
    >
      {{ shotId }}
    </button>
  </mat-menu>
  <button mat-stroked-button color="primary" [matMenuTriggerFor]="iterMenu">
    <span>Iteration: {{ iteration }}</span>
    <i class="zmdi zmdi-caret-down" style="display: inline-block"></i>
  </button>
  <mat-menu #iterMenu="matMenu">
    <button
      mat-menu-item
      *ngFor="let iter of iterationsOfSameShot"
      (click)="onIterationSwitch(iter)"
    >
      {{ iter }}
    </button>
  </mat-menu>
  <button *ngIf="switchIteration" mat-stroked-button color="primary" (click)="onIterationBack()">
    <span>Back to iteration {{switchIteration}}</span>
  </button>
</div>
