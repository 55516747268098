import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { ImageLoaderService } from "../../../shared/services/image-loader.service";
import { forkJoin, Observable } from "rxjs";
import { SliceType } from "../../../models/sliceType";
import { Project } from "../../../models/project";
import { Job } from "../../../models/job";
// import { Slice } from "../../../models/slice";
import { map, take } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "../../../shared/services/http.service";
import { AuthService } from "../../../shared/services/auth.service";
import { AppConfig } from "../../../app.config";
import { CachedPaletteService } from "../../services/cached-palette.service";
import { HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-image-panel",
  templateUrl: "./image-panel.component.html",
  styleUrls: ["./image-panel.component.less"],
})
export class ImagePanelComponent implements OnInit {
  project: Project;
  jobList: Job[];
  currentModelTypeId = null;
  inlineNumber: number;
  crosslineNumber: number;
  depthSlice: number;
  iterationsResponse: string;
  iterations: number[];
  pngWidth: number = 1600;
  pngHeight: number;
  isLoading: boolean = false;
  images: Array<Observable<any>>;
  velocityAuto = true;
  minVal: number;
  maxVal: number;
  minValZ: number;
  maxValZ: number;
  pUpdateCheck = false;
  // vpUpdateCheck = false;
  // vpDiffIteration: number | null = null;
  timeSlice: number = null;
  selectedJobIds: string[] = [];
  selectAll: boolean = false;
  dropdownOpen: boolean = false;
  is2d: boolean = false;
  palettes: string[] = [];
  palette: string;
  isInline: boolean;
  // downloadRawImages: boolean = true;
  jobControl = new UntypedFormControl();
  toppings = new UntypedFormControl();

  private _imageBase64: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: CachedProjectService,
    private imageService: ImageLoaderService,
    private http: HttpService,
    private authService: AuthService,
    private paletteService: CachedPaletteService
  ) {
    this.projectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project = project;
        this.jobList = project.jobs;
        this.initializeJobDetails();
      }
    });
    // Get colour map choice
    this.paletteService.getPalettes().subscribe((data) => {
      this.palettes = data;
      this.palette = data[0];
    });
  }

  ngOnInit() {
    this.pngHeight = 625;
  }

  getSliceType(sliceTypeString: any): SliceType | null {
    switch (sliceTypeString.toLowerCase()) {
      case "inline":
        return SliceType.inline;
      case "crossline":
        return SliceType.crossLine;
      case "depth":
        return SliceType.depth;
      case "1":
        return SliceType.inline;
      case "2":
        return SliceType.crossLine;
      case "3":
        return SliceType.depth;
      default:
        return null;
    }
  }

  getCurrentVeloctiyRange(type: SliceType): number[] {
    switch (type) {
      case SliceType.inline:
        return this.velocityAuto ? [null, null] : [this.minVal, this.maxVal];
      case SliceType.crossLine:
        return this.velocityAuto ? [null, null] : [this.minVal, this.maxVal];
      case SliceType.depth:
        return this.velocityAuto ? [null, null] : [this.minValZ, this.maxValZ];
    }
  }

  /**
   *
   * @description parse user inputs to iterations
   */
  parseIterations(iterationsResponse: string): number[] {
    if (typeof iterationsResponse !== "string") {
      return [];
    }

    return iterationsResponse
      .split(",")
      .map((str) => str.trim())
      .filter((str) => str !== "")
      .map(Number)
      .filter((num) => !isNaN(num));
  }

  /**
   *
   * @description load chart for each image
   */
  generateImages() {
    this.iterations = this.parseIterations(this.iterationsResponse);
    this.isLoading = true;
    const loadChart = (
      jobId: string,
      iteration: number,
      sliceTypeString: string,
      jobName: string
    ) => {
      let sliceType = this.getSliceType(sliceTypeString);
      let velocityRange = this.getCurrentVeloctiyRange(sliceType);
      var hasVelocity =
        velocityRange &&
        velocityRange.length == 2 &&
        velocityRange[0] &&
        velocityRange[1];
      this.isLoading = true;
      this.projectService
        .getSlice(
          jobId,
          this.currentModelTypeId,
          iteration,
          sliceType,
          this.getNumberForSliceType(sliceType),
          this.is2d,
          this.palette,
          hasVelocity ? velocityRange[0] : null,
          hasVelocity ? velocityRange[1] : null,
          null,
          1,
          // this.vpUpdateCheck ? this.vpDiffIteration : 0,
          0,
          this.timeSlice
        )
        .pipe(take(1))
        .subscribe(
          (p) => {
            const images = [
              this.imageService.getImageBase64(p.url),
              this.imageService.getImageBase64(p.colorBarUrl),
            ];
            forkJoin(images)
              .pipe(take(1))
              .subscribe(
                (i) => {
                  this._imageBase64 = i[0];
                  const fileName = `Job-${jobName}-Iteration-${iteration}-Type-${sliceTypeString}.png`;
                  // if (this.downloadRawImages) {
                  //   this.downloadRawImage(fileName);
                  // } else {
                  //   this.downloadImage(fileName);
                  // }
                  this.downloadRawImage(fileName);
                  this.isLoading = false;
                },
                () => this.handleError("Error loading image data")
              );
          },
          () => this.handleError("Error loading slice data")
        );
    };

    for (const jobId of this.selectedJobIds) {
      const job = this.jobList.find((j) => j.id === jobId);
      if (job) {
        for (const iteration of this.iterations) {
          for (const sliceType in SliceType) {
            if (isNaN(Number(sliceType)))
              loadChart(job.id, iteration, sliceType, job.name);
          }
        }
      }
    }

    this.isLoading = false;
  }

  initializeJobDetails() {
    if (this.jobList && this.jobList.length > 0) {
      this.fetchJobDetails(this.jobList[0].id).subscribe((arg) => {
        this.is2d = arg.is2d;
        this.isInline = arg.isInline;
        if (this.isInline) {
          this.inlineNumber = Math.floor(arg.crosslineNumber / 2);
          this.crosslineNumber = Math.floor(arg.inlineNumber / 2);
        } else {
          this.inlineNumber = Math.floor(arg.inlineNumber / 2);
          this.crosslineNumber = Math.floor(arg.crosslineNumber / 2);
        }
        this.depthSlice = Math.floor(arg.depthSlice / 2);
      });
    }
  }

  fetchJobDetails(jobId: string) {
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/jobs/${jobId}/`,
        this.getAuthorizationHeader()
      )
      .pipe(
        map((res) => {
          if (!res) {
            return null;
          }
          res = res;
          return {
            is2d: res.model_grid.is_2d,
            inlineNumber: res.model_grid.nx1,
            crosslineNumber: res.model_grid.nx2,
            depthSlice: res.model_grid.nx3,
            isInline: res.x2_is_inline,
          };
        })
      );
  }

  private getAuthorizationHeader(): { headers: HttpHeaders } {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );
    return { headers };
  }

  getNumberForSliceType(type: SliceType): number {
    switch (type) {
      case SliceType.inline:
        return this.inlineNumber;
      case SliceType.crossLine:
        return this.crosslineNumber;
      case SliceType.depth:
        return this.depthSlice;
    }
    return 0;
  }

  private handleError(message: string) {
    this.isLoading = false;
    console.error(message);
  }

  // private downloadImage(fileName: string) {
  //   const sliceType = fileName.split('-')[5].split('.')[0];
  //   try {
  //     const base64Data = this._imageBase64.split(',')[1];
  //     const decoratedBase64 = this.decorateImage(base64Data, sliceType);
  //     const svgData = atob(decoratedBase64);
  //     const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
  //     const url = URL.createObjectURL(svgBlob);

  //     const img = new Image();
  //     img.onload = () => {
  //       const canvas = document.createElement('canvas');
  //       canvas.width = img.width;
  //       canvas.height = img.height;
  //       const ctx = canvas.getContext('2d');
  //       ctx.drawImage(img, 0, 0);

  //       canvas.toBlob((blob) => {
  //         const url = URL.createObjectURL(blob);
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.download = fileName;
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //         URL.revokeObjectURL(url);
  //       }, 'image/png');
  //     };
  //     img.src = url;
  //   } catch (error) {
  //     console.error("Error in downloadImage: ", error);
  //   }
  // }

  private downloadRawImage(fileName: string) {
    const contentType = "image/png";
    const base64Image = this._imageBase64;
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });

    const img = new Image();
    img.src = URL.createObjectURL(blob);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = this.pngWidth;
      canvas.height = this.pngHeight;

      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(img, 0, 0, this.pngWidth, this.pngHeight);
        canvas.toBlob((resizedBlob) => {
          const resizedUrl = URL.createObjectURL(resizedBlob);
          const link = document.createElement("a");
          link.href = resizedUrl;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(resizedUrl);
        }, contentType);
      }

      URL.revokeObjectURL(img.src);
    };
  }

  isSelected(jobId: string): boolean {
    return this.selectedJobIds.includes(jobId);
  }

  toggleSelectAll(): void {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.selectedJobIds = this.jobList.map((job) => job.id);
    } else {
      this.selectedJobIds = [];
    }
  }

  toggleJobSelection(jobId: string): void {
    if (this.isSelected(jobId)) {
      this.selectedJobIds = this.selectedJobIds.filter((id) => id !== jobId);
    } else {
      this.selectedJobIds.push(jobId);
    }
  }

  onColorSelect(event) {
    let index = this.palettes.findIndex((j) => j == event.option.value);
    if (index == -1) {
      return;
    }
    this.palette = this.palettes[index];
  }

  // decorateImage(base64Image: string, sliceTypeString: string): string {
  //     const svg = d3.create("svg")
  //       .attr("width", this.pngWidth)
  //       .attr("height", this.pngHeight);

  //     const imageUrl = `data:image/png;base64,${base64Image}`;
  //     svg.append("image")
  //       .attr("x", 0)
  //       .attr("y", 0)
  //       .attr("width", this.pngWidth)
  //       .attr("height", this.pngHeight)
  //       .attr("href", imageUrl);

  //     if (sliceTypeString === 'inline') {
  //       svg.append("rect")
  //         .attr("x", this.pngWidth / 4)
  //         .attr("y", this.pngHeight / 4)
  //         .attr("width", this.pngWidth / 2)
  //         .attr("height", this.pngHeight / 2)
  //         .style("fill", "blue");
  //     } else if (sliceTypeString === 'crossline') {
  //       svg.append("rect")
  //         .attr("x", this.pngWidth / 4)
  //         .attr("y", this.pngHeight / 4)
  //         .attr("width", this.pngWidth / 2)
  //         .attr("height", this.pngHeight / 2)
  //         .style("fill", "green");
  //     } else if (sliceTypeString === 'depth') {
  //       svg.append("rect")
  //         .attr("x", this.pngWidth / 4)
  //         .attr("y", this.pngHeight / 4)
  //         .attr("width", this.pngWidth / 2)
  //         .attr("height", this.pngHeight / 2)
  //         .style("fill", "red");
  //     }

  //     svg.append("text")
  //       .attr("x", this.pngWidth / 2)
  //       .attr("y", this.pngHeight - 10)
  //       .style("text-anchor", "middle")
  //       .text("X Axis Label");

  //     svg.append("text")
  //       .attr("transform", "rotate(-90)")
  //       .attr("x", -this.pngHeight / 2)
  //       .attr("y", 20)
  //       .style("text-anchor", "middle")
  //       .text("Y Axis Label");

  //     const svgString = new XMLSerializer().serializeToString(svg.node());

  //     return btoa(svgString);
  //   }

  // toggleVpUpdateCheck(): void {
  //   this.vpUpdateCheck = !this.vpUpdateCheck;
  // }
}
