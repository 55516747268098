import { Component, OnInit, Input, Output, EventEmitter, OnDestroy  } from '@angular/core';
import { SelectionListItem } from './selectionlistitem.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-selection-list',
  templateUrl: './selectionList.component.html',
  styleUrls: ['./selectionList.component.less']
})
export class SelectionListComponent implements OnInit, OnDestroy {
  @Input('list') list: Array<SelectionListItem>;
  @Input('id') id: string;
  @Input('selectedItems') set selectedItems (value: string[]) {this._selectedItems = value || []};
  @Output('selected') selected = new EventEmitter<{"id": string, "selection": string[]}>();
  constructor(private router: Router) { }
  _selectedItems: string[];

  ngOnInit() {
  }

  ngOnDestroy() {
    this._selectedItems = [];
  }

  navigateSelection(item: SelectionListItem) {
      let url = this.router.createUrlTree([item.location]).toString();
      window.open(url, item.target);
  }

  onClickItem(event, item) {
    event.stopPropagation();
    let idx = this._selectedItems.indexOf(item);
    if (idx >= 0) {
      this._selectedItems.splice(idx, 1);
    }else {
      this._selectedItems.push(item);
    }
    this.selected.emit({"id": this.id, "selection": this._selectedItems});
  }
}
