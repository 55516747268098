<div class="container-fluid">
  <nz-tabset>
    <nz-tab nzTitle="Project Information">
      <app-project-information></app-project-information>
    </nz-tab>
    <nz-tab nzTitle="Jobs">
      <app-jobs-form></app-jobs-form>
    </nz-tab>
    <nz-tab nzTitle="Well Logs">
      <app-well-log-listing></app-well-log-listing>
    </nz-tab>
    <nz-tab nzTitle="Horizons">
      <app-horizons-listing></app-horizons-listing>
    </nz-tab>
  </nz-tabset>
</div>
