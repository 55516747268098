import { ProjectService } from "./../../../shared/services/project.service";
import { Observable, Subject, Subscription, of } from "rxjs";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { JobDetail } from "../../../models/jobDetail";
import { MinMaxDialog } from "../../../models/minMaxDialog";
import { ModelSettings } from "../../../models/modelSettings";
import { ModelType } from "../../../models/modelType";
import { ModelTypeSettings } from "../../../models/modelTypeSettings";
import { ModelViewSettings } from "../../../models/modelViewSetting";
import { Slice } from "../../../models/slice";
import { SliceType } from "../../../models/sliceType";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { VelocityComponent } from "../../project/dialogs/velocity/velocity.component";
import { SliceSettingsService } from "../../services/slice-settings.service";
import { StoredSettingsService } from "../../services/stored-settings.service";
import { ShotOverlayType } from "../../../models/shotOverlayType";
import { switchMap, take } from "rxjs/operators";
export interface SliceChartMenuSettings {
  inline: number;
  crossline: number;
  // depth: number;
  type: any;
  minVal: number;
  maxVal: number;
  minValZ: number;
  maxValZ: number;
  // jobs: any;
  // rules: any;
  // searchId: any;
  // comment: string;
  // name: string;
  timeSlice: number;
  // shot: number;
  height: number;
}

@Component({
  selector: "app-model-chart-menu",
  templateUrl: "./model-chart-menu.component.html",
  styleUrls: ["./model-chart-menu.component.less"],
})
export class SliceChartMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobDetail: JobDetail;
  @Input() settingsKey: string;
  @Input() slice: Slice;
  @Input() palettes: string[] = [];
  @Input() shotOverlayTypes: ShotOverlayType[] = [];
  @Input() iteration: number;
  @Input() modelTypeId;
  @Input() modelTypes = this.sliceSettingsService.modelTypes;
  @Input() shotModels: string[];
  @Input() availShots: number[] = [];
  @Input() inline: number;
  @Input() crossline: number;
  @Input() depth: number;
  @Input() maxDepth: number;
  @Output() menuChanged = new EventEmitter<SliceChartMenuSettings>();

  currentModelTypeId = null;
  currentShotOverlayType: ShotOverlayType = null;
  shotId: number = null;
  depthSlice: number = null;

  height: number = 600;
  depthHeight: number = 250;
  minVal: number;
  maxVal: number;
  minValZ: number;
  maxValZ: number;
  // selectedSonicId: any = null;

  velocityAuto = true;
  updateChart: boolean = false;
  showSonicLog: boolean = false;
  showHorizon: boolean = false;

  timeSliceModels = ["ForwardWavefield", "BackwardWavefield"];

  showShotOverlay: boolean = false;
  horizonTypes: any = [];

  availTimeSlices: number[] = [];
  timeSlice: number = null;
  selectedSonicId: any = null;
  paletteOfCurrentType: string;
  // inline: number;
  // crossline: number;
  showHorizonTypes = [];
  rangeSubscription: Subscription;
  defaultRangesSubscription: Subscription;
  availableTimeSliceSubsription: Subscription;
  private _timeSliceSubscription: Subscription = null;
  modelShot: any;
  constructor(
    public dialog: MatDialog,
    private sliceSettingsService: SliceSettingsService,
    private storedSettingsService: StoredSettingsService,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    this.currentModelTypeId = this.modelTypeId;
    this.currentShotOverlayType = this.shotOverlayTypes[0];
  }
  ngOnChanges(changes: SimpleChanges) {
    const change = changes.jobDetail;
    if (change && change.currentValue) {
      this.restoreRangeAndPaletteForCurrentType();
      this.restoreSettings();
    }
  }
  showMinMaxDialog(type: SliceType) {
    this.restoreRangeAndPaletteForCurrentType();
    let dialogRef = this.dialog.open(VelocityComponent, {
      // height: "350px",
      width: "400px",
      data: {
        min: type == SliceType.depth ? this.minValZ : this.minVal,
        max: type == SliceType.depth ? this.maxValZ : this.maxVal,
        auto: this.velocityAuto,
      },
    });

    dialogRef.afterClosed().subscribe((result: MinMaxDialog) => {
      if (!result) return;
      this.velocityAuto = result.auto;

      if ((!result.min && !result.max) || result.auto) {
        this.getDefaultRanges(this.currentModelTypeId, type)
          .pipe(take(1))
          .subscribe((result) => {
            this.updateChart = true;
            this.onMenuChanged();
          });
      } else {
        if (type == SliceType.depth) {
          this.minValZ = result.min;
          this.maxValZ = result.max;
          this.storeRangeAndPaletteForCurrentType();
        } else {
          this.minVal = result.min;
          this.maxVal = result.max;
          this.storeRangeAndPaletteForCurrentType();
        }
        this.onMenuChanged();
      }
    });
  }
  onMenuChanged() {
    let sliceSettings: SliceChartMenuSettings = {
      inline: this.inline,
      crossline: this.crossline,
      // depth: this.depthSlice,
      type: this.currentModelTypeId,
      minVal: this.minVal,
      maxVal: this.maxVal,
      minValZ: this.minValZ,
      maxValZ: this.maxValZ,
      // jobs: this.selectedJobs,
      // rules: this._rules,
      // searchId: this._searchId,
      // comment: this._comment,
      // name: this._name,
      timeSlice: this.timeSlice,
      // shot: this.slice.shot,
      height: this.height,
    };
    // TODO: add back
    //this.getAvailableTimeSlices(this.currentModelTypeId);
    // this.router.navigate(['.'], {
    //   relativeTo: this.route,
    //   queryParams: newParams, replaceUrl: true
    // });
    this.storeSettings();

    this.menuChanged.emit(sliceSettings);
  }

  onSonicLogClicked() {
    // this.updateChart = true;
    // setTimeout(() => {
    //   this.updateChart = false;
    // }, 200);
    //TODO: update chart
  }
  setShotOverlayType(shotOverlayType: ShotOverlayType) {
    this.currentShotOverlayType = shotOverlayType;
    if (this.currentShotOverlayType.id === "fnlc") {
      this.currentShotOverlayType.max = 0;
      this.currentShotOverlayType.min = 100;
    }
    this.showShotOverlay = true;
  }
  setModelType(modelType: ModelType, event) {
    event.stopPropagation();
    if (this.currentModelTypeId == modelType.id) return;
    this.currentModelTypeId = modelType.id;
    this.availableTimeSliceSubsription = this.getAvailableTimeSlices(
      this.currentModelTypeId
    ).subscribe((result) => {
      this.storeRangeAndPaletteForCurrentType();
      this.restoreRangeAndPaletteForCurrentType();
      this.onMenuChanged();
    });
  }
  setPalette(palette: string) {
    this.paletteOfCurrentType = palette;
    this.storeRangeAndPaletteForCurrentType();
  }
  setLog(id) {
    var log = this.jobDetail.wellLogs.find((d) => d.id == id);
    if (!log) return;

    this.selectedSonicId = id;
    this.inline = log.inline;
    this.crossline = log.crossline;
    this.showSonicLog = true;
    this.onSonicLogClicked();
    this.onMenuChanged();
  }
  setHeight() {
    this.onMenuChanged();
  }
  setHorizon(id) {
    //TODO: populate horizonTypes
    var type = this.showHorizonTypes.find((d) => d == id);
    if (type) {
      if (this.horizonTypes.length > 1) {
        this.showHorizonTypes = this.showHorizonTypes.filter((d) => d !== id);
      } else {
        this.showHorizon = !this.showHorizon;
      }
    } else {
      this.showHorizon = true;
      this.showHorizonTypes.push(id);
    }

    this.onSonicLogClicked();
  }
  private getDefaultRanges(modelTypeId, sliceType?: SliceType) {
    var subject = new Subject();
    var obs = subject.asObservable();
    if (
      this.iteration == null ||
      (!this.inline && !this.crossline && !this.jobDetail.modelGrid.is2d)
    ) {
      return of(null);
    }

    if (this.timeSliceModels.includes(modelTypeId) && !this.timeSlice) {
      return of(null);
    }
    let sliceParams = {};

    if (sliceType && sliceType == SliceType.depth) {
      sliceParams["depth"] = this.depth;
    } else {
      sliceParams["inline"] = this.inline;
      sliceParams["crossline"] = this.crossline;
    }

    this.rangeSubscription = this.projectService
      .getRanges(
        this.jobDetail.id,
        modelTypeId,
        this.iteration,
        sliceParams,
        this.shotId,
        this.timeSlice
      )
      .subscribe((ranges) => {
        if (sliceType && sliceType == SliceType.depth) {
          this.minValZ = ranges.min_val;
          this.maxValZ = ranges.max_val;
        } else {
          this.minVal = ranges.min_val;
          this.maxVal = ranges.max_val;
        }
        this.storeRangeAndPaletteForCurrentType();
        subject.next(ranges);
        return ranges;
      });

    return obs;
  }
  private getAvailableTimeSlices(modelType) {
    var subject = new Subject();
    var obs = subject.asObservable();
    if (!this.shotModels.includes(modelType)) {
      return of(null);
    }
    this._timeSliceSubscription = this.projectService
      .getShotsAvailableForModel(this.jobDetail.id, this.iteration, modelType)
      .pipe(
        switchMap((shots) => {
          if (!shots || shots.length <= 0) {
            subject.next(null);
            // return Observable.of(null);
            return;
          }
          shots = shots.sort((a, b) => a - b);
          this.availShots = shots;
          if (!this.shotId) this.shotId = shots[0];
          if (!this.timeSliceModels.includes(modelType)) {
            // return Observable.of(null);
            return of(null);
          }
          return this.projectService.getTimeSliceAvailableForModel(
            this.jobDetail.id,
            this.iteration,
            this.shotId,
            modelType
          );
        })
      )
      .subscribe((timeSlices) => {
        if (!timeSlices) {
          subject.next(null);
          return;
        }
        this.availTimeSlices = timeSlices;
        if (
          this.availTimeSlices &&
          this.availTimeSlices.length > 0 &&
          !this.timeSlice
        )
          this.timeSlice = this.availTimeSlices[0];
        subject.next(null);
        return;
      });

    return obs;
  }
  isHorizonSelected(id) {
    return this.showHorizonTypes.find((d) => d == id);
  }
  setTimeSlice(timeSlice) {
    this.timeSlice = timeSlice;
    this.onMenuChanged();
  }
  showPalletteMenu() {
    return this.slice.sliceType === SliceType.inline;
  }
  setShot(shot) {
    this.shotId = shot;
    this.onMenuChanged();
  }
  showHorizonMenu() {
    return this.slice.sliceType === SliceType.inline;
  }
  showLogMenu() {
    return this.slice.sliceType === SliceType.inline;
  }
  showHeightMenu() {
    return this.slice.sliceType === SliceType.inline;
  }
  showDepthHeightMenu() {
    return this.slice.sliceType === SliceType.depth;
  }

  private restoreRangeAndPaletteForCurrentType() {
    if (!this.currentModelTypeId || !this.jobDetail.id) return;
    let settings = this.storedSettingsService.getSettings<ModelTypeSettings>(
      this.settingsKey,
      this.currentModelTypeId + this.jobDetail.id,
      SettingTypes.ModelType,
      this.jobDetail.projectId
    );
    if (settings) {
      this.paletteOfCurrentType = settings.palette;
      this.minVal = settings.ranges.minValue;
      this.maxVal = settings.ranges.maxValue;
      this.minValZ = settings.ranges.minValueZ;
      this.maxValZ = settings.ranges.maxValueZ;
      this.velocityAuto = settings.velocityAuto;
      this.modelShot = settings.modelShot || {};
    } else {
      this.minVal = null;
      this.maxVal = null;
      this.minValZ = null;
      this.maxValZ = null;
    }
  }
  private storeRangeAndPaletteForCurrentType() {
    if (!this.currentModelTypeId || !this.jobDetail.id) return;
    let ranges = {
      minValue: this.minVal,
      maxValue: this.maxVal,
      minValueZ: this.minValZ,
      maxValueZ: this.maxValZ,
    };
    let modelShot = this.modelShot || {};
    modelShot[this.iteration] = this.shotId;
    let typeSettings: ModelTypeSettings = {
      id: this.currentModelTypeId + this.jobDetail.id,
      modelId: this.currentModelTypeId,
      projectId: this.jobDetail.projectId,
      iteration: null,
      palette: this.paletteOfCurrentType,
      ranges: ranges,
      velocityAuto: this.velocityAuto,
      modelShot: modelShot,
    };

    this.storedSettingsService.setSettings<ModelTypeSettings>(
      this.settingsKey,
      typeSettings,
      SettingTypes.ModelType
    );

    this.storedSettingsService.settingsUpdated([SettingTypes.ModelType]);
  }
  private restoreSettings() {
    let modelViewSettings =
      this.storedSettingsService.getSettings<ModelViewSettings>(
        this.settingsKey,
        this.jobDetail.id,
        SettingTypes.ModelView,
        this.jobDetail.projectId
      );
    if (modelViewSettings) {
      if (modelViewSettings.height) {
        this.height = modelViewSettings.height;
      }
      if (modelViewSettings.depthHeight) {
        this.depthHeight = modelViewSettings.depthHeight;
      }
    }
  }
  private storeSettings() {
    let existingModelSettings =
      this.storedSettingsService.getSettings<ModelSettings>(
        this.settingsKey,
        this.jobDetail.id,
        SettingTypes.Model,
        this.jobDetail.projectId
      );

    let currentSettings: ModelSettings = {
      modelTypeId: this.currentModelTypeId,
      horizonTypes: this.showHorizonTypes,
      id: this.jobDetail.id,
      projectId: this.jobDetail.projectId,
      iteration: this.iteration,
      showHorizon: this.showHorizon,
    };
    this.storedSettingsService.setSettings<ModelSettings>(
      this.settingsKey,
      currentSettings,
      SettingTypes.Model
    );

    this.storedSettingsService.setSettings<ModelViewSettings>(
      this.settingsKey,
      {
        id: this.jobDetail.id,
        projectId: this.jobDetail.projectId,
        height: this.height,
        depthHeight: this.depthHeight,
        iteration: 0,
      },
      SettingTypes.ModelView
    );

    this.storedSettingsService.settingsUpdated([
      SettingTypes.Model,
      SettingTypes.ModelView,
    ]);
  }
  ngOnDestroy() {
    if (this.rangeSubscription) {
      this.rangeSubscription.unsubscribe();
    }
    if (this.defaultRangesSubscription) {
      this.defaultRangesSubscription.unsubscribe();
    }
    if (this._timeSliceSubscription) {
      this._timeSliceSubscription.unsubscribe();
    }
    if (this.availableTimeSliceSubsription) {
      this.availableTimeSliceSubsription.unsubscribe();
    }
  }
}
