import {
  Component,
  Inject,
  OnInit
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { comparison_exclusion_list } from "../../../project-view/parameter-overview-2/fields";

interface DialogData {
  options: Array<any>;
  parameterViewMatchingOptions: Object;
  jobs: Array<object>;
}

@Component({
  selector: "app-show-matching-jobs-options",
  templateUrl: "./show-matching-jobs-options.component.html",
  styleUrls: ["./show-matching-jobs-options.component.css"],
})
export class ShowMatchingJobsOptionsComponent implements OnInit {
  options: Array<any> = [];
  flattenedOptions: Array<any> = [];
  flattenedOptionsShowing: Array<any> = [];

  jobSearchString: string = "";

  differenceParameter: number = 0;
  selectedJob: Object = {};
  jobs: Array<Object> = [];
  exclusionList: Array<string> = comparison_exclusion_list;
  exclusionListControl: UntypedFormControl;

  parameterViewMatchingOptions: Object = {};

  constructor(
    public dialogRef: MatDialogRef<ShowMatchingJobsOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    // this.options = this.data.options;

    const flatOptions = [];
    Object.keys(this.data.options).forEach((cat: string) =>
      flatOptions.push(...this.data.options[cat])
    );
    this.flattenedOptionsShowing = flatOptions;
    this.flattenedOptions = flatOptions;

    this.options = this.data.options;

    this.parameterViewMatchingOptions = this.data.parameterViewMatchingOptions;
    this.jobs = this.data.jobs;

    this.exclusionListControl = new UntypedFormControl(this.exclusionList);
  }

  ngOnInit() {}

  showDiff() {
    if (this.selectedJob) {
      this.dialogRef.close({
        diffArg: this.differenceParameter,
        selectedJob: this.selectedJob,
        exclusionList: this.exclusionList,
      });
    }
  }

  filterJobs(_event: KeyboardEvent) {
    const el = _event.target as HTMLInputElement;
    if (el.value && el.value != "")
      this.jobs = this.data.jobs.filter((job) =>
        job["job_name"].toLowerCase().includes(el.value.toLowerCase())
      );
    else this.jobs = this.data.jobs;
  }

  filterCols(_event: KeyboardEvent) {
    const el = _event.target as HTMLInputElement;
    if (el.value && el.value != "")
      this.flattenedOptionsShowing = this.flattenedOptions.filter(
        (option: string) =>
          option.toLowerCase().includes(el.value.toLowerCase())
      );
    else this.flattenedOptionsShowing = this.flattenedOptions;
  }

  excludeAll() {
    this.exclusionListControl.setValue(this.flattenedOptions);
  }

  // ----------------------------------------
  // Helper functions
  // ----------------------------------------
  getObjectKeys(obj: object) {
    return Object.keys(obj);
  }
  getObjectValues(obj: object) {
    return Object.values(obj);
  }
}
