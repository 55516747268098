import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { JobDetail } from "../../../models/jobDetail";
import { CachedProjectService } from '../../../shared/services/cached-project.service';
declare var $: any;

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.less']
})
export class LogComponent implements OnInit {
    currentJob: JobDetail;
    isLoading: boolean = true;

  constructor(private projectService: CachedProjectService, private route: ActivatedRoute) { }


  ngOnInit() {
      this.projectService.currentJob
        .subscribe(d => {
          this.currentJob = d;
          this.isLoading = false;
        });
    }

    startDownload() {
      this.isLoading = true;
     
      this.projectService.getLog(this.currentJob.id).subscribe(l => {
        this.isLoading = false;
        this.saveFile(this.currentJob.prefix + '.txt', 'data:attachment/text', l);
      });
    }

  saveFile(name, type, data) {
    if (data != null && (window.navigator as any).msSaveBlob)
      return (window.navigator as any).msSaveBlob(new Blob([data], { type: type }), name);
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.attr("href", url);
    a.attr("download", name);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
