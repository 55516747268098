<div class="container" id="data-table-comp">
  <!-- <div
    style="
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
    "
  >
    <button mat-button (click)="rebuildDataTable()">Rebuild Table</button>

    <span class="custom-tooltip">
      <div><i class="zmdi zmdi-info-outline"></i></div>
      <span
        >The records that are highlighted in red represent those with a missing
        key and log file.
        <br />
        This may be because the folder is not found in the traces locator or the
        AWS path provided, or that the paths exist but the key and/or log files
        do not exist there.
      </span>
    </span>
  </div> -->

  <div>
    <app-custom-table
      [data]="this.data"
      [loading]="this.loading"
      [editRow]="true"
      (editRowHandler)="this.editRow($event)"
      highlightColor="#ff454533"
      highlightControlKey="found"
      [removalColumns]="['id']"
      [dateKeys]="['generated_on']"
      [headerMappingFunction]="headerMappingHelper"
      rowIdKey="directory_name"
      [columns]="this.options"
      [defaultShowingColumns]="this.defaultShowingColumns"
      [stickyFieldKeys]="['directory_name']"
    >
      <span class="custom-tooltip">
        <div><i class="zmdi zmdi-info-outline"></i></div>
        <span
          >The records that are highlighted in red represent those with a
          missing key and log file.
          <br />
          This may be because the folder is not found in the traces locator or
          the AWS path provided, or that the paths exist but the key and/or log
          files do not exist there.
        </span>
      </span>
      <button mat-button (click)="rebuildDataTable()">Rebuild Table</button>
    </app-custom-table>
  </div>
</div>

<div
  class="edit-modal data-table-comp-modal overlay-behavior"
  *ngIf="showEditModal"
>
  <div class="modal-body">
    <div
      style="
        align-items: center;
        justify-content: space-between;
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
      "
    >
      <input
        type="file"
        (change)="onFileSelected($event)"
        class="file-input"
        multiple
        #fileUpload
      />
      <div class="file-upload">
        <button
          mat-mini-fab
          style="background-color: #2196f3"
          color="primary"
          class="upload-btn"
          (click)="fileUpload.click()"
        >
          <i class="zmdi zmdi-attachment-alt"></i>
        </button>
        <div id="files-preview-section"></div>
      </div>
      <p style="display: flex; gap: 10px; margin: 0px">
        <button
          mat-raised-button
          (click)="parseFiles()"
          style="height: fit-content"
        >
          Parse
        </button>
        <button
          mat-raised-button
          (click)="saveChanges()"
          style="height: fit-content; background-color: #2196f3; color: white"
        >
          Save
        </button>
      </p>
    </div>

    <div class="form-input">
      <label>
        <input [(ngModel)]="activeRow['comment']" />
        <span>Comment</span>
      </label>
    </div>

    <div
      class="pretty-parsed-data"
      [innerHTML]="syntaxHighlight(this.dataValues)"
    ></div>

    <!-- <div class="params-form-section">
      <div class="input-container" *ngFor="let key of this.getObjectKeys(this.activeRow)">
        <label>{{key}}</label>
        <input [value]="activeRow[key]" (change)="changeValue($event, key)" />
      </div>
    </div> -->
  </div>
</div>

<div
  *ngIf="showSaveModalDialog"
  id="data-table-save-dialog"
  class="overlay-behavior"
>
  <div class="dialog-body">
    You have unsaved changes.
    <div class="buttons-container">
      <button (click)="discardChanges()" class="danger">Discard</button>
      <button (click)="saveChanges()" class="primary">Save</button>
    </div>
  </div>
</div>
