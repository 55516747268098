<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <button  mat-raised-button  color="primary" *ngIf="!isLoading" (click)="startDownload()">Click to Download</button>
          <div *ngIf="isLoading" class="loading-container">
            <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner><div class="text">Loading...</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
