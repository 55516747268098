export const trace_locator_params = {
  "Data Processing": [
    "delay_both",
    "delay_data",
    "delay_source",
    "high_cut",
    "high_roll",
    "low_cut",
    "low_roll",
    "minimum_phase",
    "process_time",
    "time_step_segy",
  ],
  "Finite-Difference Setup": [
    "nx1",
    "nx2",
    "nx3",
    "time_step_fd",
    "total_time",
  ],
  "Geometry Setup": [
    "add_to_depth",
    "angle",
    "depth_scale",
    "depth_type",
    "dx",
    "dz",
    "fixed_array",
    "geometry",
    "left2right",
    "offset_2d",
    "project_2d",
    "rec_depth",
    "rec_dx",
    "rec_dy",
    "rec_geometry",
    "rec_nx",
    "rec_ny",
    "rec_x_origin",
    "rec_y_origin",
    "reciprocity",
    "sou_depth",
    "sou_dx",
    "sou_dy",
    "sou_nx",
    "sou_ny",
    "sou_x_origin",
    "sou_y_origin",
    "text",
    "unique_shots",
    "use_ffids",
    "x_origin",
    "x_shift",
    "y_origin",
    "y_shift",
    "xy_scale",
  ],
  "Output Control": [
    "debug",
    "io",
    "out_seis",
    "out_source",
    "problem_type",
    "retain",
    "water_depth",
    "water_depth_scale",
  ],
  "Trace Selection": [
    "edge_gap",
    "kill_header_2",
    "kill_header_4",
    "kill_value_2",
    "kill_value_4",
    "max_angle",
    "max_offset",
    "max_recs",
    "max_sources",
    "max_traces",
    "min_offset",
    "sample_recs",
    "sample_shots",
  ],
  "Traces Info": [
    "total_traces",
    "traces_outside_offset",
    "original_sources",
    "original_recievers",
    "maximum_recievers_per_source",
    "signature_identifier",
  ],
};

export const default_showing_columns = [
  "directory_name",
  "comment",
  "delay_both",
  "delay_data",
  "delay_source",
  "high_cut",
  "high_roll",
  "low_cut",
  "low_roll",
  "minimum_phase",
  "time_step_segy",
  "nx1",
  "nx2",
  "nx3",
  "total_time",
  "add_to_depth",
  "angle",
  "depth_scale",
  "depth_type",
  "dx",
  "dz",
  "fixed_array",
  "geometry",
  "project_2d",
  "rec_depth",
  "rec_dx",
  "rec_dy",
  "reciprocity",
  "sou_depth",
  "text",
  "unique_shots",
  "use_ffids",
  "x_origin",
  "x_shift",
  "y_origin",
  "y_shift",
  "xy_scale",
  "debug",
  "io",
  "out_seis",
  "out_source",
  "problem_type",
  "retain",
  "water_depth_scale",
  "edge_gap",
  "max_angle",
  "max_offset",
  "max_recs",
  "max_sources",
  "min_offset",
  "sample_recs",
  "sample_shots",
  "total_traces",
  "traces_outside_offset",
  "original_sources",
  "original_recievers",
  "maximum_recievers_per_source",
  "signature_identifier",
];
