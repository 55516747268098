import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";
import { PlotlyComponent } from "./plotly/plotly.component";
import { SliderComponent } from "./slider/slider.component";
import { ZorroModule } from "./zorro.module";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ZorroModule, MaterialModule],
  declarations: [SliderComponent, PlotlyComponent],
  exports: [SliderComponent, PlotlyComponent, CommonModule, FormsModule, ReactiveFormsModule],
})
export class SharedModule {}
