<div class="field-listing-container">
  <div
    style="font-size: 36px; color: rgba(0, 0, 0, 0.7)"
    class="os-animation fadeInUp animated"
  >
    Fields
  </div>
  <div
    class="text os-animation fadeInUp animated"
    data-os-animation="fadeInUp"
    data-os-animation-delay="0.2s"
    style="animation-delay: 0.2s"
    style="display: flex; justify-content: space-between"
  >
    <p>Select your Field or create a new one to add your data</p>

    <nz-button-group>
      <button
        *ngIf="filtering"
        nz-button
        nzDanger
        nzSize="small"
        (click)="filteredFields = fields; filtering = false"
      >
        <span nz-icon nzType="close" nzTheme="outline"></span>
      </button>
      <button nz-button nz-dropdown [nzDropdownMenu]="menu1" nzSize="small">
        Filter
        <span nz-icon nzType="ellipsis" nzTheme="outline"></span>
      </button>
    </nz-button-group>

    <nz-dropdown-menu #menu1="nzDropdownMenu">
      <ul nz-menu>
        <li nz-submenu nzTitle="Geography">
          <ul>
            <li nz-menu-item (click)="filterFor('geography', 'Land')">Land</li>
            <li nz-menu-item (click)="filterFor('geography', 'Marine')">
              Marine
            </li>
          </ul>
        </li>
        <li nz-submenu nzTitle="Client">
          <ul>
            <li
              nz-menu-item
              *ngFor="let client of filter_empty(clients)"
              (click)="filterFor('client', client)"
            >
              {{ client }}
            </li>
          </ul>
        </li>
        <li nz-submenu nzTitle="Location">
          <ul>
            <li
              nz-menu-item
              *ngFor="let loc of filter_empty(locations)"
              (click)="filterFor('location', loc)"
            >
              {{ loc }}
            </li>
          </ul>
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
  <button
    *ngIf="!demoUser"
    nz-button
    nzType="dashed"
    nzBlock
    (click)="getAllProjects(); fieldModalVisible = true"
  >
    <span nz-icon nzType="plus" nzTheme="outline"></span>
    Create New Field
  </button>

  <nz-collapse style="margin: 15px 0" [nzBordered]="false" nzAccordion>
    <nz-collapse-panel
      *ngFor="let field of filteredFields; index as i"
      [nzHeader]="collapseHeader"
      (nzActiveChange)="getFieldProjects(field)"
    >
      <ng-template #collapseHeader>
        <div
          style="
            display: flex;
            justify-content: space-between;
            gap: 10px;
            flex-grow: 1;
          "
        >
          <div>
            <div style="font-size: 15px">
              {{ field.name }}
            </div>
            <div *ngIf="field.client">
              <i class="zmdi zmdi-ungroup"></i>
              <span style="color: rgba(0, 0, 0, 0.75)">
                {{ field.client }}
              </span>
            </div>
            <div style="margin-top: 10px">
              <span style="color: rgba(0, 0, 0, 0.5)">
                {{ field.description }}
              </span>
            </div>
          </div>
          <div>
            <div style="display: flex; justify-content: flex-end; gap: 20px">
              <span *ngIf="field.location" style="color: #2196f3">
                <i class="zmdi zmdi-pin"></i>
                {{ field.location }}
              </span>
              <span
                class="settings-btn zmdi zmdi-edit"
                (click)="editField(field); $event.stopPropagation()"
              ></span>
            </div>
            <div *ngIf="field.geography" style="text-align: right">
              {{ field.geography }}
            </div>
          </div>
        </div>
      </ng-template>
      <nz-spin [nzSpinning]="this.isLoading">
        <div
          *ngFor="let project of this.fieldProjects[field.id]"
          class="project-list-item"
          (click)="goToProject(project)"
        >
          {{ project.name }}

          <p style="margin: 5px 0px; color: rgba(0, 0, 0, 0.5)">
            {{ project.description }}
          </p>
        </div>
      </nz-spin>
    </nz-collapse-panel>
  </nz-collapse>
</div>

<nz-modal
  [nzVisible]="fieldModalVisible"
  [nzTitle]="activeField ? 'Edit Field' : 'Create New Field'"
  (nzOnCancel)="closeFieldModal()"
  (nzOnOk)="!activeField ? createNewField() : onEditField()"
>
  <ng-container *nzModalContent>
    <nz-spin [nzSpinning]="isLoading">
      <form nz-form [formGroup]="fieldForm">
        <nz-input-group class="nz-input-group-floating-label">
          <label for="name">Field Name</label>
          <input
            type="text"
            nz-input
            formControlName="name"
            id="name"
            name="name"
            placeholder="Field Name"
          />
        </nz-input-group>
        <nz-input-group class="nz-input-group-floating-label">
          <label for="geography">Geography</label>
          <nz-select
            style="width: 100%"
            formControlName="geography"
            nzPlaceHolder="Marine or Land?"
            id="geography"
            name="geogrpahy"
          >
            <nz-option
              *ngFor="let geo of ['Marine', 'Land']"
              [nzValue]="geo"
              [nzLabel]="geo"
            ></nz-option>
          </nz-select>
        </nz-input-group>
        <nz-input-group class="nz-input-group-floating-label">
          <label for="location">Location</label>
          <nz-select
            nzShowSearch
            style="width: 100%"
            formControlName="location"
            nzPlaceHolder="Select Location"
            id="location"
            name="location"
            [nzFilterOption]="selectFilterLocation"
          >
            <nz-option
              *ngFor="let loc of locations"
              [nzValue]="loc"
              [nzLabel]="loc"
            ></nz-option>
          </nz-select>
        </nz-input-group>
        <nz-input-group class="nz-input-group-floating-label">
          <label for="clientName">Client Name</label>
          <nz-select
            nzShowSearch
            style="width: 100%"
            formControlName="client"
            nzPlaceHolder="Select Client"
            id="clientName"
            name="clientName"
            [nzFilterOption]="selectFilterClient"
          >
            <nz-option
              *ngFor="let client of clients"
              [nzValue]="client"
              [nzLabel]="client"
            ></nz-option>
          </nz-select>
        </nz-input-group>
        <div style="max-height: 200px; overflow-y: scroll; position: relative">
          <nz-input-group class="nz-input-group-floating-label">
            <label for="projects">Field Projects</label>
            <nz-select
              style="width: 100%"
              nzMode="multiple"
              nzPlaceHolder="Please select Projects"
              formControlName="projects"
              id="projects"
              name="projects"
            >
              <nz-option
                *ngFor="let p of allProjects"
                [nzValue]="p.id"
                [nzLabel]="p.name"
              ></nz-option>
            </nz-select>
          </nz-input-group>
        </div>
        <nz-input-group class="nz-input-group-floating-label">
          <label for="description">Field Description</label>
          <textarea
            type="text"
            nz-input
            formControlName="description"
            id="description"
            name="description"
            placeholder="Field Description"
          ></textarea>
        </nz-input-group>
      </form>
    </nz-spin>
  </ng-container>
</nz-modal>
