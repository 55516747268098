import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation, HostListener, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import * as d3 from 'd3';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';

export interface position {
  x: number;
  y: number;
}

@Component({
  selector: 'app-vertical-profile',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './vertical-profile.component.html',
  styleUrls: ['./vertical-profile.component.less']
})
export class VerticalProfileComponent implements OnInit, OnChanges {
  @Input() set height(value: number) {
    this._height = value;
  }
  @Input() velocities: number[];
  @Input() set depth (value:number) {
    this._depth = value;
  }

  @Input() depthSlice: number;
  @Input() modelTypeId: string;
  @Input() hideAxes: boolean;
  // if either of these two fields are not null, they would be used as min/max, should only be set if user manually set it
  @Input() axisMin: number;
  @Input() axisMax: number;
  // comes from the color bar, if the above are nulls then a padded range would be calculated, if only one is null then the other end will be calculated
  // @Input() colorbarmin: number;
  // @Input() colorbarmax: number;
  @Input() isLoading: boolean;
  @Input() hasError: boolean;
  @Input() gridSpacing: number;
  @Input() ILXLStartDepth: number;
  @Input() ILXLEndDepth: number;

  @Output() axisMinMaxEmitter = new EventEmitter<number[]>();
  @Output() syncAxisEmitter = new EventEmitter<boolean>();

  @ViewChild('graph', { static: true }) graph: ElementRef;
  @ViewChild('graphContainer', { static: true }) container: ElementRef;

  timeNumber: number;
  zoomFactor:number = 1.00;
  private width: number;
  private _height: number = 300;
  private margin: { top: number, right: number, bottom: number, left: number }
  private _depth: number;

  private transform;

  private svg;
  data = [];
  velocityMin : number;
  velocityMax : number;
  defaultMin: number;
  defaultMax: number;
  syncAxis = true; // currently always synced
  color : string = 'red';
  axisPadding: number = 0.05; // both ends
  zoomIn=null;
  zoomOut=null;
  reset=null;
  xLegend : string;
  xLegendUnit: string;
  yLegend : string = 'depth';
  yLegendUnit: string  = 'm'
  tickFormat = d3.format('.2e');

  // for the small circle on the line
  cursorX = -2000;
  cursorY = -2000;
  
  constructor() { }

  ngOnInit() {
    this.setMiscFromModelType();
    if (!this.isLoading) {
      this.data = []
      if (this.velocities != null && this._depth != null) {
          let step = this._depth / this.velocities.length 
          this.velocityMin = this.velocities[0] 
          this.velocityMax = this.velocities[0] 
          for (let i = 0; i < this.velocities.length; i++) {
              let velocity = this.velocities[i] 
              if (velocity > this.velocityMax) {
                  this.velocityMax = velocity
              }
              if (velocity < this.velocityMin) {
                  this.velocityMin = velocity
              }
              this.data.push({
                  x: velocity, y: i*step + this.gridSpacing/2
              })
          }
      }
      let min = Math.min (...this.data.map(d=>d.x));
      let max = Math.max (...this.data.map(d=>d.x));
      let range = max - min;
      let padding = range * this.axisPadding;
      this.defaultMin = min - padding;
      this.defaultMax = max + padding;
      this.draw()
    }
  }

  inputClicked(event) {
    event.stopPropagation();
  }

  onKeySubmission() {
    this.updateGraph();
    this.axisMin = Number.parseFloat(this.axisMin.toFixed(2));
    this.axisMax = Number.parseFloat(this.axisMax.toFixed(2));
    this.axisMinMaxEmitter.emit([this.axisMin,this.axisMax]);
  }

  resetAxis() {
    setTimeout(()=> {
      this.axisMax = this.defaultMax;
      this.axisMin = this.defaultMin;
      this.axisMinMaxEmitter.emit([this.axisMin,this.axisMax]);
      this.updateGraph();
    },0)
  }

  setMiscFromModelType() {
    switch (this.modelTypeId) {
      case 'Vp':
      case 'Total_Vp_Update':
      case 'Add':
      case 'Vdiff':
        this.xLegend = 'velocity';
        this.xLegendUnit = 'm/s'
        break;
      case 'InvVp Update':
        this.xLegend = 'slowness update'
        this.xLegendUnit = 's/m'
        break;
      case 'Gradient':
        this.xLegend = 'gradient'
        this.xLegendUnit = 's/m'
        break;
      case 'den':
        this.xLegend = 'density';
        this.xLegendUnit = 'g/cm^3'
        break;
      default:
        this.xLegend = 'value';
        this.xLegendUnit = ''
    }
  }

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.resizeWithDelay(event);
  }

  private resizeWithDelay = debounce((event) => {
    this.updateGraph();
    this.zoomFactor=1.00
  }, 100);

  ngOnChanges(changes: SimpleChanges): void {
    this.setMiscFromModelType();
    this.updateGraph();
    this.syncAxisEmitter.emit(this.syncAxis)
  }

  updateGraph() {
    // Remove previous image and axises
    if (this.svg) {
      this.svg.remove();
    }
    if (!this.isLoading) {
      this.data = []
      if (this.velocities != null && this._depth != null) {
          let step = this._depth / this.velocities.length 
          this.velocityMin = this.velocities[0]
          this.velocityMax = this.velocities[0] 
          for (let i = 0; i < this.velocities.length; i++) {
              let velocity = this.velocities[i]
              if (velocity > this.velocityMax) {
                  this.velocityMax = velocity
              }
              if (velocity < this.velocityMin) {
                  this.velocityMin = velocity
              }
              this.data.push({
                  x: velocity, y: i*step + this.gridSpacing/2
              })
          }
      }
      let min = Math.min (...this.data.map(d=>d.x));
      let max = Math.max (...this.data.map(d=>d.x));
      let range = max - min;
      let padding = range * this.axisPadding;
      this.defaultMin = min - padding;
      this.defaultMax = max + padding;
      this.defaultMin = Number.parseFloat(this.defaultMin.toFixed(2));
      this.defaultMax = Number.parseFloat(this.defaultMax.toFixed(2));
      // Get width and height based on current window
      this.width = this.container.nativeElement.offsetWidth;
      this.margin = {
        top: 20,
        right: 10,
        bottom: 50,
        left: 50
      };
      this.draw();
    }
  }

  private draw() {
    this.cursorX = -100;
    this.cursorY = -100;
    let width = this.width - this.margin.left - this.margin.right;
    let height = this._height - this.margin.top - this.margin.bottom;
    let graph_height = height - this.margin.bottom;
    // handler for zoom transformation
    let zoomed = () => {
      let transform = d3.event.transform;
      let tx = d3.event.transform.x;
      let ty = d3.event.transform.y;
      let tk = d3.event.transform.k;
      this.zoomFactor = tk.toPrecision(3);
      // Restrict the translation to prevent the image goes out of the bounds
      transform.x = Math.min(0, Math.max(tx, width * (1 - tk)))
      transform.y = Math.min(0, Math.max(ty, height * (1 - tk)))
      this.transform = transform;
      //Change the scales and the axises
      gX.call(xAxis.scale(d3.event.transform.rescaleX(xScale)));
      gY.call(yAxis.scale(d3.event.transform.rescaleY(yScale)));

      const newXScale = d3.event.transform.rescaleX(xScale);
      const newYScale = d3.event.transform.rescaleY(yScale);
    
      // Update the line and area paths with the new scales
      line.x(d => newXScale(d.x)).y(d => newYScale(d.y));

      svg.selectAll(".line")
        .attr("d", line);
      
      svg.selectAll('.circle')
        .attr('cx',newXScale(this.cursorX))
        .attr('cy',newYScale(this.cursorY));
    }

    //Zoom behaviour for the image
    let zoom = d3.zoom()
      .filter(function () {
        return d3.event.metaKey || d3.event.ctrlKey;
      })
      .scaleExtent([1, 50])
      .on("zoom", zoomed);

    // Set scales for axis
    let xmin = this.axisMin != null ? this.axisMin : this.defaultMin;
    let xmax = this.axisMax != null ? this.axisMax : this.defaultMax;
    this.axisMax = xmax;
    this.axisMin = xmin;
    this.axisMinMaxEmitter.emit([this.axisMin,this.axisMax]);
    let xScale = d3.scaleLinear()
      .domain([xmin, xmax])
      .range([0, width]);    
    
    let ymin = this.ILXLStartDepth;
    let ymax = this.ILXLEndDepth;
    // let ymin = 0;
    // let ymax = this._depth;

    let yScale = d3.scaleLinear()
      .domain([ymin, ymax])
      .range([0, graph_height]);

    //D3 axises with scale and style
    let xAxis;
    if (width <=300) {
      xAxis = d3.axisBottom(xScale)
        .tickValues([xmin, xmax])
        .tickSize(8)
        .tickPadding(8)
        .tickFormat(this.tickFormat)
    } else {
      xAxis = d3.axisBottom(xScale)
      .ticks(this.hideAxes ? 0 : (width <= 300 ? 2 : 3))
      .tickSize(8)
      .tickPadding(8)
      .tickFormat(this.tickFormat);
    }
    let yAxis = d3.axisLeft(yScale)
      .ticks(this.hideAxes ? 0 : 4)
      .tickSize(8)
      .tickPadding(0);

    // instantiate svg with given width and height.
    let svg = d3.select(this.graph.nativeElement)
      .attr("width", this.width)
      .attr("height", this._height)
      .append("g")
      .attr("width", width)
      .attr("height", height)
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)

    this.svg = svg;

    // The main image

    const line = d3.line()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y));
    
    if (this.depthSlice != null) {
      let dashed_line_data = [{x:xmin, y: this.data[this.depthSlice-1].y}, {x:xmax, y: this.data[this.depthSlice-1].y}]
      svg.append("path")
        .datum(dashed_line_data)
        .attr("fill", "none")
        .attr('stroke', 'black')
        .attr('stroke-width', 2)
        .attr("stroke-opacity", 1)
        .attr('d',line)
        .attr('class', 'line')
        .attr('stroke-dasharray', '3,6')
        .attr('opacity', this.depthSlice == null ? 0 : 1)
    }
    
    let circle = svg.append("circle")
        .attr('cx', xScale(this.cursorX))
        .attr('cy', yScale(this.cursorY))
        .attr('r',5)
        .style('fill','red')
        .attr('class','circle')

    svg.append("path")
        .datum(this.data)
        .attr("fill", "none")
        .attr("stroke", this.color)
        .attr("stroke-width", 2)
        .attr("stroke-opacity", 1)
        .attr("d", line)
        .attr("class", "line");

    // clipping the image
    let wrapper = svg.append("g")
      .attr("id", "wrapper")

    wrapper.append("rect")
      .attr("x", -this.margin.left)
      .attr("y", -this.margin.top)
      .attr("width", this.margin.left)
      .attr("height", this._height)
      .attr("fill", "#ffffff")

    wrapper.append("rect")
      .attr("x", -this.margin.left)
      .attr("y", -this.margin.top)
      .attr("width", this.width)
      .attr("height", this.margin.top)
      .attr("fill", "#ffffff")

    wrapper.append("rect")
      .attr("x", width)
      .attr("y", -this.margin.top)
      .attr("width", this.margin.right)
      .attr("height", this._height)
      .attr("fill", "#ffffff")

    wrapper.append("rect")
      .attr("x", -this.margin.left)
      .attr("y", graph_height)
      .attr("width", this.width)
      .attr("height", this._height - (graph_height))
      .attr("fill", "#ffffff")

    // Instantiate axis
    let gX = svg.append("g")
      .attr("transform", `translate(0, ${graph_height})`)
      .call(xAxis);
    let gY = svg.append("g").call(yAxis);

    svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", 0 - this.margin.left)
    .attr("x",0 - ((graph_height) / 2))
    .attr("dy", "1em")
    .style("text-anchor", "middle")
    .style("font-size",'10px')
    .style("font-family", 'verdana, arial, sans-serif')
    .text(`${this.yLegend} (${this.yLegendUnit})`);

    svg.append("text")
    .attr("transform",
          "translate(" + (width/2) + " ," +
                         (height) + ")")
    .style("text-anchor", "middle")
    .style("font-size",'10px')
    .style("font-family", 'verdana, arial, sans-serif')
    .text( this.xLegendUnit != '' ? `${this.xLegend} (${this.xLegendUnit})` : `${this.xLegend}`);

    // if (this.transform) {
    //   svg.call(zoom.transform, this.transform);
    // }
    // Set zoom behaviour for this svg
    // svg.call(zoom);

    var overlay = svg.append("rect")
      .attr("width", "100%")
      .attr("height", height)
      .attr("fill", "none")
      .attr("pointer-events", "all");
    overlay.call(zoom)


    const zoomIdentity = d3.zoomIdentity
    .translate(this.margin.left, this.margin.top)
    .scale(1);

    function onZoomActionClick(d) {

      switch (d.value) {
        case 'reset':
          overlay.call(zoom)
            .call(zoom.transform, zoomIdentity)
            .on("dblclick.zoom", null);
          break;
        case 'zoomin':
          zoom.scaleBy(overlay, 1.3);
          break;
        case 'zoomout':
          zoom.scaleBy(overlay, 1 / 1.3);
          break;
      }
    }

    function triggerZoomIn() {
      onZoomActionClick({ value: 'zoomin' });
    }

    function triggerZoomOut() {
      onZoomActionClick({ value: 'zoomout' });
    }

    function triggerReset() {
      onZoomActionClick({ value: 'reset' });
    }

    this.zoomIn = triggerZoomIn.bind(this);
    this.zoomOut = triggerZoomOut.bind(this);
    this.reset = triggerReset.bind(this);

    let imgHoverG = svg.patternify({ tag: 'g', selector: 'image-hover-group' })
      .attr('transform', `translate(${width },${height+15})`)
      .attr('fill', '#2C3E50')
      .attr('font-size', 11)
      .attr('opacity', 0);

    const hoverX = imgHoverG.patternify({ tag: 'text', selector: 'hover-x' })
      .text('x - 200')
      .attr('text-anchor', 'end');

    const hoverY = imgHoverG.patternify({ tag: 'text', selector: 'hover-y' })
      .text('y - 1500')
      .attr('transform','translate(0,20)')
      .attr('text-anchor', 'end');


    
    function updatePosStats(mouse, _this) {
      try {
        var transform = _this.transform;
        let ty = mouse[1]
        if (transform) {
          ty = transform.invert(mouse)[1];
        }
        var y = Math.round((_this.ILXLStartDepth + ty / (graph_height)) * _this.data.length * (_this.ILXLEndDepth - _this.ILXLStartDepth) / _this._depth );
        _this.cursorX = _this.data[y].x;
        _this.cursorY = _this.data[y].y;
        if (transform != null) {
          let rescaledX = _this.transform.rescaleX(xScale)
          let rescaledY = _this.transform.rescaleY(yScale)
          circle.attr('cx', rescaledX(_this.cursorX)).attr('cy', rescaledY(_this.cursorY))
        } else {
          circle.attr('cx', xScale(_this.cursorX)).attr('cy', yScale(_this.cursorY))
        }
        hoverX.text(`${_this.xLegend} - ${_this.data[y].x}`);
        hoverY.text(`${_this.yLegend} - ${_this.data[y].y}`);
      } catch (e) {
        // console.log(e)
        hoverX.text(``);
        hoverY.text(``);
      }
    }

    const _this = this;

    svg.on('mousemove', function (d) {
      imgHoverG.attr('opacity',1);
      circle.attr('opacity',1)
      const mouse = d3.mouse(this);
      updatePosStats(mouse, _this);
    })
    .on('mouseleave', function(d) {
      imgHoverG.attr('opacity',0)
      circle.attr('opacity',0)
    })

  }

  toggleSync() {
    this.syncAxisEmitter.emit(this.syncAxis)
  }
}