<div class="container-fluid">
  <span
    nz-tooltip
    [nzTooltipTitle]="titleTemplate"
    nz-icon
    nzType="info-circle"
    nzTheme="outline"
    style="font-size: 20px"
  ></span>

  <div style="display: flex; position: relative; gap: 10px">
    <div
      echarts
      [options]="options"
      [loading]="this.loading"
      style="
        height: 90vh;
        background-color: white;
        flex: 1 1 auto;
        transition: all 0.3s;
        border-radius: 2px;
      "
      [style.minWidth]="configHidden ? '100%' : '75%'"
      class="demo-chart"
      (chartInit)="chartInit($event)"
    ></div>

    <div
      style="
        height: 90vh;
        position: relative;
        width: 20%;
        background-color: white;
        padding: 10px;
        border-radius: 2px;
      "
      [class]="configHidden ? '_hide-config' : '_show-config'"
      class="_config-box"
    >
      <div
        style="
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
        "
      >
        <button
          nz-button
          nzType="default"
          nzSize="small"
          (click)="configHidden = !configHidden"
        >
          <span nz-icon nzType="close" nzTheme="outline"></span>
        </button>

        <div style="display: flex; gap: 5px">
          <button
            style="margin-bottom: 10px"
            nz-button
            nzType="primary"
            nzGhost
            nzSize="small"
            (click)="this.loadState()"
          >
            Load State
          </button>
          <button
            style="margin-bottom: 10px"
            nz-button
            nzType="primary"
            nzGhost
            nzSize="small"
            (click)="this.cacheState()"
          >
            Save State
          </button>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
        "
      >
        <button
          nz-button
          nzType="primary"
          nzSize="small"
          nzGhost
          (click)="this.updateNodes()"
        >
          Apply Changes
        </button>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: flex-end;
          "
        >
          <span>
            FM<nz-switch
              style="padding-left: 10px"
              [(ngModel)]="fm"
              (ngModelChange)="get_project_flowchart()"
            ></nz-switch>
          </span>
          <span>
            Job groups<nz-switch
              style="padding-left: 10px"
              [(ngModel)]="activateGrouping"
              (ngModelChange)="group_flowchart()"
            ></nz-switch>
          </span>
        </div>
      </div>

      <nz-tabset style="height: 100%" [nzSelectedIndex]="1">
        <nz-tab nzTitle="Selected Job" style="height: 100%">
          <nz-spin [nzSpinning]="this.isJobInfoLoading" style="height: 100%">
            <ng-container *ngIf="this.edittableJob; else noJobSelected">
              <div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    align-items: center;
                  "
                >
                  <input
                    style="flex-grow: 1"
                    nz-input
                    #paramsSearchInp
                    placeholder="Search"
                    [(ngModel)]="searchStr"
                    (ngModelChange)="this.changeDetectorRef.detectChanges()"
                  />
                  <button
                    nz-button
                    nzGhost
                    nzType="primary"
                    [disabled]="this.edittableJob == null"
                    style="float: right"
                    (click)="openJobDialog()"
                  >
                    Edit Job
                  </button>
                </div>
              </div>
              <div style="overflow: scroll; height: 70vh">
                <div
                  *ngFor="
                    let param of this.getObjectKeys(
                      this.edittableJobInfo?.paramsInfo || {}
                    ) | search2 : searchStr
                  "
                  style="
                    margin-bottom: 10px;
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 3px;
                  "
                >
                  <b style="display: block; height: 20px; line-height: 20px"
                    >{{ param }}:
                  </b>
                  <span
                    style="
                      font-size: smaller;
                      line-height: 15px;
                      display: block;
                      padding-left: 5px;
                    "
                    >{{ this.edittableJobInfo.paramsInfo[param] }}</span
                  >
                </div>
              </div>
            </ng-container>
            <ng-template #noJobSelected>
              No job has been selected currently. Click on a job to select it.
              {{ this.edittableJob?.id || "null" }}
            </ng-template>
          </nz-spin>
        </nz-tab>
        <nz-tab nzTitle="Filter" style="height: 100%">
          <nz-collapse [nzBordered]="false" nzAccordion>
            <nz-collapse-panel nzHeader="By Job">
              <ng-container *ngTemplateOutlet="jobPanel"> </ng-container>
            </nz-collapse-panel>
            <nz-collapse-panel nzHeader="By Job CP" nzActive>
              <ng-container *ngTemplateOutlet="cpPanel"> </ng-container>
            </nz-collapse-panel>
          </nz-collapse>
        </nz-tab>
        <nz-tab nzTitle="Presets" *ngIf="false">
          <div style="height: 85vh"></div>
        </nz-tab>
        <!-- hiding temporarily -->
        <nz-tab nzTitle="Parameters" *ngIf="false"
          >Here you will be able to filter the parameters showing when you hover
          the jobs maybe?
          <div>
            <nz-input-group [nzSuffix]="suffixIcon">
              <input
                type="text"
                nz-input
                placeholder="Search"
                [(ngModel)]="searchValueParam"
                (ngModelChange)="filterParams()"
              />
            </nz-input-group>
            <ng-template #suffixIcon>
              <span nz-icon nzType="search"></span>
            </ng-template>
            <div style="height: 520px; overflow-y: scroll">
              <nz-tree
                nzCheckable
                nzMultiple
                nzBlockNode
                style="background-color: transparent"
                [nzData]="this.filteredParams"
                (nzClick)="paramTreeActionEvent($event)"
                (nzCheckBoxChange)="paramTreeActionEvent($event)"
              ></nz-tree>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>

    <button
      style="position: fixed; top: 50%; right: 0px; transition: all 0.3s"
      [style.right]="configHidden ? '0px' : '-50px'"
      nz-button
      nzType="primary"
      (click)="configHidden = !configHidden"
    >
      <span nz-icon nzType="left" nzTheme="outline"></span>
    </button>

    <div *ngIf="loading" class="loader-container">
      <div class="loader"></div>
    </div>
  </div>
</div>

<!-- The Jobs listing shown in the collapible in the filter tab -->
<ng-template #jobPanel>
  <div style="height: 55vh">
    Filter the jobs that you want to show. When you select a job, its parents
    and descendants will be shown. <br />
    If the node selected has multiple children and siblings, its siblings will
    all be hidden while all its children will be showing

    <div>
      <nz-input-group [nzSuffix]="suffixIcon" nzSize="small">
        <input
          type="text"
          nz-input
          placeholder="Search"
          [(ngModel)]="searchValue"
        />
      </nz-input-group>
      <ng-template #suffixIcon>
        <span nz-icon nzType="search"></span>
      </ng-template>

      <div style="overflow-y: scroll; height: 32vh; position: relative">
        <label
          nz-checkbox
          style="
            margin-left: 24px;
            width: 100%;
            position: sticky;
            padding: 4px 0px;
            top: 0px;
            background: rgb(250, 250, 250);
            z-index: 1;
          "
          [(ngModel)]="jobsSelectAll"
          [nzIndeterminate]="jobsSelectIndeterminate"
          (ngModelChange)="updateAllChecked()"
        >
          Check all
        </label>
        <nz-tree
          nzCheckable
          nzMultiple
          nzBlockNode
          style="background-color: transparent"
          (nzClick)="jobTreeActionEvent($event)"
          (nzCheckBoxChange)="jobTreeActionEvent($event)"
          [nzData]="this.jobs | search2 : searchValue : 'title'"
        ></nz-tree>
      </div>
    </div>
  </div>
</ng-template>

<!-- The CPFiltering shown in the collapible in the filter tab -->
<ng-template #cpPanel>
  <div
    style="margin: 5px; overflow-y: scroll; height: 55vh; position: relative"
  >
    <div *ngFor="let filter of this.cpFilters; let i = index">
      <div
        style="
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        "
      >
        <button
          nz-button
          nzType="primary"
          nzDanger
          nzSize="small"
          style="align-self: end; margin: 5px 0px"
        >
          <span
            nz-icon
            nzType="close"
            nzTheme="outline"
            (click)="this.cpFilters.splice(i, 1)"
          ></span>
        </button>
        <p class="_cpfilters-input">
          <label> Job </label>
          <nz-select
            style="width: 100%"
            nzShowSearch
            nzPlaceHolder="Please select"
            nzSize="small"
            [nzOptions]="this.jobSelectOptionsArray"
            [nzDropdownMatchSelectWidth]="false"
            [nzDropdownStyle]="{
              width: '350px'
            }"
            [ngModel]="filter.job"
            (ngModelChange)="updateCpFilter($event, i)"
          >
          </nz-select>
        </p>

        <p class="_cpfilters-input">
          <label>Checkpoint</label>
          <nz-select
            style="width: 100%"
            nzShowSearch
            nzPlaceHolder="Please select"
            nzSize="small"
            nzMode="multiple"
            [nzOptions]="filter.cpOptions || []"
            [nzDropdownMatchSelectWidth]="false"
            [nzDropdownStyle]="{
              width: '350px'
            }"
            [(ngModel)]="filter.cp"
          >
          </nz-select>
        </p>
      </div>
    </div>

    <div style="position: sticky; bottom: 0px; z-index: 2">
      <button
        nz-button
        nzType="dashed"
        nzSize="small"
        nzBlock
        (click)="this.cpFilters.unshift({ job: null, cp: null })"
      >
        Add
      </button>
      <button
        nz-button
        nzType="priamry"
        nzSize="small"
        nzBlock
        (click)="applyCpFiltering2()"
        style="margin-top: 5px"
      >
        CP Filtering
      </button>
    </div>
  </div>
</ng-template>

<!-- For the above chart tooltip -->
<ng-template #titleTemplate>
  <div style="display: flex; gap: 10px; align-items: center; margin: 5px 0">
    <span
      style="
        border: 3px solid red;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      "
      >Job</span
    >
    Deleted, or are from a diffferent project
  </div>
  <div style="display: flex; gap: 10px; align-items: center; margin: 5px 0">
    <span
      style="
        border: 3px solid green;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      "
      >Job</span
    >
    QC Jobs
  </div>
  <div style="display: flex; gap: 10px; align-items: center; margin: 5px 0">
    <span
      style="
        border: 3px solid lightblue;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      "
      >Job</span
    >
    Normal Jobs
  </div>
  <div style="display: flex; gap: 10px; align-items: center; margin: 5px 0">
    <span
      style="
        border: 3px solid orange;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      "
      >Job</span
    >
    FM Jobs
  </div>
</ng-template>
