import { Component, OnInit } from "@angular/core";
import { JobDetail } from "../../../models/jobDetail";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { Subscription, Observable, forkJoin } from "rxjs";
import { CachedUserService } from "../../../shared/services/cached-user.service";
import { FormControl } from "@angular/forms";
import { Project } from "../../../models/project";
import { Router } from "@angular/router";
// import * as zlib from "zlib";
// import * as zlib from "browserify-zlib";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
  Always,
  Density,
  DensityGroups,
  Smoothing,
  SmoothingGroups,
  Temp,
  TempGroups,
  RWI,
  RWIMerge,
  RWIGroups,
  Mute,
  MuteGroups,
  AWI,
  AWIGroups,
  Constraints,
  ConstraintsGroups,
  Boundary,
  BoundaryGroups,
  Integers,
  StandardEnvironmentVariables,
  TraceManipulation,
  TraceManipulationGroups,
} from "./table_params";
import { Job } from "../../../models/job";
import { take } from "rxjs/operators";
import { EpicmodCommentsComponent } from "../dialogs/epicmod-comments/epicmod-comments.component";
// import { inflate } from "zlib";
import { inflate } from "pako";

interface Group {
  name: string;
  params: string[];
  nameMap?: { [key: string]: string };
}

interface Merge {
  name: string;
  params: string[];
  separator: string;
}

interface GroupData {
  name: string;
  colspan: number;
}

declare const Buffer;
@Component({
  selector: "app-tables",
  templateUrl: "./tables.component.html",
  styleUrls: ["./tables.component.css"],
})
export class TablesComponent implements OnInit {
  project: Project;
  currentJob: JobDetail;

  // variables for rwi table
  rwi_data = [];
  rwi_displayedColumns_full = Always.concat(RWI);
  rwi_displayedColumns = Always.concat(RWI);
  rwi_displayedGroups: GroupData[] = [];
  rwi_merge: Merge[] = RWIMerge;
  rwi_groups: Group[] = RWIGroups;
  rwi_collapsedColumns = [];

  // variables for smoothing table
  smoothing_data = [];
  smoothing_displayedColumns_full = Always.concat(Smoothing);
  smoothing_displayedColumns = Always.concat(Smoothing);
  smoothing_displayedGroups: GroupData[] = [];
  smoothing_merge: Merge[] = [];
  smoothing_groups: Group[] = SmoothingGroups;
  smoothing_collapsedColumns = [];

  // variables for mutes table
  mutes_data = [];
  mutes_displayedColumns_full = Always.concat(Mute);
  mutes_displayedColumns = Always.concat(Mute);
  mutes_collapsedColumns = [];
  mutes_displayedGroups: GroupData[] = [];
  mutes_merge: Merge[] = [];
  mutes_groups: Group[] = MuteGroups;

  // variables for density table
  density_data = [];
  density_displayedColumns_full = Always.concat(Density);
  density_displayedColumns = Always.concat(Density);
  density_displayedGroups: GroupData[] = [];
  density_merge: Merge[] = [];
  density_groups: Group[] = DensityGroups;
  density_collapsedColumns = [];

  // variables for temp table
  temp_data = [];
  temp_displayedColumns_full = Always.concat(Temp);
  temp_displayedColumns = Always.concat(Temp);
  temp_displayedGroups: GroupData[] = [];
  temp_merge: Merge[] = [];
  temp_groups: Group[] = TempGroups;
  temp_collapsedColumns = [];

  // variables for tracemanipulation table
  tracemanipulation_data = [];
  tracemanipulation_displayedColumns_full = Always.concat(TraceManipulation);
  tracemanipulation_displayedColumns = Always.concat(TraceManipulation);
  tracemanipulation_displayedGroups: GroupData[] = [];
  tracemanipulation_merge: Merge[] = [];
  tracemanipulation_groups: Group[] = TraceManipulationGroups;
  tracemanipulation_collapsedColumns = [];

  // variables for awi table
  awi_data = [];
  awi_displayedColumns_full = Always.concat(AWI);
  awi_displayedColumns = Always.concat(AWI);
  awi_displayedGroups: GroupData[] = [];
  awi_merge: Merge[] = [];
  awi_groups: Group[] = AWIGroups;
  awi_collapsedColumns = [];

  // variables for constraints table
  constraints_data = [];
  constraints_displayedColumns_full = Always.concat(Constraints);
  constraints_displayedColumns = Always.concat(Constraints);
  constraints_displayedGroups: GroupData[] = [];
  constraints_merge: Merge[] = [];
  constraints_groups: Group[] = ConstraintsGroups;
  constraints_collapsedColumns = [];

  // variables for boundary table
  boundary_data = [];
  boundary_displayedColumns_full = Always.concat(Boundary);
  boundary_displayedColumns = Always.concat(Boundary);
  boundary_displayedGroups: GroupData[] = [];
  boundary_merge: Merge[] = [];
  boundary_groups: Group[] = BoundaryGroups;
  boundary_collapsedColumns = [];

  all_displayedColumns = [
    this.rwi_displayedColumns,
    this.smoothing_displayedColumns,
    this.mutes_displayedColumns,
    this.density_displayedColumns,
    this.temp_displayedColumns,
    this.awi_displayedColumns,
    this.constraints_displayedColumns,
    this.boundary_displayedColumns,
  ];

  // variables for epicmods
  epicmods_displayedColumns = ["EPICMod_Update", "EPICMod_ModelProps"];
  epicmod_modelprops = "Loading...";
  epicmod_update = "Loading...";
  epicmod_comments = {
    EPICMod_Update: "",
    EPICMod_ModelProps: "",
  };

  // variables for problem description
  problem_displayedColumns = ["Probdims", "Equation", "Anisotropy", "Problem"];
  probdims = "Loading...";
  equation = "Loading...";
  anisotropy = "Loading...";
  problem = "Loading...";
  functionals = "Loading...";

  // variables for env variables
  envvars_wanted_slaves_found = [];
  envvars_wanted_scheduler_found = [];
  envvars = {};

  // variables for combined table
  combined_displayedColumns = Array.from(Always);
  combined_displayedColumns_full = Array.from(Always);
  combined_data = [];
  combined_collapsedColumns = [];
  combined_displayedGroups: GroupData[] = [];
  combined_merge: Merge[] = [];
  combined_groups: Group[] = this.rwi_groups
    .concat(this.smoothing_groups)
    .concat(this.density_groups)
    .concat(this.temp_groups)
    .concat(this.mutes_groups)
    .concat(this.awi_groups)
    .concat(this.constraints_groups)
    .concat(this.boundary_groups);
  tables_combined = {
    rwi: false,
    smoothing: false,
    mutes: false,
    density: false,
    temp: false,
    tracemanipulation: false,
    awi: false,
    constraints: false,
    boundary: false,
  }; //whether the table is included in the combined table
  show_combined = false;

  show_collapsed = true;
  tableAvailable: boolean;
  tables = [
    "rwi",
    "smoothing",
    "mutes",
    "density",
    "tracemanipulation",
    "temp",
    "awi",
    "constraints",
    "boundary",
  ];
  table_labels = {
    rwi: "RWI",
    smoothing: "Smoothing",
    mutes: "Mutes",
    density: "Density",
    tracemanipulation: "Trace Manipulation",
    temp: "Temp",
    awi: "AWI",
    constraints: "Constraints",
    boundary: "Boundary",
  };

  default_tableHeight = "350px";
  rwi_tableHeight = this.default_tableHeight;
  smoothing_tableHeight = this.default_tableHeight;
  mutes_tableHeight = this.default_tableHeight;
  density_tableHeight = this.default_tableHeight;
  temp_tableHeight = this.default_tableHeight;
  tracemanipulation_tableHeight = this.default_tableHeight;
  awi_tableHeight = this.default_tableHeight;
  constraints_tableHeight = this.default_tableHeight;
  boundary_tableHeight = this.default_tableHeight;
  combined_tableHeight = this.default_tableHeight;

  scrollPos = 0;
  currentJobSubscription: Subscription;
  job_name: string = "Loading...";
  job_executable: string = "Loading...";
  runfileSubscription: Subscription;
  stickyColumns = [
    "Iterblk",
    "Functional",
    "Frequency",
    "RWI Type",
    "Iterations",
  ];
  not_displayedColumns = ["Iters"];

  constructor(
    private projectService: CachedProjectService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.currentJobSubscription != null) {
      this.currentJobSubscription.unsubscribe();
      this.currentJobSubscription = null;
    }

    this.currentJobSubscription = this.projectService.currentJob.subscribe(
      (job) => {
        this.resetDisplayedGroups();
        this.scrollPos =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
        if (job == null) {
          return;
        }
        this.currentJob = job;
        this.job_name = job.name;
        this.job_executable = "Loading...";
        this.functionals = "Loading...";
        let project_id = job.projectId;
        let promise = this.projectService
          .getProjectById(project_id)
          .pipe(take(1))
          .toPromise();
        promise.then((res) => {
          if (res == null) {
            return;
          }
          this.project = res;
          this.getRunfilesByJobIds([this.currentJob.id]).then(() => {
            setTimeout(() => {
              window.scrollTo({
                top: this.scrollPos,
                left: 0,
                behavior: "smooth",
              });
            });
            this.functionals = "(" + this.getFunctionals().join(", ") + ")";
          });
        });

        if (this.runfileSubscription != null) {
          this.runfileSubscription.unsubscribe();
          this.runfileSubscription = null;
        }

        this.runfileSubscription = this.projectService
          .getRunfile(this.currentJob.id)
          .subscribe((runfile) => {
            let compressed_runfile = runfile.runfile;
            let runfileBuffer = new Buffer(compressed_runfile, "base64");
            // zlib.inflate(runfileBuffer, (err, data) => {
            let inflated = inflate(runfileBuffer, { to: "string" });
            if (!inflated.err && inflated) {
              let runfile = inflated.split("\n")[0];
              this.job_executable = runfile.split("(")[1].split(")")[0];
            }
          });
        this.getMiscData();
        this.getEpicModsComments();
        this.getEnvVars();
      }
    );
  }

  ngOnDestroy() {
    if (this.currentJobSubscription != null) {
      this.currentJobSubscription.unsubscribe();
      this.currentJobSubscription = null;
    }
  }

  async getRunfiles(start: number = 0, end: number = start + 1) {
    this.resetDataSources();
    if (this.project == null || start > end) {
      return;
    }
    let jobs = this.project.jobs.slice(start, end);
    let promises = [];
    for (let job of jobs) {
      promises.push(this.updateDataSource(job));
    }
    await Promise.all(promises);
    this.setDataSources();
  }

  async getRunfilesByJobIds(jobIds: string[]) {
    // console.log(jobIds)
    this.resetDataSources();
    if (this.project == null) {
      return;
    }
    let promises = [];
    for (let jobId of jobIds) {
      promises.push(
        this.updateDataSource(this.project.jobs.find((job) => job.id === jobId))
      );
    }
    await Promise.all(promises);
    this.setDataSources();
  }

  async updateDataSource(job: Job) {
    let observables = [];
    observables.push(
      this.projectService.getRunfileObject(
        job.id,
        this.rwi_displayedColumns_full
      )
    );
    observables.push(
      this.projectService.getRunfileObject(
        job.id,
        this.smoothing_displayedColumns_full
      )
    );
    observables.push(
      this.projectService.getRunfileObject(
        job.id,
        this.mutes_displayedColumns_full
      )
    );
    observables.push(
      this.projectService.getRunfileObject(
        job.id,
        this.density_displayedColumns_full
      )
    );
    observables.push(
      this.projectService.getRunfileObject(
        job.id,
        this.temp_displayedColumns_full
      )
    );
    observables.push(
      this.projectService.getRunfileObject(
        job.id,
        this.awi_displayedColumns_full
      )
    );
    observables.push(
      this.projectService.getRunfileObject(
        job.id,
        this.constraints_displayedColumns_full
      )
    );
    observables.push(
      this.projectService.getRunfileObject(
        job.id,
        this.boundary_displayedColumns_full
      )
    );
    observables.push(
      this.projectService.getRunfileObject(
        job.id,
        this.tracemanipulation_displayedColumns_full
      )
    );

    const results = await forkJoin(observables).pipe(take(1)).toPromise();
    this.updateTableData("rwi", results[0]);
    this.updateTableData("smoothing", results[1]);
    this.updateTableData("mutes", results[2]);
    this.updateTableData("density", results[3]);
    this.updateTableData("temp", results[4]);
    this.updateTableData("awi", results[5]);
    this.updateTableData("constraints", results[6]);
    this.updateTableData("boundary", results[7]);
    this.updateTableData("tracemanipulation", results[8]);
  }

  getMiscData() {
    this.getEpicModsData();
    this.getProblemData();
  }

  getEpicModsData() {
    this.epicmod_modelprops = "Loading...";
    this.epicmod_update = "Loading...";
    this.projectService
      .getRunfileObject(this.currentJob.id, this.epicmods_displayedColumns)
      .toPromise()
      .then((res) => {
        this.epicmod_modelprops = res.global["EPICMod_ModelProps"];
        this.epicmod_update = res.global["EPICMod_Update"];
      });
  }

  getProblemData() {
    this.probdims = "Loading...";
    this.equation = "Loading...";
    this.anisotropy = "Loading...";
    this.problem = "Loading...";
    this.projectService
      .getRunfileObject(this.currentJob.id, this.problem_displayedColumns)
      .toPromise()
      .then((res) => {
        this.probdims = res.global["Probdims"];
        this.equation = res.global["Equation"];
        this.anisotropy = res.global["Anisotropy"];
        this.problem = res.global["Problem"];
        if (this.anisotropy == "none") {
          this.anisotropy = "isotropic";
        } else if (this.anisotropy == "tti") {
          this.anisotropy = "anisotropic";
        }
      });
  }

  getFunctionals() {
    let functionals = this.rwi_data.map((d) => d["Functional"]);
    let rwis = this.rwi_data
      .map((d) => d["RWI Type"])
      .filter((d) => d != "-" && d != "N/A")
      .map((d) => "RWI " + d);
    functionals = functionals.concat(rwis);
    functionals = functionals.filter(
      (f, index) => functionals.indexOf(f) === index
    );
    return functionals;
  }

  getEpicModsComments() {
    if (
      this.currentJob.epicmod_comments == undefined ||
      this.currentJob.epicmod_comments == null ||
      this.currentJob.epicmod_comments == ""
    ) {
      this.epicmod_comments = {
        EPICMod_Update: "",
        EPICMod_ModelProps: "",
      };
    } else {
      this.epicmod_comments = JSON.parse(this.currentJob.epicmod_comments);
    }
  }

  getEnvVars() {
    this.envvars_wanted_slaves_found = [];
    this.envvars_wanted_scheduler_found = [];
    this.envvars = {};
    this.projectService
      .getEnvVariables(this.currentJob.id)
      .toPromise()
      .then((res) => {
        this.envvars = res.envvars;
        if (Object.keys(this.envvars).length > 0) {
          for (let envvar of Object.keys(this.envvars)) {
            if (!StandardEnvironmentVariables.includes(envvar)) {
              if (envvar.startsWith("SLAVES_")) {
                if (!this.envvars_wanted_slaves_found.includes(envvar))
                  this.envvars_wanted_slaves_found.push(envvar);
              } else if (envvar.startsWith("SCHEDULER_")) {
                if (!this.envvars_wanted_scheduler_found.includes(envvar))
                  this.envvars_wanted_scheduler_found.push(envvar);
              }
            }
          }
        }
      });
  }

  refreshEpicModsComments() {
    this.projectService
      ._getJobDetail(this.currentJob.id)
      .toPromise()
      .then((res) => {
        this.currentJob = res;
        this.getEpicModsComments();
      });
  }

  updateEpicModsComments() {
    this.projectService
      .updateJobEpicModComments(this.currentJob.id, this.epicmod_comments)
      .toPromise()
      .then((res) => {
        if (!res) console.error("Error updating epicmod comments");
      });
  }

  openEpicModsCommentsDialog(keyword) {
    let dialogRef = this.dialog.open(EpicmodCommentsComponent, {
      height: "320px",
      width: "400px",
      data: {
        epicmod_name: keyword,
        epicmod_comments: this.epicmod_comments[keyword],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.epicmod_comments[result.epicmod_name] = result.epicmod_comments;
        this.updateEpicModsComments();
      }
    });
  }

  resetDataSources() {
    this.rwi_data = [];
    this.smoothing_data = [];
    this.mutes_data = [];
    this.density_data = [];
    this.temp_data = [];
    this.tracemanipulation_data = [];
    this.combined_data = [];
    this.awi_data = [];
    this.constraints_data = [];
    this.boundary_data = [];
  }

  setDataSources() {
    this.checkCollapse("rwi");
    this.checkCollapse("smoothing");
    this.checkCollapse("mutes");
    this.checkCollapse("density");
    this.checkCollapse("temp");
    this.checkCollapse("tracemanipulation");
    this.checkCollapse("awi");
    this.checkCollapse("constraints");
    this.checkCollapse("boundary");
    this.combineTables();
    this.updateDisplayedGroups();
    this.tableAvailable = true;
  }

  setDisplayedGroups(table: string) {
    let group: { name: string; params: string[] }[] = [
      { name: "info", params: this.stickyColumns },
    ].concat(this[table + "_groups"]);
    let displayedGroups: GroupData[] = [];
    for (let column of this.getDisplayedColumnsWithStickyColumns(table)) {
      let group_name: string;
      if (group.some((g) => g.params.includes(column))) {
        group_name = group.find((g) => g.params.includes(column)).name;
      } else {
        group_name = "";
      }
      if (
        displayedGroups.length > 0 &&
        displayedGroups[displayedGroups.length - 1].name == group_name
      ) {
        displayedGroups[displayedGroups.length - 1].colspan += 1;
      } else {
        displayedGroups.push({ name: group_name, colspan: 1 });
      }
    }
    this[table + "_displayedGroups"] = displayedGroups;
  }

  resetDisplayedGroups() {
    for (let table of this.tables.concat(["combined"])) {
      this[table + "_displayedGroups"] = [];
    }
  }

  updateDisplayedGroups() {
    this.setDisplayedGroups("rwi");
    this.setDisplayedGroups("smoothing");
    this.setDisplayedGroups("mutes");
    this.setDisplayedGroups("density");
    this.setDisplayedGroups("temp");
    this.setDisplayedGroups("tracemanipulation");
    this.setDisplayedGroups("awi");
    this.setDisplayedGroups("constraints");
    this.setDisplayedGroups("boundary");
    this.setDisplayedGroups("combined");
  }

  combineTables() {
    // this.combined_data = [];
    let combined_data = [];
    for (let i = 0; i < this.rwi_data.length; i++) {
      let row = {};
      for (let table of this.tables) {
        for (let column of this[table + "_displayedColumns_full"]) {
          row[column] = this[table + "_data"][i][column];
        }
      }
      for (let column of this.stickyColumns) {
        row[column] = this.rwi_data[i][column]; // anyone should work
      }
      combined_data.push(row);
    }
    this.combined_data = Array.from(combined_data);
    this.checkCollapse("combined");
  }

  checkCollapse(table: string) {
    let displayedColumns: string[] = Array.from(
      this[table + "_displayedColumns_full"]
    );
    this[table + "_collapsedColumns"] = [];
    this[table + "_displayedColumns"] = displayedColumns;
    for (let column of displayedColumns) {
      if (this.stickyColumns.includes(column)) {
        // never collapse those columns
        continue;
      }
      let values = this[table + "_data"].map((d) => d[column]);
      if (values.every((v) => v === values[0])) {
        this[table + "_collapsedColumns"].push(column);
        this[table + "_displayedColumns"] = this[
          table + "_displayedColumns"
        ].filter((c) => c !== column);
      }
    }
  }

  updateTableData(table: string, res) {
    let table_data = [];
    if (res == null) {
      let row = {};
      for (let param of this[table + "_displayedColumns_full"]) {
        row[param] = "N/A";
        row["Iterblk"] = "N/A";
        // row['Job'] = job.name.substring(0,10);
        this[table + "_data"].push(row);
      }
      return;
    }
    let global = res.global;
    let blocks = res.blocks;
    for (let index = 0; index < blocks.length; index++) {
      let row = {};
      let block = blocks[index];
      // console.log(block)
      for (let param of this[table + "_displayedColumns_full"]) {
        if (!Object.keys(block).includes(param)) {
          let value = global[param];
          row[param] = value;
        } else {
          let value = block[param];
          row[param] = value;
        }
        if (Array.isArray(row[param])) {
          if (param == "Offset Plane Normal" && row[param][0] != "None") {
            row[param] = row[param][0]
              .split(" ")
              .filter((x) => x != "")
              .map((x) => Number(x).toFixed(2))
              .join(" ");
          } else {
            let reduced =
              this.tryParseNumber(row[param][0]) != null
                ? this.tryParseNumber(row[param][0]).toFixed(2)
                : row[param][0];
            for (let value of row[param].slice(1)) {
              let parsed =
                this.tryParseNumber(value) != null
                  ? this.tryParseNumber(value).toFixed(2)
                  : value;
              reduced = reduced + ", " + parsed;
            }
            row[param] = reduced;
            if (Integers.includes(param)) {
              row[param] = Math.round(Number(row[param]));
            }
          }
        }
        if (row[param] == null) {
          row[param] = "-";
        }
      }
      row["Iterblk"] = index + 1;
      if (row["Iterations"] == "-" && row["Iters"]! != "-") {
        row["Iterations"] = row["Iters"];
      }
      for (let merge of this[table + "_merge"]) {
        let value = merge.params.map((p) => row[p]).join(merge.separator);
        row[merge.name] = value;
      }
      table_data.push(row);
    }
    this[table + "_data"] = Array.from(table_data);
    // console.log(JSON.stringify(this[table+'_data']))
  }

  getDisplayedColumnsWithStickyColumns(table: string) {
    let columns: string[];
    if (this.show_collapsed) {
      columns = ["Iterblk"].concat(this[table + "_displayedColumns_full"]);
    } else {
      columns = ["Iterblk"].concat(this[table + "_displayedColumns"]);
    }
    columns = columns.filter((c) => !this.not_displayedColumns.includes(c));
    return this.mergeColumns(table, columns);
  }

  getDisplayedColumns(table: string) {
    let columns: string[];
    if (this.show_collapsed) {
      columns = this[table + "_displayedColumns_full"].slice(
        this.stickyColumns.length - 1
      );
    } else {
      columns = this[table + "_displayedColumns"].slice(
        this.stickyColumns.length - 1
      );
    }
    columns = columns.filter((c) => !this.not_displayedColumns.includes(c));
    return this.mergeColumns(table, columns);
  }

  mergeColumns(table: string, columns: string[]) {
    for (let merge of this[table + "_merge"]) {
      let index = columns.findIndex((c) => merge.params.includes(c));
      if (index != -1) {
        let left = columns.slice(0, index);
        let right = columns.slice(index + merge.params.length);
        let result = left.concat([merge.name]).concat(right);
        columns = result;
      }
    }
    return columns;
  }

  getColumnWidth(table: string, column: string) {
    let data = this[table + "_data"];
    let max = column.length;
    for (let row of data) {
      if (row[column] == null) {
        continue;
      } else {
        if (row[column].toString().length > max) {
          max = row[column].toString().length;
        }
      }
    }
    return max * 10;
  }

  addToCombined(table: string) {
    if (this.tables_combined[table] == true) {
      return;
    }
    let data = this[table + "_data"];
    let combined = Array.from(this.combined_data);
    let new_combined = [];
    if (this.combined_displayedColumns_full.length == 0) {
      // no table combined yet
      new_combined = data;
      this.combined_displayedColumns_full = Array.from(
        this[table + "_displayedColumns_full"]
      ); // would include always
    } else {
      for (let row of data) {
        let combined_row = combined.find(
          (combined_row) => combined_row["Iterblk"] == row["Iterblk"]
        );
        if (combined_row == null) {
          console.error(
            "Combined table does not have the same rows as the table to be added"
          );
          throw new Error(
            "Combined table does not have the same rows as the table to be added"
          );
        } else {
          for (let param of this[table + "_displayedColumns_full"]) {
            combined_row[param] = row[param];
          }
          new_combined.push(combined_row);
          console.log(combined_row);
        }
      }
      let displayedColumns: string[] = Array.from(
        this[table + "_displayedColumns_full"]
      );
      for (let column of displayedColumns) {
        if (!this.combined_displayedColumns_full.includes(column)) {
          this.combined_displayedColumns_full.push(column);
        }
      }
    }
    this.combined_data = new_combined;
    this.setDisplayedGroups("combined");
    this.tables_combined[table] = true;
  }

  removefromCombined(table: string) {
    // remove table from combined table, probably don't need to worry about data, just displayedColumns.
    if (this.tables_combined[table] == false) {
      return;
    }
    let _combinedColumns = this.combined_displayedColumns_full;
    let combinedTables = [];
    let combinedColumns = [];
    for (let t of this.tables) {
      if (t != table) {
        if (this.tables_combined[t] == true) {
          combinedTables.push(t);
        }
      }
    }
    for (let column of _combinedColumns) {
      if (
        combinedTables.some((t) => {
          return this[t + "_displayedColumns_full"].includes(column);
        }) ||
        Always.includes(column)
      ) {
        combinedColumns.push(column);
      }
    }
    let combined = Array.from(this.combined_data);
    let new_combined = [];
    for (let row of combined) {
      let new_row = {};
      // for (let param of ['Job', 'Iterblk'].concat(combinedColumns)) {
      for (let param of ["Iterblk"].concat(combinedColumns)) {
        new_row[param] = row[param];
      }
      new_combined.push(new_row);
    }
    this.tables_combined[table] = false;
    this.combined_data = new_combined;
    this.combined_displayedColumns_full = combinedColumns;
    this.setDisplayedGroups("combined");
  }

  toggleTable(table: string) {
    console.log(table);
    if (this.tables_combined[table] == true) {
      this.removefromCombined(table);
    } else {
      this.addToCombined(table);
    }
    this.checkCollapse("combined");
  }

  tryParseNumber(value: any) {
    let num = Number(value);
    if (Number.isNaN(num)) {
      return null;
    } else {
      return num;
    }
  }

  toggleTableHeight(table: string) {
    if (this[table + "_tableHeight"] == this.default_tableHeight) {
      this[table + "_tableHeight"] = "none";
    } else {
      this[table + "_tableHeight"] = this.default_tableHeight;
    }
  }

  getTableHeight(table: string) {
    return this[table + "_tableHeight"];
  }

  getData(table: string) {
    return this[table + "_data"];
  }

  getDisplayedGroups(table: string) {
    return this[table + "_displayedGroups"];
  }

  getMappedName(table: string, name: string) {
    for (let group of this[table + "_groups"]) {
      if (group.params.includes(name)) {
        if (
          group.nameMap != null &&
          Object.keys(group.nameMap).includes(name)
        ) {
          return group.nameMap[name];
        } else {
          return name;
        }
      }
    }
    return name;
  }

  getFullName(table, column) {
    if (this[table + "_merge"].map((m) => m.name).includes(column)) {
      let merge = this[table + "_merge"].find((m) => m.name == column);
      return merge.params.join(merge.separator);
    } else {
      return column;
    }
  }

  commentTooLong(comment: string, length: number) {
    return comment.length > length;
  }
}
