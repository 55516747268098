import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { JobDetail } from "../../../models/jobDetail";
import { ShotSettings } from "../../../models/shotSettings";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { SliceSettings } from "../../services/slice-settings.service";
import { StoredSettingsService } from "../../services/stored-settings.service";

@Component({
  selector: "app-shot-container-menu",
  templateUrl: "./shot-container-menu.component.html",
  styleUrls: ["./shot-container-menu.component.less"],
})
export class ShotContainerMenuComponent implements OnInit {
  @Input() jobDetail: JobDetail;
  @Input() settingsKey: string;
  @Input() selectedShot: number;
  @Input() availableShotIds: number[];
  @Input() iterationsOfSameShot: number[];
  @Input() switchIteration: number;

  @Output() menuChanged = new EventEmitter<number>();
  @Output() iterationChanged = new EventEmitter<number>();
  iteration: number;
  shotId: number;
  constructor(private storedSettingsService: StoredSettingsService) {}

  ngOnInit() {
    this.restoreSettings();
  }
  onShotSelected(shotId: number) {
    this.shotId = shotId;
    this.storeSettings();
    this.menuChanged.emit(shotId);
  }
  onIterationSwitch(iteration: number) {
    if (iteration === this.iteration) {
      return;
    }
    const nextIteration = iteration;
    this.iterationChanged.emit(this.iteration);
    this.iteration = nextIteration;

    this.storeSliceSettings(iteration);
  }
  onIterationBack() {
    const backIteration = this.iteration;
    this.iteration = this.switchIteration;
    this.iterationChanged.emit(backIteration);

    this.storeSliceSettings(this.iteration);
  }
  private storeSettings() {
    let currentSettings = this.storedSettingsService.getSettings<ShotSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Shot
    );
    this.storedSettingsService.setSettings<ShotSettings>(
      this.settingsKey,

      {
        ...currentSettings,
        selectedShot: this.shotId,
      },
      SettingTypes.Shot
    );

    this.storedSettingsService.settingsUpdated([SettingTypes.Shot]);
  }
  private restoreSettings() {
    var storedSliceSettings =
      this.storedSettingsService.getSettings<SliceSettings>(
        this.settingsKey,
        this.jobDetail.id,
        SettingTypes.SliceSettings,
        this.jobDetail.projectId
      );
    this.iteration = storedSliceSettings.iteration;
  }
  private storeSliceSettings(iteration) {
    var storedSliceSettings =
      this.storedSettingsService.getSettings<SliceSettings>(
        this.settingsKey,
        this.jobDetail.id,
        SettingTypes.SliceSettings
      );
    this.storedSettingsService.setSettings<SliceSettings>(
      this.settingsKey,
      {
        ...storedSliceSettings,
        iteration: iteration,
      },
      SettingTypes.SliceSettings
    );
    this.storedSettingsService.settingsUpdated([SettingTypes.SliceSettings]);
  }
}
