import { Component, OnInit, ViewChild } from "@angular/core";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { DragulaService, DragulaOptions } from "ng2-dragula";
import { JobDetailDialog } from "../../../models/jobDetailDialog";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CachedUserService } from "../../../shared/services/cached-user.service";
import { MatTableDataSource, MatTable } from "@angular/material/table";
import { UntypedFormGroup, UntypedFormControl, AbstractControl, Form } from "@angular/forms";
import { Observable, Subscription, merge } from "rxjs";
import { Project } from "../../../models/project";
import { RebuildTableComponent } from "../../project/dialogs/rebuild-project-table/rebuild-project-table.component";
import { orderBy } from "lodash";

import { ParamsTree, AllParams } from "../../project/tables/table_params";

interface CollapsibleObject {
  collapsed: Boolean;
  children: any;
}

@Component({
  selector: "app-parameter-overview",
  templateUrl: "./parameter-overview.component.html",
  styleUrls: ["./parameter-overview.component.css"],
})
export class ParameterOverviewComponent implements OnInit {
  private _userSubscription: Subscription;
  results;
  is_jobs_loading: boolean = true;
  all_jobs_selected: boolean = true;
  job_names_obj: Object;
  selectedJobs: Array<string> = [];
  selectedJobIds: Array<string> = [];
  dragula_subscription = new Subscription();
  draggables = "draggables";
  query: string = "";
  fields: Array<string> = [];
  sort_by: string = "job_name";
  sort_order: "asc" | "desc" = "asc";
  used_parameters: Array<string> = ["job_name"];
  all_used_parameters: Array<string> = ["job_name"];
  project_id: string = "";
  // unused_toggle1 = false;
  unused_toggle = false;
  allDisplayedColumns = [];
  is_getting_parameter_table: boolean = false;
  dataSource: Array<Object> = [];
  filteredData: Array<Object> = [];
  // table_data: Array<Object> = [];
  paramsTree = ParamsTree;
  displayedColumns = [];
  project_name: string = "";
  options = [
    "Used Parameters",
    "AWI",
    "RWI",
    "Smoothing",
    "Mutes",
    "Density",
    "Temp",
    "Constraints",
    "Boundary",
    "IO Paths",
    "Custom",
  ];
  form: UntypedFormGroup = new UntypedFormGroup({
    _filtermode: new UntypedFormControl(this.options[0]),
  });
  previous_mode: string = "";
  keyToColBox: Map<string, UntypedFormControl> = new Map();
  columnDefinitions: Map<string, Object> = new Map();
  columnDefinitionsFilteredKeys = [];
  displayedColumnsFilteredKeys = [];
  is_exporting_as_csv: boolean = false;
  columnFilters: Map<string, string[]>;
  showJobs: Array<string> = [];
  // awi_radio:FormControl = new FormControl(true);
  // fwi_radio:FormControl = new FormControl(false);
  // custom_radio:FormControl = new FormControl(false);
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: CachedProjectService,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private userService: CachedUserService
  ) {
    this.projectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project_id = project.id;
        this.project_name = project.name;
        this.get_parameter_table(this.project_id);
      }
    });

    this.dragula_subscription = this.dragulaService
      .drop()
      .subscribe(({ name }) => {
        (this.dragulaService.find(name).drake as any).cancel(true);
      });

    this.columnFilters = new Map<string, string[]>();
  }
  onSearchJobs(event: Event) {
    const el = event.target as HTMLInputElement;
    this.showJobs = this.getJobNames().filter((name) =>
      name.toLowerCase().includes(el.value.toLowerCase())
    );
  }
  toggleAllSelection() {
    let new_jobs: Array<string>;
    this.all_jobs_selected = !this.all_jobs_selected;
    if (this.all_jobs_selected) {
      new_jobs = ["all", ...Object.keys(this.job_names_obj)];
    } else {
      new_jobs = [];
    }
    this.selectedJobs = new_jobs;
  }
  show_matching_jobs() {
    let unique_rows = [];
    this.dataSource.forEach((row) => {
      let new_row = {};
      for (const col of Object.keys(row)) {
        if (["job_id", "job_name", "id", "full_job_name"].includes(col)) {
          continue;
        }
        new_row[col] = row[col];
      }
      unique_rows.push(new_row);
    });

    // console.log("start:",unique_rows)
    this.selectedJobIds = [];
    this.selectedJobs = [];
    this.dataSource = [];
    let allColumns: Array<string> = this.displayedColumns.concat(["job_id"]);
    for (let result of this.results) {
      let cols_matching = true;
      for (const unique_row of unique_rows) {
        cols_matching = true;
        for (const col of Object.keys(unique_row)) {
          if (unique_row[col] != result[col]) {
            cols_matching = false;
            break;
          }
        }
        if (cols_matching) {
          break;
        }
      }
      if (!cols_matching) {
        continue;
      }

      // console.log("matched:",result["job_name"])
      if ("job_name" in result) {
        this.selectedJobs.push(result["job_name"]);
      }
      this.selectedJobIds.push(result["job_id"]);
      var obj = {};
      allColumns.forEach((key) => {
        // this.keyToColBox(key).
        obj[key] = result[key];
      });
      this.dataSource.push(obj);
    }
  }
  job_filter() {
    this.dataSource = [];
    this.columnDefinitionsFilteredKeys = this.getKeys(this.columnDefinitions);
    let allColumns: Array<string> = this.displayedColumns.concat(["job_id"]);
    this.displayedColumnsFilteredKeys = [...this.displayedColumns];
    var param_map = {};
    let varying_params_set = new Set<string>();
    this.selectedJobIds = [];
    // console.log("selected jobs is:",this.selectedJobs)
    // console.log("allcols is", allColumns)
    for (let result of this.results) {
      if (
        !("job_name" in result) ||
        this.selectedJobs.includes(result["job_name"])
      ) {
        if ("job_id" in result) {
          this.selectedJobIds.push(result["job_id"]);
        }
        var obj = {};
        allColumns.forEach((key) => {
          // this.keyToColBox(key).
          obj[key] = result[key];
          if (!varying_params_set.has(key)) {
            if (key in param_map) {
              if (param_map[key] != result[key]) {
                varying_params_set.add(key);
                delete param_map[key];
              }
            } else {
              param_map[key] = result[key];
            }
          }
        });
        this.dataSource.push(obj);
      }
    }

    this.clearTableFiltering();
    varying_params_set.delete("job_name");
    this.used_parameters = Array.from(varying_params_set.values()).sort();
    this.used_parameters.unshift("job_name");
  }
  getJobNames() {
    if (this.job_names_obj) {
      return Object.keys(this.job_names_obj);
    } else {
      return [];
    }
  }
  isDisplayed(key) {
    return this.displayedColumns.indexOf(key) > -1;
  }
  toUpper(str: string) {
    return str.toUpperCase();
  }
  toggle_extra_cols() {
    let displayedColumns = [];

    if (this.unused_toggle) {
      displayedColumns = [...this.allDisplayedColumns];
    } else {
      this.allDisplayedColumns.forEach((key) => {
        if (this.used_parameters.includes(key)) {
          displayedColumns.push(key);
        }
      });
    }

    this.dataSource = [];
    this.fields.forEach((key) => {
      this.form.controls[key].setValue(false);
    });
    this.displayedColumns = [...displayedColumns];
    this.displayedColumns.forEach((key) => {
      this.form.controls[key].setValue(true);
    });
    let allColumns: Array<string> = displayedColumns.concat(["job_id"]);
    var param_map = {};
    let varying_params_set = new Set<string>();
    this.selectedJobIds = [];
    for (let result of this.results) {
      if (
        !("job_name" in result) ||
        this.selectedJobs.includes(result["job_name"])
      ) {
        if ("job_id" in result) {
          this.selectedJobIds.push(result["job_id"]);
        }
        var obj = {};
        allColumns.forEach((key) => {
          obj[key] = result[key];
          if (!varying_params_set.has(key)) {
            if (key in param_map) {
              if (param_map[key] != result[key]) {
                varying_params_set.add(key);
                delete param_map[key];
              }
            } else {
              param_map[key] = result[key];
            }
          }
        });
        this.dataSource.push(obj);
      }
    }

    this.clearTableFiltering();
    varying_params_set.delete("job_name");
    this.used_parameters = Array.from(varying_params_set.values()).sort();
    this.used_parameters.unshift("job_name");
    this.displayedColumnsFilteredKeys = [...displayedColumns];
    this.unused_toggle = !this.unused_toggle;
  }
  apply_filters() {
    let displayedColumns: Array<string> = this.displayedColumns;
    if (this.previous_mode != this.form.controls._filtermode.value) {
      this.unused_toggle = false;
      switch (this.form.controls._filtermode.value) {
        case "Model":
          displayedColumns = [
            "job_name",
            "dx",
            "nx1",
            "nx2",
            "nx3",
            "equation",
            "kernel",
            "btop",
            "bbottom",
            "bleft",
            "bright",
            "bfront",
            "bback",
            "extra_top",
            "extra_bottom",
            "extra_left",
            "extra_right",
            "extra_front",
            "extra_back",
            "units",
            "domain",
          ];
          break;
        case "RWI":
          displayedColumns = [
            "job_name",
            "rwi_vs_iters",
            "rwi_bg_iters",
            "rwi_cut_factor",
            "rwi_restart_vs",
            "rwi_shift_factor",
            "rwi_add_incident",
            "rwi_agc",
            "rwi_start_time",
            "rwi_end_time",
            "rwi_shot_autoscaling",
            "rwi_timetweak_ratefact",
            "rwi_timetweak_maxfact",
            "rwi_spatial",
            "rwi_grad_contrib",
            "rwi_gradmaskang",
            "vs_low_cut",
            "vs_high_cut",
            "rwi_low_cut",
            "rwi_high_cut",
          ];
          break;
        case "Smoothing":
          displayedColumns = [
            "job_name",
            "modelsmoothx1",
            "modelsmoothx2",
            "modelsmoothx3",
            "modelsmoothcut",
            "modelsmoothramp",
            "modelsmoothcutvel",
            "smoothx1",
            "smoothx2",
            "smoothx3",
            "smoothcut",
            "smoothramp",
            "struct_smooth",
            "rcvrline_smoothdist",
            "rwi_smooth_x1",
            "rwi_smooth_x2",
            "rwi_smooth_x3",
            "rwi_smoothcut",
            "rwi_smoothramp",
            "rwi_struct_smooth",
            "rwi_presmooth_clip_lo",
            "rwi_presmooth_clip_hi",
            "rwi_presmooth_clip_smthfact",
            "rwi_smthsprdclip_low",
            "rwi_smthsprdclip_high",
            "rwi_smthsprdclip_smthfact",
            "rwi_rcvrline_smoothdist",
            "rwi_median_x1",
            "rwi_median_x2",
            "rwi_median_x3",
            "finalsmoothx1",
            "finalsmoothx2",
            "finalsmoothx3",
            "finalsmoothcut",
            "finalsmoothramp",
            "finalmodelsmoothcutvel",
          ];
          break;
        case "Mutes":
          displayedColumns = [
            "job_name",
            "offset_plane_normal",
            "rwi_cut_taper",
            "rwi_offset_cut",
            "rwi_offset_grow",
            "outer_mute_start_time",
            "outer_mute_growth",
            "outer_mute_taper_width",
            "inner_mute_start_time",
            "inner_mute_growth",
            "inner_mute_taper_width",
          ];
          break;
        case "Density":
          displayedColumns = [
            "job_name",
            "density_ramps",
            "min_gardner_vel",
            "max_gardner_vel",
            "salt_velocity",
            "salt_density",
            "water_mincells",
            "water_maxcells",
            "water_velocity",
            "water_density",
          ];
          break;
        case "Temp":
          displayedColumns = [
            "job_name",
            "starttime",
            "endtime",
            "minoffset",
            "maxoffset",
            "amplitude",
            "normalise",
            "timetweak_ratefact",
            "timetweak_maxfact",
            "shot_autoscaling",
            "source_scaling",
            "slowness",
            "spatial",
            "conj_grad",
            "accel_grad",
            "reduce_oscillation",
            "gradmaskang",
            "line_search_method",
            "line_search_max_steps",
            "badresidweight",
          ];
          break;
        case "Constraints":
          displayedColumns = [
            "job_name",
            "velcutoff",
            "velcon_min",
            "auto_minvel",
            "velcon_max",
            "auto_maxvel",
            "window",
            "w1",
            "w2",
            "w3",
            "w4",
          ];
          break;
        case "Boundary":
          displayedColumns = [
            "job_name",
            "extra_left",
            "extra_right",
            "extra_front",
            "extra_back",
            "extra_top",
            "extra_bottom",
            "borderx1",
            "borderx2",
            "borderx3",
            "bleft",
            "bright",
            "bfront",
            "bback",
            "btop",
            "bbottom",
          ];
          break;
        case "IO Paths":
          displayedColumns = [
            "job_name",
            "aws_path",
            "sourcesig_locator",
            "geometry_locator",
            "traces_locator",
            "epicmod_locator",
            "checkpoint_locator",
            "qc_dump_locator",
          ];
          break;
        case "AWI":
          displayedColumns = [
            "job_name",
            "awi_normfilt",
            "awi_atype",
            "awi_rtype",
            "awi_scale",
            "awi_steptype",
            "awi_top",
            "awi_protectspike",
          ];
          break;
        default:
        case "Used Parameters":
          displayedColumns = [...this.all_used_parameters];
          break;
      }
    } else {
      this.fields.forEach((key) => {
        if (!this.isDisplayed(key)) {
          if (this.keyToColBox[key].value) {
            displayedColumns.push(key);
            this.form.controls._filtermode.setValue("Custom");
          }
        } else if (!this.keyToColBox[key].value) {
          const index = displayedColumns.indexOf(key, 0);
          if (index > -1) {
            displayedColumns.splice(index, 1);
          }
          this.form.controls._filtermode.setValue("Custom");
        }
      });
    }
    // this.unused_toggle = false;
    //to check for the next time we select
    this.previous_mode = this.form.controls._filtermode.value;

    this.dataSource = [];
    this.fields.forEach((key) => {
      this.form.controls[key].setValue(false);
    });
    this.columnDefinitionsFilteredKeys = this.getKeys(this.columnDefinitions);
    this.displayedColumns = displayedColumns;
    this.allDisplayedColumns = [...displayedColumns];
    this.displayedColumns.forEach((key) => {
      this.form.controls[key].setValue(true);
    });

    let allColumns: Array<string> = displayedColumns.concat(["job_id"]);
    this.displayedColumnsFilteredKeys = [...this.displayedColumns];
    this.selectedJobIds = [];
    var param_map = {};
    let varying_params_set = new Set<string>();
    for (let result of this.results) {
      if (
        !("job_name" in result) ||
        this.selectedJobs.includes(result["job_name"])
      ) {
        if ("job_id" in result) {
          this.selectedJobIds.push(result["job_id"]);
        }
        var obj = {};
        allColumns.forEach((key) => {
          // this.keyToColBox(key).
          obj[key] = result[key];
          if (!varying_params_set.has(key)) {
            if (key in param_map) {
              if (param_map[key] != result[key]) {
                varying_params_set.add(key);
                delete param_map[key];
              }
            } else {
              param_map[key] = result[key];
            }
          }
        });
        this.dataSource.push(obj);
      }
    }

    this.clearTableFiltering();

    varying_params_set.delete("job_name");
    this.used_parameters = Array.from(varying_params_set.values()).sort();
    this.used_parameters.unshift("job_name");
  }

  getSnakeCase(item: String) {
    let temp = item.toLowerCase();
    while (temp.indexOf(" ") != -1) {
      temp = temp.replace(" ", "_");
    }
    return temp;
  }

  deselect_all() {
    Object.keys(this.columnDefinitions).forEach((key) => {
      if (!["id", "job_id", "project_id", "job_name"].includes(key)) {
        this.form.controls[key].setValue(false);
      }
    });
    this.apply_filters();
  }

  getKeys(map) {
    return Array.from(Object.keys(map));
  }

  getColObj(key) {
    return {
      def: key,
      label: this.convertHeader(key),
      hide: this.keyToColBox[key].value,
    };
  }

  arraysEqual<T>(a: T[], b: T[]): boolean {
    // If lengths are different, arrays are not equal
    if (a.length !== b.length) {
      // console.log("LEngths are different!", a.length, b.length)
      return false;
    }

    // Check each element for equality
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        // console.log("Differnt at", i, a[i], b[i])
        return false;
      }
    }

    // If all elements are equal, arrays are equal
    return true;
  }
  get_form(fields: Array<string>) {
    this.keyToColBox = new Map();
    this.columnDefinitions = new Map();
    let obj = {};
    let colObj = {};
    obj["_filtermode"] = new UntypedFormControl(this.options[0]);
    fields.forEach((key) => {
      var fc: UntypedFormControl;
      if (key in ["id", "job_id", "project_id"]) {
        fc = new UntypedFormControl(false);
      } else {
        fc = new UntypedFormControl(true);
      }
      obj[key] = fc;
      this.keyToColBox[key] = fc;
      this.columnDefinitions[key] = this.getColObj(key);

      let comparingKey = this.columnDefinitions[key]["label"].toLowerCase();
      while (comparingKey.indexOf(" ") != -1) {
        comparingKey = comparingKey.replace(" ", "");
      }

      if (!AllParams.includes(comparingKey)) {
        ParamsTree.Misc.children.push(
          this.columnDefinitions[key]["label"].toLowerCase()
        );
      }
    });
    this.form = new UntypedFormGroup(obj);
    // console.log(this.columnDefinitions)

    let objs: Observable<boolean>[] = this.fields.map(
      (key) => this.keyToColBox[key].valueChanges
    );

    merge(...objs).subscribe((v) => {
      // console.log("hit checkbox")
      this.fields.forEach((key) => {
        // console.log(key,this.keyToColBox[key].value)
        this.columnDefinitions[key].hide = this.keyToColBox[key].value;
      });
      // console.log("done")
    });
    // console.log("made form")
    // console.log("displayed columns before making it: ")
    // console.log(this.displayedColumns)
  }

  get_parameter_table(project_id, rebuild = false) {
    this.is_getting_parameter_table = true;
    // console.log("getting table")
    this.is_jobs_loading = true;
    this.projectService
      .getParameterTable(project_id, rebuild)
      .toPromise()
      .then((data) => {
        // this.dataSource = []
        this.is_getting_parameter_table = false;
        let results = data["results"];
        this.results = results;
        // console.log("received data:", this.results);
        let fields = Array.from(Object.keys(results[0])).sort((one, two) => {
          return one < two ? -1 : 1;
        });
        fields.push("full_job_name");
        if (!this.arraysEqual(this.fields, fields)) {
          this.fields = fields;
          this.get_form(this.fields);
        }
        // this.displayedColumns = this.fields
        // const index = this.fields.indexOf("job_id", 0);
        // if (index > -1) {
        //   this.fields.splice(index, 1);
        // }
        this.dataSource = [];
        this.job_names_obj = Object();
        this.selectedJobs = ["all"];
        var param_map = {};
        let varying_params_set = new Set<string>();
        for (let result of results) {
          if ("job_name" in result) {
            result["full_job_name"] = result["job_name"];
          }
          var obj = {};
          // console.log(result)
          this.fields.forEach((key) => {
            obj[key] = result[key];
            if (key == "job_name") {
              this.selectedJobs.push(result[key]);
              this.job_names_obj[result[key]] = true;
            }

            // if (result[key] != null) {
            if (!varying_params_set.has(key)) {
              if (key in param_map) {
                if (param_map[key] != result[key]) {
                  varying_params_set.add(key);
                  delete param_map[key];
                }
              } else {
                param_map[key] = result[key];
              }
            }
          });
          this.dataSource.push(obj);
        }
        this.showJobs = this.getJobNames();
        this.is_jobs_loading = false;
        varying_params_set.add("full_job_name");
        varying_params_set.delete("job_name");
        this.used_parameters = Array.from(varying_params_set.values()).sort();
        this.used_parameters.unshift("job_name");
        this.all_used_parameters = [...this.used_parameters];
        // console.log("used_params", this.all_used_parameters)
        // console.log("param_position:",param_position)
        // console.log("used parameters:", this.used_parameters)
        this.apply_filters();
        this.clearTableFiltering();
        // console.log("Finish.")
        // this.dataSource = this.table_data;
      });
  }
  _get_project_table(project_id, rebuild = false) {
    this.get_parameter_table(project_id, (rebuild = rebuild));
    // this.is_getting_parameter_table = true;
    // // console.log("getting uncached table")
    // this.projectService._getParameterTable(project_id, rebuild).toPromise().then(data => {
    //   // console.log("received data");
    //   // this.dataSource = []
    //   this.is_getting_parameter_table = false;
    //   let results = data['results'];
    //   // console.log(results)
    //   this.results = results;
    //   let fields = Array.from(Object.keys(results[0])).sort((one, two) => {
    //     return one < two ? -1 : 1
    //   });

    //   if (!this.arraysEqual(this.fields, fields)) {
    //     this.fields = fields
    //     this.get_form(this.fields);
    //   }
    //   // this.displayedColumns = this.fields
    //   // const index = this.fields.indexOf("job_id", 0);
    //   // if (index > -1) {
    //   //   this.fields.splice(index, 1);
    //   // }
    //   this.dataSource = [];
    //   for (let result of results) {
    //     var obj = {};
    //     this.fields.forEach((key) => {
    //       obj[key] = result[key]
    //     });
    //     this.dataSource.push(obj);
    //   }
    //   this.apply_filters();
    //   // console.log("Finish.")
    //   // this.dataSource = this.table_data;
    // });
  }

  ngOnInit() {
    this._userSubscription = this.userService.userDetail.subscribe((d) => {
      if (d && !(d.isStaff || d.email === "testuser01@s-cube.com"))
        this.router.navigateByUrl("/");
    });
  }

  searchParameters(query) {
    let temp = [];
    if (query.length > 0) {
      this.getKeys(this.columnDefinitions).forEach((key) => {
        if (
          this.columnDefinitions[key].label
            .toLowerCase()
            .search(query.toLowerCase()) != -1
        )
          temp.push(key);
      });
      this.columnDefinitionsFilteredKeys = temp;

      temp = [];
      this.displayedColumns.forEach((key) => {
        if (key.replaceAll("_", " ").search(query.toLowerCase()) != -1)
          temp.push(key);
      });
      this.displayedColumnsFilteredKeys = temp;
    } else {
      this.columnDefinitionsFilteredKeys = this.getKeys(this.columnDefinitions);
      this.displayedColumnsFilteredKeys = this.displayedColumns;
    }
  }

  onSearchSub(event) {
    this.query = event.target.value;
    this.searchParameters(event.target.value);
  }

  ngOnDestroy() {
    this.dragula_subscription.unsubscribe();
  }
  // cbValues;

  /**
   * Control column ordering and which columns are displayed.
   */
  // columnDefinitions = this.fields.map(key=>this.getColObj(key));

  getDisplayedColumns(): string[] {
    return Array.from(this.columnDefinitions.values())
      .filter((cd: any) => cd.hide)
      .map((cd: any) => cd.def);
  }

  onCollapsibleClickHandler(
    categoryObject: CollapsibleObject,
    categoryName: String,
    event: Event
  ) {
    if (categoryObject.collapsed) {
      categoryObject.collapsed = false;
      const node = event.target["nextSibling"];
      node["nextSibling"]["style"]["display"] = "none";
    } else {
      categoryObject.collapsed = true;
      const node = event.target["nextSibling"];
      node["nextSibling"]["style"]["display"] = "block";
    }
  }

  convertHeader(s) {
    var splitStr = s.toLowerCase().split("_");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }

  sort_table(sort_by_column: string, direction?: "asc" | "desc") {
    this.sort_by = sort_by_column;

    if (direction) {
      this.sort_order = direction;
    } else {
      this.sort_order = this.sort_order == "asc" ? "desc" : "asc";
    }

    this.filteredData = orderBy(
      this.filteredData,
      [sort_by_column],
      this.sort_order
    );
  }

  rebuild_parameter_table(project_id) {
    this.dialog
      .open(RebuildTableComponent, {
        height: "320px",
        width: "400px",
        data: {
          project_name: this.project_name,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined && result["confirm"]) {
          this._get_project_table(project_id, true);
        }
      });
  }
  export_table_as_csv(id) {
    this.is_exporting_as_csv = true;
    this.projectService
      .getProjectTableCSV(id)
      .toPromise()
      .then((data) => {
        this.is_exporting_as_csv = false;
        let results = data["results"];
        let fields: Array<string> = Array.from(results[0].keys);
        let csvstr = fields.join(",") + "\n";
        for (let row of results) {
          let entry = fields.map((x) => row[x]);
          csvstr += entry.join(",") + "\n";
        }
        let blob = new Blob([csvstr], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "project_table.csv";
        a.click();
      });
  }

  _export_table_as_csv(id) {
    this.is_exporting_as_csv = true;
    this.projectService
      ._getProjectTableCSV(id)
      .toPromise()
      .then((data) => {
        this.is_exporting_as_csv = false;
        let results = data["results"];
        let fields: Array<string> = Array.from(Object.keys(results[0]));
        let csvstr = fields.join(",") + "\n";
        for (let row of results) {
          let entry = fields.map((x) => row[x]);
          csvstr += entry.join(",") + "\n";
        }
        let blob = new Blob([csvstr], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "project_table.csv";
        a.click();
      });
  }

  nav_to_job(job_id) {
    window.open("/projects/" + job_id + "/model", "_blank");
    // this.router.navigate(['../projects', job_id, 'model']);
  }

  applyTableFilter() {
    this.filteredData = this.dataSource.filter((data) => {
      return Array.from(this.columnFilters).every(([key, values]) => {
        return values.some(
          (val) => (data[key] || "").toLowerCase() === (val || "").toLowerCase()
        );
      });
    });

    // make sure we maintain our sorting once the filter has been applied
    this.sort_table(this.sort_by, this.sort_order);
  }

  addToTableFilter(checked: boolean, value: string, column: string) {
    const existingFiltersForColumn = this.columnFilters.get(column) || [];
    let newFiltersForColumn = existingFiltersForColumn;

    if (checked) {
      newFiltersForColumn.push(value);
    } else {
      newFiltersForColumn.splice(existingFiltersForColumn.indexOf(value), 1);
    }

    if (newFiltersForColumn.length) {
      this.columnFilters.set(column, newFiltersForColumn);
    } else {
      this.columnFilters.delete(column);
    }
  }

  clearFilters(column: string) {
    this.columnFilters.delete(column);
  }

  handleFilterInputClick(event) {
    event.stopPropagation();
  }

  getFilterValuesForColumn(column: string) {
    return new Set(this.filteredData.map((data) => data[column]));
  }

  private clearTableFiltering() {
    this.filteredData = this.dataSource;
    this.columnFilters = new Map();
  }
  nav_to_trace(trace_locator: string) {
    let trace_locator_path = "";
    if (trace_locator[trace_locator.length - 1] == "/") {
      trace_locator_path = trace_locator.substring(0, trace_locator.length - 1);
    } else {
      trace_locator_path = trace_locator;
    }
    let temp = trace_locator_path.split("/");
    trace_locator_path = temp[temp.length - 1];
    console.log(temp, trace_locator, trace_locator_path);
    window.open(
      "/project/" + this.project_id + "/data-table/" + trace_locator_path,
      "_blank"
    );
  }
}
