import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-graph',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit {

  @Input() xData: number[];
  @Input() yData: any;
  @Input() wideGraph: boolean = false;
  @Input() scale: number[] = null;
  @Input() yAxisName: string;
  @Output('point') point: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('graph', { static: true }) graph: ElementRef;
  @ViewChild('graphContainer', { static: true }) container: ElementRef;

  xValue: number = 0;
  yValue: number = 0;
  private width: number;
  private height: number;
  private margin = {top: 0.1, right: 0.1, bottom: 0.1, left: 0.15};

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.width = this.container.nativeElement.offsetWidth*0.8;
    if (this.wideGraph){
      this.height = this.width*0.375;
      this.margin.left = 0.075;
    }
    else {
      this.height = this.width*0.75;
    }

    let xData = this.xData;
    let yData = this.yData.data.filter(d => d !== null);

    let xRange = Math.max(...xData)-Math.min(...xData);
    let minX = Math.min(...this.xData)-0.05*xRange;
    let maxX = Math.max(...this.xData)+0.05*xRange;

    let minY = Math.min(...yData)
    let maxY = Math.max(...yData)
    if (minY == maxY) {
      minY *= 0.9;
      maxY *= 1.1;
    }
    if (this.scale[0] != null) {
      minY = this.scale[0];
    }
    if (this.scale[1] != null && this.scale[1] > minY) {
      maxY = this.scale[1];
    }
    let yRange = maxY - minY;
    minY = minY-0.05*yRange;
    maxY =  maxY+0.05*yRange;

    this.draw(minX, maxX, minY, maxY, xData, this.yData.data);
  }

  private draw(minX: number, maxX: number, minY: number, maxY: number,
    xData: number[], yData: number[]) {
    let plotData: number[][] = xData.map((e, i) => [e, yData[i]]).filter(d => d[1] !== null && d[1] !== undefined);
    plotData.sort((a, b) => a[0]-b[0])
    let xScale = d3.scaleLinear()
                   .domain([minX, maxX])
                   .range([0, this.width]);
    let yScale = d3.scaleLinear()
                   .domain([minY, maxY])
                   .range([this.height, 0]);

    let line = d3.line().x(function(d: number[]) {return xScale(d[0]);})
                        .y(function(d: number[]) {return yScale(d[1]);});
    let svg = d3.select(this.graph.nativeElement)
                .attr("width", this.width*(1+this.margin.left+this.margin.right))
                .attr("height", this.height*(1+this.margin.top+this.margin.bottom))
                .append("g")
                .attr("transform", 'translate('+this.width*this.margin.left+', '+this.height*this.margin.top+')');
    svg.append("g").attr("transform", 'translate(0,'+this.height+')').call(d3.axisBottom(xScale));
    svg.append("text").attr("transform", 'translate('+ (this.width/2) +','+(this.height+40)+')')
       .text("Iteration").style("font-weight", "bold")
    svg.append("g").call(d3.axisLeft(yScale));
    svg.append("text").attr("transform", 'translate(-90,'+ this.height/2 +')')
       .text(() => this.yAxisName).style("font-weight", "bold")
    // Add path
    svg.append("g").selectAll("path").data([plotData]).enter().append("path").attr("class", "fline").attr("d",line).attr("clip-path", "url(#rect_clip)");
    // Add nodes
    svg.append("g").selectAll("circle").data(plotData).enter().append("circle")
       .attr("cx", function(d: number[]) {return xScale(d[0])})
       .attr("cy", function(d: number[]) {;return yScale(d[1])}).attr("r", 5)
       .on("mouseover", function() {d3.select(this).attr("r", 8).style("opacity", 0.7)})
       .on("mouseout", function() {d3.select(this).attr("r", 5).style("opacity", 1)})
       .on("click",(d: number[]) => {this.point.emit(xData.indexOf(d[0]))})
       .attr("clip-path", "url(#rect_clip");
    svg.append("clipPath").attr("id", "rect_clip").append("rect").attr("x", 0).attr("y", 0).attr("height", this.height).attr("width", this.width);

  }
}