<div class="wrapper">
  <nav id="sidebar">
    <ul class="list-unstyled components">
      <li routerLinkActive="active"><a routerLink="scores"><i class="zmdi zmdi-grid"></i> Scores</a></li>
      <li routerLinkActive="active"><a routerLink="search"><i class="zmdi zmdi-search"></i> Search</a></li>
    </ul>
  </nav>

  <div id="content">
    <router-outlet></router-outlet>
  </div>
</div>
