<section class="container-fluid">
  <div>
    <app-custom-table
      [data]="this.data"
      [loading]="this.loading"
      [columns]="this.options"
      [editRow]="true"
      [collapsibleDetail]="true"
      [collapsibleBodyFunction]="this.collapsibleSlot"
      [removalColumns]="['parent_job']"
      [truncationFields]="['parent_job_name']"
      [inMenuButtonConfig]="this.inMenuButtonConfig"
      [linkDefinitions]="this.linkDefinitions"
      (editRowHandler)="this.editRow($event)"
    >
      <!-- <button mat-button (click)="rebuildModelTable()">Rebuild Table</button> -->
    </app-custom-table>
  </div>
</section>

<nz-modal
  [(nzVisible)]="showEditModal"
  (nzOnCancel)="cancelEdit()"
  nzTitle="Edit Job"
>
  <ng-container *nzModalContent>
    <form
      nz-form
      [formGroup]="this.editModelFormControl"
      (submit)="this.saveChanges($event)"
    >
      <nz-input-group class="legacy-input-styling">
        <label>Comment</label>
        <input nz-input placeholder="Comment" formControlName="comments" />
      </nz-input-group>

      <nz-input-group class="legacy-input-styling">
        <label>Select Job</label>
        <nz-select style="width: 100%" formControlName="selectedOriginJob">
          <nz-option nzLabel="None" [nzValue]="null">None</nz-option>
          <ng-container *ngFor="let job of this.jobsWithIterations">
            <nz-option [nzValue]="job" [nzLabel]="job['job_name']"></nz-option>
          </ng-container>
        </nz-select>
      </nz-input-group>

      <nz-input-group
        class="legacy-input-styling"
        *ngIf="this.editModelFormControl.value['selectedOriginJob']"
      >
        <label>Checkpoint number</label>
        <nz-form-control nzErrorTip="Please enter a valid checkpoint number">
          <input nz-input formControlName="cp_num" required type="number" />
        </nz-form-control>
      </nz-input-group>
    </form>
  </ng-container>
  <ng-container *nzModalFooter>
    <button
      nz-button
      nzType="primary"
      (click)="saveChanges($event)"
      [disabled]="
        this.editModelFormControl.status == 'INVALID' ||
        this.editModelFormControl.pristine
      "
    >
      Save
    </button>
    <button nz-button type="reset" (click)="this.cancelEdit()">Cancel</button>
  </ng-container>
</nz-modal>
