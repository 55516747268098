<h3 mat-dialog-title>Job Settings</h3>
<form
  class="form-container"
  (ngSubmit)="submitForm()"
  [formGroup]="jobSettingsForm"
>
  <mat-dialog-content>
    <nz-form-item>
      <nz-form-label nzRequired nzFor="jobName" [nzNoColon]="true"
        >Job Name</nz-form-label
      >
      <nz-form-control>
        <input
          type="text"
          nz-input
          placeholder="Job Name"
          id="jobName"
          required
          formControlName="name"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired nzFor="projectId" [nzNoColon]="true"
        >Project Group</nz-form-label
      >
      <nz-form-control>
        <nz-select
          nzShowSearch
          [nzDisabled]="projects == null"
          [nzLoading]="projects == null"
          name="projectId"
          formControlName="projectId"
        >
          <nz-option
            *ngFor="let project of projects"
            [nzValue]="project.id"
            [nzLabel]="project.name"
            >{{ project.name }}</nz-option
          >
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired nzFor="basePath" [nzNoColon]="true"
        >Base Path</nz-form-label
      >
      <nz-form-control nzErrorTip="Base path is a required field!">
        <input
          type="text"
          nz-input
          placeholder="Base Path"
          id="basePath"
          required
          formControlName="basePath"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired nzFor="iterations" [nzNoColon]="true"
        >Iterations</nz-form-label
      >
      <nz-form-control [nzErrorTip]="iterationErrorTpl">
        <input
          type="number"
          nz-input
          placeholder="Iterations"
          id="iterations"
          required
          formControlName="iterations"
          (ngModelChange)="updateIterationsValidator()"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired nzFor="iterationsComplete" [nzNoColon]="true"
        >Iterations Complete</nz-form-label
      >
      <nz-form-control [nzErrorTip]="iterationErrorTpl">
        <input
          type="number"
          nz-input
          placeholder="Iterations Complete"
          id="iterationsComplete"
          required
          formControlName="iterationsComplete"
          (ngModelChange)="updateIterationsValidator()"
        />
      </nz-form-control>
    </nz-form-item>
    <!-- template for the various errors in iterations/iterationsCompleted -->
    <ng-template #iterationErrorTpl let-control>
      <ng-container *ngIf="control.errors?.['required']">
        Please enter a valid value
      </ng-container>
      <ng-container *ngIf="control.errors?.['pattern']">
        Please enter a positive integer
      </ng-container>
      <ng-container *ngIf="control.errors?.['iterationsInvalid']">
        Completed iterations must not be greater than total iterations!
      </ng-container>
    </ng-template>

    <nz-form-item>
      <nz-form-label nzRequired nzFor="status" [nzNoColon]="true"
        >Status</nz-form-label
      >
      <nz-form-control>
        <nz-select
          nzShowSearch
          name="status"
          formControlName="status"
          nzPlaceHolder="Status"
        >
          <nz-option nzLabel="RUNNING" nzValue="RUNNING">Started</nz-option>
          <nz-option nzLabel="NOTRUNNINNG" nzValue="NOTRUNNINNG"
            >Stopped</nz-option
          >
          <nz-option nzLabel="FINISHED" nzValue="FINISHED">Finished</nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzFor="job-comment" [nzNoColon]="true"
        >Comments</nz-form-label
      >
      <nz-form-control>
        <textarea
          nz-input
          placeholder="Comments"
          id="job-comment"
          [nzAutosize]="{ minRows: 3 }"
          formControlName="comments"
        ></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzFor="tags" [nzNoColon]="true">Tags</nz-form-label>
      <nz-form-control>
        <textarea
          nz-input
          placeholder="Tags"
          id="tags"
          [nzAutosize]="{ minRows: 2 }"
          formControlName="tag"
        ></textarea>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="parentJobControl" [nzNoColon]="true"
        >Parent Job</nz-form-label
      >
      <nz-form-control>
        <nz-select
          nzShowSearch
          [nzLoading]="jobs == null"
          [nzDisabled]="jobs == null"
          name="parentJobControl"
          [formControl]="parentJobControl"
          nzPlaceHolder="Parent Job"
          (ngModelChange)="onParentJobChanged($event)"
        >
          <nz-option nzLabel="No Parent" [nzValue]="null">No Parent</nz-option>
          <nz-option
            *ngFor="let job of jobs"
            [nzValue]="job.id"
            [nzLabel]="job.name"
            >{{ job.name }}</nz-option
          >
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired nzFor="parentJobCPNUMControl" [nzNoColon]="true"
        >Parent Job CPNUM</nz-form-label
      >
      <nz-form-control [nzErrorTip]="parentCpErrorTpl">
        <input
          type="number"
          nz-input
          placeholder="Parent Job CPNUM"
          id="parentJobCPNUMControl"
          required
          [formControl]="parentJobCPNUMControl"
          (ngModelChange)="this.updateParentJobCPnumValidator()"
        />
      </nz-form-control>
    </nz-form-item>
    <ng-template #parentCpErrorTpl let-control>
      <ng-container *ngIf="control.errors?.['required']">
        Please enter a valid value
      </ng-container>
      <ng-container *ngIf="control.errors?.['pattern']">
        Please enter a positive integer
      </ng-container>
      <ng-container *ngIf="control.errors?.['cpInvalid']">
        CPNum must not be greater than Parent Jobs total iterations
      </ng-container>
    </ng-template>
    <div>
      <label
        nz-checkbox
        [nzDisabled]="
          jobs == null ||
          parentJobControl.value == null ||
          parentJobControl.value == ''
        "
        [formControl]="offshootFormControl"
        >Is Offshoot
      </label>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="button-container">
    <button mat-raised-button color="primary" type="submit">Update</button>
    <button mat-raised-button mat-dialog-close color="basic" type="reset">
      Cancel
    </button>
  </mat-dialog-actions>
</form>
