import { Component, Input } from "@angular/core";
import { ProjectService } from "../../shared/services/project.service";
import { take } from "rxjs/operators";
import { Field } from "../../models/field";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzSelectItemInterface } from "ng-zorro-antd/select";

@Component({
  selector: "app-fields-listing",
  templateUrl: "./fields-listing.component.html",
  styleUrls: ["./fields-listing.component.less"],
})
export class FieldsListingComponent {
  @Input("demoUser") demoUser: boolean = false;
  fields: Field[];
  filteredFields: Field[];
  fieldModalVisible: boolean = false;
  isLoading: boolean = false;
  fieldProjects: { [key: string]: { id: string; name: string }[] } = {};
  activeField: Field;
  // activeFieldProjects: string[] = [];
  allProjects: {
    id: string;
    name: string;
    description: string;
    field?: string;
  }[];

  fieldForm: FormGroup;
  clients: string[] = [];
  locations: string[] = [];
  filtering: boolean = false;

  constructor(
    private projectService: ProjectService,
    private notificationService: NzNotificationService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.getFields();
    this.getAllProjects();

    this.fieldForm = fb.group({
      name: fb.control<String>("", [Validators.required]),
      projects: fb.control<String[]>([""]),
      description: fb.control<String>(""),
      location: fb.control<String>(""),
      geography: fb.control<String>("", [Validators.required]),
      client: fb.control<String>("Internal"),
    });
  }

  getAllProjects() {
    this.isLoading = true;
    this.projectService
      .getAllProjects()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.allProjects = res;
          this.isLoading = false;
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error ||
              "There was an error when getting all projects. Please try later"
          );
        },
      });
  }

  getFields() {
    this.isLoading = true;
    this.projectService
      .getAllFields(true)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.fields = res.fields;
          this.filteredFields = res.fields;
          this.locations = ["", ...res.locations];
          this.clients = ["", ...res.clients];
          this.isLoading = false;
        },
        error: (err) => {
          console.log("err", err);
          this.notificationService.error(
            "Error",
            err.error?.error || "There was an unexepected error"
          );
          this.isLoading = false;
        },
      });
  }

  getFieldProjects(field: Field, assignActiveFieldProjects: boolean = false) {
    if (field.id in this.fieldProjects) {
      if (assignActiveFieldProjects) {
        this.fieldForm
          .get("projects")
          .setValue(this.fieldProjects[field.id]?.map((p) => p.id));
      }
      return;
    }

    this.isLoading = true;
    this.projectService
      .getFieldById(field.id)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.fieldProjects[res.id] = res.projects;
          if (assignActiveFieldProjects)
            this.fieldForm
              .get("projects")
              .setValue(this.fieldProjects[field.id]?.map((p) => p.id));
          this.isLoading = false;
        },
        error: (err) => {
          console.log("get fields projects error", err);
          this.isLoading = false;
        },
      });
  }

  createNewField() {
    // if clicked while a field is being made already, skip request
    if (this.isLoading) {
      return;
    }

    if (!this.fieldForm.valid) {
      const errors = this.getFormErrors(this.fieldForm);
      this.notificationService.error(
        "Error",
        `There is an error in the following fields: ${Object.keys(errors).join(
          ", "
        )} `
      );
      return;
    }

    this.isLoading = true;
    this.projectService
      .createField({
        name: this.fieldForm.get("name").value,
        description: this.fieldForm.get("description").value,
        projects: this.fieldForm.get("projects").value,
        geography: this.fieldForm.get("geography").value,
        location: this.fieldForm.get("location").value,
        client: this.fieldForm.get("client").value,
      })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.notificationService.success(
            "Success",
            "Field created: " + res.name
          );
          this.fieldForm.reset();
          this.fieldProjects = {};
          this.isLoading = false;
          this.getFields();
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error || "An unexpected error occured!"
          );
          this.isLoading = false;
        },
      });
  }

  onEditField() {
    // if clicked while a field is being editted already, skip request
    if (this.isLoading) {
      return;
    }

    if (!this.fieldForm.valid) {
      const errors = this.getFormErrors(this.fieldForm);
      this.notificationService.error(
        "Error",
        `There is an error in the following fields: ${Object.keys(errors).join(
          ", "
        )} `
      );
      return;
    }

    this.isLoading = true;

    this.projectService
      .editField({
        id: this.activeField.id,
        name: this.fieldForm.get("name").value,
        description: this.fieldForm.get("description").value,
        projects: this.fieldForm.get("projects").value,
        geography: this.fieldForm.get("geography").value,
        location: this.fieldForm.get("location").value,
        client: this.fieldForm.get("client").value,
      })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.notificationService.success(
            "Success",
            "Field editted: " + res.name
          );
          this.fieldForm.reset();
          this.fieldProjects = {};
          this.activeField = null;
          this.isLoading = false;
          this.getFields();
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error || "An unexpected error occured!"
          );
          this.isLoading = false;
        },
      });
  }

  editField(field: Field) {
    this.getFieldProjects(field, true);
    this.getAllProjects();
    this.activeField = field;

    this.fieldForm.get("name").setValue(field.name);
    this.fieldForm.get("description").setValue(field.description);
    this.fieldForm.get("client").setValue(field.client);
    this.fieldForm.get("location").setValue(field.location);
    this.fieldForm.get("geography").setValue(field.geography);

    this.fieldModalVisible = true;
  }

  closeFieldModal() {
    this.activeField = null;
    this.fieldModalVisible = false;
    this.fieldForm.reset();
  }

  selectFilterClient = (str: string, opt: NzSelectItemInterface): boolean => {
    this.clients[0] = str;
    return opt.nzValue.toLocaleLowerCase().includes(str.toLocaleLowerCase());
  };
  selectFilterLocation = (str: string, opt: NzSelectItemInterface): boolean => {
    this.locations[0] = str;
    return opt.nzValue.toLocaleLowerCase().includes(str.toLocaleLowerCase());
  };

  goToProject(project: any) {
    this.router.navigate(["project", project.id, "parameter-view"]);
  }

  getFormErrors(form: any) {
    if (!form.controls) {
      // Return FormControl errors or null
      return form.errors ?? null;
    }
    const groupErrors = form.errors;
    const formErrors = groupErrors ? { groupErrors } : {};
    Object.keys(form.controls).forEach((key) => {
      const error = this.getFormErrors(form.get(key));
      if (error !== null) {
        formErrors[key] = error;
      }
    });
    return Object.keys(formErrors).length > 0 ? formErrors : null;
  }

  filterFor(key: string, value: string) {
    this.filtering = true;
    this.filteredFields = this.fields.filter((field) => field[key] == value);
  }

  filter_empty(arr: string[]) {
    return arr.filter((c) => c != "");
  }
}
