import { Injectable } from "@angular/core";

@Injectable()
export class DemoPageHistory {
  currentId: string;
  previousId: string;

  public setHistory(jobId: string) {
    this.previousId = this.currentId;
    this.currentId = jobId;
  }
}
