import { Component, Input, OnInit } from "@angular/core";
import { JobDetail } from "../../../models/jobDetail";

@Component({
  selector: "app-shot-chart-container",
  templateUrl: "./shot-chart-container.component.html",
  styleUrls: ["./shot-chart-container.component.less"],
})
export class ShotChartContainerComponent implements OnInit {
  @Input() jobDetail: JobDetail;
  constructor() {}

  ngOnInit() {}
}
