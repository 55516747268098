<div class="container-fluid row">
  <div #graphContainer class="col-md-11">
    <svg #graph></svg>
  </div>
  <div class="col-md-1" style="margin-top: 3%">
    <a class="control-menu" [matMenuTriggerFor]="typeMenu"><i class="zmdi zmdi-hc-2x zmdi-more-vert"></i></a>
  </div>
  <mat-menu #typeMenu="matMenu">
    <mat-slide-toggle style="margin-left: 9%" [checked]="showStd" (change)="turnOnOffStd()" (click)="$event.stopPropagation()">
      <span style="text-overflow:clip;">STD</span>
    </mat-slide-toggle>
    <button *ngFor="let plotType of plotTypes" mat-menu-item (click)="changeType(plotType, $event)">
      <span>{{plotType.name}}</span>
      <i *ngIf="selectedTypes.includes(plotType.index)" class="zmdi zmdi-check" style="margin-left:2px;"></i>
    </button>
  </mat-menu>

</div>