import { Project } from "../../../models/project";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import {
  Component,
  OnInit,
  HostListener,
  ViewEncapsulation,
  AfterViewChecked,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { get_mapping } from "./name_mappings";
import { MatDialog } from "@angular/material/dialog";
import { RebuildDataTableComponent } from "../../project/dialogs/rebuild-data-table/rebuild-data-table.component";
import { Router } from "@angular/router";
import { trace_locator_params, default_showing_columns } from "./params_list";
import { tooltips } from "./header_tooltips";

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.less"],
  encapsulation: ViewEncapsulation.None,
})
export class DataTableComponent implements OnInit, AfterViewChecked {
  project_id: string;
  project_name: string;
  selectedFiles: any[] = [];
  data: Array<any> = [];
  showEditModal: boolean = false;
  activeRow = null;
  pendingSave: boolean = false;
  showSaveModalDialog: boolean = false;
  loading: boolean = false;
  dataValues: object = {};
  scrolled: boolean = false;
  options: any = {
    base: [
      "traces_locator",
      "directory_name",
      "comment",
      "generated_on",
      "aws_path",
    ],
    ...trace_locator_params,
  };
  tooltips: { [key: string]: string } = tooltips;
  defaultShowingColumns: Array<string> = default_showing_columns;
  inMenuButtonConfig = [
    {
      text: "Rebuild Table",
      action: (event, info) => {
        this.rebuildDataTable();
      },
    },
  ];

  constructor(
    private projectService: CachedProjectService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.projectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project_id = project.id;
        this.project_name = project.name;
        this.getDatatable();
      }
    });
  }

  ngOnInit() {}

  ngAfterViewChecked() {
    let routerParts = this.router.url.split("/");
    let id = routerParts[routerParts.length - 1];
    if (document.getElementById(id) && !this.scrolled && id) {
      document.getElementById(id).scrollIntoView();
      this.scrolled = true;
    }
  }

  rebuildDataTable() {
    this.dialog
      .open(RebuildDataTableComponent, {
        width: "400px",
        data: {
          project_name: this.project_name,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined && result["confirm"]) {
          this.loading = true;
          this.projectService
            ._rebuildProjectDatatable(this.project_id, {
              paths: result["paths"],
            })
            .toPromise()
            .then((res) => {
              this.data = this.processData(res["data"]);
              this.loading = false;
            })
            .catch((err) => {
              console.error(err);
              this.loading = false;
            });
        }
      });
  }

  getDatatable() {
    this.loading = true;
    this.projectService
      ._getProjectDatatable(this.project_id)
      .toPromise()
      .then((res) => {
        this.data = this.processData(res["data"]);
        this.loading = false;
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
  }

  processData(data: Array<object>) {
    // basically prevents the 'directory_name' to be repeated. Works like a SQL Distinct but returns the whole row instead of just the columnValue
    const processedData = [];
    for (let datum of data) {
      if (
        !processedData.find(
          (element) => element["directory_name"] == datum["directory_name"]
        )
      ) {
        processedData.push({
          ...datum,
          generated_on: datum["generated_on"]
            ? new Date(datum["generated_on"])
            : "",
        });
      }
    }
    return processedData;
  }

  editRow(data: object): void {
    this.showEditModal = true;
    this.activeRow = { ...data };
  }

  deleteRow(data: object): void {
    this.loading = true;
    this.projectService
      ._deleteProjectDatatableRecord(this.project_id, data)
      .toPromise()
      .then((res) => {
        this.getDatatable();
        this.loading = false;
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
  }

  promptSave() {
    if (this.pendingSave) {
      this.showSaveModalDialog = true;
    }
  }

  discardChanges() {
    this.pendingSave = false;
    this.showSaveModalDialog = false;
  }

  saveChanges() {
    this.pendingSave = false;
    this.showSaveModalDialog = false;
    this.projectService
      ._updateProjectDatatable(this.project_id, { ...this.activeRow })
      .toPromise()
      .then((res) => this.getDatatable())
      .catch((err) => console.error(err));
  }

  changeValue(event: Event, key: string) {
    const element = event.target as HTMLInputElement;
    this.activeRow[key] = element.value;
    this.pendingSave = true;
  }

  updateFilePreview() {
    var previewElementDiv = document.getElementById("files-preview-section");
    previewElementDiv.innerHTML = "";
    for (let index in this.selectedFiles) {
      let file = this.selectedFiles[index];
      var previewElement = document.createElement("div");
      previewElement.style.display = "flex";
      previewElement.style.justifyContent = "flex-start";
      previewElement.style.alignItems = "center";
      previewElement.style.gap = "10px";
      previewElement.style.background = "aliceblue";
      previewElement.style.padding = "5px";
      previewElement.style.borderRadius = "50px";
      previewElement.style.border = "1px solid black";
      previewElement.style.cursor = "pointer";
      previewElement.setAttribute("data-key", index);
      previewElement.innerHTML += `<i style="font-size=20px" class="zmdi zmdi-menu"></i> <span>${file.name}</span>`;
      previewElementDiv.appendChild(previewElement);
    }
  }

  onFileSelected(event: Event) {
    let element = event.target as HTMLInputElement;
    for (let index = 0; index < element.files.length; index++) {
      this.selectedFiles.push(element.files[index]);
    }
    this.updateFilePreview();
  }

  deleteFile(index: number) {
    this.selectedFiles.splice(index, 1);
    this.updateFilePreview();
  }

  getFileContents(file: File) {
    // the fileReader has to be wrapped in a promise and awaited, cause its actually async but doesn't return a promise
    // hence, wrapping it up and returning the conten allows you to actually get the content as a return value
    return new Promise((resolve, reject) => {
      let contents = "";
      const reader = new FileReader();
      reader.onloadend = function (e) {
        contents = e.target.result as string;
        resolve(contents);
      };
      reader.onerror = function (e) {
        reject(e);
      };
      reader.readAsText(file);
    });
  }

  async parseFiles() {
    let keyFiles = this.selectedFiles.filter((element: File) =>
      element.name.includes("key")
    );
    let logFiles = this.selectedFiles.filter((element: File) =>
      element.name.includes("log")
    );

    let keyText = "";
    let logText = "";

    if (keyFiles.length != logFiles.length) {
      alert("Please enter the correct number of log and key files");
      return;
    }
    if (keyFiles.length == 0) {
      alert("Please enter a key file");
      return;
    }

    const dataValues = {};
    let timestamp = "";
    let project = "";

    let logFile = logFiles[0];
    logText = (await this.getFileContents(logFile)) as string;
    logText
      .replace("\r", "")
      .split("\n")
      .forEach((line) => {
        if (line.includes("Generated on")) {
          timestamp = line.replace("Generated on ", "");
        }
        if (line.includes("Project:")) {
          project = line.replace("Project:", "").trim();
        }

        let row = line.split(":");
        if (row.includes("\r")) {
          return;
        }
        let key = row[0].replace(/ /g, "").toLowerCase().trim();
        // values starting wtih ! and # are commented and to be ignored
        if (key[0] == "!" || key[0] == "#") {
          return;
        } else {
          // some values have a description after their values, delimitted by '!'
          // so seperate with ! and get the value + description
          if (!row[1]) {
            // some places, there is an identifier but no value
            return;
          }
          let value = row[1].split("!")[0].trim();

          // here, find the mapping of the key
          let standardKey = get_mapping(key, true, true);
          if (standardKey) {
            this.activeRow[standardKey] = value;
            dataValues[standardKey] = value;
          }
        }
      });
    dataValues["Project Prefix"] = project;

    // remove \r \t \n ' ' and at from the timestamp
    let timestampComps = timestamp
      .replace("\r", "")
      .replace("\n", "")
      .replace("\t", "")
      .replace(/at/g, "")
      .split(",");
    let time = timestampComps[2].split(":");
    const d = new Date(`${timestampComps[0]} ${timestampComps[1]}`);
    d.setHours(Number(time[0]), Number(time[1]), Number(time[2]));
    dataValues["generated_on"] = d.toString();
    this.activeRow["generated_on"] = d;

    let keyFile = keyFiles[0];
    keyText = (await this.getFileContents(keyFile)) as string;
    // parse key file to get all its key value pairs
    keyText
      .replace("\r", "")
      .split("\n")
      .forEach((line) => {
        let row = line.split(":");
        // \r is for empty lines so skip those lines
        if (row.includes("\r")) {
          return;
        }
        let key = row[0].replace(/ /g, "").toLowerCase().trim();
        // values starting wtih ! and # are commented and to be ignored
        if (key[0] == "!" || key[0] == "#") {
          return;
        } else {
          // some values have a description after their values, delimitted by '!'
          // so seperate with ! and get the value + description
          if (!row[1]) {
            // some places, there is an identifier but no value
            return;
          }
          let value = row[1].split("!")[0].trim();

          // here, find the mapping of the key
          let standardKey = get_mapping(key, true, true);
          if (standardKey) {
            this.activeRow[standardKey] = value;
            dataValues[standardKey] = value;
          }
        }
      });
    this.pendingSave = true;
    this.dataValues = dataValues;
  }

  syntaxHighlight(jsonObj: object) {
    let json = JSON.stringify(jsonObj, null, 2);
    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    let temp = json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        var cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );
    return `<pre>${temp}</pre>`;
  }

  getObjectKeys(obj: Object) {
    return Object.keys(obj);
  }

  getObjectValues(obj: Object) {
    return Object.values(obj);
  }

  headerMappingHelper(key: string) {
    let mapping = get_mapping(key).toLowerCase();

    if (mapping) {
      let capitalizedMapping = "";
      capitalizedMapping = mapping
        .split(" ")
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(" ");
      return capitalizedMapping;
    } else {
      return key;
    }
  }

  @HostListener("document:mouseover", ["$event"])
  mouseoverImages(e: Event) {
    let element = e.target as HTMLElement;

    let file = element.closest("#files-preview-section div") as HTMLElement;
    if (file) {
      file.style.border = "1px solid red";
    }
  }

  @HostListener("document:mouseout", ["$event"])
  mouseoutImages(e: Event) {
    let element = e.target as HTMLElement;
    let file = element.closest("#files-preview-section div") as HTMLElement;
    if (file) {
      file.style.border = "1px solid black";
    }
  }

  @HostListener("document:click", ["$event"])
  onclickImage(e: Event) {
    let element = e.target as HTMLElement;

    if (element.classList.contains("data-table-comp-modal")) {
      this.showEditModal = false;
      this.dataValues = {};
      this.selectedFiles = [];
      this.promptSave();
    }

    let file = element.closest("#files-preview-section div") as HTMLElement;
    if (file) {
      let index = Number(file.getAttribute("data-key"));
      this.deleteFile(index);
    }
  }

  tooltipsAccessor = (key: string) => {
    return tooltips[key.toLocaleUpperCase().replace(/_/g, " ")];
  };
}
