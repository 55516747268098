import { Component, OnInit, Inject, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface RebuildDataTableDialogInfo {
  project_name: string
}

@Component({
  selector: 'app-rebuild-data-table',
  templateUrl: './rebuild-data-table.component.html',
  styleUrls: ['./rebuild-data-table.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class RebuildDataTableComponent implements OnInit {
  @ViewChild('folderPaths', { static: true }) folderPathsDiv: ElementRef

  constructor(@Inject(MAT_DIALOG_DATA) public data: RebuildDataTableDialogInfo, public dialogRef: MatDialogRef<RebuildDataTableComponent>) { }

  ngOnInit() {
  }

  addNewPath() {
    const newInput = document.createElement('label')
    newInput.appendChild(document.createElement('input'))
    const spanLabel = document.createElement('span')
    spanLabel.classList.add('placeholder')
    spanLabel.textContent = 'Folder Path'
    newInput.appendChild(spanLabel)
    // <label>
    //   <input required>
    //   <span class="placeholder">Folder Path</span>
    // </label>
    this.folderPathsDiv.nativeElement.appendChild(newInput)
  }

  submit() {
    const inputsChildren = this.folderPathsDiv.nativeElement.getElementsByTagName('input')
    let paths = []
    for (let child of inputsChildren) {
      if (child.value != '') {
        paths.push(child.value.trim())
      }
    }
    this.dialogRef.close({ confirm: true, paths: paths });
  }

  cancel() {
    this.dialogRef.close({ confirm: false });
  }
}
