import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { JobDetail } from "../../../models/jobDetail";
import { ShotType } from "../../../models/shotType";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { ShotSettings } from "../../../models/shotSettings";
import { StoredSettingsService } from "../../services/stored-settings.service";

@Component({
  selector: "app-shot-chart-legend",
  templateUrl: "./shot-chart-legend.component.html",
  styleUrls: ["./shot-chart-legend.component.less"],
})
export class ShotChartLegendComponent implements OnInit {
  @Input() shotType: ShotType;
  @Input() clipValue: number;
  @Input() selectedPanel: number;
  @Output() interleaveValueChanged = new EventEmitter<number>();
  interleave_extent = 10;
  rcvrScale = { min: 0, max: 100 };
  typeOfSelectedRcvr: ShotType;
  constructor() {}

  ngOnInit() {}

  updateInterleave() {
    this.interleaveValueChanged.emit(this.interleave_extent);
  }
}
