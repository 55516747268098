import { throwError as observableThrowError, Observable, Observer } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { HttpService } from "./http.service";
import { AppConfig } from "../../app.config";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";

@Injectable()
export class ImageLoaderService {
  constructor(private http: HttpService, private authService: AuthService) {}

  public getImage(imageUrl: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );
    // console.log(imageUrl)
    return this.http
      .get(imageUrl, {
        responseType: "blob",
        headers: headers,
      })
      .pipe(
        map((res) => res),
        catchError((err: HttpErrorResponse) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }

  public getImageBase64(imageUrl: string): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      this.getImage(imageUrl).subscribe((b) => {
        let reader: FileReader = new FileReader();
        reader.onloadend = function () {
          observer.next(<string>reader.result);
          observer.complete();
        };
        reader.readAsDataURL(b);
      });
    });
  }

  public getTraceImage(id: string, parameters: any) {
    let requestOptions = new HttpHeaders();
    requestOptions.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );
    return Observable.create((observer: Observer<string>) => {
      this.http
        .get(`${AppConfig.settings.apiUrl}/jobs/${id}/shot.png`, {
          params: parameters,
          headers: requestOptions,
          responseType: "blob",
        })
        .pipe(
          catchError((err) => {
            if (err.status == 404) {
              observer.error({ status: 404, detail: "Trace not found" });
              observer.complete();
              return observableThrowError(err);
            }
            let reader: FileReader = new FileReader();
            reader.readAsDataURL(err);
            reader.onloadend = function () {
              try {
                let msg = reader.result
                  .toString()
                  .replace("data:application/json;base64,", "");
                observer.error(JSON.parse(atob(msg)));
                observer.complete();
              } catch (e) {
                observer.error(err);
                observer.complete();
              }
            };
            return observableThrowError(err);
          })
        )
        .subscribe((res) => {
          if (!res) {
            observer.next(null);
            observer.complete();
          } else {
            let reader: FileReader = new FileReader();
            reader.readAsDataURL(res);
            reader.onloadend = function () {
              observer.next(<any>reader.result);
              observer.complete();
            };
          }
        });
    });
  }

  public getTraceImageAndClipValue(id: string, parameters: any) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );
    return this.http
      .post(`${AppConfig.settings.apiUrl}/jobs/${id}/shot_image/`, parameters, {
        headers,
      })
      .pipe(
        map((response) => {
          if (!response) return;
          let p = response;
          if (parameters.panel == 5) {
            // console.log(response.json())
            return response;
          }
          return {
            imageStr: p.image,
            clipValue: p.meta ? p.meta["clip_value"] : null, // note: for phase plots the clip value is always the maximum of the non-clipped geom mean
            corr: p.corr ? p.corr : null,
          };
        }),
        catchError((err: HttpErrorResponse) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }
}
