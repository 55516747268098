import {
  Component,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
} from "@angular/core";
import { GraphComponent } from "./graph/graph.component";
import { CompareGraphComponent } from "./compare-graph/compare-graph.component";
import { MultiGraphComponent } from "./multi-graph/multi-graph.component";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { Observable, of } from "rxjs";
import { PlotData } from "../../../models/plotData";
import { catchError, switchMap } from "rxjs/operators";

@Component({
  selector: "app-plots",
  templateUrl: "./plots.component.html",
  styleUrls: ["./plots.component.css"],
})
export class PlotsComponent {
  @ViewChild("chart", { read: ViewContainerRef, static: true }) chart: ViewContainerRef;
  plotParams: { name: string; data: any }[] = [];
  displayingData: { name: string; data: number }[];
  isLoading: boolean = true;
  upLimitY: number = null;
  wideGraph: boolean = false;
  lowLimitY: number = 0;
  errorMessage: string = "Sorry, currently no data available";
  selectedIteration: number = null;
  selectedParam: number = 0;
  largestIter: number = 0;
  private cpData: number[];
  private selectedNode: any = -1;
  private graphType;
  private selectedIndicesForMultiGraph: number[] = [];

  private colorMap = {
    "traces fit": "#000000",
    "traces fit raw": "#63b8ff",
    "traces fit low": "#ff78bb",
    "traces fit amp": "#c4b7aa",
    "traces fit agc": "#009900",
    "traces fit twk": "#FFF833",
    "traces fit wgt": "#FA9404",
  };
  currentJobSubscription: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private projectService: CachedProjectService
  ) {}

  ngOnInit() {
    if (this.currentJobSubscription != null) {
      this.currentJobSubscription.unsubscribe();
      this.currentJobSubscription = null;
    }

    this.currentJobSubscription = this.projectService.currentJob
      .pipe(
        switchMap((job) => {
          this.isLoading = true;
          this.init();
          if (!job) return of(null);
          this.largestIter = job.iterationsComplete;
          this.selectedIteration = Math.min(
            this.selectedIteration,
            this.largestIter
          );
          return this.projectService.getPlotData(job.id);
        }),
        catchError((e) => {
          console.log(e);
          this.errorMessage = "Error occured when requesting plot data";
          return of(null);
        })
      )
      .subscribe((p: PlotData) => {
        if (!p) {
          this.isLoading = false;
          return;
        }
        for (let key in p) {
          if (key == "cpnums") {
            this.cpData = p[key];
          } else if (key === "traces_fits") {
            let tracesData = [];
            for (let traceType in this.colorMap) {
              let traceTypeData = p[key].find((d) => d.name === traceType);
              if (traceTypeData) {
                traceTypeData["lineColor"] = this.colorMap[traceType];
                tracesData.push(traceTypeData);
              }
            }
            this.plotParams.push({ name: "traces fits", data: tracesData });
          } else {
            let name = key.split("_").join(" ");
            this.plotParams.push({ name: name, data: p[key] });
          }
        }
        this.largestIter = Math.max(...this.cpData);
        if (
          !this.selectedIteration ||
          this.selectedNode > Math.max(...this.cpData)
        ) {
          this.selectedIteration = this.cpData[this.cpData.length - 1];
        }
        this.selectedParam = Math.min(
          this.selectedParam,
          this.plotParams.length - 1
        );
        this.loadChart();
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    if (this.currentJobSubscription != null) {
      this.currentJobSubscription.unsubscribe();
      this.currentJobSubscription = null;
    }
  }

  loadChart() {
    if (!this.plotParams) return;
    this.chart.clear();
    let factory, graphRef;
    let limitationAndEndpoint = this.findLargestExistLimit();
    let limitation = limitationAndEndpoint[0];
    let endPoint = limitationAndEndpoint[1];
    switch (this.plotParams[this.selectedParam].name) {
      case "step history":
        this.selectedNode = -1;
        factory = this.componentFactoryResolver.resolveComponentFactory(
          CompareGraphComponent
        );
        graphRef = this.chart.createComponent(factory);
        graphRef.instance.rawData = this.plotParams[this.selectedParam].data;
        graphRef.instance.iteration = limitation || 0;
        graphRef.instance.xAxisName = "stepfct";
        graphRef.instance.yAxisName = "fnl";
        this.graphType = CompareGraphComponent;
        break;
      case "traces fits":
        factory =
          this.componentFactoryResolver.resolveComponentFactory(
            MultiGraphComponent
          );
        graphRef = this.chart.createComponent(factory);
        if (
          this.plotParams[this.selectedParam].data.length > 0 &&
          this.selectedIndicesForMultiGraph.length == 0
        ) {
          this.selectedIndicesForMultiGraph = [0];
        }
        graphRef.instance.displayingIndices = this.selectedIndicesForMultiGraph;
        graphRef.instance.xData = this.cpData.slice(0, endPoint);
        graphRef.instance.yData = this.plotParams[this.selectedParam].data;
        this.graphType = MultiGraphComponent;
        if (!this.lowLimitY) {
          this.lowLimitY = 0;
        }
        if (!this.upLimitY) {
          this.upLimitY = 100;
        }
        break;
      default:
        factory =
          this.componentFactoryResolver.resolveComponentFactory(GraphComponent);
        graphRef = this.chart.createComponent(factory);
        graphRef.instance.xData = this.cpData.slice(0, endPoint);
        let data = {};
        Object.keys(this.plotParams[this.selectedParam].data).map((key) => {
          data[key] = this.plotParams[this.selectedParam].data[key].slice(
            0,
            endPoint
          );
        });
        graphRef.instance.yData = data;
        graphRef.instance.yAxisName = this.plotParams[this.selectedParam].name;
        this.graphType = GraphComponent;
        break;
    }
    if (!graphRef) return;
    graphRef.instance.wideGraph = this.wideGraph;
    graphRef.instance.scale = [this.lowLimitY, this.upLimitY];
    graphRef.instance.point.subscribe((p: number) => {
      this.selectedNode = p;
      this.updateSelectedValue();
    });
    this.updateSelectedValue();
  }

  updateGraphScale(event: KeyboardEvent) {
    if (event.keyCode != 13) return;
    this.loadChart();
  }

  onSelectedParamChange(param: number) {
    this.selectedParam = param;
    if (this.plotParams[param].name.includes("trace fit")) {
      this.upLimitY = 100;
    } else {
      this.upLimitY = null;
    }
    this.lowLimitY = 0;
    this.loadChart();
  }

  private updateSelectedValue() {
    this.displayingData = [];
    switch (this.graphType) {
      case CompareGraphComponent:
        if (typeof this.selectedNode != typeof [1, 1]) {
          this.selectedNode = -1;
          this.displayingData = null;
          return;
        }
        this.displayingData.push({
          name: "iteration",
          data: this.selectedIteration,
        });
        this.displayingData.push({
          name: "step length",
          data: this.selectedNode[0],
        });
        this.displayingData.push({
          name: "step functional",
          data: this.selectedNode[1],
        });
        break;
      case MultiGraphComponent:
        if (!this.selectedNode.types) {
          this.selectedNode = -1;
          this.displayingData = null;
          return;
        }
        this.selectedIndicesForMultiGraph = this.selectedNode.types;
        if (this.selectedNode.index == null) return;
        this.displayingData.push({
          name: "iteration",
          data: this.cpData[this.selectedNode.index],
        });
        let traceTypes = this.plotParams[this.selectedParam].data;
        for (let index of this.selectedNode.types) {
          let traceType = traceTypes[index];
          this.displayingData.push({
            name: traceType.name,
            data: traceType.data[this.selectedNode.index],
          });
        }
        break;
      case GraphComponent:
        if (typeof this.selectedNode !== typeof 1) {
          this.selectedNode = -1;
        }
        if (this.selectedNode < 0) {
          this.displayingData = null;
          return;
        }
        let selectedData = this.plotParams[this.selectedParam];
        this.displayingData.push({
          name: "iteration",
          data: this.cpData[this.selectedNode],
        });
        this.displayingData.push({
          name: selectedData.name,
          data: selectedData.data.data[this.selectedNode],
        });
        if (selectedData.data.std) {
          this.displayingData.push({
            name: "standard derivation",
            data: selectedData.data.std[this.selectedNode],
          });
        }
        break;
    }
  }

  private findLargestExistLimit() {
    if (
      this.selectedIteration !== null &&
      this.selectedIteration !== undefined
    ) {
      let limitation = this.cpData.indexOf(this.selectedIteration);
      if (limitation == -1) {
        for (let i = this.selectedIteration; i > 0; --i) {
          if (this.cpData.includes(i)) {
            return [limitation, this.cpData.indexOf(i) + 1];
          }
        }
      } else {
        return [limitation, limitation + 1];
      }
    }
    return [null, this.cpData.length];
  }

  private init() {
    this.chart.clear();
    this.selectedNode = -1;
    this.plotParams = [];
    this.cpData = [];
    this.displayingData = [];
  }
}
