import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgForm } from "@angular/forms";
import { MinMaxDialog } from "../../../../models/minMaxDialog";
declare var $;

@Component({
  selector: "app-velocity",
  templateUrl: "./velocity.component.html",
  styleUrls: ["./velocity.component.less"],
})
export class VelocityComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) form;
  hasError = false;
  center_offset_value: number = 0;
  // auto = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MinMaxDialog,
    public dialogRef: MatDialogRef<VelocityComponent>
  ) {
    // this.auto = (!data.min || !data.max);
    // this.auto = data.auto;
    this.center_offset_value = this.data.max;
    if (!this.data.slice_processing) this.data.slice_processing = [];
  }

  ngOnInit() {}

  submitForm() {
    if (this.data.auto) {
      this.dialogRef.close({
        min: null,
        max: null,
        auto: true,
        // syncRange: this.data.syncRange
      });
      return;
    }

    if (!this.form.valid) return;

    if (this.data.singleValue) {
      let val = Math.abs(this.data.offset);
      if (val == 0) {
        this.hasError = true;
        return;
      }
      this.data.max = val;
      this.data.min = -val;
    }

    if (this.data.min > this.data.max) {
      this.hasError = true;
      return;
    }
    this.hasError = false;

    if (this.data.slice_processing.includes("_zero_centred_params_process")) {
      this.data.max = this.center_offset_value;
      this.data.min = this.center_offset_value;
    }

    this.dialogRef.close(this.data);
  }

  clickInput(field: ElementRef) {
    // console.log(this.data)
    if (!this.data.auto) return;

    this.data.auto = false;
    $(field).focus();
  }
}
