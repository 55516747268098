<p id="skip"><a href="#content">Skip to the content</a></p>
<div id="site">

  <header>
    <app-nav-bar></app-nav-bar>
  </header>


  <div id="content">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>

  <span id="top-link-block" class="show affix-top hidden-xs">
    <a href="#top" onclick="$('html,body').animate({scrollTop:0},'slow');return false;">
      <i class="ion-ios-arrow-up before after"></i>
    </a>
  </span>  
</div>
