<app-banner heading="Login" description="Discover an unprecedented resolution increase in your velocity model"></app-banner>
<div class="container">
  <div class="row">
    <div class="col-md-8 col-xs-12" style="padding:20px;">
      <button class="btn btn-primary btn-margin"
              *ngIf="auth.isAuthenticated()"
              (click)="auth.logout()">
        Log Out
      </button>
    </div>
  </div>
</div>
