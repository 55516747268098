<div class="container-fluid">
  <app-custom-table
    [data]="this.data"
    [loading]="this.is_loading"
    [columns]="this.options"
    [truncationFields]="['job_name']"
    [removalColumns]="['job_id', 'project_id']"
    [isParameterView]="true"
    [linkDefinitions]="this.linkDefinitions"
    [buttonConfig]="this.buttonConfig"
    [inMenuButtonConfig]="this.inMenuButtonConfig"
    [editRow]="true"
    [defaultShowingIds]="this.landingJobs"
    (editRowHandler)="editComment($event)"
  >
  </app-custom-table>
</div>

<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Create job group"
  (nzOnCancel)="isVisible = false"
>
  <ng-container *nzModalContent>
    <nz-spin [nzSpinning]="this.creating_group">
      <div class="form-container">
        <p style="margin-bottom: 20px">Job groups for quick access</p>
        <nz-form-item style="padding: 0">
          <nz-form-label nzRequired style="left: 10px"
            >Group Name</nz-form-label
          >
          <nz-form-control nzErrorTip="Group Name is a required field">
            <input nz-input type="text" [formControl]="this.nameControl" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="padding: 0">
          <nz-form-label style="left: 10px">Comment</nz-form-label>
          <nz-form-control>
            <textarea
              nz-input
              type="text"
              [formControl]="this.commentControl"
            ></textarea>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="padding: 0">
          <nz-form-label nzRequired style="left: 10px"
            >Select Jobs</nz-form-label
          >
          <nz-form-control nzErrorTip="You must select 1 or more jobs">
            <nz-select
              nzShowSearch
              nzMode="multiple"
              style="width: 100%"
              [formControl]="this.jobsControl"
              [nzLoading]="this.gettingParentJobs"
            >
              <nz-option
                *ngFor="let job of this.parentJobs"
                [nzLabel]="job.job_name"
                [nzValue]="job.id"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </nz-spin>
  </ng-container>
  <div *nzModalFooter>
    <button
      nz-button
      nzType="default"
      (click)="isVisible = false; resetFields()"
      [disabled]="this.creating_group"
    >
      Cancel
    </button>
    <button
      nz-button
      nzType="primary"
      (click)="createGroup()"
      [disabled]="this.creating_group"
    >
      Create Group
    </button>
  </div>
</nz-modal>
