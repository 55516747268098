<div>
  <app-proj-sidebar></app-proj-sidebar>
  <div
    class="container-fluid"
    style="padding-left: 85px; display: flex; justify-content: space-between"
  >
    <div style="flex-grow: 1">
      <h6 class="project-title">
        {{ project_name }}
        <span class="actions" *ngIf="!authService.isDemoUser()">
          <button
            class="actions__item zmdi zmdi-edit"
            (click)="openProjectDialog()"
          ></button>
        </span>
      </h6>
      <small>
        {{ project_description }}
      </small>
    </div>

    <button nz-button nzType="default" (click)="add_job_modal = true">
      <span nz-icon nzType="plus" nzTheme="outline"></span>
    </button>
  </div>
  <router-outlet></router-outlet>

  <nz-modal
    [(nzVisible)]="add_job_modal"
    [nzTitle]="'Add Job to project ' + this.project_name"
    (nzOnCancel)="add_job_modal = false"
    (nzOnOk)="addJobsToProject()"
    [nzWidth]="700"
  >
    <ng-container *nzModalContent>
      <nz-spin [nzSpinning]="isLoading">
        Provide all the paths for the jobs you would like to add:
        <ng-container *ngFor="let entry of this.job_entries">
          <nz-input-group style="padding: 0" class="legacy-input-styling">
            <label style="left: 10px"> Job Base Path </label>
            <input nz-input [formControl]="entry.jobpath" />
          </nz-input-group>
          <div style="display: flex; justify-content: space-between">
            <nz-input-group
              style="padding: 0; width: 320px"
              class="legacy-input-styling"
            >
              <label style="left: 10px"> Parent Job </label>
              <nz-select
                style="width: 320px"
                [formControl]="entry.parent"
                nzShowSearch
                [nzFilterOption]="searchFn"
              >
                <nz-option nzLabel="No Parent Job"></nz-option>
                <nz-option
                  *ngFor="let job of this.parentJobs"
                  [nzLabel]="job.job_name"
                  [nzValue]="job"
                ></nz-option>
              </nz-select>
            </nz-input-group>
            <nz-input-group
              style="padding: 0; width: 320px"
              class="legacy-input-styling"
            >
              <label style="left: 10px"> Parent CP Number </label>
              <nz-input-number
                style="width: 100%"
                nz-input
                type="number"
                [formControl]="entry.parent_cp"
                [nzMin]="0"
                [nzMax]="parent_max_iteration"
              ></nz-input-number>
            </nz-input-group>
          </div>

          <nz-form-item>
            <nz-form-control
              nzExtra="In the case that NX2 > NX1, you may want to swap the 2 axis for visualization. Click on the checkbox if you'd like to do so."
            >
              <label nz-checkbox [formControl]="entry.x2_is_inline"
                >Swap Dimensions?</label
              >
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <!-- <button nz-button nzType="dashed" nzBlock (click)="addEntry()">
          Add
        </button> -->
      </nz-spin>
    </ng-container>
  </nz-modal>
</div>
