import { throwError as observableThrowError, Observable } from "rxjs";

import { finalize, tap, catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";

interface RequestOptionsCustom {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: any;
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}

@Injectable()
export class HttpService extends HttpClient {
  constructor(
    backend: HttpBackend,
    // defaultOptions: RequestOptions,
    private router: Router,
    private authService: AuthService
  ) {
    super(backend);
  }

  get(url: string, options?: any): Observable<any> {
    return super.get(url, options).pipe(
      retry(3),
      catchError(this.onCatch),
      tap(
        (res: HttpResponse<any>) => {
          this.onSuccess(res);
        },
        (error: HttpErrorResponse) => {
          this.onError(error);
        }
      ),
      finalize(() => {
        this.onEnd();
      })
    );
  }

  // post(url: string, body: any, options?: { headers?: HttpHeaders | { [header: string]: string | string[]; }}): Observable<any> {
  post(
    url: string,
    body: any,
    options?: any
  ): Observable<any> {
    return super.post(url, body, options).pipe(
      catchError(this.onCatch),
      tap(
        (res: HttpResponse<any>) => {
          this.onSuccess(res);
        },
        (error: any) => {
          this.onError(error);
        }
      ),
      finalize(() => {
        this.onEnd();
      })
    );
  }

  private onCatch(error: any, caught: Observable<any>): Observable<any> {
    return observableThrowError(error);
  }

  private onSuccess(res: HttpResponse<any>): void {}

  private onError(res: HttpErrorResponse): void {
    if (res.status === 401 || res.status === 403) {
      this.authService.logout();
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: this.router.url },
      });
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.log("An error cc");
    } else {
      this.authService.logout();
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: this.router.url },
      });
    }
  }

  private onEnd(): void {}
}
