<h3 mat-dialog-title>Warning</h3>

<div style="height: 75%; display: flex; flex-direction: column; justify-content: space-between;"
  id="data-table-rebuild-dialog">
  <div>
    This is going to rebuild the data table for <br>
    <strong>{{this.data.project_name}}</strong> <br>
    by parsing the key and log files for each job. <br>
    The process may take a while. <br>
    Do you confirm this action?
  </div>

  <div class="form-input" id="folderPaths" #folderPaths>
    <label>
      <input required>
      <span class="placeholder">Folder Path</span>
    </label>
  </div>
  <i class="zmdi zmdi-plus " (click)="this.addNewPath()"></i>

  <div style="display: flex; justify-content: space-around; ">
    <button mat-raised-button color="primary" type="submit" (click)="submit()">Confirm</button>
    <button mat-raised-button color="basic" type="reset" (click)="cancel()">Cancel</button>
  </div>
</div>