<section [ngClass]="small ? 'banner small' : 'banner'">
  <div class="container">
    <div class="row">
      <div class="info">
        <div class="col-lg-12 col-md-8 col-sm-10 col-xs-12 text-left">
          <div
            style="
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              flex-wrap: wrap;
            "
          >
            <div>
              <p class="heading os-animation fadeInUp animated">
                {{ heading }}
              </p>
              <div
                *ngIf="!!description"
                class="text c5-text os-animation fadeInUp animated"
                data-os-animation="fadeInUp"
                data-os-animation-delay="0.2s"
                style="animation-delay: 0.2s"
              >
                <p>{{ description }}</p>
              </div>
            </div>

            <div style="display: flex; gap: 20px">
              <button
                *ngIf="buttonText"
                class="btn no-bg c5-borders c5-text"
                (click)="buttonPressed()"
                tabindex="0"
              >
                {{ buttonText }}
              </button>
              <button
                class="btn no-bg c5-borders c5-text"
                (click)="goToProjectPage()"
                tabindex="0"
              >
                Project Page
              </button>
              <button
                *ngIf="this.isStaff"
                class="btn no-bg c5-borders c5-text"
                (click)="goToCostAnalysis()"
                tabindex="0"
              >
                Cost Analysis
              </button>
            </div>
          </div>
          <div
            class="os-animation fadeInUp animated"
            data-os-animation="fadeInUp"
            data-os-animation-delay="0.3s"
            style="animation-delay: 0.3s"
          >
            <form
              nz-form
              nzLayout="vertical"
              style="display: flex; gap: 20px; flex-wrap: wrap"
            >
              <nz-input-group style="flex: 1 1 40%">
                <label style="color: white; font-size: 20px; margin: 0">
                  Project
                </label>
                <nz-select
                  nzSize="large"
                  style="width: 100%; color: white"
                  nzShowSearch
                  [formControl]="projectControl"
                  (ngModelChange)="projectSelected2($event)"
                >
                  <nz-option
                    *ngFor="let project of projects"
                    [nzValue]="project.name"
                    [nzLabel]="project.name"
                  >
                    {{ project.name }}
                  </nz-option>
                </nz-select>
              </nz-input-group>
              <nz-input-group nzSize="large" style="flex: 1 1 40%">
                <label style="color: white; font-size: 20px; margin: 0">
                  Job Name
                </label>
                <nz-select
                  nzSize="large"
                  style="width: 100%; color: white"
                  nzShowSearch
                  [formControl]="jobControl"
                  (ngModelChange)="jobSelected2($event)"
                >
                  <nz-option
                    *ngFor="let job of jobs"
                    [nzValue]="job.name"
                    [nzLabel]="job.name"
                  >
                  </nz-option>
                </nz-select>
              </nz-input-group>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
