<div>
  <div class="row">
    <div class="col-md-12 total-costs">
      <div class="cost-details row" *ngIf="!!jobDetail">
        <div class="col-md-3">
          <div class="des-box blue">
            <div class="des-icon">
              <img src="/assets/icons/costs/icon02.png" alt="" />
            </div>
            <div class="des-detail">
              <h2>Iterations</h2>
              <p>{{ sliceSettings.iteration }} / {{ jobDetail.iterations }}</p>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="hasCosts && isStaff">
          <div class="col-md-3" *ngIf="jobDetail.status == 'RUNNING'">
            <div class="des-box">
              <div class="des-icon">
                <img src="/assets/icons/costs/icon01.png" alt="" />
              </div>
              <div class="des-detail">
                <h2>Elapsed Time</h2>
                <p>{{ elapsedTime }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="des-box light-blue">
              <div class="des-icon">
                <img src="/assets/icons/costs/icon04.png" alt="" />
              </div>
              <div class="des-detail">
                <h2>Cost / Iteration</h2>
                <p *ngIf="costPerIteration > 2">
                  {{ costPerIteration | currency: "USD":"symbol":"1.0-0" }}
                </p>
                <p *ngIf="costPerIteration <= 2">
                  {{ costPerIteration | currency: "USD" }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="des-box purple">
              <div class="des-icon">
                <img
                  src="/assets/icons/costs/icon06.png"
                  alt=""
                  style="padding: 13px"
                />
              </div>
              <div class="des-detail">
                <h2>Cumulative Cost</h2>
                <p>
                  {{
                    costPerIteration * iteration
                      | currency: "USD":"symbol":"1.0-0"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div id="slice-settings" class="col-md-12">
      <h4 class="card-title">Slice Settings</h4>
      <div class="row">
        <div class="col-md-8" style="padding: 0">
          <div class="sliders form-horizontal">
            <div *ngIf="!jobDetail.modelGrid.is2d">
              <div class="form-group">
                <div class="row">
                  <!-- <div class="col-xs-2 text-right"><label>Inline</label></div> -->
                  <div class="col-xs-12">
                    <app-slider
                      label="Inline"
                      [antd]="true"
                      [min]="1"
                      [max]="this.jobDetail.modelGrid.nx1"
                      [step]="steps[0]"
                      [(value)]="sliceSettings.inline"
                      (valueChange)="onSliderChanged()"
                    ></app-slider>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-xs-2 text-right">
                    <label>Crossline</label>
                  </div>
                  <div class="col-xs-10">
                    <app-slider
                      [min]="1"
                      [max]="this.jobDetail.modelGrid.nx2"
                      [step]="steps[1]"
                      [(value)]="sliceSettings.crossline"
                      (valueChange)="onSliderChanged('crossline')"
                    ></app-slider>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-xs-2 text-right">
                    <label>Depth Slice</label>
                  </div>
                  <div class="col-xs-10">
                    <app-slider
                      [min]="1"
                      [max]="this.jobDetail.modelGrid.nx3"
                      [step]="steps[2]"
                      [(value)]="sliceSettings.depth"
                      (valueChange)="onSliderChanged('depth')"
                    ></app-slider>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group iteration-slider">
              <div class="row">
                <div class="col-xs-2 text-right"><label>Iteration</label></div>
                <div class="col-xs-10">
                  <app-slider
                    [min]="0"
                    [max]="jobDetail.iterations"
                    [completed]="jobDetail.iterationsComplete"
                    [(value)]="sliceSettings.iteration"
                    [step]="steps[3]"
                    (valueChange)="onSliderChanged('iteration')"
                  ></app-slider>
                </div>
              </div>
            </div>
            <!-- <div
              class="form-group iteration-slider"
              *ngIf="
                timeSliceModels.includes(currentModelTypeId) &&
                availTimeSlices.length > 0
              "
            >
              <label class="col-sm-2">Time Slice</label>
              <app-slider
                class="col-sm-10"
                [min]="availTimeSlices[0]"
                [max]="availTimeSlices[availTimeSlices.length - 1]"
                [(value)]="timeSlice"
                [step]="getTimeSliceInterval()"
                (valueChange)="onSliderChanged()"
              ></app-slider>
            </div> -->
            <!-- <div
              class="form-group iteration-slider"
              style="visibility: hidden"
              *ngIf="
                !(
                  timeSliceModels.includes(currentModelTypeId) &&
                  availTimeSlices.length > 0
                )
              "
            >
              <label class="col-sm-2">Padding use</label>
              <app-slider class="col-sm-10"></app-slider>
            </div> -->
          </div>
        </div>
        <div
          class="col-md-4 center-box cube-chart hidden-xs"
          style="padding: 0"
          *ngIf="!jobDetail.modelGrid.is2d"
        >
          <app-slice-settings-chart
            [ranges]="ranges"
            [jobDetail]="jobDetail"
          ></app-slice-settings-chart>
        </div>
      </div>
    </div>
  </div>
</div>
