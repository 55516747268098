<div #chartContainer>
  <div class="title-row">
    <div>
      <span class="card-title">
        {{ getTitleForSliceType(slice.sliceType) }}&emsp;&emsp;{{
          getWellName(selectedSonicId)
        }}
      </span>
    </div>
    <div class="spacer"></div>
    <div>
      <div class="chart-controls">
        <button (click)="resetClicked()" title="Reset">
          <i class="zmdi zmdi-undo"></i>
        </button>
        <button (click)="zoomInClicked()" title="Zoom In">
          <i class="zmdi zmdi-zoom-in"></i>
        </button>
        <button (click)="zoomOutClicked()" title="Zoom Out">
          <i class="zmdi zmdi-zoom-out"></i>
        </button>
      </div>
    </div>
    <div *ngIf="showChartMenu">
      <app-model-chart-menu
        [jobDetail]="jobDetail"
        [settingsKey]="settingsKey"
        [slice]="slice"
        [palettes]="palettes"
        [iteration]="sliceSettings.iteration"
        [inline]="sliceSettings.inline"
        [crossline]="sliceSettings.crossline"
        [depth]="sliceSettings.depth"
        [modelTypeId]="currentModelTypeId"
        [modelTypes]="modelTypes"
        [shotModels]="shotModels"
        [shotOverlayTypes]="shotOverlayTypes"
        [maxDepth]="maxDepth"
        (menuChanged)="onMenuChanged($event)"
      ></app-model-chart-menu>
    </div>
  </div>
  {{ this.paletteOfCurrentType }}
  <app-chart-slice
    [shot]="shotId"
    [id]="jobDetail.id"
    [ranges]="sliceChartSettings.ranges"
    [xSteps]="sliceChartSettings.xSteps"
    [ySteps]="sliceChartSettings.ySteps"
    [sliceType]="sliceChartSettings.sliceType"
    [iteration]="sliceSettings.iteration"
    [sliceNumber]="sliceChartSettings.sliceNumber"
    [xTitle]="sliceChartSettings.xTitle"
    [yTitle]="sliceChartSettings.yTitle"
    [updateChart]="updateChart"
    [refreshChart]="refreshChart"
    [height]="chartHeight"
    [is2d]="jobDetail.modelGrid.is2d"
    [xLine]="sliceChartSettings.xLine"
    [yLine]="sliceChartSettings.yLine"
    [showLegend]="inlineWidth == 100"
    [palette]="paletteOfCurrentType"
    [sonicLogId]="sliceChartSettings.sonicLogId"
    [dx]="this.jobDetail.modelGrid.dx"
    [sonicNX]="sliceChartSettings.sonicNX"
    [sonicWidth]="sliceChartSettings.sonicWidth"
    [hoverLabelX]="sliceChartSettings.hoverLabelX"
    [hoverLabelY]="sliceChartSettings.hoverLabelY"
    [velocityRange]="sliceChartSettings.velocityRange"
    [modelType]="currentModelTypeId"
    [shotOverlayType]="currentShotOverlayType"
    [showShotOverlay]="sliceChartSettings.showShotOverlay"
    [showHorizons]="sliceChartSettings.showHorizons"
    [totalIterations]="jobDetail.iterations"
    [x2IsInline]="jobDetail.x2_is_inline"
    [isVpReference]="vpUpdateCheck"
    [vpIteration]="vpDiffIteration"
    [timeSlice]="timeSlice"
    [hideControls]="true"
  ></app-chart-slice>
</div>
