<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <title>File Viewer</title>
</head>
<div style="display: flex;">
  <mat-checkbox [(ngModel)]="splitByName">
    <span>Split</span>
  </mat-checkbox>
  <div *ngIf="currentJob.storage_cost != null" style="margin-top: 2px; margin-left: 40%">Storage Cost: {{currentJob.storage_cost.toFixed(4)}}</div>
</div>
<div *ngIf="!splitByName" class="card" style="margin: 15px 15px; background-color: #f3f3f3;">
  <!-- <h1 style="margin-left: 7%; color:#2196f3">File Viewer</h1> -->
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <input type="text" [(ngModel)]="name" placeholder="Search by Name" style="text-align: center; margin-left: 5%; height: 30px;" (change)="updateFilteredFiles()"/>
    <mat-paginator class="paginator" [pageSizeOptions]="[5, 20, 30, 50]"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [length]="totalItems"
      (page)="onPageChange($event)"
      style="background-color: #f3f3f3;">
    </mat-paginator>
  </div>
  
  <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
    <table class="files-list">
      <thead>
        <tr>
          <th (click)="sortByName()">File Name
            <i *ngIf="nameOrder=='ascending'" class="zmdi zmdi-chevron-up"></i>
            <i *ngIf="nameOrder=='descending'" class="zmdi zmdi-chevron-down"></i>
          </th>
          <th (click)="sortByIter()">Iteration
            <i *ngIf="iterOrder=='ascending'" class="zmdi zmdi-chevron-up"></i>
            <i *ngIf="iterOrder=='descending'" class="zmdi zmdi-chevron-down"></i>
          </th>
          <th (click)="sortByType()">Type
            <i *ngIf="typeOrder=='ascending'" class="zmdi zmdi-chevron-up"></i>
            <i *ngIf="typeOrder=='descending'" class="zmdi zmdi-chevron-down"></i>
          </th>
          <th (click)="sortBySize()">Size
            <i *ngIf="sizeOrder=='ascending'" class="zmdi zmdi-chevron-up"></i>
            <i *ngIf="sizeOrder=='descending'" class="zmdi zmdi-chevron-down"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="files-list-entry" *ngFor="let file of pagedFiles;let i = index">
          <td class="filename">{{ file.name }}</td>
          <td class="fileiter">{{ file.iteration }}</td>
          <td class="filetype">{{ file.type }}</td>
          <td class="filesize">{{ file.size.toPrecision(4) + file.unit }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  
</div>
<div *ngIf="splitByName && splitReady" class="card" style="margin: 15px 15px; background-color: #f3f3f3;">
  <div *ngFor="let name of names">
    <div *ngIf="name2Files.get(name).length > 0">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <mat-label style="font-size: large;">{{name}}</mat-label>
        <input type="text" [(ngModel)]="name2Name[name]" placeholder="Search By Name" style="text-align: center; margin-left: 5%; height: 30px;" (change)="updateSplitFilteredFiles(name)"/>
        <mat-paginator class="paginator" [pageSizeOptions]="[5, 20, 30, 50]"
          [pageSize]="name2Misc.get(name).pageSize"
          [pageIndex]="name2Misc.get(name).pageIndex"
          [length]="name2Misc.get(name).totalSize"
          (page)="onSplitPageChange($event,name)"
          style="background-color: #f3f3f3;">
        </mat-paginator>
      </div>
      <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <table class="files-list">
          <thead>
            <tr>
              <th (click)="sortSplitByName(name)">File Name
                <i *ngIf="name2Misc.get(name).nameOrder=='ascending'" class="zmdi zmdi-chevron-up"></i>
                <i *ngIf="name2Misc.get(name).nameOrder=='descending'" class="zmdi zmdi-chevron-down"></i>
              </th>
              <th (click)="sortSplitByIter(name)">Iteration
                <i *ngIf="name2Misc.get(name).iterOrder=='ascending'" class="zmdi zmdi-chevron-up"></i>
                <i *ngIf="name2Misc.get(name).iterOrder=='descending'" class="zmdi zmdi-chevron-down"></i>
              </th>
              <th (click)="sortSplitByType(name)">Type
                <i *ngIf="name2Misc.get(name).typeOrder=='ascending'" class="zmdi zmdi-chevron-up"></i>
                <i *ngIf="name2Misc.get(name).typeOrder=='descending'" class="zmdi zmdi-chevron-down"></i>
              </th>
              <th (click)="sortSplitBySize(name)">Size
                <i *ngIf="name2Misc.get(name).sizeOrder=='ascending'" class="zmdi zmdi-chevron-up"></i>
                <i *ngIf="name2Misc.get(name).sizeOrder=='descending'" class="zmdi zmdi-chevron-down"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="files-list-entry" *ngFor="let file of name2Misc.get(name).pagedFiles;let i = index">
              <td class="filename">{{ file.name }}</td>
              <td class="fileiter">{{ file.iteration }}</td>
              <td class="filetype">{{ file.type }}</td>
              <td class="filesize">{{ file.size.toPrecision(4) + file.unit }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</html>
