<h3 mat-dialog-title>Warning</h3>

<div style="height: 75%; display: flex; flex-direction: column; justify-content: space-between;">
    <div>
        <div *ngIf="keep_job">
            You are marking <br>
            <strong>{{job_name}}</strong> <br>
            to be <strong>not kept</strong>. <br>
            This means it will be cleaned up based on the defined scheme for its job type, and can't be recovered easily. <br>
            Do you confirm this action?
        </div>
        <div *ngIf="!keep_job">
            You are marking <br>
            <strong>{{job_name}}</strong> <br>
            to be <strong>kept</strong>. <br>
            This means it will not be cleaned up based on the defined scheme for its job type. <br>
            Do you confirm this action?
        </div>
    </div>
    <div style="display: flex; justify-content: space-around; ">
        <button mat-raised-button color="primary" type="submit" (click)="submit()">Confirm</button>
        <button mat-raised-button color="basic" type="reset" (click)="cancel()">Cancel</button>
    </div>
</div>