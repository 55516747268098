<div class="container-fluid">
  <app-custom-table
    [data]="this.data"
    [loading]="this.is_loading"
    [columns]="this.options"
    [truncationFields]="['job_name']"
    [removalColumns]="['job_id', 'project_id']"
    [isParameterView]="true"
    [linkDefinitions]="this.linkDefinitions"
    [buttonConfig]="this.buttonConfig"
    [editRow]="true"
    (editRowHandler)="editComment($event)"
  >
    <button (click)="refreshTable()" mat-button>Refresh Table</button>
    <button (click)="rebuildTable()" mat-button>Rebuild Table</button>
  </app-custom-table>
</div>
