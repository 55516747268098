import { NgModule } from "@angular/core";
import { DemoPageComponent } from "./demo-page/demo-page.component";
import { MaterialModule } from "../shared/material.module";
import { ProjectModule } from "../projects/project.module";
import { DemoPageHistory } from "./demo-page/demo-page-history.service";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  imports: [SharedModule, ProjectModule, MaterialModule],
  declarations: [DemoPageComponent],
  exports: [DemoPageComponent],
  providers: [DemoPageHistory],
})
export class DemoModule {}
