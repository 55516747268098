import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CachedProjectService } from "../../shared/services/cached-project.service";
import { Title } from "@angular/platform-browser";
import { FormBuilder, NonNullableFormBuilder } from "@angular/forms";

@Component({
  selector: "app-project-view",
  templateUrl: "./project-view.component.html",
  styleUrls: ["./project-view.component.css"],
})
export class ProjectViewComponent implements OnInit {
  project_id: string = "";
  project_name: string = "";
  project_description: string = "";

  add_job_modal: boolean = false;
  job_entries: Array<any> = [];
  isLoading: boolean = false;
  parentJobs: Array<any> = [];

  constructor(
    private route: ActivatedRoute,
    private cachedProjectService: CachedProjectService,
    private titleService: Title,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.cachedProjectService
          .getProjectById(params.id)
          .toPromise()
          .then((data) => {
            this.project_id = data["id"];
            this.getParentJobs();
            this.project_name = data["name"];
            this.project_description = data["description"];
            this.cachedProjectService.setCurrentProject(data);
            this.titleService.setTitle(this.project_name);
          });
      }
    });

    this.job_entries.push({
      jobpath: this.fb.control(""),
      parent: this.fb.control(null),
    });
  }

  getParentJobs() {
    this.cachedProjectService
      ._getProjectJobs(this.project_id, {
        fields: ["id", "job_name", "iterations_completed", "project__name"],
      })
      .toPromise()
      .then((res) => {
        this.parentJobs = res.data;
      })
      .catch((err) => console.log(err));
  }

  addJobsToProject() {
    console.log(
      "add jobs to project with it: ",
      this.project_id,
      this.project_name
    );
    console.log("Result", this.job_entries);
  }

  addEntry() {
    console.log("adding entry");
    this.job_entries.push({
      jobpath: this.fb.control(""),
      parent: this.fb.control(null),
    });
  }
}
