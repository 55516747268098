import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface RebuildTableDialogData {
    project_name: string;
}

@Component({
    selector: 'app-rebuild-table',
    templateUrl: './rebuild-project-table.component.html',
    styleUrls: ['./rebuild-project-table.component.css']
})
export class RebuildTableComponent implements OnInit {
    project_name: string;
    keep_job_setting_form: UntypedFormGroup;
    constructor(@Inject(MAT_DIALOG_DATA) public data: RebuildTableDialogData, public dialogRef: MatDialogRef<RebuildTableComponent>, public fb: UntypedFormBuilder ) {
        this.keep_job_setting_form = this.fb.group({
        })
        this.project_name = this.data.project_name;
    }

    ngOnInit() {
    }

    submit() {
        this.dialogRef.close({confirm: true});
    }

    cancel() {
        this.dialogRef.close({confirm: false});
    }
}