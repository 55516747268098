import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { StoredSettingsService } from '../../../services/stored-settings.service';
import { chartSettings } from '../../../../models/chartSettings';
import { SettingTypes } from '../../../../shared/enums/settingTypes';
import { StoredSetting } from '../../../../models/storedSetting';
@Component({
  selector: 'app-multi-graph',
  templateUrl: './multi-graph.component.html',
  styleUrls: ['./multi-graph.component.css']
})
export class MultiGraphComponent implements OnInit {

  @Input() xData: number[];
  @Input() yData: {data: number[], std: number[], lineColor: string, name: string}[];
  @Input() displayingIndices: number[];
  @Input() wideGraph: boolean = false;
  @Input() scale: number[] = null;
  @Output('point') point: EventEmitter<{index: number, types: number[]}> = new EventEmitter<{index: number, types: number[]}>();
  @ViewChild('graph', { static: true }) graph: ElementRef;
  @ViewChild('graphContainer', { static: true }) container: ElementRef;

  selectedTypes = []
  plotTypes = []
  showStd = false

  // just for storing std
  settingsKey = "charts";
  settingsId = "std"

  private width: number;
  private height: number;
  private margin = {top: 0.1, right: 0.1, bottom: 0.1, left: 0.12};
  private svg;
  private xScale;
  private yScale;
  private legendPostion = 0;
  private lines =[]
  private selectedNode: number;

  constructor(
    private storedSettingsService: StoredSettingsService,
  ) { }

  ngOnInit() {
    let settings = this.storedSettingsService.getSettings<chartSettings>(this.settingsKey, this.settingsId, SettingTypes.ChartSettings);
    if (settings != null) {
      this.showStd = settings.showStd;
    }
    this.selectedTypes = this.displayingIndices;
    this.getPlotTypes();
  }

  ngAfterViewInit() {
    this.setUpCanvas();
    this.drawSelectedLines()
  }

  getPlotTypes() {
    this.plotTypes = this.yData.map((d, i) => {
      return {name: d.name, index: i};
    });
  }

  changeType(plotType, event) {
    event.stopPropagation()
    let index = plotType.index;
    if (this.selectedTypes.includes(index)) {
      let indexPostion = this.selectedTypes.indexOf(index);
      if (indexPostion >= 0) {
        this.selectedTypes.splice(indexPostion, 1);
      }
    }else {
      this.selectedTypes.push(index);
    }
    this.drawSelectedLines();
  }

  turnOnOffStd() {
    this.showStd = !this.showStd;
    this.drawSelectedLines();
    let settings:chartSettings = {id: this.settingsId, showStd:this.showStd, projectId:null, iteration:null};
    this.storedSettingsService.setSettings(this.settingsKey, settings, SettingTypes.ChartSettings);
  }

  private drawSelectedLines() {
    this.lines.map(l => l.remove());
    this.lines = [];
    this.legendPostion = 0;
    for (let index of this.selectedTypes) {
      let lineColor = this.yData[index].lineColor;
      let yData = this.yData[index].data;
      let std = this.yData[index].std;
      let name = this.yData[index].name;
      this.drawLine(this.xData, yData, std, lineColor, name, ++this.legendPostion);
    }
    this.point.emit({index: this.selectedNode, types: this.selectedTypes});
  }

  private setUpCanvas() {
    let xData = this.xData;
    let yData = this.yData.map(d => d.data).reduce((a, c) => a.concat(c), []).filter(d => d!==null);
    let std = this.yData.map(d => d.std).reduce((a, c) => a.concat(c), []).filter(d => d!==null);
    this.width = this.container.nativeElement.offsetWidth*0.875;
    if (this.wideGraph) {
      this.height = this.width*0.375;
      this.margin.left = 0.07;
    } else {
      this.height = this.width*0.75;
    }

    let xRange = Math.max(...xData)-Math.min(...xData);
    let minX = Math.min(...xData)-0.05*xRange;
    let maxX = Math.max(...xData)+0.05*xRange;

    let minY = Math.min(...yData)
    let maxY = Math.max(...yData)
    if (!!std) {
      std = std.filter(d => d !== null);
      minY = Math.min(...this.arrayOperation(yData, std, (x,y) => x-y/2));
      maxY = Math.max(...this.arrayOperation(yData, std, (x,y) => x+y/2));
    }
    if (minY == maxY) {
      minY *= 0.9;
      maxY *= 1.1;
    }
    if (this.scale[0] != null) {
      minY = this.scale[0];
    }
    if (this.scale[1] != null && this.scale[1] > minY) {
      maxY = this.scale[1];
    }
    let yRange = maxY - minY;
    minY = minY-0.05*yRange;
    maxY =  maxY+0.05*yRange;

    let xScale = d3.scaleLinear()
                   .domain([minX, maxX])
                   .range([0, this.width]);
    let yScale = d3.scaleLinear()
                   .domain([minY, maxY])
                   .range([this.height, 0]);

    let svg = d3.select(this.graph.nativeElement)
                .attr("width", this.width*(1+this.margin.left+this.margin.right))
                .attr("height", this.height*(1+this.margin.top+this.margin.bottom))
                .append("g")
                .attr("transform", 'translate('+this.width*this.margin.left+', '+this.height*this.margin.top+')');
    svg.append("g").attr("transform", 'translate(0,'+this.height+')').call(d3.axisBottom(xScale));
    svg.append("g").call(d3.axisLeft(yScale));
    this.svg = svg;
    this.xScale = xScale;
    this.yScale = yScale;

  }

  private drawLine(xData: number[], yData: number[], std: number[],
    line_color: string, name: string, index: number) {

    let xScale = this.xScale;
    let yScale= this.yScale

    let svg = this.svg.append("g").attr("id", name);
    this.lines.push(svg);
    let data: number[][] = xData.map((e, i) => [e, yData[i]]).filter(d => d[1] !== null);
    std = std.filter(d => d !== null);
    data.sort((a, b) => a[0]-b[0])
    std = std.slice(0, data.length)
    // Add path
    let line = d3.line().x(function(d: number[]) {return xScale(d[0]);})
                        .y(function(d: number[]) {return yScale(d[1]);});

    svg.append("g").selectAll("path").data([data]).enter()
       .append("path")
       .attr("class", "fline")
       .style("stroke", line_color)
       .attr("d",line).attr("clip-path", "url(#rect_clip)")
       .attr("data-legend", () => name);
    // Add nodes
    svg.append("g").selectAll("circle").data(data).enter().append("circle")
       .attr("cx", function(d: number[]) {return xScale(d[0])})
       .attr("cy", function(d: number[]) {;return yScale(d[1])}).attr("r", 5)
       .style("fill", line_color)
       .on("mouseover", function() {d3.select(this).attr("r", 8).style("opacity", 0.7)})
       .on("mouseout", function() {d3.select(this).attr("r", 5).style("opacity", 1)})
       .on("click",(event, d) => {
         this.selectedNode = xData.indexOf(d[0])
         this.point.emit({index: this.selectedNode, types: this.selectedTypes})
        })
       .attr("clip-path", "url(#rect_clip");
    svg.append("clipPath").attr("id", "rect_clip").append("rect").attr("x", 0).attr("y", 0).attr("height", this.height).attr("width", this.width);

    svg.append("text").attr("transform", 'translate('+ (this.width/2) +','+(this.height+40)+')')
       .text("Iteration").style("font-weight", "bold")

    let legend = svg.append("g")
       .attr("transform",`translate(25,${index*15+5})`)

    legend.append("circle")
          .attr("r", 5)
          .style("fill", line_color)

    legend.append("text")
          .attr("x", 20)
          .style("font-size", "12px")
          .attr("alignment-baseline","middle")
          .text(name)

    if (!this.showStd) return;
    //Add error lines
    svg.append("g").selectAll("line").data(std).enter().append("line").attr("class", "errorline")
       .attr("x1", function(_d: number, i: number) { return xScale(data[i][0]) })
       .attr("x2", function(_d: number, i: number) { return xScale(data[i][0]) })
       .attr("y1", function(d: number, i: number) { return yScale(data[i][1]+d/2) })
       .attr("y2", function(d: number, i: number) { return yScale(data[i][1]-d/2) });
    svg.append("g").selectAll("line").data(std).enter().append("line").attr("class", "errorcap")
       .attr("x1", function(_d: number, i: number) { return xScale(data[i][0])-5 })
       .attr("x2", function(_d: number, i: number) { return xScale(data[i][0])+5 })
       .attr("y1", function(d: number, i: number) { return yScale(data[i][1]+d/2) })
       .attr("y2", function(d: number, i: number) { return yScale(data[i][1]+d/2) });
    svg.append("g").selectAll("line").data(std).enter().append("line").attr("class", "errorcap")
       .attr("x1", function(_d: number, i: number) { return xScale(data[i][0])-5 })
       .attr("x2", function(_d: number, i: number) { return xScale(data[i][0])+5 })
       .attr("y1", function(d: number, i: number) { return yScale(data[i][1]-d/2) })
       .attr("y2", function(d: number, i: number) { return yScale(data[i][1]-d/2) });
  }

  private arrayOperation(s: number[], t: number[], operation: (x: number, y: number) => number) {
    if (s.length != t.length) return;
    return s.map((e, i) => operation(e, t[i]));
  }


}
