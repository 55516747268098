<nav id="sidebar">
  <ul class="list-unstyled components">
    <li routerLinkActive="active">
      <a routerLink="parameter-view"
        ><i class="zmdi zmdi-filter-list"></i>
        <div class="hidden-xs">Parameter View</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="data-table"
        ><i class="zmdi zmdi-grid"></i>
        <div class="hidden-xs">Data Table</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="model-table"
        ><i class="zmdi zmdi-shape"></i>
        <div class="hidden-xs">Model Table</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="flowchart"
        ><i class="zmdi zmdi-chart"></i>
        <div class="hidden-xs">Model Flowchart</div></a
      >
    </li>
    <!-- <li routerLinkActive="active">
      <a routerLink="parameter-view"
        ><i class="zmdi zmdi-filter-list"></i>
        <div class="hidden-xs">Parameter View</div></a
      >
    </li> -->
    <li routerLinkActive="active">
      <a routerLink="table"
        ><i class="zmdi zmdi-view-list"></i>
        <div class="hidden-xs">Storage Table</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="image-panel"
        ><i class="zmdi zmdi-download"></i>
        <div class="hidden-xs">Image Download</div></a
      >
    </li>
  </ul>
</nav>
