<div>
  <div
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <div>
      <b>Project total storage cost:</b>
      {{ this.project.project_cost.toFixed(4) }}$ per month
      <nz-divider nzType="vertical" />
      <b>Project potential savings:</b>
      {{ this.project.potential_project_savings.toFixed(4) }}$ per month
    </div>
    <div style="display: flex; gap: 10px">
      <button
        nz-button
        nzType="primary"
        [disabled]="setOfCheckedId.size === 0"
        [nzLoading]="isLoading"
        nz-popconfirm
        nzPopconfirmTitle="Are you sure you want to keep all selected jobs?"
        (nzOnConfirm)="keepAllSelected()"
      >
        Keep for Selected Jobs
      </button>
      <button
        nz-button
        nzType="default"
        [disabled]="setOfCheckedId.size === 0"
        [nzLoading]="isLoading"
        nz-popconfirm
        nzPopconfirmTitle="Are you sure you want to unkeep all selected jobs?"
        (nzOnConfirm)="unkeepAllSelected()"
      >
        Unkeep for Selected Jobs
      </button>
    </div>
  </div>
</div>
<nz-table
  #innerTable
  nzSize="small"
  nzShowPagination
  nzShowSizeChanger
  nzTableLayout="fixed"
  [nzData]="this.jobCostsData"
  [nzLoading]="isLoading"
  (nzCurrentPageDataChange)="onCurrentPageDataChange()"
>
  <thead>
    <tr>
      <th
        [nzChecked]="checked"
        [nzIndeterminate]="indeterminate"
        nzLabel="Select all"
        (nzCheckedChange)="onAllChecked($event)"
        nzWidth="35px"
      ></th>
      <ng-container *ngFor="let column of this.listOfInnerColumns">
        <th
          [nzSortOrder]="column.sortOrder"
          [nzSortFn]="column.sortFn"
          [nzSortDirections]="column.sortDirections"
          [nzFilterMultiple]="column.filterMultiple"
          [width]="column.width"
        >
          {{ column.name }}
        </th>
      </ng-container>
      <th nzWidth="100px">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let innerData of innerTable.data">
      <tr>
        <td
          [nzChecked]="setOfCheckedId.has(innerData.job_id)"
          [nzLabel]="innerData.job_name"
          (nzCheckedChange)="onItemChecked(innerData.job_id, $event)"
        ></td>
        <td nzEllipsis>
          <a [href]="'projects/' + innerData.job_id + '/model'" target="_blank">
            {{ innerData.job_name }}
          </a>
        </td>
        <td>{{ innerData.soft_archive_status }}</td>
        <td>
          <div style="display: flex; justify-content: space-between">
            <span>
              {{ innerData.storage_cost }}
            </span>
            <span> $ per month </span>
          </div>
        </td>
        <td>
          <div style="display: flex; justify-content: space-between">
            <span>
              {{ innerData.potential_savings || 0 }}
            </span>
            <span> $ per month </span>
          </div>
        </td>
        <td>
          <a
            *ngIf="innerData.soft_archive_status != 'keep'"
            nz-popconfirm
            nzPopconfirmTitle="Are you sure you want to keep this job?"
            (nzOnConfirm)="keepJob(innerData)"
            >Keep</a
          >
          <a
            *ngIf="innerData.soft_archive_status == 'keep'"
            nz-popconfirm
            nzPopconfirmTitle="Are you sure you don't want to keep this job?"
            (nzOnConfirm)="unkeepJob(innerData)"
            >Unkeep</a
          >
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>
