import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface EpicmodCommentsDialogData {
  epicmod_comments: string;
  epicmod_name: string;
}

@Component({
  selector: 'app-epicmod-comments',
  templateUrl: './epicmod-comments.component.html',
  styleUrls: ['./epicmod-comments.component.css']
})
export class EpicmodCommentsComponent implements OnInit {
  epicmod_comments: string;
  epicmod_name: string;
  epicmod_comments_setting_form: UntypedFormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: EpicmodCommentsDialogData, public dialogRef: MatDialogRef<EpicmodCommentsComponent>, public fb: UntypedFormBuilder ) {
    console.log(data.epicmod_comments)
    this.epicmod_comments_setting_form = this.fb.group({
      comments: [this.data.epicmod_comments]
    })
  }

  ngOnInit() {
  }

  submit() {
    this.data.epicmod_comments = this.epicmod_comments_setting_form.get('comments').value;
    console.log(this.data);
    this.dialogRef.close(this.data);
  }
}
