<div>
  <nz-spin [nzSpinning]="isLoading">
    <form
      nz-form
      (ngSubmit)="projectInformationSubmit()"
      class="form-container"
      [formGroup]="projectInfoControl"
    >
      <nz-form-item>
        <nz-form-label nzRequired>Project Name</nz-form-label>
        <nz-form-control nzErrorTip="Please input Project Name">
          <nz-input-group>
            <input
              type="text"
              nz-input
              formControlName="name"
              placeholder="Project Name"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>Project Description</nz-form-label>
        <nz-form-control>
          <nz-input-group>
            <textarea
              type="text"
              nz-input
              formControlName="description"
              placeholder="Project Description"
            ></textarea>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzRequired>Project Field</nz-form-label>
        <nz-form-control nzErrorTip="Please Select Field!">
          <nz-input-group>
            <!-- <input type="text" nz-input formControlName="username" placeholder="Username" /> -->
            <nz-select
              id="field"
              formControlName="field"
              nzPlaceHolder="Select Field"
              [compareWith]="fieldCompareFn"
            >
              <nz-option
                *ngFor="let field of fields"
                [nzValue]="field"
                [nzLabel]="field.name"
              ></nz-option>
            </nz-select>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <button nz-button type="submit" nzType="primary">Update</button>
    </form>
  </nz-spin>
</div>
