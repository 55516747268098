export const header_mappings = {
    'ADD TO DEPTH': ['datum', 'zdatum', 'depthdatum', 'add2z', 'addtoz', 'add2depth', 'addtodepth'],
    'ANGLE': ['angle', 'rotate'],
    'DEBUG': ['diagnose', 'debug'],
    'DELAY BOTH': ['delay', 'static', 'delayboth', 'staticboth'],
    'DELAY DATA': ['dstatic', 'datastatic', 'delaydata', 'datadelay'],
    'DELAY SOURCE': ['sourstatic', 'delaysour', 'sourdelay', 'sstatic', 'sourcestatic', 'delaysource', 'sourcedelay', 'srcstatic', 'delaysrc', 'srcdelay'],
    'DEPTH SCALE': ['zscale', 'depthscale', 'vertscale', 'scalez'],
    'DEPTH TYPE': ['ztype', 'typez', 'depthtype', 'typedepth'],
    'DX': ['dx', 'delta', 'gridspacing'],
    'DZ': ['dz', 'deltaz'],
    'EDGE GAP': ['edge', 'edges', 'skipedge', 'edgegap'],
    'FIXED ARRAY': ['fixedarray', 'fixed', 'fixedchans', 'fix'],
    'GEOMETRY': ['geom', 'geometry'],
    'HIGH CUT': ['hicut', 'filter', 'hifilter', 'highfilter', 'highcut', 'antialias'],
    'HIGH ROLL': ['hiroll', 'hirolloff', 'highroll', 'highrolloff', 'rolloff'],
    'IO': ['IOAPI'],
    'KILL HEADER 2': ['excludehead2', 'killhead2', 'killheader2'],
    'KILL HEADER 4': ['excludehead4', 'killhead4', 'killheader4'],
    'KILL VALUE 2': ['excludevalue2', 'killvalue2'],
    'KILL VALUE 4': ['excludevalue4', 'killvalue4'],
    'LEFT2RIGHT': ['lefttoright', 'right', 'toright'],
    'LOW CUT': ['locut', 'lofilter', 'lowfilter', 'lowcut'],
    'LOW ROLL': ['loroll', 'lorolloff', 'lowroll', 'lowrolloff'],
    'MAX ANGLE': ['maxfeather'],
    'MAX OFFSET': ['maxoffset', 'maximumoffset', 'maxoff'],
    'MAX RECS': ['mr', 'maxrec', 'maxrecs', 'maxreceiver', 'maxreceivers', 'maxchan', 'maxchans'],
    'MAX SOURCES': ['ms', 'maxsou', 'maxsource', 'maxsources', 'maxshot', 'maxshots'],
    'MAX TRACES': ['maxtraces', 'maxtrace'],
    'MINIMUM PHASE': ['minimumphase', 'phase', 'minphase', 'miniphase', 'filterphase', 'filtphase', 'minimum'],
    'MIN OFFSET': ['minoffset', 'minimumoffset', 'minoff'],
    'NX1': ['nx1', 'ninline', 'niline', 'nx'],
    'NX2': ['nx2', 'ncrossline', 'nxline', 'ny'],
    'NX3': ['nx3', 'ndepth', 'nz'],
    'OFFSET 2D': ['2doffset', 'useoffset', 'offset'],
    'OUT SEIS': ['segyout', 'sgyout', 'segyoutput', 'sgyoutput', 'outseis', 'outseismic'],
    'OUT SOURCE': ['outsign', 'outsig', 'outsignature', 'sgysign', 'signatureout', 'sigout', 'signout', 'outsource'],
    'PROBLEM TYPE': ['problem', 'problemtype'],
    'PROCESS TIME': ['ptime', 'processtime', 'proctime'],
    'PROJECT 2D': ['project', '2dproject'],
    'REC DEPTH': ['recdepth', 'rdepth', 'receiverdepth', 'recz', 'rz', 'receiverz'],
    'REC DX': ['recdx', 'recrcedx'],
    'REC DY': ['recdy', 'recrcedy'],
    'REC GEOMETRY': ['rec', 'receiver', 'receivers', 'rectype', 'recgeometry', 'recgeom'],
    'REC NX': ['recnx', 'channx', 'recxn', 'chanxn'],
    'REC NY': ['recny', 'channy', 'recyn', 'chanyn'],
    'REC X ORIGIN': ['recx0', 'chanx0', 'recxo', 'chanxo', 'recxorigin'],
    'REC Y ORIGIN': ['recy0', 'chany0', 'recyo', 'chanyo', 'recyorigin'],
    'RECIPROCITY': ['reciprocity', 'recip', 'swap', 'swop'],
    'RETAIN': ['retain', 'retainall', 'keep', 'keepall'],
    'SAMPLE RECS': ['sschan', 'sschans', 'subsetchan', 'subsetchans', 'ssrec', 'ssreceiver', 'subsetrec', 'subsetreceiver', 'samplerecs'],
    'SAMPLE SHOTS': ['ssshot', 'ssshots', 'sssou', 'sssource', 'sshot', 'subsetshot', 'subsetshots', 'subsetsou', 'subsetsource', 'sampleshots', 'samplesou', 'samplesous'],
    'SOU DEPTH': ['soudepth', 'sdepth', 'sourcedepth', 'souz', 'sz', 'sourcez'],
    'SOU DX': ['soudx', 'sourcedx'],
    'SOU DY': ['soudy', 'sourcedy'],
    'SOU NX': ['sounx', 'sourcenx', 'souxn', 'sourcexn'],
    'SOU NY': ['souny', 'sourceny', 'souyn', 'sourceyn'],
    'SOU X ORIGIN': ['soux0', 'sourcex0', 'souxo', 'sourcexo', 'souxorigin'],
    'SOU Y ORIGIN': ['souy0', 'sourcey0', 'souyo', 'sourceyo', 'souyorigin'],
    'TEXT': ['text'],
    'TIME STEP FD': ['fdtimestep', 'timestepfd', 'fddt', 'dtfd', 'finitedifferencetimestep', 'timestepfinitedifference'],
    'TIME STEP SEGY': ['segydt', 'dtsegy', 'sgydt', 'dtsgy', 'segytimestep', 'sgytimestep', 'dtms', 'timestepsegy', 'timestepsgy'],
    'TOTAL TIME': ['ttime', 'totaltime', 'tottime'],
    'UNIQUE SHOTS': ['unique', 'uniqueshot', 'uniquesou', 'uniqueffid', 'uniquesource', 'uniqueshots'],
    'USE FFIDS': ['useffid', 'useffids', 'ffid', 'ffids'],
    'WATER DEPTH': ['waterdepth', 'water'],
    'WATER DEPTH SCALE': ['waterdepthscale', 'waterscale', 'waterdepthscalar', 'waterscalar'],
    'X ORIGIN': ['xorigin', 'originx'],
    'X SHIFT': ['xshift', 'shiftx', 'xmodel', 'modelx'],
    'Y ORIGIN': ['yorigin', 'originy'],
    'Y SHIFT': ['yshift', 'shifty', 'ymodel', 'modely'],
    'XY SCALE': ['xyscale', 'horizscale', 'hscale', 'xscale', 'horizontalscale'],
    'Total Traces': ['totaltracesused'],
    'Traces Outside Model': ['totaltracesoutsidethemodel'],
    'Traces Outside Offset' : ['tracesoutsideoffsetrange'],
    'Original Sources' : ['originalsourcesinmodel'],
    'Original Recievers' : ['originalreceiversinmodel'],
    'Maximum Recievers per Source' : ['maximumreceiverspersource'],
    'Signature Identifier': ['signatureidentifier'],

    'TRACES LOCATOR': ['traceslocator'],
    'DIRECTORY NAME': ['directoryname'],
    'COMMENT': ['comment'],
    'FOUND': ['FOUND'],
    'GENERATED ON': ['generatedon'],
    'AWS PATH': ['awspath']
}

export function get_mapping(key: string, snakecase: boolean = false, lowercase: boolean = false) {
    let keys = Object.keys(header_mappings)
    let vals = Object.values(header_mappings)
    let keyComparable = key.replace(/ /g, '').replace(/_/g, '').toLowerCase()
    let index = vals.findIndex(val => val.includes(keyComparable))
    if (keys[index]) {
        let value = keys[index]
        if (snakecase) {
            value = value.replace(/ /g, '_')
        }
        if (lowercase) {
            value = value.toLowerCase()
        }
        return value
    } else if (keys.findIndex(k => k.toLowerCase().replace(/ /g, '') == keyComparable) != -1) {
        let value = keys[keys.findIndex(k => k.toLowerCase().replace(/ /g, '') == keyComparable)]
        if (snakecase) {
            value = value.replace(/ /g, '_')
        }
        if (lowercase) {
            value = value.toLowerCase()
        }
        return value
    }
    else {
        return null
    }
}