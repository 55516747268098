import { DatePipe } from "@angular/common";
import { HttpClientModule, HttpXhrBackend } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DragulaModule, DragulaService } from "ng2-dragula";
import { NgxPaginationModule } from "ngx-pagination";
import { ShotSettingsService } from "./projects/services/shot-settings.service";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BannerComponent } from "./app/banner/banner.component";
import { FooterComponent } from "./app/footer/footer.component";
import { NavBarComponent } from "./app/nav-bar/nav-bar.component";
import { ProjectViewComponent } from "./projects/project-view/project-view.component";
import { ProjSideBarComponent } from "./projects/project-view/sidebar/sidebar.component";
import { DataComponent } from "./projects/project/data/data.component";
import { FilesComponent } from "./projects/project/files/files.component";
import { LogComponent } from "./projects/project/log/log.component";
import { ModelComponent } from "./projects/project/model/model.component";
import { ProjectComponent } from "./projects/project/project.component";
import { RunfileComponent } from "./projects/project/runfile/runfile.component";
import { SidebarComponent } from "./projects/project/sidebar/sidebar.component";
import { ProjectsComponent } from "./projects/projects.component";
import { BreadcrumbComponent } from "./shared/breadcrumb/breadcrumb.component";
import { ListComponent } from "./shared/list/list.component";
import { SelectionListComponent } from "./shared/selectionList/selectionList.component";

import { InputNumberDirective } from "./directives/input-numbers/input-number.directive";
import { CostsComponent } from "./projects/project/costs/costs.component";
// import { SliderComponent } from "./shared/slider/slider.component";
// import { PlotlyComponent } from "./shared/plotly/plotly.component";
// import { ChartSliceComponent } from "./projects/project/chart-slice/chart-slice.component";
import { LoginComponent } from "./login/login.component";
import { ChartLegendComponent } from "./projects/project/chart-legend/chart-legend.component";

import { AppConfig } from "./app.config";
import { CallbackComponent } from "./callback/callback.component";
import { CachedPaletteService } from "./projects/services/cached-palette.service";
import { PaletteService } from "./projects/services/palette.service";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { AuthService } from "./shared/services/auth.service";
import { CacheService } from "./shared/services/cache.service";
import { CachedUserService } from "./shared/services/cached-user.service";
import { DefaultMappingService } from "./shared/services/default-mapping.service";
import { ImageLoaderService } from "./shared/services/image-loader.service";
import { UserService } from "./shared/services/user.service";

import { Router } from "@angular/router";
import { EpicmodCommentsComponent } from "./projects/project/dialogs/epicmod-comments/epicmod-comments.component";
import { JobDeleteComponent } from "./projects/project/dialogs/job-delete/job-delete.component";
import { JobNameComponent } from "./projects/project/dialogs/job-name/job-name.component";
import { JobStartComponent } from "./projects/project/dialogs/job-start/job-start.component";
import { KeepJobComponent } from "./projects/project/dialogs/keep-job/keep-job.component";
import { ProjectNameComponent } from "./projects/project/dialogs/project-name/project-name.component";
import { RebuildDataTableComponent } from "./projects/project/dialogs/rebuild-data-table/rebuild-data-table.component";
import { RebuildTableComponent } from "./projects/project/dialogs/rebuild-project-table/rebuild-project-table.component";
import { VelocityComponent } from "./projects/project/dialogs/velocity/velocity.component";
import { HttpService } from "./shared/services/http.service";

import { Module as StripeModule } from "stripe-angular";
import { BillingComponent } from "./billing/billing.component";
import { StoredSettingsService } from "./projects/services/stored-settings.service";
import { BillingService } from "./shared/services/billing.service";

import { GraphComponent } from "./projects/project/plots/graph/graph.component";
import { PlotsComponent } from "./projects/project/plots/plots.component";
import { ShotImageComponent } from "./projects/project/shot-image/shot-image.component";
// import { ShotsGraphComponent } from "./projects/project/shot-image/shots-graph/shots-graph.component";
import { CompareGraphComponent } from "./projects/project/plots/compare-graph/compare-graph.component";
import { MultiGraphComponent } from "./projects/project/plots/multi-graph/multi-graph.component";

import { ColorPickerModule } from "ngx-color-picker";
import { DemoPageResolver } from "./demo/demo-page/demo-page.resolver";
import { DemoModule } from "./demo/demo.module";
import { MonitoringComponent } from "./monitoring/monitoring.component";
import { ScoresComponent } from "./monitoring/scores/scores.component";
import { SearchComponent } from "./monitoring/search/search.component";
import { ProjectModule } from "./projects/project.module";
import { ParamValueMapService } from "./projects/project/data/paramValueMapService";
import { JobDetailComponent } from "./projects/project/dialogs/job-detail/job-detail.component";
import { ParameterViewEditDialogComponent } from "./projects/project/dialogs/parameter-view-edit-dialog/parameter-view-edit-dialog.component";
import { PictureModelComponent } from "./projects/project/dialogs/picture-model/picture-model.component";
import { RebuildModelTableComponent } from "./projects/project/dialogs/rebuild-model-table/rebuild-model-table.component";
import { SearchGroupComponent } from "./projects/project/dialogs/search-group/search-group.component";
import { ShowAllFiltersDialogComponent } from "./projects/project/dialogs/show-all-filters-dialog/show-all-filters-dialog.component";
import { ShowMatchingJobsOptionsComponent } from "./projects/project/dialogs/show-matching-jobs-options/show-matching-jobs-options.component";
import { SearchPipe } from "./projects/project/files/search.pipe";
import { VerticalProfileComponent } from "./projects/project/model/vertical-profile/vertical-profile.component";
import { ScreenshotComponent } from "./projects/project/screenshot/screenshot.component";
import { SliceSettingsService } from "./projects/services/slice-settings.service";
import { MaterialModule } from "./shared/material.module";
import { MapkeyPipe } from "./shared/pipes/mapkey/mapkey.pipe";
import { CachedProjectService } from "./shared/services/cached-project.service";
import { ProjectService } from "./shared/services/project.service";
import { ScoresService } from "./shared/services/scores.service";
import { SettingsDebugComponent } from "./shared/settings-debug/settings-debug.component";
import { SharedModule } from "./shared/shared.module";
import { ZorroModule } from "./shared/zorro.module";
import { NgxEchartsModule } from "ngx-echarts";
import { CostsModule } from "./costs/costs.module";

// export function authHttpServiceFactory(http: Http, options: RequestOptions) {
//   return new AuthHttp(new AuthConfig(), http, options);
// }
export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function httpServiceFactory(
  backend: HttpXhrBackend,
  // options: RequestOptions,
  router: Router,
  authService: AuthService
) {
  return new HttpService(backend, router, authService);
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    ZorroModule,
    NgxPaginationModule,
    AppRoutingModule,
    DemoModule,
    CostsModule,
    StripeModule,
    ProjectModule,
    ColorPickerModule,
    DragulaModule.forRoot(),
    NgxEchartsModule.forRoot({ echarts: () => import("echarts") }),
  ],
  declarations: [
    SearchPipe,
    AppComponent,
    NavBarComponent,
    BannerComponent,
    ListComponent,
    SelectionListComponent,
    BreadcrumbComponent,
    FooterComponent,
    ProjectsComponent,
    MonitoringComponent,
    ScoresComponent,
    ProjectComponent,
    ProjectViewComponent,
    SidebarComponent,
    ProjSideBarComponent,
    ModelComponent,
    DataComponent,
    LogComponent,
    RunfileComponent,
    FilesComponent,
    InputNumberDirective,
    // SliderComponent,
    // PlotlyComponent,
    // ChartSliceComponent,
    ChartLegendComponent,
    LoginComponent,
    CallbackComponent,
    CostsComponent,
    ProjectNameComponent,
    JobNameComponent,
    JobDeleteComponent,
    JobStartComponent,
    JobDetailComponent,
    VelocityComponent,
    EpicmodCommentsComponent,
    KeepJobComponent,
    RebuildTableComponent,
    RebuildDataTableComponent,
    RebuildModelTableComponent,
    ParameterViewEditDialogComponent,
    ShowMatchingJobsOptionsComponent,
    ShowAllFiltersDialogComponent,
    BillingComponent,
    PlotsComponent,
    GraphComponent,
    ShotImageComponent,
    // ShotsGraphComponent,
    // D3ImageComponent,
    CompareGraphComponent,
    MultiGraphComponent,
    JobDetailComponent,
    ScreenshotComponent,
    SearchComponent,
    MapkeyPipe,
    PictureModelComponent,
    SearchGroupComponent,
    SettingsDebugComponent,
    VerticalProfileComponent,
  ],
  exports: [SettingsDebugComponent],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    // {
    //   provide: AuthHttp,
    //   useFactory: authHttpServiceFactory,
    //   deps: [HttpClient, RequestOptions],
    // },\
    {
      provide: HttpService,
      useFactory: httpServiceFactory,
      deps: [HttpXhrBackend, Router, AuthService],
    },
    AuthService,
    ScoresService,
    ParamValueMapService,
    AuthGuardService,
    CacheService,
    ProjectService,
    PaletteService,
    CachedProjectService,
    CachedPaletteService,
    ImageLoaderService,
    BillingService,
    UserService,
    CachedUserService,
    DefaultMappingService,
    StoredSettingsService,
    SliceSettingsService,
    ShotSettingsService,
    DatePipe,
    DemoPageResolver,
    DragulaService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
