import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ECharts, EChartsOption } from "echarts";
import { NzMessageService } from "ng-zorro-antd/message";
import { ProjectService } from "../../../shared/services/project.service";
import { ActivatedRoute } from "@angular/router";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { Project } from "../../../models/project";
import * as _ from "lodash";

@Component({
  selector: "app-trace-fits-plotting",
  templateUrl: "./trace-fits-plotting.component.html",
  styleUrls: ["./trace-fits-plotting.component.less"],
})
export class TraceFitsPlottingComponent implements OnInit {
  @ViewChild("graph", { static: true }) graph: ElementRef;
  chartInstance: ECharts;

  ylim: any[] = [0, 100];
  xlim: any[] = [0, 0];
  xMin: number = 0;
  xMax: number = 0;

  project_id: string = "";
  project_name: string = "";
  loadingChart: boolean = false;
  error: string = null;
  searchValue: string = "";
  options: EChartsOption;
  data: { results: any[]; tracedata: object };

  constructor(
    private _projectService: ProjectService,
    private _cachedProjectService: CachedProjectService,
    private _message: NzMessageService,
    private _route: ActivatedRoute
  ) {}

  log(e) {
    console.log(e);
  }

  ngOnInit(): void {
    // if queryParams have jobs to actually plot
    if (this._route.snapshot.queryParams.jobs) {
      this.loadingChart = true;
      const jobIds = this._route.snapshot.queryParams.jobs;
      this._cachedProjectService.currentProject.subscribe(
        (project: Project) => {
          if (project != null) {
            this.project_id = project.id;
            this.project_name = project.name;
            this._projectService
              .getTraceFitPlottingData(this.project_id, jobIds)
              .toPromise()
              .then((res) => {
                this.data = res;
                this.makeChart();
              })
              .catch((err) => this._message.error(err.message))
              .finally(() => (this.loadingChart = false));
          }
        }
      );
    }
    // static data for the sake of prototyping. make dynamic
    // this._projectService
    //   .getTraceFitPlottingData("afb2c00a-9bd5-48ab-a933-d351d82db83c", [
    //     "746bf11b-32c9-4ff0-8b62-8270af340ee0",
    //     "b367cc38-45f2-4dbc-b31b-011a9ecb3280",
    //     "f4af1935-75f1-4258-833d-56fcf4a0aded",
    //     "3d782f46-b0e3-4c19-b60f-f9235a2c1171",
    //   ])
    //   .toPromise()
    //   .then((res) => {
    //     this.data = res;
    //     console.log(res);
    //     this.makeChart();
    //   })
    //   .catch((err) => this._message.error(err.message))
    //   .finally(() => (this.loadingChart = false));
  }

  chartInit(event) {
    this.chartInstance = event;
  }

  onYlimChanged(e) {
    this.chartInstance.setOption({
      yAxis: {
        min: e[0],
        max: e[1],
        type: "value",
      },
    });
  }
  onXlimChanged(e) {
    this.chartInstance.setOption({
      xAxis: {
        min: e[0] - 1,
        max: e[1] - 1,
        type: "category",
        boundaryGap: false,
        // data: xaxisData,
      },
    });
  }

  makeChart() {
    // makes the legend data and also sorts it for ease
    const legendData: any = Object.keys(this.data.tracedata)
      .map((k) => ({
        name: k.split("-")[0],
        textStyle: {
          ellipsis: true,
          overflow: "truncated",
        },
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    // very complex and dense way of basically just making an array from first to last iteration
    const preXaxisData = Array.from(
      new Set(
        Object.values(this.data.tracedata)
          .map((o) => Object.keys(o.iterations).map((iter) => Number(iter)))
          .flat()
      )
    ).sort(function (a, b) {
      return Number(a) - Number(b);
    });

    this.xMin = 1;
    this.xMax = _.max(preXaxisData);
    this.xlim = [this.xMin, this.xMax];

    const xaxisData = Array.from({ length: this.xMax }, (_, i) => i + 1); //=> [1, 2, 3, 4, 5, 6, 7, 8, 9...]

    // complex looking chainging but is actually very simple
    const seriesData: any[] = Object.entries(this.data.tracedata).map(
      ([job, tracefits]) => {
        // const lineData = new Array(xaxisData.length).fill(null);
        const lineData = [];
        Object.entries(tracefits.iterations).forEach((trf) => {
          // assign the tracefit to the index according to iter. e.g. iteration 31 will be assigned to index 30 (iteration starting from 1)
          lineData[Number(trf[0]) - 1] = Number(trf[1]).toFixed(3);
        });

        return {
          name: job.split("-")[0],
          type: "line",
          connectNulls: true,
          data: lineData,
        };
      }
    );

    this.options = {
      legend: {
        type: "scroll",
        data: legendData,
        padding: 20,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
          restore: {},
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          animation: false,
          label: {
            backgroundColor: "#ccc",
            borderColor: "#aaa",
            borderWidth: 1,
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            color: "#222",
          },
        },
        formatter: function (params) {
          if (params.length == 0) return "";

          let tooltipHtml = `Iteration ${params[0].axisValue} <br />`;
          let sortedParams = params
            .map((p) => ({
              marker: p.marker,
              seriesName: p.seriesName,
              value: p.value,
            }))
            .sort((a, b) => b.value - a.value);
          sortedParams.forEach((param) => {
            tooltipHtml +=
              param.marker + param.seriesName + " : " + param.value + "<br />";
          });
          return tooltipHtml;
        },
      },
      xAxis: {
        type: "category",
        name: "Iterations",
        nameLocation: "middle",
        nameGap: 20,
        boundaryGap: false,
        data: xaxisData,
      },
      yAxis: {
        min: this.ylim[0],
        max: this.ylim[1],
        type: "value",
        name: "Trace fit",
        axisLine: {
          show: true,
        },
        axisPointer: {
          show: true,
        },
        minorTick: {
          show: true,
        },
        minorSplitLine: {
          show: true,
        },
      },
      dataZoom: [
        {
          show: true,
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      series: seriesData,
    };
  }
}
