import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { JobDetail } from "../../../models/jobDetail";
export interface BlockRange {
  blockNo: number;
  start: number;
  end: number;
  block: any;
}
@Component({
  selector: "app-shot-block-settings",
  templateUrl: "./shot-block-settings.component.html",
  styleUrls: ["./shot-block-settings.component.less"],
})
export class ShotBlockSettingsComponent implements OnInit, OnChanges {
  @Input() jobDetail: JobDetail;
  @Input() jobMeta: any;
  @Input() iteration: number;
  blockInfo: any = [];
  selectedBlock = null;
  selectedBlockRange: BlockRange = null;
  blockRanges: BlockRange[];
  constructor() {}

  ngOnInit() {
    this.loadPage();
  }
  ngOnChanges(changes: SimpleChanges) {
    const change = changes.iteration;
    if (!change) return;
    if (change.currentValue && !change.isFirstChange()) {
      this.loadPage();
    }
  }
  loadPage() {
    if (!this.jobMeta) {
      this.blockInfo = [];
      this.selectedBlock = null;
      return of(null);
    }
    this.blockInfo = this.jobMeta.blk_data;
    this.blockRanges = this.getBlockRanges(this.blockInfo);
    this.selectedBlockRange = this.getCurrentBlock(this.iteration);
    if (this.selectedBlockRange) {
      this.selectedBlock = this.selectedBlockRange.block;
    }
  }

  getBlockRanges(blocks: any[]) {
    var prevBlock = { start: 0, end: 0 };
    return blocks.reduce((acc, block, index) => {
      if (!acc) acc = [];
      let blockRange = {
        blockNo: index + 1,
        start: (prevBlock.end || 0) + 1,
        end: (prevBlock.end || 0) + block.iters,
        block: block,
      };
      acc.push(blockRange);
      prevBlock = blockRange;
      return acc;
    }, []);
  }
  getCurrentBlock(iteration: number) {
    return this.blockRanges.find(
      (x) => x.start <= iteration && x.end >= iteration
    );
  }
}
