<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <mat-card appearance="outlined" class="card">
        <app-slice-settings
          [jobDetail]="jobDetail"
          [settingsKey]="settingsKey"
          [ranges]="ranges"
        ></app-slice-settings>
      </mat-card>
    </div>
  </div>
  <h6>Model</h6>
  <div class="row">
    <div class="col-md-6">
      <mat-card appearance="outlined" class="card">
        <app-model-chart-container
          [jobDetail]="jobDetail"
          [settingsKey]="settingsKey"
          [slice]="{ sliceType: sliceType.inline, span: 2 }"
        ></app-model-chart-container>
      </mat-card>
    </div>
    <div class="col-md-6">
      <mat-card appearance="outlined" class="card">
        <app-model-chart-container
          [jobDetail]="jobDetail"
          [settingsKey]="settingsKey"
          [slice]="{ sliceType: sliceType.crossLine, span: 2 }"
          [showChartMenu]="false"
        ></app-model-chart-container>
      </mat-card>
    </div>
  </div>
  <div class="row" *ngIf="!jobDetail.modelGrid.is2d">
    <div class="col-md-6">
      <mat-card appearance="outlined" class="card">
        <app-model-chart-container
          [jobDetail]="jobDetail"
          [settingsKey]="settingsKey"
          [slice]="{ sliceType: sliceType.depth, span: 2 }"
          [showChartMenu]="true"
        ></app-model-chart-container
      ></mat-card>
    </div>
  </div>
  <h6>Shots</h6>
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <mat-card appearance="outlined" class="card">
        <app-shot-select-container
          [jobDetail]="jobDetail"
          [settingsKey]="settingsKey"
          [jobMeta]="jobMeta"
        ></app-shot-select-container>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-card appearance="outlined" class="card">
        <app-shot-container
          [jobDetail]="jobDetail"
          [settingsKey]="settingsKey"
        ></app-shot-container>
      </mat-card>
    </div>
  </div>
</div>
<!-- <div class="sliders-bar">
  <app-project-settings-bar
    [jobDetail]="jobDetail"
    [jobs]="jobs"
  ></app-project-settings-bar>
</div> -->
