import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { AppConfig } from '../../app.config';
import { CacheService } from './cache.service';
import { Observable, BehaviorSubject } from 'rxjs';

(window as any).global = window;

@Injectable()
export class AuthService {
  private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isLoggedIn: Observable<boolean> = this._isLoggedIn.asObservable();

  auth0 = new auth0.WebAuth({
    clientID: AppConfig.settings.auth0.clientId,
    domain: AppConfig.settings.auth0.domain,
    audience: AppConfig.settings.auth0.audience,
    scope: AppConfig.settings.auth0.scope,
    responseType: 'token id_token',
    redirectUri: `${window.location.origin}/callback`
  });

  constructor(public router: Router, private cacheService: CacheService) { }

  public login(url: string = null): void {
    this.setReturnUrl(url);
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);

        var returnUrl = this.getReturnUrl();
        this.router.navigateByUrl(returnUrl);
      } else if (err) {
        this.router.navigate(['/']);
        console.log(err);
      }
    });
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  public getTokenHeaderText(): string {
    if (this.isDemoUser())
      return 'Token ' + this.getToken();
    else
      return 'Bearer ' + this.getToken();
  }

  private setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.removeItem('demo_user');
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('demo_user');
    localStorage.removeItem('settings')

    this.cacheService.deleteAll();
    this._isLoggedIn.next(false);
    // Go back to the home route
    this.router.navigate(['/']);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    var result = this.isDemoUser() || (new Date().getTime() < expiresAt);

    if (result != this._isLoggedIn.value)
      this._isLoggedIn.next(result);

    return result;
  }

  public loginDemoUser() {
    localStorage.setItem('demo_user', '1');
    localStorage.setItem('access_token', '738873120169ad104c14b2427a076e6a1714958d');
  }

  public isDemoUser() {
    return localStorage.getItem('demo_user') == '1';
  }

  private setReturnUrl(url: string) {
    if(url)
      localStorage.setItem('returnUrl', url);
  }

  private getReturnUrl(): string {
    var url = localStorage.getItem('returnUrl') || '/projects';
    localStorage.removeItem('returnUrl');
    return url;
  }
}
