import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { JobDetail } from "../../models/jobDetail";
import { Project } from "../../models/project";
import { Ranges } from "../../models/ranges";
import { SliceType } from "../../models/sliceType";
import { CachedProjectService } from "../../shared/services/cached-project.service";

@Component({
  selector: "app-demo-page",
  templateUrl: "./demo-page.component.html",
  styleUrls: ["./demo-page.component.less"],
})
export class DemoPageComponent implements OnInit, OnDestroy {
  jobDetail: JobDetail;
  jobMeta: any;
  project: Project;
  jobs: any[];
  ranges: Ranges = {
    x: [0, 0],
    y: [0, 0],
    z: [0, 0],
  };
  sliceType = SliceType;
  settingsKey = "demo";

  constructor(
    private route: ActivatedRoute,
    private projectService: CachedProjectService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.jobDetail = data.payload[0];
      this.project = data.payload[1];
      this.jobMeta = data.payload[2];
      this.jobs = this.project.jobs;
    });

    this.ranges = {
      x: [0, this.jobDetail.modelGrid.nx2],
      y: [0, this.jobDetail.modelGrid.nx1],
      z: [0, this.jobDetail.modelGrid.nx3],
    };
  }

  ngOnDestroy(): void {
    this.projectService.clearJobDetailCache([this.jobDetail.id]);
  }
}
