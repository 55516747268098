<div class="actions" >
  <a class="actions__item" [matMenuTriggerFor]="menu"><i class="zmdi zmdi-more-vert"></i></a>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="modelTypes && modelTypes.length>0"
      [matMenuTriggerFor]="modelTypeMenu">
      Model Property
    </button>
    <div mat-menu-item style="text-overflow:clip;" *ngIf="showHeightMenu()">
      Height
      <mat-slider [min]="250" [max]="800" step="50" [(ngModel)]="height" #ngSlider><input matSliderThumb (change)="setHeight()" #ngSliderThumb="matSliderThumb" /></mat-slider>
    </div>
    <div mat-menu-item style="text-overflow:clip;" *ngIf="showDepthHeightMenu()">
      Height
      <mat-slider [min]="200" [max]="maxDepth" step="50" [(ngModel)]="depthHeight" #ngSlider><input matSliderThumb (change)="setHeight()" #ngSliderThumb="matSliderThumb" /></mat-slider>
    </div>
    <button mat-menu-item (click)="showMinMaxDialog(slice.sliceType)">
      Set Min/Max Values
    </button>
    <div mat-menu-item *ngIf=" shotOverlayTypes.length>0 && false"
      [matMenuTriggerFor]="shotOverlayMenu">
      <span>Shot Overlay&nbsp;</span>
      <mat-slide-toggle [(ngModel)]="showShotOverlay" (click)="$event.stopPropagation();">
      </mat-slide-toggle>
    </div>
    <div mat-menu-item [matMenuTriggerFor]="logMenu" *ngIf="jobDetail.containsLog && showLogMenu()">
      <span>Sonic Logs&nbsp;</span>
      <mat-slide-toggle [(ngModel)]="showSonicLog" (change)="onSonicLogClicked()"></mat-slide-toggle>
    </div>
    <div mat-menu-item [matMenuTriggerFor]="horizonMenu" *ngIf="showHorizonMenu() && horizonTypes.length>0">
      <span>Show Horizon&nbsp;</span>
      <mat-slide-toggle [(ngModel)]="showHorizon" (click)="onSonicLogClicked();"></mat-slide-toggle>
    </div>
    <button mat-menu-item *ngIf="!!palettes && palettes.length>0 && showPalletteMenu()" [matMenuTriggerFor]="paletteMenu">
      Colour Map
    </button>
    <button mat-menu-item *ngIf="timeSliceModels.includes(currentModelTypeId)"
      [matMenuTriggerFor]="timeSliceMenu">
      Time Slices
    </button>
    <button mat-menu-item *ngIf="shotModels.includes(currentModelTypeId)" [matMenuTriggerFor]="shotMenu">
      Shots
    </button>
  </mat-menu>
  <mat-menu #modelTypeMenu="matMenu">
    <button *ngFor="let modelType of modelTypes" mat-menu-item (click)="setModelType(modelType, $event)">
      <span>{{modelType.name}}</span>
      <i *ngIf="modelType.id===currentModelTypeId" class="zmdi zmdi-check" style="margin-left:2px;"></i>
    </button>
  </mat-menu>
  <mat-menu #paletteMenu="matMenu">
    <button *ngFor="let palette of palettes" mat-menu-item (click)="setPalette(palette)">
      <span>{{palette}}</span>
      <i *ngIf="palette==paletteOfCurrentType" class="zmdi zmdi-check" style="margin-left:2px;"></i>
    </button>
  </mat-menu>
  <mat-menu #logMenu="matMenu">
    <button *ngFor="let log of jobDetail.wellLogs" mat-menu-item (click)="setLog(log.id)">
      <span>IL - {{log.inline}}, XL - {{log.crossline}}</span>
    </button>
  </mat-menu>
  <mat-menu #horizonMenu="matMenu">
    <button *ngFor="let type of horizonTypes" mat-menu-item (click)="setHorizon(type)">
      <span>{{type}}</span>
      <i *ngIf="isHorizonSelected(type)" class="zmdi zmdi-check" style="margin-left:2px;"></i>
    </button>
  </mat-menu>
  <mat-menu #shotOverlayMenu="matMenu">
    <button *ngFor="let shotOverlayType of shotOverlayTypes" mat-menu-item
      (click)="setShotOverlayType(shotOverlayType)">
      <span>{{shotOverlayType.name}}</span>
      <i *ngIf="shotOverlayType.id===currentShotOverlayType.id" class="zmdi zmdi-check"
        style="margin-left:2px;"></i>
    </button>
  </mat-menu>
  <mat-menu #timeSliceMenu="matMenu">
    <button *ngFor="let time of availTimeSlices" mat-menu-item (click)="setTimeSlice(time)">
      <span>{{time}}</span>
      <i *ngIf="timeSlice==time" class="zmdi zmdi-check" style="margin-left:2px;"></i>
    </button>
  </mat-menu>
  <mat-menu #shotMenu="matMenu">
    <button *ngFor="let shot of availShots" mat-menu-item (click)="setShot(shot)">
      <span>{{shot}}</span>
      <i *ngIf="shotId==shot" class="zmdi zmdi-check" style="margin-left:2px;"></i>
    </button>
  </mat-menu>
</div>