import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { JobDetailDialog } from "../../../models/jobDetailDialog";
import { Project } from "../../../models/project";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { CachedUserService } from "../../../shared/services/cached-user.service";
import { ParameterViewEditDialogComponent } from "../../project/dialogs/parameter-view-edit-dialog/parameter-view-edit-dialog.component";
import { RebuildTableComponent } from "../../project/dialogs/rebuild-project-table/rebuild-project-table.component";
import { ButtonConfig } from "../data-table/custom-table/custom-table.component";
import { parameter_view_fields } from "./fields";

@Component({
  selector: "app-parameter-overview-2",
  templateUrl: "./parameter-overview-2.component.html",
  styleUrls: ["./parameter-overview-2.component.less"],
})
export class ParameterOverview2Component implements OnInit, OnDestroy {
  private _userSubscription: any;
  private _projectSubscription: Subscription;
  private _parameterTableSubscription: Subscription;

  project_id: string;
  project_name: string;
  is_loading: boolean = false;
  data: Array<any> = [];
  options: Object = parameter_view_fields;
  linkDefinitions: Array<any> = [
    {
      key: "job_name",
      template: (row: object) => `projects/${row["job_id"]}/model`,
    },
    {
      key: "parent_job_name",
      template: (row: object) =>
        `projects/${row["parent_job_id"]}/model?iteration=${
          row["parent_job_cp"] || 0
        }`,
    },
  ];
  buttonConfig: ButtonConfig[] = [
    {
      text: "Open Showing Jobs",
      action: (event, info) => {
        window.open(
          `/projects/${info[0].job_id}/model?${info
            .map((job: Object) => `jobs=${job["job_id"]}`)
            .join("&")}`,
          "_blank"
        );
      },
    },
  ];

  constructor(
    private router: Router,
    private projectService: CachedProjectService,
    public dialog: MatDialog,
    private userService: CachedUserService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this._userSubscription = this.userService.userDetail.subscribe((d) => {
      if (d && !(d.isStaff || d.email === "testuser01@s-cube.com"))
        this.router.navigateByUrl("/");
    });
    this._projectSubscription = this.projectService.currentProject.subscribe(
      (project: Project) => {
        if (project != null) {
          this.project_id = project.id;
          this.project_name = project.name;
          this.get_parameter_table();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._parameterTableSubscription.unsubscribe();
    this._userSubscription.unsubscribe();
    this._projectSubscription.unsubscribe();
  }

  get_parameter_table(rebuild: boolean = false, forceRefresh: boolean = false) {
    this.is_loading = true;
    this._parameterTableSubscription = this.projectService
      .getParameterTable(this.project_id, rebuild, [], true)
      .subscribe(
        (data) => {
          this.data = [...data["results"]];
          this.is_loading = false;
        },
        (err) => {
          console.log(err);
          this.is_loading = false;
        }
      );
  }

  refreshTable() {
    this.get_parameter_table(false, true);
  }

  rebuildTable() {
    this.dialog
      .open(RebuildTableComponent, {
        height: "320px",
        width: "400px",
        data: {
          project_name: this.project_name,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined && result["confirm"]) {
          this.get_parameter_table(true);
        }
      });
  }

  editComment(row: object) {
    this.dialog
      .open(ParameterViewEditDialogComponent, {
        data: { comments: row["comments"] },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result !== undefined) {
          const details: JobDetailDialog = {
            id: row["job_id"],
            name: row["job_name"],
            comments: result,
          };
          this.projectService.updateJobDetails(details).subscribe(
            (data) => {
              this.projectService.clearProjectsCache();
              this.projectService.clearJobDetailCache([this.project_id]);
              this.snackBar.open("Job Details updated", null, {
                duration: 2000,
              });
              this.get_parameter_table(false, true);
            },
            (error) => {
              this.snackBar.open(
                "Sorry, the job details could not be updated",
                null,
                { duration: 2000 }
              );
            }
          );
        }
      });
  }
}
