<div class="actions">
  <a [matMenuTriggerFor]="graphMenu" class="actions__item"
    ><i class="zmdi zmdi-hc-2x zmdi-more-vert"></i
  ></a>
  <mat-menu #graphMenu="matMenu">
    <div mat-menu-item style="text-overflow: clip">
      Height
      <mat-slider
        [min]="200"
        [max]="1000"
        step="50"
        [(ngModel)]="mainGraphHeight"
       #ngSlider><input matSliderThumb (change)="onMenuChanged()" #ngSliderThumb="matSliderThumb" /></mat-slider>
    </div>
    <div mat-menu-item style="text-overflow: clip">
      Clipping
      <mat-slider
        [min]="-2"
        [max]="0"
        step="0.2"
        [(ngModel)]="currentClipping"
        (click)="$event.stopPropagation()"
       #ngSlider><input matSliderThumb (change)="setClipping()" #ngSliderThumb="matSliderThumb" /></mat-slider>
    </div>
    <!-- <mat-slide-toggle
      style="margin-left: 5%"
      [(ngModel)]="globalClipping"
      (change)="updateMainImage()"
    >
      Global Clipping</mat-slide-toggle
    > -->
    <div
      mat-menu-item
      style="text-overflow: clip; cursor: pointer"
      (click)="setScaling()"
    >
      Scaling
    </div>
    <button mat-menu-item [matMenuTriggerFor]="shotTypeMenu">Shot Type</button>
    <button
      mat-menu-item
      [matMenuTriggerFor]="shotTypeDetailMenu"
      *ngIf="shotType && shotType.fwd"
    >
      Shot Type Detail
    </button>
  </mat-menu>
  <mat-menu #shotTypeMenu="matMenu" class="long-menu" xPosition="before">
    <!-- <table> -->

    <ng-container *ngFor="let column of tableTypes">
      <ng-container *ngFor="let fileType of column">
        <button
    
          mat-menu-item
          (click)="onShotTypeChange(fileType, $event)"
        >
          {{ fileType.displayName }}
          <i
            *ngIf="fileType === shotType"
            class="zmdi zmdi-check"
            style="margin-left: 2px"
          ></i>
        </button>
      </ng-container>
    </ng-container>
    <!-- </table> -->
  </mat-menu>
  <mat-menu #shotTypeDetailMenu="matMenu">
    <button
      mat-menu-item
      *ngFor="let typeDetail of typeDetails"
      (click)="onShotTypeDetailChange(typeDetail.value, $event)"
    >
      <span>{{ typeDetail.name }}</span>
      <i
        *ngIf="shotType &&  typeDetail.value === shotType.fwd"
        class="zmdi zmdi-check"
        style="margin-left: 2px"
      ></i>
    </button>
  </mat-menu>
</div>
