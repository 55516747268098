﻿<section class="breadcrumb-trail c5-bg">
  <div class="container">
    <div class="row">
      <nav class="col-xs-12">
        <ol itemscope="" itemtype="http://schema.org/BreadcrumbList">
          <li *ngFor="let item of items; let i = index" itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem" ngClass="{i==items.length-1?'active':''}"><a [href]="item.location" itemscope="" itemtype="http://schema.org/Thing" itemprop="item"><span itemprop="name">{{item.name}}</span></a><meta itemprop="position" [content]="i"></li>
        </ol>
      </nav>
    </div>
  </div>
</section>