export const header_tooltips = {
  xwi_executable: "Version of XWI used",
  comments: "User added comments",
  date: "Date of latest checkpoint generated by XWI for this job",
  epicmod_locator: "Path to epicmods files",
  checkpoint_locator: "Path to checkpoint files",
  geometry_locator: "Path to geometry files",
  modelprops_locator: "Path to model files (StartVp...)",
  qc_dump_locator: "Path to qc_dump files",
  sourcesig_locator: "Paths to source signature file",
  traces_locator: "",
  io_api: "I/O Format used by XWI for this job",
  tti_units: "Units that the values in the anisotropy files are written in. Also works for VTI. Only necessary when VTI or TTI is selected.",
  ttiang_units: "Angle units the TTI file contains. Only necessary when TTI is selected.",
  units: "Unit system to be used",
  equation: "Wave propagation equation used",
  anisotropy: "Choose the type of anisotropy to use (none, VTI, TTI)",
  domain: "",
  kernel: "Physics Propagator to be used, choose between high and low",
  probdims: "Specify if the project setup is for a 2D or 3D example",
  problem: "Select if you want to generate data based on a TrueVp or complete an FWI inversion. Select one of: tomography, synthetic, RTM",
  iters: "Number of iterations for EACH iterblock.",
  numiterblocks: "Number of total Iteration Blocks to process",
  final_iteration: "",
  nx1: "Size of 1st dimension.",
  nx2: "Size of 2nd dimension. Set to 1 if problem is 2D",
  nx3: "Size of the depth dimension.",
  dx: "Discretization of model grid in meters",
  fd_time_step: "The time step to use for modelling. ",
  totaltime: "",
  alpha: "",
  flipshotcycles: "",
  max_dead_srcs: "",
  maxps: "Maximum number of point sources per shot in the data",
  maxrc: "Maximum number of receivers per shot",
  ncomp: "Number of composite shots in the data ",
  nptrecs: "Total number of receivers in the data",
  nptsrcs: "Number of total shots in the data ",
  numcsperiter: "Total number of shots to use per iteration. This should be set to NCOMP/NumCStoSkip",
  numcstoskip: "Number of shots to skip per iteration, i.e. if its set to 2 then every 3rd shot will be used.",
  epicmod_adjoints: "",
  epicmod_comparetraces: "",
  epicmod_localgradprec: "",
  epicmod_localmodprops: "",
  epicmod_propagate: "",
  epicmod_residuals: "",
  epicmod_update: "",
  epicmod_modelprops: "",
  epicmod_density: "",
  epicmod_stepfactor: "",
  rwi_shot_autoscaling: "",
  rwi_end_time: "trace-length (secs) for BG iters only (VS iters get length from normal EndTime/TotalTime ",
  rwi_start_time: "time to ignore grad contribs during backprop for RWI BG iters (i.e. overrides StartTime for BG iters",
  rwi_agc: "",
  rwi_timetweak_ratefact: "",
  rwi_timetweak_maxfact: "",
  rwi2_far_ratio: "",
  rwi2_near_ratio: "",
  "iterations:_vs/bg": "",
  rwi_cut_factor: "",
  rwi_restart_vs: "allow building multi-freq RWI perturb",
  rwi_shift_factor: "",
  rwi_add_incident: "",
  rwi_vs_iters: "",
  rwi_bg_iters: "",
  rwi_scale: "",
  rwi_type: "",
  rwi_perturb_density: "",
  used_rwi: "",
  rwi_rcvrline_smthdist: "",
  rwi_timeslice_maxboost: "",
  rwi2_use_reflectivity: "",
  rwi_spatial: "",
  rwi_grad_contrib: "",
  rwi_gradmaskang: "degrees; only affects BG iters within RWI blocks",
  vs_low_cut: "",
  rwi_low_cut: "",
  vs_high_cut: "",
  rwi_high_cut: "",
  smoothx1: "Smoothing to be applied in NX1 at every iteration.Positive float = wavelengths, Negative float = cells.",
  smoothx2: "Smoothing to be applied in NX2 at every iteration.Positive float = wavelengths, Negative float = cells.",
  smoothx3: "Smoothing to be applied in NX3 at every iteration.Positive float = wavelengths, Negative float = cells.",
  smoothcut: "",
  smoothramp: "",
  struct_smooth: "",
  rcvrline_smoothdist: "",
  rwi_smooth_x1: "",
  rwi_smooth_x2: "",
  rwi_smooth_x3: "",
  rwi_smooth_cut: "",
  rwi_smooth_ramp: "",
  rwi_struct_smooth: "",
  rwi_rcvrline_smoothdist: "",
  rwi_median_x1: "",
  rwi_median_x2: "",
  rwi_median_x3: "",
  rwi_presmooth_clip_lo: "",
  rwi_presmooth_clip_hi: "",
  rwi_presmooth_clip_smthfact: "",
  rwi_smthsprdclip_low: "low clip (percent), default 35%",
  rwi_smthsprdclip_high: "high clip (percent), default 90%",
  rwi_smthsprdclip_smthfact: "smoothing factor to use for clips (percent), default 85%",
  finalsmoothx1: "",
  finalsmoothx2: "",
  finalsmoothx3: "",
  finalsmoothcut: "",
  finalsmoothramp: "",
  finalmodelsmoothcutvel: "",
  modelsmoothx1: "",
  modelsmoothx2: "",
  modelsmoothx3: "",
  modelsmoothcut: "",
  modelsmoothramp: "",
  modelsmoothcutvel: "won't touch anywhere in model that has Vp < this value",
  finalsmoothvelcut: "smoothed update will be zero wherever Vp < this value",
  rwi_cut_taper: "",
  rwi_offset_cut: "",
  rwi_offset_grow: "",
  outer_mute_start_time: "",
  outer_mute_growth: "",
  outer_mute_taper_width: "",
  inner_mute_start_time: "",
  inner_mute_growth: "",
  inner_mute_taper_width: "",
  offset_plane_normal: "project offsets within a plane",
  minoffsetx1: "Specify min offset in inline direction",
  minoffsetx2: "Specify min offset in x-line direction",
  maxoffsetx1: "Specify max offset in inline direction",
  maxoffsetx2: "Specify max offset in x-line direction",
  density_ramps: "",
  min_gardner_vel: "",
  max_gardner_vel: "",
  salt_velocity: "",
  salt_density: "",
  water_mincells: "",
  water_maxcells: "",
  water_velocity: "Specifies which velocity value signifies water. All velocities below Water Velocity will be set to Water Density",
  water_density: "Sets this density for all cells which contain the water velocity",
  gardner_factor: "",
  gardner_power: "",
  fixed_density: "",
  update_density: "Map updates into density generating DenChange checkpoint files",
  update_weight: "",
  scale_i: "",
  scale_r: "",
  steplen: "",
  line_search_method: "Choose between quadratic or linear line search methods for step factor calculation. The step factor is the multiplier applied to the model update.",
  line_search_max_steps: "Maximum number of steps line search method can perform.",
  badresidweight: "",
  starttime: "The start time of the traces, this is usually set to 0.0 ",
  endtime: "The end time of the traces for modelling. ",
  minoffset: "Limit the min offset to be processed/modelled. Receivers outside this radius will be ignored",
  maxoffset: "Limit the max offset to be processed/modelled. Receivers outside this radius will be ignored",
  amplitude: "yes = Use amplitude of predicted | no = Use amplitude of observed",
  normalise: "yes = Divide each trace with norm of observed trace",
  timetweak_ratefact: "",
  timetweak_maxfact: "",
  shot_autoscaling: "Scale each predicted shot gather to match field",
  source_scaling: "",
  corr_match_weight: "Controls how much to use the correlation of pred & obs to weight the matching between the two",
  final_iter_srcdiff: "",
  final_iter_srcsmth: "",
  source_differ: "",
  source_smooth: "",
  gausswid: "",
  minimum_traces: "",
  functional: "Choose the method of calculating the adjoints/residuals.",
  slowness: "Invert for slowness rather than velocity ",
  spatial: "Use spatial preconditioning ",
  conj_grad: "",
  accel_grad: "",
  reduce_oscillation: "",
  gradmaskang: "degrees; affects VS iters and iters in normal non-RWI blocks",
  long_contrib_x1: "Contributions of long-wavelength gradient components for NX1 direction",
  long_contrib_x2: "Contributions of long-wavelength gradient components for NX2 direction",
  long_contrib_x3: "Contributions of long-wavelength gradient components for NX3 direction",
  short_contrib_x1: "Contributions of short-wavelength gradient components for NX1 direction",
  short_contrib_x2: "Contributions of short-wavelength gradient components for NX2 direction",
  short_contrib_x3: "Contributions of short-wavelength gradient components for NX3 direction",
  percent: "",
  awi_scale: "",
  awi_top: "",
  awi_normfilt: "",
  awi_atype: "",
  awi_rtype: "",
  awi_steptype: "",
  awi_protectspike: "",
  awi_amin: "",
  awi_decimate: "",
  awi_diffenv: "",
  awi_filtsmooth: "",
  awi_flavour: "",
  awi_length: "",
  awi_max_freq: "",
  awi_maxsubtraces: "",
  awi_noise: "",
  awi_taper: "",
  awi_full_on: "",
  awi_min_freq: "",
  awi_mix: "",
  awi_mix_dist: "",
  awi_overcook: "",
  awi_post_filt: "",
  awi_power: "",
  awi_pre_filt: "",
  awi_rmin: "",
  awi_smooth_dat: "",
  awi_smooth_src: "",
  awi_start: "",
  awi_tile_traces: "",
  awi_trace_deci: "",
  awi_rnormfilt: "",
  pyawi: "",
  tile_width: "",
  tiling: "",
  tile_extension: "",
  math_mode: "",
  protect_spike: "",
  sailline_edge_taper: "",
  filtsmooth_offset: "",
  offset_scale: "",
  decimate: "",
  max_freq: "",
  trace_window: "",
  direction: "",
  use_fft: "",
  length: "",
  width: "",
  noise: "",
  filtsmooth: "",
  scale: "",
  normfilt: "",
  top: "",
  d_scale: "",
  filter_dim: "",
  filtsmooth_time: "",
  frequencies: "",
  mode: "",
  mu: "",
  parallel: "",
  sailline_length: "",
  salline_length: "",
  tile_time: "",
  total_iterations: "Total number of iterations this job has run for",
  velcutoff: "Original velocities below this are never changed",
  velcon_min: "Velocity updates are clipped to this lower bound",
  auto_minvel: "[percent]  ! using 'VelConMin : auto' is the same as 5 percent",
  velstart_min: "Minimum Velocity of the Vp Start Model",
  vs_start_min: "Minimum Velocity of the Vs Start Model",
  delta_start_min: "Minimum Velocity of the True Delta Model",
  eps_start_min: "Minimum Velocity of the True Epsilon Model",
  velcon_max: "Velocity updates are clipped to this upper bound",
  auto_maxvel: "[percent]  ! using 'VelConMax : auto' is the same as 5 percent",
  velstart_max: "Maximum Velocity of the Vp Start Model",
  vs_start_max: "Maximum Velocity of the Vs Start Model",
  delta_start_max: "Maximum Velocity of the True Delta Model",
  eps_start_max: "Maximum Velocity of the True Epsilon Model",
  window: "Apply tropizoidal window to update in vertical direction",
  w1: "",
  w2: "",
  w3: "",
  w4: "",
  window1: "",
  window2: "",
  window3: "",
  window4: "",
  extra_left: "Number of cells to extend NX1 in one direction",
  extra_right: "Number of cells to extend NX1 in one direction",
  extra_front: "Number of cells to extend NX2 in one direction",
  extra_back: "Number of cells to extend NX2 in one direction",
  extra_top: "Number of cells to extend top of the model. Specify 1 when using a freesurface",
  extra_bottom: "Number of cells to extend bottom of the model.",
  borderx1: "Limit region of computation in NX1",
  borderx2: "Limit region of computation in NX2",
  borderx3: "Limit region of computation in NX3",
  bleft: "Size of absorbing boundary for NX1",
  bright: "Size of absorbing boundary for NX1",
  bfront: "Size of absorbing boundary for NX2",
  bback: "Size of absorbing boundary for NX2",
  btop: "Size of absorbing boundary at the top. Use -99 to utilise a free surface",
  bbottom: "Size of absorbing boundary at the bottom of the model",
  sourceamp: "",
  sourcephase: "",
  fm: "Signifies if the job is a Forward Modelling or not.",
  aws_path: "Path of this job in the AWS bucket.",
  frequency: "Frequency to focus on an iteration. Each Frequency specifies its own Iteration Block",
  gausswidth: "",
  ndone: "Number of iterations completed on a particular iteration block",
  update_props: "",
  min_traces: "",
  minvpwithvs: "",
  modelsmoothvelcut: "",
  reduce_oscillations: "",
  timeshift_adjoint: "",
  vptovsratio: "",
  vs_override_max_con: "",
  demult_match: "",
  demult_ghosts: "",
  final_tracefit: "",
  parent_job_cp: "Checkpoint of the job which this model originated from",
  parent_job_name: "Name of the job which this model originated from",
};
