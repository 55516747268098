export const allSliceProcessings = {
  grad: ["_zero_centred_params_process"],
  prec: ["_zero_centred_params_process"],
  den: ["_min_range_params_process"],
  dens: ["_min_range_params_process"],
  Vp: ["_min_range_params_process"],
  Vs: ["_min_range_params_process"],
  VpMin: ["_min_range_params_process"],
  VpMax: ["_min_range_params_process"],
  Gradient: ["_zero_centred_params_process"],
  "Raw Gradient": ["_zero_centred_params_process"],
  "InvVp Update": ["_zero_centred_params_process"],
  "Vp Update": ["_zero_centred_params_process"],
  "Vs Update": ["_zero_centred_params_process"],
  "InvVs Update": ["_zero_centred_params_process"],
  Total_Vp_Update: [
    "_zero_centred_params_process",
    "_min_range_params_process",
  ],
  "Total Vs Update": [
    "_zero_centred_params_process",
    "_min_range_params_process",
  ],
  RWI_Perturb: ["_zero_centred_params_process"],
  RTM: ["_zero_centred_params_process"],
  StructSmth: ["_zero_centred_params_process"],
  MedianSmth: ["_zero_centred_params_process"],
  Image: ["_zero_centred_params_process"],
  CustomImage: ["_zero_centred_params_process"],
  ForwardWavefield: ["_zero_centred_params_process"],
  BackwardWavefield: ["_zero_centred_params_process"],
  vdiff: ["_zero_centred_params_process", "_min_range_params_process"],
  "vdiff central": [
    "_zero_centred_params_process",
    "_min_range_params_process",
  ],
  "Impedance vdiff": [
    "_zero_centred_params_process",
    "_min_range_params_process",
  ],
  AWIUpdate: ["_zero_centred_params_process"],
  RWIUpdate: ["_zero_centred_params_process"],
  CombinedUpdate: ["_zero_centred_params_process"],
  Laplacian: ["_zero_centred_params_process", "_min_range_params_process"],
  SmoothingSmoothUpdate: ["_zero_centred_params_process"],
  SmoothingUpdate: ["_zero_centred_params_process"],
  "InvVp-Upd-StructSmooth": ["_zero_centred_params_process"],
  "InvVp-Upd-Unsmooth": ["_zero_centred_params_process"],
  Sigma: ["_min_range_params_process"],
  Reflectivity: ["_zero_centred_params_process"]
};
