import { Injectable } from "@angular/core";
import { Observable, Subscription, BehaviorSubject, throwError } from "rxjs";
import { AuthService } from "../../shared/services/auth.service";
import { HttpService } from "../../shared/services/http.service";
import { AppConfig } from "../../app.config";
import { HttpHeaders } from "@angular/common/http";
import { map, retry, catchError } from "rxjs/operators";

@Injectable()
export class PaletteService {
  constructor(private http: HttpService, private authService: AuthService) {}

  getPalettes(): Observable<string[]> {
    let headers = this.getAuthorizationHeader();
    return this.http
      .get(`${AppConfig.settings.apiUrl}/available_palettes/`, { headers })
      .pipe(
        retry(2),
        map((response: object) => {
          if (!response) return;

          let data = response["palette"];
          return data;
        }),
        catchError((err) => throwError(err))
      );
  }

  private getAuthorizationHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );

    return headers;
  }
}
