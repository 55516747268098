<footer class="footer ">
  <div class="container hidden-md hidden-xs">
    <!-- ROW -->
    <div class="row">
      <div class="col-lg-3 col-md-4 col-xs-12">
        <!-- COMPANY DETAILS -->

        <div class="company-details">
          <p class="heading">S-Cube</p>
          <address>
            <p><span>52 Princes Gate</span><br><span>Exhibition Road</span><br><span>London&nbsp;SW7 2PG</span><br><span>United Kingdom</span></p>
          </address>
          <p>Tel. +44 (0) 20 7594 6415</p>
          <p>Email. <a href="mailto:info@s-cube.com">info@s-cube.com</a></p>
        </div>

        <!--// COMPANY DETAILS -->
      </div>
      <div class="col-lg-5 col-md-4 col-xs-12">

        <!--<nav class="quick-links">
          <p class="heading">Quick links</p>
          <ul class="row">
            <li class="col-lg-4 col-xs-6">
              <a href="https://www.s-cube.com/contact/">Contact</a>
            </li>
          </ul>
        </nav>-->

      </div>
      <div class="col-md-3 col-md-offset-1 col-xs-12">

        <nav class="social">
          <p class="heading">Social pages</p>
          <ul>
                  <li class="linkedin"><a href="https://www.linkedin.com/company/xwi/" class="symbol" target="_blank" title="LinkedIn">linkedin</a></li>
          </ul>
        </nav>
        <!-- <span>
          App Version: {{appVersion}}
        </span> -->

      </div>
    </div>
    <!--// ROW -->
    <!-- ROW -->
    <div class="row">
      <div class="col-xs-12">
        <nav class="footer">
          <ul>
            <li class="copyright"><div>© Sub Salt Solutions Ltd {{currentYear | date:'yyyy'}}</div></li>
          </ul>
        </nav>
      </div>
    </div>
    <!--// ROW -->
  </div>
</footer>
