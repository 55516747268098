import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface KeepJobDialogData {
    keep_job: boolean;
    job_name: string;
}

@Component({
    selector: 'app-keep-job',
    templateUrl: './keep-job.component.html',
    styleUrls: ['./keep-job.component.css']
})
export class KeepJobComponent implements OnInit {
    keep_job: boolean;
    job_name: string;
    keep_job_setting_form: UntypedFormGroup;
    constructor(@Inject(MAT_DIALOG_DATA) public data: KeepJobDialogData, public dialogRef: MatDialogRef<KeepJobComponent>, public fb: UntypedFormBuilder ) {
        this.keep_job_setting_form = this.fb.group({
        })
        this.keep_job = this.data.keep_job;
        this.job_name = this.data.job_name;
    }

    ngOnInit() {
    }

    submit() {
        this.dialogRef.close({confirm: true});
    }

    cancel() {
        this.dialogRef.close({confirm: false});
    }
}