<div class="spc resources">
  <div class="list-group">
    <a *ngFor="let item of list" class="item list-group-item">
      <div class="row">
        <div class="col-md-10" (click)="$event.stopPropagation(); navigateSelection(item)" >
          <p class="heading">{{item.name}}<i class="ion-link pull-right after c1-text"></i></p>
          <div class="text" *ngFor="let description of item.descriptions">
            <p><em>{{description}}</em></p>
          </div>
        </div>
        <div class="col-md-1">
          <mat-checkbox [checked]="_selectedItems.indexOf(item.id)>=0" (click)="onClickItem($event, item.id)"></mat-checkbox>
        </div>
      </div>
    </a>
  </div>
</div>
