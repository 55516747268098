import { Component, OnInit, AfterViewChecked, OnChanges, SimpleChanges, Input, ElementRef, ViewChild, HostListener} from '@angular/core';

declare var Plotly: any;

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.less']
})
export class ChartLegendComponent implements OnInit, OnChanges  {
    @ViewChild('chart', { static: true }) chart: ElementRef;
    @Input() range: number[] = [0,1];
    @Input() isLoading: boolean = false;

    constructor() { }

    ngOnInit() {
    }
    
    onResize(event) {
        var layout = {
            width: this.chart.nativeElement.offsetWidth,
            height: this.chart.nativeElement.offsetHeight
        };
        Plotly.relayout(this.chart.nativeElement, layout);
    }

    ngOnChanges(changes: SimpleChanges) {
        let changed = false;
        for (let propName in changes) {
            if ((propName == "isLoading" && changes[propName].currentValue === true)
                || (propName == "iteration")){
                changed = true;
            }
        }

        if (changed) {
            this.updatePlot();
        }
    }

    private updatePlot() {
        this.isLoading = true;
        var layout = {
            width: this.chart.nativeElement.offsetWidth,
            height: this.chart.nativeElement.offsetHeight,
            xaxis: {
              autorange: true,
              showgrid: false,
              zeroline: false,
              showline: false,
              autotick: false,
              ticks: '',
              showticklabels: false
            },
            yaxis: {
              autorange: true,
              showgrid: false,
              zeroline: false,
              showline: false,
              autotick: false,
              ticks: '',
              showticklabels: false
            },
            margin: {
                l: 50,
                r: 50,
                b: 50,
                t: 10,
                pad: 4
            }
        };

        var data = [{
          z: this.range,
          colorscale: [
            ['0.0', 'rgb(0,0,182)'],
            ['0.083333333333', 'rgb(23,34,215)'],
            ['0.166666666667', 'rgb(61,91,234)'],
            ['0.25', 'rgb(82,156,252)'],
            ['0.333333333333', 'rgb(19,236,255)'],
            ['0.5', 'rgb(68,187,0)'],
            ['0.583333333333', 'rgb(170,213,0)'],
            ['0.666666666667', 'rgb(255,229,0)'],
            ['0.75', 'rgb(255,165,0)'],
            ['0.833333333333', 'rgb(241,88,0)'],
            ['0.916666666667', 'rgb(197,44,0)'],
            ['1.0', 'rgb(153,0,0)']
          ],
          type: 'heatmap'
        }];

        var options = {
          scrollZoom: false,
          showLink: false,
          displayModeBar: false
        };

        Plotly.newPlot(this.chart.nativeElement, data, layout, options);
        this.isLoading = false;
    }
}
