import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";

interface JobItem {
  id: string;
  job__id: string;
  job__job_name: string;
  job__job_basepath: string;
  job__comments: string;
}

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<JobItem> | null;
  sortDirections: NzTableSortOrder[];
  listOfFilter?: NzTableFilterList;
  filterMultiple?: boolean;
  filterFn?: NzTableFilterFn<JobItem> | null;
  width?: string;
}

@Component({
  selector: "app-job-groups-inner-table",
  templateUrl: "./job-groups-inner-table.component.html",
  styleUrls: ["./job-groups-inner-table.component.css"],
})
export class JobGroupsInnerTableComponent {
  listOfColumns: ColumnItem[] = [
    {
      name: "Job Name",
      sortOrder: null,
      sortFn: (a: JobItem, b: JobItem) =>
        a.job__job_name.localeCompare(b.job__job_name),
      sortDirections: ["ascend", "descend", null],
    },
    {
      name: "Job Comments",
      sortOrder: null,
      sortFn: (a: JobItem, b: JobItem) =>
        a.job__comments.localeCompare(b.job__comments),
      sortDirections: ["ascend", "descend", null],
    },
  ];
  isLoading: boolean = false;

  @Input() jobs: JobItem[];
  @Output() removeJob = new EventEmitter<JobItem>();

  constructor() {}

  removeJobFromGroup(data: JobItem) {
    this.removeJob.emit(data);
  }

  openGroup() {
    window.open(
      `/projects/${this.jobs[0].job__id}/model?${this.jobs
        .map((job: JobItem) => `jobs=${job.job__id}`)
        .join("&")}`,
      "_blank"
    );
  }
}
