<div>
  <div (click)="$event.preventDefault()">
    <img [src]='_data.pic' class='image'/>
    <div class="arrows prev" (click)="changeScreenshot(-1)"></div>
    <div class="arrows next" (click)="changeScreenshot(1)"></div>
  </div>
  <div class='caption-form'>
    <form>
      <div class='caption row'>
        <mat-dialog-content class='col-md-8'>
          <mat-form-field [style.width.%]=95>
            <input  id='caption' class='caption-text' matInput name='caption' [(ngModel)]="currentCaption">
          </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions class='col-md-4' style='float: right;'>
          <button (click)="updateCaption()" class='caption-button' mat-raised-button> Save Caption </button>
          <button type="reset" mat-dialog-close class='caption-button' mat-raised-button> Close </button>
        </mat-dialog-actions>
      </div>
    </form>
  </div>
</div>
