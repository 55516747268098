import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { take } from "rxjs/operators";
import { Job } from "../../../../models/job";
import { JobDetailDialog } from "../../../../models/jobDetailDialog";
import { Project } from "../../../../models/project";
import { ProjectService } from "../../../../shared/services/project.service";

@Component({
  selector: "app-job-detail",
  templateUrl: "./job-detail.component.html",
  styleUrls: ["./job-detail.component.less"],
})
export class JobDetailComponent implements OnInit {
  projects: Project[];
  jobSettingsForm: UntypedFormGroup;
  jobs: Job[]; // candidates of parent job
  parentJobControl = new UntypedFormControl("");
  parentJobMaxIters = 0;
  parentJobCPNUMControl = new UntypedFormControl({ value: 0, disabled: true });
  parentJobCPNUM = 0;
  offshootFormControl = new UntypedFormControl(false);
  offshoot = false;

  updateIterationsValidator(): void {
    Promise.resolve().then(() =>
      this.jobSettingsForm?.controls?.iterations.updateValueAndValidity()
    );
    Promise.resolve().then(() =>
      this.jobSettingsForm?.controls?.iterationsComplete.updateValueAndValidity()
    );
    this.jobSettingsForm.controls.iterations.markAsTouched();
    this.jobSettingsForm.controls.iterationsComplete.markAsTouched();
  }
  iterationsValidator = (control: UntypedFormControl) => {
    if (
      this.jobSettingsForm?.controls?.iterationsComplete.value >
      this.jobSettingsForm?.controls?.iterations.value
    ) {
      return { iterationsInvalid: true };
    }
    return {};
  };

  updateParentJobCPnumValidator(): void {
    Promise.resolve().then(() =>
      this.parentJobCPNUMControl.updateValueAndValidity()
    );
  }
  parentJobCPnumValidator = (control: UntypedFormControl) => {
    if (control.value > this.parentJobMaxIters) {
      return { cpInvalid: true };
    }
    return {};
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: JobDetailDialog,
    public dialogRef: MatDialogRef<JobDetailComponent>,
    private projectService: ProjectService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.projectService.getProjects().subscribe((p) => {
      this.projects = p;
      let project = p.find((project) => project.id == this.data.projectId);
      if (project != null) {
        let thisJob = project.jobs.find((job) => job.name == this.data.name);
        if (thisJob.parent_job_id != null) {
          this.parentJobControl.patchValue(thisJob.parent_job_id);
          this.parentJobCPNUMControl.patchValue(this.data.parent_cp_num);
          this.parentJobCPNUMControl.enable();

          this.offshootFormControl.patchValue(this.data.offshoot);
          this.projectService
            .getJobIterationsCompleted(thisJob.parent_job_id)
            .pipe(take(1))
            .subscribe((res) => {
              if (res == null) {
                return;
              }
              this.parentJobMaxIters = res;
            });
        }
        this.jobs = project.jobs.filter(
          (job) => job.parent_job_id != thisJob.id && job.id != thisJob.id
        );
      }
    });
    this.jobSettingsForm = this.fb.group(
      {
        name: [this.data.name, Validators.required],
        projectId: [this.data.projectId, Validators.required],
        basePath: [this.data.basePath, Validators.required],
        iterations: [
          this.data.iterations,
          [
            Validators.required,
            Validators.pattern("^[0-9]+$"),
            this.iterationsValidator,
          ],
        ],
        iterationsComplete: [
          this.data.iterationsComplete,
          [
            Validators.required,
            Validators.pattern("^[0-9]+$"),
            this.iterationsValidator,
          ],
        ],
        status: [this.data.status, Validators.required],
        comments: [this.data.comments],
        tag: [
          Object.entries(JSON.parse(this.data.tag || "{}"))
            .map((k) => `${k[0]}: ${k[1]}`)
            .join("; "),
        ],
      },
      {}
    );
    this.parentJobCPNUMControl.addValidators([
      Validators.required,
      Validators.pattern("^[0-9]+$"),
      this.parentJobCPnumValidator,
    ]);
  }

  submitForm() {
    if (!this.jobSettingsForm.valid) return;

    this.data.name = this.jobSettingsForm.get("name").value;
    this.data.projectId = this.jobSettingsForm.get("projectId").value;
    this.data.basePath = this.jobSettingsForm.get("basePath").value;
    this.data.iterations = this.jobSettingsForm.get("iterations").value;
    this.data.iterationsComplete =
      this.jobSettingsForm.get("iterationsComplete").value;
    this.data.status = this.jobSettingsForm.get("status").value;
    this.data.comments = this.jobSettingsForm.get("comments").value;
    let tags = {};
    this.jobSettingsForm
      .get("tag")
      .value.split(";")
      .forEach((p) => {
        let keyvalue = p.split(":");
        if (!keyvalue || keyvalue.length != 2) return;
        tags[keyvalue[0].trim()] = keyvalue[1].trim();
      });
    this.data.tag = JSON.stringify(tags);
    this.data.parent_job_id = this.parentJobControl.value;
    if (this.data.parent_job_id == null) {
      this.data.parent_cp_num = null;
      this.data.offshoot = null;
    } else {
      this.data.parent_cp_num = this.parentJobCPNUMControl.value;
      this.data.offshoot = this.offshootFormControl.value;
    }
    this.dialogRef.close(this.data);
  }

  onParentJobChanged($event) {
    if (this.parentJobControl.value != null) {
      this.projectService
        .getJobIterationsCompleted(this.parentJobControl.value)
        .pipe(take(1))
        .subscribe((res) => {
          if (res != null) {
            this.parentJobMaxIters = res;
            this.parentJobCPNUMControl.enable();
            if (this.parentJobCPNUMControl.value > this.parentJobMaxIters) {
              this.parentJobCPNUMControl.patchValue(this.parentJobMaxIters);
            }
          }
        });
    } else {
      this.parentJobCPNUMControl.disable();
      this.parentJobCPNUMControl.patchValue(0);
    }
  }
}
