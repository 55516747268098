import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { JobDetailDialog } from "../../../models/jobDetailDialog";
import { MatDialog } from "@angular/material/dialog";
import { KeepJobComponent } from "../../project/dialogs/keep-job/keep-job.component";
import { Project } from "../../../models/project";
import { RebuildTableComponent } from "../../project/dialogs/rebuild-project-table/rebuild-project-table.component";

export interface ProjectTableEntry {
  job_id: string;
  job_name: string;
  job_type: string;
  storage_cost: number;
  iters: number;
  job_status: string;
  keep: boolean;
  possible_savings: number;
}

@Component({
  selector: "app-project-table",
  templateUrl: "./project-table.component.html",
  styleUrls: ["./project-table.component.less"],
})
export class ProjectTableComponent implements OnInit {
  table_data: ProjectTableEntry[] = [];
  project_id: string = "";
  project_name: string = "";
  project_description: string = "";
  is_getting_project_table: boolean = false;
  is_exporting_as_csv: boolean = false;
  sort_by: string = "job_id";
  sort_order: string = "asc";
  project_total_storage_cost: number = 0;

  cost_std: number = 0.023; //standard storage class
  cost_ir: number = 0.004; //Glacier IR storage class
  cost_da: number = 0.00099; //deep archive storage class
  project_total_savings_possible: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: CachedProjectService,
    public dialog: MatDialog
  ) {
    this.projectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project_id = project.id;
        this.project_name = project.name;
        this.project_description = project.description;
        this.get_project_table(this.project_id);
      }
    });
  }

  ngOnInit() {}

  get_project_table(project_id, rebuild = false) {
    this.is_getting_project_table = true;
    this.projectService
      .getProjectTable(project_id, rebuild)
      .toPromise()
      .then((data) => {
        this.is_getting_project_table = false;
        let results = data["results"];
        this.table_data = [];
        for (let result of results) {
          let entry: ProjectTableEntry = {
            job_id: result["job_id"],
            job_name: result["job_name"],
            job_type: result["job_type"],
            storage_cost: result["storage_cost"],
            iters: result["iters"],
            job_status: result["job_status"],
            keep: result["keep"],
            possible_savings: 0,
          };

          if (
            entry.job_status == "keep" ||
            entry.job_status == "< 7 days (full storage)"
          ) {
            let bits = entry.storage_cost / this.cost_std;
            entry.possible_savings = entry.storage_cost - bits * this.cost_ir;
          }

          this.project_total_savings_possible += entry.possible_savings || 0;
          this.project_total_storage_cost +=
            Number(result["storage_cost"]) || 0;

          this.table_data.push(entry);
        }
      });
  }

  _get_project_table(project_id, rebuild = false) {
    this.is_getting_project_table = true;
    this.projectService
      ._getProjectTable(project_id, rebuild)
      .toPromise()
      .then((data) => {
        this.is_getting_project_table = false;
        let results = data["results"];
        this.table_data = [];
        for (let result of results) {
          let entry: ProjectTableEntry = {
            job_id: result["job_id"],
            job_name: result["job_name"],
            job_type: result["job_type"],
            storage_cost: result["storage_cost"],
            iters: result["iters"],
            job_status: result["job_status"],
            keep: result["keep"],
            possible_savings: 0,
          };
          if (
            entry.job_status == "keep" ||
            entry.job_status == "< 7 days (full storage)"
          ) {
            let bits = entry.storage_cost / this.cost_std;
            entry.possible_savings = entry.storage_cost - bits * this.cost_ir;
          }

          this.project_total_savings_possible += entry.possible_savings || 0;
          this.project_total_storage_cost +=
            Number(result["storage_cost"]) || 0;
          this.table_data.push(entry);
        }
      });
  }

  rebuild_project_table(project_id) {
    this.dialog
      .open(RebuildTableComponent, {
        height: "320px",
        width: "400px",
        data: {
          project_name: this.project_name,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined && result["confirm"]) {
          this._get_project_table(project_id, true);
        }
      });
  }

  update_entry(job_id, keep_job) {
    this.projectService
      ._getJobDetail(job_id)
      .toPromise()
      .then((data) => {
        let jobDetailDialog: JobDetailDialog = {
          id: data["id"],
          name: data["name"],
          projectId: data["projectId"],
          basePath: data["basePath"],
          iterations: data["iterations"],
          iterationsComplete: data["iterationsComplete"],
          status: data["status"],
          comments: data["comments"],
          tag: data["tag"],
          parent_job_id: data["parent_job_id"],
          parent_cp_num: data["parent_cp_num"],
          keep_job: keep_job,
        };
        this.projectService
          .updateJobDetails(jobDetailDialog)
          .toPromise()
          .then((data) => {
            this.is_getting_project_table = true;
            this.projectService
              ._getProjectTable(this.project_id, true, [job_id])
              .toPromise()
              .then((data) => {
                this.is_getting_project_table = false;
                let results = data["results"];
                let entry: ProjectTableEntry = {
                  job_id: results[0]["job_id"],
                  job_name: results[0]["job_name"],
                  job_type: results[0]["job_type"],
                  storage_cost: results[0]["storage_cost"],
                  iters: results[0]["iters"],
                  job_status: results[0]["job_status"],
                  keep: results[0]["keep"],
                  possible_savings: 0,
                };
                if (
                  entry.job_status == "keep" ||
                  entry.job_status == "< 7 days (full storage)"
                ) {
                  let bits = entry.storage_cost / this.cost_std;
                  entry.possible_savings =
                    entry.storage_cost - bits * this.cost_ir;
                }

                let index = this.table_data.findIndex(
                  (x) => x.job_id == job_id
                );
                this.table_data[index] = entry;
                this.table_data = this.table_data.slice();
              });
          });
      });
  }

  export_table_as_csv(id) {
    this.is_exporting_as_csv = true;
    this.projectService
      .getProjectTableCSV(id)
      .toPromise()
      .then((data) => {
        this.is_exporting_as_csv = false;
        let results = data["results"];
        let fields = [
          "job_id",
          "job_name",
          "job_type",
          "storage_cost",
          "iters",
          "job_status",
          "keep",
          "bucket",
          "key",
          "sequence",
        ];
        let csvstr = fields.join(",") + "\n";
        for (let row of results) {
          let entry = fields.map((x) => row[x]);
          csvstr += entry.join(",") + "\n";
        }
        let blob = new Blob([csvstr], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "project_table.csv";
        a.click();
      });
  }

  _export_table_as_csv(id) {
    this.is_exporting_as_csv = true;
    this.projectService
      ._getProjectTableCSV(id)
      .toPromise()
      .then((data) => {
        this.is_exporting_as_csv = false;
        let results = data["results"];
        let fields = [
          "job_id",
          "job_name",
          "job_type",
          "storage_cost",
          "iters",
          "job_status",
          "keep",
          "bucket",
          "key",
          "sequence",
        ];
        let csvstr = fields.join(",") + "\n";
        for (let row of results) {
          let entry = fields.map((x) => row[x]);
          csvstr += entry.join(",") + "\n";
        }
        let blob = new Blob([csvstr], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "project_table.csv";
        a.click();
      });
  }

  nav_to_job(job_id) {
    window.open("/projects/" + job_id + "/model", "_blank");
    // this.router.navigate(['../projects', job_id, 'model']);
  }

  keep_job(job_id: string) {
    let keep = this.table_data.find((x) => x.job_id == job_id).keep;
    let name = this.table_data.find((x) => x.job_id == job_id).job_name;
    this.dialog
      .open(KeepJobComponent, {
        width: "500px",
        data: {
          job_name: name,
          keep_job: keep,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined && result["confirm"]) {
          keep = !keep;
          this.table_data.find((x) => x.job_id == job_id).keep = keep;
          this.update_entry(job_id, keep);
          this.table_data = this.table_data.slice();
        }
      });
  }

  get_color_for_type(type: string) {
    switch (type) {
      case "A or AF or F":
      case "A, AF, F":
        return "#e74c3c";
      case "AFR":
        return "#e67e22";
      case "FRA within AFR":
      case "FRA":
        return "#f1c40f";
      case "FAFR within AFR":
      case "FAFR":
        return "#2ecc71";
      case "other":
        return "#3498db";
      case "R":
        return "#9b59b6";
      default:
        return "default";
    }
  }

  sort_table(sort_by: string) {
    if (sort_by == this.sort_by) {
      this.sort_order = this.sort_order == "asc" ? "desc" : "asc";
    } else {
      this.sort_order = "asc";
      this.sort_by = sort_by;
    }
    this.table_data = this.table_data.sort((a, b) => {
      let a_val = a[sort_by];
      let b_val = b[sort_by];
      if (a_val < b_val) {
        return this.sort_order == "asc" ? -1 : 1;
      } else if (a_val > b_val) {
        return this.sort_order == "asc" ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  four_dp(num: number) {
    return num.toFixed(4);
  }

  getTooltipText(status: string) {
    let tooltipText = "";

    switch (status) {
      case "keep":
        tooltipText =
          "All the files of this job have been retained in standard storage. Keep in mind that this is the most costly form of storage.";
        break;
      case "glacier instant retrieval":
        tooltipText =
          "These jobs have had most of their files deleted, but the important ones have been retained. You can still see all the relevant information in this project while saving on storage costs. Our suggestion is to keep all inactive jobs in Glacial instant retrieval.";
        break;
      case "full storage":
        tooltipText =
          "The jobs marked 'full storage' will be sent to Glacier Instant Retrieval. You will still be able to access all relevant information in these jobs and models, while saving alot on storage costs.";
        break;
      case "< 7 days (full storage)":
        tooltipText =
          "These jobs have been created in the past 7 days. If you decide to not 'keep' these jobs, they will automatically be set to full storage, and sent to Glacier Instant Retrieval after 7 days.";
        break;
      case "glacier deep archive":
        tooltipText =
          "These jobs have been put in deep archive. Please consult the admin to unarchive these jobs.";
        break;
      default:
        break;
    }

    return tooltipText;
  }
}
