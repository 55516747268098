import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Invoice } from '../models/invoice';
import { InvoiceStatus } from '../models/invoiceStatus';
import { BillingDetail } from '../models/billingDetail';
import { UserDetail } from '../models/userDetail';
import * as countriesJson from '../../assets/data/countries.json';
import { StripeToken, StripeCard, StripeCardOptions, StripeClasses, StripeStyling, StripeScriptTag } from 'stripe-angular';
import { NgForm } from '@angular/forms';
import { BillingService } from '../shared/services/billing.service';
import { CachedUserService } from '../shared/services/cached-user.service';
import { Router } from '@angular/router';
import { AppConfig } from '../app.config';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.less']
})
export class BillingComponent implements OnInit {
  @ViewChild(NgForm) form;
  @ViewChild('stripeCard') stripeCard: StripeCard;
  isLoadingDetails: boolean = true;
  isLoadingInvoices: boolean = true;
  isEditing: boolean = false;
  isEditingPayment: boolean = false;
  paymentError: string;

  stripeOptions: StripeCardOptions = {
    classes: <StripeClasses>{},
    hidePostalCode: true,
    hideIcon: false,
    iconStyle: "default",
    placeholder: "",
    style: <StripeStyling>{}
  };

  billingDetail: BillingDetail = <any>{};
  billingDetailEdit: BillingDetail;

  invoices: Invoice[];
  page: number = 1;
  countries = {};
  countryCodes = {};

  userDetail: UserDetail = null;
  
  constructor(private billingService: BillingService, private userService: CachedUserService, private router: Router, public StripeScriptTag: StripeScriptTag) {
    this.countries = countriesJson;
    this.countryCodes = Object.keys(this.countries);
  }

  ngOnInit() {
    this.StripeScriptTag.setPublishableKey(AppConfig.settings.stripeKey);

    this.userService.getUserDetails().subscribe(detail => {
      this.userDetail = detail;

      if (!this.userDetail.groupName) {
        this.router.navigate(['/projects']);
        return;
      }

      this.loadBilling();
      this.loadInvoices();
    });
  }

  loadInvoices() {
    this.billingService.getInvoices(this.userDetail.groupName).subscribe(invoices => {
      this.invoices = invoices;
      this.isLoadingInvoices = false;
    }, error => {
      this.isLoadingInvoices = false;
      this.invoices = [];
    });
  }

  loadBilling() {
    this.isLoadingDetails = true;
    this.billingService.getBillingDetail(this.userDetail.groupName).subscribe(detail => {
      this.billingDetail = detail;
      this.billingDetail.id = this.userDetail.groupName;
      this.isLoadingDetails = false;
    }, error => {
      this.isLoadingDetails = false;
      if (error.status == 404) {
        this.editBilling();
      }
    });
  }

  getAddress(billing: BillingDetail): string{
    var address = billing.address;
    if (!!billing.city) {
      address += ", " + billing.city;
    }
    if (!!billing.county) {
      address += ", " + billing.county;
    }
    if (!!billing.postcode) {
      address += ", " + billing.postcode;
    }
    if (!!billing.country) {
      address += ", " + billing.country;
    }

    return address;
  }

  editBilling() {
    this.billingDetailEdit = { ...this.billingDetail };
    this.isEditing = true;
    this.isEditingPayment = !this.billingDetail.paymentType;
  }

  cancelBilling() {
    this.isEditing = false;
    this.isEditingPayment = false;
    this.paymentError = null;
  }

  editPayment() {
    this.paymentError = null;
    this.isEditingPayment = true;
  }

  cancelPayment() {
    this.isEditingPayment = false;
  }

  submitForm() {
    if (!this.form.valid)
      return;

    this.paymentError = null;
    this.isLoadingDetails = true;
    this.billingDetailEdit.stripeToken = null;

    if (this.isEditingPayment) {
      this.stripeCard.createToken({
        name: this.billingDetailEdit.name,
        address_line1: this.billingDetailEdit.address,
        address_zip: this.billingDetailEdit.postcode,
        address_country: this.billingDetailEdit.country
      });
      return;
    }

    this.updateDetails();
  }

  onStripeInvalid(error: Error) {
    if (!error) {
      this.paymentError = null;
      return;
    }
    this.paymentError = error.message;
    this.isLoadingDetails = false;
  }

  setStripeToken(token: StripeToken) {
    this.billingDetailEdit.stripeToken = token.id;
    this.updateDetails();
  }

  onStripeError(error: Error) {
    this.paymentError = error.message;
    this.isLoadingDetails = false;
  }

  updateDetails() {
    this.isLoadingDetails = true;

    this.billingService.updateBillingDetails(this.billingDetailEdit).subscribe(result => {
      this.isEditing = false;
      this.loadBilling();
    }, (error) => {
      if (error.error != null) {
        var chars = ['{', '}', '[', ']', '"'];
        var msg = JSON.stringify(error.error);
        chars.forEach(c => {
          msg = this.replaceAll(msg, c, '');
        });
        this.paymentError = msg;
      }
      else
        this.paymentError = error;

      this.isLoadingDetails = false;
    });    
  }

  replaceAll(str, find, replace): string {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  }
  showInvoice(url: string) {
    if (!url)
      return;
    window.open(url.replace('/pdf',''), '_blank');
  }
}
