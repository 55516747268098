import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-show-all-filters-dialog",
  templateUrl: "./show-all-filters-dialog.component.html",
  styleUrls: ["./show-all-filters-dialog.component.css"],
})
export class ShowAllFiltersDialogComponent implements OnInit {
  @ViewChild("dialogBody", { static: true }) dialogBodyRef: ElementRef;

  filtersApplied: Object = {};
  allData: Array<Object> = [];
  filtersNegation: Object = {}; // basically show the opposite of the filters. Show what's showing instead of what's hiding. But only for the columns filters have been applied to

  constructor(
    public dialogRef: MatDialogRef<ShowAllFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.filters) {
      this.filtersApplied = this.data.filters;
      this.allData = this.data.data;
    }
  }

  ngOnInit() {
    const el = this.dialogBodyRef.nativeElement as HTMLElement;

    let htmlBody = "";

    if (Object.keys(this.filtersApplied).length == 0) {
      htmlBody += `<h4 style="font-size: medium;margin: 0px;">No filters applied</h4>`;
    }

    Object.keys(this.filtersApplied).forEach((key: string) => {
      const filterValues = this.filtersApplied[key];
      const negation = new Set(
        this.allData
          .map((datum: Object) => datum[key])
          .filter((val: string) => !filterValues.includes(val))
      );

      htmlBody += "<div>";

      htmlBody += `<h4 style="font-size: medium;margin: 0px;">${key
        .split("_")
        .map((word) => word[0].toLocaleUpperCase() + word.substring(1))
        .join(" ")}</h4>`;
      htmlBody += `<ul style="padding-left: 10px;">`;
      for (let filterValue of Array.from(negation)) {
        htmlBody += "<li>";
        htmlBody += filterValue;
        htmlBody += "</li>";
      }
      htmlBody += "</ul>";

      htmlBody += "</div>";
    });

    el.innerHTML = htmlBody;
  }
}
