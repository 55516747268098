import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, style, animate, transition, state } from '@angular/animations';

import { ListItem } from '../shared/list/listitem.model';
import { BreadCrumb } from '../shared/breadcrumb/breadcrumb.model';
import { Project } from "../models/project";
import { Job } from "../models/job";
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectNameComponent } from './project/dialogs/project-name/project-name.component';
import { ProjectDialog } from '../models/projectDialog';
import { AuthService } from '../shared/services/auth.service';
import { Subscription } from 'rxjs';
import { CachedUserService } from '../shared/services/cached-user.service';
import { Router } from '@angular/router';
import { ProjectService } from '../shared/services/project.service';
import { CachedProjectService } from '../shared/services/cached-project.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.less'],
  animations: [
    trigger('slideIn', [
    state('*', style({ 'overflow-y': 'hidden' })),
    state('void', style({ 'overflow-y': 'hidden' })),
    transition('* => void', [
      style({ height: '*', opacity: 1 }),
      animate(250, style({ height: 0, opacity: 0 }))
    ]),
    transition('void => *', [
      style({ height: '0', opacity: 0 }),
      animate(250, style({ height: '*', opacity: 1 }))
    ])
  ])]
})
export class ProjectsComponent implements OnInit, OnDestroy {
  projects: Project[];
  jobItems: {};
  isStaff = false;
  subscription: Subscription = null;
  
  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, private projectService: ProjectService, private cachedProjectService: CachedProjectService, public authService: AuthService, private userService: CachedUserService, private router: Router) { }

  getListFromJobs(jobs: Job[]): ListItem[] {
    return jobs.map(j => {
      if (j.parent_job_id != null) {
        // console.log(j.parent_job_id)
      }
      return new ListItem(j.id, j.name, null,'/projects/' + j.id);
    });
  }

  activeProject: string;

  clickProject(project: Project) {
    this.activeProject === project.id ? this.activeProject = null : this.activeProject = project.id;
  }

  isActiveProject(project: Project): boolean {
    return this.activeProject === project.id;
  }

  breadcrumb = [new BreadCrumb('Home', '/'), new BreadCrumb('Projects', '/projects')];

  ngOnInit() {
    this.cachedProjectService.setCurrentProject(null);

    this.loadProjects();

    this.subscription = this.userService.userDetail.subscribe(d => {
      this.isStaff = d && d.isStaff;
    });
  }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  loadProjects() {
    this.projectService.getProjects()
      .subscribe(projects => {
        this.projects = projects;
        this.jobItems = {};
        for (let project of projects) {
          this.jobItems[project.id] = this.getListFromJobs(project.jobs);
        }
      });
  }

  gotoMonitoring() {
    this.router.navigate(['/monitoring'])
  }

  openProjectDialog() {
    let dialogRef = this.dialog.open(ProjectNameComponent, {
      height: '320px',
      width: '400px',
      data: {
        id:null,
        name: null,
        description: null
      }
    });

    dialogRef.afterClosed().subscribe((result: ProjectDialog) => {
      if (!result)
        return;
      this.projectService.createProject(result).subscribe(data => {
        this.snackBar.open("Project created successfully", null, { duration: 2000 });

        this.loadProjects();
      }, (error) => {
        this.snackBar.open("Sorry, there was an error creating the project", null, { duration: 2000 });
        });
    });
  }

  navigateToProjectTable(project: Project) {
    this.router.navigate(['/project', project.id]);
  }
}
