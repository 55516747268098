<div class="container-fluid">

  <div class="row" style="display: flex;">
    <mat-card appearance="outlined" class="col-md-10 card_container container-fluid" id="slice-settings" style="padding: 0px;">
      <div class=row style="margin: 0px;">
        <div class="sliders form-horizontal iter_setting col-md-7" style="margin: 20px 0px;">
          <div class="form-group iteration-slider">
            <app-slider class="col-sm-12" label="Iteration" [antd]="true" [min]="1" [max]="totalIteration"
              [(value)]="iteration" [step]="1" (valueChange)="onIterationChange()"></app-slider>
          </div>
          <div class="form-group iteration-slider">
            <app-slider class="col-sm-12" label="Depth" [antd]="true" [min]="1" [max]="nx3" [(value)]="sliceNum"
              [step]="1" (valueChange)="onDepthChange()"></app-slider>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="row container-fluid" id="image-and-slice" style="margin: 0; padding: 0px;">
        <div class="row shot-container container-fluid" style="margin: 0;" id="depth-slice">
          <div id="shotsContainer" style="margin: 0; padding: 0;" *ngIf="shotData" class="col-md-7">
            <div *ngIf="loadingShots" class="loading">
              <mat-progress-spinner mode="indeterminate" class="spinner" diameter="30"></mat-progress-spinner>
              <span class="loading-text">Loading Shots</span>
            </div>
            <div class="row" class="error-message" *ngIf="!shotData && !loadingShots">
              <h3> No Shot Information Available!</h3>
            </div>
            <div>
              <div class="row" style="margin: 0; padding: 0; display: flex; justify-content: flex-end;">
                <h5 class="col-sm-5">
                  <div class="circle-grey"></div> Iteration Shots: {{unavailableShots}}
                </h5>
                <h5 class="col-sm-5">
                  <div class="circle-red"></div> Dumped Shots: {{availableShots}}
                </h5>
                <a class="shot-height-trigger" [matMenuTriggerFor]="heightMenu"><i
                    class="zmdi zmdi-hc-2x zmdi-more-vert" style="width: 20px;text-align:right;"></i></a>
                <mat-menu #heightMenu="matMenu">
                  <div class="tooltip-container slider-container" style="text-overflow:clip;"
                    (click)="$event.stopPropagation()">
                    <span>Height</span>
                    <mat-slider [min]="200" [max]="800" step="40">
                      <input matSliderThumb [(ngModel)]="shotGraphHeight" #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <div class="tooltip">{{shotGraphHeight}}</div>
                  </div>
                  <div class="tooltip-container slider-container"
                    style="text-overflow:clip; display: flex; justify-content: space-between;"
                    (click)="$event.stopPropagation()">
                    <span>Shot Radius</span>
                    <mat-slider [min]="1" [max]="10" step="0.5">
                      <input matSliderThumb [(ngModel)]="shotGraphRadius" #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <div class="tooltip">{{shotGraphRadius}}</div>
                  </div>
                  <div
                    *ngIf="!showSonicLog && currentJob.containsLog"
                    mat-menu-item
                    >
                    <!-- [matMenuTriggerFor]="wellMenu" -->
                    <mat-slide-toggle
                      [(ngModel)]="showWellLog"
                      (ngModelChange)="onShowWellLogs()"
                    ></mat-slide-toggle>
                    <span>Well Logs</span>
                  </div>
                  
                  <!-- <mat-menu #wellMenu="matMenu">
                    <button
                      *ngFor="let log of currentJob.wellLogs"
                      mat-menu-item
                      (click)="setWell(log.id)"
                    >
                      <span>IL - {{ log.inline }}, XL - {{ log.crossline }}</span>
                    </button>
                  </mat-menu> -->

                  <button mat-menu-item [matMenuTriggerFor]="shotColourMenu">
                    Shot Colour
                  </button>

                  <nz-input-group nzSize="large" class="legacy-input-styling">
                    <label>Shot Colour Min</label> 
                    <input nz-input [(ngModel)]="shotColor.min" (click)='$event.stopPropagation()'
                    (change)="updateShotColorRef()" >
                  </nz-input-group>
                  
                  <nz-input-group nzSize="large" class="legacy-input-styling">
                    <label>Shot Colour Max</label> 
                    <input nz-input [(ngModel)]="shotColor.max" (click)='$event.stopPropagation()'
                    (change)="updateShotColorRef()" >
                  </nz-input-group>

                  <button mat-menu-item (click)="updateBgRange()">
                    Reset Vp Colour Scale

                  </button>

                  <nz-input-group nzSize="large" class="legacy-input-styling">
                    <label>Min</label> 
                    <input nz-input [(ngModel)]="_minValZ" (click)='$event.stopPropagation()'
                    (keyup)="submitShotsGraphMinMax($event)" >
                  </nz-input-group>

                  <nz-input-group nzSize="large" class="legacy-input-styling">
                    <label>Max</label> 
                    <input nz-input [(ngModel)]="_maxValZ" (click)='$event.stopPropagation()'
                    (keyup)="submitShotsGraphMinMax($event)">
                  </nz-input-group>

                  <mat-checkbox style="margin-left: 5%;" [checked]="plotRcvr" (change)='plotRcvr = !plotRcvr'>Show
                    Receivers
                  </mat-checkbox>

                  <nz-input-group nzSize="large" class="legacy-input-styling">
                    <label>Reciever Sample Interval</label> 
                    <input nz-input [(ngModel)]="rcvrInterval" (click)='$event.stopPropagation()'
                    (focusout)="updateDisplayingRcvr()">
                  </nz-input-group>

                </mat-menu>
              </div>
              <mat-menu #shotColourMenu="matMenu">
                <button mat-menu-item *ngFor="let colourType of shotColourTypes"
                  (click)="selectedShotColourType=colourType.id; $event.stopPropagation()">
                  <span>{{colourType.name}}</span>
                  <i *ngIf="selectedShotColourType===colourType.id" class="zmdi zmdi-check"
                    style="margin-left:2px;"></i>
                </button>
              </mat-menu>
              <div class="row" style="margin: 0px;">
                <app-shots-graph #shots [height]="shotGraphHeight" [plotRcvrs]='plotRcvr' [rcvrData]="rcvrDataDisplay"
                  [shotData]="shotData" [wellLogs]="wellLogs" [backgroundImage]="shotBgImg" [modelScale]="getModelScale(false)"
                  [colorScale]="shotColor" [selectedShot]="selectedShot" [colourType]="selectedShotColourType"
                  [radius]="shotGraphRadius" (onShotSelected)="onShotSelected($event)" [shotType]="shotType"
                  [isMainImage]="false"></app-shots-graph>
              </div>
            </div>
          </div>
          <div *ngIf="shotData" style="padding: 0px 20px;" class="col-md-5 container-fluid control-panel">
            <div class="row" style="margin: 0px; padding: 0px;">
              <div class="panel-info" style="padding: 0px; display: flex; flex-wrap: wrap; gap: 5px 30px">
                <div style="flex: 1 1 45%; display: flex; justify-content: space-between;">
                  <label style="font-weight: 600;">Number of Traces:</label>
                  <span style="text-align: right;">{{numTraces || "N/A"}}</span>
                </div>
                <div style="flex: 1 1 45%; display: flex; justify-content: space-between;">
                  <label style="font-weight: 600;">Total Time:</label>
                  <span style="text-align: right;">{{totalTime?(totalTime | number:"1.0-3")+" s":"N/A"}}</span>
                </div>
                <div style="flex: 1 1 45%; display: flex; justify-content: space-between;">
                  <label style="font-weight: 600;">Number of Samples:</label>
                  <span style="text-align: right;">{{numSamples || "N/A"}}</span>
                </div>
                <div style="flex: 1 1 45%; display: flex; justify-content: space-between;">
                  <label style="font-weight: 600;">Time Interval:</label>
                  <span style="text-align: right;">{{totalTime?(totalTime*1000/numSamples | number:"1.0-3")+" ms":"N/A"}} </span>
                </div>
                <div style="flex: 1 1 45%; display: flex; justify-content: space-between;" *ngIf="isStaff">
                  <label style="font-weight: 600;">Trace fit:</label>
                  <span style="text-align: right;" *ngIf="selectedTraceFit">{{selectedTraceFit[0] || "N/A"}} </span>
                </div>
                <div style="flex: 1 1 45%; display: flex; justify-content: space-between;" *ngIf="isStaff">
                  <label style="font-weight: 600;">Trace fit raw:</label>
                  <span style="text-align: right;" *ngIf="selectedTraceFit">{{selectedTraceFit[1] || "N/A"}} </span>
                </div>
                <div style="flex: 1 1 45%; display: flex; justify-content: space-between;" *ngIf="isStaff">
                  <label style="font-weight: 600;">Shot Id:</label>
                  <span style="text-align: right;">{{selectedShot}} </span>
                </div>
                <div style="flex: 1 1 100%; display: flex; justify-content: space-between;" *ngIf="isStaff">
                  <label style="font-weight: 600;">Shot Colour Type:</label>
                  <span style="text-align: right;" *ngIf="selectedShotColourType">{{shotColorTypeName(selectedShotColourType) ||
                    "N/A"}} </span>
                </div>
              </div>
            </div>
            <div style="display: flex; gap: 30px; justify-content: center;">
              <nz-input-group style="width: 150px; padding: 0px;" class="legacy-input-styling">
                <label for="startTrace" style="left: 10px">Start Trace</label>
                <input nz-input name="startTrace" id="startTrace" 
                  type="number" placeholder="Start Trace" [(ngModel)]="startTrace"
                  (keyup)="onKeySubmission($event)">
              </nz-input-group>
              <nz-input-group style="width: 150px; padding: 0px;" class="legacy-input-styling">
                <label for="endTrace" style="left: 10px">End Trace</label>
                <input nz-input id="endTrace" name="endTrace" 
                  type="number" placeholder="End Trace" [(ngModel)]="endTrace"
                  (keyup)="onKeySubmission($event)">
              </nz-input-group>
            </div>
            <div style="display: flex; gap: 30px; justify-content: center;">
              <nz-input-group style="width: 150px; padding: 0px;" class="legacy-input-styling">
                <label for="startTime" style="left: 10px">Start Time</label>
                <input nz-input name="startTime" id="startTime" 
                  type="number" placeholder="Start Time" [(ngModel)]="start_time"
                  (keyup)="onKeySubmission($event)" (change)="checkStartTimeEndTime()">
              </nz-input-group>
              <nz-input-group style="width: 150px; padding: 0px;" class="legacy-input-styling">
                <label for="endTime" style="left: 10px">End Time</label>
                <input nz-input id="endTime" name="endTime" 
                  type="number" placeholder="End Time" [(ngModel)]="end_time"
                  (keyup)="onKeySubmission($event)" (change)="checkStartTimeEndTime()">
              </nz-input-group>
            </div>
            <div class="row submit" style="margin: 0px">
              <button mat-stroked-button color="primary" [disabled]="!selectedShot" class="col-md-4"
                (click)="onReplotClicked()">Replot</button>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div *ngIf="selectedShot" class="row container-fluid" style="margin: 0;" id="main-image">
          <div *ngIf="shotType && shotType.name =='custom_type' && shotType.options" class="col-md-12 shot-options">
            <mat-grid-list cols="3" rowHeight="40">
              <mat-grid-tile *ngFor="let name of shotType.options" class='res-type'
                [style.background]="name===customName?'lightgrey':'white'">
                <span style="width: 90%" (click)="onCustomNameSelected(name)">
                  {{name}}
                </span>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
          <div class="row shot-image-top" style="margin: 0">
            <div class="shot-image-tab-row" style="display: flex; justify-content:space-between">
              <div *ngIf="panelNames != null" 
                [style]="panelNames.length > 1 ? 'border-color: #ccc; border-style: solid; border-width: 0px 0px 1px 0px; padding: 0px;' : 'border-width: 0px'" 
                class="inner_tab col-md-8"
              >
                <mat-tab-group 
                  *ngIf="panelNames.length > 1" [(selectedIndex)]="selectedPanel"
                  (selectedIndexChange)="onTabSwitch()" (keyup)="onKeySwitchTab($event)">
                  <mat-tab *ngFor="let name of panelNames" [label]="getTabName(name)"></mat-tab>
                </mat-tab-group>
              </div>
              <div class="image-height-trigger">
                <a [matMenuTriggerFor]="graphMenu"><i class="zmdi zmdi-hc-2x zmdi-more-vert"
                    style="width: 40px;text-align:center; margin-right: 7%;"></i></a>
                <mat-menu class="matMenu" #graphMenu="matMenu">
                  <div style="text-overflow:clip;" class="tooltip-container slider-container">
                    Height
                    <!-- <mat-slider [min]="200" [max]="800" step="40" [(ngModel)]="shotGraphHeight" ngDefaultControl><input matSliderThumb /></mat-slider> -->
                    <mat-slider [min]="200" [max]="1000" step="50" (click)="$event.stopPropagation()" #ngSlider><input
                        matSliderThumb [(ngModel)]="mainGraphHeight" /></mat-slider>
                    <div class="tooltip">{{mainGraphHeight}}</div>
                  </div>
                  <div class="slider-container" style="text-overflow:clip;">
                    Clipping
                    <mat-slider [disabled]="!velocityAuto" *ngIf="!isPhaseDiff" [min]="-2" [max]="0" step="0.2"
                      (click)="$event.stopPropagation()" #ngSlider>
                      <input matSliderThumb (change)="setClipping()" [(ngModel)]="currentClipping"
                        #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <mat-slider *ngIf="isPhaseDiff" [min]="0" [max]="phasediff_clipValue" step="0.01"
                      (click)="$event.stopPropagation()" #ngSlider>
                      <input matSliderThumb (change)="setPhaseClipping()" #ngSliderThumb="matSliderThumb"
                        [(ngModel)]="currentPhaseClipping" />
                    </mat-slider>
                  </div>
                  <mat-slide-toggle [disabled]="!velocityAuto" style='margin-left: 5%; width: 95%;'
                    [(ngModel)]='globalClipping' (change)='updateMainImage()'>
                    Global Clipping</mat-slide-toggle>
                  <mat-slide-toggle *ngIf="rcvrDistance != null && !this.isSpectrum"
                    style="margin-left: 5%; width: 95%;" [(ngModel)]="mute"
                    (change)="toggleMute()">Mute</mat-slide-toggle>
                  <mat-slide-toggle *ngIf="!this.isSpectrum" style="margin-left: 5%; width: 95%;"
                    [(ngModel)]="enable_corr" (change)="toggleCorr()">Correlation</mat-slide-toggle>
                  <button mat-menu-item [matMenuTriggerFor]="shotTypeDetailMenu"
                    *ngIf="shotType != null && shotType.fwd">
                    Shot Type Detail
                  </button>
                  <nz-input-group class="legacy-input-styling" *ngIf="isSpectrum" (click)="$event.stopPropagation()">
                    <label> Y-Axis Min </label>
                    <nz-input-number style="width: 100%;" nzPrecision="2" [(ngModel)]="spectrumRange[0]" (keyup)="submitSpectrumRange($event)"></nz-input-number>
                  </nz-input-group>
                  <nz-input-group class="legacy-input-styling" *ngIf="isSpectrum" (click)="$event.stopPropagation()">
                    <label> Y-Axis Max </label>
                    <nz-input-number nzPrecision="2" style="width: 100%;" [(ngModel)]="spectrumRange[1]" (keyup)="submitSpectrumRange($event)"></nz-input-number>
                  </nz-input-group>
                  <nz-input-group class="legacy-input-styling" *ngIf="isSpectrum" (click)="$event.stopPropagation()">
                    <label> X-Axis Min </label>
                    <nz-input-number nzPrecision="2" style="width: 100%;" [(ngModel)]="spectrumRangeX[0]" (keyup)="submitSpectrumRange($event)"></nz-input-number>
                  </nz-input-group>
                  <nz-input-group class="legacy-input-styling" *ngIf="isSpectrum" (click)="$event.stopPropagation()">
                    <label> X-Axis Max </label>
                    <nz-input-number nzPrecision="2" style="width: 100%;" [(ngModel)]="spectrumRangeX[1]" (keyup)="submitSpectrumRange($event)"></nz-input-number>
                  </nz-input-group>
                  <nz-input-group class="legacy-input-styling"
                    *ngIf="shotType.name == 'RcvrList' && shotType.filterSettings.value == 'corr'"
                    (click)="$event.stopPropagation()">
                    <label> Shot Colour Min </label>
                    <nz-input-number nzPrecision="2" style="width: 100%;" [(ngModel)]="inputRcvrScale.min" (keyup)="submitCorrRange($event)"></nz-input-number>
                  </nz-input-group>
                  <nz-input-group class="legacy-input-styling"
                    *ngIf="shotType.name == 'RcvrList' && shotType.filterSettings.value == 'corr'"
                    (click)="$event.stopPropagation()">
                    <label> Shot Colour Max </label>
                    <nz-input-number nzPrecision="2" style="width: 100%;" [(ngModel)]="inputRcvrScale.max" (keyup)="submitCorrRange($event)"></nz-input-number>
                  </nz-input-group>
                  <button mat-menu-item [matMenuTriggerFor]="spectrumMenu" class="spectrum-select" *ngIf="isSpectrum">
                    Data
                  </button>
                  <div mat-menu-item (click)="screenshotPage()" [matMenuTriggerFor]="screenshotOptions" >Screenshot</div>
                  <!-- <button mat-menu-item (click)="downloadShotAsPng()">
                    Screenshot
                  </button> -->
                  <mat-menu #spectrumMenu="matMenu">
                    <ng-container *ngFor="let iter of spectrumIterOptions; let i = index">
                      <button mat-menu-item (click)="$event.stopPropagation(); toggleSpectrumIter(i)">
                        <span> {{iter}} </span>
                        <i *ngIf="spectrumOn != null && spectrumOn[iter] != null && spectrumOn[iter][0] != null && spectrumOn[iter][0]"
                          class="zmdi zmdi-check" style="display: inline-block"></i>
                      </button>
                    </ng-container>
                  </mat-menu>
                  <!-- <mat-menu #spectrumIterMenu="matMenu">
                    <ng-template matMenuContent let-index="index">
                      <button mat-menu-item (click)="$event.stopPropagation(); spectrumOn[index][0] = !spectrumOn[index][0]">
                        <span>Predicted</span>
                        <i *ngIf="spectrumOn != null && spectrumOn[index] != null && spectrumOn[index][0] != null && spectrumOn[index][0]" class="zmdi zmdi-check" style="display: inline-block"></i>
                      </button>
                      <button mat-menu-item (click)="$event.stopPropagation(); spectrumOn[index][1] = !spectrumOn[index][1]">
                        <span>Field</span>
                        <i *ngIf="spectrumOn != null && spectrumOn[index] != null && spectrumOn[index][1] != null && spectrumOn[index][1]" class="zmdi zmdi-check" style="display: inline-block"></i>
                      </button>
                    </ng-template>
                  </mat-menu> -->
                </mat-menu>
                <mat-menu #screenshotOptions="matMenu">
                  <div mat-menu-item (click)="$event.stopPropagation()">
                    <mat-slide-toggle [(ngModel)]="screenshotWithShotsInfo" >
                      With shots information
                    </mat-slide-toggle>
                  </div>
                </mat-menu>
                <!-- <mat-menu #shotTypeMenu="matMenu" class="shotTypeTable" xPosition="after"> -->
                <!-- <table> -->
                <!-- <ng-container *ngFor="let column of tableTypes"> -->
                <!-- <ng-container *ngFor="let fileType of column"> -->
                <!-- <button  -->
                <!-- style="padding: 0px; font-size: 7px; text-align: center;" -->
                <!-- mat-menu-item -->
                <!-- (click)="onShotTypeChange(fileType, $event)"> -->
                <!-- {{fileType.displayName}} -->
                <!-- <i *ngIf="fileType===shotType" class="zmdi zmdi-check" style="margin-left:2px;"></i> -->
                <!-- </button> -->
                <!-- </ng-container> -->
                <!-- </ng-container> -->
                <!-- </table> -->
                <!-- </mat-menu> -->

                <mat-menu #shotTypeMenu="matMenu" class="long-menu">
                  <!-- <table> -->

                  <ng-container *ngFor="let column of tableTypes">
                    <ng-container *ngFor="let fileType of column">
                      <button mat-menu-item (click)="onShotTypeChange(fileType, $event)">
                        {{ fileType.displayName }}
                        <i *ngIf="fileType === shotType" class="zmdi zmdi-check" style="margin-left: 2px"></i>
                      </button>
                    </ng-container>
                  </ng-container>
                  <!-- </table> -->
                </mat-menu>
                <mat-menu #shotTypeDetailMenu="matMenu">
                  <button mat-menu-item *ngFor="let typeDetail of typeDetails"
                    (click)="onShotTypeDetailChange(typeDetail.value, $event)">
                    <span>{{typeDetail.name}}</span>
                    <i *ngIf="typeDetail.value===shotType.fwd" class="zmdi zmdi-check" style="margin-left:2px;"></i>
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="iter-switch row">
              <div style="margin-left: 7%" class="function-buttons">
                <button mat-stroked-button color="primary" [matMenuTriggerFor]="shotMenu">
                  <span>Shot: {{selectedShot}}</span>
                  <i class="zmdi zmdi-caret-down" style="display: inline-block"></i>
                </button>
                <mat-menu #shotMenu="matMenu">
                  <button mat-menu-item *ngFor="let shot of getShotsOfSameIteration()"
                    (click)="onShotSelected(shot.shotId)"> {{shot.shotId}} </button>
                </mat-menu>
                <button mat-stroked-button color="primary" *ngIf="!isSpectrum" [matMenuTriggerFor]="iterMenu">
                  <span>Iteration: {{iteration}}</span>
                  <i class="zmdi zmdi-caret-down" style="display: inline-block"></i>
                </button>
                <button mat-stroked-button color="primary" *ngIf="isSpectrum" [matMenuTriggerFor]="spectrumIterMenu">
                  <span>Iteration: {{iteration}}</span>
                  <i class="zmdi zmdi-caret-down" style="display: inline-block"></i>
                </button>
                <button mat-stroked-button color="primary" style="text-overflow:clip; cursor: pointer;"
                  (click)='setScaling()'>
                  Scaling
                </button>
                <!-- <button mat-stroked-button *ngIf="panelNames[selectedPanel] == 'Phase Difference'" color="primary" style="text-overflow:clip; cursor: pointer;" (click)='setPhaseScaling()'>
                  Scaling
                </button> -->
                <!-- <mat-slide-toggle mat-stroked-button color="accent" style="margin: 10px;" [(ngModel)]='globalScaling' (change)="setGlobalScaling()">
                  Global Scaling
                </mat-slide-toggle> -->
                <!-- <mat-form-field color="primary" style="margin-left: 5px; margin-right: 5px;" *ngIf="panelNames[selectedPanel] == 'Phase Difference'">
                  <mat-label>Scale Factor Power</mat-label>
                  <input matInput type="number" step="0.1" [(ngModel)]="phasediff_scale_factor_power" (keyup)="onKeySubmission($event)">
                </mat-form-field> -->
                <button mat-stroked-button color="primary" [matMenuTriggerFor]="shotTypeMenu">
                  <span>Shot Type: {{shotType.displayName}}</span>
                  <i class="zmdi zmdi-caret-down" style="display: inline-block"></i>
                </button>
                <mat-menu #iterMenu="matMenu">
                  <button mat-menu-item *ngFor="let iter of itersOfSameShot" (click)="onIterationSwitch(iter)"> {{iter}}
                  </button>
                </mat-menu>
                <mat-menu #spectrumIterMenu="matMenu">
                  <button mat-menu-item *ngFor="let iter of spectrumIterOptions" (click)="onIterationSwitch(iter)">
                    {{iter}}
                  </button>
                </mat-menu>
                <button *ngIf="switchIteration" mat-stroked-button color="primary" (click)="onIterationSwitch()">
                  <span>Back to iteration {{switchIteration}}</span>
                </button>
                <div class="color-div" style="margin-left: 10px; margin-top: 10px;">
                  <div *ngIf="!isPhaseDiff && !isSpectrum">
                    <div *ngIf="shotType.name == 'RcvrList' && !!rcvrScale && typeOfSelectedRcvr.name == 'RcvrList'"
                      class="colorbar">
                      <span>{{shotType.displayName}}</span>
                      <div *ngIf="shotType.filterSettings.value != 'corr'" class="color-bar-bwr"></div>
                      <div *ngIf="shotType.filterSettings.value == 'corr'" class="color-bar-gray"></div>
                      <div class="color-bar-stat" style="display: flex; justify-content: space-between;">
                        <span>{{rcvrScale.min.toExponential(3)}}</span>
                        <span style="float: right;">{{rcvrScale.max.toExponential(3)}}</span>
                      </div>
                    </div>
                    <div *ngIf="(shotType.name != 'RcvrList' || typeOfSelectedRcvr.name != 'RcvrList') && !!clipValue"
                      class="colorbar">
                      <span>{{shotType.displayName}}</span>
                      <div class="color-bar-bwr"></div>
                      <div class="color-bar-stat" style="display: flex; justify-content: space-between;">
                        <span *ngIf="clipValue[0] != null">{{clipValue[0].toExponential(3)}}</span>
                        <span *ngIf="clipValue[1] != null"
                          style="float: right;">{{clipValue[1].toExponential(3)}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="phasediff-colorbar" *ngIf="isPhaseDiff">
                    <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
                      <img src="../../../../assets/colorbar/phasediff_colorbar.png" style="width: 100%; height: 20px;">
                    </div>
                    <div style="display: flex; justify-content: space-between; width: 100%;">
                      <span>-pi</span>
                      <span>pi</span>
                    </div>
                  </div>
                  <!-- this one is literally just to add spacing and make it so all the buttons and images stay on the same level -->
                  <div *ngIf="isSpectrum" style="height: 64px;"></div> 
                </div>
              </div>
            </div>
          </div>
          <div class="image-div" (keyup)="onKeySwitchGraph($event)" tabindex="0">
            <div *ngIf="loadingImage" class="loading">
              <mat-progress-spinner mode="indeterminate" class="spinner" diameter="30"></mat-progress-spinner>
              <span class="loading-text">loading image</span>
            </div>
            <div class="row" *ngIf="imageUrl || rcvrData">
              <div *ngIf="shotType.name=='RcvrList' && typeOfSelectedRcvr.name == 'RcvrList'">
                <div style="width: 90%; margin-left: auto; margin-right: auto;">
                  <app-shots-graph (onShotSelected)="onRcvrSelected($event)" [height]="mainGraphHeight"
                    [shotData]="rcvrData" [backgroundImage]="shotBgImg" [modelScale]="getModelScale(true)"
                    [selectedShot]="null" [colourType]="selectedShotColourType" [colorScale]='rcvrScale'
                    [colorMap]="rcvrColorMap" [isMainImage]="true"></app-shots-graph>
                </div>
              </div>
              <div *ngIf="shotType.name!='RcvrList' || typeOfSelectedRcvr.name !='RcvrList'">
                <div class="image-switch-wrapper col-md-1">
                  <div *ngIf="hasLeftPage && imageUrl"
                    [ngStyle]="{'height': mainGraphHeight*0.9+'px', 'float': 'right'}" class="image-switch"
                    (click)="onSliceChange(false)">
                    <div class="image-switch-middle">
                      <i style="margin-right: 10%; float: right" class="zmdi zmdi-hc-2x zmdi-caret-left"></i>
                    </div>
                  </div>
                </div>
                <app-d3-image id="shot-image" *ngIf="imageUrl" class="image col-md-10 col-12"
                  [ngClass]="{'left-shadow':hasLeftPage && !is_mobile,'right-shadow':hasRightPage && !is_mobile,'shadow-both':hasLeftPage&&hasRightPage && !is_mobile}"
                  [height]="mainGraphHeight" [hideAxes]="shotType && shotType.hideAxes"
                  [image]="{backgroundImage: imageUrl, totalTime: totalTime, range: {startTrace: startTrace, endTrace: endTrace},
                  rcvrDistances: rcvrDistances,distanceTypes:muteDistanceTypes , interleaveInfo: {isInter: isInterleaveType, extent: interleave_extent}, 
                  slope: muteSlope, intersection: muteIntersection, muteOn: muteOn, colors: colors, offsets:muteOffset, mute: mute,
                  corr_start_time : corr_start_time, corr_end_time : corr_end_time, axis_start_time: start_time, axis_end_time: end_time, enable_window:enable_corr,
                  panel_name: panelNames[selectedPanel], spectrum: spectrum, spectrumOn: spectrumOn, spectrumColors: spectrumColors, spectrumRange: spectrumRange, spectrumRangeX: spectrumRangeX, spectrumIterOptions: spectrumIterOptions}"
                  (time_window_emitter)="onTimeWindowEmit($event)" (width_emitter)="onWidthEmit($event)"
                  (spectrum_range_emitter)="onSpectrumRangeEmit($event)"
                  (spectrum_range_x_emitter)="onSpectrumRangeXEmit($event)">
                </app-d3-image>
                <div *ngIf="imageUrl == null && this.loadingImage == false && !this.isSpectrum" class="image col-md-10"
                  style="display:flex; align-items: center; height: 500px; justify-content: center;">
                  <div style="text-align: center;">DUMPS NOT AVAILABLE</div>
                </div>
                <div class="col-md-1 image-switch-wrapper">
                  <div *ngIf="hasRightPage && imageUrl" [ngStyle]="{'height': mainGraphHeight*0.9+'px'}"
                    class="image-switch" (click)="onSliceChange(true)">
                    <div class="image-switch-middle">
                      <i style="margin-left: 10%;" class="zmdi zmdi-hc-2x zmdi-caret-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="margin: 10px 0px 0px 0px">
              <div *ngIf="shotType && shotType.name != 'custom_type'&& shotType.options && shotType.options.length > 0"
                class="col-md-12 shot-options" style="padding: 0px;">
                <div style="display: flex;flex-wrap: wrap; gap: 10px;">
                  <div *ngFor="let option of shotType.options" style="flex: 1 1 150px; margin: 0px;" class="shot-option">
                    <ng-container *ngIf="option.values">
                      <div>
                        <label style="margin: 0;">{{option.displayName}}</label>
                        <nz-radio-group [(ngModel)]="shotType.filterSettings[option.name]">
                          <label nz-radio *ngFor="let value of option.values" class="radio-button" [nzValue]="value"
                          [checked]='shotType.filterSettings[option.name]==value'>{{value}}</label>
                        </nz-radio-group>
                      </div>
                      </ng-container>
                    <nz-input-group *ngIf="!option.values" class="legacy-input-styling" style="flex-grow: 1; margin: 0px; padding: 0px;">
                      <label style="left: 10px;">{{option.displayName}}</label>
                      <input nz-input [type]="option.type || 'number'" [placeholder]="option.displayName"
                      [(ngModel)]="shotType.filterSettings[option.name]"/>
                    </nz-input-group>
                  </div>
                </div>
                <section style="display: flex; justify-content: flex-end;">
                  <button mat-stroked-button color="primary" [disabled]="!selectedShot" class="shot-option"
                     (click)="onReplotClicked()">Replot</button>
                  <button mat-stroked-button color="primary" [matMenuTriggerFor]="subTypeMenu">
                    <span>Type: {{typeOfSelectedRcvr.displayName}}</span>
                    <i class="zmdi zmdi-caret-down" style="display: inline-block"></i>
                  </button>
                  <mat-menu #subTypeMenu="matMenu">
                    <button mat-menu-item *ngFor="let type of types" (click)="changeRcvrPlotType(type)">
                      {{type.displayName}}
                      <i *ngIf="type == typeOfSelectedRcvr" class="zmdi zmdi-check" style="margin-left:2px;"></i>
                    </button>
                  </mat-menu>
                </section>
              </div>
              <div *ngIf="shotType && shotType.name=='custom'" class="col-md-12 shot-options">
                <div class="shot-option">
                  <mat-form-field>
                    <input matInput placeholder="Filter Name" [(ngModel)]="customShotName">
                  </mat-form-field>
                </div>
                <div class="shot-option">
                  <mat-form-field>
                    <input matInput placeholder="Settings" [(ngModel)]="customShotSettings">
                  </mat-form-field>
                </div>
                <button mat-stroked-button color="primary" [disabled]="!selectedShot" class="shot-option"
                  style="width:60px" (click)="onReplotClicked()">Replot</button>
              </div>
            </div>
            <div class="error-message" *ngIf="!imageUrl && !loadingImage && !rcvrData && !is_updating_graph">
              <h3 *ngIf="!shotImageError">
                No trace file for this iteration
              </h3>
              <h3 *ngIf="shotImageError">
                {{shotImageError}}
              </h3>
            </div>
          </div>
          <div style="display: flex; width: 100%;" class="row"
            *ngIf="corr!=null && enable_corr && corr.length > 0 && shotType.name != 'RcvrList'">
            <div class="image-switch-wrapper" style="width:8.34%; padding: 0 15px;"></div>
            <div class="corr-bar" [ngStyle]="gradientStyle" [style.width]="shotImageWidth.toString() + 'px'"
              [style.margin-left]="this.is_mobile ? '30px' : '32px'"
              [style.margin-right]="this.is_mobile? '10px' : '12px'"></div>
            <div class="image-switch-wrapper" style="width:8.34%; padding: 0 15px;"></div>
          </div>
          <div id="shot-image-bottom" class="shot-image-bottom" style="margin: 0;">
            <!-- <div *ngIf="imageUrl && panelNames[selectedPanel] != 'Phase Difference'">  
              <div class="color-div">
                <div *ngIf="shotType.name == 'RcvrList' && !!rcvrScale && typeOfSelectedRcvr.name == 'RcvrList'"
                  id="colorbar">
                  <div *ngIf="shotType.filterSettings.value != 'corr'" class="color-bar-bwr"></div>
                  <div *ngIf="shotType.filterSettings.value == 'corr'" class="color-bar-gray"></div>
                  <div class="color-bar-stat" style="display: flex; justify-content: space-between;">
                    <span>{{rcvrScale.min.toExponential(3)}}</span>
                    <span>{{shotType.displayName}}</span>
                    <span style="float: right;">{{rcvrScale.max.toExponential(3)}}</span>
                  </div>
                </div>
                <div *ngIf="(shotType.name != 'RcvrList' || typeOfSelectedRcvr.name != 'RcvrList') && !!clipValue"
                  id="colorbar">
                  <div class="color-bar-bwr"></div>
                  <div class="color-bar-stat" style="display: flex; justify-content: space-between;">
                    <span>{{clipValue[0].toExponential(3)}}</span>
                    <span>{{shotType.displayName}}</span>
                    <span style="float: right;">{{clipValue[1].toExponential(3)}}</span>
                  </div>
                </div> -->
            <!-- <div class="color-bar-text">{{shotType.displayName}}</div>
              </div>
            </div> -->
            <div class="corr-color-div"
              *ngIf="!is_mobile && enable_corr && corr.length > 0 && shotType.name != 'RcvrList'"
              [ngStyle]="corrColorStyle" style="margin-left: 15px; margin-right: 15px;">
              <div style="display: flex; justify-content: space-between; margin-top: 10px;">
                <span style="margin-top:10px;">-1</span>
                <span style="margin-top:10px;">Correlation</span>
                <span style="margin-top:10px;">1</span>
              </div>
              <div style="display: flex; justify-content: space-between;">
                <span>Mean: {{corr_mean.toPrecision(3)}}</span>
                <span>Std: {{corr_std.toPrecision(3)}}</span>
              </div>
            </div>
            <nz-input-group class="legacy-input-styling interleave col-md-4" style="width: 200px; padding-left: 10px;" *ngIf="isInterleaveType">
              <label>Interleave extent</label>
              <input nz-input type="number" placeholder="Interleave extent" [(ngModel)]="interleave_extent"
                (keyup)="onKeySubmission($event)">
            </nz-input-group>
            <!-- <div class="phasediff-colorbar" *ngIf="panelNames[selectedPanel] == 'Phase Difference'">
              <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
                <img src="../../../../assets/colorbar/phasediff_colorbar.png" style="width: 100%;"> 
              </div>
              <div style="display: flex; justify-content: space-between; width: 100%;">
                <span>-pi</span>            
                <span>pi</span>            
              </div>
            </div> -->
            <div *ngIf="shotType.name == 'RcvrList'" class="col-md-5 row">
              <div class="col-md-4" style=' margin-top: 2%'> <span>Value: {{rcvrValue.toExponential(2)}}</span> </div>
              <div class="col-md-4" style='margin-top: 2%'> <span>Mean: {{rcvrAvg.toExponential(2)}}</span> </div>
              <div class="col-md-4" style='margin-top: 2%'> <span>Std: {{rcvrStd.toExponential(2)}}</span> </div>
            </div>
            <div class="download">
              <div *ngIf="imageUrl != null && !isSpectrum">
                <a class="shot-height-trigger" [matMenuTriggerFor]="pngMenu"><i class="zmdi zmdi-download"
                    style="font-size: large;"></i></a>
              </div>
              <mat-menu #pngMenu="matMenu">
                <nz-input-group class="legacy-input-styling" style="width: 200px;">
                  <label>Specify PNG Width:</label>
                  <input nz-input type="number" [(ngModel)]="pngWidth" (click)='$event.stopPropagation()'>
                </nz-input-group>
                <nz-input-group class="legacy-input-styling" style="width: 200px;">
                  <label>Specify PNG Height:</label>
                  <input nz-input type="number" [(ngModel)]="pngHeight" (click)='$event.stopPropagation()'>
                </nz-input-group>
                <button nz-button nzType="primary" (click)="downloadPng()" style="margin-left: 10px;">
                  <span nz-icon nzType="download"></span>
                  Download
                </button>
              </mat-menu>
            </div>
          </div>
          <div *ngIf="is_mobile && enable_corr && corr.length > 0 && shotType.name != 'RcvrList'"
            style="display: flex; margin-bottom: 15px;">
            <div class="corr-color-div" [ngStyle]="corrColorStyle" style="margin-right: 10%; margin-left: 7%;">
              <div style="display: flex; justify-content: space-between; margin-top: 10px;">
                <span style="margin-top:20px;">-1</span>
                <span style="margin-top:20px;">Correlation</span>
                <span style="margin-top:20px;">1</span>
              </div>
            </div>
            <div style="justify-content: space-between;">
              <span>Mean: {{corr_mean.toPrecision(3)}}</span>
              <span>Std: {{corr_std.toPrecision(3)}}</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="mute" class="row mute-controls">
        <div *ngFor="let name of muteName; let i = index" class="mute-control">
          <div style="display: flex; flex-direction: column;">
            <div style="display: inline;">
              <div style="display: inline-block;">{{name}}</div>
              <div style="display: inline-block;" class="circle" [style.background-color]="colors[i]"></div>
            </div>
            <mat-form-field style="margin-top: 5px;">
              <mat-label>Growth</mat-label>
              <input matInput type="number" [(ngModel)]="muteSlope[i]" (change)="updateRef()">
            </mat-form-field>
            <mat-form-field>
              <mat-label *ngIf="name != 'rwi mute'">Start Time</mat-label>
              <mat-label *ngIf="name == 'rwi mute'">Offset Cut</mat-label>
              <input matInput type="number" [(ngModel)]="muteIntersection[i]" (change)="updateRef()">
            </mat-form-field>
            <mat-form-field>
              <mat-label>At Offset</mat-label>
              <input matInput type="number" [(ngModel)]="muteOffset[i]" (change)="updateRef()">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Distance Type</mat-label>
              <mat-select [(ngModel)]="muteDistanceTypes[i]" (ngModelChange)="muteDistanceTypeChanged()">
                <mat-option *ngFor="let type of distanceTypes" [(value)]="type.value">{{type.name}}</mat-option>
                <!-- <mat-option value="0">3D</mat-option>
                <mat-option value="1">2D-nx1,nx2</mat-option>
                <mat-option value="2">2D-nx1,nx3</mat-option>
                <mat-option value="3">2D-nx2,nx3</mat-option> -->
              </mat-select>
            </mat-form-field>
            <div style="display: inline;">
              <mat-slide-toggle style="display: inline-block;" [(ngModel)]="muteOn[i]"
                (change)="updateRef()"></mat-slide-toggle>
              <mat-label style="display: inline-block; margin-left: 5%;">Show</mat-label>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>

      <div class="contaner-fluid data-block" style="margin: auto; padding: 15px;">
        <div style="display: flex; gap: 10px;">
          <nz-input-group class="legacy-input-styling" style="padding: 0px;">
            <label style="left: 10px">Block Number</label>
            <nz-select [(ngModel)]="selectedBlk" style="width: 200px;">
              <nz-option *ngFor="let blk of blk_info; let number = index" [nzValue]="blk" [nzLabel]="number+1">
              </nz-option>
            </nz-select>
          </nz-input-group>
          <div>
            <b style="width: 150px; display: block;">Frequency:</b>
            <span *ngIf="selectedBlk">{{getParamValue("freq")}}</span>
          </div>
          <div>
            <b style="width: 150px; display: block;">Amplitude:</b>
            <span *ngIf="selectedBlk">{{getParamValue("amplitude")}}</span>
          </div>
          <div>
            <b style="width: 150px; display: block;">AGC:</b>
            <span *ngIf="selectedBlk">{{getParamValue("agc")}}</span>
          </div>
          <div>
            <b style="width: 150px; display: block;">Normalize:</b>
            <span *ngIf="selectedBlk">{{getParamValue("normalize")}}</span>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-4">
            <div class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-7">Frequency:</label>
                <span class="col-sm-4" *ngIf="selectedBlk">{{getParamValue("freq")}}</span>
              </div>
              <div class="form-group">
                <label class="col-sm-7">Amplitude:</label>
                <span class="col-sm-4" *ngIf="selectedBlk">{{getParamValue("amplitude")}}</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="col-sm-7">AGC:</label>
              <span class="col-sm-4" *ngIf="selectedBlk">{{getParamValue("agc")}}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-horizontal">
              <div class="form-group">
                <label class="col-sm-7">Normalize:</label>
                <span class="col-sm-4" *ngIf="selectedBlk">{{getParamValue("normalize")}}</span>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </mat-card>
  </div>

  <div class="sliders-bar" [ngClass]="{'open': sliderBarOpen, 'visible': sliderBarVisible}">
    <div style="display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    ">
      <h4 class="card-title" style="margin: 0.5em 10px;">Settings</h4>
      <div class="actions" style="margin: 0px;" (click)="sliderBarOpen = !sliderBarOpen"><i class="fa fa-arrow-up"></i></div>
    </div>
    <div class="col-md-6" style="padding:0;">
      <div class="form-group">
        <!-- <label class="col-sm-2">Depth</label> -->
        <app-slider [antd]="true" label="Depth" class="col-sm-10" [min]="1" [max]="nx3" [step]="1" [(value)]="sliceNum"
          (valueChange)="onDepthChange()"></app-slider>
      </div>
    </div>
    <div class="col-md-6" style="padding:0;">
      <div class="form-group iteration-slider">
        <!-- <label class="col-sm-2">Iteration</label> -->
        <app-slider [antd]="true" label="Iteration" class="col-sm-10" [min]="1" [max]="totalIteration"
          [(value)]="iteration" [step]="1" (valueChange)="onIterationChange()"></app-slider>
      </div>
    </div>
    <div class="col-md-12" style="padding-right: 20px;">
      <mat-checkbox style="float:right;" [(ngModel)]="synchroniseSettings"
        (ngModelChange)="onSynchroniseChange($event)">Synchronise Settings</mat-checkbox>
    </div>
  </div>
  <span class="foot-note"> * : parameter not specified in runfile </span>

</div>