<div>
  <mat-dialog-content>
    <nz-input-group
      style="width: 100%; max-width: 800px; padding: 0px"
      class="legacy-input-styling"
    >
      <label style="left: 10px">Select Job</label>
      <nz-select
        style="width: 100%"
        nzShowSearch
        nzPlaceHolder="Select Job"
        [(ngModel)]="this.selectedJob"
      >
        <nz-option
          *ngFor="let job of this.jobs"
          [nzLabel]="job.job_name"
          [nzValue]="job"
        ></nz-option>
      </nz-select>
    </nz-input-group>

    <!-- <mat-form-field style="width: 100%">
      <mat-label>Select Job</mat-label>
      <mat-select placeholder="Select job" [(ngModel)]="this.selectedJob">
        <div
          style="
            padding: 5px 10px 0px;
            position: sticky;
            top: 0px;
            background-color: white;
            z-index: 2;
          "
        >
          <mat-form-field style="width: 100%">
            <mat-label>Search</mat-label>
            <input
              [(ngModel)]="this.jobSearchString"
              (keyup)="this.filterJobs($event)"
              matInput
              type="Search"
            />
          </mat-form-field>
        </div>

        <mat-option *ngFor="let job of this.jobs" [value]="job">
          {{ job.job_name }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <nz-input-group
      style="width: 100%; max-width: 800px; padding: 0px"
      class="legacy-input-styling"
    >
      <label style="left: 10px">Maximum Number of Different Parameters</label>
      <input
        nz-input
        placeholder="Maximum Number of Different Parameters"
        type="number"
        [(ngModel)]="differenceParameter"
        [disabled]="
          !this.selectedJob || getObjectKeys(this.selectedJob).length == 0
        "
      />
    </nz-input-group>

    <!-- <mat-form-field style="width: 100%">
      <input
        matInput
        placeholder="Maximum Number of Different Parameters"
        type="number"
        [(ngModel)]="differenceParameter"
        [disabled]="
          !this.selectedJob || getObjectKeys(this.selectedJob).length == 0
        "
      />
    </mat-form-field> -->

    <nz-input-group
      style="width: 100%; max-width: 800px; padding: 0"
      class="legacy-input-styling"
    >
      <label style="left: 10px">Select Fields to Exclude from Comparison</label>
      <nz-select
        [nzSize]="'large'"
        style="width: 100%"
        [formControl]="this.exclusionListControl"
        nzMode="multiple"
        nzShowSearch
        nzAllowClear
        [disabled]="getObjectKeys(this.selectedJob).length == 0"
      >
        <nz-option
          *ngFor="let val of this.flattenedOptions"
          [nzValue]="val"
          [nzLabel]="val"
        >
        </nz-option>
      </nz-select>
    </nz-input-group>

    <!-- <mat-form-field style="width: 100%">
      <mat-label>Select Fields to Exclude from Comparison</mat-label>
      <mat-select
        [formControl]="this.exclusionListControl"
        multiple
        [disabled]="getObjectKeys(this.selectedJob).length == 0"
      >
        <ng-container *ngIf="this.selectedJob">
          <div
            style="
              padding: 5px 10px 0px;
              position: sticky;
              top: 0px;
              background-color: white;
              z-index: 2;
            "
          >
            <mat-form-field style="width: 100%">
              <mat-label>Search</mat-label>
              <input (keyup)="this.filterCols($event)" matInput type="Search" />
            </mat-form-field>

            <button
              (click)="excludeAll()"
              mat-menu-item
              style="
                padding: 0px;
                font-size: small;
                line-height: 15px;
                height: 25px;
              "
            >
              Select All (Exclude all from comparison)
            </button>
          </div>

          <mat-option
            *ngFor="let val of this.flattenedOptions"
            [value]="val"
            [style.display]="
              this.flattenedOptionsShowing.includes(val) ? 'flex' : 'none'
            "
          >
            {{ val }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field> -->

    <div style="display: flex; justify-content: space-between; gap: 10px">
      <button
        (click)="showDiff()"
        nz-button
        [disabled]="
          !this.selectedJob || getObjectKeys(this.selectedJob).length == 0
        "
        nzType="primary"
      >
        Show Similar Jobs
      </button>

      <button (click)="excludeAll()" nz-button>
        Select All (Exclude all from comparison)
      </button>
    </div>
  </mat-dialog-content>
</div>
