import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { CachedUserService } from '../../../shared/services/cached-user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent implements OnInit, OnDestroy {
  isStaff = false;
  runfileAccess = false;
  subscription: Subscription = null;
  constructor(public authService: AuthService, private userService: CachedUserService) { }

  ngOnInit() {
    this.subscription = this.userService.userDetail.subscribe(d => {
      this.isStaff = d && (d.isStaff || d.email==="testuser01@s-cube.com");
      this.runfileAccess = this.isStaff || d && d.runfileAccess;
    });
  }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
