import { Component, OnInit, HostListener, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-picture-model',
  templateUrl: './picture-model.component.html',
  styleUrls: ['./picture-model.component.css']
})
export class PictureModelComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) form;
  @Input('data') set data (value: any) {
    if (value) {
      this._data = value;
      this.currentCaption = value.caption;
    }
  };
  @Output() onCaptionSaved: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  currentCaption: string = ''
  _data: any = null;

  constructor(public dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
  }

  updateCaption() {
    this.onCaptionSaved.emit(this.currentCaption);
  }

  @HostListener('document:keydown', ['$event.key'])
  onKeyPressed(key) {
    switch(key) {
      case 'ArrowLeft':
        this.onChange.emit(-1);
        break;
      case 'ArrowRight':
        this.onChange.emit(1);
        break;
    }
  }


  changeScreenshot(value) {
    this.onChange.emit(value);
  }


}
