<div class="row scale_input">
  <mat-form-field class="col-sm-4">
    <input
      matInput
      type="number"
      placeholder="Start Trace"
      [(ngModel)]="startTrace"
      (keyup)="onKeySubmission($event)"
    />
  </mat-form-field>
  <mat-form-field class="col-sm-4">
    <input
      matInput
      type="number"
      placeholder="End Trace"
      [(ngModel)]="endTrace"
      (keyup)="onKeySubmission($event)"
    />
  </mat-form-field>
  <div>
    <button mat-stroked-button color="primary" [disabled]="!selectedShot" class="col-md-4"
    (click)="onReplotClicked()">Replot</button>
  </div>
</div>
