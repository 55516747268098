import { Injectable } from '@angular/core';
import { RunfileParam } from '../../../models/runfileParamType';

@Injectable()
export class ParamValueMapService {

  private standardBlkParams = {
    "Iteration & data selection": [
      "Frequency", "Iterations"
    ],

    "Inversion parameters": [
      "Conjugated gradient", "Line search method",
      "Normalise", "Amplitude", "AGC", "Shot autoscaling", "Source scaling",
      "Outer mute start time", "Outer mute growth", "Outer mute taper width", "Rec line smooth dist",
      "Timetweak rate factor", "Timetweak max factor"
    ],

    "Functional-AWI": [
      "AWI stabilisation", "AWI scale", "AWI adjoint type", "AWI residual type", "AWI flavor",
    ],

    "RWI": [
       "RWI VS iterations", "VS high cut", "VS low cut", "RWI taper",
       "RWI offset cut", "RWI offset grow", "RWI BG iterations",
       "RWI high cut", "RWI low cut", "RWI cut factor", "RWI scale",
       "RWI smooth", "RWI smooth X1", "RWI smooth X2", "RWI smooth X3",
       "RWI smooth ramp", "RWI smooth cutcell",
       "RWI median", "RWI median X1", "RWI median X2", "RWI median X3",
       "RWI timetweak rate factor", "RWI timetweak max factor", "RWI type",
       "RWI shift factor", "RWI maximum source side damping", "RWI time slice maximum boost",
       "RWI rec line smooth dist", "RWI shot autoscaling",
       "RWI gradient parts", "RWI struct smooth", "RWI add incident", "RWI AGC",
       "RWI correlated AGC", "RWI spatial"
    ],

    "Gradient smoothing & windowing": [
      "Smooth X1", "Smooth X2", "Smooth X3", "Smooth cutcell", "Smooth ramp",
      "Final smooth X1", "Final smooth X2", "Final smooth X3",
      "Final smooth cutcell", "Final smooth ramp",
    ]

 }

  private standardGlobalParams = {
    "Problem definition": [
      "Equation", "Kernel", "Problem"
    ],

    "Model definition": [
      "Nx1", "Nx2", "Nx3", "Grid spacing"
    ],

    "Data definition": [
      "Number of point sources", "Number of shots per iteration",
    ],

    "Density model": [
      "Water velocity", "Gardener cutoff velocity", "Water density"
    ]
  }

  private valueMap = {
    "AWI adjoint type": {
      0: "default*",
      1: "guassian",
      2: "exponential",
      3: "linear",
      4: "cosinebell"
    },

    "AWI residual type": {
      0: "default*",
      1: "guassian",
      2: "exponential",
      3: "linear",
      4: "cosinebell"
    },

    "AWI flavor": {
      0: "default*",
      1: "reverse",
      2: "forward",
      3: "X-correlation",
      9: "FWI"
    }
  }

  private controlParams = {
    "Functional": "2NRM",
    "RWI VS iterations": 0
  }

  constructor() { }

  getGlobal(paramDict: RunfileParam) {
    let jobParams: ParamItem[] = this.dictToList(true, paramDict)
    .map(this.mapValue.bind(this));
    return this.makeHeirarchy(jobParams);
  }

  getBlk(paramDict: RunfileParam, globalParams: RunfileParam = {}) {
    this.setControlParams(paramDict, globalParams);
    let jobParams: ParamItem[] = this.dictToList(false, paramDict)
    .filter((param: ParamItem) => this.filterAWI(param, this.controlParams["Functional"]))
    .filter((param:ParamItem) => this.filterRWI(param, this.controlParams["RWI VS iterations"]))
    .map((param: ParamItem) => this.setGlobalDefault(param, globalParams))
    .map(this.mapValue.bind(this));
    return this.makeHeirarchy(jobParams);
  }

  private setControlParams(blkDict, globalDict) {
    for (let keyword in this.controlParams) {
      let keywordSpec = blkDict[keyword] || globalDict[keyword]
      if (keywordSpec) {
        this.controlParams[keyword] = keywordSpec.value;
      }
    }
  }

  private dictToList(isGlobal: boolean, valueDict): ParamItem[] {
    let streamResult: ParamItem[] = [];
    let standardParams = isGlobal?this.standardGlobalParams:this.standardBlkParams;
    for (let category in standardParams) {
      for (let name of standardParams[category]) {
        let value = valueDict[name] ? valueDict[name].value : "default*"
        streamResult.push({name: name, value: value, category: category});
      }
    }
    return streamResult;
  }

  private setGlobalDefault(param: ParamItem, globalDict) {
    if (globalDict[param.name] && param.value === "default*") {
      param.value = globalDict[param.name].value;
    }
    return param;
  }


  private filterAWI(param: ParamItem, functional: string) {
    return functional.toUpperCase().includes("AWI") || param.category != "Functional-AWI";
  }

  private filterRWI(param: ParamItem, rwiVsIter: number) {
    return rwiVsIter != 0 || param.category != "RWI"
  }

  private mapValue(param: ParamItem): ParamItem {
    let valueSpec = this.valueMap[param.name];
    if (valueSpec) {
      param.value = valueSpec[param.value] || param.value;
    }

    return param;
  }

  private makeHeirarchy(paramList: ParamItem[]) {
    let categorizedParams = this.initCategoryDict(paramList.map(p => p.category));
    for (let param of paramList) {
      categorizedParams[param.category][param.name] = param.value;
    }
    return categorizedParams
  }

  private initCategoryDict(categories: string[]) {
    let dict = {}
    for (let category of categories) {
      dict[category] = {};
    }
    return dict;
  }

}

interface ParamItem {
  name: string;
  value: any;
  category: string;
}
