import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation, HostListener, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime ,  take } from 'rxjs/operators';

export interface time_window{
  start_time: number;
  end_time: number;
}

import { position } from '../../model/vertical-profile/vertical-profile.component';

@Component({
  selector: 'app-d3-image',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './d3-image.component.html',
  styleUrls: ['./d3-image.component.css']
})
export class D3ImageComponent implements OnInit, OnChanges {
  @Input() hideAxes: boolean = false;

  @Input() set image(value: { backgroundImage: string, 
                              totalTime: number, 
                              range: { startTrace: number, endTrace: number }, 
                              interleaveInfo: { isInter: boolean, extent: number },
                              rcvrDistances: number[][],
                              distanceTypes: number[],
                              slope:number[],
                              intersection:number[],
                              muteOn: boolean[],
                              colors: string[],
                              offsets: number[],
                              mute: boolean,
                              corr_start_time,
                              corr_end_time,
                              axis_start_time,
                              axis_end_time,
                              enable_window,
                              panel_name,
                              spectrum: any, // object of iter: position[][]
                              spectrumOn: any, // object of iter: boolean[]
                              spectrumColors: any, // object of iter: string[]
                              spectrumRange: number[],
                              spectrumIterOptions: number[],
                              spectrumRangeX: number[],
                            }) {
    // The image should only be updated when the main image changes
    if (value.backgroundImage == this._backgroundImage) return;
    this._backgroundImage = value.backgroundImage;
    this._totalTime = value.totalTime;
    // this.end_time = this._totalTime;
    this.axis_start_time = Math.max(0, this.defaultIfNull(value.axis_start_time, 0)) 
    this.axis_end_time = Math.min(this._totalTime, this.defaultIfNull(value.axis_end_time, this._totalTime)) 
    this.corr_start_time = this.defaultIfNull(value.corr_start_time,this.axis_start_time);
    this.corr_end_time = this.defaultIfNull(value.corr_end_time, this.axis_end_time);
    this._range = value.range || { startTrace: 0, endTrace: 0 };
    this._rcvrDistances = value.rcvrDistances;
    this._isInterleave = value.interleaveInfo.isInter;
    this._interleave_extent = value.interleaveInfo.extent;
    this.slope = value.slope;
    this.intersection = value.intersection;
    this.muteOn = value.muteOn;
    this.colors = value.colors;
    this.offsets = value.offsets;
    this.mute = value.mute;
    this.enable_window = value.enable_window;
    this.panel_name = value.panel_name;
    this.spectrum = value.spectrum;
    this.spectrumColors = value.spectrumColors;
    this.spectrumOn = value.spectrumOn;
    this.spectrumRange = value.spectrumRange;
    this.spectrumIterOptions = value.spectrumIterOptions;
    this.spectrumRangeX = value.spectrumRangeX;
    setTimeout(() => {
      if (this.panel_name == "Spectrum") {
        if (this.checkSpectrumMisc())
          this.updateGraph(); // not sure if debouncesubject is initialized
      } else {
        this.updateGraph();
      }
    }, 0);
  }
  @Input() set height(value: number) {
    this._height = value;
    // this.updateGraph();
    setTimeout(() => {
      if (this.panel_name == "Spectrum") {
        if (this.checkSpectrumMisc())
          this.updateGraph(); // not sure if debouncesubject is initialized
      } else {
        this.updateGraph();
      }
    }, 0);
  }

  @Output() time_window_emitter = new EventEmitter<time_window>();
  @Output() width_emitter = new EventEmitter<number>();
  @Output() spectrum_range_emitter = new EventEmitter<number[]>();
  @Output() spectrum_range_x_emitter = new EventEmitter<number[]>();

  @ViewChild('graph', { static: true }) graph: ElementRef;
  @ViewChild('graphContainer', { static: true }) container: ElementRef;

  traceNumber: number;
  timeNumber: number;
  interleaveType: string;
  _isInterleave: boolean;
  _interleave_extent: number;
  zoomFactor:number = 1.00;
  
  private width: number;
  private _height: number = 300;
  private margin: { top: number, right: number, bottom: number, left: number }
  private _backgroundImage: string;
  private _totalTime: number;
  private _range = { startTrace: 0, endTrace: 0 };
  private transform;
  private svg;

  _rcvrDistances : number[][];
  data = [];
  pngWidth : number = 1000;
  pngHeight : number = 1000;
  slope: number[];
  intersection: number[];
  muteOn: boolean[];
  mute: boolean = false; // global
  colors = ['grey', '#00FF00', 'yellow' ];
  offsets: number[];
  distanceTypes:number[] = [1,1,1]
  corr_start_time: number;
  corr_end_time: number;
  mouse_downed = false;
  mouse_downed_time;
  axis_start_time;
  axis_end_time;
  enable_window = false;
  debounceSubject = new Subject<any>();
  white_background: string;
  panel_name;
  // spectrum is basically the same as mutes, except for no need for calculation
  spectrum: any; // object of iter: position[][]
  spectrumOn: any; // object of iter: boolean[]
  spectrumColors: any; // object of iter: string[]
  spectrumRange: number[];
  spectrumIterOptions: number[];
  spectrumRangeX: number[];
  // updating: Subject<boolean> = new Subject<boolean>();
  constructor() { }

  ngOnInit() {
    // const canvas = document.createElement('canvas');
    // const context = canvas.getContext('2d');
    // const width = 100; // Adjust as needed
    // const height = 100; // Adjust as needed
    // canvas.width = width;
    // canvas.height = height;
    // context.fillStyle = '#FFFFFF'; // White color
    // context.fillRect(0, 0, width, height);

    // // Convert the canvas to a base64 data URL
    // this.white_background = canvas.toDataURL('image/png');
    this.debounceSubject.pipe(debounceTime(500)).subscribe(() => {
      this.updateGraph()
    });
  }

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.resizeWithDelay(event);
  }

  private resizeWithDelay = debounce((event) => {
    this.updateGraph();
  }, 100);

  ngOnChanges(changes: SimpleChanges): void {
    if ('image' in changes){
      let image = changes.image.currentValue;
      this.muteOn = image.muteOn;
      this._rcvrDistances = image.rcvrDistances;
      this.distanceTypes = image.distanceTypes;
      // this._range = image.range;
      this.slope = image.slope;
      this.intersection = image.intersection;
      this.colors = image.colors;
      this.offsets = image.offsets;
      this.mute = image.mute;
      this.axis_start_time = this.defaultIfNull(image.axis_start_time, 0)
      this.axis_end_time = this.defaultIfNull(image.axis_end_time, this._totalTime)
      this.corr_start_time = this.defaultIfNull(image.corr_start_time, this.axis_start_time)
      this.corr_end_time = this.defaultIfNull(image.corr_end_time, this.axis_end_time)
      this.enable_window = this.defaultIfNull(image.enable_window, false);
      this.panel_name = image.panel_name;
      this.spectrum = image.spectrum;
      this.spectrumOn = image.spectrumOn;
      this.spectrumColors = image.spectrumColors;
      this.spectrumRange = image.spectrumRange;
      this.spectrumRangeX = image.spectrumRangeX;
      this.spectrumIterOptions = image.spectrumIterOptions;
    }
    if ('height' in changes) {
      this._height = changes.height.currentValue;
    }
    // setTimeout(() => this.updateGraph(), 0);
    if (this.panel_name == 'Spectrum') {
      if (this.checkSpectrumMisc())
        this.debounceSubject.next();
    } else if (this.panel_name != 'Spectrum') {
      this.debounceSubject.next();
    }
  }

  checkSpectrumMisc() {
    if (this.spectrum != null && this.spectrumOn != null && this.spectrumColors != null && this.spectrumIterOptions != null) {
      if (Object.values(this.spectrum).some((val) => val != null) && Object.values(this.spectrumOn).some((val) => val != null) && Object.values(this.spectrumColors).some((val) => val != null) && this.spectrumIterOptions.length != 0) {
        return true;
      }
    }
    return false; 
  }

  updateGraph() {
    this.data = [];
    if (this._rcvrDistances != null && this.mute) {
      for (let i = 0; i < this.slope.length; i ++) {
        let mute_data = []
        let actual_intersection = this.intersection[i] - this.offsets[i] * (1 / this.slope[i])
        if (this.muteOn[i]) {
          for (let j = this._range.startTrace; j <= this._range.endTrace; j ++) {
            mute_data.push({
              x: j, y: actual_intersection + this._rcvrDistances[this.distanceTypes[i]][j-1] * (1 / this.slope[i])
            })
          }
        }
        this.data.push(mute_data)
      }
    }
    // Remove previous image and axises
    // if (this.svg) {
    //   this.svg.remove();
    // }
    let svg;
    if (this.svg) {
      svg = this.svg;
    }
    // Get width and height based on current window
    this.width = this.container.nativeElement.offsetWidth;
    this.width_emitter.emit(this.width)
    this.margin = {
      top: this._height * 0.05,
      right: 10,
      bottom: 30,
      left: 30
    };
    // if (!this.svg) {
      this.draw().then(()=> {
        if (svg != null) {
          svg.remove()
        }
      });
  }

  private async draw() {
    let width = this.width - this.margin.left - this.margin.right;
    let height = this._height - this.margin.top - this.margin.bottom;

    // handler for zoom transformation
    let zoomed = (event) => {
      let transform = event.transform;
      let tx = event.transform.x;
      let ty = event.transform.y;
      let tk = event.transform.k;
      this.zoomFactor = tk.toPrecision(3);
      // Restrict the translation to prevent the image goes out of the bounds
      transform.x = Math.min(0, Math.max(tx, width * (1 - tk)))
      transform.y = Math.min(0, Math.max(ty, height * (1 - tk)))
      this.transform = transform;
  
      //Change the scales and the axises
      gX.call(xAxis.scale(event.transform.rescaleX(xScale)));
      gY.call(yAxis.scale(event.transform.rescaleY(yScale)));

      const newXScale = event.transform.rescaleX(xScale);
      const newYScale = event.transform.rescaleY(yScale);

      if (this.panel_name != "Spectrum") {
        image.attr("transform", transform);
        overlay_top.attr("height", Math.max(0, newYScale(dashed_line_data[0].y)))
        overlay_bottom.attr("transform", "translate(0," + newYScale(dashed_line_data2[0].y) + ")")
                      .attr("height", Math.max(0,newYScale(this.axis_end_time) -newYScale(dashed_line_data2[0].y)))
      }
      

      
      // Update the line and area paths with the new scales
      line.x(d => newXScale(d.x)).y(d => newYScale(d.y));

      // Redraw the line path
      svg.selectAll(".line")
        .attr("d", line);
      
      svg.selectAll(".dashed_line")
        .attr("d", line)

      svg.selectAll(".spectrum-line")
        .attr("d", line)
    }

    //Zoom behaviour for the image
    let zoom = d3.zoom()
      .filter(function (event) {
        return event.metaKey || event.ctrlKey;
      })
      .scaleExtent([1, 50])
      .on("zoom", zoomed);
    
    // Set scales for axis
    let xScale = d3.scaleLinear()
      // .domain([this._range.startTrace-1/2, this._range.endTrace + 1/2])
      .domain([this._range.startTrace, this._range.endTrace])
      .range([0, width]);    
    let yScale = d3.scaleLinear()
      .domain([this.axis_start_time, this.axis_end_time])
      .range([0, height]);

    if (this.panel_name == "Spectrum") {
      let min_spectrum_x, max_spectrum_x, min_spectrum_y, max_spectrum_y;
      let xs = [];
      let ys = [];
      for (let k = 0; k < this.spectrumIterOptions.length; k ++) {
        let iter = this.spectrumIterOptions[k];
        if (this.spectrum[iter] != null) {
          for (let i = 0; i < this.spectrum[iter][0].length; i ++) {
            for (let j = 0; j < this.spectrum[iter].length; j ++) {
              xs.push(this.spectrum[iter][j][i].x);
              ys.push(this.spectrum[iter][j][i].y);
            }
          }
        }
      }
      min_spectrum_x = Math.min(...xs);
      max_spectrum_x = Math.max(...xs);
      min_spectrum_y = Math.min(...ys);
      max_spectrum_y = Math.max(...ys)
      let user_spectrum_min = this.spectrumRange[0];
      let user_spectrum_max = this.spectrumRange[1];
      let user_spectrum_min_x = this.spectrumRangeX[0];
      let user_spectrum_max_x = this.spectrumRangeX[1];
      min_spectrum_y = this.defaultIfNull(user_spectrum_min, min_spectrum_y);
      max_spectrum_y = this.defaultIfNull(user_spectrum_max, max_spectrum_y);
      min_spectrum_x = this.defaultIfNull(user_spectrum_min_x, min_spectrum_x);
      max_spectrum_x = this.defaultIfNull(user_spectrum_max_x, max_spectrum_x);
      this.spectrumRange = [min_spectrum_y, max_spectrum_y];
      this.spectrumRangeX = [min_spectrum_x, max_spectrum_x];
      this.spectrum_range_emitter.emit(this.spectrumRange);
      this.spectrum_range_x_emitter.emit(this.spectrumRangeX);
      xScale = d3.scaleLinear()
        .domain([min_spectrum_x, max_spectrum_x])
        .range([0, width]);
      yScale = d3.scaleLinear()
        .domain([max_spectrum_y, min_spectrum_y])
        .range([0, height]);
    }

    //D3 axises with scale and style
    let xAxis = d3.axisBottom(xScale)
      .ticks(this.hideAxes ? 0 : 10)
      .tickSize(8)
      .tickPadding(8);
    let yAxis = d3.axisLeft(yScale)
      .ticks(this.hideAxes ? 0 : 6)
      .tickSize(8)
      .tickPadding(8);

    // instantiate svg with given width and height.
    let svg = d3.select(this.graph.nativeElement)
      .attr("width", this.width)
      .attr("height", this._height)
      .append("g")
      .attr("width", width)
      .attr("height", height)
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)

    this.svg = svg;

    let y1 = yScale(Math.min(this.defaultIfNull(this.corr_start_time, this.axis_start_time), this.defaultIfNull(this.corr_end_time, this.axis_end_time)))
    let y2 = yScale(Math.max(this.defaultIfNull(this.corr_start_time, this.axis_start_time), this.defaultIfNull(this.corr_end_time, this.axis_end_time)))
    
    let bgImg = this._backgroundImage;
    let _totalTime = this._totalTime;
    function cropImage(start_time, end_time) {
      return new Promise((resolve, reject) => {
        let img = document.createElement('img');
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const cropWidth = img.naturalWidth;
          let startHeight = img.naturalHeight / _totalTime * start_time;
          let endHeight = img.naturalHeight / _totalTime * end_time;
          const cropHeight = endHeight - startHeight;
          canvas.width = width;
          canvas.height = height;
    
          context.drawImage(img, 0, startHeight, cropWidth, cropHeight, 0, 0, width, height);
          resolve(canvas.toDataURL('image/png'));
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = bgImg;
      });
    }
    
    // The main image
    let image;
    let overlay_top;
    let overlay_bottom;
    if (this.panel_name != "Spectrum") {
      let cropped_image;
      if (this.axis_start_time != 0 || this.axis_end_time != this._totalTime) {
        cropped_image = await cropImage(this.axis_start_time, this.axis_end_time);
      } else {
        cropped_image = this._backgroundImage;
      }
      image = svg.append("g")
        .append("image")
        .attr('preserveAspectRatio', 'none')
        .attr("xlink:href", cropped_image)
        .style("z-index",0)
        .attr("width",width).attr("height",height);
    
      overlay_top = svg.append("rect")
        .attr("class", "overlay")
        .attr("width", "100%")
        .attr("height", Math.max(0,y1))
        .attr("fill", "grey")
        .attr("pointer-events", "all")
        .style("z-index",1)
        .attr("opacity", 0.5);
      
        // yScale( Math.min(this.start_time, this.end_time))
      overlay_bottom = svg.append("rect")
        .attr("class", "overlay")
        .attr("width", "100%")
        .attr("height", Math.max(0, yScale(this.axis_end_time) - yScale(Math.max(this.defaultIfNull(this.corr_start_time, this.axis_start_time), 
                                                                        this.defaultIfNull(this.corr_end_time, this.axis_end_time)))))
        .attr("transform", "translate(0," + y2 + ")")
        .attr("fill", "grey")
        .attr("pointer-events", "all")
        .style("z-index",1)
        .attr("opacity", 0.5);
    } else {
      overlay_top = svg.append("rect")
        .attr("class", "overlay")
        .attr("width", "100%")
        .attr("height", height)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .style("z-index",3)
        .attr("opacity", 0);
    }

    const line = d3.line()
      .x(d => xScale(d.x))
      .y(d => yScale(d.y));
    
  
    if (this.panel_name == "Spectrum") {
      svg.selectAll(".spectrum-line").remove();
      for (let i = 0; i< this.spectrumIterOptions.length; i ++) {
        let iter = this.spectrumIterOptions[i];
        if (this.spectrum[iter] == null)
          continue;
        for (let j = 0; j < this.spectrum[iter].length; j ++) {
          if (this.spectrumOn[iter] == null || !this.spectrumOn[iter][j])
            continue;
          svg.append("path")
            .datum(this.spectrum[iter][j])
            .attr("fill", "none")
            .attr("stroke", this.spectrumColors[iter][j])
            .attr("stroke-width", 3)
            .attr("stroke-opacity", 0.7)
            .attr("d", line)
            .attr("class", "spectrum-line");
        }
      }
    } else {
      if (this.data.length > 0) {
        for (let i = 0; i < this.data.length; i ++) {
          svg.append("path")
            .datum(this.data[i])
            .attr("fill", "none")
            .attr("stroke", this.colors[i])
            .attr("stroke-width", 3)
            .attr("stroke-opacity", 0.7)
            .attr("d", line)
            .attr("class", "line");
        }
      }
    }

    let dashed_line_data = []
    let dashed_line_data2 = []
    for (let i = this._range.startTrace; i <= this._range.endTrace; i++) {
      dashed_line_data.push({x:i, y: this.defaultIfNull(this.corr_start_time, this.axis_start_time)});
      dashed_line_data2.push({x:i, y: this.defaultIfNull(this.corr_end_time, this.axis_end_time)})
    }

    let dashed_line1 = svg.append("path")
      .datum(dashed_line_data)
      .attr("fill", "none")
      .attr('stroke', 'black')
      .attr('stroke-width', 2)
      .attr("stroke-opacity", this.enable_window? 1 : 0)
      .attr('d',line)
      .attr('class', 'dashed_line')
      .attr('stroke-dasharray', '3,6')

    let dashed_line2 = svg.append("path")
      .datum(dashed_line_data2)
      .attr("fill", "none")
      .attr('stroke', 'black')
      .attr('stroke-width', 2)
      .attr("stroke-opacity", this.enable_window? 1 : 0)
      .attr('d',line)
      .attr('class', 'dashed_line')
      .attr('stroke-dasharray', '3,6')

    // clipping the image
    let wrapper = svg.append("g")
      .attr("id", "wrapper")

    wrapper.append("rect")
      .attr("x", -this.margin.left)
      .attr("y", -this.margin.top)
      .attr("width", this.margin.left)
      .attr("height", this._height)
      .attr("fill", "#ffffff")

    wrapper.append("rect")
      .attr("x", -this.margin.left)
      .attr("y", -this.margin.top)
      .attr("width", this.width)
      .attr("height", this.margin.top)
      .attr("fill", "#ffffff")

    wrapper.append("rect")
      .attr("x", width)
      .attr("y", -this.margin.top)
      .attr("width", this.margin.right)
      .attr("height", this._height)
      .attr("fill", "#ffffff")

    wrapper.append("rect")
      .attr("x", -this.margin.left)
      .attr("y", height)
      .attr("width", this.width)
      .attr("height", this.margin.bottom)
      .attr("fill", "#ffffff")

    if (this.panel_name != "Spectrum") {
      image.on('mousemove', (event, d) => {
        this.traceNumber = d3.pointer(event)[0] / width * (this._range.endTrace - this._range.startTrace) + this._range.startTrace;
        this.timeNumber = d3.pointer(event)[1] / height * (this.axis_end_time - this.axis_start_time) + this.axis_start_time;
        this.interleaveType = Math.floor((this.traceNumber - this._range.startTrace) / this._interleave_extent) % 2 == 0 ? "Predicted" : "Field";
        if (this.mouse_downed && this.enable_window) {
          dashed_line_data2 = dashed_line_data2.map((d) => {return {x:d.x, y: this.timeNumber}})
          dashed_line2.datum(dashed_line_data2).attr("d",line)
        }
      })

      image.on('mouseexit', () => {
        this.mouse_downed = false;
      })

      image.on('mousedown', (event, d) => {
        if (!event.transform && !event.ctrlKey && this.enable_window && this.panel_name != "Spectrum") {
          this.corr_start_time = d3.pointer(event)[1] / height * (this.axis_end_time - this.axis_start_time) + this.axis_start_time;
          this.mouse_downed = true;
          this.mouse_downed_time = new Date().getTime();
          dashed_line_data = dashed_line_data.map((d) => {return {x:d.x, y:this.corr_start_time}})
          dashed_line1.datum(dashed_line_data).attr("d",line);  
        }
      })

      svg.selectAll(".overlay").on('mousedown', (event, d)=> {
        if (event.ctrlKey) {
          return;
        }
        this.corr_start_time = this.axis_start_time;
        this.corr_end_time = this.axis_end_time;
        overlay_top.attr("height", 0);
        overlay_bottom.attr("height", 0)
        dashed_line_data = dashed_line_data.map((d)=> {return {x:d.x, y: this.corr_start_time}})
        dashed_line_data2 = dashed_line_data.map((d)=> {return {x:d.x, y: this.corr_end_time}})
        dashed_line1.datum(dashed_line_data).attr("d",line)
        dashed_line2.datum(dashed_line_data2).attr("d", line)
        this.time_window_emitter.emit({start_time: this.corr_start_time, end_time: this.corr_end_time});
      })

      let mouseUp = (event, d) =>{
        if (this.mouse_downed && !event.transform && !event.ctrlKey && this.enable_window && this.panel_name != "Spectrum") {
          this.corr_end_time = d3.pointer(event)[1] / height * (this.axis_end_time - this.axis_start_time) + this.axis_start_time;
          let mouse_uped_time = new Date().getTime();
          let duration = mouse_uped_time - this.mouse_downed_time;
          if (duration > 200 && this.corr_start_time != this.corr_end_time) {
            let newYScale
            if (this.transform) {
              newYScale = this.transform.rescaleY(yScale);
            } else {
              newYScale = yScale;
            }
            this.time_window_emitter.emit({start_time: this.corr_start_time, end_time: this.corr_end_time});

            overlay_top.attr("height", newYScale(Math.min(this.corr_start_time, this.corr_end_time)))
            overlay_bottom.attr("height", newYScale(this.axis_end_time) - newYScale( Math.max(this.corr_start_time, this.corr_end_time)))
                          .attr("transform", "translate(0," + newYScale(Math.max(this.corr_start_time, this.corr_end_time)) + ")")
            dashed_line_data2 = dashed_line_data2.map((d) => {return {x:d.x, y:Math.max(this.corr_start_time, this.corr_end_time)}})
            dashed_line2.datum(dashed_line_data2).attr("d",line);
          } else {
            this.corr_start_time = this.axis_start_time;
            this.corr_end_time = this.axis_end_time;
            dashed_line_data = dashed_line_data.map((d)=> {return {x:d.x, y: this.corr_start_time}})
            dashed_line_data2 = dashed_line_data.map((d)=> {return {x:d.x, y: this.corr_end_time}})
            dashed_line1.datum(dashed_line_data).attr("d",line)
            dashed_line2.datum(dashed_line_data2).attr("d", line)
            this.time_window_emitter.emit({start_time: this.corr_start_time, end_time: this.corr_end_time});
            overlay_top.attr("height", 0);
            overlay_bottom.attr("height", 0)
          }
        }
        this.mouse_downed = false;
      }

      image.on('mouseup', mouseUp)
      svg.selectAll(".dashed_line").on('mouseup', mouseUp)
      svg.selectAll(".overlay").on('zoom',()=>{})
    }

    // legend
    if (this.panel_name == "Spectrum") {
      const legend = svg.append("g").attr("class", "legend").attr("transform", `translate(${width-100}, 10)`);
      let labels = [];
      for (let i = 0; i < this.spectrumIterOptions.length; i ++) {
        let iter = this.spectrumIterOptions[i];
        if (this.spectrum[iter] == null) continue;
        let t = [{label:`Predicted-${iter}`, color:this.spectrumColors[iter][0]}, {label:`Field-${iter}`, color:this.spectrumColors[iter][1]}];
        for (let j = 0; j < this.spectrum[iter].length; j ++) {
          if (this.spectrumOn[iter] == null) {
            labels = []
            break;
          }
          if (this.spectrumOn[iter][j])
            labels.push(t[j]); 
        }
      }
      const legendItem = legend.selectAll(".legend-item")
        .data(labels)
        .enter()
        .append("g")
        .attr("class", "legend-item")
        .attr("transform", (d, i) => `translate(0, ${i * 20})`);
      
      legendItem.append("line")
        .attr("x1", 0)
        .attr("y1", 5)
        .attr("x2", 15)
        .attr("y2", 5)
        .style("stroke", (d) => d.color);
      
      legendItem.append("text")
        .attr("x", 20)
        .attr("y", 5)
        .attr("dy", "0.32em")
        .style("fill", "black")
        .text((d) => d.label);
    }

    // Instantiate axis
    let gX = svg.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis);
    let gY = svg.append("g").call(yAxis);

    if (this.transform) {
      svg.call(zoom.transform, this.transform);
    }
    // Set zoom behaviour for this svg
    svg.call(zoom);
  }

  defaultIfNull(obj, default_val) {
    return obj != null ? obj : default_val;
  }
}

export const resizeBase64Img = function(base64: string, newWidth: number, newHeight: number): Promise<string> {
  return new Promise((resolve) => {
      const img = document.createElement('img')
      img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = newWidth;
          canvas.height = newHeight;

          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          resolve(canvas.toDataURL());
      };
      img.src = base64;
  });
}


