import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-rebuild-model-table",
  templateUrl: "./rebuild-model-table.component.html",
  styleUrls: ["./rebuild-model-table.component.css"],
})
export class RebuildModelTableComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RebuildModelTableComponent>
  ) {}

  ngOnInit() {}

  submit() {
    this.dialogRef.close({ confirm: true });
  }

  cancel() {
    this.dialogRef.close({ confirm: false });
  }
}
