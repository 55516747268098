import {
  throwError as observableThrowError,
  Observable,
  Subscription,
  BehaviorSubject,
} from "rxjs";

import { catchError, map, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { HttpService } from "../../shared/services/http.service";
import { AppConfig } from "../../app.config";
import { AuthService } from "./auth.service";
import { BillingDetail } from "../../models/billingDetail";
import { Invoice } from "../../models/invoice";
import { HttpHeaders, HttpResponse } from "@angular/common/http";

@Injectable()
export class BillingService {
  constructor(private http: HttpService, private authService: AuthService) {}

  getInvoices(groupName: string): Observable<Invoice[]> {
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/billing/${groupName}/invoices/`,
        this.getAuthorizationHeader()
      )
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return;

          let data = response;
          let invoices = data ? data.body : null;
          if (!invoices || !invoices.data) return;

          return invoices.data.map((d) => {
            return {
              id: d.reference,
              invoiceDate: d.invoiceDate,
              description: d.description,
              amount: d.amount,
              amountWithTax: d.totalAmount,
              status: d.status,
              paymentDetails: d.paymentDetails,
              url: d.invoicePdf,
            };
          });
        })
      );
  }

  getBillingDetail(groupName: string): Observable<BillingDetail> {
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/billing/${groupName}/`,
        this.getAuthorizationHeader()
      )
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return;

          let j = response;
          let b = j ? j.body : null;
          return {
            id: j.customer_id,
            name: b.name,
            address: b.address ? b.address.address1 : null,
            city: b.address ? b.address.city : null,
            postcode: b.address ? b.address.postcode : null,
            country: b.address ? b.address.country : null,
            county: b.address ? b.address.county : null,
            email: b.email,
            paymentType: b.payment ? b.payment.method : null,
            paymentDigits: b.payment ? b.payment.digits : null,
            failureDetails: b.failureDetails,
          };
        })
      );
  }

  updateBillingDetails(data: BillingDetail): Observable<any> {
    return this.http
      .put(
        `${AppConfig.settings.apiUrl}/billing/${data.id}/`,
        {
          id: data.id,
          name: data.name,
          address: {
            address1: data.address,
            city: data.city,
            postcode: data.postcode,
            country: data.country,
            county: data.county,
          },
          email: data.email,
          payment: {
            token: data.stripeToken,
          },
        },
        this.getAuthorizationHeader()
      )
      .pipe(
        map((response) => {
          if (!response) return;

          // var data = response.json();
          // var data = response.json();
          // if (data) {
          //   var r = data.response;
          //   if (!!r) {
          //     var status_code = r.status_code;
          //     var body = r.body;

          //     if (status_code == 400) throw observableThrowError(body);
          //   }
          // }
          return response;
        }),
        catchError((err) => {
          throw observableThrowError(err);
        })
      );
  }

  private getAuthorizationHeader(): { headers: HttpHeaders } {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );
    return { headers };
  }
}
