<div class="wrapper">
  <div id="content">
    <div class="content__title">
      <h1 style="display:inline-block;margin-right:10px;"><span class="project-title">Billing</span></h1>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card" id="slice-settings">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <h4 class="card-title">Payment Details</h4>
                <div class="row">
                  <div class="loading-container" *ngIf="isLoadingDetails">
                    <div class="loader">
                      <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner><div class="text">Loading...</div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div>
                      <div class="form-horizontal" *ngIf="!isEditing">
                        <div class="form-group">
                          <label class="col-sm-3">Billing Name:</label>
                          <span class="col-sm-9">{{billingDetail.name}}</span>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3">Billing E-mail:</label>
                          <span class="col-sm-9">{{billingDetail.email}}</span>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3">Address</label>
                          <span class="col-sm-9">{{getAddress(billingDetail)}}</span>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3">Payment Method:</label>
                          <span class="col-sm-9">{{billingDetail.paymentType}} ending {{billingDetail.paymentDigits}}</span>
                        </div>
                        <div class="form-group" style="color:red" *ngIf="billingDetail.failureDetails">
                          <label class="col-sm-3">Last Payment Error:</label>
                          <span class="col-sm-9">{{billingDetail.failureDetails}}</span>
                        </div>
                        <div class="form-group">
                          <div class="col-sm-8">
                            <button mat-raised-button color="primary" title="Edit" (click)="editBilling()"><i class="zmdi zmdi-edit" style="vertical-align:auto"></i> Edit</button>
                          </div>
                        </div>
                      </div>
                      <form class="form-horizontal" *ngIf="isEditing" (ngSubmit)="submitForm()">
                        <div class="form-group">
                          <div class="col-sm-12">
                            <mat-form-field>
                              <input matInput placeholder="Billing Name" required [(ngModel)]="billingDetailEdit.name" name="name">
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="col-sm-12">
                            <mat-form-field>
                              <input matInput placeholder="Billing E-mail" required type="email" [(ngModel)]="billingDetailEdit.email" name="email" email>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="col-sm-12">
                            <mat-form-field>
                              <input matInput placeholder="Address" required [(ngModel)]="billingDetailEdit.address" name="address">
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="City" [(ngModel)]="billingDetailEdit.city" name="city">
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="County" [(ngModel)]="billingDetailEdit.county" name="county">
                            </mat-form-field>
                            <mat-form-field>
                              <input matInput placeholder="Post code" required [(ngModel)]="billingDetailEdit.postcode" name="postcode">
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="col-sm-12">
                            <mat-form-field>
                              <mat-select placeholder="Country" required [(value)]="billingDetailEdit.country" name="country">
                                <mat-option *ngFor="let countryCode of countryCodes" [value]="countries[countryCode]">{{countries[countryCode]}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-sm-12 payment-method">Payment Method</label>
                          <span class="col-sm-12" *ngIf="!isEditingPayment">{{billingDetailEdit.paymentType}} ending {{billingDetailEdit.paymentDigits}} <button class="edit-payment" (click)="editPayment()"><i class="zmdi zmdi-edit"></i> Edit</button></span>
                          <div *ngIf="isEditingPayment">
                            <stripe-card class="col-sm-6 stripe-element" #stripeCard
                                         [options]="stripeOptions"
                                         (catch)="onStripeError($event)"
                                         (invalidChange)="onStripeInvalid($event)"
                                         (tokenChange)="setStripeToken($event)"></stripe-card>
                            <div class="col-sm-3 cancel-payment" *ngIf="billingDetailEdit.paymentType">
                              <button class="edit-payment" (click)="cancelPayment()">Cancel</button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="col-sm-12" style="color:red;margin-bottom:10px;" *ngIf="paymentError">{{paymentError}}</div>
                          <div class="col-sm-12">
                            <button mat-raised-button color="primary" title="Update" style="margin-right:5px;" type="submit">Update</button>
                            <button mat-raised-button color="default" title="Cancel" (click)="cancelBilling()">Cancel</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card" id="slice-settings">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <h4 class="card-title">Invoices</h4>
                <div class="row">
                  <div class="loading-container" *ngIf="isLoadingInvoices">
                    <div class="loader">
                      <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner><div class="text">Loading...</div>
                    </div>
                  </div>
                  <div class="col-md-12" *ngIf="!isLoadingInvoices">
                    <div class="table-section" *ngIf="!!invoices && invoices.length>0">
                      <table class="table-hover">
                        <thead>
                          <tr>
                            <th>Invoice Date</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th style="width:80px;"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let invoice of invoices | paginate: {itemsPerPage: 20, currentPage: page, totalItems: total }" (click)="showInvoice(invoice.url)">
                            <td>{{invoice.invoiceDate | date}}</td>
                            <td>{{invoice.description}}</td>
                            <td>{{invoice.amount | currency:'USD'}}</td>
                            <td>
                              <span *ngIf="invoice.status==0" class="invoice-unpaid">Unpaid</span>
                              <span *ngIf="invoice.status==1" class="invoice-paid">Paid</span>
                              <span *ngIf="invoice.status==2" class="invoice-unpaid">Cancelled</span>
                              <span *ngIf="invoice.status==3" class="invoice-processing">Processing</span>
                            </td>
                            <td><button *ngIf="invoice.url" mat-button (click)="showInvoice(invoice.url)">View Details</button></td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="page-controls">
                        <pagination-controls (pageChange)="page = $event"></pagination-controls>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
