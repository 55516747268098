import { Component } from "@angular/core";
import { ProjectService } from "../../../../shared/services/project.service";
import { CachedProjectService } from "../../../../shared/services/cached-project.service";
import { Project } from "../../../../models/project";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Grid } from "../../../../models/grid";

interface NewWellLog {
  name: string;
  description: string;
  log_file_path: string;
  inline_location: number;
  crossline_location: number;
  project: string;
}
interface WellLog extends NewWellLog {
  id: string;
}

@Component({
  selector: "app-well-log-listing",
  templateUrl: "./well-log-listing.component.html",
  styleUrls: ["./well-log-listing.component.less"],
})
export class WellLogListingComponent {
  private destroy$ = new Subject<void>();
  project_id: string;
  wellLogs: WellLog[] = [];
  welllogForm: FormGroup;
  mode: "editting" | "creating" = "creating";
  activeWellLog: WellLog;
  dx: number;

  constructor(
    private projectService: ProjectService,
    private cachedProjectService: CachedProjectService,
    private formBuilder: FormBuilder,
    private notificationService: NzNotificationService
  ) {
    this.welllogForm = this.formBuilder.group({
      name: this.formBuilder.control<String>("", [Validators.required]),
      description: this.formBuilder.control<String>(""),
      log_file_path: this.formBuilder.control<String>("", [
        Validators.required,
      ]),
      inline_location: this.formBuilder.control<number>(0, [
        Validators.required,
      ]),
      crossline_location: this.formBuilder.control<number>(0, [
        Validators.required,
      ]),
    });

    this.cachedProjectService.currentProject
      .pipe(takeUntil(this.destroy$))
      .subscribe((project: Project) => {
        if (project) {
          this.project_id = project.id;
          this.getWellLogs();
        }
      });
  }

  getWellLogs() {
    this.projectService
      .getProjectWellLogs(this.project_id)
      .pipe(take(1))
      .subscribe({
        next: (wellLogs: { data: WellLog[]; model_grid: Grid }) => {
          this.wellLogs = wellLogs.data;
          this.dx = wellLogs.model_grid.dx;
        },
        error: (err) => {
          console.log(err);
          this.notificationService.error(
            "Error",
            err.error?.error || "An unexpected error occurred!"
          );
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submitForm() {
    for (var i in this.welllogForm.controls) {
      this.welllogForm.controls[i].updateValueAndValidity();
    }

    if (this.welllogForm.valid) {
      let data = this.welllogForm.value;
      if (this.mode == "editting") {
        data["id"] = this.activeWellLog.id;
      }

      data["inline_location"] = (data["inline_location"] - 1) * this.dx;
      data["crossline_location"] = (data["crossline_location"] - 1) * this.dx;

      this.projectService
        .updateWellLogs(this.project_id, data)
        .pipe(take(1))
        .subscribe({
          next: (data: { data: WellLog[]; model_grid: Grid }) => {
            this.notificationService.success(
              "Success",
              this.mode == "creating"
                ? "Successfully added well log!"
                : "Successfully editted well log!"
            );
            this.wellLogs = data.data;
            this.cachedProjectService.clearProjectCachedById(this.project_id);
          },
          error: (err) => {
            console.log("error", err);
            this.notificationService.error(
              "Error",
              err.error?.error || "An unexpected error occured!"
            );
          },
        });
      this.resetForm();
    }
  }

  editWellLog(data: WellLog) {
    this.welllogForm.setValue({
      name: data.name,
      description: data.description,
      log_file_path: data.log_file_path,
      inline_location: data.inline_location,
      crossline_location: data.crossline_location,
    });
    this.activeWellLog = data;
    this.mode = "editting";
  }

  resetForm() {
    this.welllogForm.reset();
    this.mode = "creating";
  }

  deleteWellLog(data: WellLog) {
    this.projectService
      .deleteWellLog(this.project_id, data.id)
      .pipe(take(1))
      .subscribe({
        next: (data: { data: WellLog[]; model_grid: Grid }) => {
          this.notificationService.success(
            "Success",
            "Successfully deleted well log!"
          );
          this.wellLogs = data.data;
        },
        error: (err) => {
          console.log("error", err);
          this.notificationService.error(
            "Error",
            err.error?.error || "An unexpected error occured!"
          );
        },
      });
  }
}
