import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { JobDetail } from "../../../models/jobDetail";
import { ShotType } from "../../../models/shotType";
import { VelocityComponent } from "../../project/dialogs/velocity/velocity.component";
import { debounce } from "lodash";
import { MinMaxDialog } from "../../../models/minMaxDialog";
import { StoredSettingsService } from "../../services/stored-settings.service";
import { ShotSettings } from "../../../models/shotSettings";
import { SettingTypes } from "../../../shared/enums/settingTypes";
export interface ShotChartMenuSettings {
  currentClipping: number;
  currentScaling: number[];
  shotType: ShotType;
  shotGraphHeight: number;
}
@Component({
  selector: "app-shot-chart-menu",
  templateUrl: "./shot-chart-menu.component.html",
  styleUrls: ["./shot-chart-menu.component.less"],
})
export class ShotChartMenuComponent implements OnInit, OnChanges {
  @Input() jobDetail: JobDetail;
  @Input() settingsKey: string;
  @Input() shotTypes: ShotType[];
  @Output() menuChanged = new EventEmitter<ShotChartMenuSettings>();
  shotType: ShotType;
  mainGraphHeight: number = 500;
  globalClipping: boolean = true;
  currentClipping: number = 0;
  currentScaling: number[] = [null, null];
  velocityAuto = true;
  synchroniseSettings: boolean = false;
  selectedShot: number;
  selectedPanel: number = 0;
  typeOfSelectedRcvr: ShotType;
  typeDetails = [
    { name: "forward", value: 1 },
    { name: "test step", value: 2 },
  ];

  tableTypes: any[];

  private syncFwd: number = 1;

  private shotTypeToScale = {};
  setClipping = debounce(() => {
    this.shotTypeToScale[this.shotType.ttrName] = this.currentScaling;
    this.shotTypeToScale[`${this.shotType.ttrName}_clipping`] =
      this.currentClipping;
    this.onMenuChanged();
    // this.updateMainImage();
  }, 250);
  constructor(
    public dialog: MatDialog,
    private storedSettingsService: StoredSettingsService
  ) {}

  ngOnInit() {
    this.shotType = this.shotTypes[0];
    this.tableTypes = this.translateTableTypes(12);
    this.restoreSettings();
  }
  ngOnChanges(changes: SimpleChanges) {
    const change = changes.jobDetail;
    if (change && change.currentValue) {
      this.restoreSettings();
    }
  }
  updateMainImage() {
    //TODO: implement this
  }
  setScaling() {
    if (this.currentScaling.constructor != Array) {
      this.currentScaling = [null, null];
    }
    let dialogRef = this.dialog.open(VelocityComponent, {
      // height: "350px",
      width: "400px",
      data: {
        min: this.currentScaling[0],
        max: this.currentScaling[1],
        auto: this.velocityAuto,
      },
    });
    dialogRef.afterClosed().subscribe((result: MinMaxDialog) => {
      if (!result) return;
      this.currentScaling = [result.min, result.max];
      // this.updateMainImage();
      // this.storeSettings();
      this.shotTypeToScale[this.shotType.ttrName] = this.currentScaling;
      this.velocityAuto = result.auto;
      this.onMenuChanged();
    });
  }
  onMenuChanged() {
    this.storeSettings();
    this.menuChanged.emit({
      currentClipping: this.currentClipping,
      currentScaling: this.currentScaling,
      shotType: this.shotType,
      shotGraphHeight: this.mainGraphHeight,
    });
  }
  onShotTypeChange(value: ShotType, event) {
    event.stopPropagation();
    this.shotType = value;

    if (this.synchroniseSettings) {
      this.shotType.fwd = this.syncFwd;
    }

    // this.storeSettings();
    this.setPanelNames();
    if (this.shotType.numPanel <= this.selectedPanel) {
      this.selectedPanel = 0;
    }
    if (this.selectedShot) {
      this.currentScaling = this.shotTypeToScale[this.shotType.ttrName] || [
        null,
        null,
      ];
      this.currentClipping =
        this.shotTypeToScale[`${this.shotType.ttrName}_clipping`] || 0;
      // this.updateSelectedShotInfo();
    }
    if (value.name == "RcvrList") this.typeOfSelectedRcvr = value;
    // this.updateUrl();
    this.onMenuChanged();
  }
  // updateSelectedShotInfo() {
  //   //TODO: implement this
  // }
  onShotTypeDetailChange(value: number, event) {
    event.stopPropagation();
    this.shotType.fwd = value;
    if (this.selectedShot) {
      // this.updateSelectedShotInfo();
    }

    if (this.synchroniseSettings) {
      this.syncFwd = value;
    }
    this.onMenuChanged();
  }
  translateTableTypes(chunk: number) {
    let res = [];
    let size = this.shotTypes.length / chunk;
    let idx = 0;
    while (idx < this.shotTypes.length) {
      res.push(this.shotTypes.slice(idx, idx + size));
      idx += size;
    }
    res.push(this.shotTypes.slice(idx, this.shotTypes.length));
    return res;
  }
  private updateUrl() {}
  private setPanelNames() {
    //TODO: implement this
    // let numPanel = this.shotType.name == "RcvrList" ? this.typeOfSelectedRcvr.numPanel : this.shotType.numPanel;
    // this.panelNames = this.allPanelNames.slice(0, numPanel);
    // if (this.shotType.numPanel > 1 && this.shotType.name != "RcvrList") {
    //   this.panelNames.push('Interleaved');
    // }
  }
  private restoreSettings() {
    let settings = this.storedSettingsService.getSettings<ShotSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Shot,
      this.jobDetail.projectId
    );
    if (settings) {
      this.shotType = settings.shotType;
      this.currentScaling = settings.scaling;
      this.currentClipping = settings.clipping;
      this.mainGraphHeight = settings.mainShotGraphHeight || 500;
    }
  }
  private storeSettings() {
    let currentSettings = this.storedSettingsService.getSettings<ShotSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Shot
    );
    this.storedSettingsService.setSettings<ShotSettings>(
      this.settingsKey,
      {
        ...currentSettings,
        shotType: this.shotType,
        scaling: this.currentScaling,
        clipping: this.currentClipping,
        mainShotGraphHeight: this.mainGraphHeight,
      },
      SettingTypes.Shot
    );
    this.storedSettingsService.settingsUpdated([SettingTypes.Shot]);
  }
}
