<div class="wrapper">
  <!-- <app-sidebar class="hidden-xs"></app-sidebar> -->
  <app-sidebar></app-sidebar>

  <div id="content">
    <div class="content__title" *ngIf="!!jobDetail">
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        "
      >
        <div>
          <h1 style="margin-right: 10px">
            <span class="project-title">
              <span class="actions__item" (click)="navigateToProjectTable()">{{
                jobDetail.projectName
              }}</span>
            </span>
            <span
              class="actions"
              *ngIf="!authService.isDemoUser() && !isTmpGroup"
            >
              <button
                class="actions__item zmdi zmdi-edit"
                (click)="openProjectDialog()"
              ></button>
            </span>
            <span
              class="actions"
              *ngIf="!authService.isDemoUser() && isTmpGroup"
            >
              <button
                class="actions__item zmdi zmdi-square-down"
                (click)="openSearchGroupDialog()"
              ></button>
            </span>
          </h1>
          <!-- <div class="actions" *ngIf="!authService.isDemoUser() && !isTmpGroup">
            <button class="actions__item zmdi zmdi-edit" (click)="openProjectDialog()"></button>
          </div>
          <div class="actions" *ngIf="!authService.isDemoUser() && isTmpGroup">
            <button class="actions__item zmdi zmdi-square-down" (click)="openSearchGroupDialog()"></button>
          </div> -->
        </div>
        <div style="display: flex; justify-content: right;">
          <div
            *ngIf="
              jobDetail.parent_job_id != null &&
              jobs != null &&
              parentJobFullStr != null
            "
            class="job-status"
            style="margin-right: 20px; margin-top: 2px; white-space: nowrap"
          >
            <h2 *ngIf="!isMobile" [matTooltip]="parentJobFullStr">
              Parent Job: {{ parentJobDisplayStr }}
            </h2>
            <!-- <button (click)="toParentJob()" style="border: solid; border-width: 1px;">Go to</button> -->
            <!-- <button (click)="addTabWithId(jobDetail.parent_job_id)" style="border: solid; border-width: 1px;">Add to Popular</button> -->
          </div>
          <div style="margin-top: 2px; margin-right: 10px">
            <span
              *ngIf="showCountDown()"
              [matTooltip]="'Clean Up in ' + getJobCleanUpCountDown() + ' days'"
              >Storage Status: {{ getJobArchiveStatus() }}</span
            >
            <span *ngIf="!showCountDown()"
              >Storage Status: {{ getJobArchiveStatus() }}</span
            >
          </div>
          <div
            style="cursor: pointer; margin-top: 2px; margin-right: 10px"
            class="keep-job"
          >
            <span class="actions__item" (click)="keep_job()"
              >Keep Job: {{ jobDetail.keep_job }}</span
            >
          </div>

          <div *ngIf="isReloadingJob">
            <mat-spinner
              mode="indeterminate"
              color="primary"
              diameter="20"
            ></mat-spinner>
          </div>
          <button
            style="
              border: none;
              font-size: small;
              height: fit-content;
              margin-top: 2px;
              background-color: #f3f3f3;
              white-space: nowrap;
            "
            [style.margin-right]="isMobile ? '10px' : '20px'"
            class="job-status"
            (click)="refreshJobs()"
          >
            <i class="zmdi zmdi-refresh"></i>
            <span
              class="job-status"
              style="margin-left: 10px"
              *ngIf="!isMobile"
              >{{ isReloadingJob ? "Refreshing Jobs" : "Refresh Jobs" }}</span
            >
          </button>
          <div [style.margin-right]="isMobile ? '10px' : '20px'">
            <mat-checkbox
              class="job-status"
              [(ngModel)]="synchroniseSettings"
              (ngModelChange)="onSynchroniseChange($event)"
              >{{
                isMobile ? "Sync Settings" : "Synchronise Settings"
              }}</mat-checkbox
            >
          </div>
          <div class="job-status" *ngIf="!!jobs" style="margin-top: 2px">
            <h2 *ngIf="!isMobile">Job Status:</h2>
            <h2 *ngIf="isMobile">Status:</h2>
            <p>{{ getJobStatus(jobDetail) }}</p>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 10px">
        <small style="display: block">
          {{ jobDetail.projectDescription }}
        </small>
      </div>

      <div class="row">
        <div class="col-sm-12" style="padding: 0px">
          <div class="popular-tab-container">
            <div class="btn-group">
              <button mat-raised-button matcolor="blue" (click)="addTab()">
                <i class="zmdi zmdi-plus"></i>
              </button>
              <button
                mat-raised-button
                matcolor="blue"
                [disabled]="popularJobs.length === 0"
                (click)="removeTab()"
              >
                <i class="zmdi zmdi-minus"></i>
              </button>
              <button
                mat-raised-button
                matcolor="blue"
                [disabled]="popularJobs.length === 0"
                (click)="shiftLeft()"
              >
                <i class="zmdi zmdi-arrow-back"></i>
              </button>
              <button
                mat-raised-button
                matcolor="blue"
                [disabled]="popularJobs.length === 0"
                (click)="shiftRight()"
              >
                <i class="zmdi zmdi-arrow-forward"></i>
              </button>
            </div>

            <mat-tab-group
              [ngStyle]="{'border-top': popularJobs.length > 0 ? '1px solid #ccc' : 'none'}"
              id="tab-bar-popular"
              [selectedIndex]="selectedIndexPopular"
              (selectedIndexChange)="onSelectedChangedPopular($event)"
              mat-stretch-tabs="false"
              mat-align-tabs="start"
            >
              <mat-tab *ngFor="let job of popularJobs; let i = index">
                <ng-template mat-tab-label>
                  <span *ngIf="job.status == 'RUNNING'">
                    <i class="zmdi zmdi-play"></i>&nbsp;
                  </span>
                  <span
                    [title]="job.name"
                    (click)="onSelectedChangedPopular(i)"
                  >
                    {{ job.name.substr(0, 7) }}
                  </span>
                  <div
                    class="actions"
                    *ngIf="
                      i == selectedIndexPopular && !authService.isDemoUser()
                    "
                  >
                    <button
                      class="actions__item zmdi zmdi-edit"
                      (click)="openJobDialog(true)"
                    ></button>
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div
            style="
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #ccc;
              border-top: 1px solid #ccc;
            "
          >
            <div style="overflow: auto; width: 80%">
              <mat-tab-group
                id="tab-bar"
                [selectedIndex]="selectedIndex"
                (selectedIndexChange)="onSelectedChanged($event)"
                style="
                  border-color: #ccc;
                  border-style: solid;
                  border-width: 0px 1px 0px 0px;
                "
              >
                <mat-tab *ngFor="let job of jobs; let i = index">
                  <ng-template mat-tab-label>
                    <span *ngIf="job.status == 'RUNNING'">
                      <i class="zmdi zmdi-play"></i>&nbsp;
                    </span>
                    <span [matTooltip]="job.comments">{{ job.name }}</span>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>

            <div *ngIf="!isMobile" style="padding-left: 5px;" class="job-select">
              <label style="margin-right: 5px">Selected Job</label>
              <nz-select
                nzShowSearch
                [ngModel]="this.selectedJobIndex"
                (ngModelChange)="menuSelected2($event)"
                style="width: 300px"
              >
                <nz-option
                  *ngFor="let job of jobs; let i = index"
                  [nzValue]="i"
                  [nzLabel]="job.name"
                  (click)="menuSelected2(i)"
                >
                </nz-option>
              </nz-select>
              <!-- <button
                style="width: fit-content"
                [matMenuTriggerFor]="jobMenu"
                (click)="menuOpen(menuTrigger, $event)"
              >
                {{
                  selectedJobName.substr(0, 10) +
                    (selectedJobName.length > 10 ? "..." : "")
                }}
              </button> -->
              <div class="actions" *ngIf="!authService.isDemoUser()">
                <button
                  class="actions__item zmdi zmdi-edit"
                  (click)="openJobDialog(false)"
                ></button>
              </div>
              <!-- <mat-menu
                class="long-menu"
                #jobMenu="matMenu"
                style="position: relative"
              >
                <mat-form-field
                  (click)="$event.stopPropagation()"
                  style="
                    min-width: 600px;
                    width: 90%;
                    margin: 0px 15px;
                    position: sticky;
                    top: 0px;
                    z-index: 2;
                    background: white;
                  "
                >
                  <mat-label>Search</mat-label>
                  <input matInput type="search" [(ngModel)]="jobSearchString" />
                </mat-form-field>
                <ng-container *ngFor="let job of jobs; let i = index">
                  <ng-container
                    *ngIf="
                      job.name
                        .toLowerCase()
                        .includes(jobSearchString.toLowerCase())
                    "
                  >
                    <button
                      class="job-select-menu-item"
                      mat-menu-item
                      #menuItem="matMenuItem"
                      (click)="menuSelected(menuItem, i, $event)"
                      [style.width]="windowWidth - 200 + 'px'"
                      style="overflow: hidden"
                    >
                      {{ job.name }}
                    </button>
                  </ng-container>
                </ng-container>
              </mat-menu>
              <mat-menu class="long-menu" #jobSearchMenu="matMenu">
                <input
                  type="text"
                  placeholder="Job name"
                  aria-label="job_name"
                  matInput
                  [(ngModel)]="searchStr"
                  (ngModelChange)="updateSearchStr()"
                  (click)="$event.stopPropagation()"
                  [matAutocomplete]="autoJob"
                />
                <mat-autocomplete
                  #autoJob="matAutocomplete"
                  (optionSelected)="jobSelected($event)"
                >
                  <mat-option
                    *ngFor="let job of jobFilteredOptions"
                    [value]="job.name"
                  >
                    {{ job.name.substring(0, 20) }}
                  </mat-option>
                </mat-autocomplete>
              </mat-menu> -->
            </div>

            <!-- on mobile / window with small width -->
            <div *ngIf="isMobile" class="job-select">
              <button
                [matMenuTriggerFor]="jobMenu"
                #menuTriger="matMenuTrigger"
                (click)="menuOpen(menuTrigger, $event)"
              >
                Job Select
              </button>
              <div class="actions" *ngIf="!authService.isDemoUser()">
                <button
                  class="actions__item zmdi zmdi-edit"
                  (click)="openJobDialog(false)"
                ></button>
              </div>
              <mat-menu class="long-menu-narrow" #jobMenu="matMenu">
                <ng-container *ngFor="let job of jobs; let i = index">
                  <button
                    class="job-select-menu-item-narrow"
                    mat-menu-item
                    #menuItem="matMenuItem"
                    (click)="menuSelected(menuItem, i, $event)"
                  >
                    {{ job.name }}
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>
        </div>

        <!--<div class="col-sm-2 job-status" *ngIf="jobs && (!authService.isDemoUser())">
          <button *ngIf="jobs[selectedIndex].status == 'RUNNING' || jobs[selectedIndex].status == 'NOTRUNNINNG'" mat-raised-button color="primary" (click)="onStartStopClicked()">
            <span *ngIf="jobs[selectedIndex].status == 'RUNNING'">
              <i class="zmdi zmdi-stop"></i>&nbsp;Stop
            </span>
            <span *ngIf="jobs[selectedIndex].status != 'RUNNING'">
              <i class="zmdi zmdi-play"></i>&nbsp;Start
            </span>
          </button>
        </div>-->

        <div class="col-sm-12 job-error" *ngIf="!!jobs">
          {{ jobs[selectedIndex].last_error_status }}
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>

    <div
      class="projects-bar-popular"
      [ngClass]="{ visible: tabBarVisible, emptyTab: tabIsEmpty }"
    >
      <div
        class="btn-group"
        [ngClass]="{ visible: tabBarVisible, emptyTab: tabIsEmpty }"
      >
        <button mat-raised-button matcolor="blue" (click)="addTab()">
          <i class="zmdi zmdi-plus"></i>
        </button>
        <button
          mat-raised-button
          matcolor="blue"
          [disabled]="popularJobs.length === 0"
          (click)="removeTab()"
        >
          <i class="zmdi zmdi-minus"></i>
        </button>
        <button
          mat-raised-button
          matcolor="blue"
          [disabled]="popularJobs.length === 0"
          (click)="shiftLeft()"
        >
          <i class="zmdi zmdi-arrow-back"></i>
        </button>
        <button
          mat-raised-button
          matcolor="blue"
          [disabled]="popularJobs.length === 0"
          (click)="shiftRight()"
        >
          <i class="zmdi zmdi-arrow-forward"></i>
        </button>
      </div>

      <mat-tab-group
        [selectedIndex]="selectedIndexPopular"
        (selectedIndexChange)="onSelectedChangedPopular($event)"
        mat-stretch-tabs="false"
        mat-align-tabs="start"
      >
        <mat-tab *ngFor="let job of popularJobs; let i = index">
          <ng-template mat-tab-label>
            <span *ngIf="job.status == 'RUNNING'">
              <i class="zmdi zmdi-play"></i>&nbsp;
            </span>
            <span [title]="job.name">
              {{ job.name.substr(0, 7) }}
            </span>
            <!-- <div class="actions" *ngIf="(i==selectedIndexPopular) && (!authService.isDemoUser())">
              <button class="actions__item zmdi zmdi-edit" (click)="openJobDialog(true)"></button>
            </div> -->
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <div class="projects-bar" [ngClass]="{ visible: tabBarVisible }">
        <div class="col-md-10">
          <mat-tab-group
            [selectedIndex]="selectedIndex"
            (selectedIndexChange)="onSelectedChanged($event)"
          >
            <mat-tab *ngFor="let job of jobs; let i = index">
              <ng-template mat-tab-label>
                <span *ngIf="job.status == 'RUNNING'">
                  <i class="zmdi zmdi-play"></i>&nbsp;
                </span>
                {{ job.name }}
                <!-- <div class="actions" *ngIf="(i==selectedIndex) && (!authService.isDemoUser())">
                  <button class="actions__item zmdi zmdi-edit" (click)="openJobDialog(false)"></button>
                </div> -->
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="col-md-2 sync-settings hidden-xs">
          <div class="">
            <mat-checkbox
              class="job-status"
              style="margin-top: 10px"
              [(ngModel)]="synchroniseSettings"
              (ngModelChange)="onSynchroniseChange($event)"
              >Synchronise Settings</mat-checkbox
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col">
        <mat-card>
        <app-settings-debug></app-settings-debug>
      </mat-card>
      </div>
    </div> -->
  </div>
  <div class="project-settings-bar" *ngIf="jobDetail && this.isDemoPage()">
    <app-project-settings-bar
      [jobDetail]="jobDetail"
      [jobs]="jobs"
      [bottom]="projectSettingsBarBottom"
    ></app-project-settings-bar>
  </div>
</div>
