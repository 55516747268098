<div mat-dialog-content>
  <mat-form-field style="width: 500px">
    <mat-label>Comment</mat-label>
    <input matInput [(ngModel)]="comments" />
  </mat-form-field>
</div>
<div style="justify-content: flex-end" mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button
    style="background-color: #2196f3; color: white"
    mat-button
    [mat-dialog-close]="comments"
    cdkFocusInitial
  >
    Confirm
  </button>
</div>
