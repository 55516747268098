import { Component, OnInit, Input } from '@angular/core';
import { BreadCrumb } from './breadcrumb.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.less']
})
export class BreadcrumbComponent implements OnInit {
    @Input() items: Array<BreadCrumb>;

  constructor() { }

  ngOnInit() {
  }

}
