import { Injectable, isDevMode } from "@angular/core";
import { IAppConfig } from "./models/appConfig";
import { environment } from "../environments/environment";

@Injectable()
export class AppConfig {
  static settings: IAppConfig;

  constructor() {}

  load() {
    return new Promise<void>((resolve, reject) => {
      AppConfig.settings = environment;
      resolve();
    });
  }
}
