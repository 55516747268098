<div class="container-fluid">
  Flowchart 2 works

  <!-- <div id="graph" #graph style="width: 1000px; height: 1000px"></div> -->
  <div *ngIf="!error" style="display: flex; gap: 15px; position: relative">
    <div
      echarts
      [options]="options"
      [loading]="this.loading"
      style="height: 90vh; flex: 1 1 75%"
      class="demo-chart"
      (chartInit)="chartInit($event)"
    ></div>
    <div style="width: 20%">
      filter
      <button nz-button nzType="primary" nzGhost (click)="this.makeGraph()">
        Refresh Graph
      </button>

      <nz-tabset>
        <nz-tab nzTitle="Jobs"
          >From here you can filter which jobs will be showing maybe? e.g.
          options: show QC jobs, show all jobs, show independant jobs, show all
          connections of a job? show nested connections of a job?
          <button
            nz-button
            nzType="primary"
            nzGhost
            (click)="hideSingletonJobs()"
          >
            Singleton/Standalone Jobs
          </button>

          <div>
            <nz-input-group [nzSuffix]="suffixIcon">
              <input
                type="text"
                nz-input
                placeholder="Search"
                [(ngModel)]="searchValue"
                (ngModelChange)="filterJobs()"
              />
            </nz-input-group>
            <ng-template #suffixIcon>
              <span nz-icon nzType="search"></span>
            </ng-template>

            <label
              nz-checkbox
              style="margin-left: 24px; width: 100%; padding: 4px 0px"
              [(ngModel)]="jobsSelectAll"
              [nzIndeterminate]="jobsSelectIndeterminate"
              (ngModelChange)="updateAllChecked()"
            >
              Check all
            </label>
            <nz-tree
              nzCheckable
              nzMultiple
              nzBlockNode
              nzVirtualHeight="520px"
              style="background-color: transparent"
              [nzData]="this.filteredJobs"
              (nzClick)="jobTreeActionEvent($event)"
              (nzCheckBoxChange)="jobTreeActionEvent($event)"
            ></nz-tree>
          </div>
        </nz-tab>
        <nz-tab nzTitle="Parameters"
          >Here you will be able to filter the parameters showing when you hover
          the jobs maybe?
          <div>
            <nz-input-group [nzSuffix]="suffixIcon">
              <input
                type="text"
                nz-input
                placeholder="Search"
                [(ngModel)]="searchValueParam"
                (ngModelChange)="filterParams()"
              />
            </nz-input-group>
            <ng-template #suffixIcon>
              <span nz-icon nzType="search"></span>
            </ng-template>
            <div style="height: 520px; overflow-y: scroll">
              <nz-tree
                nzCheckable
                nzMultiple
                nzBlockNode
                style="background-color: transparent"
                [nzData]="this.filteredParams"
                (nzClick)="paramTreeActionEvent($event)"
                (nzCheckBoxChange)="paramTreeActionEvent($event)"
              ></nz-tree>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>

    <div *ngIf="loading" class="loader-container">
      <div class="loader"></div>
    </div>
  </div>
  <div
    *ngIf="error"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      height: 500px;
      font-size: x-large;
      font-weight: 500;
    "
  >
    {{ error }}
  </div>
</div>
