import { Component, OnInit } from "@angular/core";
import { ProjectService } from "../../shared/services/project.service";
import { CachedUserService } from "../../shared/services/cached-user.service";
import { NzMessageService } from "ng-zorro-antd/message";

import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from "ng-zorro-antd/table";

interface ItemData {
  id: string;
  name: string;
  archive_enabled: Boolean;
  potential_project_savings: number;
  project_cost: number;
  job_costs: Array<any>;
}

interface JobItemData {
  job_id: string;
  job_name: string;
  soft_archive_status: any;
  keep_job: any;
  storage_cost: number;
  potential_savings?: number;
}

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<ItemData> | null;
  sortDirections: NzTableSortOrder[];
  listOfFilter?: NzTableFilterList;
  filterMultiple?: boolean;
  filterFn?: NzTableFilterFn<ItemData> | null;
  width?: string;
}

interface InnerColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<JobItemData> | null;
  sortDirections: NzTableSortOrder[];
  listOfFilter?: NzTableFilterList;
  filterMultiple?: boolean;
  filterFn?: NzTableFilterFn<JobItemData> | null;
  width?: string;
}

@Component({
  selector: "app-costs-analysis-dashboard",
  templateUrl: "./costs-analysis-dashboard.component.html",
  styleUrls: ["./costs-analysis-dashboard.component.css"],
})
export class CostsAnalysisDashboardComponent implements OnInit {
  listOfColumns: ColumnItem[] = [
    {
      name: "Name",
      sortOrder: null,
      sortFn: (a: ItemData, b: ItemData) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend", null],
    },
    {
      name: "Archiving Enabled",
      sortOrder: null,
      sortFn: (a: ItemData, b: ItemData) => {
        if (a.archive_enabled < b.archive_enabled) return -1;
        if (a.archive_enabled > b.archive_enabled) return 1;
        return 0;
      },
      sortDirections: ["ascend", "descend", null],
      width: "200px",
    },
    {
      name: "Project Costs",
      sortOrder: "descend",
      sortFn: (a: ItemData, b: ItemData) => {
        if (a.project_cost < b.project_cost) return -1;
        if (a.project_cost > b.project_cost) return 1;
        return 0;
      },
      sortDirections: ["ascend", "descend", null],
      width: "300px",
    },
  ];

  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();

  totalProjectsCost: number = 0;
  totalProjectsSavings: number = 0;

  isLoading: boolean = false;
  costsData: Array<any> = [];
  isStaff: boolean = false;

  getData() {
    this.totalProjectsCost = 0;
    this.totalProjectsSavings = 0;
    this.isLoading = true;
    this.projectSevice.getAllProjectsCosts().subscribe(
      (data) => {
        this.costsData = data.results.map((datum) => {
          this.totalProjectsCost += datum.project_cost;
          this.totalProjectsSavings += datum.potential_project_savings;
          return {
            ...datum,
            expand: false,
          };
        });
        this.setOfCheckedId = new Set<number>();
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  constructor(
    private projectSevice: ProjectService,
    private message: NzMessageService,
    private userService: CachedUserService
  ) {
    this.userService.userDetail.subscribe((d) => {
      this.isStaff = d && d.isStaff;
      this.getData();
    });
  }
  ngOnInit(): void {}

  enableArchiving(project: ItemData) {
    this.isLoading = true;
    this.projectSevice
      .setProjectArchiving({
        projectIds: [project.id],
        archive_enabled: true,
      })
      .subscribe(
        (res) => {
          this.message.success(`Archiving enabled for ${project.name}`);
          this.isLoading = false;
          this.getData();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }
  disableArchiving(project: ItemData) {
    this.isLoading = true;
    this.projectSevice
      .setProjectArchiving({
        projectIds: [project.id],
        archive_enabled: false,
      })
      .subscribe(
        (res) => {
          this.message.success(`Archiving disabled for ${project.name}`);
          this.isLoading = false;
          this.getData();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }

  enableArchivingAllSelected() {
    const arrayOfCheckedIds = Array.from(this.setOfCheckedId);
    this.projectSevice
      .setProjectArchiving({
        projectIds: arrayOfCheckedIds,
        archive_enabled: true,
      })
      .subscribe(
        (res) => {
          this.message.success(`Archiving enabled for all selected`);
          this.isLoading = false;
          this.getData();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }
  disableArchivingAllSelected() {
    const arrayOfCheckedIds = Array.from(this.setOfCheckedId);
    this.projectSevice
      .setProjectArchiving({
        projectIds: arrayOfCheckedIds,
        archive_enabled: false,
      })
      .subscribe(
        (res) => {
          this.message.success(`Archiving disabled for all selected`);
          this.isLoading = false;
          this.getData();
        },
        (err) => {
          this.isLoading = false;
          console.error(err);
          this.message.error(`An error occured.`);
        }
      );
  }

  // functions for ng-zorro table selection
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  onCurrentPageDataChange(): void {
    // this.costsData = costsData;
    this.refreshCheckedStatus();
  }
  refreshCheckedStatus(): void {
    const listOfEnabledData = this.costsData.filter(
      ({ disabled }) => !disabled
    );
    this.checked = listOfEnabledData.every(({ id }) =>
      this.setOfCheckedId.has(id)
    );
    this.indeterminate =
      listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) &&
      !this.checked;
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  onAllChecked(checked: boolean): void {
    this.costsData
      .filter(({ disabled }) => !disabled)
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }
}
