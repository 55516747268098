<div class="container-fluid">
  <div *ngIf="isLoading" class="loading">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="loading-container">
              <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner><div class="text">Loading...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!!currentJob && !isLoading" [@fadeInOut]>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-horizontal">
                <div *ngFor="let category of getObjKeys(globalInfo)">
                  <div class="row">
                    <h5 class="col-sm-9">{{category}}</h5>
                  </div>
                  <div class="form-group" *ngFor="let param of getObjKeys(globalInfo[category])">
                    <label class="col-sm-5">{{param}}:</label>
                    <span class="col-sm-6" *ngIf="currentCosts">{{globalInfo[category][param]}}</span>
                  </div>
                </div>
                <h4>Time Stepping</h4>
                <div class="form-group">
                  <label class="col-sm-5">Sample interval:</label>
                  <span class="col-sm-6" *ngIf="currentCosts">{{currentCosts.timeSample}}</span>
                </div>
                <div class="form-group">
                  <label class="col-sm-5">Number of Time Steps:</label>
                  <span class="col-sm-6" *ngIf="currentCosts">{{currentCosts.timeSteps}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-horizontal">
                <div *ngIf="blkInfo.length > 0">
                  <h4>Iteration Blocks</h4>
                  <div class="form-group">
                    <mat-form-field class="col-sm-7">
                        <mat-label>Block Number</mat-label>
                        <mat-select [(ngModel)]="selectedBlk">
                          <mat-option *ngFor="let blk of blkInfo; let number = index" [value]="blk">
                            {{number+1}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngFor="let category of getObjKeys(selectedBlk)">
                    <div class="row">
                      <h5 class="col-sm-9">{{category}}</h5>
                    </div>
                    <div class="form-group" *ngFor="let param of getObjKeys(selectedBlk[category])">
                      <label class="col-sm-5">{{param}}:</label>
                      <span class="col-sm-6" *ngIf="currentCosts">{{selectedBlk[category][param]}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="foot-note"> * : parameter not specified in runfile </span>


  </div>
</div>
