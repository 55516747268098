<div>
  <div class="rule-box" *ngIf="rules.size> 0">
    <div class="rule" *ngFor="let rule of rules | mapkey; let i = index">
      <i class="fa fa-trash" style="cursor:pointer;" (click)="deleteRule(rule)"></i>
      <span>{{displayRule(rule)}}</span>
    </div>
  </div>

  <div class="row">
    <div class="search-block col-md-8">
      <form>
          <mat-form-field class="search-full-width">
            <mat-label class="search-input">Add Rules</mat-label>
            <input  class="search-input" [formControl]='keywordsCtrl' [matAutocomplete]="auto" matInput [(ngModel)]="inputRule" name="inputRule">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let keyword of filteredKeywords | async" [value]="keyword">
                  <span>{{ keyword }}</span>
                </mat-option>
              </mat-autocomplete>
          </mat-form-field>
      </form>
      <p *ngIf="errorInput" class="error-warning">
        Invalid Input Format
      <p>
    </div>
    <i class="col-md-2 zmdi zmdi-plus-square zmdi-hc-3x add-button" (click)="addRule()"></i>
  </div>
  <div class="row">
    <div class="col-md-2">
      <button mat-raised-button color="primary" class="search-button" (click)="search()">Search</button>
    </div>
    <div class="col-md-2" *ngIf="(resultGroups.length > 0 || matchedJobs.length > 0) && !isLoading">
      <button class="open-all" mat-raised-button (click)="openSelectedJobs()" color="primary">Open Selected Jobs</button>
    </div>
    <div class='col-md-4' style="margin-top: 1%;">
      <mat-radio-group aria-label="Sort" [(ngModel)]='sortCriteria' (change)="search()">
        <mat-radio-button value="_score">Relevence</mat-radio-button>
        <mat-radio-button value="created">Created Time</mat-radio-button>
      </mat-radio-group>
     </div>
  </div>
  <div class="loading-container search-loading" *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner><div class="text"></div>
  </div>
  <div class="empty-message" *ngIf="afterSearch && matchedJobs.length == 0 && resultGroups.length == 0 && !isLoading">
    <h3>{{errorMessage}}</h3>
  </div>
  <div class="result-block" *ngIf="!isLoading">
    <div class="container" data-os-animation="fadeIn" data-os-animation-delay="0">
      <h3 *ngIf="isSuggestion && !afterSearch">Recent Searches</h3>
      <div class="row">
        <div class="spc accordion">
          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div *ngIf='currentPageNum == 1' >
              <div role="tab" *ngFor="let project of resultGroups; let i = index">
                <p class="heading">
                  <a role="button" class="collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapse-1293-1" aria-expanded="false" (click)="clickProject(project)">
                    <div class="row">
                      <p class="heading col-md-10">{{project.name}}</p>
                      <button mat-raised-button class="col-xm-2" color="primary" style="margin-top: 5px;" (click)='openSearchGroup(project)'>Open</button>
                      <button mat-raised-button class="col-xm-2" color="warn" style="margin-top: 5px;" (click)='deletSearchGroup($event, project)'>Delete</button>
                    </div>
                    <div class="text" *ngIf="!project.rules"><p><em>{{project.description}}</em></p></div>
                    <div class="text row" *ngIf='project.rules'>
                      <div class="col-md-10" style="overflow-x: auto;">
                        <p><em>Search Rules: {{displayRules(project.rules)}}</em></p>
                      </div>
                      <button mat-raised-button class="col-xm-2" color="info" (click)='importRules($event, project.rules)'>Add</button>
                    </div>
                    <app-selection-list [@slideIn] [hidden]="!isActiveProject(project.id)" [id]="project.id" [list]="getListFromJobs(project.jobs)" [selectedItems]=
                    "selectedJobs[project.id]" (selected)="onGroupJobSelected($event)"></app-selection-list>
                  </a>
                </p>
              </div>
            </div>
            <div role="tab" *ngFor="let job of matchedJobs; let i = index">
              <p class="heading">
                <a role="button" class="collapsed" data-parent="#accordion" data-target="#collapse-1293-1" aria-expanded="false" (click)='onJobClicked(job)'>
                  <div class="row" >
                    <p class="heading col-md-10">{{job.name}}</p>
                    <div class="col-md-1">
                      <mat-checkbox [checked]="selectedJobs.indexOf(job.id) > 0" (click)="onJobSelected($event, job.id)"></mat-checkbox>
                    </div>
                  </div>
                  <div class="text"><p><em>{{displayRunfileValue(job.id)}}</em></p></div>
                  <div [@slideIn] [hidden]="!isActiveProject(job.id)">
                    <mat-card appearance="outlined">
                      <mat-card-content>
                        <div class="form-horizontal">
                          <div class="form-group">
                            <label class="col-sm-5">Comments:</label>
                            <span class="col-sm-6">{{job.description}}</span>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-5">Project:</label>
                            <span class="col-sm-6">{{job.project}}</span>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-5">Project Description:</label>
                            <span class="col-sm-6">{{job.projectDescription}}</span>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-5">Prefix:</label>
                            <span class="col-sm-6">{{job.prefix}}</span>
                          </div>
                           <div class="form-group">
                            <label class="col-sm-5">Iteration:</label>
                            <span class="col-sm-6">{{job.iteration}}</span>
                          </div>
                           <div class="form-group">
                            <label class="col-sm-5">Last Modified Time:</label>
                            <span class="col-sm-6">{{job.createdDate}}</span>
                          </div>
                           <div class="form-group">
                            <label class="col-sm-5">Status:</label>
                            <span class="col-sm-6">{{job.status}}</span>
                          </div>
                           <div class="form-group">
                            <label class="col-sm-8"></label>
                            <button mat-raised-button class="col-xm-2" color="primary" (click)='openJob($event, job)'>Open</button>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 5%">
      <div class="col-md-6 roll index-block">
        <button mat-button color="primary" class="col-xm-1 index" *ngFor="let i of pageNumOnDisplay" (click)="jumpPage(i)" [disabled]="i==currentPageNum">{{i}}</button>
      </div>
    </div>
  </div>
</div>
