<div>
  <div class="projects" *ngIf="projects">
    <mat-form-field>
      <input type="text" placeholder="Project" matInput [formControl]="projectControl" [matAutocomplete]="autoProject">
      <mat-autocomplete #autoProject="matAutocomplete" (optionSelected)="projectSelected($event)">
        <mat-option *ngFor="let project of filteredOptions | async" [value]="project.name">
          {{project.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="loading-container" *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner><div class="text"></div>
  </div>

  <mat-card appearance="outlined" *ngIf="scores && scores.length>0">
    <mat-card-content>
      <h4>Options</h4>

      <section class="example-section">
        <label>Score:</label>
        <mat-radio-group [(ngModel)]="selectedScore" (ngModelChange)="updateDataSource()">
          <mat-radio-button [value]="'project'">Project</mat-radio-button>
          <mat-radio-button [value]="score" *ngFor="let score of scoreHeaders">{{score}}</mat-radio-button>
        </mat-radio-group>
      </section>
      <section class="example-section">
        <label>Grouping:</label>
        <mat-radio-group [(ngModel)]="grouping" (ngModelChange)="updateDataSource()">
          <mat-radio-button value="avg">Average</mat-radio-button>
          <mat-radio-button value="std">Standard Deviation</mat-radio-button>
          <mat-radio-button value="shots">Score Per Shot</mat-radio-button>
        </mat-radio-group>
      </section>
      <section class="example-section">
        <label>Iteration:</label>
        <div class="iterations">
          <mat-slider [max]="maxIteration" [min]="minIteration" [(ngModel)]="selectedIteration" (ngModelChange)="updateDataSource()"><input matSliderThumb /></mat-slider>
          <mat-form-field>
            <mat-select [(ngModel)]="selectedIteration" (ngModelChange)="updateDataSource()">
              <mat-option *ngFor="let iteration of iterations" [value]="iteration">
                {{iteration}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="grouping=='shots'">
          <label>Shot ID:</label>
          <div class="iterations">
            <mat-slider [max]="maxShot" [min]="minShot" [(ngModel)]="selectedShot" (ngModelChange)="updateDataSource()" [step]='shotStep'><input matSliderThumb /></mat-slider>
            <mat-form-field>
              <mat-select [(ngModel)]="selectedShot" (ngModelChange)="updateDataSource()">
                <mat-option *ngFor="let shot of availableShots.get(selectedIteration)" [value]="shot">
                  {{shot}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

      </section>
      <section class="example-section">
        <label>Settings:</label>
        <mat-form-field *ngIf="settingOptions" style="width:250px;">
          <mat-select [(ngModel)]="selectedOption" (ngModelChange)="settingSelected($event)">
            <mat-option *ngFor="let option of settingOptions" [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <div *ngFor="let selectedSetting of selectedSettings; let i = index">
            <i class="fa fa-trash" style="cursor:pointer;" (click)="deleteSetting(selectedSetting)"></i>
            <span>{{selectedSetting}}</span>
            <div style="display:inline-block;">
              <mat-form-field style="width:200px">
                <mat-select [(ngModel)]="additionalSetting[selectedSetting]" (ngModelChange)="updateDataSource()" multiple>
                  <mat-option *ngFor="let setting of settings[selectedSetting]" [value]="setting">
                    {{setting}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </section>
    </mat-card-content>
  </mat-card>

</div>

<div class="table-container" *ngIf="selectedSettings && selectedSettings.length==1">
  <table class="table">
    <thead>
      <tr>
        <th>{{selectedSettings[0]}}</th>
        <th>Project</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let settingOne of settings[selectedSettings[0]]">
        <td>{{settingOne}}</td>
        <td>
          <a *ngFor="let project of getScoreSingle(settingOne)" [routerLink]="['/projects/' + project.jobId]" target="_blank" style="display:block" [title]="project.name">{{project.score}}{{getPercentDrop(project)}} <small *ngIf="selectedScore!='project'">({{trim_name(project.name)}})</small></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="table-container"  *ngIf="selectedSettings && selectedSettings.length>1 && settings[selectedSettings[1]]">
  <table class="table">
    <thead>
      <tr>
        <th rowspan="2">{{selectedSettings[0]}}</th>
        <th [attr.colspan]="settings[selectedSettings[1]].length">{{selectedSettings[1]}}</th>
      </tr>
      <tr>
        <th *ngFor="let setting of settings[selectedSettings[1]]">{{setting}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let settingOne of settings[selectedSettings[0]]">
        <td>{{settingOne}}</td>
        <td *ngFor="let settingTwo of settings[selectedSettings[1]]">
          <a *ngFor="let project of getScores(settingOne,settingTwo)" [routerLink]="['/projects/' + project.jobId]" target="_blank" style="display:block" [title]="project.name">{{project.score}}{{getPercentDrop(project)}} <small *ngIf="selectedScore!='project'">({{trim_name(project.name)}})</small></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="row" style="margin-top:20px;">
  <div class="col-md-6" *ngIf="jobsLoadedText">
    <div style="font-size:90%;font-weight:bold;">Jobs visible:</div>
    <div>{{jobsLoadedText}}</div>
  </div>
  <div class="col-md-6" *ngIf="jobsFailedText">
    <div style="font-size:90%;font-weight:bold;">Jobs not available:</div>
    <div>{{jobsFailedText}}</div>
  </div>
</div>
<div class="footer-jobs" *ngIf="selectedSettings && selectedSettings.length>0">
  <a mat-raised-button [routerLink]="['/projects/', selectedJobs[0]]" [queryParams]="{jobs: selectedJobs}" color="primary" target="_blank">Open Selected Jobs</a>
</div>
