import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CacheService } from "../../shared/services/cache.service";
import { PaletteService } from "./palette.service";

@Injectable()
export class CachedPaletteService {
  constructor(
    private paletteService: PaletteService,
    private cacheService: CacheService
  ) {}

  getPalettes(): Observable<string[]> {
    return this.cacheService.get("palettes", this.paletteService.getPalettes());
  }
}
