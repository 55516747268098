<div>
  <app-proj-sidebar></app-proj-sidebar>
  <div
    class="container-fluid"
    style="padding-left: 85px; display: flex; justify-content: space-between"
  >
    <div style="flex-grow: 1">
      <h6 class="project-title">
        {{ project_name }}
      </h6>
      <small>
        {{ project_description }}
      </small>
    </div>

    <button nz-button nzType="default" (click)="add_job_modal = true">
      <span nz-icon nzType="plus" nzTheme="outline"></span>
    </button>
  </div>
  <router-outlet></router-outlet>

  <nz-modal
    [(nzVisible)]="add_job_modal"
    [nzTitle]="'Add Job to project ' + this.project_name"
    (nzOnCancel)="add_job_modal = false"
    (nzOnOk)="addJobsToProject()"
  >
    <ng-container *nzModalContent>
      <nz-spin [nzSpinning]="isLoading">
        Provide all the paths for the jobs you would like to add:
        <ng-container *ngFor="let entry of this.job_entries">
          <div style="display: flex">
            <nz-input-group style="padding: 0" class="legacy-input-styling">
              <label style="left: 10px"> Job Base Path </label>
              <input nz-input [formControl]="entry.jobpath" />
            </nz-input-group>

            <nz-input-group class="legacy-input-styling">
              <label> Parent Job </label>
              <nz-select style="width: 100%" [formControl]="entry.parent">
                <nz-option nzLabel="No Parent Job"></nz-option>
                <nz-option
                  *ngFor="let job of this.parentJobs"
                  [nzLabel]="job.job_name"
                  [nzValue]="job.id"
                ></nz-option>
              </nz-select>
            </nz-input-group>
          </div>
        </ng-container>

        <button nz-button nzType="dashed" nzBlock (click)="addEntry()">
          Add
        </button>
      </nz-spin>
    </ng-container>
  </nz-modal>
</div>
