import { Injectable } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { JobDetail } from "../../models/jobDetail";
import { ModelType } from "../../models/modelType";
import { StoredSetting } from "../../models/storedSetting";
export interface SliceSettings extends StoredSetting {
  // shotId: number;
  inline: number;
  crossline: number;
  depth: number;
  // startTrace: number;
  // endTrace: number;
}
@Injectable()
export class SliceSettingsService {
  shotTypes = [
    {
      name: "lowpass-B4",
      ttrName: "lowpass-B4",
      numPanel: 2,
      displayName: "Raw",
      fwd: 1,
    },
    {
      name: "lowpass",
      ttrName: "lowpass",
      numPanel: 2,
      displayName: "Lowpass",
      fwd: 1,
    },
    { name: "agc", ttrName: "agc", numPanel: 3, displayName: "AGC", fwd: 1 },
    {
      name: "RLsmt",
      ttrName: "RLsmt",
      numPanel: 3,
      displayName: "REC Line Smooth",
      fwd: 1,
    },
    {
      name: "compare",
      ttrName: "compare",
      numPanel: 3,
      displayName: "Compare",
      fwd: 1,
    },
    {
      name: "res",
      ttrName: "res",
      numPanel: 1,
      displayName: "Residual",
      fwd: 1,
    },
    { name: "adj", ttrName: "adj", numPanel: 1, displayName: "Adjoint" },
    {
      name: "rwicut",
      ttrName: "rwicut",
      numPanel: 3,
      displayName: "RWI Cut",
      fwd: 1,
    },
    {
      name: "rwicut-B4",
      ttrName: "rwicut-B4",
      numPanel: 3,
      displayName: "RWI Cut Before",
      fwd: 1,
    },
    {
      name: "matchfilt",
      ttrName: "matchfilt",
      numPanel: 1,
      displayName: "Match Filter",
      fwd: 1,
    },
    {
      name: "primaries",
      ttrName: "primaries",
      numPanel: 1,
      displayName: "Primaries",
      fwd: 1,
    },
    {
      name: "multiples",
      ttrName: "multiples",
      numPanel: 1,
      displayName: "Multiples",
      fwd: 1,
    },
    {
      name: "matchmults",
      ttrName: "matchmults",
      numPanel: 1,
      displayName: "Multiples Matched",
      fwd: 1,
    },
    {
      name: "direct-arrival",
      ttrName: "direct-arrival",
      numPanel: 1,
      displayName: "Direct Arrival",
      fwd: 1,
    },
    {
      name: "fullfield",
      ttrName: "fullfield",
      numPanel: 1,
      displayName: "Full Field",
      fwd: 1,
    },
    {
      name: "fullpred",
      ttrName: "fullpred",
      numPanel: 1,
      displayName: "Full Predicted",
      fwd: 1,
    },
    {
      name: "1d",
      ttrName: "compare",
      numPanel: 1,
      displayName: "1D Dynamic",
      fwd: 1,
      filterName: "1d",
      filterSettings: {
        filter_type: "raw",
        noise: 0.01,
        lag_max: 2000,
        normalise: "True",
        lowpass_on: "True",
        lowpass_cutoff: 4.5,
        lowpass_order: 6,
        t_scale: 0.01,
        t_type: "Gaussian",
        wmin: "True",
      },
      options: [
        {
          name: "filter_type",
          displayName: "Filter Type",
          values: ["raw", "adjoint", "residual", "check"],
        },
        { name: "noise", displayName: "Noise" },
        { name: "lag_max", displayName: "Lag Max" },
        {
          name: "normalise",
          displayName: "Normalise",
          values: ["True", "False"],
        },
        {
          name: "lowpass_on",
          displayName: "Lowpass",
          values: ["True", "False"],
        },
        { name: "lowpass_cutoff", displayName: "Lowpass Cutoff" },
        { name: "lowpass_order", displayName: "Lowpass Order" },
        { name: "t_scale", displayName: "T Scale" },
        {
          name: "t_type",
          displayName: "T Type",
          values: ["Gaussian", "Linear", "Exp"],
        },
        { name: "wmin", displayName: "wmin", values: ["True", "False"] },
      ],
    },
    {
      name: "2d",
      ttrName: "compare",
      numPanel: 1,
      displayName: "2D Dynamic",
      fwd: 1,
      hideAxes: true,
      filterName: "2d",
      filterSettings: {
        filter_type: "raw",
        sample_interval: 10,
        lag_t_max: 150,
        lag_x_max: 200,
        noise: 0.01,
        t_scale_x: 0.1,
        t_scale_t: 0.3,
        t_type: "Gaussian",
        wmin: "True",
      },
      options: [
        {
          name: "filter_type",
          displayName: "Filter Type",
          values: ["raw", "adjoint", "residual"],
        },
        { name: "sample_interval", displayName: "Sample Interval" },
        { name: "lag_t_max", displayName: "Lag t_max" },
        { name: "lag_x_max", displayName: "Lag x_max" },
        { name: "noise", displayName: "Noise" },
        { name: "t_scale_x", displayName: "T Scale x" },
        { name: "t_scale_t", displayName: "T Scale t" },
        {
          name: "t_type",
          displayName: "T Type",
          values: ["Gaussian", "Spike"],
        },
        { name: "wmin", displayName: "wmin", values: ["True", "False"] },
      ],
    },
    {
      name: "RcvrList",
      ttrName: "rcvrlist",
      numPanel: 3,
      displayName: "Spatial Plotting",
      fwd: 1,
      filterSettings: {
        freq: 3,
        offset: 2000,
        angles: "0, 90, 180, 270",
        angle_offset: 25,
        value: "freq",
        interval: 10,
      },
      options: [
        {
          name: "value",
          displayName: "Receiver Value",
          values: ["freq", "res", "corr"],
        },
        { name: "freq", displayName: "frequency" },
        { name: "offset", displayName: "Offset" },
        { name: "angles", displayName: "Angles", type: "text" },
        { name: "angle_offset", displayName: "Angle Offset" },
        { name: "interval", displayName: "Interval" },
      ],
    },
    {
      name: "custom",
      ttrName: "compare",
      numPanel: 1,
      displayName: "Custom Dynamic",
      fwd: 1,
    },
    {
      name: "custom_type",
      ttrName: "custom",
      numPanel: 3,
      displayName: "Custom Shot Type",
      options: null,
    },
  ];
  modelTypes: ModelType[] = [
    { id: "Vp", name: "Vp" },
    { id: "Gradient", name: "Gradient" },
    { id: "InvVp Update", name: "InvVp Update" },
  ];

  constructor(private router: Router) {}
  getSettingsFromQueryParams(params: ParamMap, jobDetail: JobDetail) {
    let settings = {
      inline: +(params.get("inline") || jobDetail.defaultNx1),
      crossline: +(params.get("crossline") || jobDetail.defaultNx2),
      depth: +(params.get("depth") || jobDetail.defaultNx3),
      iteration: +params.get("iteration"),
      // shotId: +params.get("shotId"),
      // startTrace: +params.get("startTrace"),
      // endTrace: +params.get("endTrace"),
    } as SliceSettings;

    settings.iteration = Math.min(
      settings.iteration,
      jobDetail.iterationsComplete
    );

    return settings;
  }
  setQueryStringParams(sliceSettings: SliceSettings, route: ActivatedRoute) {
    let newParams: SliceSettings = {
      inline: sliceSettings.inline,
      crossline: sliceSettings.crossline,
      depth: sliceSettings.depth,
      iteration: sliceSettings.iteration,
      // shotId: sliceSettings.shotId,
      // startTrace: sliceSettings.startTrace,
      // endTrace: sliceSettings.endTrace,
    } as SliceSettings;

    this.router.navigate(["."], {
      relativeTo: route,
      queryParams: newParams,
      queryParamsHandling: "merge",
    });
  }
  setDefaults(sliceSettings: SliceSettings, jobDetail: JobDetail) {
    if(!sliceSettings){
      sliceSettings = {} as SliceSettings;
    }
    sliceSettings.inline = +(sliceSettings.inline || jobDetail.defaultNx1);
    sliceSettings.crossline = +(
      sliceSettings.crossline || jobDetail.defaultNx2
    );
    sliceSettings.depth = +(sliceSettings.depth || jobDetail.defaultNx3);
    sliceSettings.iteration = Math.min(
      sliceSettings.iteration || 0,
      jobDetail.iterationsComplete
    );

    return sliceSettings;
  }

  setQueryParams(route: ActivatedRoute, params: any) {
    const validParams = Object.keys(params)
      .filter((key) => !!params[key])
      .reduce((obj, key) => {
        obj[key] = params[key];
        return obj;
      }, {});
    this.router.navigate(["."], {
      relativeTo: route,
      queryParams: validParams,
      queryParamsHandling: "merge",
    });
  }
}
