import { Component, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CachedProjectService } from "../../../../shared/services/cached-project.service";
import { take, takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import { ProjectService } from "../../../../shared/services/project.service";
import { Field } from "../../../../models/field";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
  selector: "app-project-information",
  templateUrl: "./project-information.component.html",
  styleUrls: ["./project-information.component.less"],
})
export class ProjectInformationComponent implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1); // used to unsubscribe to all subscriptions on destroy
  projectInfoControl: FormGroup = this.fb.group({
    description: this.fb.control("", []),
    field: this.fb.control({}, [Validators.required]),
    name: this.fb.control("", [Validators.required]),
  });
  fields: Field[] = [];
  isLoading: boolean = false;

  constructor(
    private notificationService: NzNotificationService,
    private cachedProjectService: CachedProjectService,
    private fb: FormBuilder,
    private projectService: ProjectService
  ) {
    this.cachedProjectService.currentProject
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res) {
          this.projectInfoControl.setValue({
            description: res?.description || "",
            field: res.field,
            name: res.name,
          });
        }
      });

    this.projectService
      .getAllFields()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.fields = res.fields;
        },
        error: (err) => {
          console.log(err);
          this.notificationService.error(
            "Error",
            err.error?.error || "An error occurred while getting all the fields"
          );
        },
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  projectInformationSubmit() {
    console.log("submit project information form", this.projectInfoControl);
    if (this.isLoading) {
      return;
    }
    if (!this.projectInfoControl.valid) {
      let errors = this.getFormErrors(this.projectInfoControl);
      this.notificationService.error(
        "Error",
        "Please check the form for error on fields: " +
          Object.keys(errors).join(", ")
      );
      return;
    }

    this.isLoading = true;
  }

  fieldCompareFn = (o1: Field, o2: Field): boolean =>
    o1 && o2 ? o1.id === o2.id : o1 === o2;

  getFormErrors(form: any) {
    if (!form.controls) {
      // Return FormControl errors or null
      return form.errors ?? null;
    }
    const groupErrors = form.errors;
    const formErrors = groupErrors ? { groupErrors } : {};
    Object.keys(form.controls).forEach((key) => {
      const error = this.getFormErrors(form.get(key));
      if (error !== null) {
        formErrors[key] = error;
      }
    });
    return Object.keys(formErrors).length > 0 ? formErrors : null;
  }
}
