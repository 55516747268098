<h3 mat-dialog-title>Warning</h3>
<div
  mat-dialog-content
  style="
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  "
>
  <div>
    <div *ngIf="keep_job">
      You are marking
      <strong>{{ job_name }}</strong>
      to be <strong>not kept</strong>. <br />
      <div>
        This means it will be cleaned up based on the defined scheme for its job
        type, and can't be recovered easily. <br />
        All files in this job will be deleted except:
        <ul>
          <li>
            Velocity(Vp/Vs) and Density from CP0, CP1 and Final CP of each
            iteration block.
          </li>
          <li>Final CP of the job.</li>
          <li>
            For each RWI original block, first and last background CP and first
            and last Virtual Source CP are kept(all model files).
          </li>
          <li>
            For each RWI reloaded block, last two CP's are kept(all model
            files).
          </li>
          <li>
            For each CP kept, we retain only lowpass-B4 and compare files for
            half of the csref files (if more than 5 dumped).
          </li>
        </ul>
        These retained files will still allow you to view the job whenever
        needed.
      </div>
      <br />
      Do you confirm this action?
    </div>
    <div *ngIf="!keep_job">
      You are marking
      <strong>{{ job_name }}</strong>
      to be <strong>kept</strong>. <br />
      This means it will not be cleaned up based on the defined scheme for its
      job type. <br />
      Do you confirm this action?
    </div>
  </div>
  <div style="display: flex; justify-content: space-around">
    <button mat-raised-button color="primary" type="submit" (click)="submit()">
      Confirm
    </button>
    <button mat-raised-button color="basic" type="reset" (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
