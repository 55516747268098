import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation, Output, EventEmitter, HostListener, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import {debounce} from 'lodash'
import * as d3 from 'd3';
import { ShotType } from '../../../../models/shotType';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-shots-graph',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './shots-graph.component.html',
  styleUrls: ['./shots-graph.component.css']
})
export class ShotsGraphComponent implements OnInit, OnDestroy, OnChanges {
  @Input() hideAxes: boolean = false
  @Input() colorMap: string[] = ['black', 'grey', 'white']
  @Input() set radius(value:number){
    this._radius = value;
    this.drawAfter(50);
  }
  @Input() set shotData(value: {x: number, y:number, shotId: number, hasTraceFile: boolean, traces_fit: number}[]) {
    this._shotData = value;
 
    this.drawAfter(50);
  }

  @Input() set rcvrData(value: {x: number, y:number, shotId: number, hasTraceFile: boolean, traces_fit: number}[]) {
    this._rcvrData = value;

    this.drawAfter(50);
  }

  @Input() shotType: ShotType;

  @Input() set plotRcvrs(value: boolean) {
    this._plotRcvrs = value;

    this.drawAfter(50);
  }


  @Input() set backgroundImage (value: string) {
    this._backgroundImage = value;

    this.drawAfter(50);
  }
  @Input() set height(value: number) {
    this._height =value;
  
    this.drawAfter(50);
  }
  @Input() modelScale: {x: number, y: number}
  @Input() set selectedShot(value: number) {
      this._selectedShot = value;
      this.drawAfter(50);  
  }
  @Input() set colourType(value: string) {
    this._colourType = value;
  
    this.drawAfter(50);
  }
  @Input() set colorScale(value: {min: number, max: number}) {
    this.minTraceFit = value.min;
    this.maxTraceFit = value.max;

    this.drawAfter(50);
  }

  @Input() isMainImage: boolean = false;

  @ViewChild('graph', { static: true }) graph: ElementRef;
  @ViewChild('graphContainer', { static: true }) container: ElementRef;
  @Output('onShotSelected') shotSelection: EventEmitter<number> = new EventEmitter<number>();
  @Output('traceFit') traceFit: EventEmitter<number> = new EventEmitter<number>();

  private width: number;
  private _height: number = 200;
  private _shotData:  {x: number, y:number, shotId: number, hasTraceFile: boolean, traces_fit: number}[];
  private _rcvrData:  {x: number, y:number, shotId: number, hasTraceFile: boolean, traces_fit: number}[];
  private _backgroundImage: string
  private _selectedShot: number;
  private _colourType: string;
  private svg;
  private updateDelay;
  private minTraceFit = 0;
  private maxTraceFit = 100;
  private _plotRcvrs = false;
  padding = {left:30, bottom:30, top: 15}
  _radius=3;

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.resizeWithDelay(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  private resizeWithDelay = debounce((event) => {
    this.updateGraph();
  }, 100);

  updateGraph() {
    // Remove previous shot graph
    if(this.svg) {
      this.svg.remove();
    }

    if (!this._shotData) return;


    // if(this._selectedShot &&
    //    !(this._shotData.filter(s => s.hasTraceFile).map(s => s != null? s.shotId : null).includes(this._selectedShot))) {
    //   this._selectedShot = null;
    // }
    this.width = this.container.nativeElement.offsetWidth;
    // calculate the sizes of svg and scales for shot points
    this.draw(this._shotData, this._rcvrData);
  }

  private draw(data, rcvrData?) {  
    let xScale = d3.scaleLinear()
                   .domain([0, this.modelScale.x])
                   .range([this.padding.left, this.width]);

    let domain = [0, this.modelScale.y];
    if (domain[1] < 0) {
      domain = [-domain[1], domain[1]*0.03]
    }
    let yScale = d3.scaleLinear()
                   .domain(domain)
                   .range([this._height - this.padding.bottom, this.padding.top]);
    let colourScale = d3.scaleLinear()
                        .domain([this.minTraceFit, (this.maxTraceFit+this.minTraceFit)/2, this.maxTraceFit])
                        .range(this.colorMap);
    
    let kilometerFormatter = d3.format(".1f");
    
    let xAxis = d3.axisBottom(xScale)
      .ticks(this.hideAxes ? 0 : 5)
      .tickSize(5)
      .tickPadding(5)
      .tickFormat(function(d) {
        return kilometerFormatter(d / 1000); // Convert meters to kilometers
      });

    let yAxis = d3.axisLeft(yScale)
      .ticks(this.hideAxes ? 0 : 3)
      .tickSize(5)
      .tickPadding(5)
      .tickFormat(function(d) {
        return kilometerFormatter(d / 1000); // Convert meters to kilometers
      });

    let step = 255 / (this.maxTraceFit - this.minTraceFit)
    let color = (value) => {
      let c = Math.round( (value - this.minTraceFit) * step);
      if (c < 0) {
        c = 0;
      }
      if (c > 255) {
        c = 255;
      }
      return c;
    }

    let svg = d3.select(this.graph.nativeElement)
                .attr("width", this.width)
                .attr("height", this._height)
                .append("g").attr("id", "svg");
    this.svg = svg;

    // Background image
    svg.append("g").attr("id", "bgImage")
       .append("image")
       .attr("transform", `translate(${this.padding.left},${this.padding.top})`)
       .attr("width", this.width - this.padding.left).attr("height", this._height - this.padding.bottom - this.padding.top)
       .attr('preserveAspectRatio', 'none')
       .attr("xlink:href", this._backgroundImage)
       .attr('fill', 'white')
       .attr('opacity',0.3);


    let colorType = this._colourType;
    let distinguishColor = this.colorMap.includes('red') ? 'black' : 'red'
    // Shot points
    let radius = this._radius;
    let shots = svg.append("g").attr("id", "circles");
    let isMainImage = this.isMainImage;
    shots.selectAll("circle").data(data).enter().append("circle")
         .attr("cx", function(d) {return xScale(d.x)})
         .attr("cy", function(d) {return yScale(d.y)})
         .attr("r",  function(d) {
          let r = radius != null ? radius : 3;
          return d.hasTraceFile? r+2: r;})
         .attr("fill", function(d) {
          if (isMainImage) {
            return colourScale(d.traces_fit);
          } else {
            let grayscale = color(d[colorType])
            let resultColor = `rgb(${grayscale},${grayscale},${grayscale})`;
            return resultColor;
          }
         }).attr("stroke", function(d) { return d.hasTraceFile?distinguishColor:'none'})
          .attr("stroke-width", 2)
         .classed("selected", (d) => false)
         .classed("selected", (d)=>{return d.shotId == this._selectedShot})
         .on("mouseover", function(event, d) {
            d3.select(this)
              .attr("r", function(d) {
                let r = radius != null ? radius : 3;
                return d.hasTraceFile? r+5 : r;
              }).style("cursor", "pointer");
          })
         .on("mouseout", function(event, d) {
            d3.select(this)
              .attr("r", function(d) {
                let r = radius != null ? radius : 3;
                return d.hasTraceFile? r+2 : r;
              });
          })
         .on("click", (event, d) => {
            this._selectedShot = d.shotId;
            let id = d.shotId
            shots.selectAll("circle").classed("selected", (d)=>d.shotId == id);
            this.shotSelection.emit(d.shotId);
         });
    

    if (rcvrData && this._plotRcvrs) {
      console.log(rcvrData)
      shots.append("g").attr("id", "rcvrCircles")
         .selectAll("circle").data(rcvrData).enter().append("circle")
         .attr("cx", function(d) {return xScale(d.x)})
         .attr("cy", function(d) {return yScale(d.y)})
         .attr("r",  function(d) {return 1.5})
         .attr("fill", function(d) {
           if (d.hasTraceFile) {
             return 'orange';
           }
           return 'purple'
         }).each(function(d) {
          if (d.hasTraceFile) {
            this.parentNode.appendChild(this); // Move green circles to the end of the parent group
          }
        });
      

    }


    // Instantiate axis
    let gX = svg.append("g")
      .attr("transform", `translate(0, ${this._height - this.padding.bottom})`)
      .call(xAxis);
    let gY = svg.append("g")
      .attr("transform", `translate(${this.padding.left}, 0)`)
      .call(yAxis);

  }

  private drawAfter(time: number) {
    // Wait time amount of time before calling draw()
    // Prevent previous calling of draw() is this function is invoked
    // again in the amout of time.
    // Used to prevent multiple calls to draw() if inputs changes at the same time.
    clearTimeout(this.updateDelay);
    this.updateDelay = setTimeout(() => this.updateGraph(), time);
  }
}
