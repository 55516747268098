import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import * as d3 from "d3";
import { cloneDeep, debounce, range } from "lodash";
import { Observable, Subject, Subscription, forkJoin, of } from "rxjs";
import { catchError, finalize, switchMap, take } from "rxjs/operators";
import { Cost } from "../../../models/cost";
import { CostItem } from "../../../models/costItem";
import { JobDetail } from "../../../models/jobDetail";
import { MinMaxDialog } from "../../../models/minMaxDialog";
import { PlotDetail } from "../../../models/plotDetail";
import { ShotOverlayDto } from "../../../models/shotOverlayDto";
import { ShotSettings } from "../../../models/shotSettings";
import { ShotType } from "../../../models/shotType";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { CachedUserService } from "../../../shared/services/cached-user.service";
import { DefaultMappingService } from "../../../shared/services/default-mapping.service";
import { ImageLoaderService } from "../../../shared/services/image-loader.service";
import { StoredSettingsService } from "../../services/stored-settings.service";
import { stripDash } from "../chart-slice/chart-slice.component";
import { VelocityComponent } from "../dialogs/velocity/velocity.component";
import { getRandomHexColor } from "../model/model.component";
import { resizeBase64Img } from "./d3-image/d3-image.component";
import html2canvas from "html2canvas";
import { WellLog } from "../../../models/wellLog";

export interface SpectrumResponse {
  predicted_x: number[];
  predicted_y: number[];
  field_x: number[];
  field_y: number[];
}

declare var $: any;
@Component({
  selector: "app-shot-image",
  templateUrl: "./shot-image.component.html",
  styleUrls: ["./shot-image.component.less"],
})
export class ShotImageComponent implements OnInit, OnDestroy {
  settingsKey = "page";
  // parameters set on init
  iteration: number = 1;
  totalIteration: number;
  nx3: number;
  types: ShotType[];
  tableTypes: any[];
  typeDetails = [
    { name: "forward", value: 1 },
    { name: "test step", value: 2 },
  ];
  maxNumTracesShown: number = 1000;
  selectedJobs: any = null;
  isStaff = false;
  private _userSubscription: Subscription;
  // parameters for selected shot
  sliceNum: number;
  numTraces: number;
  numSamples: number;
  totalTime: number;

  // variables for dom rendering
  loadingImage: boolean = true;
  loadingShots: boolean = false;

  // variables for iteration switching
  switchIteration: number;
  itersOfSameShot: number[];

  // variables for shot graph plotting
  shotData: any;
  availableShots: number;
  unavailableShots: number;
  shotBgImg: string;
  selectedShot: number;
  defaultShotGraphHeight: number = 350;
  shotGraphHeight: number = this.defaultShotGraphHeight;
  defaultMainGraphHeight: number = 500;
  mainGraphHeight: number = this.defaultMainGraphHeight;
  hasRightPage: boolean = false;
  hasLeftPage: boolean = false;
  selectedShotColourType: string = "traces_fit";
  shotColourTypes = [
    { id: "traces_fit", name: "Trace fit" },
    { id: "traces_low", name: "Trace fit Raw" },
  ];
  customName: string = null;

  // Custom dynamic filter settings
  customShotName = "1d";
  customShotSettings =
    '{ "filter_type": "raw", "noise": 0.01, "lag_max": 2000 }';
  shotImageError = null;

  //variables for retrieveing shot image
  panelNames: string[];
  allPanelNames: string[] = ["Prediction", "Field", "Difference"];
  selectedPanel: number = 0;
  startTrace: number;
  endTrace: number;
  shotType: ShotType;
  imageUrl: string;
  clipValue: number[];
  interleave_extent: number = 10;
  isInterleaveType: boolean = false;
  currentScaling: number[] = [null, null];
  currentClipping: number = 0;
  globalClipping: boolean = true;
  setClipping = debounce(() => {
    this.storeSettings();
    // store scaling value for that shot type when changing the scaling bar
    this.shotTypeToScale[this.shotType.ttrName] = this.currentScaling;
    this.shotTypeToScale[`${this.shotType.ttrName}_clipping`] =
      this.currentClipping;
    this.updateMainImage();
  }, 250);

  //variable for runfile parameters
  blk_info: any = [];
  selectedBlk = null;
  selectedTraceFit: number[];
  selectedShotFunctional: number;

  //variable for normalizing shot functional values
  typeOfSelectedRcvr: ShotType;
  scaleRatio: number;
  rcvrData: any;
  rcvrIndices: number[];
  rcvrDataFull: any;
  rcvrDataDisplay: any;
  rcvrScale = { min: 0, max: 100 };
  rcvrValue = 0;
  plotRcvr = false;
  rcvrInterval = 50;
  rcvrColorMap = ["blue", "white", "red"];
  rcvrAvg = 0;
  rcvrStd = 0;
  queryInterval = 1;

  sliderBarOpen: boolean = false;
  sliderBarVisible: boolean = false;
  is2D: boolean = false;
  synchroniseSettings: boolean = false;
  velocityAuto = true;
  //map that stores scaling values for diffrent shot type
  private shotTypeToScale = {};

  private jobId: string;
  private x2IsInline: boolean;

  private shotsImageSubscriber: Subscription;
  private mainImageSubscriber: Subscription;
  private selectedShotInfoSubscriber: Subscription;
  private shotsBgImageSubscriber: Subscription;
  private customNamesSubscriber: Subscription;
  private currentJobSubscriber: Subscription;
  private currentJob: JobDetail;
  private _routeQuerySubscription: Subscription;
  private delayedLoading;
  private loadedJob = false;
  private projectId = null;
  private _minValZ: number;
  private _maxValZ: number;
  private _synchoriseSubscription;
  private syncFwd: number = 1;
  startTraceManual: number;
  endTraceManual: number;

  pngWidth = 1600;
  pngHeight = 900;
  globalScaling = true ;
  panelToScaling = {};
  panelToAuto = {};
  rcvrDistance: any;
  rcvrDistances: any;
  muteName = ["mute 1", "mute 2", "mute 3"];
  muteSlope = [1200, 1200, 1200];
  muteIntersection = [1.5, 1, 0.5];
  muteOn = [false, false, false]; // per mute
  colors = ["grey", "#00FF00", "yellow"];
  mute = false; // overall
  muteOffset = [0, 0, 0];
  muteDistanceTypes = [1, 1, 1];
  distanceTypes = [
    { name: "3D", value: 0 },
    { name: "Horizontal Plane", value: 1 },
    { name: "Vertical Inline Plane", value: 2 },
    { name: "Vertical Crossline Plane", value: 3 },
  ];
  shotColor = { min: 0, max: 100 }; // for shots graph color scale
  corr: number[] = [];
  gradientStyle = {};
  enable_corr: boolean = false;
  corr_start_time;
  corr_end_time;
  corr_mean;
  corr_std;
  start_time;
  end_time;
  color_map = d3
    .scaleSequential()
    .domain([-1, 1])
    .interpolator(d3.interpolateViridis);
  corrColorStyle = {
    background: `linear-gradient(to right, ${this.color_map(
      -1
    )}, ${this.color_map(-0.5)}, ${this.color_map(0)}, ${this.color_map(
      0.5
    )}, ${this.color_map(1)})`,
    height: "20px",
    width: "300px",
    "max-width": "30vw",
    "margin-bottom": "15px",
  };
  is_mobile = false;
  is_updating_graph: boolean = false;
  scrollPos;
  time_to_scroll_subscription = new Subject<string>();
  phasediff_colorbar: string = null; // not needed anymore
  phasediff_scale_factor_power = 0.75;
  currentPhaseClipping = 1;
  // default_pdc = true; //default phasediff clipping -> no clipping
  phasediff_clipValue = 1; // the meta value returned with the phaseplot image call
  shotImageWidth: number; // for aligning the corr bar and the image
  isPhaseDiff: boolean = false;
  isSpectrum: boolean = false;
  spectrum: any; // an object with iteration as prop names and data as values, in the form of [predicted, field]
  spectrumOn: any; // an object with iteration as prop names and data as values, in the form of [true, false]
  spectrumColors: any; // an object with iteration as prop names and data as values, in the form of [color, color]
  spectrumRange: number[] = [null, null];
  spectrumRangeX: number[] = [null, null];
  shotGraphRadius: number = 3;
  functionalSequence; // for the itermenu of the spectrum graph to be based on iterblocks
  spectrumIterOptions: number[];
  // the current iter will always be blue and red
  currentIterSpectrumColors = ["blue", "red"];
  // the other iter take colors from this list, when exhausted, use random colors
  availableSpectrumColors = [
    "green",
    "yellow",
    "purple",
    "orange",
    "pink",
    "brown",
    "grey",
    "black",
  ];
  // recover available to this value when switching iters
  _spectrumColors = [
    "green",
    "yellow",
    "purple",
    "orange",
    "pink",
    "brown",
    "grey",
    "black",
  ];
  specInitialized = false;
  specInitSubscriber = new Subject<boolean>();
  inputRcvrScale = { min: 0, max: 100 };
  wasCorr = false; // used to determine whether to initialize the rcvrScale
  screenshotWithShotsInfo: boolean = true;
  showWellLog: boolean = false;
  wellLogs: {x: number, y: number}[] = [];

  constructor(
    public dialog: MatDialog,
    private imageService: ImageLoaderService,
    private projectService: CachedProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private defaultMapping: DefaultMappingService,
    private storedSettingService: StoredSettingsService,
    private userService: CachedUserService
  ) {}

  ngOnInit() {
    this.time_to_scroll_subscription.pipe(take(1)).subscribe((res) => {
      if (this.scrollPos != null) {
        window.scrollTo(0, this.scrollPos);
      }
    });
    this.is_mobile =
      /Mobile/.test(navigator.userAgent) || window.innerWidth <= 1000;
    if (this._routeQuerySubscription) {
      this._routeQuerySubscription.unsubscribe();
      this._routeQuerySubscription = null;
    }
    this._userSubscription = this.userService.userDetail.subscribe((d) => {
      this.isStaff =
        d && (d.isStaff || (d.tracefitAccess != null && d.tracefitAccess));
    });
    this._routeQuerySubscription = this.route.queryParams.subscribe(
      (params) => {
        this.updateFromQueryParams(params);
        this.time_to_scroll_subscription.next("LETS ROLL");
      }
    );

    this._synchoriseSubscription =
      this.storedSettingService.synchroniseSettings.subscribe(
        (val) => (this.synchroniseSettings = val)
      );
    // Get current job information and then get shot information for this job at iteration 1
    this.currentJobSubscriber = this.projectService.currentJob
      .pipe(
        switchMap((job) => {
          this.specInitialized = false;
          this.storeSettings();
          this.initPage();
          if (!job) {
            return of(null);
          }
          this.currentJob = job;
          this.projectId = job.projectId;
          this.jobId = job.id;
          this.totalIteration = job.iterations;
          this.types = [
            {
              name: "lowpass-B4",
              ttrName: "lowpass-B4",
              numPanel: 2,
              displayName: "Raw",
              fwd: 1,
            },
            {
              name: "lowpass",
              ttrName: "lowpass",
              numPanel: 2,
              displayName: "Lowpass",
              fwd: 1,
            },
            {
              name: "agc",
              ttrName: "agc",
              numPanel: 3,
              displayName: "AGC",
              fwd: 1,
            },
            {
              name: "RLsmt",
              ttrName: "RLsmt",
              numPanel: 3,
              displayName: "REC Line Smooth",
              fwd: 1,
            },
            {
              name: "compare",
              ttrName: "compare",
              numPanel: 3,
              displayName: "Compare",
              fwd: 1,
            },
            {
              name: "res",
              ttrName: "res",
              numPanel: 1,
              displayName: "Residual",
              fwd: 1,
            },
            {
              name: "adj",
              ttrName: "adj",
              numPanel: 1,
              displayName: "Adjoint",
            },
            {
              name: "rwicut",
              ttrName: "rwicut",
              numPanel: 3,
              displayName: "RWI Cut",
              fwd: 1,
            },
            {
              name: "rwicut-B4",
              ttrName: "rwicut-B4",
              numPanel: 3,
              displayName: "RWI Cut Before",
              fwd: 1,
            },
            {
              name: "matchfilt",
              ttrName: "matchfilt",
              numPanel: 1,
              displayName: "Match Filter",
              fwd: 1,
            },
            {
              name: "primaries",
              ttrName: "primaries",
              numPanel: 1,
              displayName: "Primaries",
              fwd: 1,
            },
            {
              name: "multiples",
              ttrName: "multiples",
              numPanel: 1,
              displayName: "Multiples",
              fwd: 1,
            },
            {
              name: "matchmults",
              ttrName: "matchmults",
              numPanel: 1,
              displayName: "Multiples Matched",
              fwd: 1,
            },
            {
              name: "direct-arrival",
              ttrName: "direct-arrival",
              numPanel: 1,
              displayName: "Direct Arrival",
              fwd: 1,
            },
            {
              name: "fullfield",
              ttrName: "fullfield",
              numPanel: 1,
              displayName: "Full Field",
              fwd: 1,
            },
            {
              name: "fullpred",
              ttrName: "fullpred",
              numPanel: 1,
              displayName: "Full Predicted",
              fwd: 1,
            },
            {
              name: "1d",
              ttrName: "compare",
              numPanel: 1,
              displayName: "1D Dynamic",
              fwd: 1,
              filterName: "1d",
              filterSettings: {
                filter_type: "raw",
                noise: 0.01,
                lag_max: 2000,
                normalise: "True",
                lowpass_on: "True",
                lowpass_cutoff: 4.5,
                lowpass_order: 6,
                t_scale: 0.01,
                t_type: "Gaussian",
                wmin: "True",
              },
              options: [
                {
                  name: "filter_type",
                  displayName: "Filter Type",
                  values: ["raw", "adjoint", "residual", "check"],
                },
                { name: "noise", displayName: "Noise" },
                { name: "lag_max", displayName: "Lag Max" },
                {
                  name: "normalise",
                  displayName: "Normalise",
                  values: ["True", "False"],
                },
                {
                  name: "lowpass_on",
                  displayName: "Lowpass",
                  values: ["True", "False"],
                },
                { name: "lowpass_cutoff", displayName: "Lowpass Cutoff" },
                { name: "lowpass_order", displayName: "Lowpass Order" },
                { name: "t_scale", displayName: "T Scale" },
                {
                  name: "t_type",
                  displayName: "T Type",
                  values: ["Gaussian", "Linear", "Exp"],
                },
                {
                  name: "wmin",
                  displayName: "wmin",
                  values: ["True", "False"],
                },
              ],
            },
            {
              name: "2d",
              ttrName: "compare",
              numPanel: 1,
              displayName: "2D Dynamic",
              fwd: 1,
              hideAxes: true,
              filterName: "2d",
              filterSettings: {
                filter_type: "raw",
                sample_interval: 10,
                lag_t_max: 150,
                lag_x_max: 200,
                noise: 0.01,
                t_scale_x: 0.1,
                t_scale_t: 0.3,
                t_type: "Gaussian",
                wmin: "True",
              },
              options: [
                {
                  name: "filter_type",
                  displayName: "Filter Type",
                  values: ["raw", "adjoint", "residual"],
                },
                { name: "sample_interval", displayName: "Sample Interval" },
                { name: "lag_t_max", displayName: "Lag t_max" },
                { name: "lag_x_max", displayName: "Lag x_max" },
                { name: "noise", displayName: "Noise" },
                { name: "t_scale_x", displayName: "T Scale x" },
                { name: "t_scale_t", displayName: "T Scale t" },
                {
                  name: "t_type",
                  displayName: "T Type",
                  values: ["Gaussian", "Spike"],
                },
                {
                  name: "wmin",
                  displayName: "wmin",
                  values: ["True", "False"],
                },
              ],
            },
            {
              name: "RcvrList",
              ttrName: "rcvrlist",
              numPanel: 3,
              displayName: "Spatial Plotting",
              fwd: 1,
              filterSettings: {
                freq: 3,
                offset: 2000,
                angles: "0, 90, 180, 270",
                angle_offset: 25,
                value: "freq",
                interval: 10,
              },
              options: [
                {
                  name: "value",
                  displayName: "Receiver Value",
                  values: ["freq", "res", "corr"],
                },
                { name: "freq", displayName: "frequency" },
                { name: "offset", displayName: "Offset" },
                { name: "angles", displayName: "Angles", type: "text" },
                { name: "angle_offset", displayName: "Angle Offset" },
                { name: "interval", displayName: "Interval" },
              ],
            },
            {
              name: "custom",
              ttrName: "compare",
              numPanel: 1,
              displayName: "Custom Dynamic",
              fwd: 1,
            },
            {
              name: "custom_type",
              ttrName: "custom",
              numPanel: 3,
              displayName: "Custom Shot Type",
              options: null,
            },
          ];
          this.tableTypes = this.transTableTypes(12);
          if (!this.restoreSettings()) {
            this.iteration = Math.min(this.totalIteration, this.iteration);
          }
          if (this.iteration > this.totalIteration) {
            this.iteration = this.totalIteration;
          }
          if (!this.typeOfSelectedRcvr) {
            this.typeOfSelectedRcvr = this.types[11];
          }
          if (this.shotType) {
            //this.shotType = this.types.find(t => t.name==this.shotType.name)
          } else {
            // update shottype
            this.shotType = this.types[0];
            this.shotTypeToColorType();
          }
          this.x2IsInline = job.x2_is_inline;
          this.nx3 = job.modelGrid.nx3;
          this.is2D = job.modelGrid.is2d;
          if (!this.sliceNum && this.nx3) {
            this.sliceNum = Math.floor(this.nx3 / 2);
          }
          if (!this.loadedJob) {
            this.updateFromQueryParams(this.route.snapshot.queryParams);
          }

          if (this.synchroniseSettings) {
            this.shotType.fwd = this.syncFwd;
          }

          this.loadedJob = true;
          // this.specInit()
          return this.projectService.getJobMeta(this.jobId);
        })
      )
      .subscribe((meta) => {
        this.checkIsPhaseDiff();
        this.checkIsSpectrum();
        if (!meta) {
          this.blk_info = [];
          this.selectedBlk = null;
          return of(null);
        }
        this.blk_info = meta.blk_data;
        if (!this.selectedBlk) {
          this.selectedBlk = this.blk_info?.length > 0 ? this.blk_info[0] : null;
        }
        this.ensureSelectedPanelExists();
      });
  }
  private ensureSelectedPanelExists() {
    const selectedPanelName = this.panelNames[this.selectedPanel];
    if (!this.panelNames.includes(selectedPanelName)) {
      const predictionPanelIndex = this.panelNames.indexOf("Prediction");
      if (predictionPanelIndex !== -1) {
        this.selectedPanel = predictionPanelIndex;
      } else {
        this.selectedPanel = 0;
      }
    }
  }

  private isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  }

  ngOnDestroy() {
    this.storeSettings();
    this.loadedJob = false;
    if (
      this.currentJobSubscriber !== null &&
      this.currentJobSubscriber !== undefined
    ) {
      this.currentJobSubscriber.unsubscribe();
      this.currentJobSubscriber = null;
    }
    if (
      this.mainImageSubscriber !== null &&
      this.mainImageSubscriber !== undefined
    ) {
      this.mainImageSubscriber.unsubscribe();
      this.mainImageSubscriber = null;
    }
    if (
      this.shotsBgImageSubscriber !== null &&
      this.shotsBgImageSubscriber !== undefined
    ) {
      this.shotsBgImageSubscriber.unsubscribe();
      this.shotsBgImageSubscriber = null;
    }
    if (
      this.shotsImageSubscriber !== null &&
      this.shotsImageSubscriber !== undefined
    ) {
      this.shotsImageSubscriber.unsubscribe();
      this.shotsImageSubscriber = null;
    }
    if (
      this.selectedShotInfoSubscriber !== null &&
      this.selectedShotInfoSubscriber !== undefined
    ) {
      this.selectedShotInfoSubscriber.unsubscribe();
      this.selectedShotInfoSubscriber = null;
    }

    if (
      this._routeQuerySubscription !== null &&
      this._routeQuerySubscription !== undefined
    ) {
      this._routeQuerySubscription.unsubscribe();
      this._routeQuerySubscription = null;
    }
    if (
      this._userSubscription !== null &&
      this._userSubscription !== undefined
    ) {
      this._userSubscription.unsubscribe();
      this._userSubscription = null;
    }
    if (
      this.time_to_scroll_subscription !== null &&
      this.time_to_scroll_subscription !== undefined
    ) {
      this.time_to_scroll_subscription.unsubscribe();
      this.time_to_scroll_subscription = null;
    }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    var offset = $("#slice-settings").offset();
    this.sliderBarVisible = offset && offset.top + 50 < $(document).scrollTop();
    this.scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(e) {
    this.is_mobile =
      /Mobile/.test(navigator.userAgent) || window.innerWidth <= 1000;
    // console.log(this.is_mobile)
  }

  // Event handlers
  onIterationChange(): void {
    this.storeSettings();
    this.initPage();
    this.updateShotsGraph();
    this.updateUrl();
  }

  onShotTypeChange(value: ShotType, event) {
    // this.mute = false;
    // this.rcvrDistance = null;
    // this.rcvrDistances = [];
    event.stopPropagation();
    // update shot type
    this.shotType = value;
    this.shotTypeToColorType();
    if (this.synchroniseSettings) {
      this.shotType.fwd = this.syncFwd;
    }

    this.storeSettings();
    let lastPanelName = this.panelNames[this.selectedPanel];
    this.setPanelNames();
    let newIndex = this.panelNames.findIndex((pN) => pN == lastPanelName);

    // console.log(newIndex)
    // if (this.shotType.numPanel <= this.selectedPanel) {
    //   this.selectedPanel = 0;
    // }
    
    if (newIndex != null) {
      this.selectedPanel = newIndex;
    } else {
      this.selectedPanel = 0;
    }
    if (this.selectedShot) {
      this.currentScaling = this.shotTypeToScale[this.shotType.ttrName] || [
        null,
        null,
      ];
      this.currentClipping =
        this.shotTypeToScale[`${this.shotType.ttrName}_clipping`] || 0;
      this.updateSelectedShotInfo();
    }
    if (value.name == "RcvrList") {
      this.typeOfSelectedRcvr = value;
    }
    this.updateUrl();
  }

  onShotTypeDetailChange(value: number, event) {
    this.mute = false;
    this.rcvrDistance = null;
    this.rcvrDistances = [];
    event.stopPropagation();
    this.shotType.fwd = value;
    if (this.selectedShot) {
      this.updateSelectedShotInfo();
    }

    if (this.synchroniseSettings) {
      this.syncFwd = value;
    }
  }

  onIterationSwitch(iteration?: number): void {
    let newIter = iteration || this.switchIteration;
    this.switchIteration = this.iteration;
    this.iteration = newIter;
    this.updateShotsGraph();
    this.updateUrl();
  }

  onShotSelected(shotId: number) {
    if (!this.shotData) {
      return;
    }
    let shot = this.shotData.find((s) => s.shotId == shotId);
    this.selectedTraceFit = [shot.traces_fit, shot.traces_low];
    this.selectedShotFunctional = Number(
      (shot.fnlc / this.scaleRatio).toFixed(2)
    );
    if (!shot.hasTraceFile) {
      this.selectedShot = shotId;
      this.storeSettings();
      this.imageUrl = null;
      this.loadingImage = false;
      return;
    }
    this.selectedShot = shotId;
    this.loadingImage = true;
    if (this.shotType.name == "RcvrList") {
      this.typeOfSelectedRcvr = this.shotType;
    }

    this.storeSettings();
    // this.updateSelectedShotInfo();
    this.updateUrl();
  }

  onReplotClicked() {
    // this.startTrace = Math.min(this.numTraces-1, Math.max(this.startTrace, 1))
    // this.endTrace = Math.max(this.startTrace+1, Math.min(this.numTraces, this.endTrace))
    this.startTrace = Math.max(1, this.startTrace);
    this.endTrace = Math.min(this.numTraces, this.endTrace);
    if (this.startTrace >= this.endTrace) {
      this.startTrace = Math.max(1, this.endTrace - this.maxNumTracesShown);
    }
    this.updateMainImage();
    this.setClipping();
    this.updateUrl();
  }

  onTabSwitch() {
    this.checkIsInterleaved();
    this.checkIsPhaseDiff();
    this.checkIsSpectrum();
    this.currentScaling = this.panelToScaling[
      this.panelNames[this.selectedPanel]
    ] || [null, null];
    this.velocityAuto =
      this.panelToAuto[this.panelNames[this.selectedPanel]] ||
      (this.currentScaling[0] == null && this.currentScaling[1] == null);
    this.updateMainImage();
  }

  checkIsPhaseDiff() {
    if (this.panelNames == null || this.panelNames.length == 0) return false;
    this.isPhaseDiff =
      this.panelNames[this.selectedPanel] == "Phase Difference";
    return this.isPhaseDiff;
  }

  checkIsSpectrum() {
    if (this.panelNames == null || this.panelNames.length == 0) return false;
    this.isSpectrum = this.panelNames[this.selectedPanel] == "Spectrum";
    return this.isSpectrum;
  }

  checkIsInterleaved() {
    this.isInterleaveType =
      this.panelNames[this.selectedPanel] == "Interleaved";
  }

  onDepthChange() {
    this.storeSettings();
    this.updateBgRange();
  }

  onSliceChange(next: boolean) {
    let range = this.endTrace - this.startTrace;
    if (next) {
      this.endTrace = Math.min(this.numTraces, this.endTrace + range);
      this.startTrace = this.endTrace - range;
    } else {
      this.startTrace = Math.max(1, this.startTrace - range);
      this.endTrace = this.startTrace + range;
    }
    this.updateMainImage();
  }

  // Wrapper for keyboard event
  onKeySubmission(event: KeyboardEvent) {
    this.checkStartTimeEndTime();
    this.checkPhaseDiffScale();
    if (event.key == "Enter") {
      this.onReplotClicked();
    }
  }

  submitSpectrumRange(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.updateRef();
    }
  }

  submitCorrRange(event: KeyboardEvent) {
    if (event.key == "Enter") {
      if (this.inputRcvrScale.min == null) {
        this.inputRcvrScale.min = this.rcvrScale.min;
      }
      if (this.inputRcvrScale.max == null) {
        this.inputRcvrScale.max = this.rcvrScale.max;
      }
      this.rcvrScale = {
        min: this.inputRcvrScale.min,
        max: this.inputRcvrScale.max,
      };
    }
  }

  submitShotsGraphMinMax(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.updateShotsGraph();
    }
  }

  transTableTypes(chunk: number) {
    let res = [];
    let size = this.types.length / chunk;
    let idx = 0;
    while (idx < this.types.length) {
      res.push(this.types.slice(idx, idx + size));
      idx += size;
    }
    res.push(this.types.slice(idx, this.types.length));
    return res;
  }

  onKeySwitchTab(event: KeyboardEvent) {
    if (event.key == "ArrowRight") {
      this.selectedPanel = Math.min(
        this.selectedPanel + 1,
        this.panelNames.length - 1
      );
    } else if (event.key == "ArrowLeft") {
      this.selectedPanel = Math.max(0, this.selectedPanel - 1);
    }
  }

  onKeySwitchGraph(event: KeyboardEvent) {
    if (event.key == "ArrowRight") {
      this.onSliceChange(true);
    } else if (event.key == "ArrowLeft") {
      this.onSliceChange(false);
    }
  }

  onCustomNameSelected(name: string) {
    this.customName = name;
    this.updateSelectedShotInfo();
  }

  onRcvrSelected(shotId: number) {
    this.rcvrValue = (
      this.rcvrData.find((s) => s.shotId == shotId) || {}
    ).traces_fit;
  }

  onSynchroniseChange(value) {
    this.storedSettingService.setSynchroniseSettings(value);
    this.storeSettings();
  }

  getShotsOfSameIteration() {
    if (this.shotData) {
      return this.shotData.filter((s) => s.hasTraceFile);
    }
    return [];
  }

  getModelScale(forRcvr = false) {
    let height: number;
    let gridSpace = this.currentJob.modelGrid.dx;
    if (this.is2D) {
      height = -this.currentJob.modelGrid.nx3 * gridSpace;
    } else {
      height = this.currentJob.modelGrid.nx1 * gridSpace;
    }

    return { x: this.currentJob.modelGrid.nx2 * gridSpace, y: height };
  }

  getParamValue(param) {
    switch (this.selectedBlk[param]) {
      case undefined:
      case null:
        return null;
      case "!default":
        return this.defaultMapping.getDefaultValue(param) + " *";
      default:
        return this.selectedBlk[param];
    }
  }

  getShotFuncs(data) {
    if (!data) return null;
    return data.map((d) => d.fnlc);
  }

  getTabName(name) {
    if (
      name != "Difference" ||
      this.shotType.name != "RcvrList" ||
      this.typeOfSelectedRcvr.name != "RcvrList"
    )
      return name;
    else return "Phase Residual";
  }

  setScaling() {
    // if (this.currentScaling.constructor != Array) {
    //   this.currentScaling = [null, null];
    // }
    let dialogRef = this.dialog.open(VelocityComponent, {
      height: "350px",
      width: "400px",
      data: {
        singleValue: true,
        min: this.currentScaling[0],
        max: this.currentScaling[1],
        offset: this.currentScaling[1],
        auto: this.velocityAuto,
        isScaling: true,
        global: this.globalScaling,
      },
    });

    dialogRef.afterClosed().subscribe((result: MinMaxDialog) => {
      if (!result) return;
      this.currentScaling = [result.min, result.max];
      this.velocityAuto = result.auto;
      if (!result.auto) {
        this.clipValue = this.currentScaling;
      }
      if (this.isPhaseDiff) {
        this.phasediff_clipValue = this.currentScaling[1];
      }
      if (result.global != null) {
        this.globalScaling = result.global;
      }
      if (this.globalScaling) {
        for (let name of this.panelNames) {
          this.panelToScaling[name] = this.currentScaling;
          this.panelToAuto[name] = this.velocityAuto;
        }
      } else {
        this.panelToScaling[this.panelNames[this.selectedPanel]] =
          this.currentScaling;
        this.panelToAuto[this.panelNames[this.selectedPanel]] =
          this.velocityAuto;
      }
      this.updateMainImage();
      this.shotTypeToScale[this.shotType.ttrName] = this.currentScaling;
      this.shotTypeToScale[`${this.shotType.ttrName}_clipping`] =
        this.currentClipping;
    });
  }

  setPhaseClipping = debounce(() => {
    this.currentScaling[1] = this.currentPhaseClipping;
    if (this.globalScaling) {
      for (let name of this.panelNames) {
        this.panelToScaling[name][1] = this.currentPhaseClipping;
        this.panelToAuto[name] = false;
      }
    }
    this.updateMainImage();
  }, 250);

  // setPhaseScaling() {
  //   let dialogRef = this.dialog.open(VelocityComponent, {
  //     height:"350px",
  //     width:"400px",
  //     data: {
  //       singleValue: true,
  //       min: 0,
  //       max: this.currentPhaseClipping,
  //       offset: this.currentPhaseClipping,
  //       auto: this.default_pdc,
  //     }
  //   })

  //   dialogRef.afterClosed().subscribe((result: MinMaxDialog) => {
  //     if (!result) return;
  //     if (result.auto) {
  //       this.currentPhaseClipping = this.phasediff_clipValue;
  //     } else {
  //       // using single value but we don't want it centered around 0, in fact it shouldn't really matter
  //       // because the lambda for phase plot doesn't care about min anyway
  //       this.currentPhaseClipping = Math.min(result.max, this.phasediff_clipValue);
  //       this.default_pdc = false;
  //     }
  //     this.updateMainImage();
  //     this.storeSettings();
  //   })
  // }

  updateDisplayingRcvr() {
    if (!this.rcvrDataFull) {
      return;
    }
    let criteria;
    if (this.shotType.name == "RcvrList") {
      let selectedIdx = this.rcvrIndices;
      criteria = function (i) {
        return selectedIdx.includes(i);
      };
    } else {
      let startIdx = Math.floor(this.startTrace || 0);
      let endIdx = Math.floor(this.endTrace || 0);
      criteria = function (i) {
        return i >= startIdx && i <= endIdx;
      };
    }
    let displayingData = cloneDeep(this.rcvrDataFull).map((r: any, i) => {
      r.hasTraceFile = criteria(i);
      return r;
    });
    this.rcvrDataDisplay = displayingData.filter(
      (r, i) => i % this.rcvrInterval == 0
    );
  }

  updateBgRange() {
    this._minValZ = null;
    this._maxValZ = null;
    this.updateShotsBgImage();
  }

  changeRcvrPlotType(type: ShotType) {
    this.typeOfSelectedRcvr = type;
    this.setPanelNames();
    if (type.name == "RcvrList") return;
    this.delayedLoading = setTimeout(() => (this.loadingImage = true), 250);
    if (
      this.mainImageSubscriber !== null &&
      this.mainImageSubscriber !== undefined
    )
      this.mainImageSubscriber.unsubscribe();
    this.mainImageSubscriber = this.getImageUrl(type, true).subscribe(
      async (res) => {
        if (!res) {
          this.imageUrl = null;
          this.loadingImage = false;
          return;
        }
        clearTimeout(this.delayedLoading);
        this.imageUrl = "data:image/png;base64," + res.imageStr;
        if (this.isPhaseDiff) {
          this.phasediff_clipValue = res.clipValue;
        } else if (this.isSpectrum) {
          this.specMisc(res, this.iteration);
        } else {
          this.clipValue = res.clipValue;
        }
        if (res.corr) {
          this.corrMisc(res.corr);
        }
        this.loadingImage = false;
      },
      (error) => {
        if (error.status != 404) {
          this.shotImageError = error.detail || error;
        }
        this.imageUrl = null;
        clearTimeout(this.delayedLoading);
        this.loadingImage = false;
      }
    );
  }

  // update functions
  private updateShotsGraph() {
    if (
      this.shotsImageSubscriber !== null &&
      this.shotsImageSubscriber !== undefined
    ) {
      try {
        this.shotsImageSubscriber.unsubscribe();
      } catch (Error) {
        this.shotsImageSubscriber = null;
      }
      this.shotsImageSubscriber = null;
    }
    this.loadingShots = true;
    this.shotsImageSubscriber = this.getIterationShotInfo()
      .pipe(
        finalize(() => {
          if (
            this.shotData &&
            this.selectedShot &&
            this.shotData
              .filter((s) => s.hasTraceFile)
              .map((s) => s.shotId)
              .includes(this.selectedShot)
          ) {
            this.updateSelectedShotInfo();
          } else {
            this.switchIteration = null;
            this.itersOfSameShot = null;
            this.imageUrl = null;
            if (
              this.shotData === null ||
              (this.shotData !== null &&
                this.shotData !== undefined &&
                !this.shotData.map((s) => s.shotId).includes(this.selectedShot))
            ) {
              this.selectedShot = null;
            }
            this.loadingImage = false;
          }
        }),
        switchMap((shotsInfo) => {
          let shotsData = this.getShotData(shotsInfo);
          if (!shotsData) {
            return of(null);
          }
          this.shotData = shotsData;
          this.scaleRatio = this.shotData[0].scale_ratio;
          this.availableShots = shotsData.filter((s) => s.hasTraceFile).length;
          this.unavailableShots = shotsData.length;

          if (this.selectedShot) {
            let shot = this.shotData.find((s) => s.shotId == this.selectedShot);
            if (shot) {
              this.selectedTraceFit = [shot.traces_fit, shot.traces_low];
              this.selectedShotFunctional = Number(
                (shot.fnlc / this.scaleRatio).toFixed(2)
              );
            }
          }
          // this.updateMainImage();
          if (!this._maxValZ || !this._minValZ) {
            return this.projectService.getRanges(
              this.jobId,
              "Vp",
              this.currentJob.iterationsComplete,
              { depth: this.sliceNum }
            );
          }
          return of(null);
        }),
        switchMap((range) => {
          if (range) {
            this._minValZ = range.min_val;
            this._maxValZ = range.max_val;
          }
          return this.projectService.getSlice(
            this.jobId,
            "Vp",
            this.iteration - 1,
            3,
            this.sliceNum,
            this.is2D,
            null,
            this._minValZ,
            this._maxValZ
          );
        }),
        catchError((err, caught) => {
          // in the case that the image for the iteration - 1 slice is not found, look for the iteration slice 
          return this.projectService.getSlice(
            this.jobId,
            "Vp",
            this.iteration,
            3,
            this.sliceNum,
            this.is2D,
            null,
            this._minValZ,
            this._maxValZ
          );
        }),
        catchError((err) =>{
          return of(null)
        }),
        switchMap((plotDetail: PlotDetail) => {
          if (!plotDetail || !plotDetail.url) {
            return of(null);
          }
          return this.imageService.getImageBase64(plotDetail.url);
        })
      )
      .subscribe((sliceImage: string) => {
        this.loadingShots = false;
        
        this.shotBgImg = sliceImage;
      });
  }

  private updateMainImage() {
    let shotType = this.shotType;
    this.shotImageError = null;
    if (
      this.mainImageSubscriber !== null &&
      this.mainImageSubscriber !== undefined
    ) {
      this.mainImageSubscriber.unsubscribe();
      this.mainImageSubscriber = null;
    }
    clearTimeout(this.delayedLoading);
    if (shotType.name == "RcvrList") {
      if (this.typeOfSelectedRcvr.name == "RcvrList") {
        if (!this.rcvrDataFull) this._getRcvrData(true);
        return;
      } else shotType = this.typeOfSelectedRcvr;
    }

    this.delayedLoading = setTimeout(() => (this.loadingImage = true), 250);

    this.mainImageSubscriber = this.getImageUrl(
      shotType,
      this.shotType.name == "RcvrList"
    ).subscribe(
      (res) => {
        if (!res) {
          this.imageUrl = null;
          // this.loadingImage = false;
          setTimeout(() => {
            this.loadingImage = false;
          }, 250);
          return;
        }
        clearTimeout(this.delayedLoading);
        this.imageUrl = "data:image/png;base64," + res.imageStr;
        if (this.isPhaseDiff) {
          this.phasediff_clipValue = res.clipValue;
        } else if (this.isSpectrum) {
          this.specMisc(res, this.iteration);
        } else {
          this.currentScaling = Array.from(res.clipValue);
          this.panelToScaling[this.panelNames[this.selectedPanel]] = Array.from(
            res.clipValue
          );
          this.clipValue = Array.from(res.clipValue);
        }
        if (res.corr) {
          this.corrMisc(res.corr);
        }
        this.updateDisplayingRcvr();
        this.checkPagination();
        setTimeout(() => {
          this.loadingImage = false;
        }, 250);
        // this.loadingImage = false;
      },
      (error) => {
        if (error.status != 404) {
          this.shotImageError = error.detail || error;
        }
        this.imageUrl = null;
        clearTimeout(this.delayedLoading);
        // this.loadingImage = false;
        setTimeout(() => {
          this.loadingImage = false;
        }, 250);
      }
    );
  }

  onShowWellLogs() {
    if (this.showWellLog){
      if (this.currentJob.x2_is_inline){
        this.wellLogs = this.currentJob.wellLogs.map(well => ({x: well.inline * this.currentJob.modelGrid.dx, y: well.crossline * this.currentJob.modelGrid.dx}))
      }else{
        this.wellLogs = this.currentJob.wellLogs.map(well => ({x: well.crossline * this.currentJob.modelGrid.dx, y: well.inline * this.currentJob.modelGrid.dx}))
      }
    }
    else this.wellLogs = []
  }

  private updateSelectedShotInfo() {
    this._getRcvrData(this.shotType.name == "RcvrList");
    if (this.shotType.name == "custom_type" && !this.shotType.options) {
      if (
        this.customNamesSubscriber !== null &&
        this.customNamesSubscriber !== undefined
      )
        this.customNamesSubscriber.unsubscribe();
      // this.loadingImage = false;
      setTimeout(() => {
        this.loadingImage = false;
      }, 250);
      this.customNamesSubscriber = this.projectService
        .getAvailableCustomNames(this.jobId, this.iteration, this.selectedShot)
        .subscribe((names) => {
          this.shotType.options = names;
        });
      if (!this.customName) return;
    }
    if (
      this.selectedShotInfoSubscriber !== null &&
      this.selectedShotInfoSubscriber !== undefined
    ) {
      this.selectedShotInfoSubscriber.unsubscribe();
      this.selectedShotInfoSubscriber = null;
    }
    let type =
      this.shotType.name == "RcvrList" ? "compare" : this.shotType.ttrName;
    let data = { type: type };
    Object.assign(
      data,
      this.iteration && { iteration: this.iteration },
      this.selectedShot && { shot_id: this.selectedShot },
      this.shotType.fwd && { fwd: this.shotType.fwd },
      this.customName && { custom_name: this.customName },
      this.shotType.numPanel && { num_panel: this.shotType.numPanel }
    );
    this.selectedShotInfoSubscriber = this.projectService
      .getTraceMeta(this.jobId, data)
      .pipe(
        switchMap((traceMeta) => {
          if (!traceMeta) {
            return of(null);
          }
          this.numTraces = traceMeta.num_trace;
          this.numSamples = traceMeta.num_sample;
          this.totalTime = traceMeta.total_time;
          if (this.end_time != null) {
            this.end_time = Math.min(this.end_time, this.totalTime);
          }
          if (!this.startTrace && !this.endTrace) {
            this.startTrace = Math.max(
              Math.floor((this.numTraces - this.maxNumTracesShown) / 2),
              1
            );
            this.endTrace = Math.min(
              this.numTraces,
              this.startTrace + this.maxNumTracesShown
            );
          }

          let data = {
            type: this.shotType.ttrName,
            shot_id: this.selectedShot,
            custom_name: this.customName,
            fwd: this.shotType.fwd,
          };
          return this.projectService.getTraceFilesById(this.jobId, data);
        }),
        switchMap((files) => {
          if (!files) {
            return of(null);
          }
          files.sort((a, b) => a - b);
          this.itersOfSameShot = files;
          this.specInit(); // must happen after itersOfSameShot is set
          if (
            this.iteration == this.switchIteration ||
            !this.itersOfSameShot.includes(this.switchIteration)
          ) {
            this.switchIteration = null;
          }
          if (this.shotType.name == "RcvrList") return of(null);
          clearTimeout(this.delayedLoading);
          this.delayedLoading = setTimeout(
            () => (this.loadingImage = true),
            300
          );
          return this.getImageUrl(
            this.shotType,
            this.shotType.name == "RcvrList"
          );
        })
      )
      .subscribe(
        (res) => {
          clearTimeout(this.delayedLoading);
          if (res) {
            this.imageUrl = "data:image/png;base64," + res.imageStr;
            if (this.panelNames[this.selectedPanel] == "Phase Difference") {
              this.phasediff_clipValue = res.clipValue;
            } else if (this.isSpectrum) {
              this.specMisc(res, this.iteration);
            } else {
              this.currentScaling = Array.from(res.clipValue);
              this.panelToScaling[this.panelNames[this.selectedPanel]] =
                Array.from(res.clipValue);
              this.clipValue = Array.from(res.clipValue);
            }
            if (res.corr) {
              this.corrMisc(res.corr);
            }
            this.checkPagination();
          } else {
            this.imageUrl = null;
          }
          // this.loadingImage = false;
          setTimeout(() => {
            this.loadingImage = false;
          }, 250);
        },
        (error) => {
          clearTimeout(this.delayedLoading);
          this.imageUrl = null;
          // this.loadingImage = false;
          setTimeout(() => {
            this.loadingImage = false;
          }, 250);
          console.log(error);
        }
      );
  }

  private updateShotsBgImage() {
    if (
      this.shotsBgImageSubscriber !== null &&
      this.shotsBgImageSubscriber !== undefined
    ) {
      this.shotsBgImageSubscriber.unsubscribe();
      this.shotsBgImageSubscriber = null;
    }
    this.projectService
      .getRanges(this.jobId, "Vp", this.currentJob.iterationsComplete, {
        depth: this.sliceNum,
      })
      .pipe(
        switchMap((range) => {
          if (!this._minValZ || !this._maxValZ) {
            this._minValZ = range.min_val;
            this._maxValZ = range.max_val;
          }
          return this.projectService.getSlice(
            this.jobId,
            "Vp",
            this.iteration,
            3,
            this.sliceNum,
            this.is2D,
            null,
            null,
            null
          );
        }),
        switchMap((plotDetail: PlotDetail) => {
          if (!plotDetail) return of(null);
          return this.imageService.getImageBase64(plotDetail.url);
        })
      )
      .subscribe((sliceImage: string) => {
        this.shotBgImg = sliceImage;
      });
  }

  private updateFromQueryParams(queryParams: Params) {
    // console.log("updateFromQueryParams")
    if (!this.jobId) return;
    if (!!queryParams["iteration"] || queryParams["iteration"] == 0) {
      this.iteration = parseInt(queryParams["iteration"]) || 1;
    }
    if (!!queryParams["jobs"]) {
      this.selectedJobs = queryParams["jobs"];
    }
    if (!!queryParams["start"]) {
      this.startTrace = Math.max(parseInt(queryParams["start"]), 1);
    }
    if (!!queryParams["end"]) {
      this.endTrace = parseInt(queryParams["end"]);
    }
    if (
      queryParams["type"] &&
      this.types.find((t) => t.name == queryParams["type"])
    ) {
      if (this.shotType.name != queryParams["type"])
        this.shotType = this.types.find((t) => t.name == queryParams["type"]);

      if (queryParams["settings"]) {
        try {
          this.shotType.filterSettings = JSON.parse(queryParams["settings"]);
        } catch (e) {}
      }
    }

    if (queryParams["id"]) {
      this.selectedShot = parseInt(queryParams["id"]);
    }

    this.updateUrl();

    if (
      this.is2D !== null &&
      this.x2IsInline !== null &&
      this.sliceNum !== null &&
      this.nx3 !== null
    ) {
      this.setPanelNames();
      this.updateShotsGraph();
      this.loadedJob = true;
    }
  }

  private updateUrl() {
    this.router.navigate(["."], {
      relativeTo: this.route,
      queryParams: {
        iteration: this.iteration,
        id: this.selectedShot,
        type: this.shotType.name,
        start: this.startTrace,
        end: this.endTrace,
        jobs: this.selectedJobs,
        settings: this.shotType.filterSettings
          ? JSON.stringify(this.shotType.filterSettings)
          : null,
      },
      replaceUrl: true,
    });
  }

  // Request functions
  private getIterationShotInfo(): Observable<
    [Cost, ShotOverlayDto[], number[], ShotOverlayDto[]]
  > {
    return forkJoin([
      this.projectService.getCosts(
        this.jobId,
        this.totalIteration,
        this.x2IsInline
      ),
      this.projectService.getShotOverlay(this.jobId, this.iteration),
      this.projectService.getTraceFilesByIteration(this.jobId, this.iteration),
      this.projectService.getShotOverlay(this.jobId, this.iteration),
    ]);
  }

  /**
   *
   * @param shotType
   * @param useIdx
   * @param iteration
   * @description get the shot image url
   * the newly added panels, phase difference and spectrum, will set panel to 4 and 5 respectively, the panel values for them are only used to identify the type.
   * the backend and the lambda function will handle these new panels as special cases.
   * the panel values for predicted and field are used to calculate offset in ttr file, so they are set to 0 and 1 respectively.
   * @returns
   */
  private getImageUrl(
    shotType: ShotType,
    useIdx = false,
    iteration = this.iteration
  ): Observable<any> {
    if (
      !(iteration && this.selectedShot && this.jobId) ||
      this.startTrace >= this.endTrace
    )
      return of(null);

    this.checkStartTraceEndTrace();
    let startTrace = Math.max(
      Math.floor((this.numTraces - this.maxNumTracesShown) / 2),
      0
    );
    let endTrace = Math.min(
      this.numTraces,
      this.startTrace + this.maxNumTracesShown
    );
    let panel;
    let ob: Observable<any>;
    if (this.panelNames[this.selectedPanel] == "Difference") {
      // the displayed order of difference and interleaved is switched.
      panel = 2;
    } else if (this.panelNames[this.selectedPanel] == "Phase Difference") {
      panel = 4;
    } else if (this.panelNames[this.selectedPanel] == "Spectrum") {
      panel = 5;
      // ob = this.projectService.getFunctionalSequence(this.currentJob.id, this.currentJob.basePath, this.currentJob.prefix)
    } else {
      panel = this.selectedPanel;
    }
    let parameters = {
      type: shotType.ttrName,
      panel: panel,
      scaling: this.velocityAuto ? [null, null] : this.currentScaling,
      clipping: Math.pow(10, this.currentClipping),
      global_clip: this.globalClipping,
      num_panel: shotType.numPanel,
      filter_name:
        shotType.name == "custom" ? this.customShotName : shotType.filterName,
      filter_settings:
        shotType.name == "custom"
          ? JSON.parse(this.customShotSettings || "{}")
          : shotType.filterSettings,
      interleave_attrs: {
        is_interleave: this.isInterleaveType ? 1 : 0,
        extent: this.interleave_extent,
      },
    };
    parameters["with_corr"] = this.enable_corr;
    if (this.enable_corr) {
      if (this.corr_start_time) {
        parameters["start_time"] = Math.round(
          this.corr_start_time / (this.totalTime / this.numSamples)
        );
      }

      if (this.corr_end_time) {
        parameters["end_time"] = Math.round(
          this.corr_end_time / (this.totalTime / this.numSamples)
        );
      }
    }

    if (this.panelNames[this.selectedPanel] == "Phase Difference") {
      parameters["scale_fact_power"] = this.phasediff_scale_factor_power;
      parameters["scaling"] = [0, this.currentScaling[1]];
    }

    if (useIdx) {
      let stdSrcLoc = this.shotData.find((s) => s.shotId == this.selectedShot);
      let sortedIndices = this.rcvrData
        .map((r, i) => {
          let distX = r.x - stdSrcLoc.x;
          let distY = r.y - stdSrcLoc.y;
          let dist = distX * Math.abs(distX) + distY * Math.abs(distY);
          return { dist: dist, idx: this.rcvrIndices[i] };
        })
        .filter((s) => s.idx != undefined);
      sortedIndices.sort((a, b) => a.dist - b.dist);
      //let sortedIndices = Array.from(this.rcvrIndices)
      //sortedIndices.sort((a, b) => a - b);
      parameters["indices"] = sortedIndices.map((s) => s.idx);
    } else {
      parameters["start_trace"] = this.startTrace || startTrace;
      parameters["end_trace"] = Math.min(
        this.numTraces,
        this.endTrace || endTrace
      );
    }

    if (parameters.filter_settings) {
      parameters.filter_settings.clipping = this.currentClipping;
    }

    Object.assign(
      parameters,
      iteration && { iteration: iteration },
      this.selectedShot && { shot_id: this.selectedShot },
      this.shotType.fwd && { fwd: this.shotType.fwd },
      this.customName && { custom_name: this.customName }
    );

    if (panel == 5) {
      const observable = new Observable<any>((observer) => {
        let params = Object.assign({}, parameters, { iteration: iteration });
        let obs = this.imageService.getTraceImageAndClipValue(
          this.jobId,
          params
        );
        observer.next(obs);
        observer.complete();
      });

      return observable.pipe(
        switchMap((observable: Observable<any>) => observable)
      );
    } else {
      return this.imageService.getTraceImageAndClipValue(
        this.jobId,
        parameters
      );
    }
  }

  /**
   *
   * @param full_data
   * @description get the receiver data, mainly used for mute plot and spatial plotting. if want mute only, set full_data to false, otherwise set it to true.
   */
  private _getRcvrData(full_data = false) {
    this.rcvrData = [];
    let rcvrType =
      this.shotType.name == "RcvrList"
        ? this.shotType
        : this.types.find((t) => t.name == "RcvrList");
    rcvrType.numPanel = rcvrType.filterSettings.value === "freq" ? 3 : 1;
    let filterParams = {
      angles: rcvrType.filterSettings.angles
        .split(",")
        .map((a: any) => parseInt(a)),
      offset: rcvrType.filterSettings.offset,
      angle_offset: rcvrType.filterSettings.angle_offset,
      value: rcvrType.filterSettings.value,
      panel: this.selectedPanel,
      freq: rcvrType.filterSettings.freq,
    };
    this.queryInterval = rcvrType.filterSettings.interval;
    let src_loc0 = null;
    let src_loc1 = null;
    let offset_x1 = null;
    let offset_x2 = null;
    if (this.selectedShot != null) {
      this.projectService
        .getRcvrData(
          this.jobId,
          this.iteration,
          this.selectedShot,
          this.shotType.fwd,
          filterParams,
          this.currentJob.x2_is_inline
        )
        .pipe(
          switchMap((data) => {
            if (!data) {
              this.rcvrData = null;
              this.rcvrDataFull = null;
              this.rcvrValue = 0;
              this.rcvrScale = { min: 0, max: 100 };
              this.inputRcvrScale = { min: 0, max: 100 };
              return;
            }
            // calculate data for mute plots
            let dx = this.currentJob.modelGrid.dx;
            let src = data["src_loc"];
            let rcvr = data["rcvr_loc"];
            let rcvr2src = rcvr.map((r) => {
              return this.euclidean(src[0], r[0], src[1], r[1]);
            });
            this.rcvrDistance = rcvr2src;
            if (src.length == 3 && rcvr[0].length == 3) {
              this.rcvrDistances = [];
              let rcvr2src3d = rcvr.map((r) => {
                return this.euclidean3d(
                  src[0],
                  r[0],
                  src[1],
                  r[1],
                  src[2],
                  r[2]
                );
              });
              this.rcvrDistances.push(rcvr2src3d);
              this.rcvrDistances.push(rcvr2src); // x and y, ignore z
              // assuming [1] to be y
              let rcvr2srcxz = rcvr.map((r) => {
                //x and z, ignore y
                return this.euclidean(src[0], r[0], src[2], r[2]);
              });
              this.rcvrDistances.push(rcvr2srcxz);
              let rcvr2srcyz = rcvr.map((r) => {
                //x and z, ignore y
                return this.euclidean(src[1], r[1], src[2], r[2]);
              });
              this.rcvrDistances.push(rcvr2srcyz);
            } else {
              this.rcvrDistances = [[], rcvr2src, [], []];
            }

            let stdSrcLoc = this.shotData.find(
              (s) => s.shotId == this.selectedShot
            );
            offset_x1 = Math.round(stdSrcLoc.x / dx) * dx - data.src_loc[0];
            // this.offset_x1 = offset_x1;
            offset_x2 = Math.round(stdSrcLoc.y / dx) * dx - data.src_loc[1];
            // this.offset_x2 = offset_x2;
            this.rcvrDataFull = data.rcvr_loc.map((r, i) => {
              return {
                x: r[0] + offset_x1,
                y: r[1] + offset_x2,
                shotId: i + 1,
                hasTraceFile: false,
                traces_fit: 0,
              };
            });
            this.rcvrDataDisplay = this.rcvrDataFull.filter(
              (r, i) => i % this.rcvrInterval === 0
            );
            this.rcvrIndices =
              data.selected_idx || range(0, data.rcvr_loc.length);
            this.rcvrData = this.rcvrDataFull.filter(
              (r, i) =>
                i % rcvrType.filterSettings.interval === 0 &&
                this.rcvrIndices.includes(i)
            );
            src_loc0 = data.src_loc[0];
            src_loc1 = data.src_loc[1];
            if (full_data) {
              try {
                return this.projectService.getRcvrData_data(
                  this.jobId,
                  this.iteration,
                  this.selectedShot,
                  this.shotType.fwd,
                  filterParams
                );
              } catch (e) {
                console.error(e);
                return of(null);
              }
            } else {
              this.updateDisplayingRcvr();

              return of(null);
            }
          })
        )
        .subscribe(
          (data) => {
            if (!data || data.length == 0) {
              this.rcvrAvg = 0;
              this.rcvrStd = 0;
              return;
            }
            this.rcvrAvg = data.reduce((a, b) => a + b, 0) / data.length;
            this.rcvrStd = Math.sqrt(
              data.map((v) => v - this.rcvrAvg).reduce((a, b) => a + b * b, 0) /
                data.length
            );
            if (
              this.currentScaling &&
              this.currentScaling[0] != null &&
              this.currentScaling[1] != null
            ) {
              this.rcvrScale = {
                min: this.currentScaling[0],
                max: this.currentScaling[1],
              };
              this.inputRcvrScale = {
                min: this.currentScaling[0],
                max: this.currentScaling[1],
              };
            } else {
              if (rcvrType.filterSettings.value == "corr") {
                if (this.rcvrScale == null || !this.wasCorr) {
                  this.rcvrScale = { min: -1, max: 1 };
                  this.inputRcvrScale = { min: -1, max: 1 };
                }
                this.wasCorr = true;
              } else if (rcvrType.filterSettings.value == "freq") {
                this.wasCorr = false;
                this.rcvrScale = { min: -Math.PI, max: Math.PI };
                this.inputRcvrScale = { min: -Math.PI, max: Math.PI };
              } else {
                this.wasCorr = false;
                this.rcvrScale = {
                  min: Math.min(...data),
                  max: Math.max(...data),
                };
                this.inputRcvrScale = {
                  min: Math.min(...data),
                  max: Math.max(...data),
                };
              }
              let clipValue = Math.pow(10, this.currentClipping);
              this.rcvrScale = {
                min: this.rcvrScale.min * clipValue,
                max: this.rcvrScale.max * clipValue,
              };
              this.inputRcvrScale = {
                min: this.inputRcvrScale.min * clipValue,
                max: this.inputRcvrScale.max * clipValue,
              };
            }
            this.rcvrData.forEach((r, i) => (r.traces_fit = data[i]));
            this.rcvrData.push({
              x: src_loc0 + offset_x1,
              y: src_loc1 + offset_x2,
              shotId: 0,
              hasTraceFile: true,
              traces_fit: 0,
            });
            if (rcvrType.filterSettings.value == "corr") {
              this.rcvrColorMap = ["black", "grey", "white"];
            } else {
              this.rcvrColorMap = ["blue", "white", "red"];
            }
            this.calculateTraceRange();
            this.updateDisplayingRcvr();
          },
          (error) => {
            this.rcvrAvg = 0;
            this.rcvrStd = 0;
          }
        );
    }
  }

  euclidean(x1: number, x2: number, y1: number, y2: number) {
    return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
  }

  euclidean3d(
    x1: number,
    x2: number,
    y1: number,
    y2: number,
    z1: number,
    z2: number
  ) {
    return Math.sqrt(
      Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2) + Math.pow(z1 - z2, 2)
    );
  }

  //utility functions
  private getShotData(shotsInfo) {
    if (
      !(
        (
          shotsInfo &&
          shotsInfo[0] &&
          // shotsInfo[1] &&
          shotsInfo[2]
        )
        // shotsInfo[3]
      )
    ) {
      this.shotData = null;
      return;
    }
    let costs: CostItem[] = shotsInfo[0].costItems;
    let shotOverlays: ShotOverlayDto[] = shotsInfo[1];
    let availableShots: number[] = shotsInfo[2] || [];
    let firstShot: ShotOverlayDto[] = shotsInfo[3];
    if (costs && shotOverlays) {
      return shotOverlays.map((s) => {
        let costItem = costs.find((c) => c.shotNo == s.shot_id);
        let available = availableShots.includes(s.shot_id);
        let scaleRatio =
          Math.max.apply(
            Math,
            firstShot.map((f) => f.fnlc)
          ) / 100.0;
        return {
          y: costItem ? (this.is2D ? costItem.sz : costItem.sy) : null,
          x: costItem ? costItem.sx : null,
          shotId: s.shot_id,
          hasTraceFile: available,
          traces_fit: s.traces_fit,
          traces_low: s.dc_low,
          fnlc: s.fnlc,
          scale_ratio: scaleRatio,
        };
      });
    } else if (costs && (shotOverlays == null || shotOverlays == undefined)) {
      const shots = []
      costs.forEach((c) => {
        let available = availableShots.includes(c.shotNo);
        let scaleRatio = 1;
        if (available){
          shots.push({
            y: this.is2D ? c.sz : c.sy,
            x: c.sx,
            shotId: c.shotNo,
            hasTraceFile: available,
            traces_fit: 0,
            traces_low: 0,
            fnlc: 0,
            scale_ratio: scaleRatio,
          });
        }
      });
      return shots

    }
  }

  private checkPagination() {
    this.hasRightPage = this.endTrace < this.numTraces;
    this.hasLeftPage = this.startTrace > 1;
  }

  private initPage() {
    this.numSamples = null;
    this.numTraces = null;
    this.totalTime = null;
  }

  private setPanelNames() {
    let numPanel =
      this.shotType.name == "RcvrList"
        ? this.typeOfSelectedRcvr.numPanel
        : this.shotType.numPanel;
    this.panelNames = this.allPanelNames.slice(0, numPanel);
    if (this.shotType.numPanel > 1 && this.shotType.name != "RcvrList") {
      if (this.shotType.displayName != "Compare") {
        this.panelNames.push("Interleaved");
      } else {
        this.panelNames[2] = "Interleaved";
        this.panelNames.push("Difference");
      }
      if (["Raw", "Lowpass", "Compare"].includes(this.shotType.displayName)) {
        this.panelNames.push("Phase Difference");
        this.panelNames.push("Spectrum");
      }
    }
    for (let name of this.panelNames) {
      if (this.panelToScaling[name] == null) {
        this.panelToScaling[name] = [null, null];
        this.panelToAuto[name] = true;

        if (this.globalScaling) {
          let otherScales = [];
          let otherAutos = [];
          for (let name2 of this.panelNames) {
            if (name == name2) {
              continue;
            }
            otherScales.push(this.panelToScaling[name2]);
            otherAutos.push(this.panelToAuto[name2]);
          }
          let min = otherScales[0] && otherScales[0][0] ;
          let max = otherScales[0] && otherScales[0][1];
          let global = true;
          for (let i = 1; i < otherScales.length; i++) {
            if (otherScales[i] && (min != otherScales[i][0] || max != otherScales[i][1])) {
              global = false;
              break;
            }
          }
          if (global) {
            this.panelToScaling[name] = [min, max];
            this.panelToAuto[name] = otherAutos[0];
          }
        }
      }
    }
  }

  private calculateTraceRange() {
    let currentShot = this.shotData.find((s) => s.shotId === this.selectedShot);
    if (
      !currentShot ||
      !this.rcvrDataFull ||
      (this.startTrace && this.endTrace)
    )
      return;
    let rcvrDist = this.rcvrDataFull.map((r, idx) => {
      let dist =
        Math.pow(currentShot.x - r.x, 2) + Math.pow(currentShot.y - r.y, 2);
      return { dist: dist, idx: idx };
    });
    rcvrDist = rcvrDist.sort((a, b) => a.dist - b.dist);
    let halfRange = Math.floor(this.maxNumTracesShown / 2);
    this.startTrace =
      this.startTrace != null
        ? this.startTrace
        : Math.max(1, rcvrDist[0].idx - halfRange);
    this.endTrace =
      this.endTrace != null
        ? this.endTrace
        : Math.min(
            this.numTraces || this.startTrace + this.maxNumTracesShown,
            rcvrDist[0].idx + halfRange
          );
    if (this.numTraces != null) {
      this.startTrace = Math.min(
        this.numTraces - this.maxNumTracesShown,
        Math.max(1, this.startTrace)
      );
      this.endTrace = Math.min(
        this.numTraces,
        Math.max(this.maxNumTracesShown, this.endTrace)
      );
    }
    this.updateMainImage();
  }

  private storeSettings() {
    if (!this.jobId) return;
    // if we are synchronised, we take the scaling value of the current job, and we are no longer auto
    let syncrhonised = this.storedSettingService.synchronised;
    if (
      syncrhonised &&
      this.velocityAuto &&
      this.currentScaling[0] != null &&
      this.currentScaling[1] != null
    ) {
      this.velocityAuto = false;
      this.panelToAuto[this.panelNames[this.selectedPanel]] = false;
    }
    this.storedSettingService.setSettings<ShotSettings>(
      this.settingsKey,
      {
        id: this.currentJob.id,
        projectId: this.projectId,
        block: this.selectedBlk,
        iteration: this.iteration,
        sliceNum: this.sliceNum,
        shotType: this.shotType,
        selectedShot: this.selectedShot,
        availableShotIds: [],
        startTrace: this.startTrace,
        endTrace: this.endTrace,
        startTraceManual: this.startTraceManual,
        endTraceManual: this.endTraceManual,
        scaling: this.currentScaling,
        clipping: this.currentClipping,
        minValZ: this._minValZ,
        maxValZ: this._maxValZ,
        typeOfSelectedRcvr: this.typeOfSelectedRcvr,
        rcvrIdx: this.rcvrIndices,
        shotGraphHeight: this.shotGraphHeight,
        mainShotGraphHeight: this.mainGraphHeight,
        corr_start_time: this.corr_start_time,
        corr_end_time: this.corr_end_time,
        start_time: this.start_time,
        end_time: this.end_time,
        shot_color: this.shotColor,
        scrollPos: this.scrollPos,
        enable_corr: this.enable_corr,
        mute: this.mute,
        spectrumRange: this.spectrumRange,
        spectrumRangeX: this.spectrumRangeX,
        shotGraphRadius: this.shotGraphRadius,
        rcvrScale: this.rcvrScale,
      },
      SettingTypes.Shot
    );
  }

  private restoreSettings() {
    // console.log("Restoring settings")
    let lastSettings = this.storedSettingService.getSettings<ShotSettings>(
      this.settingsKey,
      this.currentJob.id,
      SettingTypes.Shot
    );
    if (!lastSettings) {
      return false;
    }
    this.iteration = Math.min(
      lastSettings.iteration,
      this.currentJob.iterationsComplete
    );
    this.selectedBlk = lastSettings.block;
    this.shotType = lastSettings.shotType;
    if (this.selectedShotColourType == null) {
      // keep the last colour type
      this.shotTypeToColorType();
    }
    this.selectedShot =
      this.selectedShot != null ? this.selectedShot : lastSettings.selectedShot;
    // this.startTrace = Math.min(this.numTraces-1, lastSettings.startTrace);
    this.startTrace =
      this.startTrace != null ? this.startTrace : lastSettings.startTrace;
    // this.endTrace = Math.min(this.numTraces, lastSettings.endTrace);
    this.endTrace =
      this.endTrace != null ? this.endTrace : lastSettings.endTrace;
    if (this.numTraces != null) {
      this.startTrace = Math.min(
        this.numTraces - this.maxNumTracesShown,
        Math.max(1, this.startTrace)
      );
      this.endTrace = Math.min(
        this.numTraces,
        Math.max(this.maxNumTracesShown, this.endTrace)
      );
    }
    this.currentClipping = lastSettings.clipping || 0;
    this.currentScaling = lastSettings.scaling || [null, null];
    if (this.currentScaling != null) {
      if (this.currentScaling[0] != null && this.currentScaling[1] != null) {
        if (this.storedSettingService.synchronised) {
          this.velocityAuto = false;
          if (this.panelNames == null || this.panelNames == undefined) {
            this.setPanelNames();
          }
          this.panelToAuto[this.panelNames[this.selectedPanel]] = false;
        }
      }
    }
    setTimeout(() => (this.clipValue = Array.from(this.currentScaling)), 0);
    this.sliceNum = lastSettings.sliceNum;
    this._minValZ = lastSettings.minValZ;
    this._maxValZ = lastSettings.maxValZ;
    if (
      this.shotType.name == "RcvrList" &&
      lastSettings.typeOfSelectedRcvr.name != "RcvrList"
    ) {
      this.rcvrIndices = lastSettings.rcvrIdx;
      this.typeOfSelectedRcvr = lastSettings.typeOfSelectedRcvr;
    }
    this.mainGraphHeight =
      this.mainGraphHeight != this.defaultMainGraphHeight
        ? this.mainGraphHeight
        : lastSettings.mainShotGraphHeight;
    this.shotGraphHeight =
      this.shotGraphHeight != this.defaultShotGraphHeight
        ? this.shotGraphHeight
        : lastSettings.shotGraphHeight;
    if (this.mainGraphHeight == null)
      this.mainGraphHeight = this.defaultMainGraphHeight;
    if (this.shotGraphHeight == null)
      this.shotGraphHeight = this.defaultShotGraphHeight;
    this.corr_start_time =
      this.corr_start_time != null
        ? this.corr_start_time
        : lastSettings.corr_start_time;
    this.corr_end_time =
      this.corr_end_time != null
        ? this.corr_end_time
        : lastSettings.corr_end_time;
    this.start_time =
      this.start_time != null ? this.start_time : lastSettings.start_time;
    this.end_time =
      this.end_time != null ? this.end_time : lastSettings.end_time;
    this.shotColor =
      this.shotColor.min != 0 || this.shotColor.max != 100
        ? this.shotColor
        : lastSettings.shot_color != null
        ? lastSettings.shot_color
        : { min: 0, max: 100 };
    this.updateShotColorRef();
    this.enable_corr =
      lastSettings.enable_corr != null ? lastSettings.enable_corr : false;
    this.mute = lastSettings.mute != null ? lastSettings.mute : false;
    this.spectrumRange = lastSettings.spectrumRange;
    if (this.spectrumRange == null) {
      this.spectrumRange = [null, null];
    }
    this.spectrumRangeX = lastSettings.spectrumRangeX;
    if (this.spectrumRangeX == null) {
      this.spectrumRangeX = [null, null];
    }
    this.shotGraphRadius = lastSettings.shotGraphRadius;

    this.scrollPos =
      this.scrollPos != null ? this.scrollPos : lastSettings.scrollPos;
    if (lastSettings.rcvrScale != null) {
      this.rcvrScale = lastSettings.rcvrScale;
      this.inputRcvrScale = {
        min: this.rcvrScale.min,
        max: this.rcvrScale.max,
      };
    }
    return true;
  }

  downloadShotAsPng() {
    const containerElement = document.getElementById("image-and-slice");
    html2canvas(containerElement, { scrollY: -window.scrollY }).then((canvas) => {
      let url = canvas.toDataURL();
      let name = this.getShotPngNameStr();
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      link.click();
    });
  }

  getShotPngNameStr() {
    let parts = [
      this.currentJob.projectName.substring(0, 6),
      this.currentJob.name.substring(0, 6),
      this.shotType != null ? this.shotType.displayName.substring(0, 5) : null,
      this.selectedPanel != null
        ? this.panelNames[this.selectedPanel].substring(0, 5)
        : null,
      this.selectedShot != null ? "SHOT" + this.selectedShot.toString() : null,
      this.currentClipping != null
        ? "CLIP(" + this.currentClipping + ")"
        : null,
      this.iteration != null ? "CPNUM" + this.iteration.toString() : null,
      this.startTrace != null
        ? "TRACE(" +
          this.startTrace.toString() +
          "," +
          this.endTrace.toString() +
          ")"
        : null,
      this.currentScaling != null && this.currentScaling[1] != null 
        ? "SCALE(" + this.currentScaling[1].toString() + ")"
        : null,
    ];
    let str = stripDash(parts[0]);
    for (let i = 1; i < parts.length; i++) {
      if (parts[i] == null) continue;
      str += "-" + stripDash(parts[i]);
    }
    return str + ".png";
  }

  setGlobalScaling() {
    if (this.globalScaling) {
      for (let name of this.panelNames) {
        this.panelToScaling[name] = this.currentScaling;
        this.panelToAuto[name] = this.velocityAuto;
      }
    }
    this.updateMainImage();
  }

  toggleMute() {
    // this.muteOn = [false,false,false]
    this.updateRef();
    this.updateMainImage();
  }

  toggleCorr() {
    if (this.enable_corr && this.corr.length == 0) {
      this.getImageUrl(
        this.shotType,
        this.shotType.name == "Rcvrlist"
      ).subscribe((res) => {
        if (res) {
          if (res.corr) {
            this.corrMisc(res.corr);
          }
        }
      });
    }
  }

  muteDistanceTypeChanged() {
    this.updateRef();
    this.updateMainImage();
  }

  /**
   *
   * @description updates the references of arrays to trigger rerender of component
   */
  updateRef() {
    this.muteSlope = Array.from(this.muteSlope);
    this.muteIntersection = Array.from(this.muteIntersection);
    this.muteOn = Array.from(this.muteOn);
    this.muteOffset = Array.from(this.muteOffset);
    this.muteDistanceTypes = Array.from(this.muteDistanceTypes);
    // this.spectrumOn = Array.from(this.spectrumOn);
  }

  shotTypeToColorType() {
    if (this.shotType.displayName == "Raw") {
      this.selectedShotColourType = "traces_low";
    } else {
      this.selectedShotColourType = "traces_fit";
    }
  }

  shotColorTypeName(id: string): string {
    return this.shotColourTypes.find((i) => i.id == id).name;
  }

  updateShotColorRef() {
    this.shotColor = { min: this.shotColor.min, max: this.shotColor.max };
  }

  valueToColor(value: number): string {
    if (value < -1 || value > 1) {
      return `rgb(255,0,0)`;
    }
    let v = Math.round(((value + 1) / 2) * 255);
    return `rgb(${v},${v},${v})`;
  }

  /**
   *
   * @description the visualisation of correlation is a rectangle aligned with the shot image x axis, and the color represents the correlation value
   * used linear gradient and a list of colors to color the rectangle
   */
  updateColorGradient() {
    let colors = this.corr.map((c) => this.color_map(c));
    let gradientColors = "";
    for (let color of colors) {
      gradientColors += color + ", ";
    }
    gradientColors = gradientColors.slice(0, -2);
    this.gradientStyle = {
      background: `linear-gradient(to right, ${gradientColors})`,
      height: "50px",
      "margin-bottom": "15px",
      // "padding": "0",
    };
  }

  /**
   *
   * @description set up correlation variables
   * @param corr
   */
  corrMisc(corr: number[]) {
    let mean = corr.reduce((sum, value) => sum + value, 0) / corr.length;
    let squaredDifferencesSum = corr.reduce(
      (sum, value) => sum + Math.pow(value - mean, 2),
      0
    );
    let std = Math.sqrt(squaredDifferencesSum / corr.length);
    this.corr = corr;
    this.corr_mean = mean;
    this.corr_std = std;
    this.updateColorGradient();
  }

  /**
   *
   * @description initialize spectrum variables
   */
  specInit() {
    //reset colors
    this.availableSpectrumColors = Array.from(this._spectrumColors);
    //init spectrum variables
    this.spectrum = {};
    this.spectrumOn = {};
    this.spectrumColors = {};
    this.projectService
      .getFunctionalSequence(
        this.currentJob.id,
        this.currentJob.basePath,
        this.currentJob.prefix
      )
      .toPromise()
      .then((res) => {
        if (res == null) {
          console.error(
            "Unable to get functional sequence, you will only see the current iteration available"
          );
          this.spectrumIterOptions = [this.iteration];
        } else {
          this.spectrumIterOptions = [];
          let ranges = res.additionals.map((item) => item[1].Iters);
          // for (let range of ranges) {
          for (let iter of this.itersOfSameShot) {
            // if (iter >= range[0] && iter <= range[1]) {
            this.spectrumIterOptions.push(iter);
            // break;
            // }
          }
          // }
        }
        for (let iter of this.spectrumIterOptions) {
          this.spectrum[iter] = null;
          this.spectrumOn[iter] = [false, false];
          this.spectrumColors[iter] = null;
        }
        this.specInitialized = true;
        this.specInitSubscriber.next(true);
      });
  }

  /**
   * @description set up spectrum variables using the response from the server
   * @param res
   * @param iteration
   */
  specMisc(res: SpectrumResponse, iteration: number) {
    if (this.specInitialized == false) {
      this.specInitSubscriber.pipe(take(1)).subscribe(() => {
        this.specMisc(res, iteration);
      });
      return;
    }
    if (res == null) {
      return;
    }
    let ps = [];
    let fs = [];
    let on;
    let colors;
    on = [true, true];
    if (iteration == this.iteration) {
      colors = Array.from(this.currentIterSpectrumColors);
    } else {
      colors = [this.popColor(), this.popColor()];
    }
    for (let i = 0; i < res.field_x.length; i++) {
      let f = { x: res.field_x[i], y: res.field_y[i] };
      let p = { x: res.predicted_x[i], y: res.predicted_y[i] };
      ps.push(p);
      fs.push(f);
    }
    this.spectrum[iteration] = [ps, fs];
    this.spectrumOn[iteration] = on;
    this.spectrumColors[iteration] = colors;
    this.updateRef();
  }

  /**
   *
   * @description when start time or end time is changed, need to update correlation
   */
  onTimeWindowEmit(event) {
    if (event.start_time == null && event.end_time == null) {
      this.corr_start_time = null;
      this.corr_end_time = null;
    } else {
      this.corr_start_time = Math.min(event.start_time, event.end_time);
      this.corr_end_time = Math.max(event.start_time, event.end_time);
    }
    this.getImageUrl(this.shotType, this.shotType.name == "Rcvrlist")
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          if (res.corr) {
            this.corrMisc(res.corr);
          }
        }
      });
  }

  downloadPng() {
    resizeBase64Img(this.imageUrl, this.pngWidth, this.pngHeight).then(
      (resizedImage: string) => {
        const link = document.createElement("a");
        link.href = resizedImage;
        link.download = this.getShotPngNameStr();
        link.click();
      }
    );
  }

  checkStartTimeEndTime() {
    if (this.start_time) {
      if (this.start_time < 0) {
        this.start_time = 0;
      }
      if (this.start_time > this.totalTime - 1) {
        this.start_time = this.totalTime - 1;
      }
    }
    if (this.end_time) {
      if (this.end_time > this.totalTime) {
        this.end_time = this.totalTime;
      }
      if (this.end_time < this.start_time + 1) {
        this.end_time = this.start_time + 1;
      }
      if (this.end_time < 1) {
        this.end_time = 1;
      }
    }
  }

  checkStartTraceEndTrace() {
    let startTrace = this.startTrace;
    let endTrace = this.endTrace;
    startTrace = Math.max(startTrace, 1);
    endTrace = Math.min(endTrace, this.numTraces);
    if (startTrace > endTrace) {
      startTrace = endTrace - this.maxNumTracesShown;
      if (startTrace < 1) {
        startTrace = 1;
      }
    }
    this.startTrace = startTrace;
    this.endTrace = endTrace;
  }

  checkPhaseDiffScale() {
    if (this.phasediff_scale_factor_power < 0) {
      this.phasediff_scale_factor_power = 2;
    }
    if (this.phasediff_scale_factor_power > 5) {
      this.phasediff_scale_factor_power = 2;
    }
  }

  /**
   *
   * @param index
   * @description toggle spectrum on or off for a particular iteration
   */
  toggleSpectrumIter(index: number) {
    let iter = this.spectrumIterOptions[index];
    this.spectrumOn[iter][0] = !this.spectrumOn[iter][0];
    this.spectrumOn[iter][1] = !this.spectrumOn[iter][1];
    if (this.spectrumOn[iter][0] && this.spectrumOn[iter][1]) {
      if (this.spectrum[iter] == null) {
        this.getImageUrl(this.shotType, this.shotType.name == "Rcvrlist", iter)
          .pipe(take(1))
          .subscribe((res) => {
            if (res) {
              this.specMisc(res, iter);
            }
            this.updateRef();
          });
      } else {
        this.updateRef();
      }
    } else {
      this.updateRef();
    }
  }

  popColor() {
    if (this.availableSpectrumColors.length > 0) {
      return this.availableSpectrumColors.pop();
    } else {
      return getRandomHexColor();
    }
  }

  putColor(color) {
    this.availableSpectrumColors.splice(0, 0, color);
  }

  onIsUpdatingGraphEmit(event) {
    if (event != null) setTimeout(() => (this.is_updating_graph = event), 0);
  }

  onWidthEmit(event) {
    setTimeout(() => (this.shotImageWidth = event), 0);
    // console.log(this.shotImageWidth)
  }

  onSpectrumRangeEmit(event: number[]) {
    if (event.some((item, index) => item != this.spectrumRange[index])) {
      setTimeout(() => (this.spectrumRange = event), 0);
    }
  }

  onSpectrumRangeXEmit(event: number[]) {
    if (event.some((item, index) => item != this.spectrumRangeX[index])) {
      setTimeout(() => (this.spectrumRangeX = event), 0);
    }
  }

  screenshotPage(){
    const containerElement = document.getElementById("image-and-slice");
    if (this.screenshotWithShotsInfo){
      html2canvas(containerElement, { scrollY: -window.scrollY }).then((canvas) => {
        let url = canvas.toDataURL();
        let name = this.getShotPngNameStr();
        const link = document.createElement("a");
        link.href = url;
        link.download = name;
        link.click();
      });
    }else{
      html2canvas(containerElement.querySelector('#main-image'), { scrollY: -window.scrollY }).then((canvas) => {
        let url = canvas.toDataURL();
        let name = this.getShotPngNameStr();
        const link = document.createElement("a");
        link.href = url;
        link.download = name;
        link.click();
      });
    }

  }
}
