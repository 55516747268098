<div class="row">
  <div style="margin-left: 7%" *ngIf="shotType" class="col-md-4">
    <div class="color-div">
      <div
        *ngIf="
          shotType.name == 'RcvrList' &&
          !!rcvrScale &&
          typeOfSelectedRcvr.name == 'RcvrList'
        "
        id="colorbar"
      >
        <div
          *ngIf="shotType.filterSettings.value != 'corr'"
          class="color-bar-bwr"
        ></div>
        <div
          *ngIf="shotType.filterSettings.value == 'corr'"
          class="color-bar-gray"
        ></div>
        <div>
          <span>{{ rcvrScale.min.toExponential(3) }}</span>
          <span style="float: right">{{ rcvrScale.max.toExponential(3) }}</span>
        </div>
      </div>
      <div
        *ngIf="
          (shotType.name != 'RcvrList' ||
            typeOfSelectedRcvr.name != 'RcvrList') &&
          !!clipValue
        "
        id="colorbar"
      >
        <div class="color-bar-bwr"></div>
        <div>
          <span>{{ clipValue[0].toExponential(3) }}</span>
          <span class="toright">{{ clipValue[1].toExponential(3) }}</span>
        </div>
      </div>
      <div class="color-bar-text">{{ shotType.displayName }}</div>
    </div>
  </div>
  <div class="col-md-4" *ngIf="selectedPanel == shotType.numPanel">
    <div class="row">
      <div class="col-md-4">
        <mat-form-field class="interleave">
          <input
            matInput
            type="number"
            placeholder="Interleave extent"
            [(ngModel)]="interleave_extent"
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <button mat-stroked-button color="primary" (click)="updateInterleave()">
          Update
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="shotType.name == 'RcvrList'" class="col-md-4 row">
    <div class="col-md-4" style="margin-top: 2%">
      <span>Value: {{ rcvrValue.toExponential(2) }}</span>
    </div>
    <div class="col-md-4" style="margin-top: 2%">
      <span>Mean: {{ rcvrAvg.toExponential(2) }}</span>
    </div>
    <div class="col-md-4" style="margin-top: 2%">
      <span>Std: {{ rcvrStd.toExponential(2) }}</span>
    </div>
  </div>
</div>
