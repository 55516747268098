<div class="row mb-2 shot-header" *ngIf="showChart()">
  <div class="shot-labels card-title col-md-10 col-xs-11">
    <div class="d-flex">
      <div class="circle-grey"></div>
      <div class="shot-label">Unavailable Shots: {{ unavailableShots }}</div>
    </div>
    <div class="d-flex">
      <div class="circle-red"></div>
      <div class="shot-label">Available Shots: {{ availableShots }}</div>
    </div>
  </div>
  <div class="col-md-2 col-xs-1 text-right">
    <app-shot-select-chart-menu
      [jobDetail]="jobDetail"
      [settingsKey]="settingsKey"
      (menuChanged)="onMenuChanged($event)"
      (recieverIntervalChanged)="updateDisplayingRcvr($event)"
    ></app-shot-select-chart-menu>
  </div>
</div>
<div class="image-div">
  <div *ngIf="loadingImage" class="loading">
    <mat-progress-spinner
      mode="indeterminate"
      class="spinner"
      diameter="30"
    ></mat-progress-spinner>
    <span class="loading-text">loading image</span>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="showChart()">
        <app-shot-chart
          [height]="shotGraphHeight"
          [plotRcvrs]="plotRcvr"
          [rcvrData]="rcvrDataDisplay"
          [shotData]="shotData"
          [backgroundImage]="shotBgImg"
          [modelScale]="getModelScale(false)"
          [selectedShot]="selectedShot"
          [colourType]="selectedShotColourType"
          (onShotSelected)="onShotSelected($event)"
        ></app-shot-chart>
        <div class="row">
          <div class="col-md-6">
            <app-shot-block-settings
              [jobDetail]="jobDetail"
              [jobMeta]="jobMeta"
              [iteration]="sliceSettings.iteration"
            ></app-shot-block-settings>
          </div>
          <div class="col-md-6">
            <app-shot-select-form
              [jobDetail]="jobDetail"
              [settingsKey]="settingsKey"
              [selectedShot]="selectedShot"
              [startTrace]="startTrace"
              [endTrace]="endTrace"
            ></app-shot-select-form>
          </div>
        </div>
      </div>
      <div *ngIf="!showChart()">Select an iteration greater than 0</div>
    </div>
  </div>
</div>
