import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import {
  style,
  animate,
  transition,
  trigger,
} from "@angular/animations";import { Router } from "@angular/router";
import { JobDetail } from "../../../models/jobDetail";
import { CachedProjectService } from '../../../shared/services/cached-project.service';
import { Subscription, Observable } from 'rxjs';
import { CachedUserService } from '../../../shared/services/cached-user.service';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JobDialog } from '../../../models/jobDialog';
import { JobDeleteComponent } from '../dialogs/job-delete/job-delete.component';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginatorModule } from "@angular/material/paginator";

import { MatTableDataSource } from '@angular/material/table';
import { StoredSetting } from "../../../models/storedSetting";
import { StoredSettingsService } from "../../services/stored-settings.service";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { debounceTime, take } from "rxjs/operators";

interface File {
  name: string;
  size: number; // size in unit
  unit: string;
  bytes: number; // size in bytes
  type: string;
  iteration?: number;
}

interface FileMisc {
  totalSize: number;
  pageIndex: number;
  pageSize: number;
  // startIndex: number;
  // endIndex: number;
  pagedFiles: File[];
  files: File[];
  typeOrder?: string;
  sizeOrder?: string;
  nameOrder?: string;
  iterOrder?: string;
}

export interface FileSettings extends StoredSetting{
  fileMisc: {[key: string]: FileMisc};
}

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.less'],
  animations: [trigger('fadeInOut', [
    transition(':enter', [   // :enter is alias to 'void => *'
      style({ opacity: 0 }),
      animate(500, style({ opacity: 1 }))
    ]),
    transition(':leave', [   // :leave is alias to '* => void'
      animate(500, style({ opacity: 0 }))
    ])
  ])]
})

export class FilesComponent implements OnInit, OnDestroy {
  currentJob: JobDetail;
  displayContent: string;
  isLoading: boolean = true;
  jobControl: UntypedFormControl;
  filteredJobs: any;
  private _jobSubscription: Subscription;
  private _userSubscription: Subscription;
  files: File[] = [{name:"HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1HELLO1", size:50 , unit: "MB", bytes: 50 * 1024 * 1024, type: "txt"},
                   {name:"HELLO2", size:60 , unit: "MB", bytes: 60 * 1024 * 1024, type: "txt"},
                   {name:"HELLO3", size:70 , unit: "KB", bytes: 70 * 1024, type: "txt"},
                   {name:"HELLO4", size:80 , unit: "GB", bytes: 80 * 1024 * 1024 * 1024, type: "txt"},
                   {name:"HELLO5", size:40 , unit: "MB", bytes: 40 * 1024 * 1024, type: "ttr"},
                   {name:"HELLO6", size:70 , unit: "MB", bytes: 70 * 1024 * 1024, type: "ttr"},
                   {name:"HELLO7", size:80 , unit: "KB", bytes: 80 * 1024, type: "ttr"},
                   {name:"HELLO8", size:60 , unit: "KB", bytes: 60 * 1024, type: "ttr"},
                   {name:"HELLO9", size:50 , unit: "MB", bytes: 50 * 1024 * 1024, type: "sgy"},
                   {name:"HELLO10", size:40 , unit: "MB", bytes: 40 * 1024 * 1024, type: "sgy"},
                   {name:"HELLO11", size:30 , unit: "KB", bytes: 30 * 1024, type: "sgy"},
                   {name:"HELLO12", size:20 , unit: "GB", bytes: 20 * 1024 * 1024 * 1024, type: "sgy"},
                  ];
  private _filteredFiles: File[] = []; // a copy of filtered files
  private _files: File[] = []; // a copy of files
  pagedFiles: File[] = [];
  pageSize;
  pageIndex;
  totalItems;
  units = ["Bytes", "KB", "MB", "GB", "TB"];
  name: string;
  typeOrder: string = null;
  sizeOrder: string = null;
  nameOrder: string = null;
  iterOrder: string = null;
  //stores the files by name, won't be changed
  name2Files: Map<string, File[]> = new Map<string, File[]>();
  //stores all the info about the files by name
  name2Misc: Map<string, FileMisc> = new Map<string, FileMisc>();
  name2Name = {};
  splitByName: boolean = false;
  names: string[] = ['compare','lowpass-b4','lowpass','orion','other'];
  splitReady = false;
  settingsKey = "files";
  currentJobSubscription: Subscription;
  constructor(public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private projectService: CachedProjectService,
    private userService: CachedUserService,
    private storedSettingsService: StoredSettingsService,
    private router: Router) { 
      this.jobControl = new UntypedFormControl();
   }

  ngOnInit() {
    // console.log(this.files)
    // this._files = Array.from(this.files);
    // this._filteredFiles = Array.from(this._files);
    // this.pageSize = 20;
    // this.pageIndex = 0;
    // this.totalItems = this._files.length;
    // this.updatePagedFiles();

    if (this.currentJobSubscription != null) {
      this.currentJobSubscription.unsubscribe();
      this.currentJobSubscription = null;
    }

    this.currentJobSubscription = 
    this.projectService.currentJob.subscribe((d)=>{
      if (this.currentJob != null) {
        this.storeSettings();
      }
      if (d != null) {
        this.currentJob = d;
        this.projectService.getFilesList(this.currentJob.id, this.currentJob.basePath).pipe(take(1)).subscribe((res)=> {
          if (res) {
            res = res.map((file) => {
              let parts : string[] = file.name.split('.');
              let type, name;
              if (parts.length > 1) {
                type = parts.pop()!;
                name = parts.join('.');
              } else {
                type = "directory / raw-text";
                name = parts[0];
              }
              let entry: File = {name: file.name, bytes: file.size, unit:"KB", size:0, type: type};
              let breakByCP = file.name.split('CP');
              let breakByIter = file.name.split('iter');
              let iter = null;
              if (breakByCP.length > 1) {
                iter = Number.parseInt(breakByCP[1].substring(0,5));
              } else if (breakByIter.length > 1) {
                iter = Number.parseInt(breakByIter[1].substring(0,5));
              } 
              entry.iteration = iter;
              let size = file.size;
              let index = 0;
              while (size > 1024) {
                size = size / 1024
                index += 1;
              }
              entry.unit = this.units[index];
              entry.size = size;
              return entry;
            })

            this.files = res;
            this._files = Array.from(this.files);
            this._filteredFiles = Array.from(this._files);
            this.pageSize = 20;
            this.pageIndex = 0;
            this.totalItems = res.length;
            this.updatePagedFiles();
            this.names[3] = this.currentJob.prefix.toLocaleLowerCase();
            
            this.splitReady = false;
            this.updateName2Files();
            this.updateName2Misc();
            this.restoreSettings();
            this.sortSplits();
    
            this.splitReady = true;
            return null;
          } else {
            return null;
          }
        })
      }
    })
  }

  ngOnDestroy() {
    if (this._jobSubscription) {
      this._jobSubscription.unsubscribe();
    }
    if (this._userSubscription) {
      this._userSubscription.unsubscribe();
    }
    if (this.currentJobSubscription != null) {
      this.currentJobSubscription.unsubscribe();
      this.currentJobSubscription = null;
    }
  }

  updateFilteredFiles(): void {
    if (this.name == null || this.name == "") {
      this.files = Array.from(this._files);
    } else {
      this.files = this._files.filter((file) => {
        return file.name.toLocaleLowerCase().includes(this.name.toLocaleLowerCase());
      })
    }
    this.totalItems = this.files.length;
    this._filteredFiles = Array.from(this.files);

    if (this.nameOrder != null) {
      this.sortByName(false)
    } else if (this.sizeOrder != null) {
      this.sortBySize(false);
    } else if (this.typeOrder != null) {
      this.sortByType(false);
    } else if (this.iterOrder != null) {
      this.sortByIter(false);
    } else {
      this.updatePagedFiles();
    }
  }

  updateSplitFilteredFiles(name: string) :void {
    if (! this.name2Misc.has(name)) {
      throw new Error("name not found");
    }
    let misc = this.name2Misc.get(name);
    if (this.name2Name[name] == null || this.name2Name[name] == "") {
      misc.files = Array.from(this.name2Files.get(name));
    }
    misc.files = misc.files.filter((file) => {
      if (this.name2Name[name] == null || this.name2Name[name] == "") {
        return true;
      } else {
        return file.name.toLocaleLowerCase().includes(this.name2Name[name].toLocaleLowerCase());
      }
    });
    misc.totalSize = misc.files.length;
    misc.pageIndex = 0;
    misc.pagedFiles = Array.from(misc.files).slice(misc.pageIndex * misc.pageSize, misc.pageIndex * misc.pageSize + misc.pageSize);
    this.name2Misc.set(name, misc);
  }

  updateName2Files(): void {
    this.name2Files.clear();
    this.names.forEach((name) => {
      this.name2Files.set(name, []);
      this.name2Name[name] = "";
    });
    this._files.forEach((file) => {
      if (file.name.toLocaleLowerCase().includes("compare")) {
        let files = this.name2Files.get("compare");
        files.push(file);
        this.name2Files.set("compare", files);
      } else if (file.name.toLocaleLowerCase().includes("lowpass-b4")) {
        let files = this.name2Files.get("lowpass-b4");
        files.push(file);
        this.name2Files.set("lowpass-b4", files);
      } else if (file.name.toLocaleLowerCase().includes("lowpass")) {
        let files = this.name2Files.get("lowpass");
        files.push(file);
        this.name2Files.set("lowpass", files);
      } else if (file.name.toLocaleLowerCase().includes(this.names[3])) {
        let files = this.name2Files.get(this.names[3]);
        files.push(file);
        this.name2Files.set(this.names[3], files);
      } else {
        let files = this.name2Files.get("other");
        files.push(file);
        this.name2Files.set("other", files);
      }
    })
  }

  updateName2Misc() {
    for (let name of this.names) {
      let fileMisc = {
          totalSize: this.name2Files.get(name).length,
          pageSize: 20,
          pageIndex: 0,
          pagedFiles: this.name2Files.get(name).slice(0, 20),
          typeOrder: null,
          nameOrder: null,
          sizeOrder: null,
          iterOrder: null,
          files: this.name2Files.get(name),
      }
      this.name2Misc.set(name, fileMisc);
    }
  }

  sortSplits() {
    for (let name of this.names) {
      let fileMisc = this.name2Misc.get(name);
      if (fileMisc.typeOrder != null){
        this.sortSplitByType(name, false);
      } else if (fileMisc.nameOrder != null) {
        this.sortSplitByName(name, false);
      } else if (fileMisc.sizeOrder != null) {
        this.sortSplitBySize(name, false);
      } else if (fileMisc.iterOrder != null) {
        this.sortSplitByIter(name, false);
      }
    }
  }

  sortBySize(changeOrder = true) {
    this.typeOrder = null;
    this.nameOrder = null;
    this.iterOrder = null;
    if (changeOrder) {
      if (this.sizeOrder == null) {
        this.sizeOrder = "ascending";
      } else if (this.sizeOrder == "ascending") {
        this.sizeOrder = "descending";
      } else if (this.sizeOrder == "descending") {
        this.sizeOrder = null;
      }
    }
    if (this.sizeOrder != null) {
    let f = this.files.sort((a,b)=>{
      if (this.sizeOrder == "ascending") {
        return a.bytes - b.bytes;
      } else if (this.sizeOrder == "descending"){
        return b.bytes - a.bytes;
      }
    })
      this.files = Array.from(f);
    } else {
      this.files = Array.from(this._filteredFiles);
    }
    this.totalItems = this.files.length;
    this.updatePagedFiles();
  }

  sortSplitBySize(name:string, changeOrder=true) {
    if (! this.name2Misc.has(name)) {
      throw new Error("name not found");
    }
    let misc = this.name2Misc.get(name);
    misc.typeOrder = null;
    misc.nameOrder = null;
    misc.iterOrder = null;
    if (changeOrder) {
      if (misc.sizeOrder == null) {
        misc.sizeOrder = "ascending";
      } else if (misc.sizeOrder == "ascending") {
        misc.sizeOrder = "descending";
      } else if (misc.sizeOrder == "descending") {
        misc.sizeOrder = null;
      }
    }
    if (misc.sizeOrder != null) {
      let f = misc.files.sort((a,b)=>{
        if (misc.sizeOrder == "ascending") {
          return a.bytes - b.bytes;
        } else if (misc.sizeOrder == "descending"){
          return b.bytes - a.bytes;
        }
      })
      misc.files = Array.from(f);
    } else {
      misc.files = Array.from(this.name2Files.get(name)).filter((file) => {
        if (this.name2Name[name] == null || this.name2Name[name] == "") {
          return true;
        } else {
          return file.name.toLocaleLowerCase().includes(this.name2Name[name].toLocaleLowerCase());
        }
      });
    }
    misc.totalSize = misc.files.length;
    misc.pagedFiles = Array.from(misc.files).slice(misc.pageIndex * misc.pageSize, misc.pageIndex * misc.pageSize + misc.pageSize);
    this.name2Misc.set(name, misc);
  }

  sortByType(changeOrder = true) {
    this.sizeOrder = null;
    this.nameOrder = null;
    this.iterOrder = null;
    if (changeOrder) {
      if (this.typeOrder == null) {
        this.typeOrder = "ascending";
      } else if (this.typeOrder == "ascending") {
        this.typeOrder = "descending";
      } else if (this.typeOrder == "descending") {
        this.typeOrder = null;
      }
    }
    if (this.typeOrder != null) {
      let f = this.files.sort((a,b) => {
        if (a.type < b.type) {
          if (this.typeOrder == "ascending") {
            return -1;
          } else {
            return 1;
          }
        } else  {
          if (this.typeOrder == "ascending") {
            return 1;
          } else {
            return -1;
          }
        }
      })
      this.files = Array.from(f);
    } else {
      this.files = Array.from(this._filteredFiles);
    }
    this.totalItems = this.files.length;
    this.updatePagedFiles();
  }

  sortSplitByType(name:string, changeOrder=true) {
    if (! this.name2Misc.has(name)) {
      throw new Error("name not found");
    }
    let misc = this.name2Misc.get(name);
    misc.sizeOrder = null;
    misc.nameOrder = null;
    misc.iterOrder = null;
    if (changeOrder) {
      if (misc.typeOrder == null) {
        misc.typeOrder = "ascending";
      } else if (misc.typeOrder == "ascending") {
        misc.typeOrder = "descending";
      } else if (misc.typeOrder == "descending") {
        misc.typeOrder = null;
      }
    }
    if (misc.typeOrder != null) {
      let f = misc.files.sort((a,b) => {
        if (a.type < b.type) {
          if (misc.typeOrder == "ascending") {
            return -1;
          } else {
            return 1;
          }
        } else  {
          if (misc.typeOrder == "ascending") {
            return 1;
          } else {
            return -1;
          }
        }
      })
      misc.files = Array.from(f);
    } else {
      misc.files = Array.from(this.name2Files.get(name)).filter((file) => {
        if (this.name2Name[name] == null || this.name2Name[name] == "") {
          return true;
        } else {
          return file.name.toLocaleLowerCase().includes(this.name2Name[name].toLocaleLowerCase());
        }
      });
    }
    misc.totalSize = misc.files.length;
    misc.pagedFiles = Array.from(misc.files).slice(misc.pageIndex * misc.pageSize, misc.pageIndex * misc.pageSize + misc.pageSize);
    this.name2Misc.set(name, misc);
  }

  sortByName(changeOrder = true) {
    this.sizeOrder = null;
    this.typeOrder = null;
    this.iterOrder = null;
    if (changeOrder) {
      if (this.nameOrder == null) {
        this.nameOrder = "ascending";
      } else if (this.nameOrder == "ascending") {
        this.nameOrder = "descending";
      } else if (this.nameOrder == "descending") {
        this.nameOrder = null;
      }
    }
    if (this.nameOrder != null) {
      let f = this.files.sort((a,b) => {
        if (a.name < b.name) {
          if (this.nameOrder == "ascending") {
            return -1;
          } else {
            return 1;
          }
        } else  {
          if (this.nameOrder == "ascending") {
            return 1;
          } else {
            return -1;
          }
        }
      })
      this.files = Array.from(f);
    } else {
      this.files = Array.from(this._filteredFiles);
    }
    this.totalItems = this.files.length;
    this.updatePagedFiles();
  }

  sortSplitByName(name: string, changeOrder = true) {
    if (! this.name2Misc.has(name)) {
      throw new Error("name not found");
    }
    let misc = this.name2Misc.get(name);
    misc.typeOrder = null;
    misc.sizeOrder = null;
    misc.iterOrder = null;
    if (changeOrder) {
      if (misc.nameOrder == null) {
        misc.nameOrder = "ascending";
      } else if (misc.nameOrder == "ascending") {
        misc.nameOrder = "descending";
      } else if (misc.nameOrder == "descending") {
        misc.nameOrder = null;
      }
    }
    if (misc.nameOrder != null) {
      let f = misc.files.sort((a,b) => {
        if (a.name < b.name) {
          if (misc.nameOrder == "ascending") {
            return -1;
          } else {
            return 1;
          }
        } else  {
          if (misc.nameOrder == "ascending") {
            return 1;
          } else {
            return -1;
          }
        }
      })
      misc.files = Array.from(f);
    } else {
      misc.files = Array.from(this.name2Files.get(name)).filter((file) => {
        if (this.name2Name[name] == null || this.name2Name[name] == "") {
          return true;
        } else {
          return file.name.toLocaleLowerCase().includes(this.name2Name[name].toLocaleLowerCase());
        }
      });
    }
    misc.totalSize = misc.files.length;
    misc.pageIndex = 0;
    misc.pagedFiles = Array.from(misc.files).slice(0, misc.pageSize);
    this.name2Misc.set(name, misc);
  }

  sortByIter(changeOrder = true) {
    this.sizeOrder = null;
    this.typeOrder = null;
    this.nameOrder = null;
    if (changeOrder) {
      if (this.iterOrder == null) {
        this.iterOrder = "ascending";
      } else if (this.iterOrder == "ascending") {
        this.iterOrder = "descending";
      } else if (this.iterOrder == "descending") {
        this.iterOrder = null;
      }
    }
    if (this.iterOrder != null) {
      let f = this.files.sort((a,b) => {
        if ( a.iteration == null && b.iteration == null) {
          return 0;
        } else if (a.iteration == null) {
          if (this.iterOrder == "ascending") {
            return -1;
          } else {
            return 1;
          }
        } else if (b.iteration == null) {
          if (this.iterOrder == "ascending") {
            return 1;
          } else {
            return -1;
          }
        } else if (a.iteration < b.iteration) {
          if (this.iterOrder == "ascending") {
            return -1;
          } else {
            return 1;
          }
        } else  {
          if (this.iterOrder == "ascending") {
            return 1;
          } else {
            return -1;
          }
        }
      })
      this.files = Array.from(f);
    } else {
      this.files = Array.from(this._filteredFiles);
    }
    this.totalItems = this.files.length;
    this.updatePagedFiles();
  }

  sortSplitByIter(name: string, changeOrder = true) {
    if (! this.name2Misc.has(name)) {
      throw new Error("name not found");
    }
    let misc = this.name2Misc.get(name);
    misc.typeOrder = null;
    misc.sizeOrder = null;
    misc.nameOrder = null;
    if (changeOrder) {
      if (misc.iterOrder == null) {
        misc.iterOrder = "ascending";
      } else if (misc.iterOrder == "ascending") {
        misc.iterOrder = "descending";
      } else if (misc.iterOrder == "descending") {
        misc.iterOrder = null;
      }
    }
    if (misc.iterOrder != null) {
      let f = misc.files.sort((a,b) => {
        if ( a.iteration == null && b.iteration == null) {
          return 0;
        } else if (a.iteration == null) {
          if (misc.iterOrder == "ascending") {
            return -1;
          } else {
            return 1;
          }
        } else if (b.iteration == null) {
          if (misc.iterOrder == "ascending") {
            return 1;
          } else {
            return -1;
          }
        } else if (a.iteration < b.iteration) {
          if (misc.iterOrder == "ascending") {
            return -1;
          } else {
            return 1;
          }
        } else  {
          if (misc.iterOrder == "ascending") {
            return 1;
          } else {
            return -1;
          }
        }
      })
      misc.files = Array.from(f);
    } else {
      misc.files = Array.from(this.name2Files.get(name)).filter((file) => {
        if (this.name2Name[name] == null || this.name2Name[name] == "") {
          return true;
        } else {
          return file.name.toLocaleLowerCase().includes(this.name2Name[name].toLocaleLowerCase());
        }
      });
    }
    misc.totalSize = misc.files.length;
    misc.pageIndex = 0;
    misc.pagedFiles = Array.from(misc.files).slice(0, misc.pageSize);
    this.name2Misc.set(name, misc);
  }


  deleteJob(job: JobDetail) {

    let dialogRef = this.dialog.open(JobDeleteComponent, {
      height: '225px',
      width: '275px',
      data: {
        id: job.id,
        name: job.name,
        projectId: null
      }
    });

    dialogRef.afterClosed().subscribe((result: JobDialog) => {
      if (!result)
        return;
      this.projectService.deleteJob(result.id).subscribe(data => {
        this.router.navigate(['/projects']);
        return;
      }, (error) => {
        this.snackBar.open("Sorry, the job could not be deleted", null, { duration: 2000 });
      });
    });
  }

  onPageChange(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePagedFiles();
  }

  onSplitPageChange(event: PageEvent, name: string) {
    let fileMisc = this.name2Misc.get(name);
    fileMisc.pageIndex = event.pageIndex;
    fileMisc.pageSize = event.pageSize;
    let startIndex = fileMisc.pageIndex * fileMisc.pageSize;
    let endIndex = startIndex + fileMisc.pageSize;
    fileMisc.pagedFiles = fileMisc.files.slice(startIndex, endIndex);
    this.name2Misc.set(name, fileMisc);
  }

  // Function to update the paged files based on the current pagination settings
  updatePagedFiles() {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedFiles = this.files.slice(startIndex, endIndex);
  }

  storeSettings(){
    let fileMisc = {}
    this.name2Misc.forEach((value, key) => {
      fileMisc[key] = value;
    })
    let setting:FileSettings = {
      id: this.currentJob.id,
      projectId: this.currentJob.projectId,
      fileMisc: fileMisc,
      iteration: null,
    }
    this.storedSettingsService.setSettings<FileSettings>(this.settingsKey, setting, SettingTypes.FileSettings);
  }

  // assuming name2Misc is already populated, restore the order
  restoreSettings(){
    console.log("restore settings")
    let setting = this.storedSettingsService.getSettings<FileSettings>(this.settingsKey, this.currentJob.id, SettingTypes.FileSettings);
    if (setting != null) {
      if (setting.id == this.currentJob.id) {
        for (let key in setting.fileMisc) {
          let misc = this.name2Misc.get(key);
          misc['iterOrder'] = setting.fileMisc[key]['iterOrder'];
          misc['nameOrder'] = setting.fileMisc[key]['nameOrder'];
          misc['sizeOrder'] = setting.fileMisc[key]['sizeOrder'];
          misc['typeOrder'] = setting.fileMisc[key]['typeOrder'];
          this.name2Misc.set(key, misc);
        }
      }
    }
  }

}


