import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { JobDetail } from "../../../models/jobDetail";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import {
  SliceSettings,
  SliceSettingsService,
} from "../../services/slice-settings.service";
import { StoredSettingsService } from "../../services/stored-settings.service";
declare var $: any;
@Component({
  selector: "app-project-settings-bar",
  templateUrl: "./project-settings-bar.component.html",
  styleUrls: ["./project-settings-bar.component.less"],
})
export class ProjectSettingsBarComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() jobDetail: JobDetail;
  @Input() settingsKey: string = "demo";
  @Input() jobs: any[];
  @Input() bottom: string;
  sliceSettings: SliceSettings;
  step = 10;
  visible = false;
  offset = 450;
  sliderSettings$ = new Subject();
  sliderSubscription: Subscription;
  private settingsSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sliceSettingsService: SliceSettingsService,
    private storedSettingsService: StoredSettingsService
  ) {}

  ngOnInit() {
    this.loadPage(this.route.snapshot.queryParamMap);
    this.sliderSubscription = this.sliderSettings$
      .pipe(debounceTime(100))
      .subscribe((params) => {
        this.setSliderSettings();
      });
    this.settingsSubscription = this.storedSettingsService.settingsUpdated$
      .pipe(
        filter((settingTypes) =>
          settingTypes.some((r) => [SettingTypes.SliceSettings].indexOf(r) >= 0)
        )
      )
      .subscribe(() => {
        this.restoreSettings();
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    const change = changes.jobDetail;
    if (change) {
      if (change.currentValue && !change.isFirstChange()) {
        this.loadPage(this.route.snapshot.queryParamMap);
      }
    }
  }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    this.visible = this.offset + 50 < $(document).scrollTop();
  }
  onSliderChanged(event) {
    this.sliderSettings$.next(this.sliceSettings);
  }
  onJobClicked(job: JobDetail) {
    this.router.navigate(["/projects", job.id, "demo"], {
    queryParamsHandling: 'preserve'
});
  }

  loadPage(queryParams: ParamMap) {
    this.restoreSettings();
  }
  setSliderSettings() {
    this.storedSettingsService.setSettings<SliceSettings>(
      this.settingsKey,
      {
        ...this.sliceSettings,
        id: this.jobDetail.id,
        projectId: this.jobDetail.projectId,
      },
      SettingTypes.SliceSettings
    );

    this.storedSettingsService.settingsUpdated([
      SettingTypes.SliceSettings,
      SettingTypes.Shot,
    ]);
  }
  restoreSettings() {
    var storedSliceSettings =
      this.storedSettingsService.getSettings<SliceSettings>(
        this.settingsKey,
        this.jobDetail.id,
        SettingTypes.SliceSettings
      );
    this.sliceSettings = this.sliceSettingsService.setDefaults(
      storedSliceSettings,
      this.jobDetail
    );
  }
  ngOnDestroy(): void {
    if (this.sliderSubscription) {
      this.sliderSubscription.unsubscribe();
    }
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }
}
