<div #graphContainer >
  <div class="row" style="margin-left: 4%; white-space: nowrap">
    <span class="col-md-2">trace: {{traceNumber | number: "1.0-0"}}</span>
    <span class="col-md-2">time: {{timeNumber | number: "1.0-3"}}</span>
    <span *ngIf="_isInterleave" class="col-md-2" style="white-space: nowrap;">from: {{interleaveType}}</span>
    <div class="zoom-factor">Zoom Factor: {{zoomFactor}}</div>
  </div>
  <svg id="svg" #graph></svg>
</div>

