import { Component, Input } from "@angular/core";
import { ProjectService } from "../../shared/services/project.service";
import { take } from "rxjs/operators";
import { Field } from "../../models/field";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Project } from "../../models/project";

@Component({
  selector: "app-fields-listing",
  templateUrl: "./fields-listing.component.html",
  styleUrls: ["./fields-listing.component.less"],
})
export class FieldsListingComponent {
  @Input("demoUser") demoUser: boolean = false;
  fields: Field[];
  fieldModalVisible: boolean = false;
  fieldDescription: string = "";
  fieldName: string = "";
  isLoading: boolean = false;
  fieldProjects: { [key: string]: { id: string; name: string }[] } = {};
  activeField: Field;
  activeFieldProjects: string[] = [];
  allProjects: {
    id: string;
    name: string;
    description: string;
    field?: string;
  }[];

  constructor(
    private projectService: ProjectService,
    private notificationService: NzNotificationService,
    private router: Router
  ) {
    this.getFields();
    this.getAllProjects();
  }

  getAllProjects() {
    this.isLoading = true;
    this.projectService
      .getAllProjects()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.allProjects = res;
          this.isLoading = false;
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error ||
              "There was an error when getting all projects. Please try later"
          );
        },
      });
  }

  getFields() {
    this.isLoading = true;
    this.projectService
      .getAllFields()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.fields = res;
          this.isLoading = false;
        },
        error: (err) => {
          console.log("err", err);
          this.notificationService.error(
            "Error",
            err.error?.error || "There was an unexepected error"
          );
          this.isLoading = false;
        },
      });
  }

  createNewField() {
    // if clicked while a field is being made already, skip request
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.projectService
      .createField({
        name: this.fieldName,
        description: this.fieldDescription,
        projects: this.activeFieldProjects,
      })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.notificationService.success(
            "Success",
            "Field created: " + res.name
          );
          this.fieldDescription = "";
          this.fieldName = "";
          this.activeFieldProjects = [];
          this.fieldProjects = {};
          this.isLoading = false;
          this.getFields();
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error || "An unexpected error occured!"
          );
          this.isLoading = false;
        },
      });
  }

  goToProject(project: any) {
    this.router.navigate(["project", project.id, "parameter-view"]);
  }

  getFieldProjects(field: Field, assignActiveFieldProjects: boolean = false) {
    if (field.id in this.fieldProjects) {
      if (assignActiveFieldProjects) {
        this.activeFieldProjects = this.fieldProjects[field.id]?.map(
          (p) => p.id
        );
      }
      return;
    }
    this.isLoading = true;
    this.projectService
      .getFieldById(field.id)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.fieldProjects[res.id] = res.projects;
          if (assignActiveFieldProjects)
            this.activeFieldProjects = this.fieldProjects[field.id]?.map(
              (p) => p.id
            );
          this.isLoading = false;
        },
        error: (err) => {
          console.log("get fields projects error", err);
          this.isLoading = false;
        },
      });
  }

  onEditField() {
    // if clicked while a field is being editted already, skip request
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.projectService
      .editField({
        id: this.activeField.id,
        name: this.fieldName,
        description: this.fieldDescription,
        projects: this.activeFieldProjects,
      })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.notificationService.success(
            "Success",
            "Field editted: " + res.name
          );
          this.fieldDescription = "";
          this.fieldName = "";
          this.activeFieldProjects = [];
          this.activeField = null;
          this.fieldProjects = {};
          this.isLoading = false;
          this.getFields();
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error || "An unexpected error occured!"
          );
          this.isLoading = false;
        },
      });
  }

  editField(field: Field) {
    this.getFieldProjects(field, true);
    this.getAllProjects();
    this.activeField = field;
    this.fieldName = field.name;
    this.fieldDescription = field.description;
    this.fieldModalVisible = true;
  }

  closeFieldModal() {
    this.activeField = null;
    this.fieldName = "";
    this.fieldDescription = "";
    this.fieldModalVisible = false;
  }
}
