<!-- <div class="row">
  <div class="col-md-6">
    <mat-form-field>
      <mat-label>Block Number</mat-label>
      <mat-select [(ngModel)]="selectedBlock">
        <mat-option
          *ngFor="let blockRange of blockRanges; let number = index"
          [value]="blockRange.block"
        >
          {{ blockRange.blockNo }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div> -->
<div class="row" *ngIf="selectedBlock">
  <div class="col-md-12">
    <div class="block-row">
      <div class="form-group">
        <label class="col-sm-7">Iteration:</label>
        <span class="col-sm-4 text-left">{{ iteration }}</span>
      </div>
      <div class="form-group">
        <label class="col-sm-7">Block:</label>
        <span class="col-sm-4 text-left">{{ selectedBlockRange.blockNo }}</span>
      </div>
      <div class="form-group">
        <label class="col-sm-7">Frequency:</label>
        <span class="col-sm-4 text-left">{{ selectedBlock.freq }}</span>
      </div>
      <div class="form-group">
        <label class="col-sm-7">Amplitude:</label>
        <span class="col-sm-4 text-left">{{ selectedBlock.amplitude }}</span>
      </div>
    </div>
  </div>
</div>
