<div class="actions">
  <a class="actions__item" [matMenuTriggerFor]="heightMenu"
    ><i class="zmdi zmdi-hc-2x zmdi-more-vert"></i
  ></a>
  <mat-menu #heightMenu="matMenu">
    <div mat-menu-item style="text-overflow: clip">
      Height
      <mat-slider
        [min]="200"
        [max]="800"
        step="40"
        [(ngModel)]="shotGraphHeight"
       #ngSlider><input matSliderThumb (change)="onMenuChanged()" #ngSliderThumb="matSliderThumb" /></mat-slider>
    </div>
    <button mat-menu-item [matMenuTriggerFor]="shotColourMenu">
      Shot Colour
    </button>
    <button mat-menu-item (click)="updateBgRange()">Reset Colour Scale</button>
    <mat-form-field style="margin-left: 5%">
      <mat-label>Reciever Sample Interval</mat-label>
      <input
        matInput
        [(ngModel)]="rcvrInterval"
        (click)="$event.stopPropagation()"
        (focusout)="updateDisplayingRcvr()"
      />
    </mat-form-field>
    <mat-checkbox
      style="margin-left: 5%"
      [checked]="plotRcvr"
      (change)="setShowReceiver($event.checked)"
      >Show Receivers</mat-checkbox
    >
  </mat-menu>
  <mat-menu #shotColourMenu="matMenu">
    <button
      mat-menu-item
      *ngFor="let colourType of shotColourTypes"
      (click)="setColourType(colourType)"
    >
      <span>{{ colourType.name }}</span>
      <i
        *ngIf="selectedShotColourType === colourType.id"
        class="zmdi zmdi-check"
        style="margin-left: 2px"
      ></i>
    </button>
  </mat-menu>
</div>
