import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Observable, Subscription, forkJoin, of } from "rxjs";
import { CostItem } from "../../../models/costItem";
import { JobDetail } from "../../../models/jobDetail";
import { PlotDetail } from "../../../models/plotDetail";
import { ShotOverlayDto } from "../../../models/shotOverlayDto";
import { ShotType } from "../../../models/shotType";
import { ImageLoaderService } from "../../../shared/services/image-loader.service";
import { ProjectService } from "../../../shared/services/project.service";
import {
  SliceSettings,
  SliceSettingsService,
} from "../../services/slice-settings.service";
import { ShotChartComponent } from "../shot-chart/shot-chart.component";
import { range, cloneDeep } from "lodash";
import { ShotSettings } from "../../../models/shotSettings";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { StoredSettingsService } from "../../services/stored-settings.service";
import { ShotChartMenuSettings } from "../shot-select-chart-menu/shot-select-chart-menu.component";
import { ShotSettingsService } from "../../services/shot-settings.service";
import { filter, finalize, map, switchMap } from "rxjs/operators";
import { Cost } from "../../../models/cost";

@Component({
  selector: "app-shot-select-container",
  templateUrl: "./shot-select-container.component.html",
  styleUrls: ["./shot-select-container.component.less"],
})
export class ShotSelectContainerComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() jobDetail: JobDetail;
  @Input() jobMeta: any;
  @Input() settingsKey: string;
  shotType: ShotType;
  sliceSettings: SliceSettings;
  currentIteration: number = null;
  totalIteration: number;
  shotGraphHeight: number = 200;
  plotRcvr = false;
  rcvrIndices: number[];
  rcvrDataDisplay: any;
  startTrace: number;
  endTrace: number;
  rcvrInterval = 50;
  is2D: boolean = false;
  shotData: any;
  shotBgImg: string;
  selectedShot: number;
  selectedShotColourType: string = "traces_fit";
  loadingShots = true;
  scaleRatio: any;
  availableShots: number;
  availableShotIds: number[];
  unavailableShots: number;
  selectedTraceFit: any[];
  selectedShotFunctional: number;
  switchIteration: any;
  itersOfSameShot: any;
  imageUrl: any;
  sliceNum: any;
  nx3: number;
  shotTypes: ShotType[] = this.sliceSettingsService.shotTypes;
  typeOfSelectedRcvr: ShotType;
  tableTypes: any[];
  rcvrDataFull: any;
  rcvrData: any;
  rcvrScale = { min: 0, max: 100 };
  rcvrValue = 0;
  rcvrColorMap = ["blue", "white", "red"];
  rcvrAvg = 0;
  rcvrStd = 0;
  selectedShotPanel: number = 0;
  queryInterval = 1;
  private _maxValZ: any;
  private _minValZ: any;

  private x2IsInline: boolean;
  @ViewChild(ShotChartComponent) chart: ShotChartComponent;
  selectedBlk: any;
  currentScaling: any;
  currentClipping: number;
  startTraceManual: number;
  endTraceManual: number;
  private shotsImageSubscriber: Subscription;
  private shotSettingsSubscriber: Subscription;
  private storageSettingsSubscriber: Subscription;
  private settingsSubscription: Subscription;
  maxNumTracesShown: number = 1000;
  numTraces: number;
  loadingImage: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private imageService: ImageLoaderService,
    private projectService: ProjectService,
    private sliceSettingsService: SliceSettingsService,
    private storedSettingsService: StoredSettingsService,
    private shotSettingsService: ShotSettingsService
  ) {}

  ngOnInit() {
    this.loadPage(this.route.snapshot.queryParamMap);
    this.initSettings();

    this.settingsSubscription =
      this.storedSettingsService.settingsUpdated$.subscribe((settingTypes) => {
        if (settingTypes.some((type) => type === SettingTypes.Depth)) {
          this.restoreSettings();
          // this.sliceNum = this.sliceSettings.depth;
          this.updateShotsBgImage();
        } else {
          this.loadPage(this.route.snapshot.queryParamMap);
        }
      });

    this.shotSettingsSubscriber =
      this.shotSettingsService.shotSettings$.subscribe((settings) => {
        this.startTrace = settings.startTrace;
        this.endTrace = settings.endTrace;
        this.startTraceManual = settings.startTraceManual;
        this.endTraceManual = settings.endTraceManual;
        this.selectedShotPanel = settings.panel;
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    const change = changes.jobDetail;
    if (change.currentValue && !change.isFirstChange()) {
      this.loadPage(this.route.snapshot.queryParamMap);
    }
  }
  loadPage(params: ParamMap) {
    this.restoreSettings();
    if (this.showChart()) {
      // TODO: this is hacky, but it works for now
      setTimeout(() => {
        this.updateShotsGraph();
      }, 500);
    }
  }
  initSettings() {
    if (!this.typeOfSelectedRcvr) this.typeOfSelectedRcvr = this.shotTypes[11];
    this.tableTypes = this.transTableTypes(12);

    if (!this.typeOfSelectedRcvr) this.typeOfSelectedRcvr = this.shotType[11];
    if (this.shotType) {
      //this.shotType = this.types.find(t => t.name==this.shotType.name)
    } else {
      this.shotType = this.shotTypes[0];
    }
    this.x2IsInline = this.jobDetail.x2_is_inline;
    this.nx3 = this.jobDetail.modelGrid.nx3;
    this.is2D = this.jobDetail.modelGrid.is2d;
    if (!this.sliceNum && this.nx3) {
      this.sliceNum = Math.floor(this.nx3 / 2);
    }
    this.currentIteration = this.sliceSettings.iteration;
  }
  onMenuChanged(settings: ShotChartMenuSettings) {
    this.shotGraphHeight = settings.shotGraphHeight;
    this.plotRcvr = settings.plotRcvr;
    this._minValZ = settings.minValZ;
    this._maxValZ = settings.maxValZ;
    this.selectedShotColourType = settings.selectedShotColourType;

    this.refreshChart();
  }
  refreshChart() {
    this.loadingImage = false;
    if (this.plotRcvr || this.selectedShot) {
      this.getRcvrData()
        .pipe(
          finalize(() => {
            this.loadingImage = false;
          })
        )
        .subscribe((data) => {
          setTimeout(() => {
            this.chart.refresh();
          }, 0);
        });
    } else {
      setTimeout(() => {
        this.chart.refresh();
      }, 0);
    }
  }
  transTableTypes(chunk: number) {
    let res = [];
    let size = this.shotTypes.length / chunk;
    let idx = 0;
    while (idx < this.shotTypes.length) {
      res.push(this.shotTypes.slice(idx, idx + size));
      idx += size;
    }
    res.push(this.shotTypes.slice(idx, this.shotTypes.length));
    return res;
  }
  private updateShotsGraph() {
    this.loadingShots = true;
    this.shotsImageSubscriber = this.getIterationShotInfo()
      .pipe(
        finalize(() => {
          if (
            this.shotData &&
            this.selectedShot &&
            this.shotData
              .filter((s) => s.hasTraceFile)
              .map((s) => s.shotId)
              .includes(this.selectedShot)
          ) {
            // TODO: check this
            // this.updateSelectedShotInfo();
          } else {
            this.switchIteration = null;
            this.itersOfSameShot = null;
            this.selectedShot = null;
            this.imageUrl = null;
          }
        }),
        switchMap((shotsInfo) => {
          let shotsData = this.getShotData(shotsInfo);
          if (!shotsData) {
            return of(null);
          }
          this.shotData = shotsData;
          this.scaleRatio = this.shotData[0].scale_ratio;
          this.availableShots = shotsData.filter((s) => s.hasTraceFile).length;
          this.availableShotIds = shotsData
            .filter((s) => s.hasTraceFile)
            .map((x) => x.shotId);
          this.unavailableShots = shotsData.length - this.availableShots;
          if (this.selectedShot) {
            let shot = this.shotData.find((s) => s.shotId == this.selectedShot);
            if (shot) {
              this.selectedTraceFit = [shot.traces_fit, shot.traces_low];
              this.selectedShotFunctional = Number(
                (shot.fnlc / this.scaleRatio).toFixed(2)
              );
            }
          }
          if (!this._maxValZ || !this._minValZ) {
            return this.projectService.getRanges(
              this.jobDetail.id,
              "Vp",
              this.jobDetail.iterationsComplete - 1,
              { depth: this.sliceNum }
            );
          }
          return of(null);
        }),
        switchMap((range) => {
          if (range) {
            this._minValZ = range.min_val;
            this._maxValZ = range.max_val;
          }
          return this.projectService.getSlice(
            this.jobDetail.id,
            "Vp",
            this.sliceSettings.iteration - 1,
            3,
            this.sliceNum,
            this.is2D,
            null,
            this._minValZ,
            this._maxValZ
          );
        }),
        switchMap((plotDetail: PlotDetail) => {
          if (!plotDetail || !plotDetail.url) {
            return of(null);
          }
          return this.imageService.getImageBase64(plotDetail.url);
        })
      )
      .subscribe((sliceImage: string) => {
        this.loadingShots = false;
        this.shotBgImg = sliceImage;
        this.refreshChart();

        // TODO: is this still needed
        // this.updateMainImage();
      });
  }
  private getIterationShotInfo(): Observable<
    [Cost, ShotOverlayDto[], number[]]
  > {
    return forkJoin([
      this.projectService.getCosts(
        this.jobDetail.id,
        this.totalIteration,
        this.x2IsInline
      ),
      this.projectService.getShotOverlay(
        this.jobDetail.id,
        this.sliceSettings.iteration
      ),
      this.projectService.getTraceFilesByIteration(
        this.jobDetail.id,
        this.sliceSettings.iteration
      ),
    ]);
  }
  private getShotData(shotsInfo) {
    if (
      !(
        shotsInfo &&
        shotsInfo[0] &&
        shotsInfo[1] &&
        shotsInfo[2] &&
        shotsInfo[3]
      )
    ) {
      this.shotData = null;
      return;
    }
    let costs: CostItem[] = shotsInfo[0].costItems;
    let shotOverlays: ShotOverlayDto[] = shotsInfo[1];
    let availableShots: number[] = shotsInfo[2] || [];
    let firstShot: ShotOverlayDto[] = shotsInfo[3];
    if (costs && shotOverlays) {
      return shotOverlays.map((s) => {
        let costItem = costs.find((c) => c.shotNo == s.shot_id);
        let available = availableShots.includes(s.shot_id);
        let scaleRatio =
          Math.max.apply(
            Math,
            firstShot.map((f) => f.fnlc)
          ) / 100.0;
        return {
          y: costItem ? (this.is2D ? costItem.sz : costItem.sy) : null,
          x: costItem ? costItem.sx : null,
          shotId: s.shot_id,
          hasTraceFile: available,
          traces_fit: s.traces_fit,
          traces_low: s.dc_low,
          fnlc: s.fnlc,
          scale_ratio: scaleRatio,
        };
      });
    }
  }
  getModelScale(forRcvr = false) {
    let height: number;
    let gridSpace = this.jobDetail.modelGrid.dx;
    if (this.is2D) {
      height = -this.jobDetail.modelGrid.nx3 * gridSpace;
    } else {
      height = this.jobDetail.modelGrid.nx1 * gridSpace;
    }

    return { x: this.jobDetail.modelGrid.nx2 * gridSpace, y: height };
  }
  onShotSelected(shotId: number) {
    // let shot = this.shotData.find(s => s.shotId == shotId);
    // this.selectedTraceFit = [shot.traces_fit, shot.traces_low];
    // this.selectedShotFunctional = Number((shot.fnlc / this.scaleRatio).toFixed(2));
    // if (!shot.hasTraceFile) return;
    // this.loadingImage = true;
    // if (this.shotType.name == "RcvrList")
    //   this.typeOfSelectedRcvr = this.shotType;
    // this.updateSelectedShotInfo();
    // this.updateUrl();
    this.selectedShot = shotId;
    this.storeSettings();
    // this.sliceSettings.shotId = shotId;
    // this.sliceSettingsService.setQueryStringParams(
    //   this.sliceSettings,
    //   this.route
    // );
  }
  updateDisplayingRcvr() {
    if (!this.rcvrDataFull) return;
    let criteria;
    if (this.shotType.name == "RcvrList") {
      let selectedIdx = this.rcvrIndices;
      criteria = function (i) {
        return selectedIdx.includes(i);
      };
    } else {
      let startIdx = Math.floor(this.startTrace || 0);
      let endIdx = Math.floor(this.endTrace || 0);
      criteria = function (i) {
        return i >= startIdx && i <= endIdx;
      };
    }
    let displayingData = cloneDeep(this.rcvrDataFull).map((r: any, i) => {
      r.hasTraceFile = criteria(i);
      return r;
    });
    this.rcvrDataDisplay = displayingData.filter(
      (r, i) => i % this.rcvrInterval == 0
    );
  }

  private updateShotsBgImage() {
    this.projectService
      .getRanges(
        this.jobDetail.id,
        "Vp",
        this.jobDetail.iterationsComplete - 1,
        {
          depth: this.sliceNum,
        }
      )
      .pipe(
        switchMap((range) => {
          if (!this._minValZ || !this._maxValZ) {
            this._minValZ = range.min_val;
            this._maxValZ = range.max_val;
          }
          return this.projectService.getSlice(
            this.jobDetail.id,
            "Vp",
            this.currentIteration - 1,
            3,
            this.sliceNum,
            this.is2D,
            null,
            this._minValZ,
            this._maxValZ
          );
        }),
        switchMap((plotDetail: PlotDetail) => {
          if (!plotDetail) return of(null);
          return this.imageService.getImageBase64(plotDetail.url);
        })
      )
      .subscribe((sliceImage: string) => {
        this.shotBgImg = sliceImage;
      });
  }
  showChart() {
    if (!this.sliceSettings) {
      return false;
    }
    return this.sliceSettings.iteration > 0;
  }
  private storeSettings() {
    let lastSettings = this.storedSettingsService.getSettings<ShotSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Shot,
      this.jobDetail.projectId
    );
    this.storedSettingsService.setSettings<ShotSettings>(
      this.settingsKey,
      {
        ...lastSettings,
        id: this.jobDetail.id,
        projectId: this.jobDetail.projectId,
        selectedShot: this.selectedShot,
        shotType: this.shotType,
        sliceNum: this.sliceNum,
        startTrace: this.startTrace,
        endTrace: this.endTrace,
        startTraceManual: this.startTraceManual,
        endTraceManual: this.endTraceManual,
        scaling: this.currentScaling,
        clipping: this.currentClipping,
        minValZ: this._minValZ,
        maxValZ: this._maxValZ,
        availableShotIds: this.availableShotIds,
      },
      SettingTypes.Shot
    );
    this.storedSettingsService.settingsUpdated([SettingTypes.Shot]);
  }

  private getRcvrData() {
    this.loadingImage = true;
    if (!this.selectedShot) {
      return of(true);
    }
    let rcvrType =
      this.shotType.name == "RcvrList"
        ? this.shotType
        : this.shotTypes.find((t) => t.name == "RcvrList");
    rcvrType.numPanel = rcvrType.filterSettings.value === "freq" ? 3 : 1;
    let filterParams = {
      angles: rcvrType.filterSettings.angles.split(",").map((a) => parseInt(a)),
      offset: rcvrType.filterSettings.offset,
      angle_offset: rcvrType.filterSettings.angle_offset,
      value: rcvrType.filterSettings.value,
      panel: this.selectedShotPanel,
      freq: rcvrType.filterSettings.freq,
    };
    this.queryInterval = rcvrType.filterSettings.interval;
    return this.projectService
      .getRcvrData(
        this.jobDetail.id,
        this.sliceSettings.iteration,
        this.selectedShot,
        this.shotType.fwd,
        filterParams,
        this.jobDetail.x2_is_inline
      )
      .pipe(
        map((data) => {
          if (!data) {
            this.rcvrData = null;
            this.rcvrDataFull = null;
            this.rcvrValue = 0;
            this.rcvrScale = { min: 0, max: 100 };
            this.loadingImage = false;
            return true;
          }

          let dx = this.jobDetail.modelGrid.dx;
          let stdSrcLoc = this.shotData.find(
            (s) => s.shotId == this.selectedShot
          );
          let offset_x1 = Math.round(stdSrcLoc.x / dx) * dx - data.src_loc[0];
          let offset_x2 = Math.round(stdSrcLoc.y / dx) * dx - data.src_loc[1];

          this.rcvrDataFull = data.rcvr_loc.map((r, i) => {
            return {
              x: r[0] + offset_x1,
              y: r[1] + offset_x2,
              shotId: i + 1,
              hasTraceFile: false,
              traces_fit: 0,
            };
          });
          this.rcvrDataDisplay = this.rcvrDataFull.filter(
            (r, i) => i % this.rcvrInterval === 0
          );
          this.rcvrIndices =
            data.selected_idx || range(0, data.rcvr_loc.length);
          this.rcvrData = this.rcvrDataFull.filter(
            (r, i) =>
              i % rcvrType.filterSettings.interval === 0 &&
              this.rcvrIndices.includes(i)
          );
          this.rcvrAvg =
            data.values.reduce((a, b) => a + b, 0) / data.values.length;
          this.rcvrStd = Math.sqrt(
            data.values
              .map((v) => v - this.rcvrAvg)
              .reduce((a, b) => a + b * b, 0) / data.values.length
          );
          if (
            this.currentScaling &&
            this.currentScaling[0] != null &&
            this.currentScaling[1] != null
          ) {
            this.rcvrScale = {
              min: this.currentScaling[0],
              max: this.currentScaling[1],
            };
          } else {
            if (rcvrType.filterSettings.value == "corr") {
              this.rcvrScale = { min: -1, max: 1 };
            } else if (rcvrType.filterSettings.value == "freq") {
              this.rcvrScale = { min: -Math.PI, max: Math.PI };
            } else {
              this.rcvrScale = {
                min: Math.min(...data.values),
                max: Math.max(...data.values),
              };
            }
            let clipValue = Math.pow(10, this.currentClipping);
            this.rcvrScale = {
              min: this.rcvrScale.min * clipValue,
              max: this.rcvrScale.max * clipValue,
            };
          }
          if (rcvrType.filterSettings.value == "corr") {
            this.rcvrColorMap = ["black", "grey", "white"];
          } else {
            this.rcvrColorMap = ["blue", "white", "red"];
          }
          this.rcvrData.forEach((r, i) => (r.traces_fit = data.values[i]));
          this.rcvrData.push({
            x: data.src_loc[0] + offset_x1,
            y: data.src_loc[1] + offset_x2,
            shotId: 0,
            hasTraceFile: true,
            traces_fit: 0,
          });

          this.calculateTraceRange();
          this.updateDisplayingRcvr();
          this.loadingImage = false;
        })
      );
  }

  private calculateTraceRange() {
    let currentShot = this.shotData.find((s) => s.shotId === this.selectedShot);
    if (
      !currentShot ||
      !this.rcvrDataFull ||
      (this.startTrace && this.endTrace)
    )
      return;
    let rcvrDist = this.rcvrDataFull.map((r, idx) => {
      let dist =
        Math.pow(currentShot.x - r.x, 2) + Math.pow(currentShot.y - r.y, 2);
      return { dist: dist, idx: idx };
    });
    rcvrDist = rcvrDist.sort((a, b) => a.dist - b.dist);
    let halfRange = Math.floor(this.maxNumTracesShown / 2);
    this.startTrace = Math.max(1, rcvrDist[0].idx - halfRange);
    this.endTrace = Math.min(
      this.numTraces || this.startTrace + this.maxNumTracesShown,
      rcvrDist[0].idx + halfRange
    );
    // this.updateMainImage();
  }

  private restoreSettings() {
    let lastSettings = this.storedSettingsService.getSettings<ShotSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Shot,
      this.jobDetail.projectId
    );

    var storedSliceSettings =
      this.storedSettingsService.getSettings<SliceSettings>(
        this.settingsKey,
        this.jobDetail.id,
        SettingTypes.SliceSettings,
        this.jobDetail.projectId
      );
    this.sliceSettings = this.sliceSettingsService.setDefaults(
      storedSliceSettings,
      this.jobDetail
    );
    if (!lastSettings) {
      return false;
    }
    if (lastSettings.shotGraphHeight) {
      this.shotGraphHeight = lastSettings.shotGraphHeight;
    }
    if (!lastSettings.shotType) {
      return false;
    }
    // this.iteration = Math.min(lastSettings.iteration, this.jobDetail.iterationsComplete);
    this.selectedShot = lastSettings.selectedShot;
    this.selectedBlk = lastSettings.block;
    this.shotType = lastSettings.shotType;
    this.startTrace = lastSettings.startTrace;
    this.endTrace = lastSettings.endTrace;
    this.currentClipping = lastSettings.clipping || 0;
    this.currentScaling = lastSettings.scaling || [null, null];
    this.sliceNum = lastSettings.sliceNum;
    this._minValZ = lastSettings.minValZ;
    this._maxValZ = lastSettings.maxValZ;
    if (
      this.shotType.name == "RcvrList" &&
      lastSettings.typeOfSelectedRcvr.name != "RcvrList"
    ) {
      this.rcvrIndices = lastSettings.rcvrIdx;
      this.typeOfSelectedRcvr = lastSettings.typeOfSelectedRcvr;
    }
    return true;
  }
  ngOnDestroy(): void {
    if (this.shotsImageSubscriber) {
      this.shotsImageSubscriber.unsubscribe();
    }
    if (this.shotSettingsSubscriber) {
      this.shotSettingsSubscriber.unsubscribe();
    }
    if (this.storageSettingsSubscriber) {
      this.storageSettingsSubscriber.unsubscribe();
    }
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }
}
