/**
 * @fileoverview table_params.ts - Defines the parameters for each table in the project page.
 * tables.component.ts uses these parameters, pass them in the request to the backend, 
 * the backend look up the parameters in the latest chkpt runfile, and return the results.
 * some parameters are grouped together, the first row in each table is for the groups.
 * 
 * To add a new parameter, add it to the corresponding array below at the desired position.
 * If added in the middle, make sure it is not at the position that could separate a group.
 * 
 * To add a new table, define a new array below, and import it in tables.component.ts, 
 * you will also need to define relevant variables and method calls in tables.component.ts.
 * 
 * For info on Merge and Groups, see RWIMerge and RWIGroups below.
 * For info on Environment Variables, see StandardEnvironmentVariables below.
 */


/**
 * @description parameters in this list will be displayed in all tables.
 */
export const Always = [
    "Functional",
    "RWI Type",
    "Frequency",
    "Iterations",
    "Iters",
]

/**
 * @description parameters in this list will be rounded to be integer.
 */
export const Integers = [
    "Iterations",
    "Iters",
    "RWI VS Iters",
    "RWI BG Iters",
]

/**
 * @description parameters in RWI table.
 */
export const RWI = [
    "RWI VS Iters",
    "RWI BG Iters",
    "RWI Cut Factor",
    "RWI Restart VS",
    "RWI Shift Factor",
    "RWI Add Incident",
    "RWI AGC",
    "RWI Start Time",
    "RWI End Time",
    "RWI Shot AutoScaling",
    "RWI TimeTweak RateFact",
    "RWI TimeTweak MaxFact",
    "RWI Spatial",
    "RWI Grad Contrib",
    "RWI GradMaskAng",
    "VS Low Cut",
    "VS High Cut",
    "RWI Low Cut",
    "RWI High Cut",
]


/**
 * @description parameters that get merged into one cell.
 * name: the name of the merged cell.
 * params: the parameters that get merged.
 * separator: the separator between the parameters.
 */
export const RWIMerge = [
    {
        name: "Iterations: VS/BG",
        params: ['RWI VS Iters', 'RWI BG Iters'],
        separator: ' / '
    },
]

/**
 * @description the groups in RWI table.
 * name: the name of the group.
 * params: the parameters in the group.
 * nameMap: the mapping from the parameter name to the display name.
 */
export const RWIGroups = [
    {
        name: "RWI Trace Manipulations",
        params: ['RWI Shot AutoScaling', , 'RWI End Time', 'RWI Start Time', 'RWI AGC', 'RWI TimeTweak RateFact', 'RWI TimeTweak MaxFact'],
        nameMap: {
            'RWI Shot AutoScaling': 'Shot AutoScaling',
            'RWI End Time': 'End Time',
            'RWI Start Time': 'Start Time',
            'RWI AGC': 'AGC',
            'RWI TimeTweak RateFact': 'TimeTweak RateFact',
            'RWI TimeTweak MaxFact': 'TimeTweak MaxFact'
        }
    },
    {
        name: "RWI Other",
        params: ['Iterations: VS/BG', 'RWI Cut Factor', 'RWI Restart VS', 'RWI Shift Factor', 'RWI Add Incident'],
        nameMap: {
            'RWI VS Iters': 'VS Iters',
            'RWI BG Iters': 'BG Iters',
            'RWI Cut Factor': 'Cut Factor',
            'RWI Restart VS': 'Restart VS',
            'RWI Shift Factor': 'Shift Factor',
            'RWI Add Incident': 'Add Incident'
        }
    },
    {
        name: "RWI Gradient & Preconditioner",
        params: ['RWI Spatial', 'RWI Grad Contrib', 'RWI GradMaskAng'],
        nameMap: {
            'RWI Spatial': 'Spatial',
            'RWI Grad Contrib': 'Grad Contrib',
            'RWI GradMaskAng': 'GradMaskAng'
        }
    },
    {
        name: "RWI Filters",
        params: ['VS Low Cut', 'RWI Low Cut', 'VS High Cut', 'RWI High Cut'],
        nameMap: {
            'VS Low Cut': 'VS Low Cut',
            'VS High Cut': 'VS High Cut',
            'RWI Low Cut': 'BG Low Cut',
            'RWI High Cut': 'BG High Cut'
        }
    }
]

export const Smoothing = [
    "ModelSmoothX1",
    "ModelSmoothX2",
    "ModelSmoothX3",
    "ModelSmoothCut",
    "ModelSmoothRamp",
    "ModelSmoothCutVel",
    "SmoothX1",
    "SmoothX2",
    "SmoothX3",
    "SmoothCut",
    "SmoothRamp",
    "Struct Smooth",
    "RcvrLine SmoothDist",
    "RWI Smooth X1",
    "RWI Smooth X2",
    "RWI Smooth X3",
    "RWI Smooth Cut",
    "RWI Smooth Ramp",
    "RWI Struct Smooth",
    "RWI PreSmooth Clip Lo",
    "RWI PreSmooth Clip Hi",
    "RWI PreSmooth Clip SmthFact",
    "RWI SmthSprdClip Low",
    "RWI SmthSprdClip High",
    "RWI SmthSprdClip SmthFact",
    "RWI RcvrLine SmoothDist",
    "RWI Median X1",
    "RWI Median X2",
    "RWI Median X3",
    "FinalSmoothX1",
    "FinalSmoothX2",
    "FinalSmoothX3",
    "FinalSmoothCut",
    "FinalSmoothRamp",
    "FinalModelSmoothCutVel",
]

export const SmoothingGroups = [
    {
        name: "Iteration Smooth",
        params: ['SmoothX1', 'SmoothX2', 'SmoothX3', 'SmoothCut', 'SmoothRamp', 'Struct Smooth', 'RcvrLine SmoothDist'],
        nameMap: {
            'SmoothX1': 'X1',
            'SmoothX2': 'X2',
            'SmoothX3': 'X3',
            'SmoothCut': 'Cut Cells',
            'SmoothRamp': 'Ramp',
            'Struct Smooth': 'Structural',
            'RcvrLine SmoothDist': 'RcvrLine Dist',
        }
    },
    {
        name: "RWI Smooth",
        params: ['RWI Smooth X1', 'RWI Smooth X2', 'RWI Smooth X3', 'RWI Smooth Cut', 'RWI Smooth Ramp',
            'RWI Struct Smooth', 'RWI RcvrLine SmoothDist', 'RWI Median X1', 'RWI Median X2', 'RWI Median X3',
            "RWI PreSmooth Clip Lo", "RWI PreSmooth Clip Hi", "RWI PreSmooth Clip SmthFact", "RWI SmthSprdClip Low",
            "RWI SmthSprdClip High", "RWI SmthSprdClip SmthFact",],
        nameMap: {
            'RWI Smooth X1': 'X1',
            'RWI Smooth X2': 'X2',
            'RWI Smooth X3': 'X3',
            'RWI Smooth Cut': 'Cut Cells',
            'RWI Smooth Ramp': 'Ramp',
            'RWI Struct Smooth': 'Structural',
            'RWI RcvrLine SmoothDist': 'RcvrLine Dist',
            'RWI Median X1': 'Median X1',
            'RWI Median X2': 'Median X2',
            'RWI Median X3': 'Median X3',
            "RWI PreSmooth Clip Lo": "PreSmooth Clip Lo",
            "RWI PreSmooth Clip Hi": "PreSmooth Clip Hi",
            "RWI PreSmooth Clip SmthFact": "PreSmooth Clip SmthFact",
            "RWI SmthSprdClip Low": "SmthSprdClip Low",
            "RWI SmthSprdClip High": "SmthSprdClip High",
            "RWI SmthSprdClip SmthFact": "SmthSprdClip SmthFact",
        }
    },
    {
        name: "Block Smooth",
        params: ['FinalSmoothX1', 'FinalSmoothX2', 'FinalSmoothX3', 'FinalSmoothCut', 'FinalSmoothRamp', 'FinalModelSmoothCutVel'],
        nameMap: {
            'FinalSmoothX1': 'X1',
            'FinalSmoothX2': 'X2',
            'FinalSmoothX3': 'X3',
            'FinalSmoothCut': 'Cut Cells',
            'FinalSmoothRamp': 'Ramp',
            'FinalModelSmoothCutVel': 'Cut Vel'
        }
    },
    {
        name: "Model Smooth",
        params: ['ModelSmoothX1', 'ModelSmoothX2', 'ModelSmoothX3', 'ModelSmoothCut', 'ModelSmoothRamp', 'ModelSmoothCutVel'],
        nameMap: {
            'ModelSmoothX1': 'X1',
            'ModelSmoothX2': 'X2',
            'ModelSmoothX3': 'X3',
            'ModelSmoothCut': 'Cut Cells',
            'ModelSmoothRamp': 'Ramp',
            'ModelSmoothCutVel': 'Cut Vel'
        }
    }
]

export const Mute = [
    "Offset Plane Normal",
    "RWI Cut Taper",
    "RWI Offset Cut",
    "RWI Offset Grow",
    "Outer Mute Start Time",
    "Outer Mute Growth",
    "Outer Mute Taper Width",
    "Inner Mute Start Time",
    "Inner Mute Growth",
    "Inner Mute Taper Width",
]

export const MuteGroups = [
    {
        name: "RWI Mute",
        params: ['RWI Cut Taper', 'RWI Offset Cut', 'RWI Offset Grow'],
        nameMap: {
            'RWI Cut Taper': 'Cut Taper',
            'RWI Offset Cut': 'Offset Cut',
            'RWI Offset Grow': 'Offset Grow'
        }
    },
    {
        name: "Outer Mute",
        params: ['Outer Mute Start Time', 'Outer Mute Growth', 'Outer Mute Taper Width'],
        nameMap: {
            'Outer Mute Start Time': 'Start Time',
            'Outer Mute Growth': 'Growth',
            'Outer Mute Taper Width': 'Taper Width'
        }
    },
    {
        name: "Inner Mute",
        params: ['Inner Mute Start Time', 'Inner Mute Growth', 'Inner Mute Taper Width'],
        nameMap: {
            'Inner Mute Start Time': 'Start Time',
            'Inner Mute Growth': 'Growth',
            'Inner Mute Taper Width': 'Taper Width'
        },
    },
    {
        name: "Mute Other",
        params: ['Offset Plane Normal'],
    }
]

export const Density = [
    "Density Ramps",
    "Min Gardner Vel",
    "Max Gardner Vel",
    "Salt Velocity",
    "Salt Density",
    "Water MinCells",
    "Water MaxCells",
    "Water Velocity",
    "Water Density",
    "Gardner Factor",
    "Gardner Power"
]

export const DensityGroups = [
    {
        name: "Density",
        params: ['Density Ramps', 'Min Gardner Vel', 'Max Gardner Vel', 'Salt Velocity', 'Salt Density', 'Water MinCells', 'Water MaxCells', 'Water Velocity', 'Water Density', "Gardner Factor", "Gardner Power"],
    }
]

export const Temp = [
    // "awi scale",
    // "awi top",
    // "awi normfilt",
    // "VelCutOff",
    // "VelCon Min",
    // "VelCon Max",
    // "StartTime",
    // "EndTime",
    // 'MinOffset',
    // "MaxOffset",
    // "Amplitude",
    // "Normalise",
    // 'TimeTweak RateFact',
    // 'TimeTweak MaxFact',
    // "Shot AutoScaling",
    // "Source Scaling",
    // "TotalTime",
    "Slowness",
    "Spatial",
    "Conj Grad",
    "Accel Grad",
    "Reduce oscillation",
    "GradMaskAng",
    'Line Search Method',
    'Line Search Max Steps',
    "BadResidWeight",
]

export const TempGroups = [
    {
        name: "Step Factor",
        params: ['Line Search Method', 'Line Search Max Steps', "BadResidWeight"],
        nameMap: {
            'Line Search Method': 'Method',
            'Line Search Max Steps': 'Max Steps',
            "BadResidWeight": "BadResidWeight"
        }
    },
    {
        name: "Gradient & Preconditioner",
        params: ['Slowness', 'Spatial', 'Conj Grad', 'Accel Grad', 'Reduce oscillation', 'GradMaskAng'],
    }


]

export const TraceManipulation =[
    'StartTime', 'EndTime', 'MinOffset', 'MaxOffset', 'Amplitude', 'Normalise', 'TimeTweak RateFact', 'TimeTweak MaxFact', 'Shot AutoScaling', 'Source Scaling', 'Source Differ', 'Source Smooth'
]
export const TraceManipulationGroups = [
    {
        name: "Before Propogation",
        params: ['StartTime', 'EndTime', 'MinOffset', 'MaxOffset', 'Source Differ', 'Source Smooth'],
    },
    {
        name: 'After Propogation',
        params: ['Amplitude', 'Normalise', 'TimeTweak RateFact', 'TimeTweak MaxFact', 'Shot AutoScaling', 'Source Scaling']
    }
]

export const AWI = [
    "awi normfilt",
    "awi atype",
    "awi rtype",
    "awi scale",
    "awi steptype",
    "awi top",
    "awi protectspike",
]

export const AWIGroups = [
    {
        name: "AWI",
        params: ['awi scale', 'awi top', 'awi normfilt', 'awi atype', 'awi rtype', 'awi steptype', 'awi protectspike'],
        nameMap: {
            'awi scale': 'Scale',
            'awi top': 'Top',
            'awi normfilt': 'Normfilt',
            'awi atype': 'Atype',
            'awi rtype': 'Rtype',
            'awi steptype': 'Steptype',
            'awi protectspike': 'Protectspike'
        }
    }
]

export const Constraints = [
    'VelCutOff',
    'VelCon Min',
    'Auto MinVel',
    'VelCon Max',
    'Auto MaxVel',
    'Window',
    'W1',
    'W2',
    'W3',
    'W4',
]

export const ConstraintsGroups = [
    {
        name: "Minimum Velocities",
        params: ['VelCutOff', 'VelCon Min', 'Auto MinVel'],
        nameMap: {
            'VelCutOff': 'Cut Off',
            'VelCon Min': 'Constraint',
            'Auto MinVel': 'Auto'
        }
    },
    {
        name: "Maximum Velocities",
        params: ['VelCon Max', 'Auto MaxVel'],
        nameMap: {
            'VelCon Max': 'Constraint',
            'Auto MaxVel': 'Auto'
        }
    },
    {
        name: "Window",
        params: ['Window', 'W1', 'W2', 'W3', 'W4'],
        nameMap: {
            'Window': 'Active',
        }
    }
]

export const Boundary = [
    "Extra Left",
    "Extra Right",
    "Extra Front",
    "Extra Back",
    "Extra Top",
    "Extra Bottom",
    "BorderX1",
    "BorderX2",
    "BorderX3",
    "Bleft",
    "Bright",
    "Bfront",
    "Bback",
    "Btop",
    "Bbottom",
]

export const BoundaryGroups = [
    {
        name: "Global Domain",
        params: ['Extra Left', 'Extra Right', 'Extra Front', 'Extra Back', 'Extra Top', 'Extra Bottom'],
        nameMap: {
            'Extra Left': 'Left',
            'Extra Right': 'Right',
            'Extra Front': 'Front',
            'Extra Back': 'Back',
            'Extra Top': 'Top',
            'Extra Bottom': 'Bottom'
        }
    },
    {
        name: "Local Domain",
        params: ['BorderX1', 'BorderX2', 'BorderX3'],
        nameMap: {
            'BorderX1': 'X1',
            'BorderX2': 'X2',
            'BorderX3': 'X3'
        }
    },
    {
        name: "Absorbing",
        params: ['Bleft', 'Bright', 'Bfront', 'Bback', 'Btop', 'Bbottom'],
        nameMap: {
            'Bleft': 'Left',
            'Bright': 'Right',
            'Bfront': 'Front',
            'Bback': 'Back',
            'Btop': 'Top',
            'Bbottom': 'Bottom'
        }
    }
]


/**
 * @description Environment variable in this list will be ignored when the backend looks for environment variables
 * in the beginning of the log files.
 */
export const StandardEnvironmentVariables = [
    'SCHEDULER_VERBOSE',
    'SCHEDULER_SHOWLEVEL',
    'SCHEDULER_SHOWTIMESTAMP',
    'SCHEDULER_SHOWDATESTAMP',
    'SCHEDULER_SHOWHOSTNAME',
    'SCHEDULER_SLAVESHAVEDATA',
    'SCHEDULER_SLAVESHAVEMODEL',
    'SCHEDULER_SLAVESHAVEUPDATE',
    'SCHEDULER_WRITEUPDATE',
    'SCHEDULER_DUMPRAWGRAD',
    'SCHEDULER_DUMPRAWPREC',
    'SCHEDULER_DUMPPREC',
    'SCHEDULER_DUMPGRAD',
    'SCHEDULER_DUMPSTRUCTSMTH',
    'SCHEDULER_ALLOWNOSLAVES',
    'SCHEDULER_CACHESOURCEORDER',
    'SCHEDULER_MAXACTIVERCVS',
    'SCHEDULER_MAXDEFERRED',
    'SCHEDULER_SHOWSTATINTERVAL',
    'SCHEDULER_FORCESLAVESGETMODEL',
    'SCHEDULER_TERMITER',
    'SCHEDULER_SLAVESTIMEOUT',
    'SLAVES_LOCALSTORE',
    'SLAVES_LOCALSTORETRIGGER',
    'SLAVES_SHOWTIMESTAMP',
    'SLAVES_SHOWDATESTAMP',
    'SLAVES_SHOWHOSTNAME',
    'SLAVES_DUMPCSREFS',
    'SLAVES_DUMPCOMPARE',
    'SLAVES_DUMPAGC',
    'SLAVES_DUMPLOWPASS',
    'SLAVES_DUMPRAW',
    'SLAVES_DUMPGRAD',
    'SLAVES_DUMPDENSITY',
    'SLAVES_DUMPRESIDS',
    'SLAVES_DUMPADJOINT',
    'SLAVES_DUMPRCVLINSMTH',
    'SLAVES_DUMPRCVR',
    'SLAVES_SHOWDATACORR',
    'SLAVES_SHOWLEVEL',
    'SLAVES_TIMEOUT',
]

export const ParamsTree = {
    "Job": {
        collapsed: false,
        children: {
            "Job": {
                collapsed: false,
                children: [
                    "job id",
                    "job name",
                    "project id",
                    "xwi executable",
                    "comment",
                    "date"
                ]
            }
        }
    },
    "Default Locators": {
        collapsed: false,
        children: {
            "Default Locators": {
                collapsed: false,
                children: [
                    "epicmod locator",
                    "checkpoint locator",
                    "geometry locator",
                    "modelprops locator",
                    "qc dump locator",
                    "sourcesig locator",
                    "traces locator"
                ]
            }
        }
    },

    "Problem": {
        collapsed: false,
        children: {
            "General": {
                collapsed: false,
                children: [
                    "io api",
                    "tti units",
                    "ttiang units",
                    "units",
                    "equation",
                    "anisotropy",
                    "domain",
                    "kernel",
                    "probdims",
                    "problem",
                    "iters",
                    "numiterblocks",
                    "final iteration"
                ]
            },
            "Discretisation": {
                collapsed: false,
                children: [
                    "nx1",
                    "nx2",
                    "nx3",
                    "dx",
                    "fd time step",
                    "totaltime"
                ]
            },
            "Attenuation": {
                collapsed: false,
                children: [
                    "alpha"
                ]
            },
            "Source and Receiver": {
                collapsed: false,
                children: [
                    "flipshotcycles",
                    "max dead srcs",
                    "maxps",
                    "maxrc",
                    "ncomp",
                    "nptrecs",
                    "nptsrcs",
                    "numcsperiter",
                    "numcstoskip"
                ]
            }
        }
    },
    "EPICMods": {
        collapsed: false,
        children: {
            "EPICMod Type": {
                collapsed: false,
                children: [
                    "epicmod adjoints",
                    "epicmod comparetraces",
                    "epicmod localgradprec",
                    "epicmod localmodprops",
                    "epicmod propagate",
                    "epicmod residuals",
                    "epicmod update"
                ]
            }
        }
    },
    "RWI": {
        collapsed: false,
        children: {
            "RWI Trace Manipulations": {
                collapsed: false,
                children: [
                    "RWI Shot AutoScaling",
                    "RWI End Time",
                    "RWI Start Time",
                    "RWI AGC",
                    "RWI TimeTweak RateFact",
                    "RWI TimeTweak MaxFact",
                    "rwi2 far ratio",
                    "rwi2 near ratio"
                ]
            },
            "RWI Other": {
                collapsed: false,
                children: [
                    "Iterations: VS/BG",
                    "RWI Cut Factor",
                    "RWI Restart VS",
                    "RWI Shift Factor",
                    "RWI Add Incident",
                    "RWI VS Iters",
                    "RWI BG Iters",
                    "rwi scale",
                    "rwi type"
                ]
            },
            "RWI Gradient & Preconditioner": {
                collapsed: false,
                children: [
                    "RWI Spatial",
                    "RWI Grad Contrib",
                    "RWI GradMaskAng"
                ]
            },
            "RWI Filters": {
                collapsed: false,
                children: [
                    "VS Low Cut",
                    "RWI Low Cut",
                    "VS High Cut",
                    "RWI High Cut"
                ]
            }
        }
    },
    "Smoothing": {
        collapsed: false,
        children: {
            "Iteration Smooth": {
                collapsed: false,
                children: [
                    "SmoothX1",
                    "SmoothX2",
                    "SmoothX3",
                    "SmoothCut",
                    "SmoothRamp",
                    "Struct Smooth",
                    "RcvrLine SmoothDist"
                ]
            },
            "RWI Smooth": {
                collapsed: false,
                children: [
                    "RWI Smooth X1",
                    "RWI Smooth X2",
                    "RWI Smooth X3",
                    "RWI Smooth Cut",
                    "RWI Smooth Ramp",
                    "RWI Struct Smooth",
                    "RWI RcvrLine SmoothDist",
                    "RWI Median X1",
                    "RWI Median X2",
                    "RWI Median X3",
                    "RWI PreSmooth Clip Lo",
                    "RWI PreSmooth Clip Hi",
                    "RWI PreSmooth Clip SmthFact",
                    "RWI SmthSprdClip Low",
                    "RWI SmthSprdClip High",
                    "RWI SmthSprdClip SmthFact"
                ]
            },
            "Block Smooth": {
                collapsed: false,
                children: [
                    "FinalSmoothX1",
                    "FinalSmoothX2",
                    "FinalSmoothX3",
                    "FinalSmoothCut",
                    "FinalSmoothRamp",
                    "FinalModelSmoothCutVel"
                ]
            },
            "Model Smooth": {
                collapsed: false,
                children: [
                    "ModelSmoothX1",
                    "ModelSmoothX2",
                    "ModelSmoothX3",
                    "ModelSmoothCut",
                    "ModelSmoothRamp",
                    "ModelSmoothCutVel"
                ]
            }
        }
    },
    "Mute": {
        collapsed: false,
        children: {
            "RWI Mute": {
                collapsed: false,
                children: [
                    "RWI Cut Taper",
                    "RWI Offset Cut",
                    "RWI Offset Grow"
                ]
            },
            "Outer Mute": {
                collapsed: false,
                children: [
                    "Outer Mute Start Time",
                    "Outer Mute Growth",
                    "Outer Mute Taper Width"
                ]
            },
            "Inner Mute": {
                collapsed: false,
                children: [
                    "Inner Mute Start Time",
                    "Inner Mute Growth",
                    "Inner Mute Taper Width"
                ]
            },
            "Mute Other": {
                collapsed: false,
                children: [
                    "Offset Plane Normal"
                ]
            }
        }
    },
    "Density": {
        collapsed: false,
        children: {
            "Density": {
                collapsed: false,
                children: [
                    "Density Ramps",
                    "Min Gardner Vel",
                    "Max Gardner Vel",
                    "Salt Velocity",
                    "Salt Density",
                    "Water MinCells",
                    "Water MaxCells",
                    "Water Velocity",
                    "Water Density"
                ]
            }
        }
    },
    "Temp": {
        collapsed: false,
        children: {
            "Step Factor": {
                collapsed: false,
                children: [
                    "Line Search Method",
                    "Line Search Max Steps",
                    "BadResidWeight"
                ]
            },
            "Trace Manipulations": {
                collapsed: false,
                children: [
                    "StartTime",
                    "EndTime",
                    "MinOffset",
                    "MaxOffset",
                    "Amplitude",
                    "Normalise",
                    "TimeTweak RateFact",
                    "TimeTweak MaxFact",
                    "Shot AutoScaling",
                    "Source Scaling",
                    "corr match weight",
                    "final iter srcdiff",
                    "final iter srcsmth",
                    "source differ",
                    "source smooth",
                    "gausswid",
                    "minimum traces"
                ]
            },
            "Gradient & Preconditioner": {
                collapsed: false,
                children: [
                    "functional",
                    "Slowness",
                    "Spatial",
                    "Conj Grad",
                    "Accel Grad",
                    "Reduce oscillation",
                    "GradMaskAng",
                    "long contrib x1",
                    "long contrib x2",
                    "long contrib x3",
                    "short contrib x1",
                    "short contrib x2",
                    "short contrib x3",
                    "percent"
                ]
            }
        }
    },
    "AWI": {
        collapsed: false,
        children: {
            "AWI": {
                collapsed: false,
                children: [
                    "awi scale",
                    "awi top",
                    "awi normfilt",
                    "awi atype",
                    "awi rtype",
                    "awi steptype",
                    "awi protectspike",
                    "awi amin",
                    "awi decimate",
                    "awi diffenv",
                    "awi filtsmooth",
                    "awi flavour",
                    "awi length",
                    "awi max freq",
                    "awi maxsubtraces",
                    "awi noise",
                    "awi taper",
                    "aws path"
                ]
            },
            "pyAWI": {
                collapsed: false,
                children: [
                    "pyawi",
                    "tile width",
                    "tiling",
                    "tile extension",
                    "math mode",
                    "protect spike",
                    "sailline edge taper",
                    "filtsmooth offset",
                    "offset scale",
                    "decimate",
                    "max freq",
                    "trace window",
                    "direction",
                    "use fft",
                    "length",
                    "width",
                    "noise",
                    "filtsmooth",
                    "scale",
                    "normfilt",
                    "top",
                    "d scale",
                    "filter dim",
                    "filtsmooth time",
                    "frequencies",
                    "mode",
                    "mu",
                    "parallel",
                    "sailline length",
                    "salline length",
                    "tile time",
                    "total iterations"
                ]
            }
        }
    },
    "Constraint": {
        collapsed: false,
        children: {
            "Minimum Velocities": {
                collapsed: false,
                children: [
                    "VelCutOff",
                    "VelCon Min",
                    "Auto MinVel",
                    "velstart min",
                    "vs start min",
                    "delta start min",
                    "eps start min"
                ]
            },
            "Maximum Velocities": {
                collapsed: false,
                children: [
                    "VelCon Max",
                    "Auto MaxVel",
                    "velstart max",
                    "vs start max",
                    "delta start max",
                    "eps start max"
                ]
            },
            "Window": {
                collapsed: false,
                children: [
                    "Window",
                    "W1",
                    "W2",
                    "W3",
                    "W4"
                ]
            }
        }
    },
    "Boundary": {
        collapsed: false,
        children: {
            "Global Domain": {
                collapsed: false,
                children: [
                    "Extra Left",
                    "Extra Right",
                    "Extra Front",
                    "Extra Back",
                    "Extra Top",
                    "Extra Bottom"
                ]
            },
            "Local Domain": {
                collapsed: false,
                children: [
                    "BorderX1",
                    "BorderX2",
                    "BorderX3"
                ]
            },
            "Absorbing": {
                collapsed: false,
                children: [
                    "Bleft",
                    "Bright",
                    "Bfront",
                    "Bback",
                    "Btop",
                    "Bbottom"
                ]
            }
        }
    },
    "Misc": {
        collapsed: false,
        children: ["id",
            "scale i",
            "scale r",
            "sourceamp",
            "sourcephase",
            "steplen"]
    }
}

export const ParamsTree2 = {
    "Job": {
            "Job": [
                    "job id",
                    "job name",
                    "project id",
                    "xwi executable",
                    "comment",
                    "date"
                ]
    },
    "Default Locators": {
            "Default Locators": [
                    "epicmod locator",
                    "checkpoint locator",
                    "geometry locator",
                    "modelprops locator",
                    "qc dump locator",
                    "sourcesig locator",
                    "traces locator"
                ]
    },

    "Problem": {
            "General": [
                    "io api",
                    "tti units",
                    "ttiang units",
                    "units",
                    "equation",
                    "anisotropy",
                    "domain",
                    "kernel",
                    "probdims",
                    "problem",
                    "iters",
                    "numiterblocks",
                    "final iteration"
                ],
            "Discretisation": [
                    "nx1",
                    "nx2",
                    "nx3",
                    "dx",
                    "fd time step",
                    "totaltime"
                ]
            ,
            "Attenuation":  [
                    "alpha"
                ]
            ,
            "Source and Receiver":  [
                    "flipshotcycles",
                    "max dead srcs",
                    "maxps",
                    "maxrc",
                    "ncomp",
                    "nptrecs",
                    "nptsrcs",
                    "numcsperiter",
                    "numcstoskip"
                ]
            
    },
    "EPICMods": {
            "EPICMod Type": [
                    "epicmod adjoints",
                    "epicmod comparetraces",
                    "epicmod localgradprec",
                    "epicmod localmodprops",
                    "epicmod propagate",
                    "epicmod residuals",
                    "epicmod update"
                ]
    },
    "RWI": {
            "RWI Trace Manipulations": [
                    "RWI Shot AutoScaling",
                    "RWI End Time",
                    "RWI Start Time",
                    "RWI AGC",
                    "RWI TimeTweak RateFact",
                    "RWI TimeTweak MaxFact",
                    "rwi2 far ratio",
                    "rwi2 near ratio"
                ]
            ,
            "RWI Other":  [
                    "Iterations: VS/BG",
                    "RWI Cut Factor",
                    "RWI Restart VS",
                    "RWI Shift Factor",
                    "RWI Add Incident",
                    "RWI VS Iters",
                    "RWI BG Iters",
                    "rwi scale",
                    "rwi type"
                ]
            ,
            "RWI Gradient & Preconditioner": [
                    "RWI Spatial",
                    "RWI Grad Contrib",
                    "RWI GradMaskAng"
                ]
            ,
            "RWI Filters":  [
                    "VS Low Cut",
                    "RWI Low Cut",
                    "VS High Cut",
                    "RWI High Cut"
                ]
            
    },
    "Smoothing": {
            "Iteration Smooth":[
                    "SmoothX1",
                    "SmoothX2",
                    "SmoothX3",
                    "SmoothCut",
                    "SmoothRamp",
                    "Struct Smooth",
                    "RcvrLine SmoothDist"
                ]
            ,
            "RWI Smooth":[
                    "RWI Smooth X1",
                    "RWI Smooth X2",
                    "RWI Smooth X3",
                    "RWI Smooth Cut",
                    "RWI Smooth Ramp",
                    "RWI Struct Smooth",
                    "RWI RcvrLine SmoothDist",
                    "RWI Median X1",
                    "RWI Median X2",
                    "RWI Median X3",
                    "RWI PreSmooth Clip Lo",
                    "RWI PreSmooth Clip Hi",
                    "RWI PreSmooth Clip SmthFact",
                    "RWI SmthSprdClip Low",
                    "RWI SmthSprdClip High",
                    "RWI SmthSprdClip SmthFact"
                ]
            ,
            "Block Smooth":[
                    "FinalSmoothX1",
                    "FinalSmoothX2",
                    "FinalSmoothX3",
                    "FinalSmoothCut",
                    "FinalSmoothRamp",
                    "FinalModelSmoothCutVel"
                ]
            ,
            "Model Smooth":[
                    "ModelSmoothX1",
                    "ModelSmoothX2",
                    "ModelSmoothX3",
                    "ModelSmoothCut",
                    "ModelSmoothRamp",
                    "ModelSmoothCutVel"
                ]
        
    },
    "Mute": {
            "RWI Mute": [
                    "RWI Cut Taper",
                    "RWI Offset Cut",
                    "RWI Offset Grow"
                ]
            ,
            "Outer Mute": [
                    "Outer Mute Start Time",
                    "Outer Mute Growth",
                    "Outer Mute Taper Width"
                ]
            ,
            "Inner Mute": [
                    "Inner Mute Start Time",
                    "Inner Mute Growth",
                    "Inner Mute Taper Width"
                ]
            ,
            "Mute Other": [
                    "Offset Plane Normal"
                ]
            
    },
    "Density": {
            "Density": [
                    "Density Ramps",
                    "Min Gardner Vel",
                    "Max Gardner Vel",
                    "Salt Velocity",
                    "Salt Density",
                    "Water MinCells",
                    "Water MaxCells",
                    "Water Velocity",
                    "Water Density"
                ]
    },
    "Temp": {
            "Step Factor":[
                    "Line Search Method",
                    "Line Search Max Steps",
                    "BadResidWeight"
                ],
            "Trace Manipulations": [
                    "StartTime",
                    "EndTime",
                    "MinOffset",
                    "MaxOffset",
                    "Amplitude",
                    "Normalise",
                    "TimeTweak RateFact",
                    "TimeTweak MaxFact",
                    "Shot AutoScaling",
                    "Source Scaling",
                    "corr match weight",
                    "final iter srcdiff",
                    "final iter srcsmth",
                    "source differ",
                    "source smooth",
                    "gausswid",
                    "minimum traces"
                ],
            "Gradient & Preconditioner": [
                    "functional",
                    "Slowness",
                    "Spatial",
                    "Conj Grad",
                    "Accel Grad",
                    "Reduce oscillation",
                    "GradMaskAng",
                    "long contrib x1",
                    "long contrib x2",
                    "long contrib x3",
                    "short contrib x1",
                    "short contrib x2",
                    "short contrib x3",
                    "percent"
                ]
    },
    "AWI": {
            "AWI": [
                    "awi scale",
                    "awi top",
                    "awi normfilt",
                    "awi atype",
                    "awi rtype",
                    "awi steptype",
                    "awi protectspike",
                    "awi amin",
                    "awi decimate",
                    "awi diffenv",
                    "awi filtsmooth",
                    "awi flavour",
                    "awi length",
                    "awi max freq",
                    "awi maxsubtraces",
                    "awi noise",
                    "awi taper",
                    "aws path"
                ],
            "pyAWI":[
                    "pyawi",
                    "tile width",
                    "tiling",
                    "tile extension",
                    "math mode",
                    "protect spike",
                    "sailline edge taper",
                    "filtsmooth offset",
                    "offset scale",
                    "decimate",
                    "max freq",
                    "trace window",
                    "direction",
                    "use fft",
                    "length",
                    "width",
                    "noise",
                    "filtsmooth",
                    "scale",
                    "normfilt",
                    "top",
                    "d scale",
                    "filter dim",
                    "filtsmooth time",
                    "frequencies",
                    "mode",
                    "mu",
                    "parallel",
                    "sailline length",
                    "salline length",
                    "tile time",
                    "total iterations"
                ]
    },
    "Constraint": {
            "Minimum Velocities": [
                    "VelCutOff",
                    "VelCon Min",
                    "Auto MinVel",
                    "velstart min",
                    "vs start min",
                    "delta start min",
                    "eps start min"
                ],
            "Maximum Velocities": [
                    "VelCon Max",
                    "Auto MaxVel",
                    "velstart max",
                    "vs start max",
                    "delta start max",
                    "eps start max"
                ],
            "Window": [
                    "Window",
                    "W1",
                    "W2",
                    "W3",
                    "W4"
                ]
            
    },
    "Boundary": {
        
            "Global Domain": [
                    "Extra Left",
                    "Extra Right",
                    "Extra Front",
                    "Extra Back",
                    "Extra Top",
                    "Extra Bottom"
                ],
            "Local Domain": [
                    "BorderX1",
                    "BorderX2",
                    "BorderX3"
                ],
            "Absorbing": [
                    "Bleft",
                    "Bright",
                    "Bfront",
                    "Bback",
                    "Btop",
                    "Bbottom"
                ]
        
    },
    "Misc": ["id",
            "scale i",
            "scale r",
            "sourceamp",
            "sourcephase",
            "steplen"]
}

export const AllParams = [
    "jobid",
    "jobname",
    "projectid",
    "xwiexecutable",
    "comment",
    "date",
    "epicmodlocator",
    "checkpointlocator",
    "geometrylocator",
    "modelpropslocator",
    "qcdumplocator",
    "sourcesiglocator",
    "traceslocator",
    "ioapi",
    "ttiunits",
    "ttiangunits",
    "units",
    "equation",
    "anisotropy",
    "domain",
    "kernel",
    "probdims",
    "problem",
    "iters",
    "numiterblocks",
    "finaliteration",
    "nx1",
    "nx2",
    "nx3",
    "dx",
    "fdtimestep",
    "totaltime",
    "alpha",
    "flipshotcycles",
    "maxdeadsrcs",
    "maxps",
    "maxrc",
    "ncomp",
    "nptrecs",
    "nptsrcs",
    "numcsperiter",
    "numcstoskip",
    "epicmodadjoints",
    "epicmodcomparetraces",
    "epicmodlocalgradprec",
    "epicmodlocalmodprops",
    "epicmodpropagate",
    "epicmodresiduals",
    "epicmodupdate",
    "rwishotautoscaling",
    "rwiendtime",
    "rwistarttime",
    "rwiagc",
    "rwitimetweakratefact",
    "rwitimetweakmaxfact",
    "rwi2farratio",
    "rwi2nearratio",
    "iterations:vs/bg",
    "rwicutfactor",
    "rwirestartvs",
    "rwishiftfactor",
    "rwiaddincident",
    "rwivsiters",
    "rwibgiters",
    "rwiscale",
    "rwitype",
    "rwispatial",
    "rwigradcontrib",
    "rwigradmaskang",
    "vslowcut",
    "rwilowcut",
    "vshighcut",
    "rwihighcut",
    "smoothx1",
    "smoothx2",
    "smoothx3",
    "smoothcut",
    "smoothramp",
    "structsmooth",
    "rcvrlinesmoothdist",
    "rwismoothx1",
    "rwismoothx2",
    "rwismoothx3",
    "rwismoothcut",
    "rwismoothramp",
    "rwistructsmooth",
    "rwircvrlinesmoothdist",
    "rwimedianx1",
    "rwimedianx2",
    "rwimedianx3",
    "rwipresmoothcliplo",
    "rwipresmoothcliphi",
    "rwipresmoothclipsmthfact",
    "rwismthsprdcliplow",
    "rwismthsprdcliphigh",
    "rwismthsprdclipsmthfact",
    "finalsmoothx1",
    "finalsmoothx2",
    "finalsmoothx3",
    "finalsmoothcut",
    "finalsmoothramp",
    "finalmodelsmoothcutvel",
    "modelsmoothx1",
    "modelsmoothx2",
    "modelsmoothx3",
    "modelsmoothcut",
    "modelsmoothramp",
    "modelsmoothcutvel",
    "rwicuttaper",
    "rwioffsetcut",
    "rwioffsetgrow",
    "outermutestarttime",
    "outermutegrowth",
    "outermutetaperwidth",
    "innermutestarttime",
    "innermutegrowth",
    "innermutetaperwidth",
    "offsetplanenormal",
    "densityramps",
    "mingardnervel",
    "maxgardnervel",
    "saltvelocity",
    "saltdensity",
    "watermincells",
    "watermaxcells",
    "watervelocity",
    "waterdensity",
    "linesearchmethod",
    "linesearchmaxsteps",
    "badresidweight",
    "starttime",
    "endtime",
    "minoffset",
    "maxoffset",
    "amplitude",
    "normalise",
    "timetweakratefact",
    "timetweakmaxfact",
    "shotautoscaling",
    "sourcescaling",
    "corrmatchweight",
    "finalitersrcdiff",
    "finalitersrcsmth",
    "sourcediffer",
    "sourcesmooth",
    "gausswid",
    "minimumtraces",
    "functional",
    "slowness",
    "spatial",
    "conjgrad",
    "accelgrad",
    "reduceoscillation",
    "gradmaskang",
    "longcontribx1",
    "longcontribx2",
    "longcontribx3",
    "shortcontribx1",
    "shortcontribx2",
    "shortcontribx3",
    "percent",
    "awiscale",
    "awitop",
    "awinormfilt",
    "awiatype",
    "awirtype",
    "awisteptype",
    "awiprotectspike",
    "awiamin",
    "awidecimate",
    "awidiffenv",
    "awifiltsmooth",
    "awiflavour",
    "awilength",
    "awimaxfreq",
    "awimaxsubtraces",
    "awinoise",
    "awitaper",
    "awspath",
    "pyawi",
    "tilewidth",
    "tiling",
    "tileextension",
    "mathmode",
    "protectspike",
    "saillineedgetaper",
    "filtsmoothoffset",
    "offsetscale",
    "decimate",
    "maxfreq",
    "tracewindow",
    "direction",
    "usefft",
    "length",
    "width",
    "noise",
    "filtsmooth",
    "scale",
    "normfilt",
    "top",
    "dscale",
    "filterdim",
    "filtsmoothtime",
    "frequencies",
    "mode",
    "mu",
    "parallel",
    "saillinelength",
    "sallinelength",
    "tiletime",
    "totaliterations",
    "velcutoff",
    "velconmin",
    "autominvel",
    "velstartmin",
    "vsstartmin",
    "deltastartmin",
    "epsstartmin",
    "velconmax",
    "automaxvel",
    "velstartmax",
    "vsstartmax",
    "deltastartmax",
    "epsstartmax",
    "window",
    "w1",
    "w2",
    "w3",
    "w4",
    "extraleft",
    "extraright",
    "extrafront",
    "extraback",
    "extratop",
    "extrabottom",
    "borderx1",
    "borderx2",
    "borderx3",
    "bleft",
    "bright",
    "bfront",
    "bback",
    "btop",
    "bbottom",
    "id",
    "scalei",
    "scaler",
    "sourceamp",
    "sourcephase",
    "steplen"
]