export enum SettingTypes {
  Model = 1,
  Shot = 2,
  Data = 3,
  ModelType = 4,
  ShotType = 5,
  ShotIteration = 6,
  ModelView = 7,
  SliceSettings = 8,
  Depth = 9,
  ChartSettings = 10,
  FileSettings = 11,
}
