import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { debounce } from "lodash";
import * as d3 from "d3";

@Component({
  selector: "app-shot-chart",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./shot-chart.component.html",
  styleUrls: ["./shot-chart.component.less"],
})
export class ShotChartComponent implements OnInit {
  @Input() hideAxes: boolean = false;
  @Input() colorMap: string[] = ["black", "grey", "white"];
  @Input() set shotData(
    value: {
      x: number;
      y: number;
      shotId: number;
      hasTraceFile: boolean;
      traces_fit: number;
    }[]
  ) {
    this._shotData = value;
  }

  @Input() set rcvrData(
    value: {
      x: number;
      y: number;
      shotId: number;
      hasTraceFile: boolean;
      traces_fit: number;
    }[]
  ) {
    this._rcvrData = value;
  }

  @Input() set plotRcvrs(value: boolean) {
    this._plotRcvrs = value;
  }

  @Input() set backgroundImage(value: string) {
    this._backgroundImage = value;

    // this.drawAfter(50);
  }
  @Input() set height(value: number) {
    this._height = value;
  }
  @Input() modelScale: { x: number; y: number };
  @Input() set selectedShot(value: number) {
    this._selectedShot = value;
  }
  @Input() set colourType(value: string) {
    this._colourType = value;
  }
  @Input() set colorScale(value: { min: number; max: number }) {
    this.minTraceFit = value.min;
    this.maxTraceFit = value.max;
  }

  @ViewChild("graph", { static: true }) graph: ElementRef;
  @ViewChild("graphContainer", { static: true }) container: ElementRef;
  @Output("onShotSelected") shotSelection: EventEmitter<number> =
    new EventEmitter<number>();
  @Output("traceFit") traceFit: EventEmitter<number> =
    new EventEmitter<number>();

  private width: number;
  private _height: number = 200;
  private _shotData: {
    x: number;
    y: number;
    shotId: number;
    hasTraceFile: boolean;
    traces_fit: number;
  }[];
  private _rcvrData: {
    x: number;
    y: number;
    shotId: number;
    hasTraceFile: boolean;
    traces_fit: number;
  }[];
  private _backgroundImage: string;
  private _selectedShot: number;
  private _colourType: string;
  private svg;
  private updateDelay;
  private minTraceFit = 0;
  private maxTraceFit = 100;
  private _plotRcvrs = false;

  constructor() {}

  ngOnInit() {}

  @HostListener("window:resize", ["$event"])
  onresize(event) {
    this.resizeWithDelay(event);
  }

  private resizeWithDelay = debounce((event) => {
    this.refresh();
  }, 100);

  refresh() {
    // Remove previous shot graph
    if (this.svg) {
      this.svg.remove();
    }

    if (!this._shotData) return;

    if (
      this._selectedShot &&
      !this._shotData
        .filter((s) => s.hasTraceFile)
        .map((s) => s.shotId)
        .includes(this._selectedShot)
    ) {
      this._selectedShot = null;
    }
    this.width = this.container.nativeElement.offsetWidth;
    // calculate the sizes of svg and scales for shot points
    this.draw(this._shotData, this._rcvrData);
  }

  private draw(data, rcvrData?) {
    let xScale = d3
      .scaleLinear()
      .domain([0, this.modelScale.x])
      .range([0, this.width]);

    let domain = [0, this.modelScale.y];
    if (domain[1] < 0) {
      domain = [-domain[1], domain[1] * 0.03];
    }
    let yScale = d3.scaleLinear().domain(domain).range([this._height, 0]);

    let colourScale = d3
      .scaleLinear()
      .domain([
        this.minTraceFit,
        (this.maxTraceFit + this.minTraceFit) / 2,
        this.maxTraceFit,
      ])
      .range(this.colorMap);
    let svg = d3
      .select(this.graph.nativeElement)
      .attr("width", this.width)
      .attr("height", this._height)
      .append("g")
      .attr("id", "svg");
    this.svg = svg;

    // Background image
    svg
      .append("g")
      .attr("id", "bgImage")
      .append("image")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.width)
      .attr("height", this._height)
      .attr("preserveAspectRatio", "none")
      .attr("xlink:href", this._backgroundImage)
      .attr("fill", "white")
      .attr("opacity", 0.3);

    let colorType = this._colourType;
    let distinguishColor = this.colorMap.includes("red") ? "black" : "red";
    // Shot points
    let shots = svg.append("g").attr("id", "circles");

    shots
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return xScale(d.x);
      })
      .attr("cy", function (d) {
        return yScale(d.y);
      })
      .attr("r", function (d) {
        return d.hasTraceFile ? 6 : 3;
      })
      .attr("fill", function (d) {
        if (d.hasTraceFile) {
          return distinguishColor;
        }
        return colourScale(d[colorType]);
      })
      .classed(
        "selected",
        (d) => d.shotId === this._selectedShot && d.hasTraceFile
      )
      .on("mouseover", function (d) {
        d3.select(this).attr("r", 8).style("cursor", "pointer");
      })
      .on("mouseout", function (d) {
        d3.select(this).attr("r", function (d) {
          return d.hasTraceFile ? 6 : 3;
        });
      })
      .on("click", (d) => {
        this._selectedShot = d.shotId;
        d3.select("#shot-chart")
          .selectAll("circle")
          .classed("selected", (d) => d.shotId === this._selectedShot);
        this.shotSelection.emit(d.shotId);
      });

    if (rcvrData && this._plotRcvrs) {
      shots
        .append("g")
        .attr("id", "rcvrCircles")
        .selectAll("circle")
        .data(rcvrData)
        .enter()
        .append("circle")
        .attr("cx", function (d) {
          return xScale(d.x);
        })
        .attr("cy", function (d) {
          return yScale(d.y);
        })
        .attr("r", function (d) {
          return 1.5;
        })
        .attr("fill", function (d) {
          if (d.hasTraceFile) {
            return "green";
          }
          return "yellow";
        });
      shots.select(".selected").raise();
    }
  }
}
