<!-- <mat-tab-group
  *ngIf="panelNames.length > 1"
  [(selectedIndex)]="selectedPanel"
  (selectedIndexChange)="onTabChanged()"
  (keyup)="onKeySwitchTab($event)"
>
  <mat-tab *ngFor="let name of panelNames" [label]="getTabName(name)"></mat-tab>
</mat-tab-group> -->
<mat-button-toggle-group
  *ngIf="panelNames.length > 1"
  [(value)]="selectedPanel"
  (keyup)="onKeySwitchTab($event)"
>
  <mat-button-toggle
    class="text-uppercase"
    [value]="i"
    *ngFor="let name of panelNames; let i = index"
    (click)="onTabChanged(i)"
    >{{ getTabName(name) }}</mat-button-toggle
  >
</mat-button-toggle-group>
