import { map, switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, of } from "rxjs";

import { UserService } from "./user.service";
import { CacheService } from "./cache.service";
import { UserDetail } from "../../models/userDetail";

@Injectable()
export class CachedUserService {
  private _userDetail: BehaviorSubject<UserDetail> =
    new BehaviorSubject<UserDetail>(null);
  public readonly userDetail: Observable<UserDetail> =
    this._userDetail.asObservable();

  constructor(
    private userService: UserService,
    private cacheService: CacheService
  ) {}

  getUserDetails(): Observable<UserDetail> {
    return this.cacheService.get(
      "user_details",
      this.userService.getUserDetails().pipe(
        map((d) => {
          this._userDetail.next(d);
          return d;
        })
      )
    );
  }

  getBillingGroupAdmin(groupName: string): Observable<any> {
    return this.cacheService.get(
      "billing_group_" + groupName,
      this.userService.getBillingGroupAdmin(groupName)
    );
  }

  isGroupAdmin(): Observable<boolean> {
    return this.getUserDetails().pipe(
      switchMap((detail) => {
        if (!detail || !detail.groupName) return of(false);

        return this.getBillingGroupAdmin(detail.groupName).pipe(
          map((groupAdmin) => {
            return detail.id == groupAdmin;
          })
        );
      })
    );
  }
}
