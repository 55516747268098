<nav id="sidebar">
  <ul class="list-unstyled components">
    <li routerLinkActive="active">
      <a routerLink="dashboard"
        ><i class="zmdi zmdi-home"></i>
        <div class="hidden-xs">Project Dashboard</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="parameter-view"
        ><i class="zmdi zmdi-filter-list"></i>
        <div class="hidden-xs">Parameter View</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="job-groups"
        ><span nz-icon nzType="cluster" nzTheme="outline"></span>
        <div class="hidden-xs">Job Groups</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="data-table"
        ><i class="zmdi zmdi-grid"></i>
        <div class="hidden-xs">Data Table</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="model-table"
        ><i class="zmdi zmdi-shape"></i>
        <div class="hidden-xs">Model Table</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="flowchart"
        ><i class="zmdi zmdi-chart"></i>
        <div class="hidden-xs">Model Flowchart</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="flowchart2"
        ><i class="zmdi zmdi-chart"></i>
        <div class="hidden-xs">Model Flowchart 2</div></a
      >
    </li>
    <!-- <li routerLinkActive="active">
      <a routerLink="parameter-view"
        ><i class="zmdi zmdi-filter-list"></i>
        <div class="hidden-xs">Parameter View</div></a
      >
    </li> -->
    <!-- <li routerLinkActive="active">
      <a routerLink="table"
        ><i class="zmdi zmdi-view-list"></i>
        <div class="hidden-xs">Storage Table</div></a
      >
    </li> -->
    <li routerLinkActive="active">
      <a routerLink="table"
        ><span nz-icon nzType="database" nzTheme="outline"></span>
        <div class="hidden-xs">Storage Table</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="trace-fits-plotting">
        <img
          src="/assets/icons/chart.png"
          style="height: 20px; object-fit: contain"
        />
        <div class="hidden-xs">Trace fits plotting</div></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="image-panel"
        ><i class="zmdi zmdi-download"></i>
        <div class="hidden-xs">Image Download</div></a
      >
    </li>
  </ul>
</nav>
