export const environment = {
  production: false,
  apiUrl: 'https://staging-aws-d01.substrata.s-cube.com/api/v1',
  auth0: {
    clientId: 'yJMkUVlKyK30jsGrY9WVQQrpcCrVcaxh',
    domain: 'scube.eu.auth0.com',
    audience: 'https://aws-dev01.substrata.s-cube.com',
    scope: 'openid email'
  },
  stripeKey: 'pk_live_kEKNpjDgbUKvCX96SsBPv19h'
};
