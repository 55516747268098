<h3 mat-dialog-title>Job Settings</h3>

<form class="form-container" (ngSubmit)="submitForm()">
  <mat-dialog-content>
    <mat-form-field>
      <input matInput placeholder="Job Name" required [(ngModel)]="data.name" name="name">
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Project Group" required [(value)]="data.projectId" name="projectId">
        <mat-option *ngFor="let project of projects" [value]="project.id">{{project.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="button-container">
    <button mat-raised-button color="primary" type="submit">Update</button>
    <button mat-raised-button mat-dialog-close color="basic" type="reset">Cancel</button>
  </mat-dialog-actions>
</form>
