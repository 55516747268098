<div class="container-fluid">
  <div *ngIf="!currentJob" class="loading">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="loading-container">
              <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
              <div class="text">Loading...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!!currentJob" [@fadeInOut]>
    <div class="col-md-12">
      <div class="card" id="slice-settings" style="margin-bottom: 0px;">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 total-costs">
              <div class="cost-details row" *ngIf="!!currentJob">
                <div class="col-md-3">
                  <div class="des-box blue">
                    <div class="des-icon"><img src="/assets/icons/costs/icon02.png" alt=""></div>
                    <div class="des-detail">
                      <h2>Iterations</h2>
                      <p>{{iteration}} / {{currentJob.iterations}}</p>
                    </div>
                  </div>
                </div>
                <!-- <div *ngIf="hasCosts && isStaff">
                  <div class="col-md-3" *ngIf="currentJob.status == 'RUNNING'">
                    <div class="des-box">
                      <div class="des-icon"><img src="/assets/icons/costs/icon01.png" alt=""></div>
                      <div class="des-detail">
                        <h2>Elapsed Time</h2>
                        <p>{{elapsedTime}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="des-box light-blue">
                      <div class="des-icon"><img src="/assets/icons/costs/icon04.png" alt=""></div>
                      <div class="des-detail">
                        <h2>Cost / Iteration</h2>
                        <p *ngIf="costPerIteration>2">{{costPerIteration | currency:'USD' : 'symbol' : '1.0-0'}}</p>
                        <p *ngIf="costPerIteration<=2">{{costPerIteration | currency:'USD'}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="des-box purple">
                      <div class="des-icon"><img src="/assets/icons/costs/icon06.png" alt="" style="padding:13px;">
                      </div>
                      <div class="des-detail">
                        <h2>Cumulative Cost</h2>
                        <p>{{costPerIteration * iteration | currency:'USD' : 'symbol' : '1.0-0'}}</p>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div id="slice-settings" class="col-md-12">
              <h4 class="card-title">Slice Settings</h4>
              <div class="row">
                <div class="col-md-6" style="padding:0;">
                  <div class="sliders form-horizontal">
                    <div *ngIf="!currentJob.modelGrid.is2d">
                      <div class="form-group" style="display: flex; align-items: center;">
                        <!-- <div class="col-xs-2 text-right"><label>Inline</label></div> -->
                        <div class="col-xs-12 pink-slider">
                          <app-slider 
                          label="Inline"
                          [antd]="true" [min]="1" [max]="this.currentJob.modelGrid.nx1" [step]="steps[0]"
                            [(value)]="inlineNumber" (valueChange)="onSliderChanged()">
                          </app-slider>
                        </div>
                      </div>
                      <div class="form-group" style="display: flex; align-items: center;">
                        <!-- <div class="col-xs-2 text-right"><label>Crossline</label></div> -->
                        <div class="col-xs-12 pink-slider">
                          <app-slider label="Crossline"
                          [antd]="true" [min]="1" [max]="this.currentJob.modelGrid.nx2" [step]="steps[1]"
                            [(value)]="crosslineNumber" (valueChange)="onSliderChanged()">
                          </app-slider>
                        </div>
                      </div>
                      <div class="form-group" style="display: flex; align-items: center;">
                        <!-- <div class="col-xs-2 text-right"><label>Depth Slice</label></div> -->
                        <div class="col-xs-12 pink-slider">
                          <app-slider label="Depth Slice"
                          [antd]="true" [min]="1" [max]="this.currentJob.modelGrid.nx3" [step]="steps[2]"
                            [(value)]="depthSlice" (valueChange)="onSliderChanged()">
                          </app-slider>
                        </div>
                      </div>
                    </div>
                    <div class="form-group iteration-slider" style="display: flex; align-items: center;">
                      <!-- <div class="col-xs-2 text-right"><label>Iteration</label></div> -->
                      <div class="col-xs-12">
                        <app-slider 
                          label="Iteration"
                          [antd]="true" [min]="0" [max]="currentJob.iterations"
                          [completed]="currentJob.iterationsComplete" [(value)]="iteration" [step]="steps[3]"
                          (valueChange)="onSliderChanged()">
                        </app-slider>
                      </div>
                    </div>
                    <div class="form-group iteration-slider"
                      *ngIf="timeSliceModels.includes(currentModelTypeId) && availTimeSlices.length > 0">
                      <label class="col-sm-2">Time Slice</label>
                      <app-slider class="col-sm-10" [min]="availTimeSlices[0]"
                        [max]="availTimeSlices[availTimeSlices.length-1]" [(value)]="timeSlice"
                        [step]="getTimeSliceInterval()" (valueChange)="onSliderChanged()"></app-slider>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-4 center-box cube-chart" style="padding:0;" *ngIf="!currentJob.modelGrid.is2d && !isMobile">
                  <app-plotly [ranges]="ranges" [inlineNumber]="inlineNumber" [crosslineNumber]="crosslineNumber"
                    [depthSlice]="depthSlice" style="height:300px;"></app-plotly>
                </div> -->
                <div class="col-md-6 functional-sequence-table">
                  <!-- <table *ngIf="functionals != null && functionals.length > 0">
                    <thead>
                      <tr>
                        <th class="iterblock entry-col">
                          Block
                        </th>
                        <th class="functional entry-col">
                          Functional
                        </th>
                        <th class="frequency entry-col">
                          Frequency
                        </th>
                        <th class="additional entry-col">
                          Iterations
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let functional of functionals; let i = index" (click)="$event.stopPropagation()">
                        <td class="iterblock entry-col"> {{i+1}} </td>
                        <td class="functional entry-col"> {{functional}} </td>
                        <td class="frequency entry-col"> {{frequencies[i].toFixed(2)}} </td>
                        <td class="additional entry-col" style="display: flex;">
                          <button style="width: fit-content;" (click)="onSliderChanged(additionals[i][0])" [ngClass]="{'clickable':additionals[i][0] <= currentJob.iterationsComplete}" >{{additionals[i][0]}}</button>
                          <span style="width: fit-content;"> - </span>
                          <button style="width: fit-content;" (click)="onSliderChanged(additionals[i][1])" [ngClass]="{'clickable':additionals[i][1] <= currentJob.iterationsComplete}">{{additionals[i][1]}}</button>
                        </td>
                      </tr>
                    </tbody>
                  </table> -->
                  <div [ngStyle]="{'height': tableHeight, 'overflow': 'auto'}">
                    <mat-table *ngIf="functionals != null && functionals.length > 0" [dataSource]="dataSource">
                      <ng-container matColumnDef="Block">
                        <mat-header-cell *matHeaderCellDef> Block </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="cell"> {{element["block"]}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="Functional">
                        <mat-header-cell *matHeaderCellDef> Functional </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="cell"> {{element["functional"]}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="Frequency">
                        <mat-header-cell *matHeaderCellDef> Frequency </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="cell"> {{element["frequency"].toFixed(2)}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Iterations">
                        <mat-header-cell *matHeaderCellDef> Iterations </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="cell" style="display: flex;">
                          <span>{{element["iters"]["Iters"][0]}}</span>
                          <span>&nbsp;-&nbsp;</span>
                          <span>{{element["iters"]["Iters"][1]}}</span>
                          <span *ngIf="element['functional'] == 'RWI' && element['iters']['RWI VS Iters'] != null && element['iters']['RWI BG Iters'] != null">
                            &nbsp;(vs : {{element["iters"]['RWI VS Iters']}}, bg : {{element["iters"]["RWI BG Iters"]}})
                          </span>
                        </mat-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="cols" style="position: sticky; top: 0; z-index:1; background-color: white;"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: cols; let i = index" [ngStyle]="{ 'border-left': '5px solid', 'border-left-color': FUNCTIONAL_COLORS[row.functional], 'z-index': 0, 'opacity': row.block_started?1:0.5}"></mat-row>
                    </mat-table>
                  </div>
                  <button mat-stroked-button (click)="toggleTableHeight()" style="border: none; font-size: large;">
                    <i *ngIf="tableHeight == '300px'" class="zmdi zmdi-caret-down"></i>
                    <i *ngIf="tableHeight != '300px'" class="zmdi zmdi-caret-up"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="slices col-lg-12">
      <div class="loader" *ngIf="isLoading">
        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
      </div>
      <div id="slices">
        <div [ngClass]="{'fade-out': isLoading}" class="chart-slice-container">
          <div [id]="'slice_id_'+i" class="col-md-12 chart-slice" [style.width]="currentJob.modelGrid.is2d && !isMobile ? '75%' : getWidthForSliceType(slice.sliceType)" *ngFor="let slice of slices; let i = index"
            >
            <div class="card" *ngIf="!currentJob.modelGrid.is2d || i==0">
              <h4 class="card-title" style="margin-bottom:0;">
                {{getTitleForSliceType(slice.sliceType)}}&emsp;&emsp;{{getWellName(selectedSonicId)}}
                <div class="row" style="margin-left: 5%; top: -0.1%; position: absolute">
                  <div
                    [ngStyle]="{'visibility': currentModelTypeId === 'Total_Vp_Update' && i == 0 ? 'visible': 'hidden'}"
                    class="vpUpdateCheck row col-md-12">
                    <p class="vpPosition col-md-2" style="margin-top: 7%">Diff</p>
                    <mat-checkbox class='col-md-1' style="margin-top: 7%" [(ngModel)]="vpUpdateCheck"
                      (ngModelChange)="onVpUpdateChange($event)">
                    </mat-checkbox>
                    <mat-form-field class="col-sm-4">
                      <input matInput type="number" placeholder="iter" [(ngModel)]="vpDiffIteration"
                        (keyup)="onKeySubmission($event)">
                    </mat-form-field>
                  </div>
                  <div *ngIf="false" class="row col-md-6">
                    <div *ngIf='i == 0'>
                      <mat-form-field class="col-sm-4">
                        <input matInput type="number" placeholder="min" [(ngModel)]="minVal">
                      </mat-form-field>
                      <mat-form-field class="col-sm-4">
                        <input matInput type="number" placeholder="max" [(ngModel)]="maxVal">
                      </mat-form-field>
                    </div>
                    <div *ngIf='i == 2'>
                      <mat-form-field class="col-sm-4">
                        <input matInput type="number" placeholder="min" [(ngModel)]="minValZ">
                      </mat-form-field>
                      <mat-form-field class="col-sm-4">
                        <input matInput type="number" placeholder="max" [(ngModel)]="maxValZ">
                      </mat-form-field>
                    </div>
                    <button mat-stroked-button color="primary" class='col-md-2' style="margin-top: 3%"
                      (click)="onScaleChange(i)">
                      Replot
                    </button>
                  </div>
                </div>
              </h4>


              <div class="actions" *ngIf="i!=1">
                <a class="actions__item" [matMenuTriggerFor]="menu"><i class="zmdi zmdi-more-vert"></i></a>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item *ngIf="i!=1 && modelTypes && modelTypes.length>0"
                    [matMenuTriggerFor]="modelTypeMenu">
                    Model Property
                  </button>
                  <div class="slider-container" style="text-overflow:clip; height: fit-content;" *ngIf="i==0" [matTooltip]="height">
                    Height
                    <mat-slider [min]="250" [max]="800" [step]="25" (click)="$event.stopPropagation()" #ngSlider>
                      <input matSliderThumb [(ngModel)]="height" (change)="inputHeight = height; setVpHeight()" #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                  </div>
                  <div class="slider-container" style="text-overflow:clip;" *ngIf="i==2" [matTooltip]="depthHeight">
                    Height
                    <mat-slider [min]="200" [max]="maxDepth * 1.3" [step]="25" (click)="$event.stopPropagation()" #ngSlider>
                      <input matSliderThumb [(ngModel)]="depthHeight" (change)="inputDepthHeight = depthHeight; setVpHeight()" #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                  </div>
                  <button mat-menu-item (click)="showMinMaxDialog(slice.sliceType)">
                    Min/Max
                  </button>
                  <div mat-menu-item *ngIf="i==2 && shotOverlayTypes && shotOverlayTypes.length>0"
                    [matMenuTriggerFor]="shotOverlayMenu">
                    <mat-slide-toggle [(ngModel)]="showShotOverlay" (click)="$event.stopPropagation();">
                    </mat-slide-toggle>
                    <span>Shot Overlay&nbsp;</span>
                  </div>
                  <div class="slider-container" *ngIf="i==2 && shotOverlayTypes && shotOverlayTypes.length>0 && showShotOverlay">
                    <span>Shot Radius</span>
                    <mat-slider [min]="1" [max]="10" [step]="1" (click)="$event.stopPropagation();">
                      <input [(ngModel)]="shotRadius" matSliderThumb />
                    </mat-slider>
                  </div>
                  <mat-form-field *ngIf="i==2 && shotOverlayTypes && shotOverlayTypes.length>0 && showShotOverlay" style="padding-left: 5%;">
                    <mat-label>Shot Colour Min</mat-label>
                    <input matInput type="number" [(ngModel)]="currentShotOverlayType.min" (click)="$event.stopPropagation();" (keyup)="onKeySubmissionShotOverlayType($event)">
                  </mat-form-field>
                  <mat-form-field *ngIf="i==2 && shotOverlayTypes && shotOverlayTypes.length>0 && showShotOverlay" style="padding-left: 5%;">
                    <mat-label>Shot Colour Max</mat-label>
                    <input matInput type="number" [(ngModel)]="currentShotOverlayType.max" (click)="$event.stopPropagation();" (keyup)="onKeySubmissionShotOverlayType($event)">
                  </mat-form-field>

                  <div mat-menu-item [matMenuTriggerFor]="logMenu" *ngIf="currentJob.containsLog && i==0">
                    <span>Sonic Logs&nbsp;</span>
                    <mat-slide-toggle [(ngModel)]="showSonicLog" (change)="onSonicLogClicked()"></mat-slide-toggle>
                  </div>
                  <div mat-menu-item [matMenuTriggerFor]="horizonMenu" *ngIf="i==0 && horizonTypes.length>0">
                    <span>Horizon&nbsp;</span>
                    <mat-slide-toggle [(ngModel)]="showHorizon" (click)="onSonicLogClicked();"></mat-slide-toggle>
                  </div>

                  <button mat-menu-item *ngIf="!!palettes && palettes.length>0 && i==0" [matMenuTriggerFor]="paletteMenu">
                    Colour Map
                  </button>
                  <button mat-menu-item *ngIf="timeSliceModels.includes(currentModelTypeId)"
                    [matMenuTriggerFor]="timeSliceMenu">
                    Time Slices
                  </button>
                  <button mat-menu-item *ngIf="shotModels.includes(currentModelTypeId)" [matMenuTriggerFor]="shotMenu">
                    Shots
                  </button>
                  <div mat-menu-item *ngIf="currentJob.modelGrid.is2d">
                    <mat-slide-toggle [(ngModel)]="enableClick">Enable Click</mat-slide-toggle>
                  </div>

                  <div mat-menu-item>
                    <mat-slide-toggle (change)="toggleDepthSlice($event)" [checked]="showDepthSliceGraph">
                      Show Depth slice
                    </mat-slide-toggle>
                  </div>

                  <button mat-menu-item (click)="downloadSlicesAsPng()">
                    <mat-slide-toggle [(ngModel)]="showDepthSliceScreenshot" (click)="$event.stopPropagation(); depthScreenshotToggle();"></mat-slide-toggle>
                    <span>Screenshot&nbsp;</span>
                    {{depthScreenshotText}}
                  </button>
                  <div mat-menu-item>
                    <mat-slide-toggle [(ngModel)]="showDottedLines" (click)="$event.stopPropagation(); onSonicLogClicked();"></mat-slide-toggle>
                    <span>Dotted Lines&nbsp;</span>
                  </div>
                  <mat-label htmlFor="xlStart" *ngIf="i==0" style="padding-left: 16px;font-size: 15px;" >Start:</mat-label>
                  <div class="menu-xl-start-fields">
                    <nz-input-group [nzSize]="'default'" id="xlStart" *ngIf="currentJob.modelGrid.is2d || currentJob.x2_is_inline && i==0" style="flex-grow:1;" (click)="$event.stopPropagation()" [style.width.px]=70>
                      <label>XL</label>
                      <input nz-input type="number" [(ngModel)]="ILXLStartCrossline" (keyup)="submitSliceRanges($event)">
                    </nz-input-group>
                    <nz-input-group [nzSize]="'default'" *ngIf="currentJob.modelGrid.is2d || currentJob.x2_is_inline && i==0" style="flex-grow:1;" (click)="$event.stopPropagation()" [style.width.px]=70>
                      <label>IL</label>
                      <input nz-input type="number" [(ngModel)]="ILXLStartInline" (keyup)="submitSliceRanges($event)">
                    </nz-input-group>
                    <nz-input-group [nzSize]="'default'" id="xlStart" *ngIf="!currentJob.modelGrid.is2d && !currentJob.x2_is_inline && i==0" style="flex-grow:1;" (click)="$event.stopPropagation()" [style.width.px]=70>
                      <label>IL</label>
                      <input nz-input type="number" [(ngModel)]="ILXLStartInline" (keyup)="submitSliceRanges($event)">
                    </nz-input-group>
                    <nz-input-group [nzSize]="'default'" *ngIf="!currentJob.modelGrid.is2d && !currentJob.x2_is_inline && i==0" style="flex-grow:1;" (click)="$event.stopPropagation()" [style.width.px]=70>
                      <label>XL</label>
                      <input nz-input type="number" [(ngModel)]="ILXLStartCrossline" (keyup)="submitSliceRanges($event)">
                    </nz-input-group>
                    <nz-input-group [nzSize]="'default'" *ngIf="i==0" style="flex-grow:1;" (click)="$event.stopPropagation()" [style.width.px]=70>
                      <label>Depth</label>
                      <input nz-input type="number" [(ngModel)]="ILXLStartDepth" (keyup)="submitSliceRanges($event)">
                    </nz-input-group>
                  </div>
                  <mat-label htmlFor="xlEnd" *ngIf="i==0" style="padding-left: 16px;font-size: 15px;" >End:</mat-label>
                  <div class="menu-xl-end-fields">
                    <nz-input-group [nzSize]="'default'" id="xlEnd" *ngIf="currentJob.modelGrid.is2d || currentJob.x2_is_inline && i==0" style="flex-grow:1;" (click)="$event.stopPropagation()" [style.width.px]=70>
                      <label>XL</label>
                      <input nz-input type="number" [(ngModel)]="ILXLEndCrossline" (keyup)="submitSliceRanges($event)">
                    </nz-input-group>
                    <nz-input-group [nzSize]="'default'" *ngIf="currentJob.modelGrid.is2d || currentJob.x2_is_inline && i==0" style="flex-grow:1;" (click)="$event.stopPropagation()" [style.width.px]=70>
                      <label>IL</label>
                      <input nz-input type="number" [(ngModel)]="ILXLEndInline" (keyup)="submitSliceRanges($event)">
                    </nz-input-group>
                    <nz-input-group [nzSize]="'default'" id="xlEnd" *ngIf="!currentJob.modelGrid.is2d && !currentJob.x2_is_inline && i==0" style="flex-grow:1;" (click)="$event.stopPropagation()" [style.width.px]=70>
                      <label>IL</label>
                      <input nz-input type="number" [(ngModel)]="ILXLEndInline" (keyup)="submitSliceRanges($event)">
                    </nz-input-group>
                    <nz-input-group [nzSize]="'default'" *ngIf="!currentJob.modelGrid.is2d && !currentJob.x2_is_inline && i==0" style="flex-grow:1;" (click)="$event.stopPropagation()" [style.width.px]=70>
                      <label>XL</label>
                      <input nz-input type="number" [(ngModel)]="ILXLEndCrossline" (keyup)="submitSliceRanges($event)">
                    </nz-input-group>
                    <nz-input-group [nzSize]="'default'" *ngIf="i==0" style="flex-grow:1;" (click)="$event.stopPropagation()" [style.width.px]=70>
                      <label>Depth</label>
                      <input nz-input type="number" [(ngModel)]="ILXLEndDepth" (keyup)="submitSliceRanges($event)">
                    </nz-input-group>
                  </div>
                  <div style="display: flex; justify-content: flex-end;">
                    <button mat-stroked-button (click)="resetRanges()" style="margin: 5px 16px;">Reset Ranges</button>
                  </div>
                </mat-menu>


                <mat-menu #modelTypeMenu="matMenu">
                  <button *ngFor="let modelType of modelTypes" mat-menu-item (click)="setModelType(modelType, $event)">
                    <span>{{modelType.name}}</span>
                    <i *ngIf="modelType.id===currentModelTypeId" class="zmdi zmdi-check" style="margin-left:2px;"></i>
                  </button>
                </mat-menu>
                <mat-menu #paletteMenu="matMenu">
                  <button *ngFor="let palette of palettes" mat-menu-item (click)="setPalette(palette)">
                    <span>{{palette}}</span>
                    <i *ngIf="palette==currentJob.last_used_palette" class="zmdi zmdi-check" style="margin-left:2px;"></i>
                  </button>
                </mat-menu>
                <mat-menu #logMenu="matMenu">
                  <button *ngFor="let log of currentJob.wellLogs" mat-menu-item (click)="setLog(log.id)">
                    <span>IL - {{log.inline}}, XL - {{log.crossline}}</span>
                  </button>
                </mat-menu>
                <mat-menu #horizonMenu="matMenu">
                  <div *ngFor="let type of horizonTypes" >
                    <div  mat-menu-item>
                      <span (click)="setHorizon(type)">{{type}}</span>
                      <i *ngIf="isHorizonSelected(type)" class="zmdi zmdi-check" style="margin-left:2px;"></i>
                    </div>
                  </div>
                </mat-menu>
                <mat-menu #shotOverlayMenu="matMenu">
                  <button *ngFor="let shotOverlayType of shotOverlayTypes" mat-menu-item
                    (click)="setShotOverlayType(shotOverlayType)">
                    <span>{{shotOverlayType.name}}</span>
                    <i *ngIf="shotOverlayType.id===currentShotOverlayType.id" class="zmdi zmdi-check"
                      style="margin-left:2px;"></i>
                  </button>
                </mat-menu>
                <mat-menu #timeSliceMenu="matMenu">
                  <button *ngFor="let time of availTimeSlices" mat-menu-item (click)="setTimeSlice(time)">
                    <span>{{time}}</span>
                    <i *ngIf="timeSlice==time" class="zmdi zmdi-check" style="margin-left:2px;"></i>
                  </button>
                </mat-menu>
                <mat-menu #shotMenu="matMenu">
                  <button *ngFor="let shot of availShots" mat-menu-item (click)="setShot(shot)">
                    <span>{{shot}}</span>
                    <i *ngIf="shotId==shot" class="zmdi zmdi-check" style="margin-left:2px;"></i>
                  </button>
                </mat-menu>
              </div>
              <app-chart-slice [shot]='shotId' [id]="currentJob.id" [ranges]="getRangesForSliceType(slice.sliceType)"
                [xSteps]="getXStepForSliceType(slice.sliceType)" [ySteps]="getYStepForSliceType(slice.sliceType)"
                [sliceType]="getAdjustedSliceType(slice.sliceType)" [iteration]="iteration"
                [sliceNumber]="getNumberForSliceType(slice.sliceType)" [xTitle]="getXTitleForSliceType(slice.sliceType)"
                [yTitle]="getYTitleForSliceType(slice.sliceType)" [updateChart]="updateChart"
                [refreshChart]="refreshChart" [height]="i==2?depthHeight:height" [is2d]="currentJob.modelGrid.is2d"
                [xLine]="getXForSliceType(slice.sliceType)" [yLine]="getYForSliceType(slice.sliceType)"
                [showLegend]="i!=1 || inlineWidth==100" [palette]="currentJob.last_used_palette"
                [sonicLogId]="showShowLogId()" [dx]="this.currentJob.modelGrid.dx"
                [sonicNX]="getSonicNXForSliceType(slice.sliceType)"
                [sonicWidth]="getSonicWidthForSliceType(slice.sliceType)" [hoverLabelX]="getHoverLabelX(slice.sliceType)"
                [hoverLabelY]="getHoverLabelY(slice.sliceType)" [velocityRange]="getCurrentVeloctiyRange(slice.sliceType)"
                [modelType]="currentModelTypeId" [shotOverlayType]="currentShotOverlayType"
                [showShotOverlay]="getShotOverlay(slice.sliceType)" [shotRadius]="shotRadius"
                [showHorizons]="getHorizons(slice.sliceType)" [horizonColors]="horizonColors"
                [totalIterations]="currentJob.iterations" [x2IsInline]="currentJob.x2_is_inline"
                [isVpReference]="vpUpdateCheck" [vpIteration]="vpDiffIteration" [timeSlice]='timeSlice'
                [pngNameDetails]="{pName:currentJob.projectName, jName:currentJob.name, cpnum:iteration}"
                [indexInDocument]="vpPositionSwapped? (i==1?2: (i==2?1: 0)) : i" [showDottedLines]="showDottedLines"
                [startDepth]="ILXLStartDepth" [endDepth]="ILXLEndDepth"
                [startInline]="ILXLStartInline" [endInline]="ILXLEndInline"
                [startCrossline]="ILXLStartCrossline" [endCrossline]="ILXLEndCrossline"
                (clickEmitter)="onClickEmit($event)" (colorbarTextEmitter)="onColorbarTextEmit($event)"
                (loadFinishedEmitter)="onLoadFinishedEmit($event)">
              </app-chart-slice>
            </div>
          </div>

          <div id="velocity-profile" [ngClass]="currentJob.modelGrid.is2d ? 'velocity-profile' : 'velocity-profile'"  *ngIf="!isLoading"
            [style.width]="getWidthForSliceType(null)"
            style="margin-left: 0 !important;"
            >
            <div class="card" style="padding-bottom: 2.15px !important;" >
              <h4 class="card-title" style="white-space: nowrap; margin-bottom: 0;">Vertical Profile</h4>
              <app-vertical-profile [height]="currentJob.modelGrid.is2d ? vpHeight + 23 : vpHeight"
              [depth]="depth" [depthSlice]="depthSlice" [velocities]="velocities" [hideAxes]="false"
              [axisMin]="verticalProfileAxis[0]" [axisMax]="verticalProfileAxis[1]"
              [modelTypeId]="currentModelTypeId" [isLoading]="verticalProfileIsLoading" [hasError]="verticalProfileHasError" [gridSpacing]="currentJob.modelGrid.dx"
              [ILXLStartDepth]="ILXLStartDepth" [ILXLEndDepth]="ILXLEndDepth"
              (axisMinMaxEmitter)="onVerticalProfileAxisMinMaxUpdate($event)" (syncAxisEmitter)="onSyncAxisUpdate($event)"
              ></app-vertical-profile>
              <mat-slide-toggle class="swap-pos" *ngIf="!currentJob.modelGrid.is2d" [(ngModel)]="vpPositionSwapped" (change)="swapPosition()">
                <span>View next to Inline</span>
              </mat-slide-toggle>
            </div>
          </div>

          <div id="comment" class="col-lg-6 col-md-6 velocity-profile" *ngIf="!isLoading"
          [ngStyle]="{'float': currentJob.modelGrid.is2d ? 'left' : 'right'}"
          [style.width]="getWidthForSliceType(null)"
          style="margin-left: auto !important;"
          >
          <div class="card" style="padding-bottom: 2.15px !important;" >
            <h4 class="card-title" style="white-space: nowrap; margin-bottom: 0;">Comment</h4>
            <div style="padding-top: 20px;">
              <textarea [(ngModel)]="comment" matTooltip="Enter your comment" rows="3" style="width: 100%; resize: both;" [style.height]="currentJob.modelGrid.is2d ? vpHeight - 26 + 23 + 'px' : vpHeight - 26 + 'px'"></textarea>
            </div>
              <!-- <button color="primary" class="custom-button" (click)="updateComment(tooltip)">Save</button> -->
              <button class="custom-button" (click)="updateComment()" [disabled]="isUpdated">{{ isUpdated ? 'Updated' : 'Save' }}</button>
            </div>
        </div>
          <div style="display: inline-block; margin-left: 10px; vertical-align: top;" [style.height]="vpHeight" class="card" *ngIf="showHorizon">
            <h4 class="card-title" style="margin-bottom:15px">Selected Horizons:</h4>
            <div *ngFor="let type of showHorizonTypes; let i = index">
              <div style="display: inline-block;">{{type}}</div>
              <Button style="display: inline-block; width: 16px; height: 16px; border-radius: 8px;" [(colorPicker)]="horizonColors[i]" (colorPickerChange)="onColorChanged($event)"
                      [style.background-color]="horizonColors[i]">
              </Button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="sliders-bar" [ngClass]="{'open': sliderBarOpen, 'visible': sliderBarVisible}">
      <div class="col-md-12">
        <div>
          <h4 class="card-title" style="margin: 0;">Slice Settings</h4>
          <div class="actions" (click)="sliderBarOpen = !sliderBarOpen"><i class="fa fa-arrow-up"></i></div>
        </div>
        <div class="row">
          <div class="col-md-6" style="padding:0;" *ngIf="!currentJob.modelGrid.is2d">
            <div *ngIf="!currentJob.modelGrid.is2d">
              <div class="form-group">
                <!-- <div class="col-xs-2 text-right"><label>Inline</label></div> -->
                <div class="col-xs-12">
                  <app-slider label="Inline" [antd]="true" [min]="1" [max]="this.currentJob.modelGrid.nx1" [step]="steps[0]"
                    [(value)]="inlineNumber" (valueChange)="onSliderChanged()">
                  </app-slider>
                </div>
              </div>
              <div class="form-group">
                <!-- <div class="col-xs-2 text-right"><label>Crossline</label></div> -->
                <div class="col-xs-12">
                  <app-slider label="Crossline" [antd]="true" [min]="1" [max]="this.currentJob.modelGrid.nx2" [step]="steps[1]"
                    [(value)]="crosslineNumber" (valueChange)="onSliderChanged()">
                  </app-slider>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" style="padding:0; white-space: nowrap;">
            <div >
              <div *ngIf="!currentJob.modelGrid.is2d" class="form-group">
                <!-- <div class="col-xs-2 text-right"><label>Depth Slice</label></div> -->
                <div class="col-xs-12">
                  <app-slider label="Depth Slice" [antd]="true" [min]="1" [max]="this.currentJob.modelGrid.nx3" [step]="steps[2]"
                    [(value)]="depthSlice" (valueChange)="onSliderChanged()">
                  </app-slider>
                </div>
              </div>
              <div class="form-group" >
                <!-- <div class="col-xs-2 text-right"><label>Iteration</label></div> -->
                <div class="col-xs-12">
                  <app-slider label="Iteration" [antd]="true" [min]="0" [max]="currentJob.iterations"
                    [completed]="currentJob.iterationsComplete" [(value)]="iteration" [step]="steps[3]"
                    (valueChange)="onSliderChanged()">
                  </app-slider>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-12">
            <mat-checkbox style="margin-top:10px;margin-right:38px;float:right;" [(ngModel)]="synchroniseSettings"
              (ngModelChange)="onSynchroniseChange($event)">Synchronise Settings</mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
