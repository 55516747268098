<div class="_container">
  <div
    style="
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 10px;
    "
  >
    <div
      style="
        display: flex;
        gap: 10px;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-grow: 1;
      "
    >
      <div style="display: flex; gap: 10px">
        <button nz-button [nzLoading]="this.isLoading" nzType="primary">
          Rebuild Table
        </button>
        <button
          nz-button
          [nzLoading]="this.is_exporting_as_csv || this.isLoading"
          nzType="default"
          (click)="_export_table_as_csv()"
        >
          <span nz-icon nzType="export" nzTheme="outline"></span>Export as CSV
        </button>
        <button
          nz-button
          nzType="dashed"
          (click)="this.storageClassesModalVisible = true"
        >
          Storage Classes
        </button>
      </div>
      <div style="display: flex; gap: 10px">
        <button
          nz-button
          [nzLoading]="this.isLoading"
          [disabled]="setOfCheckedId.size === 0"
          nzType="primary"
          nz-popconfirm
          nzPopconfirmTitle="Are you sure you want to keep all selected jobs?"
          (nzOnConfirm)="keepSelected()"
        >
          Keep Selected
        </button>
        <button
          nz-button
          [nzLoading]="this.isLoading"
          [disabled]="setOfCheckedId.size === 0"
          nzType="default"
          nz-popconfirm
          nzPopconfirmTitle="Are you sure you want to unkeep all selected jobs?"
          (nzOnConfirm)="unkeepSelected()"
        >
          Unkeep Selected
        </button>
        <button nz-button nzType="default" (click)="resetFilters()">
          Clear all filters
        </button>
      </div>
    </div>
    <div>
      <div style="display: flex; justify-content: space-between; gap: 10px">
        <b>Expected Project Storage Cost: </b>
        <span>{{ projectCostInfo?.cost.toFixed(4) || 0 }}$ per Month</span>
      </div>
      <div style="display: flex; justify-content: space-between; gap: 10px">
        <b>Potential Project Storage Savings: </b>
        <span
          >{{ projectCostInfo?.potential_savings.toFixed(4) || 0 }}$ per
          Month</span
        >
      </div>
    </div>
  </div>

  <nz-table
    #filterTable
    [nzData]="this.storageData"
    nzTableLayout="fixed"
    [nzLoading]="this.isLoading"
    nzShowPagination
    nzShowSizeChanger
    [nzScroll]="{ x: '1200px' }"
    (nzCurrentPageDataChange)="onCurrentPageDataChange()"
    class="_storage-table"
  >
    <thead>
      <tr>
        <th
          nzWidth="35px"
          [nzChecked]="checked"
          [nzIndeterminate]="indeterminate"
          nzLabel="Select all"
          nzLeft
          (nzCheckedChange)="onAllChecked($event)"
        ></th>
        <ng-container *ngFor="let column of this.listOfColumns">
          <th
            [nzSortOrder]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections"
            [nzFilterMultiple]="column.filterMultiple"
            [nzFilters]="column.listOfFilter"
            [nzFilterFn]="column.filterFn"
            [nzWidth]="column.width"
            [nzLeft]="column.left"
            [style.zIndex]="column.left ? '3' : ''"
          >
            {{ column.name }}
          </th>
        </ng-container>
        <th nzRight nzWidth="125px">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of filterTable.data">
        <td
          [nzChecked]="setOfCheckedId.has(data.job_id)"
          [nzDisabled]="data.disabled"
          [nzLabel]="data.job_name"
          nzLeft
          (nzCheckedChange)="onItemChecked(data.job_id, $event)"
        ></td>
        <td nzLeft>{{ data.job_name }}</td>
        <td>{{ data.functional }}</td>
        <td>{{ data.comments }}</td>
        <td>
          <div style="display: flex; justify-content: space-between">
            {{ data.storage_cost?.toFixed(4) || 0 }}
            <span>$ per Month</span>
          </div>
        </td>
        <td>
          <div style="display: flex; justify-content: space-between">
            {{ data.potential_savings?.toFixed(4) || 0 }}
            <span>$ per Month</span>
          </div>
        </td>
        <td>{{ data.job_status }}</td>
        <td>{{ data.iterations }}</td>
        <td>{{ data.iters }}</td>
        <td nzRight>
          <div>
            <a
              *ngIf="!data.keep"
              (click)="activeJob = data; keepConfirmationModalVisible = true"
              >Keep</a
            >
            <a
              *ngIf="data.keep"
              (click)="activeJob = data; unkeepConfirmationModalVisible = true"
              >Unkeep</a
            >
            <nz-divider nzType="vertical" />
            <a
              (click)="
                this.comments = data.comments;
                this.activeJob = data;
                this.editCommentModal = true
              "
              >Edit</a
            >
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<!-- MODALS DECLARATIONS -->

<nz-modal
  nzCentered
  [(nzVisible)]="unkeepConfirmationModalVisible"
  nzTitle="Confirm Unkeep"
  (nzOnCancel)="unkeepConfirmationModalVisible = false; this.activeJob = null"
  (nzOnOk)="handleConfirmUnkeep()"
>
  <ng-container *nzModalContent>
    <p [innerHTML]="getUnkeepConfirmationContent()"></p>
  </ng-container>
</nz-modal>

<nz-modal
  nzCentered
  [(nzVisible)]="keepConfirmationModalVisible"
  nzTitle="Confirm Keep"
  (nzOnCancel)="keepConfirmationModalVisible = false; this.activeJob = null"
  (nzOnOk)="handleConfirmKeep()"
>
  <ng-container *nzModalContent>
    <p>Are you sure you want to keep the job {{ activeJob?.job_name }}?</p>
  </ng-container>
</nz-modal>

<nz-modal
  nzCentered
  [(nzVisible)]="editCommentModal"
  [nzTitle]="'Edit Comment for Job: ' + this.activeJob?.job_name"
  (nzOnCancel)="editCommentModal = false; this.activeJob = null"
  (nzOnOk)="saveComment()"
>
  <ng-container *nzModalContent>
    <nz-input-group class="legacy-input-styling" style="padding: 0">
      <label style="left: 10px">Comments</label>
      <textarea
        nz-input
        [(ngModel)]="this.comments"
        [nzAutosize]="{ minRows: 2 }"
      ></textarea>
    </nz-input-group>
  </ng-container>
</nz-modal>

<nz-modal
  nzCentered
  [(nzVisible)]="storageClassesModalVisible"
  nzTitle="Storage Classes"
  (nzOnCancel)="storageClassesModalVisible = false"
>
  <ng-container *nzModalContent>
    <div>
      There are a total of 5 storage classes within the XWI + Amazon system. The
      following is an explanation of each:
      <ul>
        <li>
          <b>keep:</b>
          All the files of this job have been retained in standard storage. Keep
          in mind that this is the most costly form of storage and should be
          used sparingly.
        </li>

        <li>
          <b>full storage:</b>
          The jobs marked 'full storage' will have intermediate checkpoints
          removed and sent to Glacier Instant Retrieval. You will still be able
          to access relevant models and shots in these jobs.
        </li>
        <li>
          <b>&lt; 7 days (full storage):</b>
          These jobs have been created in the past 7 days. If you decide to not
          'keep' these jobs, they will automatically be set to full storage, and
          sent to Glacier Instant Retrieval after the 7 days expire.
        </li>
        <li>
          <b> glacier instant retrieval:</b>
          These jobs have had intermediate checkpoint files deleted. You will
          still be able to access relevant models and shots in these jobs. Most
          if not all jobs should reside in this tier.
        </li>
        <li>
          <b>Glacier Deep Archive:</b> These jobs have been put in deep archive
          and are not accessible. Please consult the admin to unarchive these
          jobs.
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-container *nzModalFooter>
    <button
      nz-button
      nzType="primary"
      (click)="storageClassesModalVisible = false"
    >
      OK
    </button>
  </ng-container>
</nz-modal>
