<!-- <div style="display: flex; justify-content: end; gap: 10px">
  <button nz-button nzType="default" (click)="openGroup()">Open Group</button>
  <button nz-button nzType="default" (click)="openGroupInParameterView()">
    Open Group in Parameter View
  </button>
</div> -->
<nz-table
  #filterTable
  [nzData]="this.jobs"
  nzTableLayout="fixed"
  [nzLoading]="isLoading"
  nzSize="small"
  nzShowPagination
  nzShowSizeChanger
>
  <thead>
    <tr>
      <ng-container *ngFor="let column of this.listOfColumns">
        <th
          [nzSortOrder]="column.sortOrder"
          [nzSortFn]="column.sortFn"
          [nzSortDirections]="column.sortDirections"
          [nzFilterMultiple]="column.filterMultiple"
          [nzWidth]="column.width"
        >
          {{ column.name }}
        </th>
      </ng-container>
      <th nzWidth="100px">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let data of filterTable.data">
      <tr>
        <td>{{ data.job__job_name }}</td>
        <td>
          {{ data.job__comments }}
        </td>
        <td>
          <a
            nz-popconfirm
            nzPopconfirmTitle="Are you sure you want to remove the job from this group?"
            (nzOnConfirm)="removeJobFromGroup(data)"
            nz-tooltip
            nzTooltipTitle="Remove job from group"
          >
            <span
              nz-icon
              nzType="delete"
              nzTheme="outline"
              style="font-size: larger"
            ></span>
          </a>
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>
