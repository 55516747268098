<div>
  <mat-grid-list cols="6" class='overview-block'>
    <mat-grid-tile *ngFor="let pic of screenshotsList">
      <div style="width: 90%; float: right;">
        <mat-checkbox [(ngModel)]='pic.selected'></mat-checkbox>
        <img class='overview'  (click)='onPicSelected(pic)' [src]='pic.pic'/>
        <div class='overview-caption'>
          <span> {{pic.caption}} </span>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="-group image-selector">
    <button type="button" mat-raised-button (click)="fileInput.click()">Upload Screenshots</button>
    <input style="display: none;" multiple (change)="onFileChosen()" #fileInput type="file" id="file">
    <button type="button" mat-raised-button (click)="deleteScreenshot()">Delete Selected</button>
  </div>
</div>
