<div #graphContainer style="position: relative">
  <!-- <div class="row" style="margin-left: 4%; white-space: nowrap">
      <div class="zoom-factor">Zoom Factor: {{zoomFactor}}</div>
    </div> -->
  <div class="loading" [hidden]="!isLoading">
    <mat-progress-spinner
      mode="indeterminate"
      diameter="25"
    ></mat-progress-spinner>
    <div class="text">Loading next slice...</div>
  </div>
  <div class="error" *ngIf="!isLoading && hasError">Couldn't load profile</div>
  <div class="chart-controls" *ngIf="!isLoading && !hasError">
    <div class="zoom-factor">Zoom: {{ zoomFactor }}</div>
    <!-- <button (click)="reset()" title="Reset"><i class="zmdi zmdi-undo"></i></button>
      <button (click)="zoomIn()" title="Zoom In"><i class="zmdi zmdi-zoom-in"></i></button>
      <button (click)="zoomOut()" title="Zoom Out"><i class="zmdi zmdi-zoom-out"></i></button> -->
    <button [matMenuTriggerFor]="menu" class="three-dot-menu">
      <i class="zmdi zmdi-more-vert"></i>
    </button>
    <mat-menu #menu="matMenu">
      <div mat-menu-item>
        <!-- <mat-form-field >
          <mat-label>X-Axis Minimum</mat-label>
          <input matInput type="number" (click)="inputClicked($event)" (change)="onKeySubmission()" (keyup)="onKeySubmission()" [(ngModel)]="axisMin">
        </mat-form-field> -->
        <nz-input-group
          class="nz-input-group-floating-label"
          [nzSize]="'large'"
          id="X-Axis Minimum"
          (click)="$event.stopPropagation()"
          [style.width.px]="200"
        >
          <label>X-Axis Minimum</label>
          <input
            nz-input
            type="number"
            (click)="inputClicked($event)"
            (change)="onKeySubmission()"
            (keyup)="onKeySubmission()"
            [(ngModel)]="axisMin"
          />
        </nz-input-group>
      </div>
      <div mat-menu-item>
        <!-- <mat-form-field >
            <mat-label>X-Axis Maximum</mat-label>
            <input matInput type="number" (click)="inputClicked($event)" (keyup)="onKeySubmission()" [(ngModel)]="axisMax">
          </mat-form-field> -->
        <nz-input-group
          class="nz-input-group-floating-label"
          [nzSize]="'large'"
          id="X-Axis Maximum"
          (click)="$event.stopPropagation()"
          [style.width.px]="200"
        >
          <label>X-Axis Maximum</label>
          <input
            nz-input
            type="number"
            (click)="inputClicked($event)"
            [(ngModel)]="axisMax"
            (keyup)="onKeySubmission()"
          />
        </nz-input-group>
      </div>
      <!-- <div mat-menu-item>
          <mat-slide-toggle [(ngModel)]="syncAxis" (change)="toggleSync()" (click)="$event.stopPropagation()">
            <mat-label>Sync</mat-label>
          </mat-slide-toggle>
        </div> -->
      <div mat-menu-item>
        <button mat-stroked-button color="primary" (click)="resetAxis()">
          Reset
        </button>
      </div>
    </mat-menu>
  </div>
  <svg id="svg" #graph></svg>
</div>
