<div class="chart-container" (window:resize)="onResize($event)" style="position: relative">
  <div class="chart" #chart></div>
  <div class="chart-controls" style="display: flex;" *ngIf="!isLoading && !hasError && !hideControls">
    <div style="width: fit-content; padding-top: 2px;" class="zoom-factor">Zoom: {{d3HeatmapChart == null ? 1 :d3HeatmapChart.attrs.lastTransform.k.toPrecision(3)}}</div>
    <button (click)="resetClicked()" title="Reset"><i class="zmdi zmdi-undo"></i></button>
    <button (click)="zoomInClicked()" title="Zoom In"><i class="zmdi zmdi-zoom-in"></i></button>
    <button (click)="zoomOutClicked()" title="Zoom Out"><i class="zmdi zmdi-zoom-out"></i></button>
  </div>
  <div class="loading" [hidden]="!isLoading">
    <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner><div class="text">Loading next slice...</div>
  </div>
  <div class="error" *ngIf="!isLoading && hasError">{{errorMessage}}</div>
  <div style="display: flex; width: fit-content; position: absolute; right: 5%; bottom: 2%;">
  <a *ngIf="_imageBase64 != null" class="shot-height-trigger" [matMenuTriggerFor]="heightMenu"><i class="zmdi-download zmdi" style="font-size: large;"></i></a>
  </div>
  <mat-menu #heightMenu="matMenu">
    
    <mat-form-field style="margin-left: 5%;">
      <mat-label>Specify PNG Width:</mat-label>
      <input matInput type="number" [(ngModel)]="pngWidth" (click)='$event.stopPropagation()'>
    </mat-form-field>
    <mat-form-field style="margin-left: 5%;">
      <mat-label>Specify PNG Height:</mat-label>
      <input matInput type="number" [(ngModel)]="pngHeight" (click)='$event.stopPropagation()'>
    </mat-form-field>
    <button mat-flat-button color="primary" style="margin-left: 5%;" *ngIf="_imageBase64 != null" (click)="downloadPng()">DOWNLOAD</button>

  </mat-menu>
</div>
