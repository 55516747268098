import { Component, HostListener, OnInit } from "@angular/core";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { Project } from "../../../models/project";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RebuildModelTableComponent } from "../../project/dialogs/rebuild-model-table/rebuild-model-table.component";
import { fields } from "./fields";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from "@angular/forms";

@Component({
  selector: "app-model-table",
  templateUrl: "./model-table.component.html",
  styleUrls: ["./model-table.component.less"],
})
export class ModelTableComponent implements OnInit {
  project_id: string;
  project_name: string;

  activeRow: object = null;
  showEditModal: boolean = false;
  loading: boolean = false;
  data: Array<any> = [];
  options: Object = fields;
  jobsWithIterations: Array<object> = [];

  selectedOriginJob: Object = null;
  comment: string = "";
  editModelFormControl: UntypedFormGroup;

  linkDefinitions = [
    {
      key: "parent_job_name",
      template: (row: object) => `projects/${row["parent_job"]}/model`,
    },
    {
      key: "origin_job_name",
      template: (row: object) => `projects/${row["origin_job"]}/model`,
    },
  ];
  inMenuButtonConfig = [
    {
      text: "Rebuild Table",
      action: (event, info) => {
        this.rebuildModelTable();
      },
    },
  ];

  cp_num: UntypedFormControl;
  // = new FormControl('', [Validators.max(this.editModelFormControl.value['selectedOriginJob']['iterations_completed'])]);

  constructor(
    private projectService: CachedProjectService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public fb: UntypedFormBuilder
  ) {
    this.projectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project_id = project.id;
        this.project_name = project.name;
        this.getModelTable();

        this.editModelFormControl = fb.group({
          selectedOriginJob: null,
          comments: "",
          cp_num: null,
        });

        this.projectService
          ._getProjectJobs(this.project_id, {
            fields: ["id", "job_name", "iterations_completed", "project__name"],
          })
          .toPromise()
          .then((res) => (this.jobsWithIterations = res.data))
          .catch((err) => console.log(err));
      }
    });
  }

  ngOnInit() {}

  getModelTable() {
    this.loading = true;
    this.projectService
      ._getProjectModelTable(this.project_id)
      .toPromise()
      .then((res) => {
        this.data = [...res.data];
        if (res["message"]) {
          this.snackBar.open(res["message"], "", {
            duration: 3000,
          });
        }
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.snackBar.open(err["error"], "", {
          duration: 3000,
        });
        this.loading = false;
      });
  }

  rebuildModelTable() {
    this.dialog
      .open(RebuildModelTableComponent, {
        width: "400px",
        data: {
          project_name: this.project_name,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != undefined && result["confirm"]) {
          this.loading = true;
          this.projectService
            ._rebuildProjectModelTable(this.project_id)
            .toPromise()
            .then((res) => {
              this.data = res["data"];
              if (res["message"]) {
                this.snackBar.open(res["message"], "", {
                  duration: 3000,
                });
              }
              this.loading = false;
            })
            .catch((err) => {
              console.error(err);
              this.snackBar.open(err["error"], "", {
                duration: 3000,
              });
              this.loading = false;
            });
        }
      });
  }

  collapsibleSlot(row: object) {
    let htmlString = "";

    htmlString += "<div>";
    if (row["jobs"] && row["jobs"].length > 0) {
      row["jobs"].forEach((job: object) => {
        htmlString += `<a href="projects/${job["id"]}/model">${job["job_name"]}</a><br>`;
      });
    } else {
      htmlString += "No jobs are using this Model!";
    }

    htmlString += "</div>";
    return htmlString;
  }

  editRow(data: object): void {
    this.showEditModal = true;
    this.activeRow = { ...data };

    this.editModelFormControl.controls["comments"].setValue(data["comments"]);
    if (data["origin_job"]) {
      this.editModelFormControl.controls["selectedOriginJob"].setValue(
        this.jobsWithIterations.find((job) => job["id"] == data["origin_job"])
      );
      this.editModelFormControl.controls["cp_num"].setValue(data["origin_cp"]);
    }
  }

  saveChanges(event: any) {
    // this long if is only to make sure that the cp_num is valid and less than max
    if (this.editModelFormControl.value["selectedOriginJob"]) {
      if (
        this.editModelFormControl.value["cp_num"] < 0 ||
        this.editModelFormControl.value["cp_num"] >
          this.editModelFormControl.value["selectedOriginJob"][
            "iterations_completed"
          ]
      ) {
        this.editModelFormControl.controls["cp_num"].setErrors({
          invalid: true,
          incorrect: true,
        });
        return;
      }
    } else {
      this.editModelFormControl.controls["cp_num"].setErrors({
        invalid: false,
        incorrect: false,
      });
    }

    // now the form submission
    const updatedValues = {
      id: this.activeRow["id"],
      density_id: this.activeRow["density_id"],
      model_id: this.activeRow["model_id"],
      comments: this.editModelFormControl.value["comments"],
      origin_job_id: null,
      origin_cp_num: null,
    };

    if (this.editModelFormControl.value["selectedOriginJob"]) {
      updatedValues["origin_job_id"] =
        this.editModelFormControl.value["selectedOriginJob"]["id"];
      updatedValues["origin_cp_num"] =
        this.editModelFormControl.value["cp_num"];
    }

    this.loading = true;
    this.projectService
      ._updateModeltable(this.project_id, updatedValues)
      .toPromise()
      .then((res) => {
        this.loading = false;
        this.getModelTable();
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
    this.showEditModal = false;
    this.editModelFormControl.reset();
  }

  cancelEdit() {
    this.showEditModal = false;
    this.editModelFormControl.reset();
  }

  @HostListener("document:click", ["$event"])
  onclickImage(e: Event) {
    let element = e.target as HTMLElement;

    if (element.classList.contains("model-table-comp-modal")) {
      this.showEditModal = false;
    }
  }
}
