<div>
    <div class="container-fluid">
        <div style="display: flex; justify-content: space-between;">
            <div>
                <div style="display: inline-block;">
                    <div *ngIf="is_getting_project_table" style="display: inline-block;">
                        <mat-spinner mode="indeterminate" color="primary" diameter="20"></mat-spinner>
                    </div>
                    <button mat-button (click)="_get_project_table(project_id, false)" style="display: inline-block;">Refresh Table</button>    
                </div>
                <div style="display: inline-block;">
                    <div *ngIf="is_getting_project_table" style="display: inline-block;">
                        <mat-spinner mode="indeterminate" color="primary" diameter="20"></mat-spinner>
                    </div>
                    <button mat-button (click)="rebuild_project_table(project_id)" style="display: inline-block;">Rebuild Table</button>    
                </div>
                <div style="display: inline-block;">
                    <div *ngIf="is_exporting_as_csv" style="display: inline-block;">
                        <mat-spinner mode="indeterminate" color="primary" diameter="20"></mat-spinner>
                    </div>
                    <button mat-button (click)="_export_table_as_csv(project_id)" style="display: inline-block;">Export as CSV</button>
                </div>
            </div>
            <div>
                <b>Project Storage Cost: </b> {{this.project_total_storage_cost.toFixed(2)}}
            </div>
        </div>
        <div class="table">
            <table>
                <thead>
                    <tr>
                        <th (click)="sort_table('job_name')" style="width: 20%;">Job Name
                            <span *ngIf="sort_by == 'job_name'">
                                <span *ngIf="sort_order == 'asc'"><a><i class="zmdi zmdi-caret-up"></i></a></span>
                                <span *ngIf="sort_order == 'desc'"><a><i class="zmdi zmdi-caret-down"></i></a></span>
                            </span>
                        </th>
                        <th (click)="sort_table('job_type')" style="width: 15%;">Job Type
                            <span *ngIf="sort_by == 'job_type'">
                                <span *ngIf="sort_order == 'asc'"><a><i class="zmdi zmdi-caret-up"></i></a></span>
                                <span *ngIf="sort_order == 'desc'"><a><i class="zmdi zmdi-caret-down"></i></a></span>
                            </span>
                        </th>
                        <th (click)="sort_table('storage_cost')" style="width: 10%;">Storage Cost
                            <span *ngIf="sort_by == 'storage_cost'">
                                <span *ngIf="sort_order == 'asc'"><a><i class="zmdi zmdi-caret-up"></i></a></span>
                                <span *ngIf="sort_order == 'desc'"><a><i class="zmdi zmdi-caret-down"></i></a></span>
                            </span>
                        </th>
                        <th (click)="sort_table('job_status')" style="width: 20%;">Job Status
                            <span *ngIf="sort_by == 'job_status'">
                                <span *ngIf="sort_order == 'asc'"><a><i class="zmdi zmdi-caret-up"></i></a></span>
                                <span *ngIf="sort_order == 'desc'"><a><i class="zmdi zmdi-caret-down"></i></a></span>
                            </span>
                        </th>
                        <th (click)="sort_table('iters')" style="width: 15%;">Iters
                            <span *ngIf="sort_by == 'iters'">
                                <span *ngIf="sort_order == 'asc'"><a><i class="zmdi zmdi-caret-up"></i></a></span>
                                <span *ngIf="sort_order == 'desc'"><a><i class="zmdi zmdi-caret-down"></i></a></span>
                            </span>
                        </th>
                        <th (click)="sort_table('keep')" style="width: 20%;">Keep
                            <span *ngIf="sort_by == 'keep'">
                                <span *ngIf="sort_order == 'asc'"><a><i class="zmdi zmdi-caret-up"></i></a></span>
                                <span *ngIf="sort_order == 'desc'"><a><i class="zmdi zmdi-caret-down"></i></a></span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entry of table_data" [style.border-left]="'5px solid'" [style.border-left-color]="get_color_for_type(entry.job_type)">
                        <td class="job-name" (click)="nav_to_job(entry.job_id)" style="width: 20%;">{{ entry.job_name }}</td>
                        <td style="width: 20%;">{{ entry.job_type }}</td>
                        <td style="width: 5%;">{{ four_dp(entry.storage_cost) }}</td>
                        <td style="width: 20%;">{{ entry.job_status }}</td>
                        <td style="width: 15%;">{{ entry.iters }}</td>
                        <td style="width: 20%;">
                            <div style="display: flex; justify-content: space-between; vertical-align: middle; align-items: center;">
                                <div>
                                    {{ entry.keep ? 'Yes' : 'No' }}
                                </div>  
                                <button mat-raised-button (click)="keep_job(entry.job_id)" style="display: inline-block;">
                                    Update
                                </button>
                            </div>
    
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
</div>