import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { JobDialog } from '../../../../models/jobDialog';


@Component({
  selector: 'app-job-delete',
  templateUrl: './job-delete.component.html',
  styleUrls: ['./job-delete.component.less']
})
export class JobDeleteComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) form;

  constructor(@Inject(MAT_DIALOG_DATA) public data: JobDialog, public dialogRef: MatDialogRef<JobDeleteComponent>) { }

  ngOnInit() {
  }

  submitForm() {
    if (!this.form.valid)
      return;
    
    this.dialogRef.close(this.data);
  }
}
