<div class="field-listing-container">
  <div
    style="font-size: 36px; color: rgba(0, 0, 0, 0.7)"
    class="os-animation fadeInUp animated"
  >
    Fields
  </div>
  <div
    class="text os-animation fadeInUp animated"
    data-os-animation="fadeInUp"
    data-os-animation-delay="0.2s"
    style="animation-delay: 0.2s"
  >
    <p>Select your Field or create a new one to add your data</p>
  </div>
  <button
    *ngIf="!demoUser"
    nz-button
    nzType="dashed"
    nzBlock
    (click)="getAllProjects(); fieldModalVisible = true"
  >
    <span nz-icon nzType="plus" nzTheme="outline"></span>
    Create New Field
  </button>

  <nz-collapse style="margin: 15px 0" [nzBordered]="false" nzAccordion>
    <nz-collapse-panel
      *ngFor="let field of fields; index as i"
      [nzHeader]="collapseHeader"
      (nzActiveChange)="getFieldProjects(field)"
    >
      <ng-template #collapseHeader>
        <div
          style="
            display: flex;
            justify-content: space-between;
            gap: 10px;
            flex-grow: 1;
          "
        >
          <div>
            <div style="font-size: 15px; margin-bottom: 5px">
              {{ field.name }}
            </div>
            <div>
              <span style="color: rgba(0, 0, 0, 0.5)">
                {{ field.description }}
              </span>
            </div>
          </div>
          <span
            class="settings-btn zmdi zmdi-edit"
            (click)="editField(field); $event.stopPropagation()"
          ></span>
        </div>
      </ng-template>
      <nz-spin [nzSpinning]="this.isLoading">
        <div
          *ngFor="let project of this.fieldProjects[field.id]"
          class="project-list-item"
          (click)="goToProject(project)"
        >
          {{ project.name }}

          <p style="margin: 5px 0px; color: rgba(0, 0, 0, 0.5)">
            {{ project.description }}
          </p>
        </div>
      </nz-spin>
    </nz-collapse-panel>
  </nz-collapse>
</div>

<nz-modal
  [nzVisible]="fieldModalVisible"
  [nzTitle]="activeField ? 'Edit Field' : 'Create New Field'"
  (nzOnCancel)="closeFieldModal()"
  (nzOnOk)="!activeField ? createNewField() : onEditField()"
>
  <ng-container *nzModalContent>
    <nz-spin [nzSpinning]="isLoading">
      <nz-input-group class="nz-input-group-floating-label">
        <label for="name">Field Name</label>
        <input
          type="text"
          nz-input
          [(ngModel)]="fieldName"
          id="name"
          name="name"
          placeholder="Field Name"
        />
      </nz-input-group>
      <div style="max-height: 200px; overflow-y: scroll; position: relative">
        <nz-input-group class="nz-input-group-floating-label">
          <label for="projects">Field Projects</label>
          <nz-select
            style="width: 100%"
            nzMode="multiple"
            nzPlaceHolder="Please select Projects"
            [(ngModel)]="activeFieldProjects"
            id="projects"
            name="projects"
          >
            <nz-option
              *ngFor="let p of allProjects"
              [nzValue]="p.id"
              [nzLabel]="p.name"
            ></nz-option>
          </nz-select>
        </nz-input-group>
      </div>
      <nz-input-group class="nz-input-group-floating-label">
        <label for="description">Field Description</label>
        <textarea
          type="text"
          nz-input
          [(ngModel)]="fieldDescription"
          id="description"
          name="description"
          placeholder="Field Description"
        ></textarea>
      </nz-input-group>
    </nz-spin>
  </ng-container>
</nz-modal>
