<h3 mat-dialog-title>Job Status</h3>

<form class="form-container" (ngSubmit)="submitForm()">
  <mat-dialog-content>
    <span *ngIf="data.status != 'RUNNING'">Are you sure you want to <b>START</b> the job:<br/><em>{{data.name}}</em></span>
    <span *ngIf="data.status == 'RUNNING'">Are you sure you want to <b>STOP</b> the job:<br/><em>{{data.name}}</em></span>
  </mat-dialog-content>
  <mat-dialog-actions class="button-container">
    <button mat-raised-button color="primary" type="submit">Yes</button>
    <button mat-raised-button mat-dialog-close color="basic" type="reset">No</button>
  </mat-dialog-actions>
</form>
