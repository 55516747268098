import { Component, OnInit } from "@angular/core";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { forkJoin, Observable, Observer } from "rxjs";
import { PictureModelComponent } from "../dialogs/picture-model/picture-model.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-picture",
  templateUrl: "./screenshot.component.html",
  styleUrls: ["./screenshot.component.css"],
})
export class ScreenshotComponent implements OnInit {
  screenshots: any[][] = [];
  screenshotsList: any[] = [];
  selectedScreenshots: any = [];
  private currentJob = null;
  private currentScreenshot = null;
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private projectService: CachedProjectService
  ) {}

  ngOnInit() {
    this.projectService.currentJob.subscribe((d) => {
      if (!d) return;
      this.currentJob = d;
      this.loadPictures();
    });
  }

  loadPictures() {
    this.projectService
      .getScreenShots(this.currentJob.id)
      .subscribe((res: any[]) => {
        res.forEach((r) => (r.selected = false));
        this.screenshotsList = res;
      });
  }

  onPicSelected(pic) {
    this.currentScreenshot = this.screenshotsList.indexOf(pic);
    let dialogRef = this.dialog.open(PictureModelComponent, {
      height: "90%",
      width: "70%",
    });
    dialogRef.componentInstance.data = pic;

    dialogRef.componentInstance.onCaptionSaved.subscribe((result: any) => {
      let picture = this.screenshotsList[this.currentScreenshot];
      picture.caption = result;
      this.projectService
        .updateScreenshotsCaption(picture.id, picture.caption)
        .subscribe(
          (res) => {
            this.snackBar.open("Caption Saved", null, { duration: 2000 });
          },
          (error) => {
            this.snackBar.open("Saving Caption Failed", null, {
              duration: 2000,
            });
          }
        );
    });

    dialogRef.componentInstance.onChange.subscribe((result: any) => {
      this.currentScreenshot = Math.min(
        this.screenshotsList.length - 1,
        Math.max(0, this.currentScreenshot + result)
      );
      let newScreenshot = this.screenshotsList[this.currentScreenshot];
      dialogRef.componentInstance.data = newScreenshot;
    });
  }

  onFileChosen() {
    const input: any = document.querySelector("#file");
    this.upload(input.files);
  }

  deleteScreenshot() {
    let screenshots = this.screenshotsList.filter((s) => s.selected);
    forkJoin(
      ...screenshots.map((s) => this.projectService.deleteScreenshot(s.id))
    ).subscribe(
      (responses) => {
        responses.forEach((res) => {
          let idx = this.screenshotsList.findIndex((s) => s.id == res);
          if (idx < 0) return;
          this.screenshotsList.splice(idx, 1);
        });
        this.snackBar.open("Screenshots Deleted", null, { duration: 2000 });
      },
      (error) => {
        this.snackBar.open("Sorry, deletion failed", null, { duration: 2000 });
      }
    );
  }

  upload(files) {
    let fileNum = files.length;
    if (fileNum <= 0) return;
    let readFiles = Observable.create((observer: Observer<any>) => {
      let screenshots = [];
      let counter = 0;
      let reader: FileReader = new FileReader();
      reader.onloadend = function () {
        let caption = files[counter].name.split(".")[0];
        screenshots.push({
          caption: caption,
          data: btoa(<string>reader.result),
        });
        counter += 1;
        if (counter == fileNum) {
          observer.next(screenshots);
          observer.complete();
        } else {
          reader.readAsBinaryString(files[counter]);
        }
      };
      reader.readAsBinaryString(files[0]);
    });
    readFiles.subscribe((screenshots) => {
      this.projectService
        .uploadScreenshots(this.currentJob.id, screenshots)
        .subscribe(
          (data) => {
            this.screenshotsList = this.screenshotsList.concat(
              data.screenshots
            );
            this.snackBar.open("Upload completed", null, { duration: 2000 });
          },
          (error) => {
            this.snackBar.open("Sorry, pictures cannot be uploaded", null, {
              duration: 2000,
            });
          }
        );
    });
  }
}
