import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { ProjectDialog } from "../../../../models/projectDialog";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgForm } from "@angular/forms";
import { ProjectService } from "../../../../shared/services/project.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { take } from "rxjs/operators";
import { Field } from "../../../../models/field";

@Component({
  selector: "app-project-name",
  templateUrl: "./project-name.component.html",
  styleUrls: ["./project-name.component.less"],
})
export class ProjectNameComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) form;

  isLoading: boolean = false;
  fields: Field[] = [];
  selectedField: Field | null = null;
  selectedFieldId: string = "";
  readonly compareFn = (o1: Field, o2: Field): boolean =>
    o1 && o2 ? o1.id === o2.id : o1 === o2;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProjectDialog,
    public dialogRef: MatDialogRef<ProjectNameComponent>,
    private projectService: ProjectService,
    private notificationService: NzNotificationService
  ) {
    this.selectedField = this.data.field || null;
    this.getFields();
  }

  ngOnInit() {}

  getFields() {
    this.isLoading = true;
    this.projectService
      .getAllFields()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.fields = res;
          this.isLoading = false;
        },
        error: (err) => {
          this.notificationService.error(
            "Error",
            err.error?.error || "There was an unexepected error"
          );
          this.isLoading = false;
        },
      });
  }

  submitForm() {
    if (!this.form.valid) return;
    this.data.field = this.selectedField;
    this.dialogRef.close(this.data);
  }
}
