import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import type { ECharts, EChartsOption } from "echarts";
import { NzFormatEmitEvent, NzTreeNodeOptions } from "ng-zorro-antd/tree";
import { Project } from "../../../models/project";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { parameters } from "./data";
import { cloneDeep } from "lodash";

const NODE_TOOLTIP_PARAM_KEYS = [
  "density_ramps",
  "min_gardner_vel",
  "max_gardner_vel",
  "salt_velocity",
  "salt_density",
  "water_mincells",
  "water_maxcells",
  "water_velocity",
  "water_density",
  "gardner_factor",
  "gardner_power",
  "fixed_density",
];

function getDifferences(obj1: object, obj2: object) {
  const differences = [];
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      differences.push(key);
    }
  }
  return differences;
}

@Component({
  selector: "app-flowchart2",
  templateUrl: "./flowchart2.component.html",
  styleUrls: ["./flowchart2.component.less"],
})
export class Flowchart2Component {
  @ViewChild("graph", { static: true }) graph: ElementRef;
  chartInstance: ECharts;

  project_id: string = "";
  project_name: string = "";
  loading: boolean = false;
  error: string = null;
  searchValue: string = "";

  // these vars are for the chart
  nodes: Array<object> = [];
  links: Array<object> = [];
  options: EChartsOption;
  categories: Array<object> = [];

  // these vars are for the filter panel on the rightside
  jobs: NzTreeNodeOptions[] = [];
  filteredJobs: NzTreeNodeOptions[] = [];
  jobsSelectAll: boolean = true;
  jobsSelectIndeterminate: boolean = false;
  parameterOptions: NzTreeNodeOptions[] = null;
  filteredParams: NzTreeNodeOptions[] = [];
  searchValueParam: string = "";

  constructor(
    @Inject(CachedProjectService) private projectService: CachedProjectService,
    private _snackbar: MatSnackBar
  ) {
    this.loading = true;
    this.projectService.currentProject.subscribe((project: Project) => {
      if (project != null) {
        this.project_id = project.id;
        this.project_name = project.name;
        this.get_project_flowchart(this.project_id);
      }
    });

    // title: d.label,
    // key: d.id,
    // category: uniqCategories.findIndex(
    //   (cat) => cat.name == d.category
    // ),
    // jobType: d.category,
    // isLeaf: true,
    // checked: true,
    const temp = [];
    Object.keys(parameters).forEach((key) => {
      const children = parameters[key].map((param) => ({
        title: param,
        key: param,
        isLeaf: true,
      }));
      temp.push({
        title: key,
        key: key,
        children: children,
      });
    });
    this.parameterOptions = temp;
    this.filteredParams = [...temp];
    // console.log("temp", temp);
  }

  get_project_flowchart(project_id, FM = false) {
    this.loading = true;
    // console.log("getting network");
    this.projectService
      .getProjectFlowchart(project_id, FM)
      .toPromise()
      .then((data) => {
        const nodeSet = [];
        const categoriesSet = [];
        const uniqCategories = [];
        const jobs = [];
        // console.log(data);
        const uniqNodes = [];
        data?.nodes?.forEach((d) => {
          if (nodeSet.includes(d.label)) {
            return;
          } else {
            if (!categoriesSet.includes(d.category)) {
              categoriesSet.push(d.category);
              uniqCategories.push({ name: d.category });
            }
            nodeSet.push(d.label);
            uniqNodes.push({
              id: d.id,
              name: d.label,
              category: uniqCategories.findIndex(
                (cat) => cat.name == d.category
              ),
              params: d.params,
              fullname: d?.fullname || null,
            });
            // these jobs are for the select boxes in the filter panel on the right
            jobs.push({
              title: d.label,
              key: d.id,
              category: uniqCategories.findIndex(
                (cat) => cat.name == d.category
              ),
              jobType: d.category,
              isLeaf: true,
              checked: true,
            });
          }
        });

        this.jobs = jobs;
        this.filteredJobs = this.jobs;
        this.categories = uniqCategories;
        this.nodes = uniqNodes || [];
        this.links = data?.links || [];
        this.makeGraph();
      })
      .catch((err) => {
        this._snackbar.open(`Error ${err.status}: ${err.message}`, "Close");
        this.error = "An error occured! Please reload the page or try later.";
      })
      .finally(() => {
        this.loading = false;
      });
  }

  ngOnInit(): void {}

  makeGraph() {
    this.loading = true;
    // console.log("making graph", this.nodes, this.links);
    this.options = {
      backgroundColor: "white",
      title: {
        text: "Project Flowchart",
        subtext: "Connecting all jobs",
        top: "top",
        left: "right",
      },
      tooltip: {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        position: function (pos, params, el, elRect, size) {
          var obj = { top: 10 };
          obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        },
        extraCssText: "height: 500px; overflow-y: scroll",
      },
      legend: [
        {
          data: this.categories.map(function (a) {
            return a["name"];
          }),
          left: "left",
        },
      ],
      animationDuration: 100,
      animationEasingUpdate: "quinticInOut",
      series: [
        {
          name: "Job",
          type: "graph",
          height: 500,
          legendHoverLink: false,
          layout: "force",
          force: {
            initLayout: "circular",
            repulsion: 200,
            edgeLength: 100,
            friction: 0.1,
          },
          data: this.nodes,
          links: this.links,
          categories: this.categories,
          roam: true,
          draggable: true,
          edgeSymbol: ["none", "arrow"],
          tooltip: {
            formatter: function (param) {
              // console.log(param);
              let tooltipContent: string = "";
              const { dataType } = param;

              if (dataType == "node") {
                tooltipContent = "node information";
                const nodeInfo = param.data;
                let tooltipRows = NODE_TOOLTIP_PARAM_KEYS.map(
                  (key) => `
                    <div style="width: 100%; display: flex; align-items: center; justify-content: space-between; gap: 15px;">
                      <b>${key}</b><span>${nodeInfo.params[key]}</span>
                    </div>
                  `
                );
                // let tooltipRows = NODE_TOOLTIP_PARAM_KEYS.map(
                //   (key) => `${key}: ${nodeInfo.params[key]} <br/>`
                // );
                tooltipContent = [
                  `<div style="width: 100%; display: flex; justify-content: space-between; gap: 15px;">
                    <b>Job Name</b> 
                    <span style="max-width: 250px; white-space: pre-wrap; word-wrap: break-word">${nodeInfo.fullname}</span>
                  </div>`,
                  `<div style="widhth: 100%; height: 1px; border-radius: 1px; background: #ccc"></div>`,
                  ...tooltipRows,
                ].join("");
              } else if (dataType == "edge") {
                tooltipContent = "edge information";
                const edgeInfo = param.data;
                // console.log("data", this.data);

                const { source, target } = edgeInfo;
                // let obj1 = this.data.find((job) => job.id == source);
                // let obj2 = this.data.find((job) => job.id == target);
                // let diff = getDifferences(obj1, obj2);
                // tooltipContent += `Diff betweeen ${source} and ${target}`;
                // tooltipContent += diff
                //   .map((key) => `${key}: ${obj1[key]} - ${obj2[key]}<br/>`)
                //   .join("");
              } else {
                tooltipContent = "none";
              }
              return tooltipContent;
            },
          },
          symbolSize: 50,
          zoom: 0.5,
          // The node itself
          itemStyle: {},
          // The node label
          label: {
            show: true,
            position: "inside",
          },
          edgeLabel: {
            show: true,
            formatter: (d) => {
              return d.data.valueOf()["label"];
            },
            fontSize: 20,
            position: "middle",
          },
          lineStyle: {
            color: "source",
            curveness: 0.3,
          },
          // effect on hover
          emphasis: {
            focus: "adjacency",
            lineStyle: {
              width: 10,
            },
          },
          scaleLimit: {
            min: 0.4,
            max: 2,
          },
        },
      ],
    };
    // console.log("options: ", this.options);
    this.loading = false;
  }

  clickHandler(event) {
    // console.log(event);
  }

  jobTreeActionEvent(event: NzFormatEmitEvent): void {
    if (event.eventName == "check") {
      this.updateSingleChecked();
    }
  }

  paramTreeActionEvent(event: NzFormatEmitEvent): void {
    // console.log(event);
  }

  filterJobs() {
    this.filteredJobs = this.jobs.filter((job: NzTreeNodeOptions) =>
      job.title.includes(this.searchValue)
    );
  }

  filterParams() {
    // this.filteredParams = this.parameterOptions.filter(
    //   (param: NzTreeNodeOptions) => param.children.filter.includes(this.searchValueParam)
    // );

    // const temp = [...this.parameterOptions];
    const temp = cloneDeep(this.parameterOptions);
    temp?.forEach((category, index) => {
      category.children = category.children.filter((param) =>
        param.title.includes(this.searchValueParam)
      );
    });
    this.filteredParams = temp.filter(
      (category) => category.children.length > 0
    );
    // console.log(this.filteredParams, this.parameterOptions);
  }

  updateAllChecked(): void {
    // console.log("updateAllChecked firing");
    this.jobsSelectIndeterminate = false;
    if (this.jobsSelectAll) {
      this.jobs = this.jobs.map((item) => ({
        ...item,
        checked: true,
      }));
    } else {
      this.jobs = this.jobs.map((item) => ({
        ...item,
        checked: false,
      }));
    }

    this.filteredJobs = this.jobs;
    this.searchValue = "";
  }

  updateSingleChecked(): void {
    // console.log("updateSingleChecked firing");
    if (this.jobs.every((item) => !item.checked)) {
      this.jobsSelectAll = false;
      this.jobsSelectIndeterminate = false;
    } else if (this.jobs.every((item) => item.checked)) {
      this.jobsSelectAll = true;
      this.jobsSelectIndeterminate = false;
    } else {
      this.jobsSelectIndeterminate = true;
    }

    this.updateNodes();
  }

  updateNodes() {
    this.nodes = this.jobs
      .filter((job) => job.checked)
      .map((job) => ({
        id: job.key,
        name: job.title,
        category: job.category,
      }));
    this.chartInstance.setOption({
      series: [{ data: this.nodes }],
    });
  }

  chartInit(event) {
    this.chartInstance = event;
  }

  hideSingletonJobs() {
    const linkSourcesSet = new Set(this.links.map((link) => link["source"]));
    const linkTargetSet = new Set(this.links.map((link) => link["target"]));

    // the internal job.checked thing will update the state of the selects on the filter panel
    const newNodes = this.jobs
      .filter((job) => {
        if (linkSourcesSet.has(job.key) || linkTargetSet.has(job.key)) {
          job.checked = true;
          return true;
        } else {
          job.checked = false;
          return false;
        }
      })
      .map((job) => ({
        id: job.key,
        name: job.title,
        category: job.category,
      }));

    // make copy cause otherwise it has the same ref and it kinda doesn't really update it
    this.filteredJobs = [...this.jobs];
    this.searchValue = "";

    this.nodes = newNodes;
    // console.log(this.jobs, this.filteredJobs);
    this.chartInstance.setOption({
      series: [{ data: this.nodes }],
    });
  }
}
