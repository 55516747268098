import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { JobDetail } from "../../../models/jobDetail";
import { ShotSettings } from "../../../models/shotSettings";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { StoredSettingsService } from "../../services/stored-settings.service";
export interface ShotChartMenuSettings {
  shotGraphHeight: number;
  plotRcvr: boolean;
  minValZ: number;
  maxValZ: number;
  selectedShotColourType: string;
}
@Component({
  selector: "app-shot-select-chart-menu",
  templateUrl: "./shot-select-chart-menu.component.html",
  styleUrls: ["./shot-select-chart-menu.component.less"],
})
export class ShotSelectChartMenuComponent implements OnInit, OnChanges {
  @Input() jobDetail: JobDetail;
  @Input() settingsKey: string;
  @Output() menuChanged = new EventEmitter<ShotChartMenuSettings>();
  @Output() recieverIntervalChanged = new EventEmitter<number>();
  shotGraphHeight: number = 200;
  plotRcvr = false;
  minValZ: number;
  maxValZ: number;
  shotColourTypes = [
    { id: "traces_fit", name: "Trace fit" },
    { id: "traces_low", name: "Trace fit - Low" },
  ];
  selectedShotColourType: string = "traces_fit";
  rcvrInterval = 50;
  constructor(private storedSettingsService: StoredSettingsService) {}

  ngOnInit() {
    this.restoreSettings();
  }
  ngOnChanges(changes: SimpleChanges) {
    const change = changes.jobDetail;
    if (change && change.currentValue) {
      this.restoreSettings();
    }
  }
  onMenuChanged() {
    this.storeSettings();
    this.menuChanged.emit({
      shotGraphHeight: this.shotGraphHeight,
      plotRcvr: this.plotRcvr,
      minValZ: this.minValZ,
      maxValZ: this.maxValZ,
      selectedShotColourType: this.selectedShotColourType,
    });
  }
  updateDisplayingRcvr() {
    this.recieverIntervalChanged.emit(this.rcvrInterval);
  }

  setShowReceiver(show: boolean) {
    this.plotRcvr = !this.plotRcvr;
    this.onMenuChanged();
  }
  setColourType(colourType: any) {
    this.selectedShotColourType = colourType.id;
    this.onMenuChanged();
  }
  updateBgRange() {
    this.minValZ = null;
    this.maxValZ = null;
    this.onMenuChanged();
    // this.updateShotsBgImage();
  }
  private storeSettings() {
    let settings = this.storedSettingsService.getSettings<ShotSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Shot,
      this.jobDetail.projectId
    );

    this.storedSettingsService.setSettings<ShotSettings>(
      this.settingsKey,
      {
        ...settings,
        shotGraphHeight: this.shotGraphHeight,
        minValZ: this.minValZ,
        maxValZ: this.maxValZ,
      },
      SettingTypes.Shot
    );
  }
  private restoreSettings() {
    let settings = this.storedSettingsService.getSettings<ShotSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Shot,
      this.jobDetail.projectId
    );
    if (settings) {
      this.minValZ = settings.minValZ;
      this.maxValZ = settings.maxValZ;
      this.shotGraphHeight = settings.shotGraphHeight;
    }
  }
}
