import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapkey',
  pure: false
})
export class MapkeyPipe implements PipeTransform {

  transform(map: Map<any, any>): any {
    return Array.from(map.keys());
  }

}
