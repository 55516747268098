import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpService } from "../../shared/services/http.service";
import { AppConfig } from "../../app.config";
import { AuthService } from "./auth.service";
import { UserDetail } from "../../models/userDetail";
import { HttpHeaders } from "@angular/common/http";
import { map, retry } from "rxjs/operators";

export const RUNFILE_ACCESS_GROUPS = ["runfile_view"];
export const TRACEFIT_ACCESS_GROUPS = ["trace_fit_view"];

@Injectable()
export class UserService {
  constructor(private http: HttpService, private authService: AuthService) {}

  getUserDetails(): Observable<UserDetail> {
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/users/self/`,
        this.getAuthorizationHeader()
      )
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return;

          let data = response;
          // console.log(data)
          return {
            id: data.id,
            groupName: data.billing_group,
            isStaff: data.is_staff,
            email: data.email,
            runfileAccess: data.user_groups.some((group) => {
              return RUNFILE_ACCESS_GROUPS.includes(group);
            }),
            tracefitAccess: data.user_groups.some((group) => {
              return TRACEFIT_ACCESS_GROUPS.includes(group);
            }),
          };
        })
      );
  }

  getBillingGroupAdmin(groupName: string): Observable<string> {
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/billing_groups/${groupName}/`,
        this.getAuthorizationHeader()
      )
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return;

          let data = response;
          if (!data) return null;

          return data.billing_admin_user;
        })
      );
  }

  private getAuthorizationHeader(): { headers: HttpHeaders } {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );
    return { headers };
  }
}
