import { ShotSettingsService } from "./../../services/shot-settings.service";
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Observable, of, Subscription } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { JobDetail } from "../../../models/jobDetail";
import { ShotSettings } from "../../../models/shotSettings";
import { ShotType } from "../../../models/shotType";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { ImageLoaderService } from "../../../shared/services/image-loader.service";
import {
  SliceSettings,
  SliceSettingsService,
} from "../../services/slice-settings.service";
import { StoredSettingsService } from "../../services/stored-settings.service";
import { Panel } from "../shot-tabs/shot-tabs.component";

@Component({
  selector: "app-shot-container",
  templateUrl: "./shot-container.component.html",
  styleUrls: ["./shot-container.component.less"],
})
export class ShotContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobDetail: JobDetail;
  @Input() shotData: any;
  @Input() settingsKey: string;
  sliceSettings: SliceSettings;
  selectedShot: number = null;
  availableShotIds: number[] = [];
  mainGraphHeight: number = 500;
  hasRightPage: boolean = false;
  hasLeftPage: boolean = false;
  shotType: ShotType;
  isInterleaveType: boolean = false;
  interleaveExtent: number = 0;
  imageUrl: string;
  startTrace: number;
  endTrace: number;
  totalTime: number;
  shotImageError = null;
  loadingImage: boolean;
  clipValue: number;
  interleave_extent: number = 10;
  customName: any;
  numTraces: number;
  numSamples: number;
  maxNumTracesShown: number = 1000;
  iterationsOfSameShot: number[];
  switchIteration: number;
  selectedPanel: number = 0;
  currentScaling: number[] = [null, null];
  currentClipping = 0;
  globalClipping: boolean = true;
  customShotName = "1d";
  customShotSettings =
    '{ "filter_type": "raw", "noise": 0.01, "lag_max": 2000 }';
  rcvrIndices: number[];
  rcvrData: any;
  shotTypes: ShotType[] = this.sliceSettingsService.shotTypes;
  typeOfSelectedRcvr: ShotType;
  startTraceManual: number;
  endTraceManual: number;
  selectedBlk: any;
  private delayedLoading;
  private mainImageSubscriber: Subscription;
  private customNamesSubscriber: Subscription;
  private selectedShotInfoSubscriber: Subscription;
  private settingsSubscription: Subscription;
  isValidSelectedShot: boolean;

  constructor(
    private route: ActivatedRoute,

    private sliceSettingsService: SliceSettingsService,
    private projectService: CachedProjectService,
    private imageService: ImageLoaderService,
    private storedSettingsService: StoredSettingsService,
    private shotSettingsService: ShotSettingsService
  ) {}

  ngOnInit() {
    this.shotType = this.shotTypes[0];
    this.loadPage(this.route.snapshot.queryParamMap);
    this.settingsSubscription = this.storedSettingsService.settingsUpdated$
      .pipe(
        filter((settingTypes) =>
          settingTypes.some(
            (r) =>
              [SettingTypes.Shot, SettingTypes.SliceSettings].indexOf(r) >= 0
          )
        )
      )
      .subscribe(() => {
        this.loadPage(this.route.snapshot.queryParamMap);
      });
    this.updateShot();
  }
  ngOnChanges(changes: SimpleChanges) {
    const change = changes.jobDetail;
    if (change.currentValue && !change.isFirstChange()) {
      this.loadPage(this.route.snapshot.queryParamMap);
    }
  }
  loadPage(params: ParamMap) {
    this.restoreSettings();
    if (this.selectedShot > 0) {
      this.updateShot();
    }
  }
  updateShot() {
    this.isValidSelectedShot = false;
    if (this.shotType.name == "RcvrList")
      this.typeOfSelectedRcvr = this.shotType;
    this.projectService
      .getTraceFilesByIteration(this.jobDetail.id, this.sliceSettings.iteration)
      .subscribe((traceFiles) => {
        this.shotData = traceFiles;
        let shot = this.shotData.find((s) => s == this.selectedShot);
        if (shot) {
          this.isValidSelectedShot = true;
          this.updateSelectedShotInfo();
        }
      });
  }
  onTabChanged(panel: Panel) {
    this.checkIsInterleaved(panel.name);
    this.selectedPanel = panel.index;
    //TODO: update shot
    // this.updateMainImage();
    this.updateShot();
  }
  onMenuChanged() {
    this.restoreSettings();
    this.updateShot();
  }
  onIterationChanged(switchIteration: number) {
    this.switchIteration = switchIteration;
  }
  onShotContainerMenuChanged(shotId: number) {}
  checkIsInterleaved(panelName: string) {
    this.isInterleaveType = panelName == "Interleaved";
  }

  private updateMainImage() {
    let shotType = this.shotType;
    this.shotImageError = null;

    clearTimeout(this.delayedLoading);

    // TODO: re-add
    // if (shotType.name == "RcvrList") {
    //   if (this.typeOfSelectedRcvr.name == "RcvrList") {
    //     if (!this.rcvrDataFull)
    //       this._getRcvrData();
    //     return
    //   } else
    //     shotType = this.typeOfSelectedRcvr
    // }

    this.delayedLoading = setTimeout(() => (this.loadingImage = true), 250);
    this.mainImageSubscriber = this.getImageUrl(
      shotType,
      this.shotType.name == "RcvrList"
    ).subscribe(
      (res) => {
        if (!res) {
          this.loadingImage = false;
          this.imageUrl = null;
          return;
        }
        clearTimeout(this.delayedLoading);
        this.imageUrl = "data:image/png;base64," + res.imageStr;
        this.clipValue = res.clipValue;
        // TODO: add back
        // this.updateDisplayingRcvr();
        this.checkPagination();
        this.loadingImage = false;
      },
      (error) => {
        if (error.status != 404) {
          this.shotImageError = error.detail || error;
        }
        this.imageUrl = null;
        clearTimeout(this.delayedLoading);
        this.loadingImage = false;
      }
    );
  }

  private updateSelectedShotInfo() {
    // TODO: add back
    // this._getRcvrData();
    if (this.sliceSettings.iteration == 0 || !this.selectedShot) {
      return;
    }
    this.loadingImage = true;
    if (this.shotType.name == "custom_type" && !this.shotType.options) {
      this.loadingImage = false;
      this.customNamesSubscriber = this.projectService
        .getAvailableCustomNames(
          this.jobDetail.id,
          this.sliceSettings.iteration,
          this.selectedShot
        )
        .subscribe((names) => {
          this.shotType.options = names;
        });
      if (!this.customName) return;
    }

    let type =
      this.shotType.name == "RcvrList" ? "compare" : this.shotType.ttrName;
    let data = { type: type };
    Object.assign(
      data,
      this.sliceSettings.iteration && {
        iteration: this.sliceSettings.iteration,
      },
      this.selectedShot && { shot_id: this.selectedShot },
      this.shotType.fwd && { fwd: this.shotType.fwd },
      this.customName && { custom_name: this.customName },
      this.shotType.numPanel && { num_panel: this.shotType.numPanel }
    );

    this.selectedShotInfoSubscriber = this.projectService
      .getTraceMeta(this.jobDetail.id, data)
      .pipe(
        switchMap((traceMeta) => {
          if (!traceMeta) {
            return of(null);
          }
          this.numTraces = traceMeta.num_trace;
          this.numSamples = traceMeta.num_sample;
          this.totalTime = traceMeta.total_time;

          if (this.endTraceManual) {
            this.endTrace = Math.min(this.endTraceManual, this.numTraces);
          } else {
            this.endTrace = this.numTraces;
          }
          if (this.startTraceManual) {
            this.startTrace =
              this.startTraceManual <= this.numTraces
                ? this.startTraceManual
                : 1;
          } else {
            this.startTrace = Math.max(
              (this.endTrace || 1) - this.maxNumTracesShown,
              1
            );
          }

          this.shotSettingsService.setShotSettings({
            startTrace: this.startTrace,
            endTrace: this.endTrace,
            startTraceManual: this.startTraceManual,
            endTraceManual: this.endTraceManual,
            panel: this.selectedPanel,
          });

          let data = {
            type: this.shotType.ttrName,
            shot_id: this.selectedShot,
            custom_name: this.customName,
            fwd: this.shotType.fwd,
          };
          return this.projectService.getTraceFilesById(this.jobDetail.id, data);
        }),
        switchMap((files) => {
          if (!files) {
            return of(null);
          }
          files.sort((a, b) => a - b);
          this.iterationsOfSameShot = files;
          if (
            this.sliceSettings.iteration == this.switchIteration ||
            !this.iterationsOfSameShot.includes(this.switchIteration)
          ) {
            this.switchIteration = null;
          }
          if (this.shotType.name == "RcvrList") return of(null);
          clearTimeout(this.delayedLoading);
          this.delayedLoading = setTimeout(
            () => (this.loadingImage = true),
            300
          );
          return this.getImageUrl(
            this.shotType,
            this.shotType.name == "RcvrList"
          );
        })
      )
      .subscribe(
        (res) => {
          clearTimeout(this.delayedLoading);
          this.updateMainImage();

          this.loadingImage = false;
          if (res) {
            this.imageUrl = "data:image/png;base64," + res.imageStr;
            this.clipValue = res.clipValue;
            this.checkPagination();
          } else {
            this.loadingImage = false;
            this.imageUrl = null;
          }
        },
        (error) => {
          clearTimeout(this.delayedLoading);
          this.loadingImage = false;
          this.imageUrl = null;
          console.log(error);
        }
      );
  }
  private getImageUrl(shotType, useIdx = false): Observable<any> {
    if (
      !(
        this.sliceSettings.iteration &&
        this.selectedShot &&
        this.jobDetail.id
      ) ||
      this.startTrace >= this.endTrace
    )
      return of(null);

    let startTrace = Math.max(
      Math.floor((this.numTraces - this.maxNumTracesShown) / 2),
      0
    );
    let endTrace = Math.min(
      this.numTraces,
      this.startTrace + this.maxNumTracesShown
    );

    let parameters = {
      type: shotType.ttrName,
      panel: this.selectedPanel,
      scaling: this.currentScaling,
      clipping: Math.pow(10, this.currentClipping),
      global_clip: this.globalClipping,
      num_panel: shotType.numPanel,
      filter_name:
        shotType.name == "custom" ? this.customShotName : shotType.filterName,
      filter_settings:
        shotType.name == "custom"
          ? JSON.parse(this.customShotSettings || "{}")
          : shotType.filterSettings,
      interleave_attrs: {
        is_interleave: this.isInterleaveType ? 1 : 0,
        extent: this.interleave_extent,
      },
    };

    if (useIdx) {
      let stdSrcLoc = this.shotData.find((s) => s.shotId == this.selectedShot);
      let sortedIndices = this.rcvrData
        .map((r, i) => {
          let distX = r.x - stdSrcLoc.x;
          let distY = r.y - stdSrcLoc.y;
          let dist = distX * Math.abs(distX) + distY * Math.abs(distY);
          return { dist: dist, idx: this.rcvrIndices[i] };
        })
        .filter((s) => s.idx != undefined);
      sortedIndices.sort((a, b) => a.dist - b.dist);
      parameters["indices"] = sortedIndices.map((s) => s.idx);
    } else {
      parameters["start_trace"] = this.startTrace || startTrace;
      parameters["end_trace"] = this.endTrace || endTrace;
    }

    if (parameters.filter_settings) {
      parameters.filter_settings.clipping = this.currentClipping;
    }

    Object.assign(
      parameters,
      this.sliceSettings.iteration && {
        iteration: this.sliceSettings.iteration,
      },
      this.selectedShot && { shot_id: this.selectedShot },
      this.shotType.fwd && { fwd: this.shotType.fwd },
      this.customName && { custom_name: this.customName }
    );

    return this.imageService.getTraceImageAndClipValue(
      this.jobDetail.id,
      parameters
    );
  }
  onSliceChange(next: boolean) {
    let range = this.endTrace - this.startTrace;
    if (next) {
      this.endTrace = Math.min(this.numTraces, this.endTrace + range);
      this.startTrace = this.endTrace - range;
    } else {
      this.startTrace = Math.max(1, this.startTrace - range);
      this.endTrace = this.startTrace + range;
    }
    this.updateMainImage();
  }

  private checkPagination() {
    this.hasRightPage = this.endTrace < this.numTraces;
    this.hasLeftPage = this.startTrace > 1;
  }

  showChart() {
    return (
      this.selectedShot &&
      this.sliceSettings &&
      this.sliceSettings.iteration > 0 &&
      this.isValidSelectedShot
    );
  }
  hasShotData() {
    if (!this.shotData) return false;
    return this.shotData.length > 0;
  }
  interleaveChanged(interleaveExtent: number) {
    this.interleave_extent = interleaveExtent;
    this.updateShot();
  }
  private restoreSettings() {
    let lastSettings = this.storedSettingsService.getSettings<ShotSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Shot,
      this.jobDetail.projectId
    );
    var storedSliceSettings =
      this.storedSettingsService.getSettings<SliceSettings>(
        this.settingsKey,
        this.jobDetail.id,
        SettingTypes.SliceSettings,
        this.jobDetail.projectId
      );
    this.sliceSettings = this.sliceSettingsService.setDefaults(
      storedSliceSettings,
      this.jobDetail
    );
    if (!lastSettings || !lastSettings.shotType) {
      return false;
    }
    this.mainGraphHeight = lastSettings.mainShotGraphHeight || 500;
    this.selectedShot = lastSettings.selectedShot;
    this.availableShotIds = lastSettings.availableShotIds;
    this.selectedBlk = lastSettings.block;
    this.shotType = lastSettings.shotType;
    this.startTrace = lastSettings.startTrace;
    this.endTrace = lastSettings.endTrace;
    this.startTraceManual = lastSettings.startTraceManual;
    this.endTraceManual = lastSettings.endTraceManual;
    this.currentClipping = lastSettings.clipping || 0;
    this.currentScaling = lastSettings.scaling || [null, null];
    if (
      this.shotType.name == "RcvrList" &&
      lastSettings.typeOfSelectedRcvr.name != "RcvrList"
    ) {
      this.rcvrIndices = lastSettings.rcvrIdx;
      this.typeOfSelectedRcvr = lastSettings.typeOfSelectedRcvr;
    }
    return true;
  }
  ngOnDestroy(): void {
    if (this.mainImageSubscriber) {
      this.mainImageSubscriber.unsubscribe();
    }
    if (this.customNamesSubscriber) this.customNamesSubscriber.unsubscribe();
    if (this.selectedShotInfoSubscriber) {
      this.selectedShotInfoSubscriber.unsubscribe();
    }
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }
}
