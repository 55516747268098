import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { JobDialog } from '../../../../models/jobDialog';
import { Project } from '../../../../models/project';
import { ProjectService } from '../../../../shared/services/project.service';


@Component({
  selector: 'app-job-name',
  templateUrl: './job-name.component.html',
  styleUrls: ['./job-name.component.less']
})
export class JobNameComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) form;
  projects: Project[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: JobDialog, public dialogRef: MatDialogRef<JobNameComponent>, private projectService: ProjectService) { }

  ngOnInit() {
    this.projectService.getProjects().subscribe(p => {
      this.projects = p;
    });
  }

  submitForm() {
    if (!this.form.valid)
      return;
    
    this.dialogRef.close(this.data);
  }
}
