import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CachedProjectService } from '../../shared/services/cached-project.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.css']
})
export class ProjectViewComponent implements OnInit {

  project_id: string = '';
  project_name: string = '';
  project_description: string = '';

  constructor(
    private route: ActivatedRoute, 
    private projectService: CachedProjectService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.projectService.getProjectById(params.id).toPromise().then(data => {
          this.project_id = data['id'];
          this.project_name = data['name'];
          this.project_description = data['description'];
          this.projectService.setCurrentProject(data);
          this.titleService.setTitle(this.project_name);
      })
      }
    });
  }

}
