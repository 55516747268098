<h3 mat-dialog-title>Model Display Settings</h3>

<form class="form-container" (ngSubmit)="submitForm()">
  <mat-dialog-content>
    <mat-slide-toggle
      class="slider"
      [(ngModel)]="data.auto"
      (change)="data.syncRange = data.syncRange ? !data.auto : data.syncRange"
      name="auto"
    >
      Auto
    </mat-slide-toggle>
    <mat-slide-toggle
      *ngIf="data.isScaling"
      class="slider"
      [(ngModel)]="data.global"
      name="global"
    >
      Global Scaling
    </mat-slide-toggle>

    <ng-container *ngIf="data.slice_processing">
      <ng-container
        *ngIf="
          !data.slice_processing.includes('_zero_centred_params_process');
          else singleOffsetField
        "
      >
        <div style="position: relative" *ngIf="!data.singleValue">
          <nz-input-group
            class="nz-input-group-floating-label"
            [nzSize]="'large'"
            [style.width.%]="100"
          >
            <label>Minimum Value</label>
            <nz-input-number
              style="width: 100%"
              [nzPrecision]="2"
              [disabled]="data.auto"
              placeholder="Minimum Value"
              name="min"
              [(ngModel)]="data.min"
              #minField
            />
          </nz-input-group>
          <div
            *ngIf="this.data.auto"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            (click)="clickInput(minField)"
          ></div>
        </div>
        <div *ngIf="!data.singleValue" style="position: relative">
          <nz-input-group
            class="nz-input-group-floating-label"
            [nzSize]="'large'"
            [style.width.%]="100"
          >
            <label>Maximum Value</label>
            <nz-input-number
              style="width: 100%"
              [nzPrecision]="2"
              [disabled]="data.auto"
              placeholder="Maximum Value"
              [(ngModel)]="data.max"
              name="max"
              #maxField
            />
          </nz-input-group>
          <div
            *ngIf="this.data.auto"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            (click)="clickInput(maxField)"
          ></div>
        </div>

        <div *ngIf="data.singleValue" style="position: relative">
          <nz-input-group
            class="nz-input-group-floating-label"
            [nzSize]="'large'"
            [style.width.%]="100"
          >
            <label>Clip Value</label>
            <input
              nz-input
              [disabled]="data.auto"
              placeholder="Clip Value"
              required
              type="number"
              [(ngModel)]="data.offset"
              name="clipValue"
              #clip
            />
          </nz-input-group>
          <div
            *ngIf="this.data.auto"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            (click)="clickInput(clip)"
          ></div>
        </div>
      </ng-container>
      <ng-template #singleOffsetField>
        <div *ngIf="!data.singleValue" style="position: relative">
          <nz-input-group
            class="nz-input-group-floating-label"
            [nzSize]="'large'"
            [style.width.%]="100"
          >
            <label>Zero centerd value</label>
            <input
              nz-input
              [disabled]="data.auto"
              placeholder="Zero centerd value"
              required
              type="number"
              [(ngModel)]="center_offset_value"
              name="center_offset_value"
              #offset
            />
          </nz-input-group>
          <div
            *ngIf="this.data.auto"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            (click)="clickInput(offset)"
          ></div>
        </div>
        <div *ngIf="data.singleValue" style="position: relative">
          <nz-input-group
            class="nz-input-group-floating-label"
            [nzSize]="'large'"
            [style.width.%]="100"
          >
            <label>Clip Value</label>
            <input
              nz-input
              [disabled]="data.auto"
              placeholder="Clip Value"
              required
              type="number"
              [(ngModel)]="data.offset"
              name="clipValue"
              #clip
            />
          </nz-input-group>
          <div
            *ngIf="this.data.auto"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            (click)="clickInput(clip)"
          ></div>
        </div>
      </ng-template>
    </ng-container>

    <div *ngIf="hasError && !data.singleValue" class="error-text">
      Please ensure you enter value minimum and maximum values
    </div>
    <div *ngIf="hasError && data.singleValue" class="error-text">
      Please ensure you enter valid clip value
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="button-container">
    <button mat-raised-button color="primary" type="submit">Update</button>
    <button mat-raised-button mat-dialog-close color="basic" type="reset">
      Cancel
    </button>
  </mat-dialog-actions>
</form>
