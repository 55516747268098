export const fields = {
  model_info: [
    "parent_job_name",
    "cp_num",
    "comments",
    "origin_job_name",
    "origin_cp",
    "user_modded",
  ],
  density_params_info: [
    "density_ramps",
    "min_gardner_vel",
    "max_gardner_vel",
    "salt_velocity",
    "salt_density",
    "water_mincells",
    "water_maxcells",
    "water_velocity",
    "water_density",
    "gardner_factor",
    "gardner_power",
    "fixed_density"
  ],
};
