<h3 mat-dialog-title>Save Result</h3>

<form class="form-container" (ngSubmit)="submitForm()">
  <mat-dialog-content>
    <mat-form-field>
      <input matInput placeholder="Result Group Name" required [(ngModel)]="data.name" name="name">
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="Result Group Description" rows="3" [(ngModel)]="data.description" name="description"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="button-container">
    <button mat-raised-button color="primary" type="submit">Save</button>
    <button mat-raised-button mat-dialog-close color="basic" type="reset">Cancel</button>
  </mat-dialog-actions>
</form>

