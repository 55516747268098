<div class="container-fluid">
  <div class="panel white-panel">
    <div class="col-md-12">
      <h4 class="card-title">Settings</h4>
      <div class="row">
        <div class="sliders form-horizontal">
          <div>
            <div class="form-group">
              <label for="jobSelect" class="col-xs-2 text-right">Select Jobs</label>
              <mat-form-field class="col-xs-10">
                <mat-label>Select Jobs</mat-label>
                <mat-select [(ngModel)]="selectedJobIds" multiple>
                  <mat-option (click)="toggleSelectAll()">Select All</mat-option>
                  <mat-option *ngFor="let job of jobList" [value]="job.id">
                    {{ job.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label for="colormapSelect" class="col-xs-2 text-right">Select ColorMapScheme</label>
              <mat-form-field class="col-xs-10">
                <mat-label>Select ColorMapScheme</mat-label>
                  <mat-select [(ngModel)]="palette">
                    <mat-option *ngFor="let palette of palettes" [value]="palette">
                      {{ palette }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
              <div class="col-xs-2 text-right"><label>Inline</label></div>
              <div class="col-xs-10">
                <input type="number" class="form-control" [(ngModel)]="inlineNumber" />
              </div>
            </div>
            <div class="form-group">
              <div class="col-xs-2 text-right"><label>Crossline</label></div>
              <div class="col-xs-10">
                <input type="number" class="form-control" [(ngModel)]="crosslineNumber" />
              </div>
            </div>
            <div class="form-group">
              <div class="col-xs-2 text-right"><label>Depth Slice</label></div>
              <div class="col-xs-10">
                <input type="number" class="form-control" [(ngModel)]="depthSlice" />
              </div>
            </div>
            <div class="form-group iteration-slider">
              <div class="col-xs-2 text-right"><label>Iteration</label></div>
              <div class="col-xs-10">
                <input class="form-control" [(ngModel)]="iterationsResponse" placeholder="Enter numbers separated by commas, e.g., 1, 2" />
              </div>
            </div>
            <div class="form-group">
              <div class="col-xs-2 text-right"><label>Min. Velocity</label></div>
              <div class="col-xs-10">
                <input type="number" class="form-control" [(ngModel)]="minVal" placeholder="e.g., 1000" required/>
              </div>
            </div>
            <div class="form-group">
              <div class="col-xs-2 text-right"><label>Max. Velocity</label></div>
              <div class="col-xs-10">
                <input type="number" class="form-control" [(ngModel)]="maxVal" placeholder="e.g., 5000" required/>
              </div>
            </div>
            <div class="form-group">
              <div class="col-xs-2 text-right"><label>Min. Depth Velocity</label></div>
              <div class="col-xs-10">
                <input type="number" class="form-control" [(ngModel)]="minValZ" placeholder="e.g., 1000" required/>
              </div>
            </div>
            <div class="form-group">
              <div class="col-xs-2 text-right"><label>Max. Depth Velocity</label></div>
              <div class="col-xs-10">
                <input type="number" class="form-control" [(ngModel)]="maxValZ" placeholder="e.g., 5000" required/>
              </div>
            </div>
            <div class="form-group">
              <div class="col-xs-2 text-right"><label>PNG height</label></div>
              <div class="col-xs-10">
                <input type="number" class="form-control" [(ngModel)]="pngHeight" />
              </div>
            </div>
            <!-- <div class="form-group">
              <div class="col-xs-2 text-right">
                <label>Vertical Derivative</label>
              </div>
              <div class="col-xs-10">
                <mat-checkbox [checked]="vpUpdateCheck" (change)="toggleVpUpdateCheck()">
                </mat-checkbox>
                <input *ngIf="vpUpdateCheck" type="number" [(ngModel)]="vpDiffIteration" class="form-control form-group-small" placeholder="vpDiffIteration"/>
              </div>
            </div> -->
            <!-- <div class="form-group">
              <div class="col-xs-2 text-right"><label>Download Raw Images</label></div>
              <div class="col-xs-10">
                <mat-checkbox [checked]="downloadRawImages" [(ngModel)]="downloadRawImages"></mat-checkbox>
              </div>
            </div> -->
          </div>
      </div>
        <button mat-stroked-button class="download-btn" (click)="generateImages()">Download</button>
      </div>
    </div>
  </div>
  <div class="slices col-lg-12">
  </div>
</div>
