import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription, Observable, forkJoin, of } from "rxjs";
import { startWith, map, switchMap, finalize } from "rxjs/operators";
import { CachedProjectService } from "../../shared/services/cached-project.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { UntypedFormControl } from "@angular/forms";
// import * as zlib from "zlib";
// import * as zlib from "browserify-zlib";
import * as yaml from "js-yaml";
import { Router } from "@angular/router";
import { inflate } from "pako";
import { Buffer } from "buffer";
// declare const Buffer;

@Component({
  selector: "app-scores",
  templateUrl: "./scores.component.html",
  styleUrls: ["./scores.component.less"],
})
export class ScoresComponent implements OnInit, OnDestroy {
  grouping = "avg";
  iterations = [];
  selectedIteration = null;
  selectedShot = null;
  availableShots = new Map();
  minShot = null;
  maxShot = null;
  shotStep = null;
  dataFull = null;
  minIteration = null;
  maxIteration = null;
  selectedProjectId = null;
  projects = null;
  settingOptions = null;
  selectedSettings = [];
  selectedOption = null;
  settings = {};
  scoreHeaders = [];
  selectedScore = "project";
  additionalSetting = {};
  jobsLoaded = [];
  jobsFailed = [];
  qcJob = null;
  jobsLoadedText = null;
  jobsFailedText = null;
  isLoading = true;
  selectedJobs = [];

  projectControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<any[]>;

  scores = null;
  private projectSubscription: Subscription = null;
  private scoresSubscription: Subscription = null;

  constructor(
    private projectService: CachedProjectService,
    private router: Router
  ) {}

  ngOnInit() {
    this.filteredOptions = this.projectControl.valueChanges.pipe(
      startWith(""),
      map((val) => this.filter(val))
    );

    this.projectSubscription = this.projectService
      .getProjects()
      .subscribe((p) => {
        this.projects = p;
        this.isLoading = false;
      });
    /*  this.scoresSubscription = this.scoresService.getScores().subscribe(d => {
        this.scores = d;
        this.updateDataSource();
        this.scoresSubscription = null;
      });*/
  }

  ngOnDestroy() {
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
      this.projectSubscription = null;
    }
    if (this.scoresSubscription) {
      this.scoresSubscription.unsubscribe();
      this.scoresSubscription = null;
    }
  }

  filter(val: string): any[] {
    let parts = val.split(" ").filter((s) => !!s);

    return this.projects.filter((option) => {
      return !parts.find(
        (p) => option.name.toLowerCase().indexOf(p.toLowerCase()) === -1
      );
    });
  }

  trim_name(str_val: string) {
    return str_val.slice(0, 6);
  }

  projectSelected(event: MatAutocompleteSelectedEvent) {
    let project = this.projects.find((p) => p.name == event.option.value);
    if (!project) return;

    this.selectedProjectId = project.id;
    this.updateProject();
  }

  updateProject() {
    if (!this.selectedProjectId) return;

    let project = this.projects.find((p) => p.id == this.selectedProjectId);
    if (!project) return;

    this.isLoading = true;

    if (this.scoresSubscription) {
      this.scoresSubscription.unsubscribe();
      this.scoresSubscription = null;
    }
    this.scoresSubscription = new Subscription();

    this.scores = [];
    this.additionalSetting = {};
    this.jobsLoaded = [];
    this.jobsFailed = [];
    this.jobsFailedText = null;
    this.jobsLoadedText = null;
    this.selectedSettings = [];
    this.scoreHeaders = [];
    this.settings = [];
    this.settingOptions = null;

    let count = 0;
    project.jobs.forEach((j) => {
      let self = null;
      this.scoresSubscription.add(
        this.projectService
          .getPlotData(j.id)
          .pipe(
            switchMap((d) => {
              if (!d) return of(null);
              if (d) {
                d.jobId = j.id;
                d.jobName = j.name;
                d.runfile = {};
                self = d;
              }
              return forkJoin(
                ...d.cpnums.map((i) =>
                  this.projectService.getShotOverlay(j.id, i)
                )
              );
            }),
            finalize(() => {
              if (++count == project.jobs.length)
                setTimeout(() => this.updateDataSource(), 500);
            }),
            switchMap((overlays) => {
              if (!overlays) return of(null);
              let losses = [];
              overlays.forEach((overlay, i) => {
                if (!overlay) return;
                let otherShots = this.availableShots.get(self.cpnums[i]) || [];
                let totalShots = otherShots
                  .concat(overlay.map((o) => o.shot_id))
                  .filter((v, i, self) => self.indexOf(v) == i);
                this.availableShots.set(self.cpnums[i], totalShots);
                this._overlayToScore(
                  losses,
                  overlay,
                  self.cpnums[i] - 1,
                  self.cpnums.length
                );
              });
              let fnlc = (losses.find((l) => l.name == "fnlc") || {}).data;
              if (fnlc && fnlc.length) {
                let scaleRatio = Math.max(...fnlc[0]) / 100.0;
                let shotFunctional = fnlc.map((iter) =>
                  iter.map((shot) => Number((shot / scaleRatio).toFixed(2)))
                );
                losses.push({
                  data: shotFunctional,
                  std: [],
                  name: "shot functional",
                });
              }
              self.shots_fits = losses;
              let currentShots = this.availableShots.get(
                Math.max(...self.cpnums)
              );
              this.minShot = Math.min(...currentShots);
              this.maxShot = Math.max(...currentShots);
              this.shotStep =
                currentShots.length > 1 ? currentShots[1] - currentShots[0] : 0;
              return this.getAllParameters(j.id);
            })
          )
          .subscribe(
            (parameters) => {
              if (!parameters) return;
              this.jobsLoaded.push(j.name);
              self.runfile = parameters;
              this.scores.push(self);
            },
            (error) => {
              console.log(error);
            }
          )
      );
    });
  }

  settingSelected(event) {
    if (!this.selectedSettings.find((s) => s == this.selectedOption)) {
      this.selectedSettings.push(this.selectedOption);
      this.additionalSetting[this.selectedOption] = [];
    }
    setTimeout(() => {
      this.selectedOption = null;
      this.updateDataSource();
    }, 0);
  }

  deleteSetting(setting: string) {
    let index = this.selectedSettings.indexOf(setting);
    if (index > -1) {
      this.selectedSettings.splice(index, 1);
    }

    delete this.additionalSetting[setting];

    this.updateDataSource();
  }

  async updateDataSource() {
    this.isLoading = false;
    this.selectedJobs = [];
    this.jobsLoadedText = this.jobsLoaded
      .map((j_name) => this.trim_name(j_name))
      .join(", ");
    let project = this.projects.find((p) => p.id == this.selectedProjectId);
    this.jobsFailed = project.jobs
      .map((j) => j.name)
      .filter((j) => !this.jobsLoaded.find((l) => l == j));
    this.jobsFailedText = this.jobsFailed
      .map((j_name) => this.trim_name(j_name))
      .join(", ");

    if (!this.scores || this.scores.length < project.jobs.length)
      await new Promise((r) => setTimeout(r, 250));

    if (!this.scores || this.scores.length == 0) {
      this.dataFull = null;
      return;
    }

    let iters = [].concat(...this.scores.map((s) => s.cpnums));

    this.minIteration = Math.min(...iters);
    this.maxIteration = Math.max(...iters);
    this.iterations = this.createRange(this.minIteration, this.maxIteration);
    this.scoreHeaders = ["fnl1", "stepfct"];
    this.settings = {};
    this.settingOptions = [];

    if (
      !this.selectedIteration ||
      this.selectedIteration < this.minIteration ||
      this.selectedIteration > this.maxIteration
    )
      this.selectedIteration = this.maxIteration;

    let shots = this.availableShots.get(this.selectedIteration);
    this.minShot = Math.min(...shots);
    this.maxShot = Math.max(...shots);
    this.shotStep = shots.length > 1 ? shots[1] - shots[0] : 0;
    if (
      !this.selectedShot ||
      this.selectedShot < this.minShot ||
      this.selectedShot > this.maxShot
    )
      this.selectedShot = this.minShot;

    let scoresOfCurrentIteration = this.scores.filter((s) =>
      s.cpnums.includes(this.selectedIteration)
    );

    let scoresOfCurrentSetting = scoresOfCurrentIteration.filter((option) =>
      this.selectedSettings.every((s) => {
        let value = (option.runfile.global[s] || {}).value;
        value =
          value !== null && value !== undefined
            ? value.toString().toLowerCase()
            : null;
        let settingValues = this.additionalSetting[s].map((v) =>
          v.toString().toLowerCase()
        );
        return settingValues.length === 0 || settingValues.includes(value);
      })
    );

    let initParams = {};
    for (let score of scoresOfCurrentSetting)
      Object.assign(initParams, score.runfile.global);

    scoresOfCurrentSetting.reduce((a, c) => {
      for (let [k, v] of Object.entries(a || {})) {
        let ref = c.runfile.global[k]
          ? c.runfile.global[k].value.toString().toLowerCase()
          : null;
        let value = v ? (v as any).value.toString().toLowerCase() : null;
        if (ref !== value) {
          this.settingOptions.push(k);
          delete a[k];
        }
      }
      return a;
    }, initParams);

    this.settingOptions = this.settingOptions.sort();
    let data = scoresOfCurrentIteration.map((p, idx) => {
      let project = {
        id: idx,
        jobId: p.jobId,
        name: p.jobName,
        projectPath: "",
        settings: {},
        iteration: this.selectedIteration,
        projectScores: {},
      };

      project.projectScores["project"] = this.trim_name(p.jobName);
      project.projectScores["fnl1"] =
        p.fnl1.data[this.selectedIteration - this.minIteration];
      project.projectScores["stepfct"] =
        p.stepfct.data[this.selectedIteration - this.minIteration];

      let detailedScores =
        this.grouping == "shots" ? p.shots_fits : p.traces_fits;

      if (detailedScores) {
        detailedScores.forEach((t) => {
          if (!this.scoreHeaders.find((h) => h == t.name))
            this.scoreHeaders.push(t.name);

          if (this.grouping == "std")
            project.projectScores[t.name] =
              t.std[this.selectedIteration - this.minIteration];
          else {
            let scores = t.data[this.selectedIteration - this.minIteration];
            let shotIdx = this.availableShots
              .get(this.selectedIteration)
              .findIndex((s) => s == this.selectedShot);
            project.projectScores[t.name] =
              scores && scores.constructor === Array ? scores[shotIdx] : scores;
          }
        });
      }

      this.selectedSettings.forEach((s) => {
        project.settings[s] = p.runfile.global[s]
          ? p.runfile.global[s].value
          : "default";
        if (project.settings[s] === true) project.settings[s] = "true";
        else if (project.settings[s] === false) project.settings[s] = "false";

        if (!this.settings[s]) this.settings[s] = [];

        if (this.settings[s].indexOf(project.settings[s]) == -1) {
          this.settings[s].push(project.settings[s]);
        }

        this.settings[s] = this.settings[s].sort((a, b) => {
          if (a == +a && b == +b) return a - b;
          return a.toString().localeCompare(b.toString());
        });
      });

      if (!this.additionalSetting) this.selectedJobs.push(project.jobId);
      else {
        let found = true;
        for (var setting in this.additionalSetting) {
          if (
            !this.additionalSetting[setting] ||
            this.additionalSetting[setting].length == 0
          ) {
            continue;
          }

          found = this.additionalSetting[setting].find(
            (s) => project.settings[setting] == s
          );
          if (!found) break;
        }
        if (found) this.selectedJobs.push(project.jobId);
      }

      return project;
    });

    let selectedProject = this.projects.find(
      (p) => p.id == this.selectedProjectId
    );
    if (selectedProject) {
      let qcJobs = selectedProject.jobs.filter(
        (j) => j.name.indexOf("-QC") >= 0
      );
      if (qcJobs && qcJobs.length > 0) {
        let jobs = qcJobs.filter(
          (q) => !this.selectedJobs.find((j) => j == q.id)
        );
        if (jobs && jobs.length > 0)
          this.selectedJobs.push(...jobs.map((j) => j.id));
      }
    }
    this.dataFull = data;
  }

  getScores(scoreOne, scoreTwo) {
    if (!this.dataFull) {
      return null;
    }
    let values = this.dataFull.filter(
      (p) =>
        p.settings[this.selectedSettings[0]] == scoreOne &&
        p.settings[this.selectedSettings[1]] == scoreTwo
    );
    for (var setting in this.additionalSetting) {
      if (
        !this.additionalSetting[setting] ||
        this.additionalSetting[setting].length == 0
      ) {
        continue;
      }
      values = values.filter((p) =>
        this.additionalSetting[setting].includes(p.settings[setting])
      );
    }
    if (!values) return null;

    return values.map((v) => {
      return {
        jobId: v.jobId,
        name: v.name,
        score: v.projectScores[this.selectedScore],
      };
    });
  }

  getScoreSingle(scoreOne) {
    if (!this.dataFull) {
      return [];
    }
    let values = this.dataFull.filter(
      (p) => p.settings[this.selectedSettings[0]] == scoreOne
    );
    for (var setting in this.additionalSetting) {
      if (
        !this.additionalSetting[setting] ||
        this.additionalSetting[setting].length == 0
      ) {
        continue;
      }
      values = values.filter((p) =>
        this.additionalSetting[setting].includes(p.settings[setting])
      );
    }
    if (!values) return [];

    return values.map((v) => {
      return {
        jobId: v.jobId,
        name: v.name,
        score: v.projectScores[this.selectedScore],
      };
    });
  }

  isMinimumValue(score, val) {
    if (!this.dataFull) return false;
    return (
      this.dataFull
        .map((d) => d.projectScores[score])
        .filter((d) => d != null && d != "")
        .sort((a, b) => a - b)[0] == val
    );
  }

  createRange(min, max) {
    let range = [];
    for (var i = min; i <= max; i++) {
      range.push(i);
    }
    return range;
  }

  getPercentDrop(project) {
    let fullScore = this.scores.find((s) => s.jobId == project.jobId);
    if (!fullScore || typeof project.score != "number") return "";
    let detailedScores =
      this.grouping == "shots" ? fullScore.shots_fits : fullScore.traces_fits;
    let score =
      fullScore[this.selectedScore] ||
      detailedScores.find((t) => t.name == this.selectedScore);
    let initialValue = ((score || {}).data || [])[0];
    if (this.grouping == "shots")
      initialValue =
        initialValue[
          this.availableShots
            .get(this.selectedIteration)
            .findIndex((s) => s == this.selectedShot)
        ];
    if (initialValue === null) return "";
    let drop = (
      (Math.abs(initialValue - project.score) / initialValue) *
      100
    ).toFixed(2);
    return `[${drop}%]`;
  }

  private getAllParameters(id) {
    return Observable.create((observer) => {
      forkJoin([
        this.projectService.getRunfileParameters(id),
        this.projectService.getJobMeta(id),
        this.projectService.getJobDetail(id),
      ]).subscribe((d) => {
        if (!d[0]) {
          observer.complete();
        }
        if (d[0] && d[0].global) {
          for (var key in d[0].global) {
            if (key.toLowerCase().includes(" path")) delete d[0].global[key];
          }
        }

        if (!d[1]) {
          observer.next(d[0]);
          observer.complete();
        }

        if (d[0].iterBlks && d[0].iterBlks.length > 0) {
          for (var key in d[0].iterBlks[0]) {
            d[0].global["BLK1 - " + key] = d[0].iterBlks[0][key];
          }
        }

        let keywords = ["dhr", "wden"];

        for (key of keywords) {
          var re = new RegExp(key + "(\\d+)");
          var m = d[2].name.toLowerCase().match(re);
          if (m != null)
            d[0].global[key] = { value: parseFloat(m[1]), category: "WBMask" };
        }

        if (d[1] && d[1].epicmod && d[1].epicmod.epicatv_settings) {
          let compressed_runfile = d[1].epicmod.epicatv_settings;
          let runfileBuffer = new Buffer(compressed_runfile, "base64");
          // zlib.inflate(runfileBuffer, (err, data) => {
          let inflated = inflate(runfileBuffer, { to: "string" });
          if (!inflated.err && inflated) {
            let epicfile = inflated;
            let result = yaml.safeLoad(epicfile);

            for (var key in result) {
              for (var subkey in result[key]) {
                d[0].global["EPIC - " + key + ": " + subkey] = {
                  value: result[key][subkey],
                };
              }
            }
          }
          observer.next(d[0]);
          observer.complete();
          // (err, data) => {
          //   if (!err) {
          //     let epicfile = data.toString();
          //     let result = yaml.safeLoad(epicfile);

          //     for (var key in result) {
          //       for (var subkey in result[key]) {
          //         d[0].global["EPIC - " + key + ": " + subkey] = {
          //           value: result[key][subkey],
          //         };
          //       }
          //     }
          //   }
          //   observer.next(d[0]);
          //   observer.complete();
          // }
        } else {
          observer.next(d[0]);
          observer.complete();
        }
      });
    });
  }

  private _formatName(name) {
    if (name == "traces_fit") return "traces fit";
    else return name.replace("dc_", "traces fit ");
  }

  private _overlayToScore(losses, overlay, iteration, totalIteration) {
    overlay.sort((a, b) => a.sort_id - b.sort_id);
    overlay.forEach((o, j) => {
      delete o.shot_id;
      Object.entries(o).forEach((loss) => {
        let name = this._formatName(loss[0]);
        let idx = losses.findIndex((d) => d.name == name);
        let data = (losses[idx] || {}).data || new Array(totalIteration);
        let l = data[iteration] || [];
        l.push(loss[1]);
        data[iteration] = l;
        let newData = {
          data: data,
          std: (losses[idx] || {}).std || [],
          name: name,
        };
        idx >= 0 ? (losses[idx] = newData) : losses.push(newData);
      });
    });
  }
}
