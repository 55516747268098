<section class="container-fluid">
  <!-- <div
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <button mat-button (click)="rebuildModelTable()">Rebuild Table</button>
  </div> -->

  <div>
    <app-custom-table
      [data]="this.data"
      [loading]="this.loading"
      [columns]="this.options"
      [editRow]="true"
      [collapsibleDetail]="true"
      [collapsibleBodyFunction]="this.collapsibleSlot"
      [removalColumns]="['parent_job']"
      [truncationFields]="['parent_job_name']"
      [linkDefinitions]="this.linkDefinitions"
      (editRowHandler)="this.editRow($event)"
    >
      <button mat-button (click)="rebuildModelTable()">Rebuild Table</button>
    </app-custom-table>
  </div>
</section>

<div
  class="edit-modal model-table-comp-modal overlay-behavior"
  *ngIf="showEditModal"
>
  <div class="modal-body">
    <form
      [formGroup]="this.editModelFormControl"
      (submit)="this.saveChanges($event)"
    >
      <mat-form-field>
        <input matInput placeholder="Comment" formControlName="comments" />
      </mat-form-field>

      <mat-form-field appearance="fill" style="display: inline-block">
        <mat-label>Select Job</mat-label>
        <mat-select formControlName="selectedOriginJob">
          <mat-option>None</mat-option>
          <ng-container *ngFor="let job of this.jobsWithIterations">
            <mat-option [value]="job">{{ job["job_name"] }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="this.editModelFormControl.value['selectedOriginJob']"
      >
        <mat-label>Checkpoint number</mat-label>
        <input formControlName="cp_num" required matInput type="number" />
        <mat-error>Please enter a valid checkpoint number</mat-error>
      </mat-form-field>

      <div>
        <button
          mat-raised-button
          type="submit"
          style="height: fit-content; color: white"
          [style.background-color]="
            this.editModelFormControl.status == 'INVALID' ||
            this.editModelFormControl.pristine
              ? '#ccc'
              : '#2196f3'
          "
          [disabled]="
            this.editModelFormControl.status == 'INVALID' ||
            this.editModelFormControl.pristine
          "
        >
          Save
        </button>
        <button
          mat-button
          type="reset"
          style="height: fit-content"
          (click)="this.cancelEdit()"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>
