<div class="container-fluid">
  <!-- <div *ngIf="isLoading" class="loading" style="border: none !important;">
    <div class="row" style="border: none !important;">
      <div class="col-md-12" style="border: none !important;">
        <div class="card" style="border: none !important;">
          <div class="card-body" style="border: none !important;">
            <div class="loading-container" style="border: none !important;">
              <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
              <div class="text">Loading...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div *ngIf="isLoading" class="loading" style="border: none !important; width: 100%; height: 100px; display: flex; justify-content: center; align-items: center;"> -->
  <div *ngIf="isLoading" style="position: absolute; left: 50%; top: 10%; z-index: 5;">
    <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    <div class="text">Loading...</div>
  </div>
    <!-- </div> -->
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-horizontal">
                <div class="form-group">
                  <label class="col-sm-2">Job Base Path:</label>
                  <span *ngIf="currentJob!=null" class="col-sm-10" style="word-break: break-all;">{{currentJob.basePath}}</span>
                </div>
                <div class="form-group">
                  <div class="job-selection row" style="margin: 0; display: flex; align-items: center;">
                    <div class="col-sm-4 col-md-3 col-lg-4" style="margin: 0; padding: 0">
                      <nz-input-group style="display: flex;" class="legacy-input-styling">
                        <label>Select Job</label>
                        <nz-select class="col-sm-12 col-md-12" nzPlaceHolder="Select Job for comparison" nzShowSearch nzAllowClear style="flex: 0 1 400px; max-width: 400px;" [formControl]='jobControl' (click)="otherJob=''" [(ngModel)]="otherJob" name="otherJob">
                          <nz-option *ngFor="let job of filteredJobs | async" [nzValue]="job.name" [nzLabel]="job.name"></nz-option>
                        </nz-select>
                      </nz-input-group>
                    </div>
                    <div class="col-md-6">
                      <section layout="row" style="display: flex; gap: 5px; flex-wrap: wrap;">
                        <button mat-stroked-button color="primary" [matMenuTriggerFor]="iterMenu">
                          <span>Iteration: {{currentIteration}}</span>
                          <i class="zmdi zmdi-caret-down" style="display: inline-block"></i>
                        </button>
                        <mat-menu #iterMenu="matMenu">
                          <button mat-menu-item *ngFor="let iter of iterations" (click)="changeIteration(iter)">
                            {{iter}} </button>
                        </mat-menu>
                        <button mat-raised-button color="primary" (click)="getDiff()">Get Difference</button>
                        <button mat-raised-button color="primary" *ngIf="otherJob"
                          (click)="showRunfile()">Clear</button>
                      </section>
                    </div>
                    <div class="col-sm-2 col-md-2" style="display: flex; align-items: center; justify-content: center;">
                      <mat-checkbox [(ngModel)]="rawDiff">Raw Diff</mat-checkbox>
                    </div>
                  </div>
                  <div *ngIf="displayDiff">
                    <div *ngIf="!rawDiff">
                    <mat-table #table [dataSource]="diffTable">
                                    
                      <ng-container matColumnDef="Field Name">
                        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element["Field Name"]}} </mat-cell>
                      </ng-container>
                  
                      <ng-container matColumnDef="this">
                        <mat-header-cell *matHeaderCellDef> {{currentJob.name}} </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element["this"]}} </mat-cell>
                      </ng-container>
                  
                      <ng-container matColumnDef="other">
                        <mat-header-cell *matHeaderCellDef> {{currentOtherJob}} </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element["other"]}} </mat-cell>
                      </ng-container>
                                    
                      <mat-header-row style="background-color: #f2f2f2; color: white;" *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row [style.background-color]="i%2 == 0? 'white' : '#f2f2f2'" [style.color]="i%2 == 0 ? '#f2f2f2' : 'white'"
                               *matRowDef="let row; columns: displayedColumns; let i = index"
                               style="border-bottom: none;"></mat-row>
                    </mat-table>
                    </div>
                    <div *ngIf="rawDiff">
                      <div *ngFor="let difference of differences" style="display: flex;">
                        <!-- <div class="col-md-6" [style.background-color]="difference.removed ? '#ffebe9' : 'none' "> -->
                        <div style="width: 50%; display: flex; flex-direction: column;" [style.background-color]="difference.removed ? '#ffebe9' : 'none' ">
                          <div *ngFor="let line of difference.originalLine.split('\n')">{{line}}</div>
                        </div>
                        <!-- <div class="col-md-6" [style.background-color]="difference.added ? '#e6ffec' : 'none'"> -->
                        <div style="width: 50%; display: flex; flex-direction: column;" [style.background-color]="difference.added ? '#e6ffec' : 'none'">
                          <div *ngFor="let line of difference.modifiedLine.split('\n')">{{line}}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!displayDiff && displayContent != null">
                    <label class="col-sm-12">Runfile:</label>
                    <p style="white-space: pre-wrap; margin-left: 2%">{{displayContent}}</p>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-sm-4">
                    <button mat-raised-button color="warn" type="button" (click)="deleteJob(currentJob)">Delete
                      Job</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>