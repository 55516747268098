<div>
  <nz-spin [nzSpinning]="isLoading">
    <form
      nz-form
      [formGroup]="jobsForm"
      class="login-form"
      (ngSubmit)="submitForm()"
      class="form-container"
    >
      <nz-form-item>
        <nz-form-label nzRequired nzFor="job_basepath"
          >Job Base Path</nz-form-label
        >
        <nz-form-control
          [nzSm]="24"
          [nzXs]="24"
          nzErrorTip="The input is not valid Job base path!"
        >
          <input
            placeholder="Job base path"
            nz-input
            formControlName="job_basepath"
          />
        </nz-form-control>
      </nz-form-item>

      <div nz-row [nzGutter]="24">
        <div nz-col [nzXs]="12">
          <nz-form-item>
            <nz-form-label nzFor="parent_job">Parent Job</nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="parent_job"
                nzShowSearch
                [nzFilterOption]="searchFn"
                nzPlaceHolder="Select Parent Job"
              >
                <nz-option nzLabel="No Parent Job"></nz-option>
                <nz-option
                  *ngFor="let job of this.parentJobs"
                  [nzLabel]="job.job_name"
                  [nzValue]="job"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzXs]="12">
          <nz-form-item>
            <nz-form-label nzFor="parent_cp">Parent CP Number</nz-form-label>
            <nz-form-control nzErrorTip="You must provice a parent CP number!">
              <nz-input-number
                nzPlaceHolder="Select Parent checkpoint number"
                style="width: 100%"
                nz-input
                type="number"
                formControlName="parent_cp"
                [nzMin]="0"
                [nzMax]="parent_max_iteration"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <nz-form-item>
        <nz-form-control
          nzExtra="In the case that NX2 > NX1, you may want to swap the 2 axis for visualization. Click on the checkbox if you'd like to do so."
        >
          <label nz-checkbox formControlName="x2_is_inline"
            >Swap Dimensions?</label
          >
        </nz-form-control>
      </nz-form-item>

      <div style="display: flex; justify-content: flex-end">
        <button nz-button nzType="primary" type="submit">Submit</button>
      </div>
    </form>
  </nz-spin>
</div>
