import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";
import { style, animate, transition, trigger } from "@angular/animations";
import { JobDetail } from "../../../models/jobDetail";
import { Runfile } from "../../../models/runfileType";
import { RunfileParam } from "../../../models/runfileParamType";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { Subscription, Observable, of } from "rxjs";
import { Cost } from "../../../models/cost";
import { ParamValueMapService } from "./paramValueMapService";
import { StoredSettingsService } from "../../services/stored-settings.service";
import { DataSetting } from "../../../models/dataSetting";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "app-data",
  templateUrl: "./data.component.html",
  styleUrls: ["./data.component.less"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class DataComponent implements OnInit, OnDestroy {
  currentJob: JobDetail;
  settingsKey: string = "page";
  currentCosts: Cost;
  isLoading: boolean = true;
  globalInfo: RunfileParam;
  blkInfo: RunfileParam[] = [];
  selectedBlk = null;

  private _costsSubscription: Subscription;
  private rawParam: Runfile;

  constructor(
    private projectService: CachedProjectService,
    private paramMapService: ParamValueMapService,
    private storedSettings: StoredSettingsService
  ) {}

  ngOnInit() {
    if (this._costsSubscription) {
      this._costsSubscription.unsubscribe();
      this._costsSubscription = null;
    }

    this.projectService.currentJob
      .pipe(
        switchMap((d) => {
          if (!d) {
            return of(null);
          }
          this._costsSubscription = this.projectService
            .getCosts(d.id, d.iterations)
            .subscribe((cst) => {
              this.currentCosts = cst;
              this._costsSubscription = null;
              if (!cst) {
                return of(null);
              }
            });
          this.storeSettings();
          this.currentJob = d;
          return this.projectService.getRunfileParameters(
            this.currentJob.id,
            false
          );
        })
      )
      .subscribe((params: Runfile) => {
        if (!params || !params.global || !params.iterBlks) {
          this.isLoading = false;
          return;
        }
        this.rawParam = params;
        this.globalInfo = this.paramMapService.getGlobal(params.global);
        this.blkInfo = params.iterBlks.map((blk) =>
          this.paramMapService.getBlk(blk, params.global)
        );
        if (!this.restoreSettings()) {
          this.selectedBlk = this.blkInfo ? this.blkInfo[0] : null;
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.storeSettings();
    if (this._costsSubscription) {
      this._costsSubscription.unsubscribe();
    }
  }

  @HostListener("window:beforeunload", ["$event"])
  BeforeUnloadEvent(_envent) {
    this.storeSettings();
  }

  getObjKeys(obj) {
    if (!obj) {
      return [];
    }
    return Object.keys(obj);
  }

  private storeSettings() {
    if (!this.currentJob) return;
    this.storedSettings.setSettings<DataSetting>(
      this.settingsKey,
      {
        id: this.currentJob.id,
        projectId: this.currentJob.projectId,
        iteration: 0,
        selectedIndex: this.blkInfo.indexOf(this.selectedBlk),
      },
      SettingTypes.Data
    );
  }

  private restoreSettings() {
    if (!this.currentJob || !this.blkInfo) {
      return false;
    }
    let lastSettings = this.storedSettings.getSettings<DataSetting>(
      this.settingsKey,
      this.currentJob.id,
      SettingTypes.Data
    );
    if (!lastSettings) {
      return false;
    }
    this.selectedBlk = this.blkInfo[lastSettings.selectedIndex];
    return !!this.selectedBlk;
  }
}
