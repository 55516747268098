<nav id="sidebar">
  <ul class="list-unstyled components">
    <li routerLinkActive="active">
      <a routerLink="model"
        ><i class="zmdi zmdi-globe"></i><span class="hidden-xs">Model</span></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="job"
        ><i class="zmdi zmdi-grid"></i><span class="hidden-xs">Job</span></a
      >
    </li>
    <li routerLinkActive="active" *ngIf="!authService.isDemoUser()">
      <a routerLink="costs"
        ><i class="zmdi zmdi-border-clear"></i
        ><span class="hidden-xs">Grid</span></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="charts"
        ><i class="zmdi zmdi-chart"></i><span class="hidden-xs">Charts</span></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="shots"
        ><i class="zmdi zmdi-image"></i><span class="hidden-xs">Shots</span></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="demo"
        ><i class="fa-solid fa-person-chalkboard"></i
        ><span class="hidden-xs">Demo</span></a
      >
    </li>
    <li routerLinkActive="active" *ngIf="runfileAccess">
      <a routerLink="runfile"
        ><i class="zmdi zmdi-file"></i><span class="hidden-xs">Runfile</span></a
      >
    </li>
    <li routerLinkActive="active" *ngIf="isStaff">
      <a routerLink="picture"
        ><i class="zmdi zmdi-collection-image"></i
        ><span class="hidden-xs">Pictures</span></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="files"
        ><i class="zmdi zmdi-folder-outline"></i
        ><span class="hidden-xs">Files</span></a
      >
    </li>
    <li routerLinkActive="active">
      <a routerLink="tables"
        ><i class="zmdi zmdi-format-list-bulleted"></i>
        <span class="hidden-xs">Tables</span></a
      >
    </li>
  </ul>
</nav>
