import { Subscription } from "rxjs";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { JobDetail } from "../../models/jobDetail";
import { StoredSettingsService } from "../../projects/services/stored-settings.service";

@Component({
  selector: "app-settings-debug",
  templateUrl: "./settings-debug.component.html",
  styleUrls: ["./settings-debug.component.css"],
})
export class SettingsDebugComponent implements OnInit, OnDestroy {
  jobDetail: JobDetail;
  settings: any;
  settingsSubscription: Subscription;
  constructor(private storedSettingsService: StoredSettingsService) {}

  ngOnInit() {
    this.settingsSubscription =
      this.storedSettingsService.settingsUpdated$.subscribe(
        (settings) => (this.settings = settings)
      );
  }

  ngOnDestroy(): void {
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }
}
