export const tooltips: { [key: string]: string } = {
  "ADD TO DEPTH":
    'This value is added to source and receiver depths when these are calculated using SEG-Y trace header values. ZSCALE is not applied to ADD TO DEPTH. ADD TO DEPTH is not used when source and receiver depths are specified as fixed". In this case, the depths are defined explicitly using RECZ and SOUZ.',
  ANGLE: `The horizontal coordinate system defined by the SEG-Y headers will be rotated anticlockwise by ANGLE about the origin (XORIGIN, YORIGIN)`,
  DEBUG: `Output ASCII log files if "yes". These are truncated duplicates of the .ttr binary data files.`,
  "DELAY BOTH": `Time shift to be applied to both raw data and source wavelet. A positive number means that events in the shifted trace will occur at later times.`,
  "DELAY DATA": `Time shift to be applied to the raw data only. A positive number means that events in the shifted trace will occur at later times.`,
  "DELAY SOURCE": `Time shift to be applied to the source wavelet. A positive number means that events in the shifted trace will occur at later times.`,
  "DEPTH SCALE": `If source and receiver depths/elevations are specified in SEG-Y trace headers, multiply the header values by DEPTH SCALE to convert them to metres. DEPTH SCALE is not applied if DEPTH TYPE = fixed. DEPTH SCALE is not applied to ADD TO DEPTH.`,
  "DEPTH TYPE": `If "fixed", then source and receiver depths are specified directly using SOURCE DEPTH and RECEIVER DEPTH; DEPTH SCALE and ADDTO DEPTH are not used. If "elevation", source and receiver depths are calculated using the absolute values of the source and receiver surface elevations taken from SEG-Y trace headers. DEPTH SCALE and ADD TO DEPTH are applied. If "depth", source and receiver depths are calculated using the absolute values of the source depth and the receiver surface elevation taken from SEG-Y trace headers. DEPTH SCALE and ADDTO DEPTH are applied. If "both", source depth is calculated as the source depth minus the source surface-elevation, and the receiver depth is calculated as minus the receiver elevation taken from SEG-Y trace headers. Absolute values are not taken, and DEPTH SCALE and ADD TO DEPTH are applied.`,
  DX: `Horizontal (and default for vertical) grid spacing for the finite-difference modelling. For the high kernel, there should be at least 2.61grid points per wavelength at the lowest velocity and highest frequency in the seismic data. For the low kernel, there should be at least 5 grid points per wavelength in any region in which energy will travel more than about a wavelength, and at least 4 grid points per wavelength everywhere – in practice, the latter is allowable really only in shallow water with out a slow seabed.`,
  DZ: `Vertical grid spacing for the finite-difference modelling. For the high kernel, there should be at least 2.61grid points per wavelength at the lowest velocity and highest frequency in the seismic data. For the low kernel, there should be at least 5 grid points per wavelength in any region in which energy will travel more than about a wavelength, and at least 4 grid points per wavelength everywhere – in practice, the latter is allowable really only in shallow water with out a slow seabed.`,
  "EDGE GAP": `Exclude sources and receivers within this distance of the horizontal model edge. That is, leave a gap of at least this width around the edge of the model.`,
  "FIXED ARRAY": `If "yes", the receiver array is fixed in space. That is: If GEOMETRY = segy, then the channel numbers specified in the trace headers must refer to unique positions on the ground, and a particular channel number will always refer to the same physical location. This will often be the case for simple OBC and land surveys; it will never be the case for towed-streamer or roll-along surveys.`,
  GEOMETRY: `If "segy", the seismic data file <project>-RawSeis.sgy, or a text file <project>-RawSeis.txt specifying multiple input seismic data files, must be present. The survey geometry is built using the geometry specified in the SEG-Y trace headers, modified by various parameters. If used with PROBLEM TYPE = synthetics, this allows the synthetics to be generated with exactly the geometry of the field data. If "regular", a simple rectangular geometry is specified directly by parameter settings. This is only appropriate if PROBLEM TYPE = synthetic.`,
  "HIGH CUT": `Apply a high-cut (low-pass) recursive Butterworth filter to the input seismic data and seismic wavelets before any later resampling. A non-zero number specifies the 3 dB down point for minimum-phase filters (or the 6 dB down point for zero-phase filters).The filter will deal sensibly with top and bottom mutes; it will not deal cleverly with surgical mutes in the interior of individual traces.`,
  "HIGH ROLL": `Specifies the roll-off of the high-cut filter. Values will be rounded to the nearest 12 dB/octave for minimum-phase filters and to the nearest 24 dB for zero-phase filters.`,
  IO: `If "fw3d", segyprep generates ttr, ttm, vtr and pgy files. If “segy”, segyprep generates sgy, idx and geo files. If “both”, segyprep generates both file formats.`,
  "KILL HEADER 2": `The position within the SEG-Y trace header of the first byte of a two-byte integer flag that can be used to kill traces. Byte 29 (i.e. bytes 29 to 30) is the usual SEG-Y flag for dead traces. Maximum possible value is 239 which selects the header at bytes 239 to 240. A zero value disables the flag.`,
  "KILL HEADER 4": `The position within the SEG-Y trace header of the first byte of a four-byte integer flag that can be used to kill traces. Maximum possible value is 237 which selects the header at bytes 237 to 240. A zero value disables the flag.`,
  "KILL VALUE 2": `If the SEG-Y trace header at the position specified by KILLHEAD2 has the value specified by KILLVALUE2, then the trace will be killed. A value of 2 at bytes 29-30 is the usual SEG-Y convention for a dead trace.`,
  "KILL VALUE 4": `If the SEG-Y trace header at the position specified by KILLHEAD4 has the value specified by KILLVALUE4, then the trace will be killed.`,
  LEFT2RIGHT: `When using OFFSET2D, the sign of the offset in the seg-y header is significant: If LEFT2RIGHT is set to “yes”, then a positive offset corresponds to a survey acquired by a source pulling the streamer and sailing from left to right, that is from low to high x-coordinates in the reference frame of the model. A positive offset would also correspond to a source pushing the streamer and sailing right to left. In both cases, the receivers are closer to the 2D model origin than is the source. If LEFT2RIGHT is set to “no”, then a positive offset corresponds to a survey acquired by a source pulling the streamer and sailing from right to left, that is from high to low x-coordinates in the reference frame of the model. A positive offset would also correspond to a source pushing the streamer and sailing left to right. In both cases, the receivers are further from the 2D model origin than is the source.`,
  "LOW CUT": `Apply a low-cut (high-pass) recursive Butterworth filter to the input seismic data and seismic wavelets before any later resampling. A non-zero number specifies the 3 dB down point for minimum-phase filters (or the 6 dB down point for zero-phase filters). The filter will deal sensibly with top and bottom mutes; it will not deal cleverly with surgical mutes in the interior of individual traces. It will deal sensibly with large transients in the first non-zero samples. Note: the filter does not currently deal with non-zero transients at the end of a trace. These transients will affect the results for zero- phase filters, but do no affect minimum-phase filters`,
  "LOW ROLL": `Specifies the roll-off of the low-cut filter. Values will be rounded to the nearest 12 dB/octave for minimum-phase filters and to the nearest 24 dB for zero-phase filters.`,
  "MAX ANGLE": `When 3D data are projected onto a 2D line, exclude traces for which the feathering angle is greater than MAXANGLE.`,
  "MAX OFFSET": `Traces with an absolute source-receiver horizontal offset of greater than this are excluded.`,
  "MAX RECS": `Sets the maximum possible number of receivers. If this number is exceeded on input, then the code continues, but data from additional receivers are ignored.`,
  "MAX SOURCES": `Sets the maximum possible number of sources. If this number is exceeded on input, then the code continues, but data from additional sources are ignored`,
  "MAX TRACES": `Stop processing after this many input traces have been used.Useful for early QC.`,
  "MINIMUM PHASE": `“yes” = use minimum-phase filters, “no” = use zero-phase filters.`,
  "MIN OFFSET": `Traces with an absolute source-receiver horizontal offset of less than this are excluded.`,
  NX1: `The number of grid points in the left-right direction for the finite-difference code.The velocity model measures (NX1-1) * DX metres in-line. This is the X direction in the final transformed coordinate system referenced by Segyprep. Note that the X1 direction of the velocity model need not coincide with the in-line direction of the field data nor with the in-line direction of any processed reflection section, though it will often be convenient to make them the same.`,
  NX2: `The number of grid points in the front-back direction. NX2 = 1 for a 2D model. The velocity model measures (NX2-1) * DX metres cross-line. This is the Y direction in the final transformed coordinate system referenced by Segyprep.Note that the N2 direction of the velocity model need not coincide with the cross-line shooting direction of the field data nor with the cross-linedirection of any processed reflection section, though it will often be convenient to make them the same.`,
  NX3: `The number of grid point in depth for the finite-difference code. The velocity model measures (NX3-1) * DX metres in depth. This is the Z direction of the coordinate system referenced by Segyprep.`,
  "OFFSET 2D": `For 2D models, ignore the seg-y receiver coordinates, and position receivers using the source position combined with offset taken from the seg-y headers. This is useful for 2D lines that have inclomplete geometry information. The sign of the offset in the seg-y header is significant – see LEFT2RIGHT for details.`,
  "OUT SEIS": `Output the input seismic data as a SEG-Y file after processing. These output data will have identical headers and trace order to subsequent synthetics if using SEG-Y within fullwave.`,
  "OUT SOURCE": `Output the source wavelet as a SEG-Y file after processing.This is useful to check that timeshifts and resampling have performed as expected.`,
  "PROBLEM TYPE": `Output data files for the generation of synthetic data, for full-wavefield inversion, or for both. PROBLEM TYPE must be "synthetic" if GEOMETRY = regular.`,
  "PROCESS TIME": `Data and source wavelet are pre-processed at this trace length. This will normally be longer than TTIME to allow for the full application of resampling and time shifting without data loss. During data processing, the trace length is set equal to PTIME before any other processing takes place.`,
  "PROJECT 2D": `For 2D models (i.e. NX2 = 1), project sources onto a 2D line at right angles and then place receivers on this line so as to obtain the correct offset. The offset is calculated from the original source-receiver geometry; the offset in the seg-y header is not used and its sign is not relevant.`,
  "REC DEPTH": `Specifies the uniform receiver depth when DEPTH TYPE = fixed.`,
  "REC DX": `Specifies the receiver separation in the X direction when GEOMETRY = regular.`,
  "REC DY": `Specifies the receiver separation in the Y direction when GEOMETRY = regular.`,
  "REC GEOMETRY": `Only relevant if GEOMERTY = regular. If "normal", then the horizontal receiver geometry specifies the location of the receivers relative to the model. If "all", then every grid point, at the depth specified by RECZ, contains a receiver. If "source", then the receiver geometry specifies the location of the receivers relative to the position of the source. In this case, the receiver array moves horizontally with the source. Receivers lying outside the model are excluded`,
  "REC NX": `Specifies the number of receivers in the X direction when GEOMETRY = regular.`,
  "REC NY": `Specifies the number of receivers in the Y direction when GEOMETRY = regular.`,
  "REC X ORIGIN": `Specifies the X coordinate of first the source when GEOMETRY = regular. If REC GEOMETRY = normal, then REC X ORIGIN is referenced to the model. If REC GEOMETRY = source, then REC X ORIGIN is referenced to each source position.`,
  "REC Y ORIGIN": `Specifies the Y coordinate of first the source when GEOMETRY = regular. If REC GEOMETRY = normal, then REC Y ORIGIN is referenced to the model. If REC GEOMETRY = source, then REC Y ORIGIN is referenced to each source position.`,
  RECIPROCITY: `If yes, exchange sources and receivers. The exchange takes place after all trace selection and other manipulations are completed. Hence all parameters in Segyprep refer to original sources and receivers prior to the application of reciprocity. This is the sensible option to choose when there are more source positions than total receiver positions. It is only sensible with a fixed receiver array.`,
  RETAIN: `Keep the geometry of traces that fall outside the model. These traces are not included in the data files. Useful for debugging the geometry of a new dataset. If all looks good, Segyprep does NOT need to be re- run with this switch off.`,
  "SAMPLE RECS": `Apply receiver sub-sampling to the input SEG-Y data. Traces are rejected if (CHAN − CHAN1) / (SAMPLE RECS) is non-integer, where CHAN1 is the number of the first receiver. If SAMPLE RECS = 1, all receivers are accepted. If SAMPLE RECS = 4, every fourth receiver will be selected provided that channel numbers are contiguous. Note that the behaviour may not be as expected if channel numbers are not contiguous.`,
  "SAMPLE SHOTS": `Apply source sub-sampling to the input SEG-Y data. Traces are rejected if (SHOT − SHOT1) / (SAMPLE SHOTS) is non-integer, where SHOT refers to either SOURCE or FFID number as appropriate, and SHOT1 is the number of the first source. If SAMPLE SHOTS = 1, all sources are accepted. If SAMPLE SHOTS = 4, every fourth source will be selected provided that shot numbers are contiguous. Note that the behaviour may not be as expected if source numbers are not contiguous.`,
  "SOU DEPTH": `Specifies the uniform source depth when DEPTH TYPE = fixed.`,
  "SOU DX": `Specifies the source separation in the X direction when GEOMETRY = regular.`,
  "SOU DY": `Specifies the source separation in the Y direction when GEOMETRY = regular.`,
  "SOU NX": `Specifies the number of sources in the X direction when GEOMETRY = regular.`,
  "SOU NY": `Specifies the number of sources in the Y direction when GEOMETRY = regular.`,
  "SOU X ORIGIN": `Specifies the X coordinate of the first source when GEOMETRY = regular.`,
  "SOU Y ORIGIN": `Specifies the Y coordinate of the first source when GEOMETRY = regular.`,
  TEXT: `Outputs ASCII versions of the idx SEG-Y metadata index files for QC.`,
  "TIME STEP FD": `Time step for the finite-difference modelling. This need not match the sample interval of the seg-y data that segyprep generates as input to fullwave. For the high kernel, the time step must be small enough such that the maximum distance traversed in one time step is less than two-thirds of the grid spacing in the fastest cell in the model including any effects of anisotropy. For the low kernel, the time step must be small enough such that the maximum distance traversed in one time step is less than half of the grid spacing in the fastest cell in the model including any effects of anisotropy.`,
  "TIME STEP SEGY": `Sample interval for the seg-y data and wavelets prepared by segyprep for input to fullwave. This need not match the finite-difference time step used within fullwave. The input seg-y data will be re- sampled if necessary. Normally, the input data will have either been low-pass filtered outside segyprep, or will be filtered inside segyprep using HIGH CUT.`,
  "TOTAL TIME": `The total time to which the finite-difference modelling should be run. Run times are proportional to this number.`,
  "UNIQUE SHOTS": `If "yes", SOURCE or FFID numbers, as appropriate, are unique for each source position. In this case, SOUCE/FFID numbers will be retained and used to label sources, and the input SEG-Y data may be in any order. If "no", SOURCE or FFID numbers may not be unique. In this case, original SOURCE/FFID numbers will not be retained, and the sources will be renumbered. A new source is declared each time the source location or SOURCE/FFID number changes. In this case, the input SEG-Y data must be ordered as common-source records. Note that most 3D surveys will not use unique SOURCE/FFID numbers`,
  "USE FFIDS": `If "yes", source numbers are specified using FFID numbers from SEG-Y headers; these are in bytes 9–12 of the trace header. If "no", source numbers are specified using SOURCE numbers from SEG-Y headers; these are in bytes 17–20 of the trace header.`,
  "WATER DEPTH": `If “yes”, then extract source and receiver water depths from input seg-y trace headers to a separate geo file.`,
  "WATER DEPTH SCALE": `Scalar to apply to extracted water depths to convert seg-y header values to metres.`,
  "X ORIGIN": `Subtract XORIGIN from the X coordinate of source and receiver as given by the SEG-Y trace header. Note that XYSCALE is applied after subtraction of XORIGIN. That is, the units of XORIGIN are the same as the units of X given in the trace header.`,
  "X SHIFT": `After the origin shift specified by (XORIGIN, YORIGIN), scaling by XYSCALE, and rotation by ANGLE, the coordinate system origin may be shifted again to (XSHIFT, YSHIFT) in the rotated, scaled coordinate system.This provides a simple way to extend or reduce the extent of a model in the in-line and cross-line directions without re-calculating XORIGIN and YORIGIN.`,
  "Y ORIGIN": `Subtract YORIGIN from the Y coordinate of source and receiver as given by the SEG-Y trace header. Note that XYSCALE is applied after subtraction of YORIGIN. That is, the units of YORIGIN are the same as the units of Y given in the trace header.`,
  "Y SHIFT": `After the origin shift specified by (XORIGIN, YORIGIN), scaling by XYSCALE, and rotation by ANGLE , the coordinate system origin may be shifted again to (XSHIFT, YSHIFT) in the rotated, scaled coordinate system. This provides a simple way to extend or reduce the extent of a model in the in-line and cross-line directions without re-calculating XORIGIN and YORIGIN.`,
  "XY SCALE": `Multiply the (optionally shifted) xy-coordinates from the trace headers by XYSCALE. XYSCALE multiplies both the original SEG-Y coordinates and the values of XORIGIN and YORIGIN.`,
};
