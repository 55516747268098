import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Observable, Subject, Subscription, forkJoin, of } from "rxjs";
import { filter } from "rxjs/operators";
import { JobDetail } from "../../../models/jobDetail";
import { ModelSettings } from "../../../models/modelSettings";
import { ModelTypeSettings } from "../../../models/modelTypeSettings";
import { ModelViewSettings } from "../../../models/modelViewSetting";
import { Ranges } from "../../../models/ranges";
import { ShotOverlayType } from "../../../models/shotOverlayType";
import { Slice } from "../../../models/slice";
import { SliceType } from "../../../models/sliceType";
import { SettingTypes } from "../../../shared/enums/settingTypes";
import { CachedProjectService } from "../../../shared/services/cached-project.service";
import { ChartSliceComponent } from "../../project/chart-slice/chart-slice.component";
import { CachedPaletteService } from "../../services/cached-palette.service";
import {
  SliceSettings,
  SliceSettingsService,
} from "../../services/slice-settings.service";
import { StoredSettingsService } from "../../services/stored-settings.service";

export interface SliceChartSettings {
  ranges: Ranges;
  xSteps: number;
  ySteps: number;
  sliceType: SliceType;
  sliceNumber: number;
  xTitle: string;
  yTitle: string;
  xLine: number;
  yLine: number;
  sonicLogId: string;
  sonicNX: any;
  sonicWidth: number;
  hoverLabelX: string;
  hoverLabelY: string;
  velocityRange: number[];
  showShotOverlay: boolean;
  showHorizons: string[];
}

@Component({
  selector: "app-model-chart-container",
  templateUrl: "./model-chart-container.component.html",
  styleUrls: ["./model-chart-container.component.less"],
})
export class SliceChartContainerComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() jobDetail: JobDetail;
  @Input() slice: Slice;
  @Input() showChartMenu = true;
  @Input() settingsKey: string;
  shotId: number = null;
  sliceSettings: SliceSettings;
  sliceChartSettings: SliceChartSettings = {} as SliceChartSettings;
  selectedSonicId: any = null;
  showSonicLog: boolean = false;
  showShotOverlay: boolean = false;
  shotOverlayTypes: ShotOverlayType[] = [
    { id: "traces_fit", name: "Traces Fit", min: 0, max: 100 },
    { id: "norm_ratio", name: "Norm Ratio", min: 0, max: 1 },
    { id: "fitting_factor", name: "Fitting Factor", min: 0, max: 1 },
  ];
  shotModels = ["ForwardWavefield", "BackwardWavefield", "grad", "den", "dens"];
  availShots: number[] = [];
  modelTypes = this.sliceSettingsService.modelTypes;
  currentShotOverlayType: ShotOverlayType = null;
  vpUpdateCheck = false;
  vpDiffIteration: number;
  currentModelTypeId = "Vp";
  timeSlice: number = null;
  refreshChart: boolean = false;
  inlineWidth: number = 100;
  crosslineWidth: number = 100;

  depthHeight: number = 250;
  height: number = 600;
  chartHeight: number = 600;
  updateChart: boolean = false;
  showHorizon: boolean = false;
  horizonTypes: any = [];
  showHorizonTypes = [];
  palettes: string[] = [];
  maxDepth: number = 0;

  minVal: number;
  maxVal: number;
  minValZ: number;
  maxValZ: number;
  paletteOfCurrentType: string;
  private modelTypesSubscription: Subscription;
  private settingsSubscription: Subscription;
  private updateModelShotSubscription: Subscription;
  @ViewChild(ChartSliceComponent, { static: true }) chart: ChartSliceComponent;
  @ViewChild("chartContainer", { static: true }) chartContainer: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private projectService: CachedProjectService,
    private storedSettingsService: StoredSettingsService,
    private sliceSettingsService: SliceSettingsService,
    private paletteService: CachedPaletteService
  ) {}

  ngOnInit() {
    this.loadPage(this.route.snapshot.queryParamMap);
    this.settingsSubscription = this.storedSettingsService.settingsUpdated$
      .pipe(
        filter((settingTypes) =>
          settingTypes.some(
            (r) =>
              [
                SettingTypes.SliceSettings,
                SettingTypes.ModelType,
                SettingTypes.Model,
                SettingTypes.ModelView,
              ].indexOf(r) >= 0
          )
        )
      )
      .subscribe(() => {
        console.log("chart settings updated");
        this.loadPage(this.route.snapshot.queryParamMap);
      });
    this.paletteService.getPalettes().subscribe((data) => {
      this.palettes = data;
    });
    this.getSliceHeights();
    this.setChartHeight();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.getSliceWidths();
    this.getSliceHeights();
    this.setChartHeight();
  }
  ngOnChanges(changes: SimpleChanges) {
    const change = changes.jobDetail;
    if (change.currentValue && !change.isFirstChange()) {
      this.loadPage(this.route.snapshot.queryParamMap);
    }
  }
  loadPage(params: ParamMap) {
    this.restoreSettings();
    this.setSliceChartSettings();
    this.updateModelTypeList();
    this.updateModelShotSubscription = this.updateModelShot().subscribe(() => {
      this.chart.loadChart();
    });
    // TODO: add this back in after demo
    // this.updateModelTypeList();
  }
  setSliceChartSettings() {
    this.sliceChartSettings.ranges = this.getRangesForSliceType(
      this.slice.sliceType
    );
    this.sliceChartSettings.xSteps = this.getXStepForSliceType(
      this.slice.sliceType
    );
    this.sliceChartSettings.ySteps = this.getYStepForSliceType(
      this.slice.sliceType
    );
    this.sliceChartSettings.sliceType = this.getAdjustedSliceType(
      this.slice.sliceType
    );
    this.sliceChartSettings.sliceNumber = this.getNumberForSliceType(
      this.slice.sliceType
    );
    this.sliceChartSettings.xTitle = this.getXTitleForSliceType(
      this.slice.sliceType
    );
    this.sliceChartSettings.yTitle = this.getYTitleForSliceType(
      this.slice.sliceType
    );
    this.sliceChartSettings.xLine = this.getXForSliceType(this.slice.sliceType);
    this.sliceChartSettings.yLine = this.getYForSliceType(this.slice.sliceType);
    this.sliceChartSettings.sonicLogId = this.showShowLogId();
    this.sliceChartSettings.sonicNX = this.getSonicNXForSliceType(
      this.slice.sliceType
    );
    this.sliceChartSettings.sonicWidth = this.getSonicWidthForSliceType(
      this.slice.sliceType
    );
    this.sliceChartSettings.hoverLabelX = this.getHoverLabelX(
      this.slice.sliceType
    );
    this.sliceChartSettings.hoverLabelY = this.getHoverLabelY(
      this.slice.sliceType
    );
    this.sliceChartSettings.velocityRange = this.getCurrentVeloctiyRange(
      this.slice.sliceType
    );
    this.sliceChartSettings.showShotOverlay = this.getShotOverlay(
      this.slice.sliceType
    );
    this.sliceChartSettings.showHorizons = this.getHorizons(
      this.slice.sliceType
    );

    this.currentShotOverlayType = this.shotOverlayTypes[0];
  }
  onMenuChanged(sliceChartSettings: SliceChartSettings) {
    // this.sliceSettingsService.setQueryParams(this.route, sliceChartSettings);
    // this.restoreSettings()
  }
  getSliceWidths() {
    if (
      window.innerWidth < 992 ||
      !this.jobDetail ||
      this.jobDetail.modelGrid.is2d
    ) {
      this.inlineWidth = 100;
      this.crosslineWidth = 100;
      return;
    }
    var inline = Math.round(
      (100 * this.jobDetail.modelGrid.nx2) /
        (this.jobDetail.modelGrid.nx1 + this.jobDetail.modelGrid.nx2)
    );
    if (this.jobDetail.modelGrid.nx1 / this.jobDetail.modelGrid.nx2 < 0.2) {
      inline = inline * 0.97;
    }

    this.inlineWidth = inline;
    this.crosslineWidth = 100 - inline;
  }
  getSliceHeights() {
    if (this.maxDepth != 0) return;

    if (
      window.innerWidth < 992 ||
      !this.jobDetail ||
      this.jobDetail.modelGrid.is2d
    ) {
      if (!this.jobDetail) {
        this.depthHeight = 250;
        return;
      }

      this.depthHeight =
        80 +
        (this.jobDetail.modelGrid.nx1 / this.jobDetail.modelGrid.nx2) *
          (window.innerWidth - 320);
      if (this.depthHeight < 200) this.depthHeight = 200;
      return;
    }
    // TODO
    // var sliceWidth = $("#slice-settings").width();
    var sliceWidth = 100;
    if (!sliceWidth) {
      sliceWidth = window.innerWidth - 180;
    }
    var inline = 110 + ((window.innerWidth - 400) * this.inlineWidth) / 100;
    var crossline = sliceWidth - inline + 30;
    //this.depthHeight = crossline - 100;
    if (this.depthHeight < 200) this.depthHeight = 200;

    this.maxDepth = Math.max(650, this.depthHeight);
  }
  setChartHeight() {
    var width = this.chartContainer.nativeElement.offsetWidth;
    if (width < 450) {
      this.chartHeight = 350;
    } else {
      this.chartHeight =
        this.slice.sliceType === SliceType.depth
          ? this.depthHeight
          : this.height;
    }
  }
  getRangesForSliceType(type: SliceType): Ranges {
    switch (type) {
      case SliceType.inline:
        return {
          x: [0, this.jobDetail.modelGrid.nx2 * this.jobDetail.modelGrid.dx],
          y: [this.jobDetail.modelGrid.nx3 * this.jobDetail.modelGrid.dx, 0],
        };
      case SliceType.crossLine:
        return {
          x: [0, this.jobDetail.modelGrid.nx1 * this.jobDetail.modelGrid.dx],
          y: [this.jobDetail.modelGrid.nx3 * this.jobDetail.modelGrid.dx, 0],
        };
      case SliceType.depth:
        return {
          x: [0, this.jobDetail.modelGrid.nx2 * this.jobDetail.modelGrid.dx],
          y: [0, this.jobDetail.modelGrid.nx1 * this.jobDetail.modelGrid.dx],
        };
    }
    return null;
  }

  getXStepForSliceType(type: SliceType): number {
    switch (type) {
      case SliceType.inline:
        return this.jobDetail.modelGrid.nx3;
      case SliceType.crossLine:
        return this.jobDetail.modelGrid.nx3;
      case SliceType.depth:
        return this.jobDetail.modelGrid.nx1;
    }
  }

  getYStepForSliceType(type: SliceType): number {
    switch (type) {
      case SliceType.inline:
        return this.jobDetail.modelGrid.nx2;
      case SliceType.crossLine:
        return this.jobDetail.modelGrid.nx1;
      case SliceType.depth:
        return this.jobDetail.modelGrid.nx2;
    }
  }

  getNumberForSliceType(type: SliceType): number {
    switch (type) {
      case SliceType.inline:
        return this.sliceSettings.inline;
      case SliceType.crossLine:
        return this.sliceSettings.crossline;
      case SliceType.depth:
        return this.sliceSettings.depth;
    }
    return 0;
  }
  getAdjustedSliceType(type: SliceType) {
    if (!this.jobDetail || this.jobDetail.modelGrid.is2d) return type;

    switch (type) {
      case SliceType.inline:
        return this.jobDetail.x2_is_inline
          ? SliceType.inline
          : SliceType.crossLine;
      case SliceType.crossLine:
        return this.jobDetail.x2_is_inline
          ? SliceType.crossLine
          : SliceType.inline;
      case SliceType.depth:
        return SliceType.depth;
    }

    return type;
  }

  getTitleForSliceType(type: SliceType) {
    switch (type) {
      case SliceType.inline:
        return (
          "Inline " +
          (!!this.sliceSettings.inline ? this.sliceSettings.inline : "")
        );
      case SliceType.crossLine:
        return "Crossline " + this.sliceSettings.crossline;
      case SliceType.depth:
        return "Depth " + this.sliceSettings.depth;
    }
    return "";
  }

  getHoverLabelX(type: SliceType) {
    switch (type) {
      case SliceType.inline:
        return "XL";
      case SliceType.crossLine:
        return "IL";
      case SliceType.depth:
        return "XL";
    }
  }

  getHoverLabelY(type: SliceType) {
    switch (type) {
      case SliceType.inline:
        return "Depth";
      case SliceType.crossLine:
        return "Depth";
      case SliceType.depth:
        return "IL";
    }
  }

  getYTitleForSliceType(type: SliceType): string {
    switch (type) {
      case SliceType.inline:
        return "depth (" + this.getUnit() + ")";
      case SliceType.crossLine:
        return "depth (" + this.getUnit() + ")";
      case SliceType.depth:
        return "inline (" + this.getUnit() + ")";
    }
    return "";
  }

  getXForSliceType(type: SliceType): number {
    if (this.jobDetail.modelGrid.is2d) return null;

    var range = this.getRangesForSliceType(type);
    switch (type) {
      case SliceType.inline:
        return this.sliceSettings.crossline * this.jobDetail.modelGrid.dx;
      case SliceType.crossLine:
        return this.sliceSettings.inline * this.jobDetail.modelGrid.dx;
      case SliceType.depth:
        return this.sliceSettings.crossline * this.jobDetail.modelGrid.dx;
    }
    return null;
  }

  getSonicNXForSliceType(type: SliceType) {
    let log = this.jobDetail.wellLogs.find((l) => l.id == this.selectedSonicId);
    if (!log) return null;

    switch (type) {
      case SliceType.inline: {
        return log.crossline;
      }
      case SliceType.crossLine: {
        return log.inline;
      }
      case SliceType.depth: {
        let locs = this.jobDetail.wellLogs.map((w) => [
          w.crossline,
          w.inline,
          w.id == log.id,
        ]);
        return locs;
      }
    }
    return null;
  }
  getCurrentVeloctiyRange(type: SliceType): number[] {
    switch (type) {
      case SliceType.inline:
        return [this.minVal, this.maxVal];
      case SliceType.crossLine:
        return [this.minVal, this.maxVal];
      case SliceType.depth:
        return [this.minValZ, this.maxValZ];
    }
    return null;
  }
  getHorizons(type: SliceType) {
    if (
      type != SliceType.depth &&
      this.showHorizon &&
      this.horizonTypes &&
      this.showHorizonTypes.length > 0
    )
      return this.showHorizonTypes;

    return null;
  }
  getYForSliceType(type: SliceType): number {
    if (this.jobDetail.modelGrid.is2d) return null;

    var range = this.getRangesForSliceType(type);
    switch (type) {
      case SliceType.inline:
        return this.sliceSettings.depth * this.jobDetail.modelGrid.dx;
      case SliceType.crossLine:
        return this.sliceSettings.depth * this.jobDetail.modelGrid.dx;
      case SliceType.depth:
        return this.sliceSettings.inline * this.jobDetail.modelGrid.dx;
    }
    return null;
  }
  getXTitleForSliceType(type: SliceType): string {
    switch (type) {
      case SliceType.inline:
        return "crossline (" + this.getUnit() + ")";
      case SliceType.crossLine:
        return "inline (" + this.getUnit() + ")";
      case SliceType.depth:
        return "crossline (" + this.getUnit() + ")";
    }
    return "";
  }

  showShowLogId() {
    if (
      !this.jobDetail.wellLogs ||
      !this.showSonicLog ||
      this.jobDetail.wellLogs.length == 0
    )
      return null;
    let log = this.jobDetail.wellLogs.find((l) => l.id == this.selectedSonicId);
    if (
      log &&
      log.inline == this.sliceSettings.inline &&
      log.crossline == this.sliceSettings.crossline
    )
      return this.selectedSonicId;
    this.showSonicLog = false;
    this.selectedSonicId = null;
    return false;
  }

  getSonicWidthForSliceType(type: SliceType) {
    switch (type) {
      case SliceType.inline:
        return 60;
      case SliceType.crossLine:
        return (
          60 *
          (this.jobDetail.modelGrid.nx1 / this.jobDetail.modelGrid.nx2 < 0.2
            ? 0.8
            : 1)
        );
    }
  }
  getUnit(): string {
    switch (this.jobDetail.modelGrid.unit) {
      case "M":
        return "km";
      case "I":
        return "ft";
    }
    return "";
  }

  getShotOverlay(type: SliceType) {
    if (type == SliceType.depth) return this.showShotOverlay;

    return false;
  }
  getWellName(sonicId) {
    let wellLog = this.jobDetail.wellLogs.find((l) => l.id == sonicId);
    if (!wellLog || !this.showSonicLog) return "";
    return wellLog.name;
  }
  resetClicked() {
    this.chart.resetClicked();
  }

  zoomInClicked() {
    this.chart.zoomInClicked();
  }

  zoomOutClicked() {
    this.chart.zoomOutClicked();
  }
  private restoreRangeAndPaletteForCurrentType() {
    if (!this.currentModelTypeId || !this.jobDetail.id) return;
    let settings = this.storedSettingsService.getSettings<ModelTypeSettings>(
      this.settingsKey,
      this.currentModelTypeId + this.jobDetail.id,
      SettingTypes.ModelType,
      this.jobDetail.projectId
    );
    if (settings) {
      this.paletteOfCurrentType = settings.palette;
      this.minVal = settings.ranges.minValue;
      this.maxVal = settings.ranges.maxValue;
      this.minValZ = settings.ranges.minValueZ;
      this.maxValZ = settings.ranges.maxValueZ;
      this.shotId = this.getModelShotId(settings);
    } else {
      this.minVal = null;
      this.maxVal = null;
      this.minValZ = null;
      this.maxValZ = null;
    }
  }
  updateModelShot() {
    if (!this.shotModels.includes(this.currentModelTypeId)) return of(null);
    var subject = new Subject();
    var obs = subject.asObservable();
    this.projectService
      .getShotsAvailableForModel(
        this.jobDetail.id,
        this.sliceSettings.iteration,
        this.currentModelTypeId
      )
      .subscribe((shots) => {
        if (!shots || shots.length <= 0) {
          subject.next(null);
        }
        if (!this.shotId) this.shotId = shots[0];

        subject.next(this.shotId);
      });

    return obs;
  }
  updateModelTypeList() {
    if (this.sliceSettings.iteration !== 0) {
      this.modelTypesSubscription = forkJoin([
        this.projectService.getModelTypes(
          this.jobDetail.id,
          this.sliceSettings.iteration
        ),
        this.projectService.getModelTypes(this.jobDetail.id, 0),
      ]).subscribe(
        (data) => {
          this.modelTypes = data[0];
          if (
            data[1].map((d) => d.id).includes("Delta") &&
            !this.modelTypes.map((m) => m.id).includes("Delta")
          )
            this.modelTypes.push({ id: "Delta", name: "Delta" });
          if (
            data[1].map((d) => d.id).includes("Epsilon") &&
            !this.modelTypes.map((m) => m.id).includes("Epsilon")
          )
            this.modelTypes.push({ id: "Epsilon", name: "Epsilon" });
        },
        (err) => {
          this.modelTypes = [{ id: "Vp", name: "Vp" }];
        }
      );
    } else {
      this.modelTypesSubscription = this.projectService
        .getModelTypes(this.jobDetail.id, this.sliceSettings.iteration)
        .subscribe(
          (data) => {
            this.modelTypes = data;
            if (this.jobDetail.wellLogs && this.jobDetail.wellLogs.length)
              this.modelTypes = this.modelTypes.concat([
                { id: "Gradient", name: "Gradient" },
                { id: "Total_Vp_Update", name: "Total_Vp_Update" },
              ]);
          },
          (err) => {
            this.modelTypes = [{ id: "Vp", name: "Vp" }];
          }
        );
    }
  }

  // TODO: Implement this function
  // private getAvailableTimeSlices(modelType) {
  //   if (!this.shotModels.includes(modelType))
  //     return;
  //   this._timeSliceSubscription = this.projectService.getShotsAvailableForModel(this.jobDetail.id, this.iteration, modelType)
  //     .switchMap(shots => {
  //       if (!shots || shots.length <= 0)
  //         return Observable.of(null);
  //       shots = shots.sort((a, b) => a - b);
  //       this.availShots = shots
  //       if (!this.shotId)
  //         this.shotId = shots[0]
  //       if (!this.timeSliceModels.includes(modelType))
  //         return Observable.of(null);
  //       return this.projectService.getTimeSliceAvailableForModel(this.jobDetail.id, this.sliceSettings.iteration, this.shotId, modelType);
  //     }).subscribe(timeSlices => {
  //       if (!timeSlices)
  //         return;
  //       this.availTimeSlices = timeSlices;
  //       if (this.availTimeSlices && this.availTimeSlices.length > 0 && !this.timeSlice)
  //         this.timeSlice = this.availTimeSlices[0];
  //     });
  // }

  getModelShotId(settings) {
    if (!settings || !settings.modelShot) {
      return null;
    }
    return settings.modelShot[this.sliceSettings.iteration];
  }
  private restoreSettings() {
    let modelViewSettings =
      this.storedSettingsService.getSettings<ModelViewSettings>(
        this.settingsKey,
        this.jobDetail.id,
        SettingTypes.ModelView,
        this.jobDetail.projectId
      );
    if (modelViewSettings) {
      if (modelViewSettings.height) {
        this.height = modelViewSettings.height;
      }
      if (modelViewSettings.depthHeight) {
        this.depthHeight = modelViewSettings.depthHeight;
      }
    }
    var storedSliceSettings =
      this.storedSettingsService.getSettings<SliceSettings>(
        this.settingsKey,
        this.jobDetail.id,
        SettingTypes.SliceSettings,
        this.jobDetail.projectId
      );
    this.sliceSettings = this.sliceSettingsService.setDefaults(
      storedSliceSettings,
      this.jobDetail
    );

    let settings = this.storedSettingsService.getSettings<ModelSettings>(
      this.settingsKey,
      this.jobDetail.id,
      SettingTypes.Model,
      this.jobDetail.projectId
    );
    if (!settings) {
      this.currentModelTypeId = this.currentModelTypeId || "Vp";
    } else {
      this.showHorizonTypes = settings.horizonTypes;
      this.showHorizon = settings.showHorizon;

      this.currentModelTypeId = settings.modelTypeId;
    }
    this.restoreRangeAndPaletteForCurrentType();
    this.setChartHeight();
  }
  ngOnDestroy(): void {
    if (this.modelTypesSubscription) {
      this.modelTypesSubscription.unsubscribe();
    }
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
    if (this.updateModelShotSubscription) {
      this.updateModelShotSubscription.unsubscribe();
    }
  }
}
