<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Shot Map
            <!-- <span style="width: 30px;">
              <a class="actions__item" [matMenuTriggerFor]="menu"><i style="padding: 0 10px 0 6px;" class="zmdi zmdi-more-vert"></i></a>
            </span> -->
            <a class="graph-options" [matMenuTriggerFor]="menu"><i class="zmdi zmdi-more-vert"
              style="width: 40px;text-align:center; font-size: large;"></i></a>
          </h4>
          <mat-menu #menu="matMenu">
            <div mat-menu-item style="text-overflow:clip; height: fit-content;">
              Height
              <mat-slider [min]="250" [max]="800" step="50" (click)="$event.stopPropagation()" #ngSlider>
                <input matSliderThumb [(ngModel)]="height" (change)="updatePlot()" #ngSliderThumb="matSliderThumb" />
              </mat-slider>
            </div>
            <!-- <mat-form-field style="text-overflow:clip; height: fit-content; margin-left:5%" (click)="$event.stopPropagation()">
              <mat-label>Radius</mat-label>
              <input matInput type="number" style="border: none;" [(ngModel)]="radius" (keyup)="onKeySubmission($event)">
            </mat-form-field> -->
            <div mat-menu-item style="text-overflow:clip; height: fit-content;">
              <mat-label>Radius</mat-label>
              <mat-slider [min]="1" [max]="10" step="0.5" (click)="$event.stopPropagation()" #ngSlider>
                <input matSliderThumb [(ngModel)]="radius" (change)="updatePlot()" #ngSliderThumb="matSliderThumb" />
              </mat-slider>
            </div>
          </mat-menu>
          <div class="canvas-section row">
            <div class="graph-section col-md-9">
              <div class="graph-block">
                <div id="chartContainer" style="height:600px; margin: 0px auto;" #chart></div>
              </div>
            </div>
            <div class="graph-description  col-md-3" *ngIf="!isLoading && !noDataMessage">
              <div class="row">
                <div class="des-box blue">
                  <div class="des-icon"><img src="/assets/icons/costs/icon05.png" alt=""></div>
                  <div class="des-detail">
                    <h2>No of Shots</h2>
                    <p>{{costs.length}}</p>
                  </div>
                </div>                
              </div>
            </div>
          </div>
          <div *ngIf="isLoading" class="loading-container">
            <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner><div class="text">Loading...</div>
          </div>
          <div class="error" *ngIf="!isLoading && noDataMessage">No data available</div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Shot Runtimes
            <mat-form-field style="margin-left: 50px; height: 20px;">
              <mat-label>Shot ID</mat-label>
              <input matInput type="number" [(ngModel)]="shotId" (change)="filterShot()">
            </mat-form-field>
          </h4>
          <div class="table-section" *ngIf="!!costs && costs.length>0">
            <table>
              <thead>
                <tr>
                  <th>Shot Number</th>
                  <ng-container *ngIf="!isSimple">
                    <th>cnx</th>
                    <th>cny</th>
                    <th>cnz</th>
                  </ng-container>
                  <th>sx</th>
                  <th>sy</th>
                  <th *ngIf="!isSimple">Normalised Runtime (hrs)</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cost of filteredCosts | paginate: {itemsPerPage: 20, currentPage: page, totalItems: total }">
                  <td>{{cost.shotNo}}</td>
                  <ng-container *ngIf="!isSimple">
                    <td>{{cost.cnx}}</td>
                    <td>{{cost.cny}}</td>
                    <td>{{cost.cnz}}</td>
                  </ng-container>
                  <td>{{cost.sx}}</td>
                  <td>{{cost.sy}}</td>
                  <td *ngIf="!isSimple">{{cost.runTime}}</td>
                </tr>
              </tbody>
            </table>

            <div class="page-controls">
              <pagination-controls (pageChange)="page = $event"></pagination-controls>
            </div>
          </div>

          <div *ngIf="isLoading" class="loading-container">
            <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner><div class="text">Loading...</div>
          </div>
          <div class="error" *ngIf="!isLoading && noDataMessage">No data available</div>
        </div>
      </div>
    </div>
  </div>
</div>
