<div>
  <div class="job-info" style="width: 100%; display: block; margin-bottom: 10px;">
    <label style="display: inline-block;">Job Name:</label>
    <span *ngIf="currentJob!=null" style="position:absolute; left:20%; display:inline-block; word-break: break-all;">{{job_name}}</span>
  </div>
  <div class="job-info" style="width: 100%; display: block; margin-bottom: 10px;">
    <label  style="display: inline-block;">Job Executable:</label>
    <span *ngIf="currentJob!=null" style="position:absolute; left:20%; display: inline-block; word-break: break-all;">{{job_executable}}</span>
  </div>
  <div *ngIf="epicmod_modelprops != null && epicmod_modelprops != undefined" class="job-info" style="width: 100%; display: block; margin-bottom: 10px;">
    <label  style="display: inline-block;">EPICMod_ModelProps:</label> 
    <span style="position:absolute; left:20%;">
      <span *ngIf="currentJob!=null" style=" display: inline-block; word-break: break-all;">{{epicmod_modelprops}}</span>
      <span style="margin-left: 50px; display: inline-block; white-space: nowrap;">
        Comments:    {{epicmod_comments["EPICMod_ModelProps"].slice(0, 100)}} {{epicmod_comments["EPICMod_ModelProps"].length>100 ? '...' : ''}}
        <div class="tooltip" style="word-break: break-all; line-break: loose;" *ngIf="commentTooLong(epicmod_comments['EPICMod_ModelProps'], 100)">{{epicmod_comments["EPICMod_ModelProps"]}}</div>
      </span>
      <button class="actions__item zmdi zmdi-edit" (click)="openEpicModsCommentsDialog('EPICMod_ModelProps')"></button>  
    </span>
  </div>
  <div *ngIf="epicmod_update != null && epicmod_update != undefined" class="job-info" style="width: 100%; display: block; margin-bottom: 10px;">
    <label style="display: inline-block;">EPICMod_Update:</label>
    <span style="position:absolute; left:20%;">
      <span *ngIf="currentJob!=null" style="display: inline-block; word-break: break-all;">{{epicmod_update}}</span>
      <span style="margin-left: 50px; display: inline-block;">
        Comments:    {{epicmod_comments["EPICMod_Update"].slice(0, 100)}} {{epicmod_comments["EPICMod_Update"].length>100 ? '...' : ''}}
        <div class="tooltip" style="word-break: break-all; line-break: loose;" *ngIf="commentTooLong(epicmod_comments['EPICMod_Update'], 100)">{{epicmod_comments["EPICMOD_Update"]}}</div>
      </span>
      <button class="actions__item zmdi zmdi-edit" (click)="openEpicModsCommentsDialog('EPICMod_Update')"></button>  
    </span>
  </div>
  <div class="job-info" style="width: 100%; display: block; margin-bottom: 10px;">
    <label style="display: inline-block;">Problem:</label>
    <span style="position:absolute; left:20%;">
      <span>{{probdims}}</span>
      <span>&nbsp;{{equation}}</span>
      <span>&nbsp;{{anisotropy}}</span>
      <span>&nbsp;{{problem}}</span>
      <span>&nbsp;{{functionals}}</span>
    </span>
  </div>
  <div class="job-info" style="width: 100%; display: block; margin-bottom: 10px;">
    <label style="display: inline-block;">Scheduler Env Vars:</label>
    <div *ngFor="let envvar of envvars_wanted_scheduler_found">
      <label style="display: inline-block; margin-left: 5%;">{{envvar.replace('SCHEDULER_','')}}:</label>
      <span style="position:absolute; left:20%; display: inline-block; word-break: break-all;">{{envvars[envvar]}}</span>
    </div>
  </div>
  <div class="job-info" style="width: 100%; display: block; margin-bottom: 10px;">
    <label style="display: inline-block;">Slave Env Vars:</label>
    <div *ngFor="let envvar of envvars_wanted_slaves_found">
      <label style="display: inline-block; margin-left: 5%;">{{envvar.replace('SLAVES_','')}}:</label>
      <span style="position:absolute; left:20%; display: inline-block; word-break: break-all;">{{envvars[envvar]}}</span>
    </div>
  </div>
  <!-- <div *ngIf="envvars_wanted_other_found.length > 0" class="job-info" style="width: 100%; display: block; margin-bottom: 10px;">
    <label style="display: inline-block;">Other Env Vars:</label>
    <div *ngFor="let envvar of envvars_wanted_other_found">
      <label style="display: inline-block; margin-left: 5%;">{{envvar.replace('SLAVES_','')}}:</label>
      <span style="position:absolute; left:20%; display: inline-block; word-break: break-all;">{{envvars[envvar]}}</span>
    </div>
  </div> -->
  <div class="table-menu" style="width: 100%; display: block;">
    <button [matMenuTriggerFor]="tableSettingMenu">Options</button>
    <mat-menu #tableSettingMenu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="tableMenu">
        Select tables to be combined
      </button>
      <div mat-menu-item style="display: inline;">
        <mat-slide-toggle style="display: inline-block;" [(ngModel)]="show_combined" (change)="updateDisplayedGroups()"></mat-slide-toggle>
        <mat-label style="display: inline-block;">Show Combined</mat-label>
      </div>  
      <div mat-menu-item style="display: inline;">
        <mat-slide-toggle style="display: inline-block;" [(ngModel)]="show_collapsed" (change)="updateDisplayedGroups()"></mat-slide-toggle>
        <mat-label style="display: inline-block;">Show Collapsed Columns</mat-label>
      </div>
      <button *ngIf="epicmod_update != null && epicmod_update != undefined || epicmod_modelprops != null && epicmod_modelprops != undefined" mat-menu-item (click)="refreshEpicModsComments()">Refresh EpicMod Comments</button>
    </mat-menu>
    <mat-menu #tableMenu="matMenu">
      <button mat-menu-item *ngFor="let table of tables" (click)="$event.stopPropagation(); toggleTable(table)">
        <span>{{table}}</span>
        <a *ngIf="tables_combined[table]"><i class="zmdi zmdi-check"></i></a>
      </button>
    </mat-menu>
  </div>
  
  <div *ngIf="!show_combined" class="tables">
    <div class="table-container" *ngFor="let table of tables; let i = index" [style.max-height]="getTableHeight(table)" [style.min-height]="default_tableHeight" style="overflow-y:auto; margin-top: 25px">
      <div class="table-label-container">
        <a><i class="zmdi zmdi-more-vert" [matMenuTriggerFor]="perTableMenu"></i></a>
        <mat-label class="table-label">{{table_labels[table]}}</mat-label>
        <mat-menu #perTableMenu="matMenu">
          <button mat-menu-item (click)="$event.stopPropagation(); toggleTableHeight(table)">
            <span>{{getTableHeight(table) == default_tableHeight ? 'Expand' : 'Collapse'}}</span>
          </button>
        </mat-menu>
      </div>
      <div class="table">
        <table>
          <thead class="table-headers">
            <tr *ngIf="getDisplayedGroups(table).length > 0" class="group-row">
              <th class="sticky-group-cell" [attr.colspan]="getDisplayedGroups(table)[0].colspan">
                {{getDisplayedGroups(table)[0].name}}
              </th>
              <th class="group-cell" *ngFor="let group of getDisplayedGroups(table).slice(1)" [attr.colspan]="group.colspan">
                {{group.name}}
              </th>
            </tr>
            <tr>
              <th class="Iterblk sticky-header-column">
                Iterblk
              </th>
              <th class="Functional sticky-header-column" >
                Functional
              </th>
              <th class="RWIType sticky-header-column">
                RWI Type
              </th>
              <th class="Frequency sticky-header-column">
                Frequency
              </th>
              <th class="Iters sticky-header-column">
                Iters
              </th>
              <th class="header-column" *ngFor="let column of getDisplayedColumns(table)" style="white-space: nowrap;" >
                {{getMappedName(table,column)}}
                <div class="tooltip">{{getFullName(table, column)}}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let entry of getData(table)" class="table-row">
              <td class="sticky-column Iterblk">
                {{entry['Iterblk']}}
              </td>
              <td class="sticky-column Functional">
                {{entry['Functional']}}
              </td>
              <td class="sticky-column RWIType">
                {{entry['RWI Type']}}
              </td>
              <td class="sticky-column Frequency">
                {{entry['Frequency']}}
              </td>
              <td class="sticky-column Iters">
                {{entry['Iterations']}}
              </td>

              <td *ngFor="let column of getDisplayedColumns(table)">
                {{entry[column]}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="show_combined" class="tables">
    <div class="table-container" style="overflow-y:auto">
      <div class="table-label-container">
        <mat-label class="table-label">Combined Table</mat-label>
      </div>
      <div class="table">
        <table>
          <thead class="table-headers">
            <tr *ngIf="getDisplayedGroups('combined').length > 0" class="group-row">
              <th class="sticky-group-cell" [attr.colspan]="getDisplayedGroups('combined')[0].colspan">
                {{getDisplayedGroups('combined')[0].name}}
              </th>
              <th class="group-cell" *ngFor="let group of getDisplayedGroups('combined').slice(1)" [attr.colspan]="group.colspan">
                {{group.name}}
              </th>
            </tr>
            <tr>
              <th class="Iterblk sticky-header-column">
                Iterblk
              </th>
              <th class="Functional sticky-header-column" >
                Functional
              </th>
              <th class="RWIType sticky-header-column">
                RWI Type
              </th>
              <th class="Frequency sticky-header-column">
                Frequency
              </th>
              <th class="Iters sticky-header-column">
                Iters
              </th>
              <th class="header-column" *ngFor="let column of getDisplayedColumns('combined')" style="white-space: nowrap;" >
                {{getMappedName('combined',column)}}
                <div class="tooltip">{{getFullName('combined', column)}}</div>
              </th>            
            </tr>
          </thead>
            <tr *ngFor="let entry of getData('combined')" class="table-row">
              <td class="sticky-column Iterblk">
                {{entry['Iterblk']}}
              </td>
              <td class="sticky-column Functional">
                {{entry['Functional']}}
              </td>
              <td class="sticky-column RWIType">
                {{entry['RWI Type']}}
              </td>
              <td class="sticky-column Frequency">
                {{entry['Frequency']}}
              </td>
              <td class="sticky-column Iters">
                {{entry['Iterations']}}
              </td>

              <td class="cell" *ngFor="let column of getDisplayedColumns('combined')">
                {{entry[column]}}
              </td>
            </tr>
          <tbody>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>