import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { CachedUserService } from '../../shared/services/cached-user.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.less']
})
export class NavBarComponent implements OnInit {
    isActive: boolean = false;
    showFull: boolean = false;
    isBillingUser: boolean = false;

  constructor(public auth: AuthService, private userService: CachedUserService) { }

  ngOnInit() {
    // console.log(this.appVersion)
    this.auth.isLoggedIn.subscribe(result => {
      if (!result) {
        this.isBillingUser = false;
        return;
      }

      this.userService.isGroupAdmin().subscribe(result => {
        this.isBillingUser = !!result;
      });
    });
  }

  expandMenu(event) {
      this.isActive = !this.isActive;
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
      this.showFull = window.pageYOffset > 10;
  }
}
