<h3 mat-dialog-title>Warning</h3>

<div style="height: 75%; display: flex; flex-direction: column; justify-content: space-between;">
    <div>
        This is going to rebuild the table for <br>
        <strong>{{project_name}}</strong> <br>
        by parsing the latest checkpoint runfiles for each job. <br>
        The process may take a while. <br>
        Do you confirm this action?
    </div>
    <div style="display: flex; justify-content: space-around; ">
        <button mat-raised-button color="primary" type="submit" (click)="submit()">Confirm</button>
        <button mat-raised-button color="basic" type="reset" (click)="cancel()">Cancel</button>
    </div>
</div>