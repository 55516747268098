import { Component, OnInit } from '@angular/core';
import { AuthService } from "../shared/services/auth.service";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  returnUrl: string = '/projects';

  constructor(public auth: AuthService, private router: Router, private route: ActivatedRoute) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/projects';

    if (!this.auth.isAuthenticated()) {
      this.auth.login(this.returnUrl);
    }
  }

  ngOnInit() {
   
  }
}

