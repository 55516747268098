import {
    Component,
    OnInit,
    AfterViewInit,
    ElementRef,
    ViewChild,
    Input,
    SimpleChanges,
    OnChanges
} from '@angular/core';

import {Ranges} from '../../models/ranges'

declare var Plotly: any;

@Component({
  selector: 'app-plotly',
  templateUrl: './plotly.component.html',
  styleUrls: ['./plotly.component.css']
})
export class PlotlyComponent implements OnInit, AfterViewInit, OnChanges  {
    @ViewChild('chart', { static: true }) chart: ElementRef;
    @Input() crosslineNumber: number;
    @Input() inlineNumber: number;
    @Input() depthSlice: number;
    @Input() ranges: Ranges;
    @Input() is2d: boolean;
  private _hasDrawn: boolean = false;
  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {
      this.updatePlot();
  }

  ngOnChanges(changes: SimpleChanges) {
      this.updatePlot();
  }

  private updatePlot() {
      if (!this.ranges)
          return;

    var gl = document.createElement('canvas').getContext('webgl2', {willReadFrequently: true});
    if (!gl) {
      return;
    }

    var maxZ = Math.floor(this.ranges.z[1] / 50) * 50;
    var factor = Math.round(this.ranges.z[1] / 160) * 10;
    if (maxZ + factor > this.ranges.z[1])
      maxZ -= factor;

      var layout = {
          yaxis: {
            range: [this.ranges.x[0], this.ranges.x[1]],
            fixedrange: true
          },
          xaxis: {
            range: [this.ranges.y[0], this.ranges.y[1]],
            fixedrange: true
          },          
          zaxis: {
            range: [-this.ranges.z[1], -this.ranges.z[0]],
            fixedrange: true
          },
          scene: {
            aspectratio: {
              y: this.ranges.y[1] > this.ranges.x[1] ? 0.8 : 1,
              x: this.ranges.y[1] > this.ranges.x[1] ? 1 : 0.8,
              z: 0.6
            },
            camera: {
              center:{ x: -0.06676134404916242, y: -0.05883894604540139, z: 0 },
              eye:{ x: 1.5516523458323244, y: -1.3921331095173386, z: 0.7577841310619388 }
            },
            yaxis: {
              title: 'crossline',
              titlefont: {
                size: 10
              },
              nticks: 4,
              tickfont: {
                size: 10
              }
            },
            xaxis: {
              title: 'inline',
              titlefont: {
                size: 10
              },
              nticks: 4,
              tickfont: {
                size: 10
              }
            },
            zaxis: {
              title: 'depth',
              titlefont: {
                size: 10
              },              
              tickfont: {
                size: 10
              },
              tickvals: [0, -maxZ / 2, -maxZ, -this.ranges.z[1]],
              ticktext: [0, maxZ / 2, maxZ,'']
            },
          },
          margin: {
              l: 50,
              r: 50,
              b: 0,
              t: 0,
              pad: 2
          },
          height: 250,
          width: 420
      };

      var options = {
        scrollZoom: false,
        showLink: false,
        displayModeBar: false
      };

      var data = [
          {
              opacity: 0.6,
              color: 'rgb(227,87,87)',
              type: 'mesh3d',
              scene: 'scene',
              y: [this.ranges.x[0], this.ranges.x[1], this.ranges.x[1], this.ranges.x[0]],
              x: [this.ranges.y[0], this.ranges.y[0], this.ranges.y[1], this.ranges.y[1]],
              z: [-this.depthSlice, -this.depthSlice, -this.depthSlice, -this.depthSlice]
          },
          {
              opacity: 0.6,
              color: 'rgb(105,172,247)',
              type: 'mesh3d',
              scene: 'scene',
              y: [this.ranges.x[0], this.ranges.x[1], this.ranges.x[1], this.ranges.x[0]],
              x: [this.inlineNumber, this.inlineNumber, this.inlineNumber - 0.001, this.inlineNumber - 0.001],
              z: [-this.ranges.z[0], -this.ranges.z[0], -this.ranges.z[1], -this.ranges.z[1]]
          },
          {
              opacity: 0.6,
              color: 'rgb(230,106,238)',
              type: 'mesh3d',
              scene: 'scene',
              y: [this.crosslineNumber, this.crosslineNumber, this.crosslineNumber - 0.001, this.crosslineNumber - 0.001],
              x: [this.ranges.y[0], this.ranges.y[1], this.ranges.y[1], this.ranges.y[0]],
              z: [-this.ranges.z[0], -this.ranges.z[0], -this.ranges.z[1], -this.ranges.z[1]]
          }
      ];

    if (!this._hasDrawn) {
      Plotly.newPlot(this.chart.nativeElement, data, layout, options);
      this._hasDrawn = true;
    } else {
      Plotly.react(this.chart.nativeElement, data, layout);
    }
  }
}
