<h3 mat-dialog-title>Project Settings</h3>

<form class="form-container" (ngSubmit)="submitForm()">
  <!-- <mat-dialog-content> -->
  <nz-input-group class="legacy-input-styling">
    <label for="name">Project Name</label>
    <input
      nz-input
      placeholder="Project Name"
      required
      [(ngModel)]="data.name"
      id="name"
      name="name"
    />
  </nz-input-group>

  <nz-input-group class="legacy-input-styling"
    ><label for="description">Project Description</label>
    <textarea
      nz-input
      placeholder="Project Description"
      [nzAutosize]="{ minRows: 3 }"
      [(ngModel)]="data.description"
      id="description"
      name="description"
    ></textarea>
  </nz-input-group>
  <!-- <mat-form-field>
      <input
        matInput
        placeholder="Project Name"
        required
        [(ngModel)]="data.name"
        name="name"
      />
    </mat-form-field> -->

  <!-- <mat-form-field>
      <textarea
        matInput
        placeholder="Project Description"
        rows="3"
        [(ngModel)]="data.description"
        name="description"
      ></textarea>
    </mat-form-field> -->
  <!-- </mat-dialog-content> -->
  <mat-dialog-actions class="button-container">
    <button mat-raised-button color="primary" type="submit">
      {{ data.id ? "Update" : "Create" }}
    </button>
    <button mat-raised-button mat-dialog-close color="basic" type="reset">
      Cancel
    </button>
  </mat-dialog-actions>
</form>
