<div class="container-fluid">
  <div
    style="
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 10px;
    "
  >
    <div
      echarts
      [options]="options"
      [loading]="this.loadingChart"
      style="
        height: 70vh;
        flex: 1 1 75%;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
      "
      class="demo-chart"
      (chartInit)="chartInit($event)"
    ></div>
    <div
      style="
        background-color: white;
        border-radius: 5px;
        width: 300px;
        padding: 10px;
      "
    >
      <div>
        Y-Axis Limit
        <nz-slider
          nzRange
          nzMin="0"
          nzMax="100"
          [(ngModel)]="ylim"
          (nzOnAfterChange)="onYlimChanged($event)"
        ></nz-slider>
      </div>
      <div>
        X-Axis Limit
        <nz-slider
          nzRange
          [nzMin]="xMin"
          [nzMax]="xMax"
          [(ngModel)]="xlim"
          (nzOnAfterChange)="onXlimChanged($event)"
        ></nz-slider>
      </div>
    </div>
  </div>
</div>
