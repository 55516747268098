import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { of } from "rxjs";

import { Project } from "../../models/project";
import { AuthService } from "../../shared/services/auth.service";
import { JobDetail } from "../../models/jobDetail";
import { PlotData } from "../../models/plotData";
import { PlotDetail } from "../../models/plotDetail";
import { SliceType } from "../../models/sliceType";
import { HttpService } from "../../shared/services/http.service";
import { Cost } from "../../models/cost";
import { Coordinate } from "../../models/coordinate";
import { AppConfig } from "../../app.config";
import { ProjectDialog } from "../../models/projectDialog";
import { JobDialog } from "../../models/jobDialog";
import { JobDetailDialog } from "../../models/jobDetailDialog";
import { JobStatusDialog } from "../../models/jobStatusDialog";
import { ShotOverlayDto } from "../../models/shotOverlayDto";
import { ModelType } from "../../models/modelType";
import { ShotOverlayType } from "../../models/shotOverlayType";
import { RunfileParam } from "../../models/runfileParamType";
import { inflate } from "pako";
import { Buffer } from "buffer";
import {
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";

@Injectable()
export class ProjectService {
  constructor(private http: HttpService, private authService: AuthService) {}

  getProjects(): Observable<Project[]> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/projects/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;
          let data = response.results;
          var projects = data
            .map((d) => {
              return {
                id: d.id,
                name: d.name,
                description: d.description,
                jobs: d.jobs
                  .map((j) => {
                    return {
                      id: j.id,
                      name: j.job_name,
                      createdDate: new Date(j.created),
                      status: j.status,
                      minVelocity: null,
                      maxVelocity: null,
                      minVelocityZ: null,
                      maxVelocityZ: null,
                      parent_job_id: Object.keys(j).includes("parent_job_id")
                        ? j.parent_job_id
                        : null,
                      comments:
                        j.comments != null
                          ? j.comments.length == 0
                            ? "No comments"
                            : j.comments
                          : "No comments",
                    };
                  })
                  .sort((a, b) => {
                    return a.name
                      .toUpperCase()
                      .localeCompare(b.name.toUpperCase());
                  }),
              };
            })
            .sort((a, b) => {
              return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
            });

          return projects.map((project) => this.setProjectDefaults(project));
        })
      );
  }

  getProjectById(id: string): Observable<Project> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/projects/${id}/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;

          let d = response;
          var project = {
            id: d.id,
            name: d.name,
            description: d.description,
            jobs: d.jobs
              .map((j) => {
                return {
                  id: j.id,
                  name: j.job_name,
                  createdDate: new Date(j.created),
                  status: j.status,
                  minVelocity: null,
                  maxVelocity: null,
                  minVelocityZ: null,
                  maxVelocityZ: null,
                  parent_job_id: Object.keys(j).includes("parent_job_id")
                    ? j.parent_job_id
                    : null,
                };
              })
              .sort((a, b) => {
                return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
              }),
          };

          return this.setProjectDefaults(project);
        })
      );
  }

  getProjectTable(id: string, rebuild = false, ids = []): Observable<any> {
    let params = new URLSearchParams();
    params.set("rebuild", rebuild.toString());
    if (ids.length > 0) {
      params.set("ids", ids.join(","));
    }
    // console.log(`${AppConfig.settings.apiUrl}/projects/${id}/job_types/?${params.toString()}`)
    return this.http
      .get(
        `${
          AppConfig.settings.apiUrl
        }/projects/${id}/job_types/?${params.toString()}`,
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response) {
            console.error("getProjectTable: empty response");
            return;
          }

          let d = response;
          return d;
        })
      );
  }

  getParameterTable(id: string, rebuild = false, ids = []): Observable<any> {
    let params = new URLSearchParams();
    params.set("rebuild", rebuild.toString());
    if (ids.length > 0) {
      params.set("ids", ids.join(","));
    }
    // console.log(`${AppConfig.settings.apiUrl}/projects/${id}/job_types/?${params.toString()}`)
    return this.http
      .get(
        `${
          AppConfig.settings.apiUrl
        }/projects/${id}/parameter_table/?${params.toString()}`,
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response) {
            console.error("getParameterTable: empty response");
            return;
          }

          let d = response;
          return d;
        })
      );
  }

  getProjectNetwork(id: string, FM = false): Observable<any> {
    let params = new URLSearchParams();
    params.set("FM", FM.toString());
    // console.log(`${AppConfig.settings.apiUrl}/projects/${id}/job_types/?${params.toString()}`)
    return this.http
      .get(
        `${
          AppConfig.settings.apiUrl
        }/projects/${id}/flowchart/?${params.toString()}`,
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response) {
            console.error("getParameterTable: empty response");
            return;
          }

          let d = response;
          return d;
        })
      );
  }

  getProjectTableCSV(id: string): Observable<any> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/projects/${id}/job_types/csv/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) {
            console.error("getProjectTableCSV: empty response");
            return;
          }

          let d = response;
          return d;
        })
      );
  }

  getJobDetail(id: string): Observable<JobDetail> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;

          let j = response;
          // if (j['parent_job_id'] != null) {
          //   console.log("job id:", j.id)
          //   console.log("parent job id:", j['parent_job_id'])
          // }
          var jobDetail = {
            projectId: j.project.id,
            projectName: j.project.name,
            projectDescription: j.project.description,
            id: j.id,
            name: j.job_name,
            createdDate: new Date(j.created),
            status: j.status,
            basePath: j.job_basepath,
            comments: j.comments,
            prefix: j.job_prefix,
            lastParamModified: new Date(j.last_param_modified),
            lastStatusModified: new Date(j.last_status_modified),
            lastErrorStatus: j.last_error_status,
            started: j.started,
            last_used_palette: j.last_used_palette,
            modelGrid: {
              id: j.model_grid.id,
              name: j.model_grid.name,
              description: j.model_grid.description,
              unit: j.model_grid.unit,
              dx: j.model_grid.dx,
              is2d: j.model_grid.is_2d,
              // TODO: apparently nx1 and nx2 are flipped in most "typical" models
              // need to investivate inline/crossline mapping to nx1/nx2 throughout the code
              // to clear up inconsistencies
              nx1: j.x2_is_inline ? j.model_grid.nx1 : j.model_grid.nx2,
              nx2: j.x2_is_inline ? j.model_grid.nx2 : j.model_grid.nx1,
              nx3: j.model_grid.nx3,
            },
            padding: {
              id: j.model_padding.id,
              top: j.model_padding.top,
              left: j.model_padding.left,
              right: j.model_padding.right,
              bottom: j.model_padding.bottom,
              front: j.model_padding.front,
              back: j.model_padding.back,
            },
            useInterblocks: j.use_iterblocks,
            iterations: j.iterations,
            iterationsComplete: j.iterations_completed,
            defaultNx1: null,
            defaultNx2: null,
            defaultNx3: null,
            containsLog: j.project.well_logs && j.project.well_logs.length > 0,
            blockNumber: null,
            frequency: null,
            x2_is_inline: j.x2_is_inline,
            startTime: !!j.last_started
              ? new Date(j.last_started.split("+")[0] + ".000Z")
              : null,
            runTime: j.cumulated_runtime_sec,
            wellLogs: j.project.well_logs.map((i) => {
              return {
                id: i.id,
                name: i.name,
                inline: i.inline_number,
                crossline: i.crossline_number,
              };
            }),
            tag: j.tag,
            parent_job_id: j.parent_job_id,
            model_code: j.model_code,
            parent_cp_num: j.parent_cp_num,
            epicmod_comments: Object.keys(j).includes("epicmod_comments")
              ? j.epicmod_comments
              : JSON.stringify({
                  EPICMod_ModelProps: "",
                  EPICMod_Update: "",
                }),
            keep_job: j.keep_job,
            storage_cost: j.storage_cost,
            job_type: j.job_type,
            soft_archive_status: j.soft_archive_status,
            functional_sequence: j.functional_sequence,
            offshoot: j.offshoot,
          };

          this.setModelDefaults(jobDetail);
          return jobDetail;
        })
      );
  }

  getJobIterationsCompleted(id: string): Observable<number> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((res) => {
          if (!res) {
            return null;
          }
          res = res;
          return res.iterations_completed;
        })
      );
  }

  getJobMeta(id: string): Observable<any> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/meta/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;
          return response;
        })
      );
  }

  getRunfile(id: string, iteration: number = 0): Observable<any> {
    let headers = this.getAuthorizationHeader();
    let params = new HttpParams();
    if (!!iteration) {
      params = params.append("iteration", iteration.toString());
    }
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/runfile/`, {
        headers,
        params,
      })
      .pipe(
        map((response) => {
          if (!response) return;
          return response;
        }),
        catchError((error) => of(null))
      );
  }

  getRunfileObject(id: string, keywords: string[] = null): Observable<any> {
    let headers = this.getAuthorizationHeader();
    let query_str = "";
    if (keywords.length > 0) {
      let params = new URLSearchParams();
      params.set("keywords", keywords.join(","));
      query_str = "?" + params.toString();
    }

    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/jobs/${id}/runfile_object/${query_str}`,
        { headers }
      )
      .pipe(
        map((response) => {
          if (!response) return null;
          return response;
        }),
        catchError((error) => of(null))
      );
  }

  getPlotData(id: string): Observable<PlotData> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/plot_data/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response: any) => {
          if (response) {
            try {
              if (!response) {
                console.log("response has empty data");
                return;
              }
            } catch (e) {
              //response has empty data
              console.log("response has empty data");
              return;
            }
          }
          let j = response;
          if (j.cpnums.length <= 0) return;

          if (Object.keys(j).includes("cpnums_with_data")) {
            let cpnums_with_data = j.cpnums_with_data;
            delete j["cpnums_with_data"];
            if (cpnums_with_data.length < j.cpnums.length) {
              for (let cpnum of j.cpnums) {
                if (!cpnums_with_data.includes(cpnum)) {
                  j.fnl1.splice(cpnum - 1, 0, null);
                  j.stepfct.splice(cpnum - 1, 0, null);
                }
              }
            }
          }
          let plotData: PlotData = {
            cpnums: j.cpnums,
            fnl1: { data: j.fnl1 },
            stepfct: { data: j.stepfct },
          };
          delete j["cpnums"];
          delete j["fnl1"];
          delete j["stepfct"];
          if (j.stephis) {
            let sortedStepHists = [];
            for (let _i in plotData.cpnums) {
              let iteration = plotData.cpnums[_i];
              let i = parseInt(_i);
              let steps = j.stephis[iteration];
              if (!steps) {
                steps = [[], []];
              }
              steps[0].splice(0, 0, 0);
              steps[1].splice(0, 0, plotData.fnl1.data[i]);
              steps[2] = plotData.stepfct.data[i];
              sortedStepHists.push(steps);
            }
            plotData.step_history = sortedStepHists;
            delete j["stephis"];
          }
          let trace_fit = j.traces_fit || [];
          // there wouldn't be nulls in the cpnums array, but there could be nulls in the traces_fit arrays
          // so the length of tracefit arrays is bound to be greater or equal to the length of cpnums array
          if (
            trace_fit.length == 2 &&
            trace_fit[0].length >= plotData.cpnums.length
          ) {
            plotData.traces_fits = [];
            for (let key in j) {
              if (
                key === "norm_ratio" ||
                key === "fitting_factor" ||
                !j[key][0].some((d) => !!d)
              ) {
                continue;
              }
              let name = key.split("_").join(" ").replace("dc", "traces fit");
              // since the traces_fit array might be longer than the cpnums array, we need to filter out the nulls
              let data = j[key][0];
              let std = j[key][1];
              plotData.traces_fits.push({ name: name, data: data, std: std });
            }
          }
          return plotData;
        }),
        catchError((error) => {
          if (error.status == 404) {
            return of(null);
          } else {
            throw error;
          }
        })
      );
  }

  getMatchedJobs(
    rules,
    page: number = 1,
    sort: string = "_score"
  ): Observable<any> {
    // let requestOptions = new HttpHeaders();
    // requestOptions.append(
    //   "Authorization",
    //   this.authService.getTokenHeaderText()
    // );
    rules["page"] = page;
    rules["sort"] = sort;
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/params_search/`, {
        params: rules,
        headers: this.getAuthorizationHeader(),
        responseType: "json",
      })
      .pipe(
        map((response) => {
          if (!response) return;
          let searchResult = response;
          return searchResult;
        })
      );
  }

  getSearchResults(rules): Observable<any> {
    // let requestOptions = new Headers();
    // requestOptions.append(
    //   "Authorization",
    //   this.authService.getTokenHeaderText()
    // );
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/search_groups/`, {
        params: rules,
        headers: this.getAuthorizationHeader(),
        responseType: "json",
      })
      .pipe(
        map((response) => {
          if (!response) return;
          let searchResult = response;
          return searchResult.results;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  getMultipleJobs(jobIds): Observable<any> {
    return this.http
      .post(
        `${AppConfig.settings.apiUrl}/jobs/group/`,
        { jobs: jobIds },
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response) return;
          let dataList = response;
          return dataList.group_id;
        })
      );
  }

  getGroupData(jobs: any): Observable<any> {
    return this.http
      .post(
        `${AppConfig.settings.apiUrl}/jobs/group/`,
        { jobs: jobs },
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response) return;
          return response;
        })
      );
  }

  getGroupParams(jobs: any, keywords: any): Observable<any> {
    return this.http
      .post(
        `${AppConfig.settings.apiUrl}/jobs/group_params/`,
        { jobs: jobs, keywords: keywords },
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response) return;
          return response.params;
        })
      );
  }

  createSearchResultGroup(
    rule: any,
    jobIds: any,
    comment: string,
    name: string
  ): Observable<any> {
    return this.http
      .post(
        `${AppConfig.settings.apiUrl}/jobs/search_groups/`,
        { rule: rule, jobs: jobIds, comment: comment, name: name },
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response || response) return;
          return response.group;
        }),
        catchError((error) => observableThrowError(error))
      );
  }

  updateSearchResultGroup(
    groupId: any,
    comments: string,
    name: string
  ): Observable<any> {
    let data = { commetns: comments, name: name };
    return this.http
      .patch(
        `${AppConfig.settings.apiUrl}/jobs/search_groups/${groupId}/`,
        data,
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response) return;
          return groupId;
        })
      );
  }

  getModelTypes(jobId: string, iteration: number): Observable<ModelType[]> {
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/jobs/${jobId}/plot/types_available/${iteration}/`,
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response) return;
          let data = response.types_available;
          let types_with_processing = response.type_pre_plot_processings;

          return Object.keys(types_with_processing).map((d) => {
            return {
              id: d,
              name: d,
              processing: types_with_processing[d],
            };
          });
        })
      );
  }

  private setProjectDefaults(project: Project): Project {
    if (project.id == "8a298703-d84f-48ca-aa23-100069ef7407") {
      project.minVelocity = 1500;
      project.maxVelocity = 3800;
    }

    if (project.id == "4ad49fe5-a624-4a3e-91e8-19fe8d4267ba7") {
      project.minVelocity = 1500;
      project.maxVelocity = 4500;
    }

    return project;
  }

  private setModelDefaults(job: JobDetail) {
    var janz = [
      "e64823c8-ee5c-4e9c-afce-044cf7e8cb1a",
      "db5b0dc1-d70d-49d6-81ce-988d9b9e2509",
      "509a95f9-a881-4dd1-be4e-8463f1953d53",
    ];
    if (janz.find((id) => job.id == id)) {
      job.defaultNx1 = 80;
      job.defaultNx2 = 448;
      job.defaultNx3 = 92;

      job.blockNumber = [1, 2, 3, 4, 5, 6, 7, 8];
      job.frequency = [4, 5, 6, 8, 10, 12, 14, 17];
      return;
    }
    var seg = [
      "1ef2c0f0-6da7-4d59-849f-b5b8c2370283",
      "67943a66-8b59-4f19-84cf-00a1052cfc92",
      "cb99daaf-dea5-4ce4-a34e-69189423ca11",
    ];
    if (seg.find((id) => job.id == id)) {
      job.blockNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      job.frequency = [3.0, 3.3, 3.6, 4.0, 4.5, 5.2, 6.1, 7.3, 8.6, 10.0];

      if (job.id == "cb99daaf-dea5-4ce4-a34e-69189423ca11") {
        job.blockNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        job.frequency = [
          10, 11, 12.1, 13.3, 14.6, 16.1, 17.1, 19.5, 21.4, 23.6,
        ];
      }
    }

    job.defaultNx1 = Math.floor(job.modelGrid.nx1 / 2);
    job.defaultNx2 = Math.floor(job.modelGrid.nx2 / 2);
    job.defaultNx3 = Math.floor(job.modelGrid.nx3 / 2);
  }

  getSlice(
    id: string,
    modelType: string,
    iteration: number,
    sliceType: SliceType,
    sliceNumber: number,
    is2D: boolean,
    palette: string = null,
    minVelocity: number = null,
    maxVelocity: number = null,
    shotNumber: number = null,
    fwd: number = null,
    vpRef: number = null,
    timeSlice: number = null
  ): Observable<PlotDetail> {
    var queryString = "";
    if (palette) queryString += `palette=${palette}`;

    if (minVelocity && maxVelocity) {
      if (queryString) queryString += "&";

      queryString += `min_val=${minVelocity}&max_val=${maxVelocity}`;
    }

    if (modelType) {
      if (modelType == "Delta" || modelType == "Epsilon") iteration = 0;

      if (queryString) queryString += "&";

      queryString += `type=${modelType}`;
    }

    if (shotNumber) {
      if (queryString) queryString += "&";

      queryString += `shot_id=${shotNumber}`;
    }

    if (timeSlice) {
      if (queryString) queryString += "&";

      queryString += `time_slice=${timeSlice}`;
    }

    if (fwd) {
      if (queryString) queryString += "&";

      queryString += `fwd=${fwd}`;
    }

    if (!is2D) {
      if (queryString) queryString += "&";

      queryString += `slice_dim=${sliceType}&slice_number=${sliceNumber}`;
    }

    if (modelType == "Total_Vp_Update" && vpRef) {
      if (queryString) queryString += "&";

      queryString += `vp_ref=${vpRef}`;
    }

    var url = `${AppConfig.settings.apiUrl}/jobs/${id}/plot/${iteration}/`;

    return this.http
      .get(url + "?" + queryString, { headers: this.getAuthorizationHeader() })
      .pipe(
        map((response) => {
          if (!response) return;

          let p = response;
          return {
            url: p.png_plot_url,
            colorBarUrl: p.png_colorbar_url,
            min: p.min_value,
            max: p.max_value,
            sliceUrl: p.model_slice_url,
            trueMin: p.true_min_value,
            trueMax: p.true_max_value,
          };
        }),
        catchError((err) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }

  getSliceArray(
    url: string,
    width: number,
    height: number,
    min: number,
    max: number
  ): Observable<Array<Array<number>>> {
    return this.http
      .get(url, {
        headers: this.getAuthorizationHeader(),
        responseType: "arraybuffer",
      })
      .pipe(
        map((res: ArrayBuffer) => {
          var arrayBuf = res;
          var int16Array = new Uint16Array(
            arrayBuf,
            0,
            Math.floor(arrayBuf.byteLength / 2)
          );
          var precision = 100;
          if (max < 1 && max > 0) {
            precision = 1000;
            let val = max;
            do {
              val = val * 10;
              precision = precision * 10;
            } while (val < 1);
          }
          var array = new Array();
          for (var i = 0; i < height; i++) {
            array.push(
              Array.prototype.slice
                .call(int16Array.slice(i * width, (i + 1) * width))
                .map(
                  (d) =>
                    Math.round(precision * ((d / 65535) * (max - min) + min)) /
                    precision
                )
            );
          }
          return array;
        }),
        catchError((err: HttpErrorResponse) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }

  getRanges(
    id: string,
    modelType: string,
    iteration: number,
    sliceParams: object,
    shot: number = null,
    traceSlice: number = null
  ) {
    let headers = this.getAuthorizationHeader();
    let params = new HttpParams();
    // header.params = sliceParams;
    // header.params["type"] = modelType;
    // if (shot) header.params["shot_id"] = shot;
    // if (traceSlice) header.params["trace_slice"] = traceSlice;
    for (let [key, val] of Object.entries(sliceParams)) {
      params = params.append(key, val.toString());
    }
    params = params.append("type", modelType.toString());
    if (shot) params = params.append("shot_id", shot.toString());
    if (traceSlice)
      params = params.append("trace_slice", traceSlice.toString());
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/jobs/${id}/plot/${iteration}/ranges/`,
        { headers, params }
      )
      .pipe(
        map((response) => {
          if (!response) return;
          return response;
        }),
        catchError((error) => {
          return observableThrowError(error);
        })
      );
  }

  getSonicLog(id: string): Observable<Array<Coordinate>> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/well-logs/${id}/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;
          console.log("get sonic log project service", response);
          return response.map((d) => {
            return { x: d.z, y: d.v };
          });
        }),
        catchError((err) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }

  getLog(id: string): Observable<string> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/log/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;

          return response.toString();
        })
      );
  }

  getCosts(
    id: string,
    totalIterations: number,
    switched: boolean = false
  ): Observable<Cost> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/detail/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;

          let data = response;
          if (!data.shot_info || data.shot_info.length == 0) return null;

          var costItems = data.shot_info
            .sort((a, b) => b.c_total - a.c_total)
            .map((d) => {
              var iterations =
                (data.shots_per_iter * totalIterations) / data.total_shots;
              var runTime = Math.max(0.02, (d.c_total / 9229212) * 3 * 0.057);

              return {
                shotNo: d.shot_id,
                cnx: d.cnx,
                cny: d.cny,
                cnz: d.cnz,
                sx: switched ? d.sy : d.sx,
                sy: switched ? d.sx : d.sy,
                sz: d.sz,
                timeSamples: data.time_steps,
                runTime: Math.round(runTime * 1000) / 1000,
                iterations: Math.ceil(iterations),
                nodeHours: iterations * runTime,
                cost: iterations * runTime * 0.55,
              };
            });

          return {
            formulationType: data.formulation_type,
            shotsPerIteration: data.shots_per_iter,
            timeSample: data.time_sample,
            timeSteps: data.time_steps,
            totalBlocks: data.total_blocks,
            totalShots: data.total_shots,
            costItems: costItems,
            vmax: data.vmax,
            type: data.type,
          };
        })
      );
  }

  getHorizonTypes(id: string): Observable<string[]> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/horizon/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return null;

          var data = response;
          if (!data || !data.horizons_available) return null;

          return data.horizons_available.sort();
        })
      );
  }

  getHorizon(
    id: string,
    sliceNumber: number,
    sliceType: SliceType,
    horizonName: string
  ): Observable<number[]> {
    // horizonName should be encoded using URLSearchParams such that any special character will be encoded
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/jobs/${id}/horizon/?${horizonName}&slice_dim=${sliceType}&slice_number=${sliceNumber}`,
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return;
          return response;
        })
      );
  }

  getShotOverlay(id: string, iteration: number): Observable<ShotOverlayDto[]> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/shot_fit/${iteration}/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;
          return response.shot_fit_info;
        })
      );
  }

  getFnlcRanges(id: string): Observable<any> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/fnlc_range/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response || !response.json) return;
          let range = response;
          return { min_val: range.min_val, max_val: range.max_val };
        })
      );
  }

  getShotOverlayTypes(
    id: string,
    iteration: number = 1
  ): Observable<ShotOverlayType[]> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/shot_fit/${iteration}/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;
          var types = [
            { id: "dc_low", name: "Traces Fit - Low", min: 0, max: 100 },
            { id: "dc_raw", name: "Traces Fit - Raw", min: 0, max: 100 },
            { id: "traces_fit", name: "Traces Fit", min: 0, max: 100 },
            { id: "traces_fit", name: "Traces Fit", min: 0, max: 100 },
            { id: "norm_ratio", name: "Norm Ratio", min: 0, max: 1 },
            { id: "fitting_factor", name: "Fitting Factor", min: 0, max: 1 },
          ];
          var fitTypes = response.fit_types;
          if (!fitTypes) return null;

          return Object.keys(fitTypes).map((d) => {
            return {
              id: d,
              name: fitTypes[d],
              min: types.find((t) => t.id === d)
                ? types.find((t) => t.id === d).min
                : null,
              max: types.find((t) => t.id === d)
                ? types.find((t) => t.id === d).max
                : null,
            };
          });
        })
      );
  }

  getShotsAvailableForModel(
    id: string,
    iteration: number,
    modelType: string = null,
    timeSlice: number = null
  ): Observable<number[]> {
    let headers = this.getAuthorizationHeader();
    let params = new HttpParams();

    params = params.append("iteration", iteration.toString());
    // header.params = { iteration: iteration };
    if (modelType) {
      params = params.append("type", modelType);
      // header.params["type"] = modelType;
    }
    if (timeSlice) {
      params = params.append("time_slice", timeSlice.toString());
      // header.params["time_slice"] = timeSlice;
    }
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/jobs/${id}/plot/shots_available/${iteration}/`,
        { headers, params, responseType: "json" }
      )
      .pipe(
        map((response) => {
          if (!response) return;
          return response.data;
        }),
        catchError((err) => {
          if (err.status == 404) {
            return of(null);
          }
          return observableThrowError(err);
        })
      );
  }

  getTimeSliceAvailableForModel(
    id: string,
    iteration: number,
    shot_id: number,
    modelType: string
  ): Observable<any> {
    let headers = this.getAuthorizationHeader();
    let params = new HttpParams().append("types", modelType);
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/jobs/${id}/plot/time_slice_available/${iteration}/${shot_id}/`,
        { headers, params }
      )
      .pipe(
        map((response) => {
          if (!response) return;
          let timeSlices = response.data;
          timeSlices.sort((a, b) => a - b);
          return timeSlices;
        })
      );
  }

  getTraceFilesByIteration(
    id: string,
    iteration: number
  ): Observable<number[]> {
    let headers = this.getAuthorizationHeader();
    let params = new HttpParams().append("iteration", iteration.toString());
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/shot_files/`, {
        headers,
        responseType: "json",
        params,
      })
      .pipe(
        map((response) => {
          if (!response) return;
          return response.data;
        }),
        catchError((err) => {
          if (err.status == 404) {
            return of(null);
          }
          return observableThrowError(err);
        })
      );
  }

  getTraceFilesById(id: string, data: object): Observable<number[]> {
    let headers = this.getAuthorizationHeader();
    let params = new HttpParams();
    for (let [key, val] of Object.entries(data))
      params = params.append(key, val?.toString());

    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/shot_files/`, {
        headers,
        responseType: "json",
        params,
      })
      .pipe(
        map((response) => {
          if (!response) return;
          return response.data;
        }),
        catchError((err) => {
          if (err.status == 404) {
            return of(null);
          }
          return observableThrowError(err);
        })
      );
  }

  getTraceMeta(id: string, data: object): Observable<any> {
    let headers = this.getAuthorizationHeader();
    let params = new HttpParams();
    for (let [key, val] of Object.entries(data))
      params = params.append(key, val.toString());
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/shot_meta/`, {
        headers,
        params,
      })
      .pipe(
        map((response) => {
          if (!response) return;
          return response;
        }),
        catchError((err) => {
          if (err.status == 404) {
            return of(null);
          }
          return observableThrowError(err);
        })
      );
  }

  getRunfileParameters(
    id: string,
    setDefault: boolean
  ): Observable<RunfileParam> {
    let headers = this.getAuthorizationHeader();
    let params = new HttpParams().append("default", setDefault.toString());
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/parameters/`, {
        headers,
        params,
      })
      .pipe(
        map((response) => {
          if (!response) return;
          let data = response;
          return {
            global: data.global,
            iterBlks: data.iteration_blocks,
          };
        }),
        catchError((err) => {
          if (err.status == 404 || err.status == 500) {
            return of(null);
          }
          return observableThrowError(err);
        })
      );
  }

  getScreenShots(id: string): Observable<string[]> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/screenshots`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((res) => {
          if (!res) return;
          return res.screenshots;
        })
      );
  }

  getRecentSearchGroups(): Observable<any> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/recent_searches/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((res) => {
          if (!res) return;
          return res.recent_groups;
        })
      );
  }

  getAvailableCustomNames(id: string, iteration: number, shot_id: number) {
    let headers = this.getAuthorizationHeader();
    let params = new HttpParams()
      .append("iteration", iteration.toString())
      .append("shot_id", shot_id.toString());
    // header.params = { iteration: iteration, shot_id: shot_id };
    return this.http
      .get(
        `${AppConfig.settings.apiUrl}/jobs/${id}/shot/custom_name_available/`,
        { headers, params }
      )
      .pipe(
        map((res) => {
          if (!res) return;
          return res.available_custom_types;
        })
      );
  }

  getRcvrData(
    id: string,
    iteration: number,
    shot_id: number,
    fwd: number,
    filterParams: any = {},
    x2IsInline: boolean = false
  ) {
    // let header = this.getAuthorizationHeader();
    // header.params = { iteration: iteration, shot_id: shot_id, fwd: fwd };
    // Object.assign(header.params, filterParams);
    // header.responseType = ResponseContentType.ArrayBuffer;
    let headers = this.getAuthorizationHeader();

    let params = new HttpParams();
    params = params.append("iteration", iteration.toString());
    params = params.append("shot_id", shot_id.toString());
    params = params.append("fwd", fwd.toString());
    for (const [key, val] of Object.entries(filterParams)) {
      if (Array.isArray(val)) {
        for (let v of val) {
          params = params.append(key, v.toString());
        }
      } else {
        params = params.append(key, val.toString());
      }
    }
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/rcvr_list/`, {
        headers,
        params,
        responseType: "arraybuffer",
      })
      .pipe(
        map((res: ArrayBuffer) => {
          if (!res) return;

          let inflatedData: Uint8Array = inflate(res, {to: 'int8'})
          let dataArray = inflatedData.buffer;
          let metas = new Uint32Array(dataArray, 0, 6);
          // 0: num rcvr, 1: num selects, 2: src off, 3: rcvr off, 4: select off, (5: data off) -> deleted
          let groupFunc = (a, c, i) => {
            i % 2 ? a[~~(i / 2)].push(c) : a.push([c]);
            return a;
          };
          let groupFunc3d = (a, c, i) => {
            i % 3 ? a[~~(i / 3)].push(c) : a.push([c]);
            return a;
          };
          let src_locs = Array.from(
            new Float32Array(dataArray.slice(metas[2], metas[3]))
          );
          // console.log(src_locs)
          let rcvr_locs;
          if (src_locs.length == 3) {
            rcvr_locs = Array.from(
              new Float32Array(dataArray.slice(metas[3], metas[4]))
            ).reduce(groupFunc3d, []);
          } else {
            rcvr_locs = Array.from(
              new Float32Array(dataArray.slice(metas[3], metas[4]))
            ).reduce(groupFunc, []);
          }
          if (x2IsInline) {
            if (src_locs.length == 3) {
              [src_locs[0], src_locs[1], src_locs[2]] = [
                src_locs[1],
                src_locs[0],
                src_locs[2],
              ];
              rcvr_locs.forEach(
                (r) => ([r[0], r[1], r[2]] = [r[1], r[0], r[2]])
              );
            } else {
              [src_locs[0], src_locs[1]] = [src_locs[1], src_locs[0]];
              rcvr_locs.forEach((r) => ([r[0], r[1]] = [r[1], r[0]]));
            }
          }
          // console.log(src_locs)
          // console.log(rcvr_locs)
          return {
            src_loc: src_locs,
            rcvr_loc: rcvr_locs,
            selected_idx: Array.from(
              new Uint32Array(dataArray.slice(metas[4], metas[5]))
            ),
            values: Array.from(
              new Float32Array(
                dataArray.slice(metas[5], metas[5] + metas[1] * 4)
              )
            ),
          };
        })
      );
  }

  getRcvrData_data(
    id: string,
    iteration: number,
    shot_id: number,
    fwd: number,
    filterParams: any = {},
    x2IsInline: boolean = false
  ) {
    let headers = this.getAuthorizationHeader();

    let params = new HttpParams();
    params = params.append("iteration", iteration.toString());
    params = params.append("shot_id", shot_id.toString());
    params = params.append("fwd", fwd.toString());
    for (const [key, val] of Object.entries(filterParams)) {
      if (Array.isArray(val)) {
        for (let v of val) {
          params = params.append(key, v.toString());
        }
      } else {
        params = params.append(key, val.toString());
      }
    }
    // headers.params = { iteration: iteration, shot_id: shot_id, fwd: fwd };
    // Object.assign(headers.params, filterParams);

    // let responseType = ResponseContentType.ArrayBuffer;

    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/rcvr_data/`, {
        headers,
        params: params,
        responseType: "arraybuffer",
      })
      .pipe(
        map((res) => {
          if (!res) return [];
          let inflatedData: Uint8Array = inflate(res)
          let dataArray = inflatedData.buffer
          return Array.from(new Float32Array(dataArray));
        })
      );
  }

  getSearchKeywords() {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/search_keys/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((res) => {
          if (!res) return;
          return res.keywords;
        })
      );
  }

  getFilesList(id: string, base_path: string) {
    let param = new URLSearchParams();
    param.set("path", base_path);
    let headers = this.getAuthorizationHeader();
    // header.params = {path:base_path};
    return this.http
      .get(
        `${
          AppConfig.settings.apiUrl
        }/jobs/${id}/files_list?${param.toString()}`,
        { headers }
      )
      .pipe(
        map((res) => {
          if (!res) return;
          return res.result;
        })
      );
  }

  getFunctionalSequence(id: string, path: string, job_prefix: string) {
    let param = new URLSearchParams();
    param.set("path", path);
    param.set("job_prefix", job_prefix);
    let header = { headers: this.getAuthorizationHeader() };
    return this.http
      .get(
        `${
          AppConfig.settings.apiUrl
        }/jobs/${id}/functional_sequence?${param.toString()}`,
        header
      )
      .pipe(
        map((res) => {
          if (!res || !res) return null;
          return res;
        })
      );
  }

  getEnvVariables(id: string) {
    let headers = this.getAuthorizationHeader();
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${id}/env_vars/`, { headers })
      .pipe(
        map((res) => {
          if (!res) return null;
          return res;
        })
      );
  }

  createProject(data: ProjectDialog): Observable<any> {
    return this.http
      .post(
        `${AppConfig.settings.apiUrl}/projects/`,
        {
          name: data.name,
          description: data.description,
          jobs: [],
          well_logs: [],
        },
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response) return;
          return response;
        }),
        catchError((err) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }

  updateProjectDetails(data: ProjectDialog): Observable<any> {
    return this.http
      .patch(
        `${AppConfig.settings.apiUrl}/projects/${data.id}/`,
        {
          name: data.name,
          description: data.description,
        },
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return;
          return response;
        }),
        catchError((err) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }

  getProjectDatatable(projectId: string): Observable<any> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/projects/${projectId}/data_table`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }

  rebuildProjectDatatable(projectId: string, body: object): Observable<any> {
    return this.http
      .post(
        `${AppConfig.settings.apiUrl}/projects/${projectId}/data_table/`,
        body,
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => observableThrowError(err))
      );
  }

  updateProjectDatatable(projectId: string, body: object): Observable<any> {
    return this.http
      .patch(
        `${AppConfig.settings.apiUrl}/projects/${projectId}/data_table/`,
        body,
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => observableThrowError(err))
      );
  }

  deleteProjectDatatableRecord(
    projectId: string,
    body: object
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );
    const options = { body: body, headers: headers };

    return this.http
      .delete(
        `${AppConfig.settings.apiUrl}/projects/${projectId}/data_table/`,
        options
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => observableThrowError(err))
      );
  }

  getModelTableByProject(projectId: string): Observable<any> {
    return this.http
      .get(`${AppConfig.settings.apiUrl}/projects/${projectId}/model_table/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          let details = err.message;
          return observableThrowError(details);
        })
      );
  }

  rebuildModelTable(projectId: string): Observable<any> {
    return this.http
      .post(
        `${AppConfig.settings.apiUrl}/projects/${projectId}/model_table/`,
        {},
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => observableThrowError(err))
      );
  }

  updateModeltable(projectId: string, body: object): Observable<any> {
    return this.http
      .patch(
        `${AppConfig.settings.apiUrl}/projects/${projectId}/model_table/`,
        body,
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => observableThrowError(err))
      );
  }

  getProjectJobs(projectId: string, body: object): Observable<any> {
    return this.http
      .post(`${AppConfig.settings.apiUrl}/projects/${projectId}/jobs/`, body, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => observableThrowError(err))
      );
  }

  deleteJob(id: string): Observable<any> {
    return this.http
      .delete(`${AppConfig.settings.apiUrl}/jobs/${id}/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return;
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          let details = err.message;
          return observableThrowError(details);
        })
      );
  }

  deleteSearchGroup(id: string): Observable<any> {
    return this.http
      .delete(`${AppConfig.settings.apiUrl}/jobs/search_groups/${id}/`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;
          return id;
        })
      );
  }

  deleteScreenshot(id: string): Observable<any> {
    return this.http
      .delete(`${AppConfig.settings.apiUrl}/screenshot/${id}`, {
        headers: this.getAuthorizationHeader(),
      })
      .pipe(
        map((response) => {
          if (!response) return;
          return id;
        })
      );
  }

  updateJobDetails(data: JobDetailDialog): Observable<any> {
    return this.http
      .patch(
        `${AppConfig.settings.apiUrl}/jobs/${data.id}/`,
        {
          job_name: data.name,
          project: data.projectId,
          job_basepath: data.basePath,
          status: data.status,
          iterations: data.iterations,
          iterations_completed: data.iterationsComplete,
          comments: data.comments,
          tag: data.tag,
          parent_job_id: data.parent_job_id,
          parent_cp_num: data.parent_cp_num,
          keep_job: data.keep_job,
          offshoot: data.offshoot,
        },
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return;
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }

  updateJobEpicModComments(
    id: string,
    epicmod_comments: { [key: string]: string }
  ) {
    return this.http
      .patch(
        `${AppConfig.settings.apiUrl}/jobs/${id}/`,
        { epicmod_comments: JSON.stringify(epicmod_comments) },
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return;
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }

  refreshJobDetails(job_id: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );
    // headers.append('Cache-Control','no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    //headers.append('Cache-Control','no-cache');
    // headers.append('Pragma', 'no-cache');
    // headers.append('Expires', '0');
    return this.http
      .get(`${AppConfig.settings.apiUrl}/jobs/${job_id}/`, { headers })
      .pipe(
        map((response) => {
          if (!response) return;
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          let details = err;
          return observableThrowError(details);
        })
      );
  }

  updateJobStatus(data: JobStatusDialog): Observable<any> {
    return this.http
      .patch(
        `${AppConfig.settings.apiUrl}/jobs/${data.id}/`,
        {
          status: data.status,
        },
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        retry(2),
        map((response) => {
          if (!response) return;
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          let details = err;
          return observableThrowError(details);
        })
      );
    // .catch((err: ) => {
    //   let details = err;
    //   return observableThrowError(details);
    // });
  }

  updateScreenshotCaption(id: string, caption: string): Observable<any> {
    return this.http.patch(
      `${AppConfig.settings.apiUrl}/screenshot/${id}`,
      { caption: caption },
      { headers: this.getAuthorizationHeader() }
    );
  }

  uploadScreenshots(id: string, data: any): Observable<any> {
    return this.http
      .post(
        `${AppConfig.settings.apiUrl}/jobs/${id}/screenshots/`,
        { screenshots: data },
        { headers: this.getAuthorizationHeader() }
      )
      .pipe(
        map((response) => {
          if (!response) return;
          return response;
        })
      );
  }

  private getAuthorizationHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      this.authService.getTokenHeaderText()
    );
    return headers;
  }
}

// { headers?: HttpHeaders | { [header: string]: string | string[]; }; observe?: "body"; params?: HttpParams | { [param: string]: string | string[]; }; reportProgress?: boolean; responseType?: "json"; withCredentials?: boolean; }
