import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-parameter-view-edit-dialog",
  templateUrl: "./parameter-view-edit-dialog.component.html",
  styleUrls: ["./parameter-view-edit-dialog.component.css"],
})
export class ParameterViewEditDialogComponent implements OnInit {
  comments: string = "";

  constructor(
    public dialogRef: MatDialogRef<ParameterViewEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data["comments"]) {
      this.comments = this.data["comments"];
    }
  }

  ngOnInit() {}

  onCancel(): void {
    this.dialogRef.close();
  }
}
