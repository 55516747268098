import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { JobDetail } from "../../../models/jobDetail";
import { Ranges } from "../../../models/ranges";
import {
  SliceSettings,
  SliceSettingsService,
} from "../../services/slice-settings.service";

@Component({
  selector: "app-slice-settings-chart",
  templateUrl: "./slice-settings-chart.component.html",
  styleUrls: ["./slice-settings-chart.component.css"],
})
export class SliceSettingsChartComponent implements OnInit {
  @Input() jobDetail: JobDetail;
  @Input() ranges: Ranges;

  sliceSettings: SliceSettings;

  constructor(
    private route: ActivatedRoute,
    private sliceSettingsService: SliceSettingsService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.sliceSettings =
        this.sliceSettingsService.getSettingsFromQueryParams(params, this.jobDetail);
    }),
      (err) => console.log(err);
  }
}
