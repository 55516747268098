import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { ShowMatchingJobsOptionsComponent } from "../../projects/project/dialogs/show-matching-jobs-options/show-matching-jobs-options.component";

@Component({
  selector: "app-parameter-filter-box",
  templateUrl: "./parameter-filter-box.component.html",
  styleUrls: ["./parameter-filter-box.component.less"],
  encapsulation: ViewEncapsulation.None,
})
export class ParameterFilterBoxComponent implements OnInit, OnChanges {
  @ViewChild("paramsListing", { static: true }) paramsElRef: ElementRef;

  // can be given as a different argument, by default, just get the label, split on _, capitalize 1st letter of each word and turn into space separated
  @Input() labelsFormatter: (label: string) => string = (label: string) =>
    label
      .split("_")
      .map((word) => word[0].toLocaleUpperCase() + word.substring(1))
      .join(" ");
  @Input() options: any = [];
  @Input() defaultSelectedColumns: Array<any> = [];
  @Input() alawysSelectedColumns: Array<any> = [];

  filteredOptions: any = this.options;

  @Output() selected = new EventEmitter<any>();

  // --------------------------------------------------------
  // Specific for parameter view
  @Input() jobs: Array<any> = [];
  @Input() isParameterView: Boolean = false;
  @Input() showChangingColumnsControl: Boolean = false;
  @Input() parameterViewMatchingOptions: Object = {};
  @Output() applyDiff = new EventEmitter<any>();
  @Output() showChangingColumns = new EventEmitter<any>();
  // --------------------------------------------------------

  trustedHtml: any;
  selectedOptions: Array<any> = [];
  allCategories: Array<any> = [];
  optionsControl: UntypedFormGroup;
  categoriesControl: UntypedFormGroup;
  searchValue: string = "";
  columnsChanged: boolean = false;

  constructor(
    public dialog: MatDialog,
    private _formbuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      if (this.options) {
        if (this.isArray(this.options)) {
          let temp = {};
          this.options.forEach((opt) => (temp[opt] = true));
          this.optionsControl = this._formbuilder.group(temp);
        } else {
          // make a copy of opions => controlObj, mutate it to get the correct format
          const controlObj = { ...this.options };
          this.convert(controlObj);
          this.optionsControl = this._formbuilder.group(controlObj);

          let temp = {};
          this.allCategories.forEach((cat) => (temp[cat] = true));
          this.categoriesControl = this._formbuilder.group(temp);
        }
        this.filteredOptions = this.options;
      }
    }
    if (
      changes.defaultSelectedColumns &&
      this.defaultSelectedColumns.length > 0
    ) {
      const keysOnly = this.defaultSelectedColumns.map((obj) => obj["key"]);
      if (this.optionsControl) {
        for (let category of Object.keys(this.optionsControl.value)) {
          Object.keys(this.optionsControl.value[category]).forEach(
            (child: string) => {
              this.optionsControl
                .get([category, child])
                .patchValue(keysOnly.includes(child));
            }
          );
        }

        // Do the categories check, uncheck or intermediate
        const categories = Object.keys(this.optionsControl.value);
        for (let category of categories) {
          const categoryState = this.optionsControl.get(category);
          const areAllTrue = Object.values(categoryState.value).every(
            (val) => val == true
          );
          const areAllFalse = Object.values(categoryState.value).every(
            (val) => val == false
          );

          if (areAllTrue) this.categoriesControl.get(category).setValue(true);
          else if (areAllFalse)
            this.categoriesControl.get(category).setValue(false);
          else this.categoriesControl.get(category).setValue("indeterminate");
        }
      }
    }
  }

  searchOptions(event: KeyboardEvent) {
    const newFilteredOptions = {};
    for (const [key, value] of Object.entries(this.options)) {
      if (value instanceof Array) {
        //just to remove a warning, otherwise no need for this check
        newFilteredOptions[key] = value.filter((key: string) =>
          this.labelsFormatter(key)
            .toLowerCase()
            .includes(this.searchValue.toLowerCase())
        );

        if (!this.searchValue || this.searchValue == "") {
          this.activateCollapse(document.getElementById(key), false, true);
        } else {
          this.activateCollapse(document.getElementById(key), true);
        }
      }
    }

    this.filteredOptions = newFilteredOptions;
  }

  convert(obj: any) {
    if (Array.isArray(obj)) {
      let temp = {};
      obj.forEach((opt) => (temp[opt] = true));
      return temp;
    } else if (this.isObject(obj)) {
      for (let key of this.getObjectKeys(obj)) {
        this.allCategories = [...this.allCategories, key];
        obj[key] = this._formbuilder.group(this.convert(obj[key]));
      }
    }
  }

  emitSelected() {
    const vals = this.optionsControl.value;
    const flattenedOptions = [];
    Object.values(vals).forEach((k: any) => {
      flattenedOptions.push(...Object.keys(k).filter((key) => k[key]));
    });

    this.columnsChanged = false;

    this.selected.emit(flattenedOptions);
    // console.log(this.categoriesControl)
  }

  selectAll() {
    for (let key of this.allCategories) {
      this.categorySelected(null, key, true);
    }
  }
  deselectAll() {
    for (let key of this.allCategories) {
      this.categorySelected(null, key, false);
    }
  }

  categorySelected(
    _event: MatCheckboxChange,
    key: string,
    external: boolean = false
  ) {
    this.columnsChanged = true;

    let checked: boolean;
    if (_event) {
      checked = _event.checked;
    } else {
      checked = external;
    }

    this.categoriesControl.get(key).setValue(checked);
    if (this.optionsControl.value[key]) {
      const newVals = {};
      const categoryChildren = Object.keys(this.optionsControl.value[key]);
      newVals[key] = {};
      categoryChildren.forEach((child) => {
        if (this.alawysSelectedColumns.includes(child)) {
          newVals[key][child] = true;
        } else {
          newVals[key][child] = checked;
        }
      });
      this.optionsControl.patchValue(newVals);
      this.childSelected(null, key);
    }
  }

  childSelected(_event: MatCheckboxChange, parentCategory: string) {
    this.columnsChanged = true;

    const categoryState = this.optionsControl.get(parentCategory);
    const areAllTrue = Object.values(categoryState.value).every(
      (val) => val == true
    );
    const areAllFalse = Object.values(categoryState.value).every(
      (val) => val == false
    );

    if (areAllTrue) this.categoriesControl.get(parentCategory).setValue(true);
    else if (areAllFalse)
      this.categoriesControl.get(parentCategory).setValue(false);
    else this.categoriesControl.get(parentCategory).setValue("indeterminate");
  }

  activateCollapse(
    el: HTMLElement,
    open: Boolean = false,
    close: Boolean = false
  ) {
    const collapsibleContainer = el.querySelector("fieldset") as HTMLElement;
    const collapsibleChevron = el.querySelector(
      "i.collapsible-chevron"
    ) as HTMLElement;

    if (open) {
      collapsibleContainer.style.display = "flex";
      collapsibleChevron.style.rotate = "270deg";
      return;
    }
    if (close) {
      collapsibleContainer.style.display = "none";
      collapsibleChevron.style.rotate = "0deg";
      return;
    }

    if (
      collapsibleContainer.style.display == "none" ||
      collapsibleContainer.style.display == ""
    ) {
      collapsibleContainer.style.display = "flex";
      collapsibleChevron.style.rotate = "270deg";
    } else {
      collapsibleContainer.style.display = "none";
      collapsibleChevron.style.rotate = "0deg";
    }
  }

  // --------------------
  // Helpers
  // --------------------
  isObject(value: any) {
    return (
      value != null && // equality operator checks `undefined` and `null`
      (value.constructor === Object ||
        (!value.constructor && typeof value === "object"))
    );
  }
  isArray(value: any) {
    return Array.isArray(value);
  }
  getObjectKeys(obj: Object) {
    return Object.keys(obj);
  }
  getObjectEntries(obj: Object) {
    return Object.entries(obj);
  }

  // ----------------------------------------
  // Parameter View specific functions
  // ----------------------------------------
  openMatchingJobsOptions() {
    this.dialog
      .open(ShowMatchingJobsOptionsComponent, {
        data: {
          options: this.options,
          parameterViewMatchingOptions: this.parameterViewMatchingOptions,
          jobs: this.jobs,
        },
      })
      .afterClosed()
      .subscribe((result: object) => {
        if (
          result &&
          result.hasOwnProperty("selectedJob") &&
          result.hasOwnProperty("diffArg")
        )
          this.applyDiff.emit(result);
      });
  }

  showChangingColumnsHandler() {
    this.columnsChanged = false;
    this.showChangingColumns.emit();
  }
}
