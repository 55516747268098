import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CostsAnalysisDashboardComponent } from "./costs-analysis-dashboard/costs-analysis-dashboard.component";
import { SharedModule } from "../shared/shared.module";
import { ZorroModule } from "../shared/zorro.module";
import { InnerTableComponent } from './costs-analysis-dashboard/inner-table/inner-table.component';

@NgModule({
  declarations: [CostsAnalysisDashboardComponent, InnerTableComponent],
  imports: [CommonModule, SharedModule],
})
export class CostsModule {}
