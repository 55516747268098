import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { JobStatusDialog } from '../../../../models/jobStatusDialog';


@Component({
  selector: 'app-job-start',
  templateUrl: './job-start.component.html',
  styleUrls: ['./job-start.component.less']
})
export class JobStartComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) form;

  constructor(@Inject(MAT_DIALOG_DATA) public data: JobStatusDialog, public dialogRef: MatDialogRef<JobStartComponent>) { }

  ngOnInit() {
  }

  submitForm() {
    if (!this.form.valid)
      return;

    if (this.data.status == "RUNNING")
      this.data.status = "NOTRUNNINNG";
    else
      this.data.status = "RUNNING";

    this.dialogRef.close(this.data);
  }
}
